import { ofType, combineEpics } from 'redux-observable';
import { tap, ignoreElements } from 'rxjs/operators';
import { control } from "../../misc/location/resolve";
import { actions as sessionActions } from '../api/session';


export const epic = combineEpics(
	action$ => action$.pipe(
		ofType(sessionActions.events.started.type, sessionActions.events.closed.type)
		, tap(() => control.reset())
		, ignoreElements()
	)
	, action$ => action$.pipe(
		ofType(sessionActions.events.paused.type)
		, tap(() => control.pause())
		, ignoreElements()
	)
	, action$ => action$.pipe(
		ofType(sessionActions.events.resumed.type)
		, tap(() => control.resume())
		, ignoreElements()
	)
);
