import React, { useImperativeHandle } from 'react';

import { connect } from 'react-redux';

import { useI18n } from '../../../../../i18n';
import { cx } from '../../../../api';

import { actions } from '../../../../redux/api/reports';

import Form from '../../../general/form/Form';

import ObjectDeviceManager from "../../../share/devices/ObjectDeviceManager";

import './parametrizator.scss';


export default connect(state => ({
	state: state.reports.deviceStatus
}))(props => {
	const {f} = useI18n();

	useImperativeHandle(props.customRef, () => ({
		validate: values => {
			if (!values.position && !values.event && !values.zones) return f('no status aspects to report on');
		}
		, submit: values => {
			const parameters = new cx.ods.reports.DeviceStatusReportParameters();
			if (0 < values.uris?.length) parameters.uris = values.uris;
			if (values.position) parameters.position = values.position; 
			if (values.event) parameters.event = values.event;
			if (values.zones) parameters.zoneGuards = new cx.ods.processors.ZoneGuardStatusParameters();
			props.dispatch(actions.deviceStatus.generate.request({parameters}));
		}
		, clear: () => {
			props.dispatch(actions.deviceStatus.export.clear());
 		}
	}));

	const {disabled, state: {parameters}} = props;
	return <div className="device-status-parameters">
		<Form.ControlGroup
			label={f('status aspects')}
			disabled={disabled}
		>
			<Form.Control
				controlName="position"
				label={f('last location')}
				controlType={Form.Control.Type.Checkbox}
				initialValue={Boolean(parameters?.position)}
			/>
			<Form.Control
				controlName="event"
				label={f('last event')}
				controlType={Form.Control.Type.Checkbox}
				initialValue={Boolean(parameters?.event)}
			/>
			<Form.Control
				controlName="zones"
				label={f('current zones')}
				controlType={Form.Control.Type.Checkbox}
				initialValue={Boolean(parameters?.zoneGuards)}
			/>
		</Form.ControlGroup>
		<ObjectDeviceManager
			controlName="uris"
			title={f('devices restriction')}
			disabled={disabled}
			emptyMessage={f('all devices')}
			editMode
			uris={parameters?.uris}
		/>
	</div>;
});
