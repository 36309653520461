import React from 'react';
import { useDragLayer } from 'react-dnd';
import { DragItemType } from './DragItemType';
import editIcon from '../../../img/icons/edit.png';
import pinIcon from '../../../img/icons/pin-empty.png';
import removeIcon from '../../../img/icons/trash.png';
import DeviceListItemContent from '../../custom/sidebar/devices/DeviceListItemContent';
import './dragLayer.scss';

const style = {
	position: 'fixed',
	pointerEvents: 'none',
	zIndex: 1100,
	left: -17,
	top: -10,
	width: '100%',
	height: '100%',
}

function getItemStyle(initialOffset, currentOffset, item) {
	if (!initialOffset || !currentOffset) return { display: 'none' };

	let { x, y } = currentOffset;
	const transform = 'translate(' + x + 'px, ' + y + 'px)';
	const width =  item && item.width ? (item.width + 'px') : 'auto';
	return {
		transform,
		width,
		WebkitTransform: transform,
	}
}

// ---------------------------------------------------------------

function DragLayer() {
	let content = null;

	const {
		item,
		itemType,
		isDragging,
		initialOffset,
		currentOffset,
	} = useDragLayer(monitor => ({
		item: monitor.getItem(),
		itemType: monitor.getItemType(),
		initialOffset: monitor.getInitialSourceClientOffset(),
		currentOffset: monitor.getSourceClientOffset(),
		isDragging: monitor.isDragging(),
	}))

	function renderItem() {
		switch (itemType) {
			case DragItemType.ACTION_EDIT:
				return (
					<div className="action-item">
						<img src={editIcon} alt="" title="Edit" />
					</div>
				);
			case DragItemType.ACTION_PIN:
				return (
					<div className="action-item">
						<img src={pinIcon} alt="" title="Pin" />
					</div>
				);
			case DragItemType.ACTION_REMOVE:
				return (
					<div className="action-item">
						<img src={removeIcon} alt="" title="Delete" />
					</div>
				);
			case DragItemType.DEVICE:
				return <DeviceListItemContent
					pinned={item && item.pinned}
					device={item && item.device}
					reduxKey={item && item.reduxKey}
				/>;
			default:
				return null
		}
	}

	if (!isDragging) return null;

	const itemImage = renderItem();

	if (itemImage) {
		content = (<div style={style} className="drag-layer">
			<div className="item" style={getItemStyle(initialOffset, currentOffset, item)}>
				{itemImage}
			</div>
		</div>);
	}

	return (content);
}

export default DragLayer;
