import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, takeUntil } from 'rxjs/operators';
import { actions } from './actions';
import { api, rx, cx } from '../../../api';
import { actions as sessionActions } from '../session';
import { errorMap } from '../../actions';

const defaultState = {
	list: null,
	map: null,
	pending: false,
	error: null
}

// -----------------------------------------------------

function reducer(state, action) {
	switch (action.type) {
		case actions.roles.request.type:
			return state = {
				...state,
				pending: true,
				error: null
			};
		case actions.roles.success.type:
			return state = {
				...state,
				list: action.list,
				map: cx.i.hash(action.list, role => role.code),
				pending: false,
				error: null
			};
		case actions.roles.fail.type:
			return state = {
				...state,
				pending: false,
				error: action.errorMessage
			};
		case actions.roles.cancel.type:
			return state = {
				...state,
				pending: false
			};
		default:
			return state || defaultState;
	}
}

// --------------------------------------------------------

const rolesEpic = (action$) => {
	return action$.pipe(
		ofType(actions.roles.request.type),
		mergeMap(action =>
			rx(api.registry.roles).pipe(
				map(operation => actions.roles.success({ list: operation.response() })),
				errorMap(actions.roles.fail),
				takeUntil(action$.pipe(ofType(actions.roles.cancel.type)))
			)
		)
	)
}

const watchSessionEpic = (action$) => {
	return action$.pipe(
		ofType(sessionActions.events.started.type),
		map(action => actions.roles.request())
	)
}

const epic = combineEpics(rolesEpic, watchSessionEpic);

export { reducer, epic };
