import { ActionGeneratorBuilder } from "../actions";

const STORAGE_KEY = 'lang';

const userLanguage = (window.navigator
	? (window.navigator.language
		|| window.navigator.systemLanguage
		|| window.navigator.userLanguage
	)
	: "en"
).substring(0, 2).toLowerCase();

const actions = new ActionGeneratorBuilder('locale').type('setLang', 'lang').build();

const reducer = (state, action) => {
	if (state == undefined) {
		state = {
			lang: window.localStorage.getItem(STORAGE_KEY) || userLanguage, // ISO 639-1
			// country:  // ISO 3166
		};
	}
	switch (action.type) {
		case actions.setLang.type:
			window.localStorage.setItem(STORAGE_KEY, action.lang);
			return {
				...state,
				lang: action.lang
			}
		default:
			return state;
	}
}

export { actions, reducer };
