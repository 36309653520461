import React, { DispatchWithoutAction, FC, MutableRefObject } from 'react';

import {
  ConfirmModalButton,
  ConfirmModalButtonsStyled,
  ConfirmModalStyled,
  ConfirmModalTextStyled,
} from 'ui/pages/issues/confirm-modal.styled';
import { fc } from 'i18n';
import { ModalWrapper } from 'components/molecules/modal-wrapper';

interface IConfirmModalProps {
  isOpen: boolean;
  isLoading: boolean;
  modalRef: MutableRefObject<HTMLDivElement | null>;
  closeModal: DispatchWithoutAction;
  updateResolution: DispatchWithoutAction;
}

export const ConfirmModal: FC<IConfirmModalProps> = (props) => {
  const { isOpen, isLoading, modalRef, closeModal, updateResolution } = props;

  return isOpen ? (
    <ModalWrapper modalRef={modalRef}>
      <ConfirmModalStyled>
        <ConfirmModalTextStyled>
          {fc('Are you sure you want to change actions taken for this issue?')}
        </ConfirmModalTextStyled>
        <ConfirmModalButtonsStyled>
          <ConfirmModalButton type="button" disabled={isLoading} onClick={closeModal}>
            {fc('cancel')}
          </ConfirmModalButton>
          <ConfirmModalButton type="button" disabled={isLoading} onClick={updateResolution}>
            {fc('okay')}
          </ConfirmModalButton>
        </ConfirmModalButtonsStyled>
      </ConfirmModalStyled>
    </ModalWrapper>
  ) : null;
};
