import styled, { css } from 'styled-components';

import { Colors } from 'ui/constants/colors';

interface ITabStyledProps {
  isActive?: boolean;
}

export const TabsStyled = styled.div`
  display: flex;
  height: 41px;
`;

export const TabStyled = styled.span<ITabStyledProps>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 41px;
  font: bold 14px/20px Muli;
  color: ${Colors.DoveGray};
  cursor: pointer;
  padding: 0 20px 6.5px 20px;

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${Colors.UsafaBlue};
      background-color: ${Colors.SilverPhoenix};
    `}

  &:not(:last-child) {
    margin-right: 15px;
  }
`;

export const TabUnderlineStyled = styled.div`
  position: absolute;
  bottom: 0;
  height: 5px;
  width: 100%;
  background-color: ${Colors.DarkCobaltBlue};
`;
