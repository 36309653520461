import Resolver from '../Resolver';
import { actions } from '../actions';

const getter = (entry) => {
	const message = entry.event && entry.event.message;
	const coordinates = [];
	if (message) {
		coordinates.push({ latitude: message.latitude, longitude: message.longitude });
	}
	return coordinates;
}

let resolver = null;
const connect = (store) => {
	resolver = new Resolver(store, actions.ridership.exportProgress, getter);
}

export { resolver, connect };