import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { className } from '../../../../lib/className';
import Loader from '../../../general/Loader';
import { useI18n } from '../../../../../i18n';
import Address from '../../../general/location/Address';
import DatetimeField from '../../../general/datetime/DatetimeField';
import ScrollList from '../../../general/list/ScrollList';
import ReportSummary from './ReportSummary';
import ReportHeader from './ReportHeader';
import { formatter } from '../../../../misc/datetime';
import { scrollReportItemIntoView } from '../../../../misc/misc';
import { actions } from '../../../../redux/api/reports'
import EventIcon from '../../../share/events/EventIcon';
import SwitchMapMenuItem from './SwitchMapMenuItem';
import { actions as tripTraceActions } from '../../../../redux/api/tripTrace';
import TraceViewMap from '../../map/TraceViewMap';
import NoDataView from '../../../general/view/NoDataView';
import './trip.scss';

function EventCountIcon({eventType, count}) {
	return (
		<div className="event-count-icon">
			<span className="count">{count}</span>
			<EventIcon eventType={eventType} />
		</div>
	)
}

function HeaderListItem({withMarker}) {
	const { f } = useI18n();
	return (
		<>
			<div className="header-item"><span className="capitalize">{f('item No.')}</span></div>
			<div className="header-item"><span className="capitalize">{f('start time')}</span></div>
			<div className="header-item"><span className="capitalize">{f('start location')}</span></div>
			<div className="header-item"><span className="capitalize">{f('end time')}</span></div>
			<div className="header-item"><span className="capitalize">{f('end location')}</span></div>
			<div className="header-item"><span className="capitalize">{f('duration')}</span></div>
			<div className="header-item"><span className="capitalize">{f('distance') + ', ' + f('units.km')}</span></div>
			<div className="header-item"><span className="capitalize">{f('incidents')}</span></div>
			{withMarker && <div className="header-item"><span className="capitalize">{f('marker')}</span></div>}
		</>
	)
}

/**
 * @param {Object} props
 * @param {cx.ods.reports.TripSummary} props.summary
 * @param {number} props.index
 * @param {boolean} props.withMarker
 * @param {function} props.onClick
 * @param {React.Ref} [props.customRef]
 * @param {boolean} [props.selected]
 */

function ReportListItem(props) {
	const trip = props.summary.trip;
	const deviceSummary = props.summary.summary;
	const eventDigests = deviceSummary.incidentDigests;

	let incidents = null;
	if (eventDigests != null) {
		incidents = eventDigests.map((digest, at) =>
			<EventCountIcon key={at} eventType={digest.eventType} count={digest.quantity} />
		);
	}

	let marker = null;
	if (props.summary.marker != null) {
		marker = props.summary.marker.message.fields.mapped[19]; // TODO ??
	}

	const onClick = () => {
		props.onClick(trip);
	}

	const _className = className('clickable', { 'selected': props.selected });
	return (
		<>
			<div ref={props.customRef} className={_className} onClick={onClick}>{props.index}</div>
			<div className={_className} onClick={onClick}><DatetimeField datetime={trip.startAt} /></div>
			<div className={_className} onClick={onClick}><Address message={trip.startMessage} full /></div>
			<div className={_className} onClick={onClick}><DatetimeField datetime={trip.endAt} now={trip.startAt}/></div>
			<div className={_className} onClick={onClick}><Address message={trip.lastMessage} full /></div>
			<div className={_className} onClick={onClick}>{formatter.duration(trip.startAt, trip.endAt)}</div>
			<div className={_className} onClick={onClick}>{deviceSummary.distance}</div>
			<div className={_className} onClick={onClick}>{incidents || "-"}</div>
			{props.withMarker && <div className={_className} onClick={onClick}>{marker || "-"}</div>}
		</>
	)
}

function EodListItem() {
	const { f } = useI18n();
	return <div className="indicator"><span className="capitalize">{f('you have reached the end')}</span></div>;
}

// -----------------------------------------------------------------

function Trip(props) {
	const { f } = useI18n();
	const selectedItemBox = useRef();
	const [selectedTripId, setSelectedTripId] = useState(null);
	const [displayMap, setDisplayMap] = useState(false);
	const withMarker = !!props.state.parameters.markers;
	const tripTrace = props.tripTrace[selectedTripId];

	useLayoutEffect(() => {
		if (selectedItemBox.current) {
			scrollReportItemIntoView(selectedItemBox.current);
		}
	}, [selectedTripId, displayMap]);

	useEffect(() => {
		return () => {
			props.dispatch(tripTraceActions.clear());
		}
	}, []);

	const loadNext = () => {
		const uri = props.state.uri;
		const parameters = props.state.parameters;
		props.dispatch(actions.trip.request({ uri, parameters }));
	}

	const onItemClick = (trip) => {
		if (selectedTripId != trip.tripId) {
			if (!displayMap) setDisplayMap(true);
			if (!tripTrace || !props.tripTrace[trip.tripId]) {
				props.dispatch(tripTraceActions.load.request({ uri: props.state.uri, tripId: trip.tripId }));
			}
			setSelectedTripId(trip.tripId);
		}
	}

	const switchMapHandler = () => {
		setDisplayMap(!displayMap);
	}

	let content = null;
	let mapBox = null;
	let canExport = false;
	if (props.state.list != null) {
		if (!props.state.list || props.state.list.length == 0) {
			content = <NoDataView message={f('no data for such criteria')} />;
		} else {
			canExport = true;
			const items = props.state.list.map((summary, at) => {
				const isSelected = selectedTripId == summary.trip.tripId;
				return (
					<ReportListItem
						key={at}
						summary={summary}
						onClick={onItemClick}
						selected={isSelected}
						customRef={isSelected ? selectedItemBox : undefined}
						index={at+1}
						withMarker={withMarker}
					/>
				);
			});
			items.unshift(<HeaderListItem key={1000000} withMarker={withMarker} />);
			content = <ScrollList
				pending={props.state.pending}
				next={loadNext}
				isEod={!props.state.hasMore}
				eodItem={EodListItem}
			>
				{items}
			</ScrollList>;
			if (displayMap) {
				mapBox = (
					<TraceViewMap
						pending={tripTrace && tripTrace.pending}
						trace={tripTrace && tripTrace.list}
					/>
				);
			}
		}
	} else {
		content = (
			<div className="center">
				{
					props.state.error
						? <span className="error">{props.state.error}</span>
						: <Loader size={Loader.Size.MD} />
				}
			</div>
		);
	}

	const exportReport = () => {
		props.dispatch(actions.trip.export({ uri: props.state.uri }));
		props.history.goBack();
	}

	const timeRange = props.state.parameters.timeRange;
	return (
		<div className={"report trip" + (props.state.parameters.markers ? " markers" : " base")}>
			<ReportHeader
				title={f('trip report')}
				canExport={canExport}
				onExport={exportReport}
				items={<SwitchMapMenuItem disabled={!canExport} active={displayMap} onClick={switchMapHandler} />}
			/>
			<ReportSummary
				since={timeRange.since}
				until={timeRange.until}
				devices={[props.devices[props.state.uri]]}
				generatedAt={props.state.at}
			/>
			<div className={className('content', { 'with-map': displayMap })}>
				{content}
				{mapBox}
			</div>
		</div>
	)
}

export default withRouter(connect(state => {
	return ({
		state: state.reports.trip,
		devices: state.devices.map,
		tripTrace: state.tripTrace
	})
})(Trip));
