import { useSelector } from 'react-redux';

import { attributeTypesSelector, messageFieldsSelector } from 'core/redux/selectors/registry';

export const useAttribute = (attributeId?: number) => {
  const messageFields = useSelector(messageFieldsSelector);
  const attributeTypes = useSelector(attributeTypesSelector);

  const messageField = attributeId && messageFields ? messageFields[attributeId] : null;
  const attributeType = attributeTypes && messageField ? attributeTypes[messageField.fieldType] : null;

  return { messageField, attributeType };
};
