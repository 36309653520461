import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';

import { OnPageChangeType } from 'types/pagination';
import { ISelectOption } from 'types/select';
import { AllRoutes } from 'constants/routes';

export const useEntitiesTable = (path: AllRoutes) => {
  const currentLocation = useLocation();
  const { push } = useHistory();

  const searchParams = qs.parse(currentLocation.search, { ignoreQueryPrefix: true });
  const { page, pageSize, tab } = searchParams;

  const navigationPayload = Object.keys(searchParams).reduce(
    (params, key) => ({ ...params, [key]: String(searchParams[key]) }),
    {},
  );

  const updatePage: OnPageChangeType = ({ selected }) => {
    const params = { ...navigationPayload, page: String(selected) };
    const paramsString = new URLSearchParams(params).toString();

    push(`${path}?${paramsString}`);
  };

  const updatePageSize = (selectedPageSize: ISelectOption<number>) => {
    const params = { ...navigationPayload, page: '1', pageSize: String(selectedPageSize.value) };
    const paramsString = new URLSearchParams(params).toString();

    push(`${path}?${paramsString}`);
  };

  return {
    page: page ? Number(page) : 1,
    pageSize: { value: pageSize ? Number(pageSize) : 10, label: 'display X entries' },
    tab: tab ? String(tab) : undefined,
    searchParams: currentLocation.search,
    updatePage,
    updatePageSize,
  };
};
