import React, { useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import { cx } from '../../../../api';
import Form from '../../../general/form/Form';
import { actions } from '../../../../redux/api/reports';
import ObjectDeviceManager from '../../../share/devices/ObjectDeviceManager';
import EventTypePicker from 'core/react/share/events/EventTypePicker';

import './deviceStuations.scss';

const BATCH_SIZE = 70;

/**
 * @param {Object} props
 * @param {React.RefObject} props.customRef
 * @param {Object} props.timeRange
 * @param {boolean} [props.disabled]
 */

function DeviceSituations(props) {
	const { fc, f } = useI18n();

	useImperativeHandle(props.customRef, () => ({
		submit: (values) => {
			const parameters = new cx.ods.reports.DeviceSituationReportParameters();

			if (values.uris?.length > 0) {
				parameters.uris = values.uris
			}

			parameters.eventTypes = values.eventTypes?.length ? values.eventTypes : null;
			parameters.timeRange = props.timeRange;
			parameters.timeRange.window = new cx.ods.TimeWindow(BATCH_SIZE);
			parameters.markers = values.markers;
			props.dispatch(actions.deviceSituations.request({ parameters, clear: true }));
		},
		clear: () => {
			props.dispatch(actions.deviceSituations.exportClear());
		}
	}));

	return (
		<div className="device-situations-parameters" >
			<Form.ControlGroup key={50} disabled={props.disabled}>
				<Form.Control
					controlName="markers"
					controlType={Form.Control.Type.Checkbox}
					label={f('locate pre-start markers')}
					initialValue={props.state.parameters ? props.state.parameters.markers : false}
				/>
			</Form.ControlGroup>
			<ObjectDeviceManager
				controlName="uris"
				title={fc('devices restriction')}
				disabled={props.disabled}
				emptyMessage={f('all devices')}
				editMode
				uris={props.state.parameters?.uris}
			/>
			<Form.Control
				controlType={EventTypePicker}
				controlName="eventTypes"
				label={f('event types')}
				placeholder={f('default event type')}
				initialValue={props.state?.parameters?.eventTypes || null}
			/>
		</div>
	)
}

export default connect(state => {
	return {
		state: state.reports.deviceSituations
	}
})(DeviceSituations);
