import React, { FC } from 'react';

import { RangeBoundsFields } from './range-bounds-fields';
import { HysteresisFields } from './hysteresis-fields';

interface IRangeFieldsProps {
  isDisabled: boolean;
  mnemonics: string;
}

export const RangeFields: FC<IRangeFieldsProps> = ({ isDisabled, mnemonics }) => (
  <div>
    <RangeBoundsFields mnemonics={mnemonics} isDisabled={isDisabled} />
    <HysteresisFields mnemonics={mnemonics} isDisabled={isDisabled} />
  </div>
);
