import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { camelCaseToKebabCase } from '../../../misc/string';
import MessageFieldView from './MessageFieldView';
import { ReduxKeyContext } from '../../../misc/ReduxKeyContext';

const UNITS = "device-reference";

/**
 * @param {Object} props
 * @param {string} props.value
 * @param {string} props.title
 * @param {boolean} props.pinned
 */

function DeviceReferenceFieldView(props) {
	const reduxKey = useContext(ReduxKeyContext);
	const device = props.deviceMap && props.deviceMap[props.value];

	const value = (
		<span onClick={(e) => e.stopPropagation()} title="link">
			{device ?
				<Link
					to={reduxKey
						? '/' + camelCaseToKebabCase(reduxKey) + '/devices/' + props.value
						: '/device/' + props.value + '/control-panel'
					}
				>
					{device.denomination()}
				</Link>
				: props.value
			}
		</span>
	);

	return (
		<MessageFieldView
			title={props.title}
			value={value}
			pinned={props.pinned}
			onClick={props.onClick}
			fieldName={props.fieldName}
		/>
	);
}

DeviceReferenceFieldView.Units = UNITS;

export default connect(
	state => ({
		deviceMap: state.devices.map
	})
)(DeviceReferenceFieldView);