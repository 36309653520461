import { useEffect, useRef, useState } from 'react';
import { Group as LayerGroup, Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import GeoJSON from 'ol/format/GeoJSON';

import { useI18n } from 'i18n';
import { IGeoJsonLayer } from 'types/map';
import { GEO_JSONS_INFO } from 'constants/map';
import { FETCH_OPTIONS } from 'constants/api';
import { getVectorStyles } from 'helpers/map/getVectorStyles';
import useLocalStorage from 'core/misc/useLocalStorage';
import { Map } from 'core/react/general/location/Map';

export const useGeoJsonLayers = (mapName: string, map: Map | null) => {
  const { f } = useI18n();

  const geoJsonLayerGroup = useRef<LayerGroup | null>(null);

  const [selectedGeoJsonLayers, setSelectedGeoJsonLayers] = useLocalStorage(`${mapName}_geo_json_layers`);
  const [geoJsonLayersOptions, setGeoJsonLayersOptions] = useState<Record<string, string>[] | null>(null);

  const getCurrentZoom = () => Math.trunc(map?.getOlMap()?.getView()?.getZoom() || 1);

  const getGeoJsonLayerGroup = (geo: IGeoJsonLayer[]) => {
    const layers = geo.map(({ data, name, zoom, color, labelKey }) => {
      const vectorSource = new VectorSource({
        features: new GeoJSON({ featureProjection: 'EPSG:3857' }).readFeatures(data),
      });

      const layer = new VectorLayer({ source: vectorSource });

      layer.setStyle(getVectorStyles(color, labelKey));
      layer.setProperties({ name, zoom });

      return layer;
    });

    return new LayerGroup({ layers });
  };

  const fetchGeoJson = async () =>
    Promise.all(
      GEO_JSONS_INFO.map(async ({ path, ...rest }) => {
        const data = await (await fetch(path, FETCH_OPTIONS)).json();

        return { data, ...rest };
      }),
    );

  const setGeoJsonVisibility = (layersNames: string[], zoom?: number) => {
    const currentZoom = zoom || getCurrentZoom();

    if (geoJsonLayerGroup?.current) {
      geoJsonLayerGroup.current.getLayers().forEach((layer) => {
        layer.setVisible(layersNames?.includes(layer.get('name')) && currentZoom >= layer.get('zoom'));
      });
    }
  };

  const setLayersOptions = (geo: IGeoJsonLayer[]) => {
    const layersOptions: Record<string, string>[] = geo.reduce(
      (options: Record<string, string>[], layer: IGeoJsonLayer) => {
        const isOptionExist = !!options.find((option) => option.value === layer.name);

        return isOptionExist ? options : [...options, { value: layer.name, label: f(layer.name) }];
      },
      [],
    );

    setGeoJsonLayersOptions(layersOptions);
  };

  const setGeoJsonLayers = async () => {
    if (map) {
      const data = await fetchGeoJson();

      setLayersOptions(data);

      geoJsonLayerGroup.current = getGeoJsonLayerGroup(data);

      map.getOlMap().addLayer(geoJsonLayerGroup.current);

      setGeoJsonVisibility(selectedGeoJsonLayers);
    }
  };

  const onGeoJsonLayerSelect = (zones: string[]) => {
    setSelectedGeoJsonLayers(zones);
    setGeoJsonVisibility(zones);
  };

  useEffect(() => {
    void setGeoJsonLayers();
  }, [map]);

  useEffect(() => {
    if (map) {
      let currentZoom = getCurrentZoom();

      map
        .getOlMap()
        .getView()
        .on('change:resolution', () => {
          const newZoom = getCurrentZoom();

          if (currentZoom !== newZoom) {
            currentZoom = newZoom;

            geoJsonLayerGroup.current && setGeoJsonVisibility(selectedGeoJsonLayers, newZoom);
          }
        });
    }
  }, [map, selectedGeoJsonLayers]);

  return { geoJsonLayersOptions, selectedGeoJsonLayers, onGeoJsonLayerSelect };
};
