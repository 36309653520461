import React, { useEffect, useRef, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useI18n } from '../../../../i18n';
import * as widgets from '../../../redux/app/widgets';
import { isEscapeButton } from '../../../misc/misc';
import Button from '../form/Button';
import Form from '../form/Form';
import { ReduxKeyContext } from '../../../misc/ReduxKeyContext';
import ActionClose from '../../share/actionbar/ActionClose';
import { className, fromProps } from '../../../lib/className';

/**
 * @param {Object} props
 * @param {string} props.uid
 * @param {function} props.close
 * @param {string} [props.className]
 * @param {string} [props.title]
 */

function WidgetConfigurationWrap(props) {
	const { f, fc } = useI18n();
	const reduxKey = useContext(ReduxKeyContext);
	const dispatch = useDispatch();
	const box = useRef(null);
	const submitHook = useRef(null);

	useEffect(() => {
		const handleEscape = (event) => {
			if (isEscapeButton(event) && !window.document.body.className) props.close(); // HACK ?
		}
		window.document.addEventListener('keydown', handleEscape);
		return () => window.document.removeEventListener('keydown', handleEscape);
	}, []);

	const onClick = () => {
		submitHook.current();
	}

	const onApply = (values) => {
		dispatch(widgets.actions.update({ domain: reduxKey, uid: props.uid, data: values }));
		props.close && props.close();
	}

	const children = () => {
		return React.Children.map(props.children, child =>
			React.isValidElement(child) && typeof child.type !== 'string' ? React.cloneElement(child, { close: props.close }) : child
		);
	}

	return (
		<div ref={box} className={className("configure-content-wrap", fromProps(props))}>
			<div className="header">
				<span className="capitalize">
					{props.title ? props.title : fc('configuration')}
				</span>
				<ActionClose onClick={props.close} />
			</div>
			<div className="content">
				<Form
					onSubmit={onApply}
					submitHook={submitHook}
				>
					{children()}
					<div className="buttons">
						<Button onClick={onClick}>
							{f('apply')}
						</Button>
					</div>
				</Form>
			</div>
		</div>
	);
}

export default WidgetConfigurationWrap;
