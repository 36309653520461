import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import FuelLevelBalanceChart from '../renderer/FuelLevelBalanceChart/FuelLevelBalanceChart';
import TripHistoryList from '../renderer/TripHistoryList';
import NoDataView from '../../../general/view/NoDataView';
import { useI18n } from '../../../../../i18n';
import './tripHistory.scss';


function TripHistory(props) {
	const { f } = useI18n();
	const [selectedIndex, setSelectedIndex] = useState();

	const onItemClick = (entry, index) => {
		setSelectedIndex(index);
	}

	useEffect(() => {
		setSelectedIndex(null);
	}, [props.previewList]);

	let content = null;
	if (props.previewList && props.previewList.length > 0) {
		content = (
			<div className="trip-history-preview">
				{props.hasFuelLevel && <FuelLevelBalanceChart
					selectedIndex={selectedIndex}
					data={props.previewList}
					uri={props.uri}
					onDataPointClick={setSelectedIndex}
				/>}
				<TripHistoryList
					list={props.previewList}
					uri={props.uri}
					selectedIndex={selectedIndex}
					onClick={onItemClick}
					hasFuelLevel={props.hasFuelLevel}
				/>
			</div>
		);
	} else {
		content = (<NoDataView message={f('no data for such criteria')} />);
	}

	return (content);
}

export default connect(state => ({
	previewList: state.reports.tripHistory.preview.list,
	hasFuelLevel: state.reports.tripHistory.parameters && !!state.reports.tripHistory.parameters.fuelLevel,
	uri: state.reports.tripHistory.uri
}))(TripHistory);