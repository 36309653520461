import React from 'react';
import { connect } from 'react-redux';
import { fromProps, className } from '../../../lib/className';
import { compare } from '../../../lib/sorting';
import Loader from '../../general/Loader';
import InputPicker from '../../general/form/InputPicker';
import './devicePicker.scss';

/**
 * For parameters - see rsuite InputPicker.
 */

function DevicePicker(props) {
	const devices = props.devices && props.devices.map(device => ({ value: device.uri, label: device.denomination() }));
	if (devices) devices.sort(compare(item => item.label.toLowerCase()));

	const content = devices
		? (
			<InputPicker
				block
				data={devices}
				placeholder={props.placeholder}
				disabled={props.disabled}
				value={props.value}
				onChange={props.onChange}
			/>
		)
		: <div className="loader"><Loader size={Loader.Size.SM} /></div>
	;
	return (
		<div className={className('device-picker', fromProps(props))}>
			{props.label && <label>{props.label}</label>}
			{content}
		</div>
	)
}

export default connect(state => {
	return {
		devices: state.devices.list
	}
})(DevicePicker);