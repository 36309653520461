import { combineEpics, ofType } from 'redux-observable';
import { filter, map as rxmap } from 'rxjs/operators';
import { actions as deviceStatesActions } from '../../api/deviceStates';
import { actions as sessionActions } from '../../api/session';
import { status } from '../../../lib/device';
import StatusUpdateDaemon from './daemon';
import { ActionGeneratorBuilder } from '../../actions';

const defaultState = {
	map: null // uri => { status: string }
};

const actions = new ActionGeneratorBuilder('deviceStatus')
	.type('changed', { uri: true, status: true })
	.type('update', { uri: true })
	.build()
;

// ---------------------------------------------------------

const reducer = (state, action) => {
	switch (action.type) {
		case actions.changed.type:
			const map = { ...state.map };
			map[action.uri] = { status: action.status };
			state = { map };
			break;
	}
	return state || defaultState;
}

// ---------------------------------------------------------

const watchStatesEpic = (action$, state$) => {
	let statusObj, oldStatus, newStatus;
	return action$.pipe(
		ofType(deviceStatesActions.changed.type),
		filter(action => {
			const map = state$.value.deviceStatuses.map;
			statusObj = map ? map[action.uri] : null;
			oldStatus = statusObj ? statusObj.status : null;
			newStatus = status(action.state.message);
			StatusUpdateDaemon.getInstance().onChange(action.uri, newStatus);
			return newStatus != oldStatus;
		}),
		rxmap(action => {
			return actions.changed({ uri: action.uri, status: newStatus });
		})
	);
}

const updateEpic = (action$, state$) => {
	let statusObj, oldStatus, newStatus;
	return action$.pipe(
		ofType(actions.update.type),
		filter(action => {
			const map = state$.value.deviceStatuses.map;
			const state = state$.value.deviceStates.map[action.uri];
			statusObj = map ? map[action.uri] : null;
			oldStatus = statusObj ? statusObj.status : null;
			newStatus = status(state.message);
			StatusUpdateDaemon.getInstance().onChange(action.uri, newStatus);
			return newStatus != oldStatus;
		}),
		rxmap(action => actions.changed({ uri: action.uri, status: newStatus }))
	);
}

const watchSessionEpic = (action$) => {
	return action$.pipe(
		ofType(sessionActions.events.paused.type, sessionActions.events.closed.type),
		rxmap(() => StatusUpdateDaemon.getInstance().reset()),
		filter(() => false)
	)
}

const epic = combineEpics(watchStatesEpic, updateEpic, watchSessionEpic);

// ---------------------------------------------------------

export { actions, reducer, epic };
