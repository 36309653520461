import React, { useRef, useEffect } from 'react';
import ListItem from './ListItem';
import Loader from '../Loader';
import { className, fromProps } from '../../../lib/className';
import './scrollList.scss';

/**
 * @param {Object} props
 * @param {function} props.next
 * @param {function} props.previous
 * @param {boolean} [props.isEod] end of data
 * @param {boolean} [props.isBod] beginning of data
 * @param {boolean} [props.pending]
 * @param {boolean} [props.autoScroll] automatic scrolling to the end of the list to initiate loading
 * @param {string} [props.eodText] text shown in case of reaching EOD
 * @param {React.Component} [props.eodItem] react component rendered in case of reaching EOD
 * @param {string} [props.bodText] text shown in case of reaching BOD
 * @param {React.Component} [props.bodItem] react component rendered in case of reaching BOD
 */

function ScrollList(props) {
	const { next, previous, pending, autoScroll } = props;

	const box = useRef(null);
	const scrollState = useRef({ value: 0 });
	//
	const isEod = useRef(true);
	const isBod = useRef(true);
	isEod.current = props.isEod != null && props.isEod;
	isBod.current = props.isBod != null && props.isBod;
	//
	const triggeredAction = useRef(null);

	let previousLoader = triggeredAction.current == 'previous' && pending
		? (<ListItem className="loader"><div><Loader /></div></ListItem>)
		: null
	;
	let nextLoader = triggeredAction.current == 'next' && pending
		? (<ListItem className="loader"><div><Loader /></div></ListItem>)
		: null
	;
	const eodIndicator = isEod && (props.eodText || props.eodItem)
		? props.eodItem
			? React.createElement(props.eodItem)
			: props.eodText && <ListItem className="indicator">{props.eodText}</ListItem>
		: null
	;
	const bodIndicator = isBod && (props.bodText || props.bodItem)
		? props.bodItem
			? React.createElement(props.bodItem)
			: props.bodText && <ListItem className="indicator">{props.bodText}</ListItem>
		: null
	;

	useEffect(() => {
		if (pending) {
			const loader = triggeredAction.current == 'next'
				? box.current.childNodes[box.current.childNodes.length-1]
				: box.current.childNodes[0]
			;
			box.current.scrollTop += (triggeredAction.current == 'next' ? 1 : -1) * loader.clientHeight;
		} else {
			triggeredAction.current = null;
		}
		if (autoScroll) {
			box.current.scrollTop = box.current.scrollHeight - box.current.clientHeight;
		}
	});

	useEffect(() => {
		const triggerNext = () => {
			if (next && !isEod.current) {
				triggeredAction.current = 'next';
				next();
			}
		};
		const triggerPrevious = () => {
			if (previous && !isBod.current) {
				triggeredAction.current = 'previous';
				previous();
			}
		};
		const handleScrolled = (event) => {
			const prevTopAt = scrollState.current.value;
			const topAt = event.target.scrollTop;
			const bottomAt = topAt + event.target.clientHeight;
			const topThreshold = 1;
			const bottomThreshold = event.target.scrollHeight - 1;

			if (topAt < prevTopAt) { // scrolling top
				if (topAt <= topThreshold) {
					triggerPrevious();
				}
			} else { // scrolling bottom
				if (bottomAt >= bottomThreshold) {
					triggerNext();
				}
			}

			if (topAt < 1) {
				event.target.scrollTop = 1;
			}

			scrollState.current = { value: topAt };
		};
		box.current.addEventListener('scroll', handleScrolled);
		return () => {
			box.current.removeEventListener('scroll', handleScrolled);
		};
	}, []);

	return (
		<div ref={box} id={props.id} className={className('x-list', 'scroll-list', fromProps(props))}>
			{previousLoader || bodIndicator}
			{props.children}
			{nextLoader || eodIndicator}
		</div>
	);
}

export default ScrollList;