import { DispatchWithoutAction, useEffect, useState } from 'react';

import { IssueSeverity } from 'constants/issues';
import { alert, warning } from 'assets/audio';

const REMOVAL_TIME = 300;
const CLOSE_TIME = 10000;

export const useNotification = (isSeverePlaying: boolean, onClose: DispatchWithoutAction, severity: IssueSeverity) => {
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const isSevere = severity === IssueSeverity.Severe;
  const isAutoClose = severity === IssueSeverity.Notice;

  const closeNotification = () => setIsClosing(true);

  const playNonSevereSound = async () => {
    if (!isSevere && !isSeverePlaying) {
      try {
        const soundFile = severity === IssueSeverity.Alert ? alert : warning;
        const sound = new Audio(soundFile);

        await sound.play();
      } catch (error) {
        setError(error?.message || 'error');
      }
    }
  };

  useEffect(() => {
    playNonSevereSound();
  }, []);

  useEffect(() => {
    if (isClosing) {
      const timeoutId = setTimeout(onClose, REMOVAL_TIME);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isClosing, onClose]);

  useEffect(() => {
    if (isAutoClose) {
      const timeoutId = setTimeout(closeNotification, CLOSE_TIME);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isAutoClose]);

  return { isClosing, isSevere, closeNotification };
};
