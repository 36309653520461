import styled from 'styled-components';

import { Colors } from 'ui/constants/colors';

export const TableFooterStyled = styled.div`
  padding: 0 20px 42px 22px;
`;

export const TableFooterActionsStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 18px;
`;

export const TableFooterTextStyled = styled.div`
  font: bold 12px Muli;
  line-height: 18px;
  color: ${Colors.DoveGray};
`;

export const TableFooterSelectStyled = styled.div`
  width: 138px;
`;
