import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import Toggle from '../../../general/form/Toggle';
import { actions } from '../../../../redux/app/widgets';
import { ReduxKeyContext } from '../../../../misc/ReduxKeyContext';

/**
 * Map options control switching widgets visibility.
 */

function MapOptionWidgets() {
	const { f } = useI18n();
	const reduxKey = useContext(ReduxKeyContext);
	const config = useSelector(state => state.widgets[reduxKey] || {});
	const dispatch = useDispatch();
	const [display, setDisplay] = useState(config.display);

	useEffect(() => {
		if (config.display != display) {
			dispatch(actions.display({ domain: reduxKey, display }));
		}
	}, [display]);

	return (
		<div className="map-option map-option-widgets">
			<Toggle
				label={f('show widgets')}
				value={display}
				onChange={setDisplay}
			/>
		</div>
	);
}

export default MapOptionWidgets;
