import { EMPTY, from, merge, of, pipe } from 'rxjs';
import { filter, groupBy, ignoreElements, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { combineEpics, ofType } from "redux-observable";

import { LogLevel, rootLogger } from 'core/lib/log';
import { throttleMap } from "core/lib/rx";

import { cx } from "core/api";
import { rs } from "core/rs";

import { ActionGeneratorBuilder, deltaReducer, errorMap } from "core/redux/actions";
import { internalizePage, timestamps } from "core/redux/serializers";

import { actions as announcerActions } from 'core/redux/api/announcer';
import { actions as sessionActions } from 'core/redux/api/session';

const logger = rootLogger.logger('veta-issues').at(LogLevel.Verbose);

const defaultPageSize = 10;

export const actions = new ActionGeneratorBuilder('veta-issues')
	.subtype('hot', hot => hot.request().success('issues').fail())
	.subtype('actual', actual => actual.request().success('issues').fail())
	.subtype('mru', hot => hot.request().success('issues').fail())
	.subtype('page', page => page.request({ruleGroup: false, no: false, size: false}).success({ruleGroup: false, page: true}).fail())
	.subtype('retrieve', retrieve => retrieve.request({issueId: true}).success({issue: true}).fail())
	.subtype('operation', operation => operation
		.type('receive', {issueId: true})
		.type('resolve', {issueId: true, resolution: true})
		.success({issueId: true}).fail({issueId: true, errorMessage: true})
	)
	.build()
;

const defaultState = {
	hot: []
	, mru: {}
	, actual: {}
	, ruleGroup: null
	, page: null
	, pending: false
	, error: null
	, operations: {}
};

export const reducer = deltaReducer((state, action) => {
	switch (action.type) {
		case actions.hot.success.type: return {
			hot: action.issues
		};
		case actions.mru.success.type: return {
			mru: Object.fromEntries(action.issues.map(issue => [issue.deviceURI, issue]))
		};
		case actions.actual.success.type: return {
			actual: Object.fromEntries(action.issues.map(issue => [issue.deviceURI, issue]))
		};
		case actions.page.request.type: return {
			pending: true
			, error: null
		};
		case actions.page.success.type: return {
			pending: false
			, page: action.page
			, ruleGroup: action.ruleGroup
		};
		case actions.page.fail.type: return {
			pending: false
			, error: action.errorMessage
		};
		case actions.retrieve.success.type: return state.page == null ? null : {
			page: {
				...state.page
				, objects: state.page.objects.map(issue => issue.id == action.issue.id ? action.issue : issue)
			}
		};
		case actions.operation.receive.type:
		case actions.operation.resolve.type: return {
			operations: {...state.operations, [action.issueId]: {
				pending: true
				, error: null
			}}
		};
		case actions.operation.success.type: return {
			operations: {...state.operations, [action.issueId]: {
				pending: false
			}}
		};
		case actions.operation.fail.type: return {
			operations: {...state.operations, [action.issueId]: {
				pending: false
				, error: action.errorMessage
			}}
		};
	}
	return null;
}, defaultState);

const internalize = timestamps(['registeredAt', 'endedAt', 'receivedAt', 'resolvedAt']).internalize;

const announceAffects = (state$, changeAffects, or = announcement => EMPTY) => pipe(
	mergeMap(action => from(action.announcements).pipe(
		mergeMap(announcement => merge(
			of(announcement).pipe(
				filter(announcement => cx.o.typeOf(announcement, cx.ods.veta.NewAirsideIssueAnnouncement))
			)
			, of(announcement).pipe(
				filter(announcement => cx.o.typeOf(announcement, cx.ods.veta.AirsideIssueChangeAnnouncement))
				, withLatestFrom(state$.pipe(map(state => state.veta.issues)))
				, filter(([announcement, state]) => changeAffects(announcement, state))
			)
			, or(announcement)
		))
		, take(1)
	))
);

const hotEpic = combineEpics(
	action$ => action$.pipe(
		ofType(actions.hot.request.type)
		, throttleMap(() => rs(`veta/issues/hot`).defer$().pipe(
			map(issues => issues.map(internalize))
			, map(issues => actions.hot.success({issues}))
			, errorMap(actions.hot.fail)
		))
	)
	, action$ => action$.pipe(
		ofType(sessionActions.events.started.type)
		, map(() => actions.hot.request())
	)
	, (action$, state$) => action$.pipe(
		ofType(announcerActions.announced.type)
		, announceAffects(state$, (announcement, state) => state.hot.find(issue => issue.id == announcement.issueId) != null)
		, map(() => actions.hot.request())
	)
	, (action$, state$) => action$.pipe(
		ofType(actions.hot.success.type)
		, filter(() => logger.loggable(LogLevel.Debug))
		, withLatestFrom(state$.pipe(map(state => state.veta.issues)))
		, tap(([_, state]) => {
			logger.debug('hot issues', state.hot);
		})
		, ignoreElements()
	)
);

const mruEpic = combineEpics(
	action$ => action$.pipe(
		ofType(actions.mru.request.type)
		, throttleMap(() => rs(`veta/issues/mru`).defer$().pipe(
			map(issues => issues.map(internalize))
			, map(issues => actions.mru.success({issues}))
			, errorMap(actions.mru.fail)
		))
	)
	, (action$, state$) => action$.pipe(
		ofType(actions.mru.success.type)
		, filter(() => logger.loggable(LogLevel.Debug))
		, withLatestFrom(state$.pipe(map(state => state.veta.issues)))
		, tap(([_, state]) => {
			logger.debug('MRU issues', state.mru);
		})
		, ignoreElements()
	)
);

const actualEpic = combineEpics(
	action$ => action$.pipe(
		ofType(actions.actual.request.type)
		, throttleMap(() => rs(`veta/issues/actual`).defer$().pipe(
			map(issues => issues.map(internalize))
			, map(issues => actions.actual.success({issues}))
			, errorMap(actions.actual.fail)
		))
	)
	, action$ => action$.pipe(
		ofType(sessionActions.events.started.type)
		, map(() => actions.actual.request())
	)
	, (action$, state$) => action$.pipe(
		ofType(announcerActions.announced.type)
		, announceAffects(state$
			, (announcement, state) => Object.values(state.actual).find(issue => issue.id == announcement.issueId) != null
			, announcement => of(announcement).pipe(
				filter(announcement => cx.o.typeOf(announcement, cx.ods.veta.RunwayGuardStateChangeAnnouncement))
			)
		)
		, map(() => actions.actual.request())
	)
	, (action$, state$) => action$.pipe(
		ofType(actions.actual.success.type)
		, filter(() => logger.loggable(LogLevel.Debug))
		, withLatestFrom(state$.pipe(map(state => state.veta.issues)))
		, tap(([_, state]) => {
			logger.debug('actual issues', state.actual);
		})
		, ignoreElements()
	)
);

const pageEpic = combineEpics(
	action$ => action$.pipe(
		ofType(actions.page.request.type)
		, filter(action => action.ruleGroup == null)
		, switchMap(action => rs(`veta/issues/page`).optional('no', action.no).param('size', action.size | defaultPageSize).defer$().pipe(
			map(internalizePage(action.size | defaultPageSize, internalize))
			, map(page => actions.page.success({page}))
			, errorMap(actions.page.fail)
		))
	)
	, action$ => action$.pipe(
		ofType(actions.page.request.type)
		, filter(action => action.ruleGroup != null)
		, switchMap(action => rs(`veta/issues/ruleGroup/${action.ruleGroup}/page`).optional('no', action.no).param('size', action.size | defaultPageSize).defer$().pipe(
			map(internalizePage(action.size | defaultPageSize, internalize))
			, map(page => actions.page.success({ruleGroup: action.ruleGroup, page}))
			, errorMap(actions.page.fail)
		))
	)
	, (action$, state$) => action$.pipe(
		ofType(actions.page.success.type)
		, filter(() => logger.loggable(LogLevel.Debug))
		, withLatestFrom(state$.pipe(map(state => state.veta.issues)))
		, tap(([_, state]) => {
			logger.debug('%s issues page', state.ruleGroup || 'all rule groups' , state.page);
		})
		, ignoreElements()
	)
	, (action$, state$) => action$.pipe(
		ofType(announcerActions.announced.type)
		, mergeMap(action => from(action.announcements))
		, filter(announcement => cx.o.typeOf(announcement, cx.ods.veta.AirsideIssueChangeAnnouncement))
		, withLatestFrom(state$.pipe(map(state => state.veta.issues)))
		, filter(([announcement, state]) => state.page?.objects.find(issue => issue.id == announcement.issueId) != null)
		, map(([announcement]) => actions.retrieve.request({issueId: announcement.issueId}))
	)
);

const retrieveEpic = combineEpics(
	action$ => action$.pipe(
		ofType(actions.retrieve.request.type)
		, groupBy(action => action.issueId)
		, mergeMap(action$ => action$.pipe(
			throttleMap(action => rs(`veta/issues/${action.issueId}`).defer$().pipe(
				map(issue => actions.retrieve.success({issue}))
				, errorMap(actions.operation.fail)
			))
		))
	)
	, (action$, state$) => action$.pipe(
		ofType(actions.retrieve.success.type)
		, filter(() => logger.loggable(LogLevel.Debug))
		, withLatestFrom(state$.pipe(map(state => state.veta.issues)))
		, tap(([action, state]) => {
			logger.debug('%d retrieved, %s issues page', action.issue.id, state.ruleGroup || 'all rule groups' , state.page);
		})
		, ignoreElements()
	)
);

const operationEpic = combineEpics(
	action$ => action$.pipe(
		ofType(actions.operation.receive.type)
		, groupBy(action => action.issueId)
		, mergeMap(action$ => action$.pipe(
			switchMap(action => rs(`veta/issues/${action.issueId}/receive`).post().defer$().pipe(
				map(() => actions.operation.success({issueId: action.issueId}))
				, errorMap(error => actions.operation.fail({issueId: action.issueId, errorMessage: error.errorMessage}))
			))
		))
	)
	, action$ => action$.pipe(
		ofType(actions.operation.resolve.type)
		, groupBy(action => action.issueId)
		, mergeMap(action$ => action$.pipe(
			switchMap(action => rs(`veta/issues/${action.issueId}/resolve`).post(action.resolution).defer$().pipe(
				map(() => actions.operation.success({issueId: action.issueId}))
				, errorMap(error => actions.operation.fail({issueId: action.issueId, errorMessage: error.errorMessage}))
			))
		))
	)
	, (action$, state$) => action$.pipe(
		ofType(actions.operation.success.type)
		, filter(() => logger.loggable(LogLevel.Debug))
		, withLatestFrom(state$.pipe(map(state => state.veta.issues)))
		, tap(([action, state]) => {
			logger.debug('#%d issue operation done', action.issueId , state.operations[action.issueId]);
		})
		, ignoreElements()
	)
);

export const epic = combineEpics(hotEpic, mruEpic, actualEpic, pageEpic, retrieveEpic, operationEpic);
