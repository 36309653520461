import { api, rx } from '../../../api';
import { actions } from './actions';
import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, takeUntil } from 'rxjs/operators';
import { actions as sessionActions } from '../session';
import { errorMap } from '../../actions';

const defaultState = {
	typeMap: null,
	nameMap: null,
	fieldsByTypeMap: null,
	pending: false,
	error: null
};

// ---------------------------------------------------------

function reducer(state, action) {
	switch (action.type) {
		case actions.messageFields.request.type:
			state = {
				...state,
				pending: true,
				error: null
			};
			break;
		case actions.messageFields.success.type:
			state = {
				...state,
				...action.maps,
				pending: false
			};
			break;
		case actions.messageFields.fail.type:
			state = {
				...state,
				pending: false,
				error: action.errorMessage
			};
			break;
		case actions.messageFields.cancel.type:
			state = {
				...state,
				pending: false
			}
			break;
		default:
	}
	return state || defaultState;
};

// ---------------------------------------------------------

function process(messageFields) {
	const typeMap = {}, nameMap = {}, fieldsByTypeMap = {};
	messageFields.forEach(messageField => {
		fieldsByTypeMap[messageField.fieldType]
			? fieldsByTypeMap[messageField.fieldType].push(messageField)
			: fieldsByTypeMap[messageField.fieldType] = [messageField]
		;
		typeMap[messageField.fieldId] = messageField;
		nameMap[messageField.name] = messageField;
	});
	return { typeMap, nameMap, fieldsByTypeMap };
}

const loadEpic = (action$) => {
	return action$.pipe(
		ofType(actions.messageFields.request.type),
		mergeMap(action =>
			rx(api.registry.messageFields).pipe(
				map(operation => actions.messageFields.success({ maps: process(operation.response()) })),
				errorMap(actions.messageFields.fail),
				takeUntil(action$.pipe(ofType(actions.messageFields.cancel.type)))
			)
		)
	)
}

const watchSessionStartedEpic = (action$) => {
	return action$.pipe(
		ofType(sessionActions.events.started.type),
		map(action => actions.messageFields.request())
	)
}

const epic = combineEpics(loadEpic, watchSessionStartedEpic);

// ---------------------------------------------------------

export { reducer, epic };
