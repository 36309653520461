import React from 'react';
import { connect, useSelector } from 'react-redux';
import EventIcon from '../events/EventIcon';
import GalleryCards from '../../general/GalleryCards';
import { fromProps, className } from '../../../lib/className';
import { useI18n } from '../../../../i18n';
import './deviceLastEventsStatistics.scss';

/**
 * @param {Object} props
 * @param {number} props.eventType
 * @param {number} props.quantity
 */

function EventStatisticItem(props) {
	const { fc } = useI18n();
	const eventTypeMap = useSelector(state => state.registry.eventTypes.typeMap);
	const _eventType = eventTypeMap && eventTypeMap[props.eventType];
	const eventTypename = _eventType ? fc({ prefix: 'device-event', id: _eventType.name }) : null;

	return (
		<div className="event-statistic-item">
			<div className="quantity">
				{props.quantity}
			</div>
			<div className="icon">
				<EventIcon eventType={props.eventType} />
			</div>
			{_eventType
				&& <div className="name" title={eventTypename}>
					{eventTypename}
				</div>
			}
		</div>
	);
}

/**
 * @param {Object} props
 * @param {Array.<string>} props.uris
 * @param {string} props.reduxKey
 */

function DeviceLastEventsStatistics(props) {
	let content = null;
	const events = {};

	const calculate = (event) => {
		if (events[event.eventType]) {
			events[event.eventType] = events[event.eventType] + 1;
		} else {
			events[event.eventType] = 1;
		}
	}
	if (props.uris.length > 0 && props.deviceStatesMap) {
		props.uris.forEach(uri => {
			const deviceState = props.deviceStatesMap[uri];
			if (deviceState && deviceState.event) {
				calculate(deviceState.event);
			} else if (deviceState && deviceState.events) {
				deviceState.events.forEach(event => calculate(event));
			}
		});
	}

	const cards = [];

	const compare = (a, b) => {
		if (events[a] > events[b]) return -1;
		if (events[a] < events[b]) return 1;
		return 0;
	};
	Object.keys(events).sort(compare).forEach(eventType => {
		cards.push(<EventStatisticItem key={eventType} eventType={eventType} quantity={events[eventType]} />)
	});

	if (cards.length > 0) {
		const size = {
			...GalleryCards.size.xs,
			CARD_WIDTH: 80
		};
		content = (
			<GalleryCards size={size} className={className('device-last-events-statistics', fromProps(props))}>
				{cards}
			</GalleryCards>
		);
	}

	return (content);
}

export default connect((state, props) => {
	let deviceStatesMap = null;
	if (props.reduxKey == 'dashboard') {
		deviceStatesMap = state.deviceStates.map;
	} else if (props.reduxKey == 'timeMachine') {
		deviceStatesMap = state.timeMachine.state.map
	}

	return { deviceStatesMap };
})(DeviceLastEventsStatistics);