import React, { useState, useContext, useEffect, useRef } from 'react';
import { useI18n } from '../../../../../i18n';
import { cx } from '../../../../api';
import Form from '../../../general/form/Form';
import { PropertyValuesContext } from './PropertiesControl';
import { useCustomDrop } from './customDrop';
import { className } from '../../../../lib/className';
import InputPicker from '../../../general/form/InputPicker';

/**
 * @param {Object} props
 * @param {cx.ods.meta.TextProperty} props.property
 * @param {function} props.onChange
 */

function TextControl(props) {
	const { f, p } = useI18n();
	const context = useContext(PropertyValuesContext);
	const initialized = useRef(false);
	const [value, setValue] = useState(null);

	useEffect(() => {
		if (!initialized.current) {
			setValue(context && context[props.property.propertyId] && context[props.property.propertyId].value || null);
			initialized.current = true;
		}
	}, [context]);

	const onChange = (value) => {
		const propertyValue = new cx.ods.meta.ScalarValue();
		propertyValue.propertyId = props.property.propertyId;
		propertyValue.value = value;
		props.onChange(propertyValue);
		setValue(value);
	}

	const validator = () => {
		if (value != null) {
			if (props.property.minimumLength !== null && value.length < props.property.minimumLength) {
				return p('please enter a value not shorter than X symbols', props.property.minimumLength);
			}
			if (props.property.maximumLength !== null && value.length > props.property.maximumLength) {
				return p('please enter a value not longer than X symbols', props.property.maximumLength);
			}
		} else if (props.property.required) {
			return f('please enter a value');
		}
	}

	// -----------------------------------

	const [dropState, dropRef] = useCustomDrop(() => props.onRemove(props.property));
	const onRef = (element) => props.onRemove && dropRef(element);

	// -----------------------------------
	let content = null;
	if (props.property?.enumeration?.length) {
		const enumerationOptions = props.property.enumeration.map((item) => ({ label: item, value: item }));

		content = <Form.Control
			controlType={InputPicker}
			controlValidator={validator}
			data={enumerationOptions}
			value={value}
			onChange={onChange}
			label={props.property.name}
			customRef={onRef}
			className={className({ 'droppable': dropState.isOver && dropState.canDrop })}
			creatable={false}
			cleanable={false}
		/>
	} else {
		content = <Form.Control
			controlType={Form.Control.Type.Input}
			controlValidator={validator}
			value={value}
			onChange={onChange}
			label={props.property.name}
			customRef={onRef}
			className={className({ 'droppable': dropState.isOver && dropState.canDrop })}
		/>
	}

	return (content);
}

export default TextControl;
