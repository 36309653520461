import { ActionGeneratorBuilder } from '../../actions';

export const actions = new ActionGeneratorBuilder('registry')
	.subtype('eventTypes', eventTypes => eventTypes.request().success('maps').fail().cancel())
	.subtype('messageFields', messageFields => messageFields.request().success('maps').fail().cancel())
	.subtype('attributeTypes', attributeTypes => attributeTypes.request().success('maps').fail().cancel())
	.subtype('countries', countries => countries.request().success('list').fail().cancel())
	.subtype('roles', roles => roles.request().success('list').fail().cancel())
	.subtype('timeZones', timeZones => timeZones.request().success('list').fail().cancel())
	.subtype('category', category => category.request('categoryName').success({ categoryName: true, list: true }).fail({ categoryName: true, errorMessage: true }).cancel())
	.build()
;
