import { reduxSwitch } from '../../tools';
import { actions } from './actions';
import { BATCH_SIZE } from './misc';

/*
	{uri => {
		{uid => {
			head: boolean,
			recent: [EventDetails],
			list: [EventDetails],
			hasMoreForward: false,
			hasMoreBackward: false,
			pending: boolean,
			error: string
		}}
	}}
*/
const defaultState = {};

function getDefaultState() {
	return {
		head: true,
		recent: null,
		list: null,
		canMoveForward: false,
		canMoveBackward: false,
		pending: true,
		error: null
	};
}

function apiReducer(state, action) {
	let local = null;
	switch (action.type) {
		case actions.find.request.type:
			state = { ...state };
			const byUri = state[action.uri];
			const byUid = byUri && byUri[action.uid];
			if (!byUri) { // new
				state[action.uri] = {
					[action.uid]: getDefaultState()
				};
			} else if (!byUid) { // new instance
				byUri[action.uid] = getDefaultState();
			} else { // existing instance
				byUid.pending = true;
				byUid.error = null;
			}
			break;
		case actions.find.success.type:
			state = { ...state };
			local = state[action.uri][action.uid];
			local.pending = false;
			local.recent = action.list;
			break;
		case actions.find.fail.type:
			state = { ...state };
			local = state[action.uri][action.uid];
			local.pending = false;
			local.error = action.errorMessage;
			break;
		case actions.find.cancel.type: // TODO
			break;
	}
	return state;
}

function processReducer(state, action) {
	let local = null;
	switch (action.type) {
		case actions.addHeadEvent.type:
			state = { ...state };
			// here we assume that the following objects already exist
			local = state[action.uri][action.uid];
			local.list.unshift(action.event);
			break;
		case actions.addHead.type:
			state = { ...state };
			local = state[action.uri][action.uid];
			if (local.list == null) local.list = [];
			local.list = action.list.concat(local.list);
			local.canMoveForward = action.list.length == BATCH_SIZE;
			if (!local.canMoveForward) local.head = true;
			break;
		case actions.addTail.type:
			state = { ...state };
			local = state[action.uri][action.uid];
			if (local.list == null) local.list = [];
			local.list = local.list.concat(action.list);
			local.canMoveBackward = action.list.length == BATCH_SIZE;
			break;
		case actions.removeHead.type:
			state = { ...state };
			local = state[action.uri][action.uid];
			local.list.splice(0, BATCH_SIZE);
			local.canMoveForward = true;
			break;
		case actions.removeTail.type:
			state = { ...state };
			local = state[action.uri][action.uid];
			local.list.splice(-BATCH_SIZE, BATCH_SIZE);
			local.canMoveBackward = true;
			break;
	}
	return state;
}

export const reducer = reduxSwitch([apiReducer, processReducer], defaultState);
