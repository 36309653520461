import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import Toggle from '../../../general/form/Toggle';
import { actions } from '../../../../redux/app/appDeviceEvents';
import { ReduxKeyContext } from '../../../../misc/ReduxKeyContext';
import { className, fromProps } from '../../../../lib/className';

/**
 * Map options control switching widgets visibility.
 * @param {Object} props
 * @param {string} [props.label]
 */

function MapOptionDisplayEvents(props) {
	const { f } = useI18n();
	const reduxKey = useContext(ReduxKeyContext);
	const display = useSelector(state => state.appDeviceEvents[reduxKey] && state.appDeviceEvents[reduxKey].display);
	const dispatch = useDispatch();

	const onChangeDisplay = (value) => {
		dispatch(actions.setDisplayEvents({ domain: reduxKey, display: value }));
	}

	return (
		<div className={className('map-option', 'map-device-events', fromProps(props))}>
			<Toggle
				label={props.label || f('show devices events')}
				value={display}
				onChange={onChangeDisplay}
			/>
		</div>
	);
}

export default MapOptionDisplayEvents;
