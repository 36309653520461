import React from 'react';
import ReactDOM from 'react-dom';
import OlOverlay from 'ol/Overlay';
import { fromEPSG4326 } from 'ol/proj/epsg3857';
import { className, fromProps } from '../../../lib/className';
import { capitalize } from '../../../misc/misc';
import { BadgeTypes } from '../../custom/widgets/RunwayInfo';

class OwMapMarker {
	/**
	 * @param {Object} props
	 * @param {DOMElement} props.box
	 * @param {Array.<number>} props.coordinates
	 * @param {string} props.id
	 */

	constructor(box, coordinates, id) {
		this.overlay = new OlOverlay({
			element: box,
			position: fromEPSG4326(coordinates),
			positioning: 'top-left',
			id: id,
			stopEvent: false,
		});
	}

	onClick(callback) {}

	getOverlay() {
		return this.overlay;
	}

	moveTo(coordinates) {
		// EPSG:4326
		const newXy = fromEPSG4326(coordinates);
		const xy = this.overlay.getPosition();
		if (xy[0] !== newXy[0] || xy[1] !== newXy[1]) {
			this.overlay.setPosition(newXy);
		}
	}

	addToMap(map) {
		this.map = map;
		this.map.getOlMap().addOverlay(this.overlay);
	}

	destroy() {
		if (this.map) {
			this.map.getOlMap().removeOverlay(this.overlay);
		}
		this.overlay = null;
	}
}

class MapMarker extends React.Component {
	/**
	 * @param {Object} props
	 * @param {OwMap} props.map
	 * @param {Array.<number>} props.coordinates [decimal, decimal] -- (lon, lat)
	 * @param {string} props.id
	 * @param {string} [props.className]
	 * @param {function} [props.onClick]
	 * @param {Object} [props.params]
	 * @param {Object} [props.properties]
	 * @param {Object} [props.bodyStyle]
	 * @param {string} [props.title]
	 * @param {string} [props.label]
	 * @param {React.Component} [props.customElement]
	 */

	constructor(props) {
		super(props);
		this.state = {
			mounted: false,
		};
	}

	setZIndex = (zIndex) => {
		if (this.marker) this.marker.getOverlay().getElement().parentNode.style.zIndex = zIndex;
		else console.log('setZIndex, no marker', this);
	};

	getDomBox() {
		return this.marker.getOverlay().getElement();
	}

	createLabel(text) {
		this.label = document.createElement('div');
		this.label.classList.add('marker-label');
		this.label.appendChild(document.createElement('div'));
		this.updateLabel(text);
		return this.label;
	}

	decorate(props) {
		const first = this.box.firstChild;
		while (this.box.firstChild) {
			this.box.removeChild(this.box.firstChild);
		}
		this.box.appendChild(first);

		if (
			props.params?.badgeType === BadgeTypes.InOpenedRunwayRed ||
			props.params?.badgeType === BadgeTypes.InTaxiwayRed ||
			props.params?.badgeType === BadgeTypes.InClosedRunwayRed
		) {
			if (
				props.params?.badgeType === BadgeTypes.InClosedRunwayRed ||
				props.params?.badgeType === BadgeTypes.InOpenedRunwayRed
			) {
				this.badge = document.createElement('div');
				this.badge.classList.add('marker-badge');
				this.badge.classList.add('red-badge');
				this.badge.appendChild(document.createElement('span'));
				props.params?.badgeType === BadgeTypes.InOpenedRunwayRed
					? (this.badge.children[0].innerText = 'RWY')
					: (this.badge.children[0].innerText = 'REP');
				this.box.appendChild(this.badge);
			}

			this.box.classList.add('red-badge');
			this.box.children[0].classList.add('red-badge');
		}

		if (
			props.params?.badgeType === BadgeTypes.NearOpenedRunwayGreen ||
			props.params?.badgeType === BadgeTypes.InOpenedRunwayGreen ||
			props.params?.badgeType === BadgeTypes.InClosedRunwayGreen ||
			props.params?.badgeType === BadgeTypes.InTaxiwayGreen
		) {
			if (
				props.params?.badgeType === BadgeTypes.InOpenedRunwayGreen ||
				props.params?.badgeType === BadgeTypes.InClosedRunwayGreen
			) {
				this.badge = document.createElement('div');
				this.badge.classList.add('marker-badge');
				this.badge.classList.add('green-badge');

				this.badge.appendChild(document.createElement('span'));
				props.params?.badgeType === BadgeTypes.InOpenedRunwayGreen
					? (this.badge.children[0].innerText = 'RWY')
					: (this.badge.children[0].innerText = 'REP');
				this.box.appendChild(this.badge);
			}
			this.box.classList.add('green-badge');
			this.box.children[0].classList.add('green-badge');
		}

		if (
			props.params?.badgeType === BadgeTypes.NearOpenedRunwayOrange ||
			props.params?.badgeType === BadgeTypes.InOpenedRunwayOrange ||
			props.params?.badgeType === BadgeTypes.InClosedRunwayOrange
		) {
			this.badge = document.createElement('div');
			this.badge.classList.add('marker-badge');
			this.badge.classList.add('orange-badge');

			if (
				props.params?.badgeType === BadgeTypes.InOpenedRunwayOrange ||
				props.params?.badgeType === BadgeTypes.InClosedRunwayOrange
			) {
				this.badge.appendChild(document.createElement('span'));
				props.params?.badgeType === BadgeTypes.InOpenedRunwayOrange
					? (this.badge.children[0].innerText = 'RWY')
					: (this.badge.children[0].innerText = 'REP');
				this.box.appendChild(this.badge);
			}

			this.box.classList.add('orange-badge');
			this.box.children[0].classList.add('orange-badge');
		}
		if (props.params?.badgeType !== undefined && props.params?.isAlerting) {
			this.icon = document.createElement('span');
			this.icon.classList.add('unresolved-issue-icon');
			this.box.appendChild(this.icon);
		}
		if (!props.params?.isAlerting || props.params?.badgeType === null) {
			if (this.box.getElementsByClassName('unresolved-issue-icon')[0]) {
				this.box.removeChild(this.box.getElementsByClassName('unresolved-issue-icon')[0]);
			}
		}
		if (this.props.map?._displayMarkerLabels) {
			this.box.appendChild(this.createLabel(this.props.label));
			return;
		}
		if (this.box.getElementsByClassName('marker-label')[0]) {
			this.box.removeChild(this.box.getElementsByClassName('marker-label')[0]);
		}
	}

	updateLabel(text) {
		if (!this.label && text) this.box.appendChild(this.createLabel(text));
		else {
			if (text) this.label.children[0].innerText = text;
			else {
				this.box.removeChild(this.label);
				this.label = null;
			}
		}
	}

	actualizeClassName(props) {
		this.box.className = className('marker', fromProps(props));
		this.decorate(props);
	}

	createMarkerDom(props) {
		this.box = document.createElement('div');
		this.body = this.box.appendChild(document.createElement('div'));
		this.updateMarkerDom(props);
		return this.box;
	}

	updateMarkerDom(props) {
		this.box.title = capitalize(props.title);
		if (this.hasLabel(props)) this.box.appendChild(this.createLabel(props.label));
		else {
			if (this.label) this.box.removeChild(this.label);
		}
		this.actualizeClassName(props);
		this.hangOnClick(props);
	}

	hangOnClick(props) {
		if (this.prevOnClick) this.box.removeEventListener('click', this.prevOnClick);
		if (props.onClick != null) this.box.addEventListener('click', props.onClick);
		this.prevOnClick = props.onClick;
	}

	installMarker(props) {
		if (!this.marker) {
			const box = this.createMarkerDom(props);
			this.marker = new OwMapMarker(box, props.coordinates, props.id);
			this.marker.addToMap(props.map);
			const overlay = this.marker.getOverlay();
			overlay.set('displayLabel', this.displayLabel.bind(this));
			this.decorate(props);
			this.hangOnClick(props);
		} else this.updateMarkerDom(props);
	}

	displayLabel() {
		this.installMarker(this.props);
	}

	hasLabel(props) {
		return props.label && props.map.isDisplayedMarkerLabels();
	}

	render() {
		if (this.marker) this.actualizeClassName(this.props);
		if (!this.marker) this.installMarker(this.props, this.hasLabel(this.props));
		const overlay = this.marker.getOverlay();
		if (this.props.params) {
			overlay.autoPan = this.props.params.autoPan;
			overlay.autoPanMargin = this.props.params.autoPanMargin;
		}
		if (this.props.properties) overlay.setProperties(this.props.properties);
		this.marker.moveTo(this.props.coordinates);
		this.props.bodyStyle &&
			Object.keys(this.props.bodyStyle).forEach((name) => {
				this.body.style[name] = this.props.bodyStyle[name];
			});
		this.hangOnClick(this.props);
		if (this.marker && this.props.customElement) {
			return ReactDOM.createPortal(
				<div className="custom-element-map-marker">{this.props.customElement}</div>,
				this.marker.getOverlay().getElement().parentNode,
			);
		} else return null;
	}

	componentDidMount() {
		this.setState({ mounted: true });
		this.actualizeClassName(this.props);
	}

	shouldComponentUpdate(nextProps, nextState) {
		let update =
			this.props.coordinates[0] != nextProps.coordinates[0] || this.props.coordinates[1] != nextProps.coordinates[1];
		if (!update) update = this.state.mounted != nextState.mounted;
		if (!update) update = this.props.id != nextProps.id;
		if (!update) update = this.props.className != nextProps.className;
		if (!update) update = this.props.label != nextProps.label;

		if (!update && !!this.props.params != !!nextProps.params) {
			const nextPropKeys = Object.keys(nextProps.params);
			update = Object.keys(this.props.params).length != nextPropKeys.length;
			if (!update) update = nextPropKeys.some((key) => nextProps.params[key] != this.props.params[key]);
		}

		if (!update) update = this.props.params?.badgeType !== nextProps.params?.badgeType;
		if (!update) update = this.props.params?.isAlerting !== nextProps.params?.isAlerting;

		return update;
	}

	componentDidUpdate(prevProps) {
		if (this.props.id != prevProps.id) this.installMarker(this.props, this.hasLabel(this.props));
		if (this.props.className != prevProps.className) this.actualizeClassName(this.props);
		if (this.hasLabel(this.props) && this.props.label != prevProps.label) this.updateLabel(this.props.label);
	}

	componentWillUnmount() {
		if (this.marker) {
			this.marker.destroy();
			this.marker = null;
		}
	}
}

export default MapMarker;
