import { useIntl } from 'react-intl';
import { intl } from '../I18nApp';
import { capitalize } from '../core/misc/misc';

export function useI18n() {
	const { messages, formatMessage, formatPlural  } = useIntl();

	const i18n = {
		f: (messageId, values) => {
			const composite = typeof messageId == 'object';
			const id = composite ? (messageId.prefix + '.' + messageId.id) : messageId;
			return messages[id]
				? formatMessage({ id }, values)
				: (composite ? messageId.id : messageId)
			;
		},
		p: (messageId, value, value2) => {
			const composite = typeof messageId == 'object';
			const pluralType = formatPlural(value);
			const id = composite ? (messageId.prefix + '.' + messageId.id) : messageId;
			return messages[id]
				? formatMessage({ id }, { type: pluralType, value, value2 })
				: (composite ? messageId.id : messageId)
			;
		},
		fc: function () {
			return capitalize(i18n.f.apply(null, arguments));
		},
		pc: function () {
			return capitalize(i18n.p.apply(null, arguments));
		}
	};

	return i18n;
}

/**
 * Internationalizes subject with react-intl formatMessage.
 * @param {string|object} messageId string | { prefix, id }
 * @param {object} values
 * @returns internationalized message or message id w/o prefix if translation is missing.
 */

export const f = (messageId, values) => {
	const composite = typeof messageId == 'object';
	const id = composite ? (messageId.prefix + '.' + messageId.id) : messageId;
	return intl.messages[id]
		? intl.formatMessage({ id }, values)
		: (composite ? messageId.id : messageId)
	;
}

/**
 * Internationalizes subject with react-intl formatPlural.
 * @param {string|object} messageId
 * @param {string|number} param
 * @returns internationalized message or message id w/o prefix if translation is missing from translation file.
 */

export const p = (messageId, value) => {
	const composite = typeof messageId == 'object';
	const pluralType = intl.formatPlural(value);
	const id = composite ? (messageId.prefix + '.' + messageId.id) : messageId;
	const result = intl.messages[messageId]
		? intl.formatMessage({ id }, { type: pluralType, value })
		: (composite ? messageId.id : messageId)
	;
	return result;
}

export const fc = (subject, values) => {
	return capitalize(f(subject, values));
}

export const pc = (subject, param) => {
	return capitalize(p(subject, param));
}