import React from 'react';
import { className, fromProps } from '../../../lib/className';

/**
 * @param {Object} props
 * @param {function} [props.onClick]
 * @param {boolean} [props.disabled]
 */

function MenuItem(props) {

	const onClick = (event) => {
		if (!props.disabled && props.onClick) {
			props.onClick(event);
		}
	}

	return (
		<div
			className={className('item', fromProps(props), { 'disabled': props.disabled })}
			onClick={onClick}
		>
			{props.children}
		</div>
	)
}

export default MenuItem;