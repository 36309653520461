import React from 'react';
import { useI18n } from '../../../../i18n';
import { DeviceDetailsProxy } from '../../../api/device'; // eslint-disable-line
import { className, fromProps } from '../../../lib/className';
import './deviceIdentityView.scss';

/**
 * @param {Object} props
 * @param {DeviceDetailsProxy} props.device
 */

function DeviceIdentityView(props) {
	const { f } = useI18n();
	const { device } = props;
	const type = device.identityType;
	let content = null;
	switch (type) {
		case 1: // imei
			content = (
				<div>
					<div className="label">{f('device IMEI')}</div>
					<div className="">{device.imei}</div>
				</div>
			);
			break;
		case 2: // uuid
			content = (
				<div>
					<div className="label">{f('device UUID')}</div>
					<div className="">{device.uuid}</div>
				</div>
			);
			break;
		case 3: // umm
			content = (<>
				<div>
					<div className="label">{f('device UUID')}</div>
					<div className="">{device.uuid}</div>
				</div>
				<div>
					<div className="label">{f('major')}</div>
					<div className="">{device.major}</div>
				</div>
				<div>
					<div className="label">{f('minor')}</div>
					<div className="">{device.minor}</div>
				</div>
			</>);
			break;
		default:
			content = (
				<div>
					<div className="label">{f('device ID')}</div>
					<div className="">{device.identifier}</div>
				</div>
			);
		// case 4: // VA serial number
		// case 5: // sigfox serial
		// case 6: // bluetooth MAC
		// case 7: // sinocastel serial number
	}

	return (
		<div className={className('device-identity', fromProps(props))}>
			{content}
		</div>
	);
}

export default DeviceIdentityView;