import React, { useRef, useEffect, useContext, useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import LayerGroup from 'ol/layer/Group';
import { extend } from 'ol/extent';
import { createEmpty as emptyExtent, isEmpty as isEmptyExtent } from 'ol/extent';
import { Draw, Modify, Snap } from 'ol/interaction';
import * as condition from 'ol/events/condition';
import Collection from 'ol/Collection';
import { cx } from '../../../../api';
import { isRightMouseButton, isEscapeButton, isDeleteButton } from '../../../../misc/misc';
import { Map } from '../../../general/location/Map';
import { DragItemType } from '../../../share/dnd/DragItemType';
import { SelectedFeatureContext } from './ZoneEditor';
import { readMultiPolygon } from '../../../../misc/wkt';
import { setProps } from '../../../../misc/ol';
import Zone from '../model/Zone'; // eslint-disable-line no-unused-vars
import MapControls from 'core/react/custom/map/controls/MapControls';
import MapOptions from 'core/react/custom/map/controls/MapOptions';
import { makeStyle, StyleType, ZIndex } from '../zoneStyle';
import GeometryType from 'ol/geom/GeometryType';
import { GeoJsonLayerSwitcher } from 'core/react/custom/dashboard/map/GeoJsonLayerSwitcher';
import { useGeoJsonLayers } from 'hooks/map/useGeoJsonLayers';
import { ZONES_MAP_NAME } from 'constants/map';

const MAP_NAME = "ZoneEditMap";

/**
 * @param {Object} props
 * @param {Zone} props.zone
 * @param {React.RefObject} [props.customRef]
 */

function ZoneEditMap(props) {
	const map = useRef(null);
	const backgroundLayer = useRef(null); // contains all features except of current zone
	const foregroundLayer = useRef(null); // contains features of current zone
	const group = useRef(null);
	const hoveredFeature = useRef(null);
	const selectedFeatures = useRef(new Collection());

	const { geoJsonLayersOptions, selectedGeoJsonLayers, onGeoJsonLayerSelect } = useGeoJsonLayers(
		ZONES_MAP_NAME,
		map.current,
	);

	// ------------------------------

	const selectedFeatureContext = useContext(SelectedFeatureContext);
	const zones = props.zones.map;
	const zoneId = props.zone.id();

	// interactions

	const isSelectActive = useRef(true);
	const draw = useRef(null);
	const snap = useRef(null);
	const modify = useRef(null);

	// ------------------------------

	const focus = (features) => {
		if (!features) return;
		let extent = new emptyExtent();
		features.forEach(feature => {
			extend(extent, feature.getGeometry().getExtent());
		});
		if (!isEmptyExtent(extent)) {
			map.current.getOwMap().fitExtent(extent);
		} else {
			map.current.getOwMap().focus();
		}
	}

	const updateFeatures = (_feature) => {
		foregroundLayer.current.getSource().getFeatures().forEach(feature => {
			if (_feature && _feature == feature || selectedFeatures.current.item(0) == feature) {
				feature.setStyle(makeStyle(StyleType.Modifying));
			} else {
				feature.setStyle(makeStyle(StyleType.Selected));
			}
		});
	}

	// interactions

	const resetInteractions = () => {
		isSelectActive.current = false;
		modify.current.setActive(false);
		draw.current.setActive(false);
		snap.current.setActive(false);
	}

	const setupModifyInteraction = () => {
		resetInteractions();
		modify.current.setActive(true);
		snap.current.setActive(true);
		isSelectActive.current = true;
	}

	const setupSelectInteraction = () => {
		resetInteractions();
		isSelectActive.current = true;
	}

	const handleAddFeature = (event) => {
		foregroundLayer.current.getSource().un('addfeature', handleAddFeature);
		const feature = event.feature;
		props.zone.createArea(feature);
		snap.current.addFeature(feature);
		selectedFeatureContext.set(feature.getId());
	}

	const setupDrawInteraction = () => {
		resetInteractions();
		draw.current.setActive(true);
		snap.current.setActive(true);
		foregroundLayer.current.getSource().on('addfeature', handleAddFeature);
	}

	// -----------------------------

	useEffect(() => {
		map.current.updateSize();
		const onKeydown = (event) => {
			if (!draw.current.getActive()) return;
			if (isEscapeButton(event)) setupSelectInteraction();
			else if (isDeleteButton(event)) draw.current.removeLastPoint();
		}
		document.addEventListener('keydown', onKeydown);
		const onContextMenu = (event) => {
			if (!modify.current.getActive()) return true;
			event.preventDefault();
			return false;
		}
		map.current.getDomBox().addEventListener('contextmenu', onContextMenu);
		return () => {
			document.removeEventListener('keydown', onKeydown);
			map.current.getDomBox().removeEventListener('contextmenu', onContextMenu);
		}
	}, []);

	useEffect(() => {
		const onClick = (event) => {
			if (!isSelectActive.current) return;
			const features = map.current.getOlMap().getFeaturesAtPixel(event.pixel, {
				layerFilter: (layer) => layer == foregroundLayer.current
			});
			const hasFeatures = Array.isArray(features) && features.length > 0;
			const selectedFeature = selectedFeatures.current.item(0);
			if (!hasFeatures && !selectedFeature || hasFeatures && selectedFeature == features[0]) return;
			if (hasFeatures) {
				const feature = features[0];
				selectedFeatureContext.set(feature.getId());
			} else {
				selectedFeatureContext.clear();
			}
		}
		map.current.getOlMap().on('click', onClick);
		const handleAreaRemove = (area) => {
			foregroundLayer.current.getSource().removeFeature(area.feature());
		}
		props.zone.addObserver(props.zone.events.areaWillRemove, handleAreaRemove);
		const handeZoneChanged = () => {
			updateFeatures();
		}
		props.zone.addObserver(props.zone.events.changed, handeZoneChanged);
		return () => {
			map.current.getOlMap().un('click', onClick);
			props.zone.removeObserver(props.zone.events.areaWillRemove, handleAreaRemove);
			props.zone.removeObserver(props.zone.events.changed, handeZoneChanged);
		}
	}, [zoneId]);

	useEffect(() => {
		if (zones) {
			const bgFeatures = [];
			const fgFeatures = [];
			Object.values(zones).forEach(zone => {
				const selected = zone.zoneId == zoneId;
				const features = selected
					? props.zone.features()
					: setProps(readMultiPolygon(zone.geometry), {
						zoneId: zone.zoneId,
						name: zone.name,
						style: zone.style
					})
				;
				features.forEach(feature => {
					feature.setStyle(selected
						? makeStyle(StyleType.Selected)
						: makeStyle(StyleType.Default)
					);
				});
				if (!selected) {
					bgFeatures.push(...features);
				} else {
					fgFeatures.push(...features);
				}
			});
			if (props.zone.virtual()) {
				props.zone.features().forEach(feature => {
					feature.setStyle(feature.getId() == props.areaId
						? makeStyle(StyleType.Selected)
						: makeStyle(StyleType.Modifying)
					);
					fgFeatures.push(feature);
				});
			}
			backgroundLayer.current = new VectorLayer({
				source: new VectorSource({ features: bgFeatures })
			});
			foregroundLayer.current = new VectorLayer({
				source: new VectorSource({ features: fgFeatures }),
				zIndex: ZIndex.Selected
			});
			group.current = new LayerGroup({
				layers: new Collection([backgroundLayer.current, foregroundLayer.current])
			});
			map.current.getOlMap().addLayer(group.current);
			focus(foregroundLayer.current.getSource().getFeatures());
			// interactions
			draw.current = new Draw({
				source: foregroundLayer.current.getSource(),
				condition: (event) => {
					if (isRightMouseButton(event.originalEvent)) {
						draw.current.removeLastPoint();
						return false;
					}
					return condition.primaryAction(event);
				},
				type: GeometryType.POLYGON
			});
			modify.current = new Modify({
				features: selectedFeatures.current,
				condition: (event) => {
					return condition.primaryAction(event);
				},
				deleteCondition: function (event) {
					if (isRightMouseButton(event.originalEvent)) {
						const coords = selectedFeatures.current.item(0).getGeometry().getCoordinates();
						if (coords.find(coord => {
							if (coord.length < 5) return false; // last element equals first element
							const at = coord.findIndex(c => c[0] == event.coordinate[0] && c[1] == event.coordinate[1]);
							if (at >= 0) {
								coord.splice(at, 1);
								if (at == 0) {
									coord.pop();
									coord.push(coord[0]);
								}
								return true;
							}
							return false;
						})) {
							selectedFeatures.current.item(0).getGeometry().setCoordinates(coords);
						}
					}
					return false;
				},
			});
			snap.current = new Snap({ features: new Collection(bgFeatures.concat(fgFeatures)) });
			map.current.getOlMap().addInteraction(draw.current);
			map.current.getOlMap().addInteraction(modify.current);
			map.current.getOlMap().addInteraction(snap.current);
			setupSelectInteraction();
		}
		return () => {
			map.current.getOlMap().removeLayer(group.current);
			map.current.getOlMap().removeInteraction(modify.current);
			map.current.getOlMap().removeInteraction(draw.current);
			map.current.getOlMap().removeInteraction(snap.current);
			resetInteractions();
			group.current = null;
			foregroundLayer.current = null;
			backgroundLayer.current = null;
			draw.current = null;
			modify.current = null;
			snap.current = null;
		};
	}, [zones])

	useEffect(() => {
		if (selectedFeatureContext.value) {
			// const feature = foregroundLayer.current.getSource().getFeatureById(selectedFeatureContext.value);
			const area = props.zone.area(selectedFeatureContext.value)
			selectedFeatures.current.clear();
			selectedFeatures.current.push(area.feature());
			setupModifyInteraction();
		} else {
			selectedFeatures.current.clear();
		}
		updateFeatures();
	}, [selectedFeatureContext.value]);

	useImperativeHandle(props.customRef, () => ({
		addFeature: () => {
			selectedFeatureContext.clear();
			setupDrawInteraction();
		}
	}));

	// ------------------drop actions-------------------

	const getMapPixel = (monitor) => {
		const mapOffset = cx.dom.at.client(map.current.getDomBox());
		const dropOffset = monitor.getClientOffset();
		return [dropOffset.x - mapOffset.left, dropOffset.y - mapOffset.top];
	}

	const onDrop = (pixel, item) => {
		const features = map.current.getOlMap().getFeaturesAtPixel(pixel);
		if (Array.isArray(features) && features.length > 0) {
			if (item.type == DragItemType.ACTION_REMOVE) {
				props.zone.removeArea(features[0].getId());
			} else if (item.type == DragItemType.ACTION_EDIT) {
				const feature = features.find(feature =>
					feature.getProperties().zoneId == zoneId
				);
				if (feature != null) {
					selectedFeatureContext.set(feature.getId(), true);
				}
			}
		}
	}

	const onHover = (pixel) => {
		const features = map.current.getOlMap().getFeaturesAtPixel(pixel);
		const feature = features && features[0];
		if (feature != hoveredFeature.current) {
			hoveredFeature.current = feature;
			updateFeatures(feature);
		}
	}

	const [, dropRef] = useDrop({
		accept: [DragItemType.ACTION_EDIT, DragItemType.ACTION_REMOVE],
		drop: (item, monitor) => onDrop(getMapPixel(monitor), item),
		hover: (item, monitor) => onHover(getMapPixel(monitor)),
	});

	// ----------------------------------------------

	/*
	const focusExtent = (extent) => {
		map.current.getOwMap().fitExtent(extent);
	}
	*/

	return (
		<div className="zone-edit-map" ref={dropRef}>
			<Map name={MAP_NAME} ref={map} baseLayer={Map.Layers.ONE} />
			<MapControls>
				<MapOptions>
					<GeoJsonLayerSwitcher
						value={selectedGeoJsonLayers}
						onChange={onGeoJsonLayerSelect}
						options={geoJsonLayersOptions}
					/>
				</MapOptions>
			</MapControls>
		</div>
	);
}

export default connect(state => {
	return {
		zones: state.zones
	}
})(ZoneEditMap);
