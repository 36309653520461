import { useFlexLayout, useTable } from 'react-table';

import { ITableColumn } from 'types/table';

interface IUseTableHookProps {
  isLoading: boolean;
  columns: ITableColumn[];
  data: Record<string, unknown>[];
}

export const useTableHook = (props: IUseTableHookProps) => {
  const { isLoading, columns, data } = props;

  const defaultColumn = { width: 150 };
  const tableOptions = { columns, data, defaultColumn, autoResetSelectedRows: false };

  const tableMethods = useTable(tableOptions, useFlexLayout);

  const { getTableProps, headerGroups, rows, prepareRow } = tableMethods;

  const tableContentProps = { isLoading, data, rows, prepareRow };

  return { headerGroups, tableContentProps, getTableProps };
};
