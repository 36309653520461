import React, { FC } from 'react';

import { ColumnHeader } from './column-header';
import { IHeaderGroup } from 'types/table';
import { TableHeaderStyled } from 'ui/table.styled';

interface IHeaderColumnProps {
  headerGroups: IHeaderGroup[];
}

export const TableHeader: FC<IHeaderColumnProps> = ({ headerGroups, ...rest }) => (
  <TableHeaderStyled>
    {headerGroups.map((headerGroup) => (
      <div {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <ColumnHeader key={column.id} column={column} {...rest} />
        ))}
      </div>
    ))}
  </TableHeaderStyled>
);
