import React, { useState, useEffect } from 'react';
import AttributeRangeDetectorPicker from './AttributeRangeDetectorPicker';
import Form from '../../general/form/Form';
import { className, fromProps } from '../../../lib/className';

/**
 * @param {Object} props
 * @param {number} props.value
 * @param {string} props.controlName
 * @param {string} props.label
 * @param {string} props.placeholder
 * @param {string} props.menuClassName
 */
function AttributeRangeDetectorControl(props) {
	const [value, setValue] = useState(props.value);

	useEffect(() => {
		setValue(props.value);
	}, [props]);

	return (
		<Form.Control
			controlType={AttributeRangeDetectorPicker}
			controlName={props.controlName || "processorId"}
			className={className(fromProps(props))}
			menuClassName={props.menuClassName}
			cleanable={false}
			value={value}
			onChange={setValue}
			label={props.label}
			placeholder={props.placeholder}
		/>
	);
}

export default AttributeRangeDetectorControl;