import { ofType, combineEpics } from "redux-observable";
import { switchMap, mergeMap, catchError, map as rxmap, takeUntil, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { rx, api } from "../../api";
import { actions as latestEventsActions } from "./latestEvents";
import { ActionGeneratorBuilder } from "../actions";

/*
	{uri => {
		{uid => {
			digest: cx.ods.devices.DeviceDigest,
			pending: boolean,
			error: string
		}}
	}}
*/
const defaultState = {};
const defaultSubstate = {
	digest: null,
	filter: null,
	pending: false,
	error: null
};

const actions = new ActionGeneratorBuilder('deviceDigest')
	.subtype(
		'filter',
		filter => filter
			.request({ uri: true, uid: true, filter: true })
			.success({ uri: true, uid: true, digest: true })
			.fail({ uri: true, uid: true, errorMessage: true })
			.cancel()
	)
	.type('clear', { uri: true, uid: true })
	.build()
;

const reducer = (state = defaultState, action) => {
	let copy = null;
	let instance = null;
	switch (action.type) {
		case actions.filter.request.type:
			copy = { ...state };
			if (copy[action.uri] == null) {
				copy[action.uri] = { [action.uid]: { ...defaultSubstate } };
			}
			if (copy[action.uri][action.uid] == null) {
				copy[action.uri][action.uid] = { ...defaultSubstate };
			}
			instance = copy[action.uri][action.uid];
			instance.filter = action.filter;
			instance.pending = true;
			instance.error = null;
			break;
		case actions.filter.success.type:
			copy = { ...state };
			copy[action.uri] = {
				...copy[action.uri],
				[action.uid]: {
					...copy[action.uri][action.uid],
					digest: action.digest,
					pending: false
				}
			}
			return copy;
		case actions.filter.fail.type:
			copy = { ...state };
			instance = copy[action.uri][action.uid];
			instance.pending = false;
			instance.error = action.errorMessage;
			break;
		case actions.clear.type:
			copy = { ...state };
			if (copy[action.uri] && copy[action.uri][action.uid]) {
				delete copy[action.uri][action.uid];
				if (Object.keys(copy[action.uri]).length === 0) {
					delete copy[action.uri];
				}
			}
			return copy;
		default:
			return state;
	}
	return copy ? copy : state;
}

const digestEpic = (action$) => {
	return action$.pipe(
		ofType(actions.filter.request.type),
		switchMap(action =>
			rx(api.devices.deviceDigest, action.uri, action.filter).pipe(
				rxmap(operation => actions.filter.success({ uri: action.uri, uid: action.uid, digest: operation.response() })),
				catchError(error => of(actions.filter.fail({ uri: action.uri, uid: action.uid, errorMessage: error.userMessage || error.message }))),
				takeUntil(action$.pipe(ofType(actions.filter.cancel.type, actions.clear.type)))
			)
		)
	)
}

const watchNewEventsEpic = (action$, state$) => {
	return action$.pipe(
		ofType(latestEventsActions.find.success.type),
		filter(action => {
			const newUris = action.maps.recentMap && Object.keys(action.maps.recentMap).filter(uri => {
				return state$.value.deviceDigest && !!state$.value.deviceDigest[uri];
			});
			return newUris && newUris.length > 0;
		}),
		mergeMap(action => {
			const emit = [];
			const deviceDigestState = state$.value.deviceDigest;
			const recentMap = action.maps.recentMap;
			if (recentMap && deviceDigestState) {
				Object.keys(recentMap).forEach(uri => {
					if (deviceDigestState && deviceDigestState[uri]) {
						Object.keys(deviceDigestState[uri]).forEach(uid => {
							const filter = deviceDigestState[uri][uid].filter;
							emit.push(actions.filter.request({ uri, uid, filter }));
						});
					}
				});
			}
			return of.apply(this, emit);
		})
	)
}

const epic = combineEpics(digestEpic, watchNewEventsEpic);

export { actions, reducer, epic };
