import React, { useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { generatePath, withRouter } from 'react-router-dom';
import { useI18n } from '../../../i18n';
import { cx } from '../../api';
import { URL_DELIMITER } from '../../misc/url';
import { datetime } from '../../misc/datetime';
import { actions as pageActions } from '../../redux/app/pages';
import * as timeMachineState from '../../redux/app/timeMachine/state';
import * as timeMachinePlayer from '../../redux/app/timeMachine/player';
import HistoryMap from '../custom/timeMachine/map/HistoryMap';
import PopupActionDispatcher from '../custom/sidebar/DevicePopupActionDispatcher';
import WidgetManager from '../general/widgets/WidgetManager';
import TimeMachineMapControls from '../custom/timeMachine/map/TimeMachineMapControls';
import ScaleControl from '../custom/timeMachine/ScaleControl';
import { setPageTitle } from '../../misc/page';
import './timeMachinePage.scss';

const ROOT_URL = '/time-machine';
const PAGE_TITLE = 'time machine';

const ensureParams = (params) => {
	let result = null;
	if (params && params.since && params.until) {
		result = params;
	} else {
		result = { since: cx.today(), until: new Date() };
		if (result.until.getHours() < 6) result.since = new Date(result.since.getTime() - datetime.DAY);
		result.now = new Date(result.since.getTime() + (result.until.getTime() - result.since.getTime()) / 2);
	}
	return result;
}

function TimeMachinePage(props) {
	const { fc } = useI18n();
	const map = useRef(null);
	const scale = useRef(null);
	const params = ensureParams(props.params);
	const since = params.since, until = params.until, now = params.now;
	const uris = props.match.params.uris;
	const selectedUris = props.selection;

	useEffect(() => {
		setPageTitle(fc(PAGE_TITLE));
		props.dispatch(pageActions.timeMachine.setActive({ status: true }));
		props.dispatch(timeMachineState.actions.setParameters({ since, until, now, uris: selectedUris }));
		props.dispatch(timeMachineState.actions.subscribe({ historyAspect: 'messages' }));
		props.dispatch(timeMachineState.actions.subscribe({ historyAspect: 'events' }));
		return () => {
			props.dispatch(timeMachineState.actions.unsubscribe({ historyAspect: 'messages' }));
			props.dispatch(timeMachineState.actions.unsubscribe({ historyAspect: 'events' }));
			props.dispatch(pageActions.timeMachine.setActive({ status: false }));
			props.dispatch(timeMachinePlayer.actions.stop());
		}
	}, []);

	useEffect(() => {
		if (uris != null && props.deviceMap != null) {
			const _uris = uris.split(URL_DELIMITER);
			const needUpdate = _uris.length != selectedUris.length || _uris.some((uri) => !selectedUris.includes(uri));
			if (needUpdate) {
				const existingUris = _uris.filter(uri => props.deviceMap[uri]);
				props.dispatch(pageActions.timeMachine.setSelection({ uris: existingUris }));
			}
		} else if (selectedUris.length > 0) {
			props.dispatch(pageActions.timeMachine.selectionClear());
		}
	}, [uris, props.deviceMap]);

	useEffect(() => {
		if (props.active) {
			const presentURL = uris !== undefined ? uris : '';
			const url = 0 < selectedUris.length ? selectedUris.join(URL_DELIMITER) : '';
			if (url != presentURL) {
				props.history.push({
					pathname: generatePath(props.match.path) + (url ? ('/devices/' + url) : '')
				});
			}
		}
		props.dispatch(timeMachineState.actions.setParameters({ since, until, now, uris: selectedUris }));
	}, [selectedUris]);

	const onChange = useCallback((since, until, now) => {
		props.dispatch(timeMachinePlayer.actions.stop());
		props.dispatch(timeMachineState.actions.setParameters({ since, until, now, uris: selectedUris }));
	}, [selectedUris]);

	useEffect(() => {
		if (map.current) map.current.getOlMap().updateSize();
		if (scale.current) scale.current.updateScale(true);
	}, [props.sidebarCollapsed]);

	const onMessageClick = (message) => {
		onChange(since, until, message.generatedAt);
	}

	return (
		<div className="app-page time-machine">
			<div className="content">
				<PopupActionDispatcher />
				<WidgetManager>
					<HistoryMap
						name="timeMachine"
						since={since}
						until={until}
						onMessageClick={onMessageClick}
						control={TimeMachineMapControls}
						customRef={map}
					/>
				</WidgetManager>
			</div>
			<div className="footer">
				<ScaleControl
					since={since}
					until={until}
					now={now}
					uris={selectedUris}
					onChange={onChange}
					customRef={scale}
				/>
			</div>
		</div>
	);
}

TimeMachinePage.Url = ROOT_URL;

export default connect(
	state => ({
		deviceMap: state.devices.map,
		active: state.pages.timeMachine.active,
		selection: state.pages.timeMachine.selection,
		params: state.timeMachine.state.parameters,
		sidebarCollapsed: state.context.sidebarCollapsed
	})
)(withRouter(TimeMachinePage));
