import { DataScope, TimeRange, createScope } from "./adapters"; // eslint-disable-line no-unused-vars
import { store } from "../../../redux/store";
import { messageProxy } from "../../../api/message";
import { intersects, subtract, union } from "../../../lib/timeRange";
import { binarySearch } from "../../../lib/search";
import { round } from "../../../lib/datetime";

class AbstractHistoryManager {

	constructor(stateName, dataName) {
		this._stateName = stateName;
		this._dataName = dataName || stateName;
	}

	stateName() {
		return this._stateName;
	}

	proxy(object) {
		return object;
	}

	dataName() {
		return this._dataName;
	}

	compare(left, right) {

	}

	createItem(timeRange, subject) {
		if (!subject) subject = [];
		let data = !Array.isArray(subject) ? subject[this.dataName()] : subject;
		data.sort(this.compare);
		data = data.map(object => this.proxy(object));
		if (!timeRange.since) timeRange.since = new Date(0);
		return { timeRange, [this.dataName()]: data };
	}

	/**
	 * @param {Array.<ScopeMessageHistory>} histories
	 * @returns new state with merged histories
	 */
	merge(histories, state) {
		state = state ? state : store.getState().history[this.stateName()];
		const map = {...state.map};
		histories.forEach(history => {
			history.scope.uris.forEach(uri => {
				const container = history.histories ? history.histories.find(container => container.uri == uri) : null;
				const item = this.createItem(history.scope.timeRange, container);
				if (!map[uri]) map[uri] = [];
				map[uri].push(item);
			});
		});
		// merge adjacent scopes
		Object.keys(map).forEach(uri => {
			map[uri].sort((left, right) => left.timeRange.since.getTime() - right.timeRange.since.getTime());
			const merged = [map[uri][0]];
			for (let at = 1, mergedAt = 0; at < map[uri].length; ++at) {
				const item = merged[mergedAt], nextItem = map[uri][at];
				if (intersects(item.timeRange, nextItem.timeRange)) {
					merged[mergedAt] = this.createItem(
						union(item.timeRange, nextItem.timeRange),
						item[this.dataName()].concat(nextItem[this.dataName()])
					);
				} else {
					merged.push(nextItem);
					mergedAt += 1;
				}
			}
			map[uri] = merged;
		});
		return {...state, map};
	}

	/**
	 * @param {DataScope} scope
	 * @returns {Array.<DataScope>} array of missing scopes
	 */
	subtract(scope, state) {
		state = state ? state : store.getState().history[this.stateName()];
		const missing = [];
		scope.uris.forEach(uri => {
			let difference = null;
			if (state.map && state.map[uri]) {
				difference = TimeRange.fromObjects(
					subtract(scope.timeRange, state.map[uri].map(dataItem => dataItem.timeRange))
				);
			} else {
				difference = [scope.timeRange];
			}
			missing.push.apply(
				missing,
				difference.map(timeRange => createScope(timeRange, [uri]))
			);
		});
		return missing.length > 0 ? missing : null;
	}

	deviceState(uri, date) {
		const state = store.getState().history[this.stateName()];
		let scope = null;
		if (state.map && state.map[uri]) {
			scope = state.map[uri].find(scope =>
				date.getTime() >= scope.timeRange.since.getTime() &&
				date.getTime() <= scope.timeRange.until.getTime()
			);
			if (scope) {
				const history = scope[this.dataName()];
				const [ result, close ] = binarySearch(history, item => Math.floor(item.generatedAt.getTime() / 1000) - Math.floor(date.getTime() / 1000));
				if (history.length > 0 && result.length == 0) {
					if (history[close].generatedAt.getTime() > date.getTime()) {
						return history[close - 1] ? [ history[close - 1] ] : null;
					} else return [ history[close] ];
				} else return result;
			}
		}
		return null;
	}

	vicinityDate(uri, date, forward = true) {
		const timestamp = date.getTime();
		const state = store.getState().history[this.stateName()];
		let generatedAt = null;
		if (state.map && state.map[uri]) {
			let scopeAt = state.map[uri].findIndex(scope =>
				timestamp >= scope.timeRange.since.getTime() &&
				timestamp < scope.timeRange.until.getTime()
			);
			if (scopeAt >= 0) {
				let history = state.map[uri][scopeAt][this.dataName()];
				if (forward) {
					// This will skip messages with identical generatedAt
					const nextAt = history.findIndex(item => round(item.generatedAt).getTime() > round(timestamp));
					if (nextAt >= 0) {
						if (nextAt < history.length) {
							generatedAt = history[nextAt].generatedAt;
						} else if (scopeAt + 1 < state.map[uri].length) {
							let history = state.map[uri][scopeAt + 1][this.dataName()];
							if (history.length > 0) generatedAt = history[0].generatedAt;
						}
					}
				} else {
					let prevAt = ([...history].reverse()).findIndex(item => round(item.generatedAt).getTime() < round(timestamp));
					if (prevAt >= 0) {
						prevAt = history.length - prevAt - 1;
						if (prevAt >= 0) {
							generatedAt = history[prevAt].generatedAt;
						} else if (scopeAt - 1 >= 0) {
							let history = state.map[uri][scopeAt - 1][this.dataName()];
							if (history.length > 0) generatedAt = history[history.length - 1].generatedAt;
						}
					}
				}
			}
		}
		return generatedAt;
	}

	state(uris, date) {
		const map = {};
		uris.forEach(uri => {
			const state = this.deviceState(uri, date);
			if (state) map[uri] = state;
		});
		return Object.keys(map).length > 0 ? map : null;
	}
}

// message history

class MessageHistoryManager extends AbstractHistoryManager {

	constructor() {
		super('messages');
	}

	compare(a, b) {
		return a.generatedAt.getTime() - b.generatedAt.getTime();
	}

	deviceState(uri, date) {
		const state = store.getState().history[this.dataName()];
		let scope = null;
		if (state.map && state.map[uri]) {
			scope = state.map[uri].find(scope =>
				date.getTime() >= scope.timeRange.since.getTime() &&
				date.getTime() <= scope.timeRange.until.getTime()
			);
			if (scope) {
				const history = scope[this.dataName()];
				const [result, close] = binarySearch(history, item => item.generatedAt.getTime() - date.getTime());
				if (history.length > 0 && result.length == 0) {
					if (history[close].generatedAt.getTime() > date.getTime()) {
						return history[close - 1];
					} else return history[close];
				} else return result[0];
			}
		}
		return null;
	}

	proxy(message) {
		return messageProxy(message);
	}
}

let messageHistoryManager = null;
MessageHistoryManager.getInstance = () => {
	if (messageHistoryManager == null) messageHistoryManager = new MessageHistoryManager();
	return messageHistoryManager;
}

// event history

class EventHistoryManager extends AbstractHistoryManager {

	constructor() {
		super('events');
	}

	compare(a, b) {
		return a.generatedAt.getTime() - b.generatedAt.getTime();
	}
}

let eventHistoryManager = null;
EventHistoryManager.getInstance = () => {
	if (eventHistoryManager == null) eventHistoryManager = new EventHistoryManager();
	return eventHistoryManager;
}

// asset presences history

class AssetPresencesHistoryManager extends AbstractHistoryManager {

	constructor() {
		super('assetPresences', 'intervals');
	}

	compare(a, b) {
		return a.startedAt.getTime() - b.startedAt.getTime();
	}

	deviceState(uri, date) {
		const state = store.getState().history[this.stateName()];
		let scope = null;
		if (state.map && state.map[uri]) {
			scope = state.map[uri].find(scope =>
				date.getTime() >= scope.timeRange.since.getTime() &&
				date.getTime() <= scope.timeRange.until.getTime()
			);
			if (scope) {
				const history = scope[this.dataName()];
				return history.filter(interval => interval.startedAt.getTime() < date.getTime() && (!interval.endedAt || date.getTime() < interval.endedAt.getTime()));
			}
		}
		return null;
	}
}

let assetPresencesHistoryManager = null;
AssetPresencesHistoryManager.getInstance = () => {
	if (assetPresencesHistoryManager == null) assetPresencesHistoryManager = new AssetPresencesHistoryManager();
	return assetPresencesHistoryManager;
}

//

export const getHistoryManagerByName = (name) => {
	switch (name) {
		case 'events':
			return EventHistoryManager;
		case 'messages':
			return MessageHistoryManager;
		case 'assetPresences':
			return AssetPresencesHistoryManager;
		default:
			break;
	}
}
