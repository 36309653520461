export const timestamps = names => ({
	internalize: object => {
		const timestamps = names.filter(name => object[name] != null).map(name => ([name, new Date(object[name])]));
		if (timestamps.length == 0) return object;
		return Object.assign({}, object, Object.fromEntries(timestamps));
	}
	, externalize: object => {
		const timestamps = names.filter(name => object[name] != null).map(name => ([name, object[name].toISOString()]));
		if (timestamps.length == 0) return object;
		return Object.assign({}, object, Object.fromEntries(timestamps));
	}
});

export const internalizePage = (size, internalize) => page => ({
	no: Math.floor(page.offset / size) + 1
	, objects: page.objects.map(internalize)
	, total: page.total
});
