import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Panel } from 'rsuite';
import { useI18n } from '../../../i18n';
import { actions } from '../../redux/api/session';
import logoWhite from '../../img/logo-white.png';
import logo from '../../img/logo.png';
import LanguageChanger from '../general/LanguageChanger';
import { setPageTitle } from '../../misc/page';
import AuthorizationForm from '../custom/profile/AuthorizationForm';
import './loginPage.scss';

const PAGE_TITLE = 'login';

function LoginPage(props) {
	const { f, fc } = useI18n();
	const from = props.location ? (props.location.state ? props.location.state.from : null) : null;

	function onSumbit(initialValue) {
		props.dispatch(actions.login.request(initialValue));
	}

	useEffect(() => {
		setPageTitle(fc(PAGE_TITLE));
	}, []);

	let content = null;
	if (!props.session.initialized) content = (<Redirect to="/" />);
	else if (props.session.authorized) content = (<Redirect to={from ? from.pathname : "/dashboard"} />);
	else content = (<AuthorizationForm firstStepAction={onSumbit} />);

	return (
		<div className="app-page login">
			<div className="logo-white"><img src={logoWhite} alt="" /></div>
			<Panel header={<div>{f('welcome to overwheels portal')}</div>}>
				<div className="logo"><img src={logo} alt="" /></div>
				{content}
				<LanguageChanger />
			</Panel>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		session: state.session
	};
};

export default withRouter(connect(mapStateToProps)(LoginPage));
