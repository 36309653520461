import { api, rx } from '../../../api';
import { actions } from './actions';
import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, takeUntil } from 'rxjs/operators';
import { actions as sessionActions } from '../session';
import { errorMap } from '../../actions';

const defaultState = {
	codeMap: null,
	mnemonicMap: null,
	pending: false,
	error: null
};

// ---------------------------------------------------------

function reducer(state, action) {
	switch (action.type) {
		case actions.attributeTypes.request.type:
			state = {
				...state,
				pending: true,
				error: null
			};
			break;
		case actions.attributeTypes.success.type:
			state = {
				...state,
				...action.maps,
				pending: false
			};
			break;
		case actions.attributeTypes.fail.type:
			state = {
				...state,
				pending: false,
				error: action.errorMessage
			};
			break;
		case actions.attributeTypes.cancel.type:
			state = {
				...state,
				pending: false
			}
			break;
		default:
	}
	return state || defaultState;
};

// ---------------------------------------------------------

function process(attributeTypes) {
	const codeMap = {}, mnemonicMap = {};
	attributeTypes.forEach(attributeType => {
		codeMap[attributeType.code] = attributeType;
		mnemonicMap[attributeType.mnemonics] = attributeType;
	});
	return { codeMap, mnemonicMap };
}

const loadEpic = (action$) => {
	return action$.pipe(
		ofType(actions.attributeTypes.request.type),
		mergeMap(action =>
			rx(api.registry.attributeTypes).pipe(
				map(operation => actions.attributeTypes.success({ maps: process(operation.response()) })),
				errorMap(actions.attributeTypes.fail),
				takeUntil(action$.pipe(ofType(actions.attributeTypes.cancel.type)))
			)
		)
	)
}

const watchSessionStartedEpic = (action$) => {
	return action$.pipe(
		ofType(sessionActions.events.started.type),
		map(action => actions.attributeTypes.request())
	)
}

const epic = combineEpics(loadEpic, watchSessionStartedEpic);

// ---------------------------------------------------------

export { reducer, epic };
