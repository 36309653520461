import React from 'react';
import { AbstractSeries } from 'react-vis';
import { getAttributeScale } from 'react-vis/es/utils/scales-utils';

function getLocs(evt) {
	const xLoc = evt.type === 'touchstart' ? evt.pageX : evt.offsetX;
	const yLoc = evt.type === 'touchstart' ? evt.pageY : evt.offsetY;
	return { xLoc, yLoc };
}

class Highlight extends AbstractSeries {
	refBox = React.createRef();
	mounted = false;

	state = {
		dragging: false,
		brushArea: {
			top: 0,
			right: 0,
			bottom: 0,
			left: 0
		},
		brushing: false,
		startLocX: 0,
		startLocY: 0,
		dragArea: null
	};

	_getDrawArea(xLoc, yLoc) {
		const { startLocX, startLocY } = this.state;
		const {
			enableX,
			enableY,
			highlightWidth,
			highlightHeight,
			innerWidth,
			innerHeight,
			marginLeft,
			marginRight,
			marginBottom,
			marginTop
		} = this.props;
		const plotHeight = innerHeight + marginTop + marginBottom;
		const plotWidth = innerWidth + marginLeft + marginRight;
		const touchWidth = highlightWidth || plotWidth;
		const touchHeight = highlightHeight || plotHeight;

		return {
			bottom: enableY ? Math.max(startLocY, yLoc) : touchHeight,
			right: enableX ? Math.max(startLocX, xLoc) : touchWidth,
			left: enableX ? Math.min(xLoc, startLocX) : 0,
			top: enableY ? Math.min(yLoc, startLocY) : 0
		};
	}

	_getDragArea(xLoc, yLoc) {
		const { enableX, enableY } = this.props;
		const { startLocX, startLocY, dragArea } = this.state;

		return {
			bottom: dragArea.bottom + (enableY ? yLoc - startLocY : 0),
			left: dragArea.left + (enableX ? xLoc - startLocX : 0),
			right: dragArea.right + (enableX ? xLoc - startLocX : 0),
			top: dragArea.top + (enableY ? yLoc - startLocY : 0)
		};
	}

	_clickedOutsideDrag(xLoc, yLoc) {
		const { enableX, enableY } = this.props;
		const {
			dragArea,
			brushArea: {left, right, top, bottom}
		} = this.state;
		const clickedOutsideDragX = dragArea && (xLoc < left || xLoc > right);
		const clickedOutsideDragY = dragArea && (yLoc < top || yLoc > bottom);
		if (enableX && enableY) {
			return clickedOutsideDragX || clickedOutsideDragY;
		}
		if (enableX) {
			return clickedOutsideDragX;
		}
		if (enableY) {
			return clickedOutsideDragY;
		}
		return true;
	}

	_convertAreaToCoordinates(brushArea) {
		// NOTE only continuous scales are supported for brushing/getting coordinates back
		const { enableX, enableY, marginLeft, marginTop } = this.props;
		const xScale = getAttributeScale(this.props, 'x');
		const yScale = getAttributeScale(this.props, 'y');

		// Ensure that users wishes are being respected about which scales are evaluated
		// this is specifically enabled to ensure brushing on mixed categorical and linear
		// charts will run as expected

		if (enableX && enableY) {
			return {
				bottom: yScale.invert(brushArea.bottom),
				left: xScale.invert(brushArea.left - marginLeft),
				right: xScale.invert(brushArea.right - marginLeft),
				top: yScale.invert(brushArea.top)
			};
		}

		if (enableY) {
			return {
				bottom: yScale.invert(brushArea.bottom - marginTop),
				top: yScale.invert(brushArea.top - marginTop)
			};
		}

		if (enableX) {
			return {
				left: xScale.invert(brushArea.left - marginLeft),
				right: xScale.invert(brushArea.right - marginLeft)
			};
		}

		return {};
	}

	startBrushing(event) {
		if (!this.mounted) return;
		// if we clicked on a marker we remember it to prevent the zoom from changing
		this.onMarkerStart = event.target.tagName == "circle" ? event.target : null;

		const { onBrushStart, onDragStart, drag } = this.props;
		const { dragArea } = this.state;
		const { xLoc, yLoc } = getLocs(event);

		const startArea = (dragging, resetDrag) => {
			const emptyBrush = {
				bottom: yLoc,
				left: xLoc,
				right: xLoc,
				top: yLoc
			};
			this.setState({
				dragging,
				brushArea: dragArea && !resetDrag ? dragArea : emptyBrush,
				brushing: !dragging,
				startLocX: xLoc,
				startLocY: yLoc
			});
		};

		const clickedOutsideDrag = this._clickedOutsideDrag(xLoc, yLoc);
		if ((drag && !dragArea) || !drag || clickedOutsideDrag) {
			startArea(false, clickedOutsideDrag);

			if (onBrushStart) {
				onBrushStart(event);
			}
			return;
		}

		if (drag && dragArea) {
			startArea(true, clickedOutsideDrag);
			if (onDragStart) {
				onDragStart(event);
			}
		}
	}

	stopBrushing(event) {
		if (!this.mounted) return;
		switch (event.type) {
			case "touchend":
			case "touchcancel":
			case "contextmenu":
				event.preventDefault();
				break;
		}
		// if button was depressed on the same marker, we stop drawing and prevent the zoom from changing
		if (event.target.tagName == "circle" && this.onMarkerStart == event.target) {
			this.setState({
				brushing: false,
				dragging: false,
				brushArea: { top: 0, right: 0, bottom: 0, left: 0 },
				startLocX: 0,
				startLocY: 0,
				dragArea: null
			});
			return;
		}

		const { brushing, dragging, brushArea } = this.state;
		// Quickly short-circuit if the user isn't brushing in our component
		if (!brushing && !dragging) {
			return;
		}
		const { onBrushEnd, onDragEnd, drag } = this.props;
		const noHorizontal = Math.abs(brushArea.right - brushArea.left) < 5;
		const noVertical = Math.abs(brushArea.top - brushArea.bottom) < 5;
		// Invoke the callback with null if the selected area was < 5px
		const isNulled = noVertical || noHorizontal;
		// Clear the draw area
		this.setState({
			brushing: false,
			dragging: false,
			brushArea: drag ? brushArea : { top: 0, right: 0, bottom: 0, left: 0 },
			startLocX: 0,
			startLocY: 0,
			dragArea: drag && !isNulled && brushArea
		});

		if (brushing && onBrushEnd) {
			onBrushEnd(!isNulled ? this._convertAreaToCoordinates(brushArea) : null);
		}

		if (drag && onDragEnd) {
			onDragEnd(!isNulled ? this._convertAreaToCoordinates(brushArea) : null);
		}
	}

	onBrush(event) {
		this.onMarkerStart = null;
		if (!this.mounted) return;
		const { onBrush, onDrag, drag } = this.props;
		const { brushing, dragging } = this.state;
		const { xLoc, yLoc } = getLocs(event);
		if (brushing) {
			const brushArea = this._getDrawArea(xLoc, yLoc);
			this.setState({ brushArea });

			if (onBrush) {
				onBrush(this._convertAreaToCoordinates(brushArea));
			}
		}

		if (drag && dragging) {
			const brushArea = this._getDragArea(xLoc, yLoc);
			this.setState({ brushArea });
			if (onDrag) {
				onDrag(this._convertAreaToCoordinates(brushArea));
			}
		}
	}

	stopPropagation(event) {
		if (this.state.brushing) {
			event.stopPropagation();
			event.preventDefault();
		}
	}

	componentDidMount() {
		this.mounted = true;
		this.parentNode = this.refBox.current.parentNode;
		this.parentNode.addEventListener("mousedown", this.startBrushing.bind(this));
		this.parentNode.addEventListener("mousemove", this.onBrush.bind(this));
		this.parentNode.addEventListener("mouseup", this.stopBrushing.bind(this));
		this.parentNode.addEventListener("mouseleave", this.stopBrushing.bind(this));
		// stopPropagation when is brushing
		this.parentNode.addEventListener("mouseover", this.stopPropagation.bind(this));
		this.parentNode.addEventListener("mouseout", this.stopPropagation.bind(this));
		// preventDefault() so that mouse event emulation does not happen
		this.parentNode.addEventListener("touchend", this.stopBrushing.bind(this));
		this.parentNode.addEventListener("touchcancel", this.stopBrushing.bind(this));
		this.parentNode.addEventListener("contextmenu", this.stopBrushing.bind(this));
	}

	componentWillUnmount() {
		this.mounted = false;
		this.parentNode.removeEventListener("mousedown", this.startBrushing.bind(this));
		this.parentNode.removeEventListener("mousemove", this.onBrush.bind(this));
		this.parentNode.removeEventListener("mouseup", this.stopBrushing.bind(this));
		this.parentNode.removeEventListener("mouseleave", this.stopBrushing.bind(this));
		// stopPropagation when is brushing
		this.parentNode.removeEventListener("mouseover", this.stopPropagation.bind(this));
		this.parentNode.removeEventListener("mouseout", this.stopPropagation.bind(this));
		// preventDefault() so that mouse event emulation does not happen
		this.parentNode.removeEventListener("touchend", this.stopBrushing.bind(this));
		this.parentNode.removeEventListener("touchcancel", this.stopBrushing.bind(this));
		this.parentNode.removeEventListener("contextmenu", this.stopBrushing.bind(this));
	}

	render() {
		const {
			color,
			className,
			highlightHeight,
			highlightWidth,
			highlightX,
			highlightY,
			innerWidth,
			innerHeight,
			marginLeft,
			marginRight,
			marginTop,
			marginBottom,
			opacity
		} = this.props;
		const {
			brushArea: { left, right, top, bottom }
		} = this.state;

		let leftPos = 0;
		if (highlightX) {
			const xScale = getAttributeScale(this.props, 'x');
			leftPos = xScale(highlightX);
		}

		let topPos = 0;
		if (highlightY) {
			const yScale = getAttributeScale(this.props, 'y');
			topPos = yScale(highlightY);
		}

		const plotWidth = marginLeft + marginRight + innerWidth;
		const plotHeight = marginTop + marginBottom + innerHeight;
		const touchWidth = highlightWidth || plotWidth;
		const touchHeight = highlightHeight || plotHeight;

		return (
			<g
				ref={this.refBox}
				transform={`translate(${leftPos}, ${topPos})`}
				className={(className + ' rv-highlight-container')}
			>
				<rect
					className="rv-mouse-target"
					fill="black"
					opacity="0"
					x="0"
					y="0"
					width={Math.max(touchWidth, 0)}
					height={Math.max(touchHeight, 0)}
				/>
				<rect
					className="rv-highlight"
					pointerEvents="none"
					opacity={opacity}
					fill={color}
					x={left}
					y={top}
					width={Math.min(Math.max(0, right - left), touchWidth)}
					height={Math.min(Math.max(0, bottom - top), touchHeight)}
				/>
			</g>
		);
	}
}

Highlight.displayName = 'HighlightOverlay';
Highlight.defaultProps = {
	color: 'rgb(77, 182, 172)',
	className: '',
	enableX: true,
	enableY: true,
	opacity: 0.3
};

export default Highlight;