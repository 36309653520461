import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

// reports

import { reducer as locationHistoryReducer } from './locationHistory/reducer';
import { epic as locationHistoryEpic } from './locationHistory/epic';

import { reducer as tripReducer } from './trip/reducer';
import { epic as tripEpic } from './trip/epic';

import { reducer as tripHistoryReducer } from './tripHistory/reducer';
import { epic as tripHistoryEpic } from './tripHistory/epic';

import { reducer as temperatureChangeReducer } from './temperatureChange/reducer';
import { epic as temperatureChangeEpic } from './temperatureChange/epic';

import { reducer as temperatureHistoryReducer } from './temperatureHistory/reducer';
import { epic as temperatureHistoryEpic } from './temperatureHistory/epic';

import { reducer as deviceUsagesReducer } from './deviceUsages/reducer';
import { epic as deviceUsagesEpic } from './deviceUsages/epic';

import { reducer as deviceSituationsReducer } from './deviceSituations/reducer';
import { epic as deviceSituationsEpic } from './deviceSituations/epic';

import { reducer as deviceStatusReducer, epic as deviceStatusEpic } from './deviceStatus';
import { reducer as deviceUtilizationReducer, epic as deviceUtilizationEpic } from './deviceUtilization';
import { reducer as drivingBehaviourReducer, epic as drivingBehaviourEpic } from './drivingBehaviour';

import { reducer as speedingReducer } from './speeding/reducer';
import { epic as speedingEpic } from './speeding/epic';

import { reducer as idlingReducer } from './idling/reducer';
import { epic as idlingEpic } from './idling/epic';

import { reducer as deviceRegistryReducer } from './deviceRegistry/reducer';
import { epic as deviceRegistryEpic } from './deviceRegistry/epic';

import { reducer as  zoneVisitsReducer } from './zoneVisits/reducer';
import { epic as zoneVisitsEpic } from './zoneVisits/epic';

import { reducer as  fuelLevelReducer } from './fuelLevel/reducer';
import { epic as fuelLevelEpic } from './fuelLevel/epic';

import { reducer as  ridershipReducer } from './ridership/reducer';
import { epic as ridershipEpic } from './ridership/epic';

import { reducer as  commuteReducer } from './commute/reducer';
import { epic as commuteEpic } from './commute/epic';
import { reducer as commuteSchedulesReducer, epic as commuteSchedulesEpic } from './commute/schedules'; 

const reducer = combineReducers({
	locationHistory: locationHistoryReducer,
	trip: tripReducer,
	tripHistory: tripHistoryReducer,
	temperatureChange: temperatureChangeReducer,
	temperatureHistory: temperatureHistoryReducer,
	deviceUsages: deviceUsagesReducer,
	deviceSituations: deviceSituationsReducer,
	deviceStatus: deviceStatusReducer,
	deviceUtilization: deviceUtilizationReducer,
	drivingBehaviour: drivingBehaviourReducer,
	speeding: speedingReducer,
	idling: idlingReducer,
	deviceRegistry: deviceRegistryReducer,
	zoneVisits: zoneVisitsReducer,
	fuelLevel: fuelLevelReducer,
	ridership: ridershipReducer,
	commute: commuteReducer,
	commuteSchedules: commuteSchedulesReducer
});

const epic = combineEpics(
	locationHistoryEpic,
	tripEpic,
	tripHistoryEpic,
	temperatureChangeEpic,
	temperatureHistoryEpic,
	deviceUsagesEpic,
	deviceSituationsEpic,
	deviceStatusEpic,
	deviceUtilizationEpic,
	drivingBehaviourEpic,
	speedingEpic,
	idlingEpic,
	deviceRegistryEpic,
	zoneVisitsEpic,
	fuelLevelEpic,
	ridershipEpic,
	commuteEpic,
	commuteSchedulesEpic
);


export { actions } from './actions';
export { reducer };
export { epic };

// connect

export { connect as locationHistoryConnect } from './locationHistory/resolver';
export { connect as tripConnect } from './trip/resolver';
export { connect as tripHistoryConnect } from './tripHistory/resolver';
export { connect as temperatureHistoryConnect } from './temperatureHistory/resolver';
export { connect as deviceSituationsConnect } from './deviceSituations/resolver';
export { connect as deviceStatusConnect } from './deviceStatus';
export { connect as speedingConnect } from './speeding/resolver';
export { connect as idlingConnect } from './idling/resolver';
export { connect as ridershipConnect } from './ridership/resolver';
export { connect as commuteConnect } from './commute/resolver';
