import React from 'react';
import { useSelector } from 'react-redux';
import SideMenu from './DeviceSideMenu';
import { className } from '../../../lib/className';
import './deviceSidebar.scss';

function DeviceSidebar() {
	const collapsed = useSelector(state => state.context.sidebarCollapsed);

	return (
		<div className={className("device-sidebar", { collapsed })}>
			<SideMenu />
		</div>
	);
}

export default DeviceSidebar;
