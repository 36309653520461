import React, { useEffect, useImperativeHandle } from 'react';
import { actions as stomachActions } from '../../../../../redux/api/stomach';
import { connect } from 'react-redux';
import Loader from '../../../../general/Loader';
import { useI18n } from '../../../../../../i18n';
import WidgetDataSignature from '../aux/WidgetDataSignature';
import DigestConfiguration from '../aux/DigestConfiguration';
import BarChart from '../aux/BarChart';
import DeviceTop from '../aux/DeviceTop';
import RecentTrips from './RecentTrips';
import * as widgets from '../../../../../redux/app/widgets';
import { cx } from '../../../../../api';
import buildParameters from '../aux/buildParameters';
import ActionLockedDevices from '../../../../general/widgets/ActionLockedDevices';
import WidgetMenuItem from '../../../../general/widgets/WidgetMenuItem';
import { className } from '../../../../../lib/className';
import ActionLock from '../../../../share/actionbar/ActionLock';
import './tripsWidget.scss';

/**
 * @param {Object} props
 * @param {string} props.uid
 * @param {string} props.reduxKey
 * @param {React.RefObject} props.customRef
 * @param {function} props.sizeChange ( uid, { w, h } )
 * @param {function} props.update to rerender WidgetContainer
 */

const PIN_ACTIONS = { lock: 'lockDevices' };

function TripsWidget(props) {
	const { fc } = useI18n();
	let content = null;
	const tripData = props.trips;
	const tripDigest = tripData && tripData.tripDigest;

	const widgetData = props.widget.data;
	const layout = props.widget.layout;
	const locked = !!(widgetData && widgetData.uris);
	const uris = locked
		? widgetData.uris
		: (props.selection ? props.selection : [])
	;

	useEffect(() => {
		if (tripDigest && !tripData.pending && props.widget.autoSize) {
			if (!tripData.parameters.recents) {
				const length = tripDigest.timeHistogram && Object.keys(tripDigest.timeHistogram.binsMap).length;
				const copyLayout = { ...layout };
				if (tripDigest.deviceTop) {
					copyLayout.w = (length < 9 ? 7 : (length > 15 ? (length > 21 ? 10 : 9) : 8));
				} else {
					copyLayout.w = (length < 9 ? 3 : (length > 15 ? (length > 21 ? 6 : 5) : 4));
				}
				props.sizeChange(copyLayout);
			} else {
				const length = tripDigest.timeHistogram && Object.keys(tripDigest.timeHistogram.binsMap).length;
				const copyLayout = { ...layout };
				copyLayout.w = (length < 9 ? 6 : (length > 15 ? (length > 21 ? 9 : 8) : 7));
				props.sizeChange(copyLayout);
			}
		}
		props.update();
	}, [tripDigest]);

	useEffect(() => {
		if (widgetData) {
			const parameters = new cx.ods.stomach.TripDigestParameters();
			parameters.filter = new cx.ods.stomach.DigestFilter();
			if (uris.length > 0) {
				parameters.filter.uriSetFilter = new cx.ods.devices.DeviceURISetFilter();
				parameters.filter.uriSetFilter.uriSet = uris;
			}
			parameters.histogram = new cx.ods.stomach.TimeHistogramParameters();
			buildParameters(parameters, widgetData);
			if (widgetData.option) {
				if (widgetData.option == 'top') {
					parameters.top = new cx.ods.stomach.DeviceTopParameters();
				} else if (widgetData.option == 'recents') {
					parameters.recents = new cx.ods.stomach.RecentTripsParameters();
				}
				parameters[widgetData.option].size = 5;
			}
			props.dispatch(stomachActions.trips.request({ uid: props.uid, parameters }));
		} else {
			const values = {
				timeRange: 0,
				days: 7,
				option: null
			}
			props.dispatch(widgets.actions.update({ domain: props.reduxKey, uid: props.uid, data: values }));
		}
	}, [widgetData, !locked ? props.selection : null]);

	const lockHandler = () => {
		props.dispatch(widgets.actions.update({ domain: props.reduxKey, uid: props.uid, data: { uris: locked ? null : uris } }));
	}

	const setPinnedActions = (actionName, pinned) => {
		const pinnedActions = props.widgetData && props.widgetData.pinnedActions ? { ...props.widgetData.pinnedActions } : {};
		pinnedActions[actionName] = pinned;
		props.dispatch(widgets.actions.update({ domain: props.reduxKey, uid: props.uid, data: { pinnedActions } }));
	}

	useImperativeHandle(props.customRef, () => ({
		title: () => fc('trips'),
		actions: (onChange) => {
			const actions = [];
			if (locked) {
				actions.push(
					<ActionLockedDevices
						uris={widgetData.uris}
						onChange={onChange}
						key="action-uris"
						emptyMessage={fc('all devices')}
					/>
				);
			}
			if (widgetData && widgetData.pinnedActions) {
				if (widgetData.pinnedActions[PIN_ACTIONS.lock]) {
					actions.push(
						<ActionLock
							key={PIN_ACTIONS.lock}
							onClick={lockHandler}
							active={locked}
							title={fc(locked ? 'unlock' : 'lock')}
						/>
					);
				}
			}
			return actions;
		},
		dynamicContent: Boolean(tripData && (tripData.parameters.recents || (tripDigest && tripDigest.deviceTop))),
		canPin: true,
		handlePin: lockHandler,
		menuItems: () => {
			return (
				<WidgetMenuItem
					onClick={lockHandler}
					text={fc(locked ? 'unlock' : 'lock')}
					canPin
					pinned={widgetData && widgetData.pinnedActions && widgetData.pinnedActions[PIN_ACTIONS.lock]}
					onChangePin={(pinned) => setPinnedActions(PIN_ACTIONS.lock, pinned)}
				/>
			);
		},
		configuration: () => <DigestConfiguration uid={props.uid} />
	}));

	if (tripDigest != null && tripDigest.timeHistogram) {
		content = (
			<div className={className("widget", "trips", { 'options': !!(tripData.parameters.recents || tripDigest.deviceTop) })}>
				<div>
					<BarChart histogram={tripDigest.timeHistogram} />
					<WidgetDataSignature data={widgetData} />
				</div>
				{tripDigest.deviceTop && <DeviceTop deviceTop={tripDigest.deviceTop} type="trips" />}
				{tripData.parameters.recents && <RecentTrips recents={tripDigest.recents} />}
			</div>
		);
	} else {
		content = (<Loader size={Loader.Size.MD} />);
	}
	return (content);
}

export default connect((state, props) => ({
	widget: state.widgets[props.reduxKey].map[props.uid],
	trips: state.stomach.trips && state.stomach.trips[props.uid],
	selection: state.pages[props.reduxKey] && state.pages[props.reduxKey].selection
}))(TripsWidget);
