import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { invert, union } from '../../../lib/timeRange';
import TimeRangeScale from '../../general/scale/TimeRangeScale';

const inRange = (event, since, until) => {
	return since <= event.generatedAt && event.generatedAt <= until;
}

/**
 * @param {Object} props
 * @param {Date} props.since
 * @param {Date} props.until
 * @param {Date} props.at
 * @param {Array.<string>} props.uris
 */
function ScaleControl(props) {
	const [uriEventMap, setUriEventMap] = useState({});
	let { history, eventsScopeMap, eventTypeMap, uris, selectedEventsUris, displayEvents, ...rest } = props;
	let absent = [];

	history = history
		? Object.keys(history)
			.filter(uri => uris.includes(uri) && history[uri] && history[uri].length > 0)
			.reduce((map, uri) => { map[uri] = history[uri]; return map; }, {})
		: null
	;

	if (history && Object.keys(history).length > 0) {
		const map = {};
		let uris = Object.keys(history);
		uris.forEach(uri => {
			const ranges = history[uri].map(scope => scope.timeRange);
			map[uri] = invert({ since: props.since, until: props.until }, ranges);
		});
		uris = Object.keys(map);
		absent = map[uris[0]]; // get first as reference
		for (let at = 1; at < uris.length; ++at) {
			if (absent.length > 0) {
				let currentRanges = map[uris[at]];
				absent.forEach(refRange => {
					let temp = [];
					let ref = refRange;
					currentRanges.forEach(range => {
						const _union = union(ref, range);
						if (_union) {
							ref = _union;
						} else {
							temp.push(range);
						}
					});
					temp.push(ref);
					currentRanges = temp;
				});
				absent = currentRanges;
			} else { // skip empty
				absent = map[uris[at]];
			}
		}
	} else {
		absent.push({ since: props.since, until: props.until });
	}

	useEffect(() => {
		if (eventsScopeMap && eventTypeMap && selectedEventsUris) {
			const newUriEventMap = {};
			if (displayEvents) {
				selectedEventsUris.forEach(uri => {
					newUriEventMap[uri] = [];
					if (eventsScopeMap[uri]) {
						eventsScopeMap[uri].forEach(scope => {
							scope.events.forEach(event => {
								if (inRange(event, props.since, props.until)) {
									newUriEventMap[uri].push({ ...event, incident: eventTypeMap[event.eventType].incident });
								}
							});
						});
					}
				});
			}
			setUriEventMap(newUriEventMap);
		}
	}, [eventsScopeMap, eventTypeMap, props.since, props.until, props.uris, selectedEventsUris, displayEvents]);

	return <TimeRangeScale ranges={absent} uriEventMap={uriEventMap} {...rest} />;
};

export default connect(
	state => ({
		history: state.history.messages.map,
		eventsScopeMap: state.history.events.map,
		eventTypeMap: state.registry.eventTypes.typeMap,
		displayEvents: state.appDeviceEvents.timeMachine && state.appDeviceEvents.timeMachine.display,
		selectedEventsUris: state.appDeviceEvents.timeMachine && state.appDeviceEvents.timeMachine.selectedEventsUris
	})
)(ScaleControl);
