import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { useI18n } from '../../../i18n';

import { shallowEqual } from '../../lib/objects';

import { setPageTitle } from '../../misc/page';
import reportIcon from '../../img/icons/report.png';

import ActionBar from '../general/actionbar/ActionBar';
import ActionEdit from '../share/actionbar/ActionEdit';
import ActionRemove from '../share/actionbar/ActionRemove';
import ActionBack from '../share/actionbar/ActionBack';
import RadioGroup from '../general/form/RadioGroup';
import Radio from '../general/form/Radio';
import SelectPicker from '../general/form/SelectPicker';

import ActionAdd from '../share/actionbar/ActionAdd';

import ReportParametrizator from '../custom/reports/ReportParametrizator';
import ReportType from '../custom/reports/ReportType';
import { isSchedulableReportType, ScheduleManager } from '../custom/reports/schedules/ScheduleManager';

import Idling from '../custom/reports/renderer/Idling';
import Commute from '../custom/reports/renderer/Commute';
import DeviceRegistry from '../custom/reports/renderer/DeviceRegistry';
import DeviceSituations from '../custom/reports/renderer/DeviceSituations';
import DeviceStatus from '../custom/reports/deviceStatus/renderer';
import DeviceUtilization from '../custom/reports/deviceUtilization/renderer';
import DrivingBehaviour from '../custom/reports/drivingBehaviour/renderer';
import DeviceUsages from '../custom/reports/renderer/DeviceUsages';
import FuelLevel from '../custom/reports/renderer/FuelLevel';
import LocationHistory from '../custom/reports/renderer/LocationHistory';
import Ridership from '../custom/reports/renderer/Ridership';
import Speeding from '../custom/reports/renderer/Speeding';
import TemperatureChange from '../custom/reports/renderer/TemperatureChange';
import TemperatureHistory from '../custom/reports/renderer/TemperatureHistory';
import Trip from '../custom/reports/renderer/Trip';
import TripHistory from '../custom/reports/renderer/TripHistory';
import ZoneVisits from '../custom/reports/renderer/ZoneVisits';

import './reportsPage.scss';

const ROOT_URL = '/reports';
const PAGE_TITLE = 'reports';

const renderers = {
	[ReportType.LocationHistory]: LocationHistory,
	[ReportType.Trip]: Trip,
	[ReportType.TripHistory]: TripHistory,
	[ReportType.TemperatureChange]: TemperatureChange,
	[ReportType.TemperatureHistory]: TemperatureHistory,
	[ReportType.DeviceUsages]: DeviceUsages,
	[ReportType.DeviceSituations]: DeviceSituations,
	[ReportType.DeviceStatus]: DeviceStatus,
	[ReportType.DeviceUtilization]: DeviceUtilization,
	[ReportType.DrivingBehaviour]: DrivingBehaviour,
	[ReportType.Speeding]: Speeding,
	[ReportType.Idling]: Idling,
	[ReportType.DeviceRegistry]: DeviceRegistry,
	[ReportType.ZoneVisits]: ZoneVisits,
	[ReportType.FuelLevel]: FuelLevel,
	[ReportType.Ridership]: Ridership,
	[ReportType.Commute]: Commute
};


function TypeSelector(props) {
	const { fc } = useI18n();

	const options = Object.values(ReportType.enum).map(type => ({
		value: type, label: fc(ReportType.name(type))
	}));
	
	return <SelectPicker className="types" data={options} cleanable={false} searchable={false} {...props}/>;
}

const ReportingMode = {
	Interactive: {name: 'interactive', label: 'interactive reporting'}
	, Schedules: {name: 'schedules', label: 'report schedules'}
};
Object.assign(ReportingMode, (() => {
	const values = Object.values(ReportingMode), index = new Map(values.map((mode) => [mode.name, mode]));
	return {
		values: () => values
		, get: name => index.get(name)
	};
})());

function ModeSelector(props) {
	const {fc} = useI18n();
	const {value, ...restProps} = props;
	const options = ReportingMode.values().map(
		({name, label}) => <Radio key={name} value={name}><span className='label'>{fc(label)}</span></Radio>
	);
	return <RadioGroup className="modes" value={value?.name} {...restProps}>{options}</RadioGroup>;
}

const routePath = (type, mode) => {
	if (!type) type = ReportType.LocationHistory;
	const base = [ROOT_URL, ReportType.toUrl(type)];
	if (isSchedulableReportType(type) && mode == ReportingMode.Schedules) base.push(mode.name);
	return base.join('/');
};

function ReportsPage(props) {
	const { f, fc } = useI18n();
	const reportType = props.match.params.type != null ? ReportType.fromUrl(props.match.params.type) : null;
	const mode = ReportingMode.get(props.match.params.mode) || ReportingMode.Interactive;
	const viewing = mode == ReportingMode.Interactive ? props.match.params.phase == 'view' : null;
	const report = props.reports[reportType];
	const [barActions, setBarActions] = useState({});

	useEffect(() => {
		if (viewing && !report.parameters) props.history.replace(routePath(reportType));
	}, [viewing]);

	useEffect(() => {
		if (reportType == null || !renderers[reportType]) props.history.replace(routePath());
		else setPageTitle(fc(ReportType.name(reportType)), fc(PAGE_TITLE));
	}, [reportType]);

	const onTypeChange = type => {
		props.history.replace(routePath(type, mode));
	};

	const onModeChange = name => {
		const mode = ReportingMode.get(name) || ReportingMode.Interactive;
		props.history.replace(routePath(reportType, mode));
	};

	const configureActions = actions => {
		if (!shallowEqual(actions, barActions)) setBarActions(actions);
	};

	const actionBar = () => {
		const actions = [];
		if (barActions.onBack) actions.push(<ActionBack key={1} onClick={barActions.onBack}/>);
		if (barActions.onAdd) actions.push(<ActionAdd key={2} onClick={barActions.onAdd}/>);
		if (barActions.modification) actions.push(<ActionEdit key={3}/>, <ActionRemove key={4}/>);
		if (actions.length == 0) return null;
		return <ActionBar className={'action-bar-default'} disabled={barActions.disabled}>{actions}</ActionBar>;
	};

	let content = null;
	if (viewing && report.parameters != null && !report.exporting) {
		const Component = renderers[reportType];
		content = <Component/>;
	} else {
		content = (
			<div className="wrapper">
				<div className="side">
					<div className="header">
						<img src={reportIcon} alt="" />
						<span className="capitalize">{f(PAGE_TITLE)}</span>
					</div>
					{mode == ReportingMode.Schedules && actionBar()}
					<div className="section">
						<TypeSelector label={f('type of report')} value={reportType} onChange={onTypeChange}/>
						{isSchedulableReportType(reportType) && <ModeSelector value={mode} onChange={onModeChange}/>}
					</div>
				</div>
				<div className="content">
					{mode == ReportingMode.Interactive 
						? <ReportParametrizator type={reportType}/>
						: <ScheduleManager reportType={reportType} configureActions={configureActions}/>
					}
				</div>
			</div>
		);
	}

	return (
		<div className="app-page reports">
			{content}
		</div>
	)
}

ReportsPage.Url = ROOT_URL;

export default connect(state => {
	return ({
		reports: state.reports
	})
})(withRouter(ReportsPage));
