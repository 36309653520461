import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { className, fromProps } from '../../../lib/className';
import { Avatar } from 'rsuite';
import { FiSlash } from 'react-icons/fi';
import { useI18n } from '../../../../i18n';
import Popup from '../../general/Popup';
import List from '../../general/list/List';
import ListItem from '../../general/list/ListItem';
import { getAssetTypeIcon, IconSize } from '../../../img';
import { capitalize } from '../../../misc/misc';
import './deviceAssetTypeIconPicker.scss';

/**
 * @param {Object} props
 * @param {Array.<number>} [props.value]
 * @param {function} props.onChange
 * @param {string} [props.title]
 */

function DeviceAssetTypeIconPicker(props) {
	const { f, fc } = useI18n();
	const [opened, setOpened] = useState(false);
	const boardBox = useRef(null);

	const setIsOpen = (value) => {
		setOpened(value);
	}

	const onClose = () => {
		setOpened(false);
	}

	const onSelect = (category) => {
		const newSelect = [];
		props.value && props.value.forEach(id => !props.assetTypeCategories.map[id] && newSelect.push(id));
		newSelect.push(category.categoryId);
		props.onChange(newSelect);
		onClose();
	}
	let categoryItems = null;
	let currentCatgory = null;
	if (props.assetTypeCategories.list && props.assetTypeCategories.list.length > 0) {
		const compare = (a, b) => {
			const aName = f({ prefix: 'category', id: a.name });
			const bName = f({ prefix: 'category', id: b.name });
			if (aName.toLowerCase() > bName.toLowerCase()) return 1;
			if (aName.toLowerCase() < bName.toLowerCase()) return -1;
			return 0;
		};
		categoryItems = props.assetTypeCategories.list.sort(compare).map(category => {
			const type = category.mnemonics;
			const categoryName = fc({ prefix: 'category', id: category.name });
			if (props.value && props.value.includes(category.categoryId)) {
				currentCatgory = category;
			}
			return (
				<ListItem
					key={category.categoryId}
					className={className({ 'selected': props.value && props.value.includes(category.categoryId) })}
					onClick={() => onSelect(category)}
				>
					<img src={getAssetTypeIcon(type, IconSize.MD)} alt="" title={categoryName} />
					<span>{categoryName}</span>
				</ListItem>
			);
		});
	}

	return (
		<div className="device-asset-type-icon-picker">
			<Popup
				isOpen={opened}
				position="top-right"
				disabled={props.disabled}
				setIsOpen={setIsOpen}
				customRef={boardBox}
				fill
				className={className('device-asset-type-icon-picker', fromProps(props))}
				content={(
					<div className="device-asset-type-icon-picker-content">
						<List>
							{categoryItems}
						</List>
					</div>
				)}
			>
				<div className="device-picker-icon" title={capitalize(props.title)}>
					<Avatar size='lg' className="clickable">
						{currentCatgory
							? <img src={getAssetTypeIcon(currentCatgory.mnemonics, IconSize.LG)} alt="" title={fc({ prefix: 'category', id: currentCatgory.name })} />
							: <FiSlash title={fc('asset type is not assigned')} />
						}
					</Avatar>
				</div>
			</Popup>
		</div>
	);
}

export default connect(
	state => ({
		assetTypeCategories: state.categories.assetTypes
	})
)(DeviceAssetTypeIconPicker);
