export const camelCaseToKebabCase = (string) => {
	return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
};

export const kebabCaseToCamelCase = (string) => {
	return string.split('-').map((item, index) =>
		index
			? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
			: item
	).join('');
}