import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useI18n } from 'i18n';
import { FieldErrorStyled } from 'ui/pages/attribute-range-detectors/detector-form.styled';
import { IMessageField, IMnemonic } from 'types/registry';
import { SUPPORTED_ATTRIBUTE_TYPES } from 'constants/form';
import { IAttributeDetectorForm } from 'hooks/attribute-range-detectors/useAttributeDetectorForm';
import MessageFieldPicker from 'core/react/share/message/MessageFieldPicker';

interface IAttributeSelectProps {
  isDisabled?: boolean;
}

export const AttributeSelect: FC<IAttributeSelectProps> = ({ isDisabled }) => {
  const { f, fc } = useI18n();

  const { control, formState } = useFormContext<IAttributeDetectorForm>();
  const { errors } = formState;

  const filterAttributes = (field: IMessageField, type: IMnemonic) =>
    SUPPORTED_ATTRIBUTE_TYPES.includes(type.mnemonics);

  return (
    <>
      <Controller
        control={control}
        name="fieldId"
        rules={{ required: fc('please select attribute') }}
        render={({ value, onChange }) => (
          <MessageFieldPicker
            disabled={isDisabled}
            filter={filterAttributes}
            placeholder={f('select attribute')}
            value={value}
            onChange={onChange}
          />
        )}
      />
      {!!errors.fieldId?.message && <FieldErrorStyled>{errors.fieldId.message}</FieldErrorStyled>}
    </>
  );
};
