import styled from 'styled-components';

import { Colors } from 'ui/constants/colors';

export const ConfirmModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 345px;
  height: 143px;
  background-color: ${Colors.White};
  border: 1px solid ${Colors.WhiteSmoke};
  box-shadow: 0 3px 6px ${Colors.BlackRussian};
`;

export const ConfirmModalTextStyled = styled.div`
  font: 14px Muli;
  line-height: 20px;
  text-align: center;
  width: 300px;
  margin-bottom: 16px;
`;

export const ConfirmModalButtonsStyled = styled.div`
  display: flex;
`;

export const ConfirmModalButton = styled.button`
  font: bold 10px Muli;
  height: 32px;
  width: 89px;
  border-radius: 15px;
  color: ${Colors.White};

  &:first-child {
    padding: 0 15px;
    margin-right: 10px;
    background-color: ${Colors.SilverPolish};
  }

  &:last-child {
    flex: 1;
    background-color: ${Colors.AquaBelt};
  }

  &:disabled {
    opacity: 0.8;
  }
`;
