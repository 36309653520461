import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { actions } from './actions';
import { createEpic } from './epic';
import { createReducer } from './reducer';
import { getHistoryManagerByName } from './managers';

export const reducer = combineReducers({ // redux key should have the same object key that actions key
	events: createReducer(actions.events, getHistoryManagerByName('events')),
	messages: createReducer(actions.messages, getHistoryManagerByName('messages')),
	assetPresences: createReducer(actions.assetPresences, getHistoryManagerByName('assetPresences'))
});

export const epic = combineEpics(
	createEpic(actions.events, getHistoryManagerByName('events'), 'events'),
	createEpic(actions.messages, getHistoryManagerByName('messages'), 'messages'),
	createEpic(actions.assetPresences, getHistoryManagerByName('assetPresences'), 'assetPresences')
);

export { actions };
