import React from 'react';
import Hover from '../Hover';
import EventField from '../../share/events/EventField';
import { className } from '../../../lib/className';

/**
 * @param {Object} props
 * @param {string} props.uri
 * @param {number} props.left
 * @param {cx.ods.devices.EventDetails} props.event
 * @param {boolean} [props.incident]
 * @param {function} [props.onClick]
 */

function EventTick(props) {
	return (
		<Hover onHover={<EventField event={props.event} uri={props.uri} showDate />}>
			<div
				className={className('event-tick', { 'incident': props.incident })}
				style={{ left: props.left }}
				onClick={props.onClick}
			/>
		</Hover>
	);
}
export default EventTick;