import React, { FC } from 'react';

import { TableStyled } from 'ui/table.styled';
import { ITableColumn } from 'types/table';
import { useTableHook } from 'hooks/useTable';
import { TableHeader } from './table-header';
import { TableContent } from './table-content';

interface ITableProps {
  isLoading: boolean;
  columns: ITableColumn[];
  data: Record<string, unknown>[];
}

export const Table: FC<ITableProps> = (props) => {
  const { headerGroups, tableContentProps, getTableProps } = useTableHook(props);

  return (
    <TableStyled {...getTableProps()}>
      <TableHeader headerGroups={headerGroups} />
      <TableContent {...tableContentProps} />
    </TableStyled>
  );
};
