import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {useI18n} from '../../../../../i18n';

import {actions} from '../../../../redux/api/reports';
import {statisticsGroups} from '../../../../redux/api/reports/deviceUtilization';

import Loader from '../../../general/Loader';
import NoDataView from '../../../general/view/NoDataView';
import ScrollList from '../../../general/list/ScrollList';

import ReportHeader from '../renderer/ReportHeader';
import ReportSummary from '../renderer/ReportSummary';

import './renderer.scss';

export default withRouter(connect(state => ({
	state: state.reports.deviceUtilization
	, deviceMap: state.devices.map
}))(props => {
	const {f} = useI18n();

	const reporting = props.state;
	const parameters = reporting.parameters;
	const devices = parameters.uris && parameters.uris.map(uri => props.deviceMap[uri]);
	const report = reporting.report;

	let content = null;
	if (reporting.error) content = <div className="center"><span className="error">{reporting.error}</span></div>;
	else if (report == null) content = <Loader size={Loader.Size.MD}/>;
	else if (report.length == 0) content = <NoDataView message={f('no data for such parameters')}/>
	else content = <ReportContent pending={reporting.pending} report={report}/>;

	const exportReport = () => {
		props.dispatch(actions.deviceUtilization.export.request());
		props.history.goBack();
	};

	return <div className="report device-utilization">
		<ReportHeader 
			title={f('device utilization report')}
			canExport={0 < report?.length} onExport={exportReport}
		/>
		<ReportSummary generatedAt={report?.timeAt} devices={devices}/>
		<div className='content'>{content}</div>
	</div>;
}));

const ReportContent = props => {
	const utilizations = props.report.slice().sort((left, right) => {
		const leftValue = left.phases.active?.duration || 0;
		const rightValue = right.phases.active?.duration || 0;
		return -(leftValue - rightValue);
	});

	return <ScrollList pending={props.pending}>
		<ListHeaders/>
		{utilizations.map((utilization, at) => <ListItem key={utilization.uri} itemNo={at + 1} utilization={utilization}/>)}
	</ScrollList>;
};

const ListHeaders = () => {
	const {f} = useI18n();

	return <div className='headers'>
		<div><span>{f('item No.')}</span></div>
		<div><span>{f('device')}</span></div>
		{statisticsGroups.map(group => <div className='group-header statistics' key={group.label}>
			<span>{f(group.label)}</span>
		</div>)}
		<div className='group-header statistics' key="odometer">
			<span>{f("odometer")}</span>
		</div>
		<div><span>{f('distance')}</span></div>
		<div><span>{f('shifts')}</span></div>
		<div><span>{f('shifts per hour')}</span></div>
		<div><span>{f('violations')}</span></div>
		<div><span>{f('idling')}</span></div>
		<div><span>{f('score')}</span></div>
		{statisticsGroups.map(group => <div className='sub-header statistics' key={group.label}>
			<span>hours</span><span>%</span>
		</div>)}
		<div className='sub-header statistics' key="sub-odometer">
			<span>{f('first')}</span><span>{f('last')}</span>
		</div>
	</div>;
};

const ListItem = connect(state => ({
	deviceMap: state.devices.map
}))(props => {
	const device = props.deviceMap[props.utilization.uri];
	const {phases, totals, score} = props.utilization;

	const activeHours = phases.active?.duration || 0;
	const shifts = phases.shift?.quantity || 0;
	const shiftsFrequency = 0 < activeHours ? Math.round(shifts * 100 / activeHours) / 100 : '';

	const firstOdometer = Math.round(phases.total?.firstOdometer / 1000) || 0;
	const lastOdometer = Math.round(phases.total?.lastOdometer / 1000) || 0;

	return <div className='item'>
		<div>{props.itemNo}</div>
		<div>{device?.denomination()}</div>
		{statisticsGroups.map(group => {
			const duration = phases[group.phase]?.duration || 0;
			const percent = totals.duration ? Math.round(duration * 100 / totals.duration) / 100 : '';
			return <div className='statistics' key={group.label}>
				<span>{duration}</span>
				<span>{percent}</span>
			</div>;
		})}
		<div className='statistics' key="odometer">
			<span>{firstOdometer}</span>
			<span>{lastOdometer}</span>
		</div>
		<div><span>{totals.distance}</span></div>
		<div><span>{shifts}</span></div>
		<div><span>{shiftsFrequency}</span></div>
		<div><span>{totals.violations}</span></div>
		<div><span>{phases.idling?.quantity || 0}</span></div>
		<div><span>{score}</span></div>
	</div>;
});
