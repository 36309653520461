import styled from 'styled-components';

import { Colors } from 'ui/constants/colors';

export const RadioButtonStyled = styled.input`
  -webkit-appearance: none;
  appearance: none;
  margin: 1px 15px 0 0;
  width: 13px;
  height: 13px;
  border: 1px solid ${Colors.DoveGray};
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  ::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    margin: 3px;
  }

  :checked {
    border: 1px solid ${Colors.AquaBelt};

    ::after {
      background-color: ${Colors.AquaBelt};
    }

    :hover {
      background-color: ${Colors.White};

      ::after {
        background-color: ${Colors.AquaBelt};
      }
    }
  }

  :focus {
    outline: 2px solid ${Colors.RossoCorsa};
  }
`;
