import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { meta } from '../../../redux/api/meta';
import { getDeviceFuelLevelBalance } from '../../../misc/fuelLevelBalance';

/**
 * @param {Object} props
 * @param {string} props.uri
 * @param {number} props.value if value equals zero has to display only "0"
 * @param {boolean} [props.withUnits]
 */

function FuelLevelBalance(props) {
	useEffect(() => {
		if (props.properties.map == null && !props.properties.pending) {
			props.dispatch(meta.properties.actions.load.request());
		}
		if (props.units.map == null && !props.units.pending) {
			props.dispatch(meta.units.actions.units.request());
		}
	}, []);

	return (<span>{getDeviceFuelLevelBalance(props.value, props.uri, props.withUnits)}</span>);
}

export default connect(state => ({
	properties: state.meta.properties,
	units: state.meta.units
}))(FuelLevelBalance);