import { useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useI18n } from 'i18n';
import { DEFAULT_SET_VALUE_PROPS } from 'constants/form';
import { unitsString } from 'core/misc/attributeTypes';
import { debounce } from 'helpers/debounce';
import { IAttributeDetectorForm } from './useAttributeDetectorForm';

export const useRangeBoundsFields = (mnemonics: string) => {
  const { fc, f } = useI18n();

  const { control, setValue, formState, trigger } = useFormContext<IAttributeDetectorForm>();

  const leftBound = useWatch({ control, name: 'leftBound' });
  const rightBound = useWatch({ control, name: 'rightBound' });
  const leftOpen = useWatch({ control, name: 'leftOpen' });
  const rightOpen = useWatch({ control, name: 'rightOpen' });

  const units = unitsString(mnemonics);

  const { errors } = formState;
  const boundsError = errors.leftBound?.message || errors.rightBound?.message;
  const boundModes = [
    { value: false, label: fc('inclusive') },
    { value: true, label: fc('exclusive') },
  ];

  const triggerDebounced = useRef(debounce(async (fields: string[]) => await trigger(fields), 10)).current;

  const triggerValidation = () => {
    triggerDebounced.cancel();
    triggerDebounced(['leftLag', 'rightLag', 'leftBound', 'rightBound']);
  };

  const validateLeftBound = (value: string) =>
    !value && !rightBound ? 'please enter at least one of the values' : true;

  const validateRightBound = (value: string) =>
    (!!leftBound && !!value && !rightOpen && Number(value) < Number(leftBound)) ||
    (!!leftBound && !!value && !!rightOpen && Number(value) <= Number(leftBound)) ||
    (!!leftBound && !!value && !!leftOpen && Number(value) <= Number(leftBound))
      ? f('please enter a valid range')
      : true;

  const handleLeftBoundChange = (value: string) => {
    setValue('leftBound', value, DEFAULT_SET_VALUE_PROPS);
    !value && setValue('leftLag', '', DEFAULT_SET_VALUE_PROPS);
    triggerValidation();
  };

  const handleRightBoundChange = (value: string) => {
    setValue('rightBound', value, DEFAULT_SET_VALUE_PROPS);
    !value && setValue('rightLag', '', DEFAULT_SET_VALUE_PROPS);
    triggerValidation();
  };

  const handleBoundModeChange = (field: string) => (value: boolean) => {
    setValue(field, value, DEFAULT_SET_VALUE_PROPS);
    triggerValidation();
  };

  return {
    control,
    boundModes,
    isLeftBound: !!leftBound,
    isRightBound: !!rightBound,
    units,
    boundsError,
    validateLeftBound,
    handleLeftBoundChange,
    validateRightBound,
    handleRightBoundChange,
    handleBoundModeChange,
  };
};
