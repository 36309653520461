import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { TimeRange, createParameters, createScope } from '../../api/history/adapters';
import { getHistoryManagerByName } from '../../api/history/managers';
import { actions as historyActions } from '../../api/history';
import { datetime } from '../../../misc/datetime';
import { HistorySubject } from './player';
import { ActionGeneratorBuilder } from '../../actions';

const defaultState = {
	parameters: null, // { since, until, now, uris },
	prevParameters: null,
	subscribers: {}, // { type: count }
	map: null // { uri: { message, events } }
};

const subscriptionAspects = {
	assetPresences: 'assetPresences',
	events: 'events',
	messages: 'messages',
}

// ---------------------------------------------------------

const actions = new ActionGeneratorBuilder('timeMachineState')
	.type('setParameters', { since: true, until: true, now: true, uris: true })
	.type('setNow', 'now')
	.type('subscribe', 'historyAspect')
	.type('unsubscribe', 'historyAspect')
	.type('setState', 'map')
	.type('updateState')
	.type('vicinityDate', { forward: true, subject: true })
	.build()
;

// ---------------------------------------------------------

const reducer = (state = defaultState, action) => {
	let since = null, until = null, now = null;
	switch (action.type) {
		case actions.setParameters.type:
			if (state.parameters && state.parameters.now.getTime() == action.now.getTime()) {
				now = state.parameters.now;
			} else now = action.now;
			if (state.parameters && state.parameters.since.getTime() == action.since.getTime()) {
				since = state.parameters.since;
			} else since = action.since;
			if (state.parameters && state.parameters.until.getTime() == action.until.getTime()) {
				until = state.parameters.until;
			} else until = action.until;
			state = {
				...state,
				prevParameters: state.parameters,
				parameters: {
					since,
					until,
					now,
					uris: action.uris
				}
			};
			break;
		case actions.setNow.type:
			since = state.parameters.since;
			until = state.parameters.until;
			now = action.now;
			if (now < since || until < now) {
				const oldNow = state.parameters.now.getTime();
				const before = oldNow - since.getTime();
				const after = until.getTime() - oldNow;
				since = new Date(now.getTime() - before);
				until = new Date(now.getTime() + after);
			}
			return {
				...state,
				prevParameters: state.parameters,
				parameters: {
					...state.parameters,
					since, until, now
				}
			};
		case actions.subscribe.type:
			state = {
				...state,
				subscribers: {
					...state.subscribers,
					[action.historyAspect]: state.subscribers[action.historyAspect] ? state.subscribers[action.historyAspect] + 1 : 1
				}
			};
			break;
		case actions.unsubscribe.type:
			state = {
				...state,
				subscribers: {
					...state.subscribers,
					[action.historyAspect]: state.subscribers[action.historyAspect] ? state.subscribers[action.historyAspect] - 1 : 0
				}
			};
			break;
		case actions.setState.type:
			state = {
				...state,
				map: action.map
			};
			break;
	}
	return state;
}

// ---------------------------------------------------------

const equals = (prev, current) => {
	return prev.now.getTime() == current.now.getTime() &&
		prev.since.getTime() == current.since.getTime() &&
		prev.until.getTime() == current.until.getTime() &&
		prev.uris.length == current.uris.length &&
		prev.uris.every(uri => current.uris.includes(uri))
	;
}

const watchParametersEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.setParameters.type, actions.setNow.type, actions.subscribe.type, actions.unsubscribe.type),
		withLatestFrom(state$.pipe(map(state => ({ history: state.history, state: state.timeMachine.state })))),
		switchMap(([action, { history, state }]) => {
			const result = [];
			const prevParameters = state.prevParameters;
			const parameters = state.parameters;
			if (!prevParameters || !equals(prevParameters, parameters)) {
				result.push(actions.updateState());
			}
			if (!parameters.uris || parameters.uris.length == 0) return of(...result);
			const dataScope = createScope(new TimeRange(parameters.since, parameters.until), parameters.uris);
			Object.values(subscriptionAspects).forEach(aspect => {
				if (state.subscribers[aspect]) {
					const manager = getHistoryManagerByName(aspect).getInstance();
					const missingScopes = manager.subtract(dataScope);
					if (missingScopes && !history[aspect].pending) {
						result.push(historyActions[aspect].load.request({
							parameters: createParameters(dataScope, parameters.now, missingScopes)
						}));
					}
				}
			});
			return of(...result);
		})
	);
}

const watchHistoryEpic = (action$, state$) => {
	return action$.pipe(
		ofType(
			actions.updateState.type,
			actions.setNow.type,
			historyActions.events.load.success.type,
			historyActions.messages.load.success.type,
			historyActions.assetPresences.load.success.type
		),
		map(action => {
			const uris = state$.value.pages.timeMachine.selection;
			const now = state$.value.timeMachine.state.parameters.now;
			const messageManager = getHistoryManagerByName('messages').getInstance();
			const messagesState = messageManager.state(uris, now);
			const eventManager = getHistoryManagerByName('events').getInstance();
			const eventsState = eventManager.state(uris, now);
			const assetPresenceManager = getHistoryManagerByName('assetPresences').getInstance();
			const assetPresencesState = assetPresenceManager.state(uris, now);
			const state = uris.reduce((map, uri) => {
				map[uri] = {
					message: messagesState?.[uri],
					events: eventsState?.[uri],
					assetPresences: assetPresencesState?.[uri]
				};
				return map;
			}, {});
			return actions.setState({ map: state });
		})
	);
}

const getObject = (subject, state) => {
	if (subject == HistorySubject.Message) {
		return state.message;
	} else if (subject == HistorySubject.Event) {
		return state.events[0];
	}
};

const vicinityDateEpic = (action$, state$) => { // For TimeMachinePlayerWidget
	return action$.pipe(
		ofType(actions.vicinityDate.type),
		withLatestFrom(state$.pipe(map(state => state.timeMachine.state))),
		map(([action, state]) => {
			const since = state.parameters.since;
			const until = state.parameters.until;
			const uris = state.parameters.uris;
			const forward = action.forward;
			const manager = action.subject == HistorySubject.Message
				? getHistoryManagerByName('messages').getInstance()
				: getHistoryManagerByName('events').getInstance()
			;
			let newNow = null;
			uris.forEach(uri => {
				if (state.map[uri]){
					const object = getObject(action.subject, state.map[uri]);
					if (object) {
						const now = object.generatedAt;
						const date = manager.vicinityDate(uri, now, forward);
						if (date) {
							if (forward && date.getTime() < (newNow && newNow.getTime() || datetime.tomorrow())) {
								newNow = date;
							}
							if (!forward && date.getTime() > (newNow && newNow.getTime() || new Date(0))) {
								newNow = date;
							}
						}
					}
				}
			});
			if (forward && newNow && newNow.getTime() < since.getTime()) newNow = since;
			if (!forward && newNow && newNow.getTime() > until.getTime()) newNow = until;
			const params = state$.value.timeMachine.state.parameters;
			return actions.setParameters({
				since: params.since,
				until: params.until,
				now: newNow ? newNow : (forward ? until : since),
				uris: params.uris
			});
		})
	);
}

const epic = combineEpics(watchParametersEpic, watchHistoryEpic, vicinityDateEpic);

export { actions, reducer, epic };
