import { cx, api, rx } from "../../../../api";
import { ofType, combineEpics } from 'redux-observable';
import { switchMap, takeUntil, map as rxmap, filter, tap, ignoreElements } from 'rxjs/operators';
import { formatDateTime, getTimezoneString } from '../../../../misc/misc';
import { resolver } from './resolver';
import { actions } from '../actions';
import { fc, f } from "../../../../../i18n";
import { errorMap } from "../../../actions";

const requestEpic = (action$) => {
	return action$.pipe(
		ofType(actions.temperatureHistory.request.type),
		switchMap(action =>
			rx(api.reports.temperatureHistory, action.uri, action.parameters).pipe(
				rxmap(operation => actions.temperatureHistory.success({ temperatures: operation.response() })),
				errorMap(actions.temperatureHistory.fail),
				takeUntil(action$.pipe(ofType(actions.temperatureHistory.cancel.type)))
			)
		)
	)
}

const startPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.temperatureHistory.export.type),
		rxmap(action => {
			const state = state$.value.reports.temperatureHistory;
			resolver.resolve(state.list, !state.hasMore);
			if (state.hasMore) {
				return actions.temperatureHistory.request({ uri: action.uri, parameters: state.parameters });
			} else {
				return actions.temperatureHistory.success({});
			}
		})
	)
}

const processPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.temperatureHistory.success.type),
		filter(action => {
			const state = state$.value.reports.temperatureHistory;
			if (state.exporting) resolver.resolve(action.temperatures, !state.hasMore);
			return state.exporting && state.hasMore;
		}),
		rxmap(action => {
			const state = state$.value.reports.temperatureHistory;
			return actions.temperatureHistory.request({ uri: state.uri, parameters: state.parameters });
		})
	)
}

const exportCsvEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.temperatureHistory.exportProgress.type),
		filter(action => action.progress == 100),
		rxmap(action => {
			const state = state$.value.reports.temperatureHistory;
			const device = state$.value.devices.map[state.uri];
			// report header
			let csv = '"'
				+ fc('report type')
				+ '",'
				+ fc('generated')
				+ ','
				+ fc('timezone')
				+ ','
				+ fc('device')
				+ ','
				+ fc('since')
				+ ','
				+ fc('until')
				+ ',"'
				+ fc('sampling interval') + ', ' + f('units.s')
				+ '"\n';
			csv += fc('temperature history');  // report type
			csv += ',' + formatDateTime(cx.now()); // generated at
			csv += ',' + getTimezoneString(); // timezone at
			csv += ',"' + (device.denomination() || device.uri) + '"';
			csv += ',"' + formatDateTime(state.parameters.timeRange.since) + '"';
			csv += ',"' + formatDateTime(state.parameters.timeRange.until) + '"';
			csv += ',' + state.parameters.interval;
			csv += "\n\n";
			// content header
			csv += fc('since') + ',' + fc('until');
			csv += ',"T1 ' + f('short.min') + ', \u00B0C","T1 ' + f('short.max') + ', \u00B0C","T1 ' + f('quantity') + '"';
			csv += ',"T2 ' + f('short.min') + ', \u00B0C","T2 ' + f('short.max') + ', \u00B0C","T2 ' + f('quantity') + '"';
			csv += ',' + fc('location') + ',' + fc('address');
			csv += "\n";
			// content
			state.list.forEach(reportItem => {
				// start time
				csv += '"' + formatDateTime(reportItem.since) + '"';
				// end time
				csv += ',"' + formatDateTime(reportItem.until) + '"';
				// temperature 1
				let t = reportItem.temperature1;
				if (t) {
					csv += ',' + t.minimum + ',' + t.maximum + ',' + t.quantity;
				} else {
					csv += ',-,-,-';
				}
				// temperature 2
				t = reportItem.temperature2;
				if (t) {
					csv += ',' + t.minimum + ',' + t.maximum + ',' + t.quantity;
				} else {
					csv += ',-,-,-';
				}
				// location, address
				if (reportItem.latitude != null && reportItem.longitude != null) {
					csv += ',(' + reportItem.latitude + ';' + reportItem.longitude + ')';
					const entryKey = resolver.key(reportItem.latitude, reportItem.longitude);
					const address = resolver.resolved[entryKey]?.getAddress();
					if (address) csv += ',"' + address.format() + '"';
					else csv += ",-";
				} else {
					csv += ",-,-";
				}
				//
				csv += "\n";
			});
			return actions.temperatureHistory.exportDone({ csv });
		}),
	)
}

const exportClearEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.temperatureHistory.exportClear.type),
		tap(() => resolver.clear()),
		ignoreElements()
	);
}

const epic = combineEpics(requestEpic, startPrepareEpic, processPrepareEpic, exportCsvEpic, exportClearEpic);

export { epic };
