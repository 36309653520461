import { cx, api, rx } from "../../../../api";
import { ofType, combineEpics } from 'redux-observable';
import { switchMap, takeUntil, map as rxmap, filter } from 'rxjs/operators';
import { formatDateTime, getTimezoneString } from '../../../../misc/misc';
import { actions } from '../actions';
import { fc, f } from "../../../../../i18n";
import { errorMap } from "../../../actions";

const requestEpic = (action$) => {
	return action$.pipe(
		ofType(actions.temperatureChange.request.type),
		switchMap(action =>
			rx(api.reports.temperatureChange, action.uri, action.parameters).pipe(
				rxmap(operation => actions.temperatureChange.success({ intervals: operation.response() })),
				errorMap(actions.temperatureChange.fail),
				takeUntil(action$.pipe(ofType(actions.temperatureChange.cancel.type)))
			)
		)
	)
}

const startPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.temperatureChange.export.type),
		rxmap(action => {
			const state = state$.value.reports.temperatureChange;
			if (state.hasMore) {
				return actions.temperatureChange.request({ uri: action.uri, parameters: state.parameters });
			} else {
				return actions.temperatureChange.exportProgress({ progress: 100 });
			}
		})
	)
}

const processPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.temperatureChange.success.type),
		filter(action => state$.value.reports.temperatureChange.exporting),
		rxmap(action => {
			const state = state$.value.reports.temperatureChange;
			return state.hasMore
				? actions.temperatureChange.request({ uri: state.uri, parameters: state.parameters })
				: actions.temperatureChange.exportProgress({ progress: 100 })
			;
		})
	)
}

const exportCsvEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.temperatureChange.exportProgress.type),
		filter(action => action.progress == 100),
		rxmap(action => {
			const state = state$.value.reports.temperatureChange;
			const device = state$.value.devices.map[state.uri];
			// report header
			let csv = '"'
				+ fc('report type')
				+ '",'
				+ fc('generated')
				+ ','
				+ fc('timezone')
				+ ','
				+ fc('device')
				+ ','
				+ fc('since')
				+ ','
				+ fc('until')
				+ ',"'
				+ fc('temperature delta')
				+ ', \u00B0C"\n';
			csv += fc('temperature change');  // report type
			csv += ',' + formatDateTime(cx.now()); // generated at
			csv += ',' + getTimezoneString(); // timezone at
			csv += ',"' + (device.denomination() || device.uri) + '"';
			csv += ',"' + formatDateTime(state.parameters.timeRange.since) + '"';
			csv += ',"' + formatDateTime(state.parameters.timeRange.until) + '"';
			csv += ',' + state.parameters.delta;
			csv += "\n\n";
			// content header
			csv += fc('since') + ',' + fc('until');
			csv += ',"T1 ' + f('short.min') + ', \u00B0C","T1 ' + f('short.max') + ', \u00B0C","T1 ' + f('quantity') + '"';
			csv += ',"T2 ' + f('short.min') + ', \u00B0C","T2 ' + f('short.max') + ', \u00B0C","T2 ' + f('quantity') + '"';
			csv += "\n";
			// content
			state.list.forEach(reportItem => {
				// start time
				csv += '"' + formatDateTime(reportItem.since) + '"';
				// end time
				csv += ',"' + formatDateTime(reportItem.until) + '"';
				// temperature 1
				var t = reportItem.temperature1;
				if (t) {
					csv += ',' + t.minimum + ',' + t.maximum + ',' + t.quantity;
				} else {
					csv += ',-,-,-';
				}
				// temperature 2
				t = reportItem.temperature2;
				if (t) {
					csv += ',' + t.minimum + ',' + t.maximum + ',' + t.quantity;
				} else {
					csv += ',-,-,-';
				}
				csv += "\n";
			});
			return actions.temperatureChange.exportDone({ csv });
		}),
	)
}

const epic = combineEpics(requestEpic, startPrepareEpic, processPrepareEpic, exportCsvEpic);

export { epic };
