import { actions } from './actions';
import { rx, api } from '../../../api';
import { of } from 'rxjs';
import { ofType, combineEpics } from 'redux-observable';
import { map as rxmap, mergeMap, catchError, takeUntil, filter } from 'rxjs/operators';
import { actions as latestEventsActions } from '../latestEvents';

const findEpic = (action$) => {
	return action$.pipe(
		ofType(actions.find.request.type),
		filter(action => !!action.filter),
		mergeMap(action => {
			return rx(api.events.find, action.uri, action.filter).pipe(
				rxmap(operation => actions.find.success({ uri: action.uri, uid: action.uid, list: operation.response() })),
				catchError(error => actions.find.fail({ uri: action.uri, uid: action.uid, errorMessage: error.userMessage || error.message })),
				takeUntil(action$.pipe(ofType(actions.find.cancel.type, actions.clear.type)))
			)
		})
	)
};

const watchNewEventsEpic = (action$, state$) => {
	return action$.pipe(
		ofType(latestEventsActions.find.success.type),
		filter(action => {
			const filteredEventsState = state$.value.deviceFilteredEvents;
			const newUris = action.maps.recentMap && Object.keys(action.maps.recentMap).filter(uri => {
				if (filteredEventsState && filteredEventsState[uri]) {
					const uids = Object.keys(filteredEventsState[uri]);
					return uids.some(uid => {
						const eventTypes = filteredEventsState[uri][uid].filter.eventTypes;
						const recentEvents = action.maps.recentMap[uri];
						return eventTypes && eventTypes.some(eventType => recentEvents && recentEvents.some(eventDetails => eventDetails.eventType === eventType));
					});
				} else {
					return false;
				}
			});
			return filteredEventsState && newUris && newUris.length > 0;
		}),
		mergeMap(action => {
			const emit = [];
			const filteredEventsState = state$.value.deviceFilteredEvents;
			const recentMap = action.maps.recentMap;
			if (recentMap && filteredEventsState) {
				Object.keys(recentMap).forEach(uri => {
					if (filteredEventsState && filteredEventsState[uri]) {
						Object.keys(filteredEventsState[uri]).forEach(uid => {
							const filter = filteredEventsState[uri][uid].filter;
							const recentEvents = action.maps.recentMap[uri];
							if (filter && filter.eventTypes) {
								const canDispatch = filter.eventTypes.some(eventType => recentEvents && recentEvents.some(eventDetails => eventDetails.eventType === eventType));
								if (canDispatch) {
									emit.push(actions.find.request({ uri, uid, filter }));
								}
							}
						});
					}
				});
			}
			return of.apply(this, emit);
		})
	)
}

const epic = combineEpics(findEpic, watchNewEventsEpic);

export { epic };
