import React, { FC, useEffect, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CardCounter, CardIndicator, TaxiwayCard } from '../../../../ui/widgets/RunwayInfo.styled';
import { AllRoutes } from '../../../../constants/routes';
import { RuleGroup } from '../../../../constants/issues';
import { actions } from '../../../redux/api/veta/taxiwayGuardDigest';
import { actions as taxiwayActions } from '../../../redux/api/veta/taxiways';
import { taxiwayDigestSelector, taxiwaysSelector } from '../../../redux/selectors/registry';

const TaxiwayIncidents: FC<{ customRef: React.RefObject<any> }> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const guarded = useSelector(taxiwayDigestSelector);
  const taxiways = useSelector(taxiwaysSelector);

  const getTaxiwaysGuardedCount = () => {
    let count = 0;

    if (taxiways.list && guarded?.digests) {
      taxiways.list.forEach((taxiway) => {
        count = count + guarded?.digests[taxiway.id]?.guardZone.present;
      });
    }

    return count;
  };

  useImperativeHandle(props.customRef, () => ({
    title: () => 'Taxiway incidents',
  }));

  const navigateToTaxiwayIssues = () => {
    history.push(`${AllRoutes.Issues}?tab=${RuleGroup.TAXIWAY}`);
  };

  useEffect(() => {
    dispatch(actions.subscribe());
    dispatch(taxiwayActions.load.request());

    return () => {
      dispatch(actions.unsubscribe());
    };
  }, []);

  return (
    <TaxiwayCard onClick={navigateToTaxiwayIssues}>
      <CardIndicator color={'#005095'}>Total no. of Non-CAT1 Vehicles in Taxiways</CardIndicator>
      <CardCounter color={'#005095'}>{getTaxiwaysGuardedCount()}</CardCounter>
    </TaxiwayCard>
  );
};

export default TaxiwayIncidents;
