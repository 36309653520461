import React from 'react';
import { connect } from 'react-redux';
import CategoriesControl from '../../../../share/categories/CategoriesControl';
import EventTypePicker from '../../../../share/events/EventTypePicker';
import Form from '../../../../general/form/Form';
import { useI18n } from '../../../../../../i18n';

/**
 *
 * @param {Object} props
 * @param {Array.<number>} [props.categoryIds]
 * @param {Array.<number>} [props.eventTypes]
 */

function DeviceEventsConfiguration(props) {
	const { f } = useI18n();
	return (<>
		<CategoriesControl
			className="event-categories widget-configuration"
			categoryIds={props.categoryIds}
			comprisingIds={props.eventTypeCategories.root ? [props.eventTypeCategories.root.categoryId] : []}
		/>
		<Form.Control
			controlType={EventTypePicker}
			controlName="eventTypes"
			label={f('event types')}
			initialValue={props.eventTypes}
		/>
	</>);
}

export default connect(state => ({
	eventTypeCategories: state.categories.eventTypes
}))(DeviceEventsConfiguration);