import React, { useRef, useState } from 'react';
import moment from "moment/moment";

import { useI18n } from '../../../../i18n';
import { cx } from '../../../api';
import {connect, useSelector} from 'react-redux';
import Form from '../../general/form/Form';
import Button from '../../general/form/Button';
import DeviceIdentityView from './DeviceIdentityView';
import DevicePresetCategoriesView from './DevicePresetCategoriesView';
import { actions } from '../../../redux/api/devices';
import { regex } from '../../../app/regex';
import DeviceCategoriesControl from './DeviceCategoriesControl';
import DeviceAssetTypeIconPicker from './DeviceAssetTypeIconPicker';
import { className, fromProps } from '../../../lib/className';
import { DeviceDetailsProxy } from '../../../api/device'; // eslint-disable-line
import { localeSelector } from 'core/redux/selectors/locale';
import './deviceForm.scss';

/**
 * @param {Object} props
 * @param {DeviceDetailsProxy} props.device
 * @param {boolean} [props.hideMainFields]
 * */

function DeviceForm(props) {
	const { f } = useI18n();
	const { device } = props;

	const locale = useSelector(localeSelector);
	const [categoryIds, setCategoryIds] = useState(device && device.categoryIds || []);
	const submitHook = useRef(null);

	const isDeviceExpired = moment(device.expiresAt).isSameOrBefore(moment());
	const deviceExpirationDate = moment(device.expiresAt).locale(locale).format('DD/MM/YYYY');

	const onSubmit = (deviceInfo) => {
		props.dispatch(actions.update.request({ uri: device.uri, deviceInfo }));
	}

	const submit = () => {
		submitHook.current();
	}

	return (
		<div className={className('device-form', fromProps(props))}>
			<Form
				disabled={props.devices.pending}
				onSubmit={onSubmit}
				submitHook={submitHook}
				objectType={cx.ods.devices.DeviceInfo}
				error={props.devices.error}
				footer={
					<Button onClick={submit} disabled={props.devices.pending}>{f('save')}</Button>
				}
			>
				<Form.ControlGroup className="description-fields">
					<Form.Control
						controlName="name"
						controlType={Form.Control.Type.Input}
						label={f('name')}
						initialValue={device.name}
						hidden={props.hideMainFields}
					/>
					<Form.Control
						controlName="categoryIds"
						controlType={DeviceAssetTypeIconPicker}
						value={categoryIds}
						onChange={setCategoryIds}
						hidden={props.hideMainFields}
					/>
				</Form.ControlGroup>
				<Form.Control
					controlName="msisdn"
					controlType={Form.Control.Type.Input}
					controlValidator={(value) => {
						if (value != null) {
							return !regex.phone.test(value) && f("please enter valid phone number without '+' sign");
						}
					}}
					label={f('phone number')}
					initialValue={device.msisdn}
					hidden={props.hideMainFields}
				/>
				{!props.hideMainFields && <>
					<DeviceIdentityView device={device} />
					{!!device.expiresAt && (
						<div>
							<div className="label">{f('expires on')}</div>
							<div className={isDeviceExpired ? 'expired' : ''}>{deviceExpirationDate}</div>
						</div>
					)}
					<DevicePresetCategoriesView device={device}/>
					<DeviceCategoriesControl categoryIds={categoryIds} onChange={setCategoryIds}/>
				</>}
				{props.children}
			</Form>
		</div>
	);
}

export default connect(state => {
	return {
		devices: state.devices
	}
})(DeviceForm);
