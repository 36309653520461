import { store } from '../../../redux/store';
import { DeviceStatus } from '../../../lib/device';
import { datetime } from '../../../misc/datetime';
import { actions } from './index';

class StatusUpdateDaemon {

	static getInstance() {
		if (!StatusUpdateDaemon.instance) StatusUpdateDaemon.instance = new StatusUpdateDaemon();
		return StatusUpdateDaemon.instance;
	}

	constructor() {
		this.map = {};
		this.wakeupAt = null;
		this.wakeupUri = null;
		this.onTimer = this.onTimer.bind(this);
	}

	updateAt(status) {
		if (status != DeviceStatus.Stale && status != DeviceStatus.Offline) {
			return new Date().getTime() + datetime.MINUTE + 1000;
		} else if (status == DeviceStatus.Offline) {
			return new Date().getTime() + 14 * datetime.MINUTE + 1000;
		}
		return null;
	}

	onChange(uri, status) {
		const updateAt = this.updateAt(status);
		if (updateAt) {
			this.map[uri] = updateAt;
			if (!this.wakeupAt || updateAt < this.wakeupAt) this.reschedule(uri, updateAt);
		} else {
			delete this.map[uri];
		}
	}

	reschedule(uri, wakeupAt) {
		if (this.timer) {
			clearTimeout(this.timer);
			this.timer = null;
		}
		this.wakeupAt = wakeupAt;
		this.wakeupUri = uri;
		this.timer = setTimeout(this.onTimer, wakeupAt - new Date().getTime());
	}

	onTimer() {
		const wakeupUri = this.wakeupUri;
		delete this.map[this.wakeupUri];
		let updateAt = null, updateUri = null;
		Object.keys(this.map).forEach(uri => {
			const time = this.map[uri];
			if (!updateAt || time < updateAt) {
				updateAt = time;
				updateUri = uri;
			}
		});
		if (updateAt) this.reschedule(updateUri, updateAt);
		store.dispatch(actions.update({ uri: wakeupUri }));
	}

	_clearTimer() {
		if (this.timer) {
			clearTimeout(this.timer);
			this.timer = null;
		}
	}

	reset() {
		this._clearTimer();
		this.map = {};
		this.wakeupAt = null;
		this.wakeupUri = null;
	}
}

export default StatusUpdateDaemon;
