import styled from 'styled-components';

import { Colors } from 'ui/constants/colors';
import { SelectHeaderStyled, SelectOptionsStyled, SelectOptionStyled } from '../molecules/select.styled';

export const RunwaysListStyled = styled.div`
  ${SelectHeaderStyled} {
    border: 1px solid #e5e5ea;
    border-radius: 2px;
    font-weight: 400;
    font-size: 13px;
    height: 34px;
    color: #8e8e93;
    font-family: Muli;
    line-height: 1.42857143;
  }
  ${SelectOptionsStyled} {
    z-index: 99999999;
  }
  ${SelectOptionStyled} {
    font-weight: 400;
    font-size: 13px;
    color: #8e8e93;
    font-family: Muli;
    line-height: 1.42857143;
  }
`;

export const CardCounter = styled.div<{ color: string }>`
  font-size: 28px;
  font-weight: bold;
  color: ${(props) => props.color};
`;

export const RunwayItem = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  color: #00adc6;
  border: 1px solid #e5e5ea;
`;

export const RunwayCards = styled.div`
  display: flex;
  margin: 20px 10px;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-content: center;
  align-items: center;
`;

export const OpenedRunwayCard = styled.div`
  width: 65px;
  height: 86px;
  cursor: pointer;
  background: #eceff6 0 0 no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const CardIndicator = styled.div<{ color: string }>`
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 43px;
  height: 21px;
  color: white;
  font-size: 11px;
  background-color: ${(props) => props.color};
`;

export const TaxiwayCard = styled.div`
  width: 100%;
  cursor: pointer;
  height: 98px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #eceff6 0 0 no-repeat padding-box;
  margin-bottom: 10px;
  & > ${CardCounter} {
    margin-bottom: 10px;
  }
  & > ${CardIndicator} {
    margin-top: 0;
    border-radius: 10px 10px 0 0;
    padding-top: 6px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 10px;
    height: 43px;
    width: 100%;
  }
`;

export const ClosedRunwayCard = styled.div`
  width: 115px;
  cursor: pointer;
  height: 98px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #eceff6 0 0 no-repeat padding-box;
  margin-bottom: 10px;
  & > ${CardCounter} {
    margin-bottom: 10px;
  }
  & > ${CardIndicator} {
    margin-top: 0;
    border-radius: 10px 10px 0 0;
    padding-top: 6px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 10px;
    height: 43px;
    width: 100%;
  }
`;

export const DevicesList = styled.div`
  border: 1px solid #e5e5ea;
  max-height: 200px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;
export const DeviceListItem = styled.div`
  display: flex;
  box-sizing: border-box;
  border: 1px solid #e5e5ea;
  min-height: 49px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & > ${CardIndicator} {
    width: 54px;
    height: 26px;
    margin-right: 12px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

export const DeviceListName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & > img {
    margin-left: 10px;
    margin-right: 8px;
  }
`;
