import React from 'react';
import { connect } from 'react-redux';
import { assert } from '../../../../lib/assert'
import { controlType } from './switch';
import Form from '../../../general/form/Form';

/**
 * @param {Object} props
 * @param {cx.ods.meta.PropertyVector} props.property
 */

function VectorControl(props)  {
	// const map = useRef({});
	assert(props.property != null, "VectorControl: vector should not be null");

	const itemProperty = props.property.element;
	const ItemType = controlType(itemProperty);
	const controls = [];

	const onChange = (propertyValue) => {
		// TODO
		// map[propertyValue.propertyId] = propertyValue;
		// const compositeValue = new cx.ods.meta.CompositeValue();
		// compositeValue.propertyId = props.property.propertyId;
		// compositeValue.elements = Object.values(map);
		// props.onChange(compositeValue, props.property.propertyId);
	}

	controls.push(
		<ItemType
			property={itemProperty}
			compositeId={props.property.propertyId}
			onChange={onChange}
		/>
	);

	return (
		<Form.ControlGroup className="property-vector" label={props.property.name}>
			{controls}
		</Form.ControlGroup>
	);
}

export default connect(state => {
	return {
		properties: state.meta.properties
	}
})(VectorControl);
