import React from 'react';
import ViewLayout from './ViewLayout';
import { className, fromProps } from '../../../lib/className';
import './panelLayout.scss';

function Header(props) {
	return (<>
		<div>
			<div className="icon">{props.icon}</div>
			<div className="title">{props.title}</div>
		</div>
		<div>{props.actionBar}</div>
	</>)
}

/**
 * @param {Object} props
 * @param {string|React.Component} props.title
 * @param {React.Component} [props.icon]
 * @param {React.Component} [props.actionBar]
 * @param {React.Component} [props.footer]
 */

function PanelLayout(props) {
	return (
		<ViewLayout
			className={className('panel-layout', fromProps(props))}
			header={
				<Header
					icon={props.icon}
					title={props.title}
					bar={props.actionBar}
				/>
			}
			footer={props.footer}
		>
			{props.children}
		</ViewLayout>
	)
}

export default PanelLayout;