import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../../general/Loader';
import { className } from '../../../../lib/className';
import { useI18n } from '../../../../../i18n';
import DatetimeField from '../../../general/datetime/DatetimeField';
import ScrollList from '../../../general/list/ScrollList';
import ReportSummary from './ReportSummary';
import ReportHeader from './ReportHeader';
import { formatter } from '../../../../misc/datetime';
import { actions } from '../../../../redux/api/reports';
import SwitchChartMenuItem from './SwitchChartMenuItem';
import NoDataView from '../../../general/view/NoDataView';
import CheckIntervalLevelsChart from './CheckIntervalLevelsChart';
import { scrollReportItemIntoView } from '../../../../misc/misc';
import './fuelLevel.scss';

function HeaderListItem() {
	const { f } = useI18n();
	return (
		<>
			<div className="header-item"><span className="capitalize">{f('item No.')}</span></div>
			<div className="header-item"><span className="capitalize">{f('start time')}</span></div>
			<div className="header-item"><span className="capitalize">{f('end time')}</span></div>
			<div className="header-item"><span className="capitalize">{f('duration')}</span></div>
			<div className="header-item"><span className="capitalize">{f('fuel level') + ', ' + f({ prefix: 'units', id: '%' })}</span></div>
		</>
	)
}

function ReportListItem(props) {
	const onClick = () => {
		props.onClick(props.interval);
	}

	const _className = className('clickable', { 'selected': props.selected });
	return (
		<>
			<div ref={props.customRef} className={_className} onClick={onClick}>{props.index}</div>
			<div className={_className} onClick={onClick}><DatetimeField datetime={props.interval.since.generatedAt} /></div>
			<div className={_className} onClick={onClick}>
				{props.interval.until && <DatetimeField datetime={props.interval.until} />}
			</div>
			<div className={_className} onClick={onClick}>
				{props.interval.until && formatter.duration(props.interval.since.generatedAt, props.interval.until)}
			</div>
			<div className={_className} onClick={onClick}>{props.interval.level}</div>
		</>
	)
}

function EodListItem() {
	const { f } = useI18n();
	return <div className="indicator"><span className="capitalize">{f('you have reached the end')}</span></div>;
}

// -----------------------------------------------------------------


function FuelLevel(props) {
	const selectedItemBox = useRef();
	const [displayChart, setDisplayChart] = useState(false);
	const [selectedInterval, setSelectedinterval] = useState();
	const { f } = useI18n();

	const loadNext = () => {
		const uri = props.state.uri;
		const parameters = props.state.parameters;
		props.dispatch(actions.fuelLevel.request({ uri, parameters }));
	}

	const onItemClick = (interval) => {
		if (!displayChart) setDisplayChart(true);
		setSelectedinterval(interval);
	}

	const switchChartHandler = () => {
		setDisplayChart(!displayChart);
	}

	useEffect(() => {
		return () => props.dispatch(actions.fuelLevel.loadingAllCancel());
	}, []);

	useEffect(() => {
		if (displayChart && props.state.hasMore) {
			props.dispatch(actions.fuelLevel.loadingAll({ uri: props.state.uri }));
		} else {
			props.dispatch(actions.fuelLevel.loadingAllCancel());
		}
	}, [displayChart]);

	useEffect(() => {
		if (selectedItemBox.current) {
			scrollReportItemIntoView(selectedItemBox.current);
		}
	}, [selectedInterval]);

	let content = null;
	let chartBox = null;
	let canExport = false;
	if (props.state.list != null) {
		if (!props.state.list || props.state.list.length == 0) {
			content = <NoDataView message={f('no data for such criteria')} />;
		} else {
			canExport = true;
			let items = props.state.list.map((interval, at) => {
				const isSelected = selectedInterval === interval;
				return (
					<ReportListItem
						key={at}
						onClick={onItemClick}
						selected={isSelected}
						customRef={isSelected ? selectedItemBox : undefined}
						interval={interval}
						index={at+1}
					/>
				);
			});
			items.unshift(<HeaderListItem key={1000000} />);
			content = (<ScrollList
				pending={props.state.pending}
				next={loadNext}
				autoScroll={props.state.loadingAll}
				isEod={!props.state.hasMore}
				eodItem={EodListItem}
			>
				{items}
			</ScrollList>);
			if (displayChart) {
				chartBox = (
					<CheckIntervalLevelsChart
						data={props.state.list}
						selectedInterval={selectedInterval}
						onSelect={setSelectedinterval}
						retrospective={props.state.parameters.timeRange.retrospective}
					/>
				);
			}
		}
	} else {
		content = (
			<div className="center">
				{
					props.state.error
						? <span className="error">{props.state.error}</span>
						: <Loader size={Loader.Size.MD} />
				}
			</div>
		);
	}

	const exportReport = () => {
		props.dispatch(actions.fuelLevel.export({ uri: props.state.uri }));
		props.history.goBack();
	}

	const timeRange = props.state.parameters.timeRange;
	return (
		<div className="report fuel-level">
			<ReportHeader
				title={f('fuel level report')}
				canExport={canExport}
				onExport={exportReport}
				items={<SwitchChartMenuItem disabled={!canExport} active={displayChart} onClick={switchChartHandler} />}
			/>
			<ReportSummary
				since={timeRange.since}
				until={timeRange.until}
				devices={[props.devices[props.state.uri]]}
				generatedAt={props.state.at}
			/>
			<div className={className('content', { 'with-chart': displayChart })}>
				{chartBox}
				{content}
			</div>
		</div>
	)
}

export default withRouter(connect(state => {
	return ({
		state: state.reports.fuelLevel,
		devices: state.devices.map
	})
})(FuelLevel));
