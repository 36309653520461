import React, { useImperativeHandle } from 'react';
import Form from '../../../../general/form/Form';
import { cx } from '../../../../../api';
import { useI18n } from '../../../../../../i18n';
import { datetime } from '../../../../../misc/datetime';


/**
 * @param {Object} props
 * @param {cx.ods.TimeFrameParameters} [props.timeFrame]
 * @param {React.Ref} props.customRef
 * @param {boolean} [props.disabled]
 * @param {string} props.prefix
 */

function TimeFrameControl(props) {
	const { f } = useI18n();

	useImperativeHandle(props.customRef, () => ({
		submit: (values) => {
			let timeFrame = null;
			if (!props.disabled) {
				timeFrame = new cx.ods.TimeFrameParameters();
				timeFrame.duration = +values[props.prefix + "duration"] * datetime.MINUTE;
				timeFrame.extraPoints = +values[props.prefix + "extraPoints"];
			}
			return timeFrame;
		},
		changed: values => {
			let changed = false;
			if (props.timeFrame) {
				changed = Number(props.timeFrame.duration) != Number(values[props.prefix + "duration"] * datetime.MINUTE);
				if (!changed) changed = Number(props.timeFrame.extraPoints) != Number(values[props.prefix + "extraPoints"]);
			}
			return changed;
		}
	}));

	return (
		<Form.ControlGroup>
			<Form.Control
				controlName={props.prefix + "duration"}
				controlType={Form.Control.Type.InputNumber}
				min={0}
				controlValidator={(value, values) => {
					if (!props.disabled) {
						if (value == null || value === '') {
							return f('please enter a value');
						} else if (+value == 0 && values[props.prefix + "extraPoints"] == 0) {
							return f('please enter a positive value');
						}
					}
				}}
				label={f('duration') + ', ' + f({ prefix: 'units', id: 'min' })}
				initialValue={props.disabled ? null : (props.timeFrame ? props.timeFrame.duration / datetime.MINUTE : 5)}
				disabled={props.disabled}
			/>
			<Form.Control
				controlName={props.prefix + "extraPoints"}
				controlType={Form.Control.Type.InputNumber}
				min={0}
				controlValidator={(value, values) => {
					if (!props.disabled) {
						if (value == null || value === '') {
							return f('please enter a value');
						} else if (+value == 0 && values[props.prefix + "duration"] == 0) {
							return f('please enter a positive value');
						}
						if (value != null && !/^(\d+)$/.test(value)) {
							return f('please enter an integer value');
						}
					}
				}}
				label={f('extra points')}
				initialValue={props.disabled ? null : (props.timeFrame ? props.timeFrame.extraPoints : 10)}
				disabled={props.disabled}
			/>
		</Form.ControlGroup>
	);
}

export default TimeFrameControl;
