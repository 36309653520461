import React from 'react';
import { connect } from 'react-redux';

import PopupNavItem from './PopupNavItem';
import LanguageSelector from '../../general/LanguageSelector';
import { actions as langActions } from '../../../redux/app/locale';

/**
 * @param {Object} props
 * @param {string} [props.title]
 */

const locales = ['en', 'th', 'ar', 'ru', 'vi', 'zh-cn', 'id'];

function LangPopupNavItem(props) {
	const iconName = locales.includes(props.lang) ? props.lang : 'blank-language';

	const srcImg = require(`../../../img/icons/languages/${iconName}.png`);

	const onChange = (lang) => {
		props.dispatch(langActions.setLang({ lang }));
	};

	return (
		<PopupNavItem
			title={props.title}
			icon={<img src={srcImg} alt="" />}
			content={<LanguageSelector onClick={onChange} active={props.lang} />}
		/>
	);
}

export default connect((state) => ({
	lang: state.locale.lang,
}))(LangPopupNavItem);
