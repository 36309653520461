import React, { FC } from 'react';
import ReactHowler from 'react-howler';

import { severe } from 'assets/audio';
import { useNotifications } from 'hooks/notifications/useNotifications';
import { NotificationsList } from './notifications-list';

export const Notifications: FC = () => {
  const { isNotificationsShown, topListProps, bottomListProps, isSeverePlaying } = useNotifications();

  return isNotificationsShown ? (
    <>
      <NotificationsList {...topListProps} />
      <NotificationsList {...bottomListProps} />
      <ReactHowler src={[severe]} playing={isSeverePlaying} loop />
    </>
  ) : null;
};
