import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { className, fromProps } from '../../../../lib/className';
import { useI18n } from '../../../../../i18n';
import { cx } from '../../../../api';
import EventIcon from '../../events/EventIcon';
import { FiClock } from 'react-icons/fi';
import './deviceCommandTile.scss';

/**
 * @param {Object} props
 * @param {cx.ods.devices.CommandType} props.command
 * @param {string} props.uri
 */

function DeviceCommandTile(props) {
	const box = useRef();
	const commandType = props.command.commandType;
	const pendingCommand = props.pendingCommands && props.pendingCommands.find(command => command.commandType === commandType);
	const pendingStatus = pendingCommand && (pendingCommand.pending && 'pending' || pendingCommand.processing && 'processing');
	const { f, fc } = useI18n();

	const onClick = () => {
		const offset = cx.dom.at.offset(box.current);
		const width = box.current.offsetWidth;
		props.onClick && props.onClick(props.command, { offset, width });
	}

	return (
		<div
			className={className(
				'device-command-tile',
				fromProps(props),
				{
					'clickable': !!props.onClick
				}
			)}
			ref={box}
			onClick={onClick}
		>
			<div className="title">
				<label>
					{f({ prefix: 'command', id: props.command.description })}
				</label>
				<div className="status">
					{props.status && <EventIcon eventType={props.status} />}
					{pendingCommand && <FiClock title={fc({ prefix: 'status', id: pendingStatus })} />}
				</div>
			</div>
			<div className="commentary">
				{f({ prefix: 'command', id: props.command.commentary })}
			</div>
		</div>
	);
}

export default connect((state, props) => ({
	pendingCommands: state.deviceCommands[props.uri] && state.deviceCommands[props.uri].pendingCommands,
	status: state.deviceCommands[props.uri]
		&& state.deviceCommands[props.uri].lastCompleteEventType
		&& state.deviceCommands[props.uri].lastCompleteEventType[props.command.commandType]
}))(DeviceCommandTile);