import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, generatePath } from 'react-router-dom';
import { useI18n } from '../../../../i18n';
// import { assert } from '../../../app/assert';
import { download } from '../../../misc/misc';
import Loader from '../../general/Loader';
import Button from '../../general/form/Button';
import { cx } from '../../../api';
import Form from '../../general/form/Form';
import DevicePicker from '../../share/devices/DevicePicker';
import ButtonGroup from '../../general/form/ButtonGroup';
import ReportType from './ReportType';
import { actions } from '../../../redux/api/reports';

// --------Parametrizators---------------------
import LocationHistory from './parametrizators/LocationHistory';
import Trip from './parametrizators/Trip';
import TripHistory from './parametrizators/TripHistory';
import TemperatureChange from './parametrizators/TemperatureChange';
import TemperatureHistory from './parametrizators/TemperatureHistory';
import DeviceUsages from './parametrizators/DeviceUsages';
import DeviceSituations from './parametrizators/DeviceSituations';
import DeviceStatus from './deviceStatus/parametrizator';
import DeviceUtilization from './deviceUtilization/parametrizator';
import DrivingBehaviour from './drivingBehaviour/parametrizator';
import Speeding from './parametrizators/Speeding';
import Idling from './parametrizators/Idling';
import DeviceRegistry from './parametrizators/DeviceRegistry';
import ZoneVisits from './parametrizators/ZoneVisits';
import FuelLevel from './parametrizators/FuelLevel';
import Ridership from './parametrizators/Ridership';
import Commute from './parametrizators/Commute';
// -----------------------------------------

import ReportPreviewer from './ReportPreviewer';
import './reportParametrizator.scss';

// TODO get this shit out of here and make it right
const endOfDay = cx.today();
endOfDay.setHours(23); endOfDay.setMinutes(59); endOfDay.setSeconds(59);
const DEFAULT_SINCE = new Date(endOfDay - 7 * 24 * 3600 * 1000 + 1000);
const DEFAULT_UNTIL = endOfDay;

const defaultTimeRange = (reportType) => {
	if (reportType == "zoneVisits") {
		return new cx.ods.TimeRangeFilter(DEFAULT_SINCE, DEFAULT_UNTIL);
	} else {
		return new cx.ods.TimeRangeParameters(DEFAULT_SINCE, DEFAULT_UNTIL);
	}
}

function ProgressIndicator(props) {
	const { f, p } = useI18n();
	let content = null;
	if (props.report.exporting) {
		let status = null;
		if (props.report.progress == null) {
			status = f('loading');
			if (props.report.list) status += ': ' + p('X records', props.report.list.length);
		} else {
			const done = props.report.progress.done;
			const total = props.report.progress.total;
			const percent = props.report.progress.percent;
			status = f('processing') + ': ' + p('X out of Y records', done, total) + ', ' + percent + '%'
		}
		content = <>
			<Loader className="loader" size={Loader.Size.XS} />
			<div className="info">
				{status}
			</div>
		</>
	}

	return (
		<div className="progress-indicator">
			{content}
		</div>
	)
}

const SUBMIT_TYPE = {
	submit: 'submit',
	preview: 'preview'
}

/**
 * @param {Object} props
 * @param {string} props.type report type
 */

function ReportParametrizator(props) {
	const { f, fc } = useI18n();
	const [timeRange, setTimeRange] = useState(defaultTimeRange(props.type));
	const [selectedDevice, setSelectedDevice] = useState(null);
	const [error, setError] = useState();
	const pluginRef = useRef(null);
	const previewTimer = useRef(null);
	const formSubmitHook = useRef(null);
	const formSubmitType = useRef(SUBMIT_TYPE.submit);
	const parameters = props.report && props.report.parameters;

	useEffect(() => {
		return () => {
			clearTimeout(previewTimer.current);
		}
	}, []);

	// restore values
	useEffect(() => {
		if (props.type == null || props.report == null) return;
		if (!skipDate) {
			const _timeRange = defaultTimeRange(props.type);
			if (parameters != null) {
				_timeRange.since = parameters.timeRange.since;
				_timeRange.until = parameters.timeRange.until;
			}
			setTimeRange(_timeRange);
		}
		setSelectedDevice(props.report.uri ? props.report.uri : null);
	}, [props.type]);

	if (props.type == null) return (null);
	let plugin = null; // plugin for extending parameterizator with report parameter controls
	let title = null;
	let isFleet = false;
	let skipDate = false;
	let maxDays = 7;
	const exporting = props.report.exporting;
	const csv = props.report.csv;

	switch (props.type) {
		case ReportType.LocationHistory:
			title = fc(ReportType.name(props.type) +  ' report');
			plugin = <LocationHistory
				customRef={pluginRef}
				timeRange={timeRange}
				disabled={exporting}
			/>;
			break;
		case ReportType.Trip:
			title = fc(ReportType.name(props.type) +  ' report');
			maxDays = 31;
			plugin = <Trip
				customRef={pluginRef}
				timeRange={timeRange}
				disabled={exporting}
			/>;
			break;
		case ReportType.TripHistory:
			title = fc(ReportType.name(props.type) +  ' report');
			plugin = <TripHistory
				customRef={pluginRef}
				timeRange={timeRange}
				disabled={exporting}
			/>;
			break;
		case ReportType.TemperatureChange:
			title = fc(ReportType.name(props.type) +  ' report');
			plugin = <TemperatureChange
				customRef={pluginRef}
				timeRange={timeRange}
				disabled={exporting}
			/>;
			break;
		case ReportType.TemperatureHistory:
			title = fc(ReportType.name(props.type) +  ' report');
			plugin = <TemperatureHistory
				customRef={pluginRef}
				timeRange={timeRange}
				disabled={exporting}
			/>;
			break;
		case ReportType.DeviceUsages:
			title = fc(ReportType.name(props.type) +  ' report');
			isFleet = true;
			maxDays = 31;
			plugin = <DeviceUsages
				customRef={pluginRef}
				timeRange={timeRange}
				disabled={exporting}
			/>;
			break;
		case ReportType.DeviceSituations:
			title = fc(ReportType.name(props.type) +  ' report');
			isFleet = true;
			maxDays = 31;
			plugin = <DeviceSituations
				customRef={pluginRef}
				timeRange={timeRange}
				disabled={exporting}
			/>;
			break;
		case ReportType.DeviceStatus:
			title = fc(ReportType.name(props.type) +  ' report');
			isFleet = true;
			skipDate = true;
			plugin = <DeviceStatus
				customRef={pluginRef}
				disabled={exporting || Boolean(csv)}
			/>
			break;
		case ReportType.DeviceUtilization:
			title = fc(ReportType.name(props.type) +  ' report');
			isFleet = true;
			maxDays = 31;
			plugin = <DeviceUtilization
				customRef={pluginRef}
				timeRange={timeRange}
				disabled={exporting || Boolean(csv)}
			/>
			break;
		case ReportType.DrivingBehaviour:
			title = fc(ReportType.name(props.type) +  ' report');
			isFleet = true;
			maxDays = 31;
			plugin = <DrivingBehaviour
				customRef={pluginRef}
				timeRange={timeRange}
				disabled={exporting || Boolean(csv)}
			/>
			break;
		case ReportType.Speeding:
			title = fc(ReportType.name(props.type) +  ' report');
			plugin = <Speeding
				customRef={pluginRef}
				timeRange={timeRange}
				disabled={exporting}
			/>;
			break;
		case ReportType.Idling:
			title = fc(ReportType.name(props.type) +  ' report');
			plugin = <Idling
				customRef={pluginRef}
				timeRange={timeRange}
				disabled={exporting}
			/>;
			break;
		case ReportType.DeviceRegistry:
			title = fc(ReportType.name(props.type) +  ' report');
			isFleet = true;
			skipDate = true;
			plugin = <DeviceRegistry
				customRef={pluginRef}
				parameters={parameters}
				disabled={!!csv}
			/>;
			break;
		case ReportType.ZoneVisits:
			title = fc(ReportType.name(props.type) +  ' report');
			isFleet = true;
			plugin = <ZoneVisits
				customRef={pluginRef}
				timeRange={timeRange}
				disabled={!!csv}
			/>;
			break;
		case ReportType.FuelLevel:
			title = fc(ReportType.name(props.type) +  ' report');
			plugin = <FuelLevel
				customRef={pluginRef}
				timeRange={timeRange}
				disabled={!!csv}
			/>;
			break;
		case ReportType.Ridership:
			title = fc(ReportType.name(props.type) +  ' report');
			plugin = <Ridership
				customRef={pluginRef}
				timeRange={timeRange}
				disabled={exporting}
			/>;
			break;
		case ReportType.Commute:
			title = fc(ReportType.name(props.type) +  ' report');
			isFleet = true;
			plugin = <Commute
				customRef={pluginRef}
				timeRange={timeRange}
				disabled={exporting}
			/>;
			break;
	}

	const changeDate = daterange => {
		if (daterange.length != 0) {
			const newTimeRange = cx.meta.clone(timeRange);
			newTimeRange.since = daterange[0];
			newTimeRange.until = daterange[1];
			newTimeRange.until.setHours(23);
			newTimeRange.until.setMinutes(59);
			newTimeRange.until.setSeconds(59);
			setTimeRange(newTimeRange);
		}
	}

	const onSubmit = (values) => {
		if (formSubmitType.current == SUBMIT_TYPE.preview) {
			pluginRef.current.preview(values);
		} else {
			const error = pluginRef.current.validate?.(values);
			if (error) setError(error);
			else {
				setError();
				pluginRef.current.submit(values);
				props.history.push(generatePath(props.match.path, {
					type: ReportType.toUrl(props.type)
				}) + '/view');
			}
		}
	}

	const onChangeForm = (values) => {
		if (props.report.preview && props.report.parameters) {
			if (previewTimer.current) clearTimeout(previewTimer.current);
			previewTimer.current = setTimeout(() => {
				let changed = false;
				if (!isFleet) changed  = props.report.uri != values.uri;
				if (!changed && !skipDate) {
					changed = values.timeRange[0].getTime() != props.report.parameters.timeRange.since.getTime();
					if (!changed) changed = values.timeRange[1].getTime() != props.report.parameters.timeRange.until.getTime();
				}
				if (!changed) changed = pluginRef.current.changed(values);
				if (changed && props.report.preview.valid) props.dispatch(actions[props.type].preview.invalidate());
				previewTimer.current = null;
			}, 0);
		}
	}

	const submit = (submitType) => {
		formSubmitType.current = submitType;
		formSubmitHook.current();
	}

	const removeCSV = () => {
		pluginRef.current.clear();
	}

	const _download = () => {
		if (exporting) return;
		download([csv], 'text/csv', title);
	}

	const timeRangeValue = timeRange ? [timeRange.since, timeRange.until] : [];

	return (
		<div className="report-parametrizator">
			<div className="parametrizator">
				<div className="title">{title}</div>
				<Form
					disabled={exporting || !!csv}
					onSubmit={onSubmit}
					onChange={onChangeForm}
					submitHook={formSubmitHook}
				>
					{!skipDate &&
						<Form.Control
							key={1}
							controlName="timeRange"
							controlType={Form.Control.Type.DateRangePicker}
							label={f('select period')}
							value={timeRangeValue}
							maxDays={maxDays}
							noFutureDates
							onChange={changeDate}
							cleanable={false}
						/>
					}
					{!isFleet &&
						<Form.Control
							key={2}
							controlName="uri"
							controlType={DevicePicker}
							controlValidator={(value) => {
								if (value == null) return f('please select device');
							}}
							label={f('select device')}
							placeholder={f('search')}
							value={selectedDevice}
							onChange={setSelectedDevice}
						/>
					}
					{plugin}
					<Form.ControlGroup className="footer" key={99}>
						{exporting && <ProgressIndicator reportType={props.type} report={props.report} />}
						{error && <div className="error">{error}</div>}
						<ButtonGroup>
							{!csv &&
								<Button key={1} onClick={() => submit(SUBMIT_TYPE.submit)} disabled={exporting}>
									{f('generate report')}
								</Button>
							}
							{csv &&
								<Button key={1} onClick={removeCSV}>
									{f('generate new report')}
								</Button>
							}
							{(exporting || csv) &&
								<Button key={2} onClick={_download} disabled={exporting}>{f('download CSV')}</Button>
							}
							{(!exporting && !csv && props.report.preview) &&
								<Button
									key={3}
									loading={props.report.preview.pending}
									disabled={props.report.preview.list && props.report.preview.valid}
									onClick={() => submit(SUBMIT_TYPE.preview)}
								>
									{f('preview')}
								</Button>
							}
						</ButtonGroup>
					</Form.ControlGroup>
				</Form>
			</div>
			{(!exporting && !csv && props.report.preview) && <ReportPreviewer type={props.type} />}
		</div>
	)
}

export default connect((state, props) => {
	return {
		report: state.reports[props.type]
	}
})(withRouter(ReportParametrizator));
