import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { sortByName } from '../../../lib/sorting';
import { actions as registryActions } from '../../../redux/api/registry';
import InputPicker from '../../general/form/InputPicker';
import Loader from '../../general/Loader';

/**
 * For other parameters - see InputPicker component
 * @param {Object} props
 * @param {string} props.categoryName
 * @param {Array.<string>} [props.availableMnemonics]
 */

function RegistryCategoryPicker(props) {
	const { availableMnemonics, categoryName, dispatch, category, label, ..._props } = props;
	const { fc } = useI18n();

	useEffect(() => {
		if (categoryName && category == null) {
			dispatch(registryActions.category.request({ categoryName }));
		}
	}, [categoryName]);

	let categoryList = null;
	if (category && category.list != null) {
		if (availableMnemonics) {
			categoryList = category.list.filter(category => availableMnemonics.includes(category.mnemonics));
		}
		categoryList = sortByName(categoryList).map(category => ({ value: category.mnemonics, label: fc({ prefix: 'registry-category-' + props.categoryName, id: category.name }) }));
	}

	const content = categoryList
		? <InputPicker
			block
			data={categoryList}
			{..._props}
		/>
		: <div className="loader"><Loader size={Loader.Size.MD} /></div>
	;

	return (
		<div className="registry-category-picker">
			{label && <label>{label}</label>}
			{content}
		</div>
	);
}

export default connect((state, props) => ({
	category: state.registry.category[props.categoryName]
}))(RegistryCategoryPicker);
