import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import { classname } from '../../../../misc/misc';
import { actions } from '../../../../redux/api/devices';
import PagePanel from '../../../general/panel/PagePanel';
import DeviceForm from '../../../share/devices/DeviceForm';
import { FiFileText as Icon } from 'react-icons/fi';
import DeviceBundlesControl from '../../deviceProperties/runtime/DeviceBundlesControl';
import { setPageTitle } from '../../../../misc/page';
import './deviceCardPage.scss';

const PAGE_TITLE = 'device card';

function DeviceCardPage(props) {
	const { f, fc } = useI18n();
	const uri = props.match.params.uri;
	if (uri == null) { /* TODO */ }
	const device = props.devices.map && props.devices.map[uri];

	useEffect(() => {
		setPageTitle(fc(PAGE_TITLE));
		if (props.devices.map == null && !props.devices.pending) {
			props.loadDevices();
		}
	}, []);

	return (
		<div className={"app-page control-panel device-card" + classname(props)}>
			<PagePanel
				header={<>
					<div className="icon"><Icon size="22" /></div>
					<div className="title">{f('device card')}</div>
				</>}
			>
				{device &&
					<DeviceForm device={device}>
						<DeviceBundlesControl device={device} />
					</DeviceForm>
				}
			</PagePanel>
		</div>
	);
}

export default connect(
	(state) => ({
		devices: state.devices
	}),
	(dispatch) => ({
		loadDevices: () => dispatch(actions.load.request())
	})
)(DeviceCardPage);