import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loader from '../../general/Loader';
import InputPicker from '../../general/form/InputPicker';
import { actions as processorActions } from '../../../redux/api/processors';
import { className, fromProps } from '../../../lib/className';

const actions = processorActions.devicePresence;

/**
 * @param {Object} props
 * @param {number} props.value
 * @param {function} props.onChange
 * @param {string} props.menuClassName
 */

function PresenseDetectorPicker(props) {

	useEffect(() => {
		if (props.detectors.list == null) {
			props.dispatch(actions.load.request());
		}
	}, []);

	let data = null;
	if (props.detectors.list != null) {
		data = props.detectors.list.map(detector =>
			({ value: detector.processorId, label: detector.name })
		);
	}

	const content = data
		? (
			<InputPicker
				block
				data={data}
				placeholder={props.placeholder}
				disabled={props.disabled}
				className={props.menuClassName}
				value={props.value}
				onChange={props.onChange}
				cleanable={false}
			/>
		)
		: <div className="loader"><Loader size={Loader.Size.MD} /></div>
	;
	return (
		<div className={className('presence-detector-picker', fromProps(props))}>
			{props.label && <label>{props.label}</label>}
			{content}
		</div>
	)
}

export default connect(
	state => ({
		detectors: state.processors.devicePresence
	})
)(PresenseDetectorPicker);
