import { cx } from "../../../api";

export const TimeRange = cx.ods.TimeRange;
TimeRange.fromObject = (object) => new TimeRange(object.since, object.until);
TimeRange.fromObjects = (objects) => objects.map(object => TimeRange.fromObject(object));
export const DataScope = cx.ods.datascope.DataScope;
export const DataScopeParameters = cx.ods.datascope.DataScopeParameters;
export const ScopeMessageHistory = cx.ods.datascope.ScopeMessageHistory;
export const ScopeEventHistory = cx.ods.datascope.ScopeEventHistory;
export const DeviceMessages = cx.ods.devices.DeviceMessages;
export const DeviceEvents = cx.ods.devices.DeviceEvents;

export const createScope = (timeRange, uris) => {
	const scope = new DataScope();
	scope.timeRange = timeRange;
	scope.uris = uris;
	return scope;
}

export const createParameters = (dataScope, timeAt, missingScopes) => {
	const parameters = new DataScopeParameters();
	parameters.overallScope = dataScope;
	parameters.timeAt = timeAt;
	parameters.missingScopes = missingScopes;
	return parameters;
}