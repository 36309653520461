import React, { useImperativeHandle, useEffect, useRef, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import * as widgets from '../../../../../redux/app/widgets';
import { actions as traceActions } from '../../../../../redux/api/trace';
import { useI18n } from '../../../../../../i18n';
import DeviceStateMapMarker from '../../../map/DeviceStateMapMarker';
import TraceViewMap from '../../../map/TraceViewMap';
import { getOwMap } from '../../../../general/location/Map';
import { datetime } from '../../../../../misc/datetime';
import { cx } from '../../../../../api';
import ActionLockedDevices from '../../../../general/widgets/ActionLockedDevices';
import WidgetMenuItem from '../../../../general/widgets/WidgetMenuItem';
import DeviceSet from '../../../../share/devices/DeviceSet';
import WidgetErrorContent from '../../../../general/widgets/WidgetErrorContent';
import TraceConfiguration from './TraceConfiguration';
import ActionTrace from '../../../../share/actionbar/ActionTrace';
import ActionFollowMarker from '../../../../share/actionbar/ActionFollowMarker';
import ActionLock from '../../../../share/actionbar/ActionLock';
import ActionMaximize from '../../../../share/actionbar/ActionMaximize';
import './deviceTrackingWidget.scss';

/**
 * Widget showing current device info on map: location, trace.
 * @param {Object} props
 * @param {string} props.uid
 * @param {string} props.reduxKey
 * @param {React.RefObject} props.customRef
 * @param {function} props.sizeChange ( uid, { w, h } )
 * @param {function} props.update to rerender WidgetContainer
 */


const DEFAULT_TRACE_DURATION = 5;
const PIN_ACTIONS = {
	maximize: 'maximize',
	trace: 'trace',
	follow: 'followMarker',
	lock: 'lockDevices'
};

function DeviceTrackingWidget(props) {
	const { f,  fc } = useI18n();

	const widgetData = props.widget.data;
	const displayTrace = widgetData && widgetData.displayTrace;
	const traceDuration = widgetData && widgetData.traceDuration || DEFAULT_TRACE_DURATION;
	const trace = displayTrace && props.trace ? props.trace.list : [];
	const followMarker = widgetData && widgetData.followMarker;
	const traceFocused = useRef(false);
	const prevTraceDuration = useRef(traceDuration);
	const locked = !!(widgetData && widgetData.uris);
	const pending = props.trace && props.trace.pending;
	const uris = locked
		? widgetData.uris
		: (props.selection ? props.selection : [])
	;

	const mapName = props.uid;
	const currentUri = widgetData && widgetData.currentUri;

	const device = props.deviceMap && props.deviceMap[currentUri];

	useEffect(() => {
		return () => props.trace && props.dispatch(traceActions.clear({ uid: props.uid }));
	}, []);

	useEffect(() => {
		if (!traceFocused.current && displayTrace && trace && currentUri) {
			if (prevTraceDuration.current == traceDuration) traceFocused.current = true;
			prevTraceDuration.current = traceDuration;
		}
	}, [props.trace]);

	useEffect(() => {
		if (displayTrace && device) {
			const filter = new cx.ods.devices.MessageFilter();
			filter.minGeneratedAt = new Date(new Date().getTime() - (datetime.MINUTE * traceDuration));
			filter.window = new cx.ods.devices.MessageWindowFilter({ size: 50 });
			props.dispatch(traceActions.load.request({ uid: props.uid, uri: currentUri, filter, clear: true }));
			traceFocused.current = false;
		} else {
			props.trace && props.dispatch(traceActions.clear({ uid: props.uid }));
			focusMarker();
		}
		props.update();
	}, [displayTrace, traceDuration, device]);

	useEffect(() => {
		followMarker && focusMarker();
		props.update();
	}, [followMarker]);

	useLayoutEffect(() => {
		if (!currentUri || !uris.includes(currentUri)) {
			props.dispatch(widgets.actions.update({ domain: props.reduxKey, uid: props.uid, data: { currentUri: uris[0] } }));
		}
		props.update();
	}, [uris]);

	useEffect(() => {
		if (uris.length == 1) {
			props.dispatch(widgets.actions.update({ domain: props.reduxKey, uid: props.uid, data: { currentUri: uris[0] } }));
		}
		props.update();
	}, [locked]);

	useEffect(() => {
		props.update();
	}, [widgetData]);

	useEffect(() => {
		const map = getOwMap(mapName);
		if (map) map.map.updateSize();
	}, [props.widget.layout.h, props.widget.expanded]);

	const lockHandler = () => {
		props.dispatch(widgets.actions.update({ domain: props.reduxKey, uid: props.uid, data: { uris: locked ? null : uris } }));
	}

	const focusMarker = () => {
		if (!currentUri) return;
		const map = getOwMap(mapName);
		if (map) {
			const overlay = map.getOlMap().getOverlayById(currentUri);
			if (overlay) map.focus(overlay.getPosition(), 16, true);
		}
	}

	const traceHandler = () => {
		props.dispatch(widgets.actions.update({ domain: props.reduxKey, uid: props.uid, data: { displayTrace: !displayTrace } }));
	}

	const followHandler = () => {
		props.dispatch(widgets.actions.update({ domain: props.reduxKey, uid: props.uid, data: { followMarker: !followMarker } }));
	}

	const setCurrentUri = (uri) => {
		props.dispatch(widgets.actions.update({ domain: props.reduxKey, uid: props.uid, data: { currentUri: uri } }));
	}

	const onMaximize = () => {
		props.dispatch(widgets.actions.maximize({ domain: props.reduxKey, uid: props.maximized ? null : props.uid }));
	}

	const setPinnedActions = (actionName, pinned) => {
		const pinnedActions = widgetData && widgetData.pinnedActions ? { ...widgetData.pinnedActions } : {};
		pinnedActions[actionName] = pinned;
		props.dispatch(widgets.actions.update({ domain: props.reduxKey, uid: props.uid, data: { pinnedActions } }));
	}

	useImperativeHandle(props.customRef, () => ({
		title: () => fc('device tracking'),
		actions: (onChange) => {
			const actions = [];
			if (locked) {
				actions.push(<ActionLockedDevices
					uris={widgetData.uris}
					key="action-uris"
					onChange={onChange}
					emptyMessage={fc('all devices')}
				/>);
			}
			if (widgetData && widgetData.pinnedActions) {
				if (widgetData.pinnedActions[PIN_ACTIONS.maximize]) {
					actions.push(
						<ActionMaximize
							key={PIN_ACTIONS.maximize}
							onClick={onMaximize}
							active={props.maximized}
						/>
					);
				}
				if (widgetData.pinnedActions[PIN_ACTIONS.trace]) {
					actions.push(
						<ActionTrace
							key={PIN_ACTIONS.trace}
							disabled={uris.length == 0}
							onClick={traceHandler}
							active={displayTrace}
							title={fc(displayTrace ? 'hide trace' : 'show trace')}
						/>
					);
				}
				if (widgetData.pinnedActions[PIN_ACTIONS.follow]) {
					actions.push(
						<ActionFollowMarker
							key={PIN_ACTIONS.follow}
							disabled={uris.length == 0}
							onClick={followHandler}
							active={followMarker}
							title={fc(followMarker ? "don't follow marker" : "follow marker")}
						/>
					);
				}
				if (widgetData.pinnedActions[PIN_ACTIONS.lock]) {
					actions.push(
						<ActionLock
							key={PIN_ACTIONS.lock}
							disabled={uris.length == 0}
							onClick={lockHandler}
							active={locked}
							title={fc(locked ? 'unlock' : 'lock')}
						/>
					);
				}
			}
			return actions;
		},
		configuration: () => <TraceConfiguration displayTrace={displayTrace} traceDuration={traceDuration} />,
		canPin: locked || uris.length > 0,
		handlePin: lockHandler,
		menuItems: () => {
			return [
				<WidgetMenuItem
					onClick={onMaximize}
					text={fc(props.maximized ? 'minimize' : 'maximize')}
					key={PIN_ACTIONS.maximize}
					canPin
					pinned={widgetData && widgetData.pinnedActions && widgetData.pinnedActions[PIN_ACTIONS.maximize]}
					onChangePin={(pinned) => setPinnedActions(PIN_ACTIONS.maximize, pinned)}
				/>,
				<WidgetMenuItem
					onClick={traceHandler}
					disabled={uris.length == 0}
					key={PIN_ACTIONS.trace}
					text={fc(displayTrace ? 'hide trace' : 'show trace')}
					canPin
					pinned={widgetData && widgetData.pinnedActions && widgetData.pinnedActions[PIN_ACTIONS.trace]}
					onChangePin={(pinned) => setPinnedActions(PIN_ACTIONS.trace, pinned)}
				/>,
				<WidgetMenuItem
					onClick={followHandler}
					disabled={uris.length == 0}
					key={PIN_ACTIONS.follow}
					text={fc(followMarker ? "don't follow marker" : "follow marker")}
					canPin
					pinned={widgetData && widgetData.pinnedActions && widgetData.pinnedActions[PIN_ACTIONS.follow]}
					onChangePin={(pinned) => setPinnedActions(PIN_ACTIONS.follow, pinned)}
				/>,
				<WidgetMenuItem
					onClick={lockHandler}
					disabled={!locked && uris.length == 0}
					key={PIN_ACTIONS.lock}
					text={fc(locked ? 'unlock' : 'lock')}
					canPin
					pinned={widgetData && widgetData.pinnedActions && widgetData.pinnedActions[PIN_ACTIONS.lock]}
					onChangePin={(pinned) => setPinnedActions(PIN_ACTIONS.lock, pinned)}
				/>
			];
		},
		dynamicContent: () => {
			if (uris.length > 1) {
				return <DeviceSet
					className="device-tracking"
					value={uris}
					onClick={setCurrentUri}
					selectedUris={currentUri ? [currentUri] : undefined}
				/>;
			}
		},
		fullHeight: true,
		expandable: uris.length > 1
	}));

	let content = null;
	if (uris.length > 0) {
		const params = {
			autoPan: {
				animation: {
					duration: 0
				}
			}
		}
		content = (
			<TraceViewMap
				pending={pending}
				mapName={mapName}
				trace={trace}
				keepInView={!traceFocused.current}
				className="tracking-map"
				markers={[<DeviceStateMapMarker params={params} device={device} key={1} onClick={focusMarker} alwaysCenter={followMarker} />]}
			/>
		);
	} else {
		content = (<WidgetErrorContent message={f('please select device')} />);
	}

	return (
		<div className="device-tracking widget">
			{content}
		</div>
	);
}

export default connect((state, props) => ({
	selection: state.pages[props.reduxKey] && state.pages[props.reduxKey].selection,
	widget: state.widgets[props.reduxKey] && state.widgets[props.reduxKey].map[props.uid],
	deviceMap: state.devices.map,
	trace: state.trace[props.uid]
}))(DeviceTrackingWidget);
