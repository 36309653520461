import { combineEpics, ofType } from "redux-observable";
import { filter, mergeMap, map as rxmap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { rx, api, getOperationObservable } from "../../api";
import { actions as deviceStatesActions } from "./deviceStates";
import { isEqualMessageDetails } from "../../misc/misc";
import { ActionGeneratorBuilder } from "../actions";

//	{
// 		uid: {
//			list: [],
//			uri: string,
// 			filter: cx.ods.devices.MessageFilter,
// 			hasMore: boolean,
//			pending: boolean,
//			error: boolean
//		}
//	}

const defaultState = {};

const actions = new ActionGeneratorBuilder('trace')
	.subtype(
		'load',
		load => load
			.request({ uid: true, uri: true, filter: true, clear: false })
			.success({ uid: true, trace: true, announced: false })
			.fail({ uid: true, errorMessage: true })
	)
	.type('clear', 'uid')
	.build()
;

const updateFilter = (filter, messages) => {
	filter.window.anchorOn(messages);
	return filter;
}

function reducer(state = defaultState, action) {
	let stateItem;
	switch (action.type) {
		case actions.load.request.type:
			stateItem = { ...state[action.uid] };
			if (stateItem.uri != action.uri || action.clear) {
				stateItem.list = null;
				stateItem.uri = action.uri;
			}
			stateItem.pending = true;
			stateItem.error = null;
			stateItem.filter = action.filter;
			return {
				...state,
				[action.uid]: stateItem
			};
		case actions.load.success.type:
			let trace = [ ...action.trace ];
			stateItem = { ...state[action.uid] };
			const newState = {
				...state,
				[action.uid]: stateItem
			};
			if (stateItem.filter) { // if there is no filter, then the data is no longer needed
				if (!action.announced) {
					const batchSize = stateItem.filter.window.size;
					stateItem.hasMore = trace ? !(trace.length < batchSize) : false;
				}
				if (trace[0]) {
					const timeOfFirst = trace[0].generatedAt.getTime();
					const timeOfLast = trace[trace.length - 1].generatedAt.getTime();
					if (timeOfLast > timeOfFirst) trace = trace.slice().reverse();
					if (stateItem.list) {
						const timeOfNew = trace[0].generatedAt.getTime();
						const timeOfOld = stateItem.list[0].generatedAt.getTime();
						stateItem.list = timeOfNew > timeOfOld
							? trace.concat(stateItem.list)
							: stateItem.list.concat(trace)
						;
					} else stateItem.list = trace;
				}
				stateItem.filter = stateItem.filter && !action.announced ? updateFilter(stateItem.filter, stateItem.list) : stateItem.filter;
				stateItem.pending = false;
				newState[action.uid] = stateItem;
			} else {
				delete newState[action.uid];
			}
			return newState;
		case actions.load.fail.type:
			return {
				...state,
				[action.uid]: {
					pending: false,
					error: action.errorMessage
				}
			};
		case actions.clear.type:
			const copyState = { ...state };
			delete copyState[action.uid];
			return copyState;
		default:
			return state;
	}
}

const observables = {};

const loadEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.load.request.type),
		mergeMap(action => {
			if (observables[action.uid] != null) {
				if (observables[action.uid][action.uri] != null) {
					observables[action.uid][action.uri].getSubscriptions().forEach(subscription => subscription.cancel());
				}
			} else {
				observables[action.uid] = {};
			}
			const stateItem = state$.value.trace[action.uid];
			const observable = rx(api.messages.trace, stateItem.uri, stateItem.filter).pipe(
				rxmap(operation => actions.load.success({ uid: action.uid, trace: operation.response() })),
				catchError(error => actions.load.fail({ uid: action.uid, errorMessage: error.userMessage || error.message })),
			)
			observables[action.uid][action.uri] = getOperationObservable(observable);
			return observable;
		})
	)
}

const processPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.load.success.type),
		filter(action => state$.value.trace[action.uid] && state$.value.trace[action.uid].hasMore),
		rxmap(action => {
			const stateItem = state$.value.trace[action.uid];
			return actions.load.request({ uid: action.uid, uri: stateItem.uri, filter: stateItem.filter });
		})
	)
}

const watchDeviceStatesMessageEpic = (action$, state$) => {
	return action$.pipe(
		ofType(deviceStatesActions.find.success.type),
		filter(action => {
			const actionMap = action.map;
			const traceMap = state$.value.trace;
			if (traceMap && actionMap) {
				const actionMapUris = Object.keys(actionMap);
				return Object.values(traceMap).some(traceData => actionMapUris.some(uri => {
					if (traceData.list) {
						const newMessage = actionMap[uri].message;
						const lastMessage = traceData.list[0];
						return !!newMessage && !isEqualMessageDetails(newMessage, lastMessage);
					} else return false;
				}));
			}
			return false;
		}),
		mergeMap(action => {
			const actionMap = action.map;
			const traceMap = state$.value.trace;
			const actionMapUris = Object.keys(actionMap);
			const uids = Object.keys(traceMap);
			const messageMap = {};  // uid: [messages]
			uids.forEach(uid => {
				const traceData = traceMap[uid];
				if (traceData.filter && !traceData.filter.maxGeneratedAt && actionMapUris.includes(traceData.uri) && traceData.list) {
					const message = actionMap[traceData.uri].message;
					const lastMessage = traceData.list[0];
					if (!isEqualMessageDetails(message, lastMessage)) messageMap[uid] = [message];
				}
			});
			const emit = [];
			Object.keys(messageMap).forEach(uid => {
				emit.push(actions.load.success({ uid, trace: messageMap[uid], announced: true }));
			});
			return of.apply(this, emit);
		})
	);
}

const epic = combineEpics(loadEpic, processPrepareEpic, watchDeviceStatesMessageEpic);

export { actions, reducer, epic };
