import { resolve } from '../../../misc/location/resolve';

class Resolver {

	constructor(store, progressAction, getter) {
		this.store = store;
		this.progressAction = progressAction;
		this.getter = getter;
		this.clear();
		this.handleReady = this.handleReady.bind(this);
		this.handleBusy = this.handleBusy.bind(this);
	}

	clear() {
		this.done = 0;
		this.total = 0;
		this.resolved = {};
		this.complete = false;
	}

	key(lat, lon) {
		return "(" + lat + ";" + lon + ")";
	}

	_resolve(coordinates) {
		if (!Array.isArray(coordinates)) coordinates = [coordinates];
		coordinates.forEach(point => {
			if (point.longitude != null && point.latitude != null) {
				this.total += 1;
				resolve(point.longitude, point.latitude, this.handleReady, this.handleBusy, this);
			}
		});
	}

	resolve(items, complete) {
		if (items) items.forEach(item => {
			this._resolve(this.getter(item));
		}, this);
		this.complete = Boolean(complete);
		if (this.total == this.done) this.notifyProgress();
	}

	runResolve(items) {
		this.clear();
		this.resolve(items, true);
	}

	handleReady(entry) {
		this.done += 1;
		this.resolved[this.key(entry.latitude, entry.longitude)] = entry;
		this.notifyProgress();
	}

	handleBusy(entry) {
		//
	}

	notifyProgress() {
		if (this.complete) {
			const progress = Math.floor(this.done / this.total * 100);
			this.store.dispatch(this.progressAction({progress, count: this.done, total: this.total}));
		}
	}
}

export default Resolver;
