import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { Group as LayerGroup } from 'ol/layer';

import { Map, getOwMap } from '../../general/location/Map';
import DeviceStateMapMarker from './DeviceStateMapMarker';
import { actions as zonesActions } from '../../../redux/api/zones';
import { setProps } from '../../../misc/ol';
import { readMultiPolygon } from '../../../misc/wkt';
import { makeStyle, StyleType } from '../zones/zoneStyle';
import { URL_DELIMITER } from '../../../misc/url';
import useLocalStorage from '../../../misc/useLocalStorage';
import { className } from '../../../lib/className';
import { MarkerOrderManager } from '../../general/location/MarkerOrderManager';
import { MarkerHoverManager } from '../../general/location/MarkerHoverManager';
import MarkerOrderOption from './controls/MarkerOrderOption';
import { useGeoJsonLayers } from 'hooks/map/useGeoJsonLayers';

import './overview.scss';
import {
  ActualIssuesSelector,
  digestSelector,
  resolutionTypesSelector,
  runwaysSelector,
} from '../../../redux/selectors/registry';
import { ResolutionKind } from '../../../../constants/issues';
import { actions } from '../../../redux/api/veta/runwayGuardDigest';
import { actions as runwayActions } from '../../../redux/api/veta/runways';
import { BadgeTypes } from '../widgets/RunwayInfo';

const DEFAULT_MAP_NAME = 'overview';

/**
 * @param {Object} props
 * @param {string} [props.name]
 * @param {React.Component} [props.control]
 * @param {React.Ref} [props.customRef]
 */

function OverviewMap(props) {
  const map = useRef(null);
  const [inited, setInited] = useState(false);
  const layerGroup = useRef(null);
  const mapName = props.name || DEFAULT_MAP_NAME;
  const [displayZones, setDisplayZones] = useLocalStorage(mapName + '_display_zones');
  const [orderOption, setOrderOption] = useLocalStorage(mapName + '_marker_order_option');
  const [defaultView, setDefaultView] = useLocalStorage(`${mapName}_default_view`);
  const uris = props.match.params.uris ? props.match.params.uris.split(URL_DELIMITER) : [];

  const layersSwitcherProps = useGeoJsonLayers(mapName, map.current);

  const focusMarkers = () => {
    if (inited && !!uris.length) {
      getOwMap(mapName).focusMarkers(uris);
    }
  };

  const focusDefaultView = () => {
    if (defaultView && map.current) {
      map.current.getOlMap().getView().animate({ center: defaultView.center, zoom: defaultView.zoom, duration: 0 });
    }
  };

  const saveMapView = () => {
    if (inited && map.current) {
      const zoom = map.current.getOlMap().getView().getZoom();
      const center = map.current.getOlMap().getView().getCenter();

      setDefaultView({ zoom, center });
    }
  };

  useEffect(() => {
    if (props.customRef) props.customRef.current = map.current;
    if (props.zones.map == null) {
      props.dispatch(zonesActions.load.request());
    }
    map.current.updateSize();
    const timeOut = setTimeout(() => {
      setInited(true);
    }, 1000);
    return () => clearTimeout(timeOut);
  }, []);

  useEffect(() => {
    uris.length ? focusMarkers() : focusDefaultView();
  }, [inited]);

  const actual = useSelector(ActualIssuesSelector);
  const resolutionTypes = useSelector(resolutionTypesSelector);
  const digest = useSelector(digestSelector);
  const runways = useSelector(runwaysSelector);

  useEffect(() => {
    focusMarkers();
  }, [props.match.params.uris]);

  useEffect(() => {
    if (props.zones.map != null) {
      const zones = Object.values(props.zones.map);
      const layers = {};
      zones.forEach((zone) => {
        const layer = new VectorLayer({
          source: new VectorSource({
            features: setProps(readMultiPolygon(zone.geometry), {
              zoneId: zone.zoneId,
              name: zone.name,
              style: zone.style,
            }),
          }),
          style: makeStyle(StyleType.Default),
        });
        layer.setProperties({ zoneId: zone.zoneId });
        layers[zone.zoneId] = layer;
      });
      layerGroup.current = new LayerGroup({ layers: Object.values(layers) });
      map.current.getOlMap().addLayer(layerGroup.current);
      layerGroup.current.setVisible(displayZones);
    }
  }, [props.zones]);

  useEffect(() => {
    if (layerGroup.current != null) {
      layerGroup.current.setVisible(displayZones);
    }
  }, [displayZones, actual, digest]);

  const focusExtent = (extent) => {
    map.current.getOwMap().fitExtent(extent);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (runways.list) {
      runways.list.forEach((runway) => {
        dispatch(actions.load.request({ runwayId: runway.id }));
      });
    }
  }, [runways]);

  useEffect(() => {
    dispatch(actions.subscribe());
    dispatch(runwayActions.load.request());

    return () => {
      dispatch(actions.unsubscribe());
    };
  }, []);

  const getDeviceIsAlerting = useCallback((device) => {
    const unresolved = actual[device.uri]?.resolutionTypeId == null;
    const issueTypeId = actual[device.uri]?.issueTypeId;
    return unresolved && (issueTypeId == 4 || issueTypeId == 2 || issueTypeId == 5 || issueTypeId == 6)
  }, [resolutionTypes, actual, digest]);

  const getBadgeType = (device, kind) => {
    let isInOpenRunway = false;
    if (digest.digests) {
      for (const key in digest.digests) {
        isInOpenRunway =
          digest.digests[key].guarded?.some((vehicle) => vehicle.uri === device.uri) && digest.digests[key].runwayOpen;
        if (isInOpenRunway) break;
      }
    }

    if (!actual[device.uri]) {
      return BadgeTypes.Unknown;
    }

    if (actual[device.uri].issueTypeId === 1) {
      return BadgeTypes.NearOpenedRunwayGreen;
    }

    if (actual[device.uri].issueTypeId === 2) {
      if (kind === ResolutionKind.AuthorizedSituation || kind === ResolutionKind.FalsePositive) {
        return BadgeTypes.NearOpenedRunwayGreen;
      }

      return BadgeTypes.NearOpenedRunwayOrange;
    }

    if (actual[device.uri].issueTypeId === 3) {
      return BadgeTypes.InOpenedRunwayGreen;
    }

    if (actual[device.uri].issueTypeId === 4) {
      if (kind === ResolutionKind.AuthorizedSituation || kind === ResolutionKind.FalsePositive) {
        return BadgeTypes.InOpenedRunwayGreen;
      }

      return BadgeTypes.InOpenedRunwayRed;
    }

    if (actual[device.uri].issueTypeId === 5 || actual[device.uri].issueTypeId === 6) {
      if (kind === ResolutionKind.AuthorizedSituation || kind === ResolutionKind.FalsePositive) {
        return BadgeTypes.InClosedRunwayGreen;
      }

      return BadgeTypes.InClosedRunwayRed;
    }

    if (actual[device.uri].issueTypeId === 7) {
      if (kind === ResolutionKind.AuthorizedSituation || kind === ResolutionKind.FalsePositive) {
        return BadgeTypes.InTaxiwayGreen;
      }

      return BadgeTypes.InTaxiwayRed;
    }

    return null;
  };

  const markers = useMemo(() => {
    const resolutionTypeMap = resolutionTypes?.map;
    return props.devices.list?.map((device) => {
      const params = {
        autoPan: uris.includes(device.uri),
        autoPanMargin: 100,
        badgeType: getBadgeType(
          device,
          resolutionTypeMap?.[actual[device.uri]?.resolutionTypeId]?.resolutionKind
        ),
        isAlerting: getDeviceIsAlerting(device),
      };
      return <DeviceStateMapMarker key={device.uri} params={params} device={device} />;
    });
  }, [actual, digest, uris, props.devices.list, resolutionTypes]);

  const MapControl = props.control;

  return (
    <MarkerOrderManager orderOption={orderOption}>
      <MarkerHoverManager map={map.current}>
        <Map className={className('overview', mapName)} name={mapName} ref={map} baseLayer={Map.Layers.ONE}>
          {markers}
        </Map>
        {MapControl && (
          <MapControl
            {...layersSwitcherProps}
            mapName={mapName}
            focusExtent={focusExtent}
            displayZones={displayZones}
            setDisplayZones={setDisplayZones}
            saveMapView={saveMapView}
            dropdownOptions={<MarkerOrderOption value={orderOption} onChange={setOrderOption} />}
          />
        )}
      </MarkerHoverManager>
    </MarkerOrderManager>
  );
}

export default connect((state) => {
  return {
    devices: state.devices,
    zones: state.zones,
  };
})(withRouter(OverviewMap));
