import React from 'react';

import { ISVGPersonalProps } from 'types/svg';

export const warningAlert: ISVGPersonalProps = {
  viewBox: '0 0 21.069 18.23',
  content: (
    <g transform="translate(0)">
      <path
        transform="translate(-9.377 -14.7)"
        d="m11.146 32.826a4.744 4.744 0 0 0 1.219 0.1h15.092a4.744 4.744 0 0 0 1.219-0.1 2.373 2.373 0 0 0 1.4-3.579q-4.006-6.652-8.065-13.3a2.493 2.493 0 0 0-2.1-1.245 2.468 2.468 0 0 0-2.1 1.245c-2.723 4.408-5.394 8.843-8.065 13.3a2.373 2.373 0 0 0 1.4 3.579zm8.765-2.308a1.2 1.2 0 0 1-1.193-1.167 1.168 1.168 0 0 1 1.193-1.167 1.184 1.184 0 0 1 1.189 1.168 1.22 1.22 0 0 1-1.189 1.167zm-1.3-9.776a1.279 1.279 0 0 1 1.271-1.3h0.052a1.232 1.232 0 0 1 1.271 1.3c0.026 0.8 0.026 3.916 0 4.668a1.165 1.165 0 0 1-1.3 1.219 1.165 1.165 0 0 1-1.3-1.219c-0.016-0.752-0.016-3.864 9e-3 -4.668z"
        fill="currentColor"
      />
    </g>
  ),
};
