import React, { Dispatch, FC } from 'react';

import {
  TableFooterStyled,
  TableFooterActionsStyled,
  TableFooterSelectStyled,
  TableFooterTextStyled,
} from 'ui/pages/issues/issues-table-footer.styled';
import { OnPageChangeType } from 'types/pagination';
import { ISelectOption } from 'types/select';
import { fc } from 'i18n';
import { PAGE_SIZES } from 'constants/table';
import { Pagination } from 'components/molecules/pagination';
import { Select } from 'components/molecules/select';

interface ITableFooterProps {
  totalCount: number;
  pageSize: ISelectOption<number> | null;
  onPageChange: OnPageChangeType;
  updatePageSize: Dispatch<ISelectOption<number>>;
  page: number;
}

export const IssuesTableFooter: FC<ITableFooterProps> = (props) => {
  const { totalCount, pageSize, onPageChange, updatePageSize, page } = props;

  return (
    <TableFooterStyled>
      <TableFooterActionsStyled>
        <Pagination pageSize={pageSize?.value || 1} totalCount={totalCount} onPageChange={onPageChange} page={page} />
        <TableFooterSelectStyled>
          <Select rounded selectedValue={pageSize} options={PAGE_SIZES} onOptionChange={updatePageSize} />
        </TableFooterSelectStyled>
      </TableFooterActionsStyled>
      <TableFooterTextStyled>{`${fc('totalNoOfEntries')} : ${totalCount}`}</TableFooterTextStyled>
    </TableFooterStyled>
  );
};
