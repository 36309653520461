import React from 'react';
import { connect } from 'react-redux';
import ReportType from './ReportType';
import { useI18n } from '../../../../i18n';
import TripHistory from './previews/TripHistory';
import Loader from '../../general/Loader';

/**
 * @param {Object} props
 * @param {string} props.type report type
 */

function ReportPreviewer(props) {
	const { f } = useI18n();

	let content = null;
	let title = null;
	let previewer = null;

	if (props.hasPreview) {
		switch (props.type) {
			case ReportType.TripHistory:
				title = f(ReportType.name(props.type) +  ' report preview');
				previewer = <TripHistory />;
				break;
		}
	}

	if (previewer && title) {
		content = (
			<div className="report-previewer">
				<div className="title">
					<span className="capitalize">{title}</span>
					{props.pending && <Loader size={Loader.Size.SM} />}
				</div>
				{previewer}
			</div>
		);
	}

	return (content);
}

export default connect((state, props) => ({
	hasPreview: state.reports[props.type] && state.reports[props.type].preview && !!state.reports[props.type].preview.list,
	pending: state.reports[props.type] && state.reports[props.type].preview && state.reports[props.type].preview.pending
}))(ReportPreviewer);