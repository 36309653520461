import {combineEpics, ofType} from "redux-observable";

import {filter, ignoreElements, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';

import {fc, f} from '../../../../i18n';

import {formatDateTime, formatUTCTicksDuration, getTimezoneString} from '../../../misc/misc';

import {api, rx} from "../../../api";
import {denominate as denominateDevices} from '../../../api/device'

import {deltaReducer, errorMap} from "../../actions";
import Resolver from "./Resolver";

const {addActions, getActions} = (() => {
	let builder, actions;
	const addActions = report => {
		builder = report
			.subtype('generate', generate => generate.request({parameters: true}).success({report: true}).fail())
			.subtype('export', subtype => subtype.request().progress().success({csv: true}).clear())
		;
	};
	const getActions = () => {
		if (!actions) {
			if (!builder) throw new Error('Device status report actions weren\'t registered');
			actions = builder.build();
			builder = null;
		}
		return actions;
	};
	return {addActions, getActions};
})();

const withActions = target => (...args) => target(getActions(), ...args);


const defaultState = {
	parameters: null
	, report: null
	, pending: false
	, error: null
};

const reducer = deltaReducer(withActions((actions, state, action) => {
	switch (action.type) {
		case actions.generate.request.type: return {
			pending: true, error: undefined
			, parameters: action.parameters
			, report: undefined
		};
		case actions.generate.success.type: return {
			pending: false
			, report: action.report
		};
		case actions.generate.fail.type: return {
			pending: false, error: action.errorMessage
		};
		case actions.export.request.type: return {
			exporting: true
		};
		case actions.export.progress.type: return {
			progress: {
				percent: action.progress
				, done: action.count
				, total: action.total
			}
		};
		case actions.export.success.type: return {
			exporting: undefined
			, progress: undefined
			, csv: action.csv
		};
		case actions.export.clear.type: return {
			csv: undefined
		}
	}
}), defaultState);


let resolver = null;
const connect = withActions((actions, store) => {
	resolver = new Resolver(store, actions.export.progress, status => {
		const points = [];
		if (status.state?.message) {
			const {longitude, latitude} = status.state?.message;
			if (longitude != null && latitude != null) points.push({longitude, latitude});
		}
		return points;
	});
});


const epic = combineEpics(
	withActions((actions, action$) => action$.pipe(
		ofType(actions.generate.request.type)
		, switchMap(action => rx(api.reports.deviceStatus, action.parameters).pipe(
			map(operation => actions.generate.success({report: operation.response()}))
			, errorMap(actions.generate.fail)
		))
	))
	, withActions((actions, action$, state$) => action$.pipe(
		ofType(actions.export.request.type)
		, withLatestFrom(state$.pipe(map(state => state.reports.deviceStatus.report)))
		, tap(([action, report]) => resolver.runResolve(report.statuses))
		, ignoreElements()
	))
	, withActions((actions, action$, state$) => action$.pipe(
		ofType(actions.export.progress.type)
		, filter(action => action.progress == 100)
		, withLatestFrom(state$.pipe(map(state => ({
			reporting: state.reports.deviceStatus
			, deviceMap: state.devices.map
			, eventTypes: state.registry.eventTypes.typeMap
			, guardMap: state.zoneGuards.map
			, zoneMap: state.zones.map
		}))))
		, map(([action, state]) => actions.export.success({csv: formatCSV(state)}))
	))
);

const formatCSV = state => {
	const {parameters, report} = state.reporting;
	const lines = [];

	lines.push(['report type', 'generated', 'timezone', 'status aspects', 'devices'].map(fc));
	const aspects = [];
	if (parameters.position) aspects.push('last position');
	if (parameters.event) aspects.push('last event');
	if (parameters.zoneGuards) aspects.push('current zones');
	lines.push([
		fc('device status report')
		, report.timeAt
		, getTimezoneString()
		, aspects.map((aspect, at) => (at == 0 ? fc : f)(aspect)).join(', ')
		, parameters.uris ? denominateDevices(parameters.uris.map(uri => state.deviceMap[uri])) : fc('all devices')
	]);

	const headers = ['device'];
	if (parameters.position) headers.push('location', null, null);
	if (parameters.event) headers.push('event', null);
	if (parameters.zoneGuards) headers.push('zone visit', null, null);
	lines.push(headers.map(cell => cell ? fc(cell) : cell));

	report.statuses.forEach(status => {
		let cells = [state.deviceMap[status.uri].denomination()];
		if (parameters.position) {
			const message = status.state?.message;
			if (!message) cells.push(null, null, null);
			else {
				cells.push(message.generatedAt);
				const {longitude, latitude} = status.state?.message;
				if (longitude == null || latitude == null) cells.push(null, null);
				else {
					cells.push(`(${latitude};${longitude})`);
					const address = resolver.resolved[resolver.key(latitude, longitude)]?.getAddress();
					cells.push(address && address.format());
				}
			}
		}
		if (parameters.event) {
			const event = status.state?.event;
			if (!event) cells.push(null, null);
			else {
				cells.push(event.generatedAt);
				const eventType = state.eventTypes[event.eventType];
				cells.push(eventType && fc({prefix: 'device-event', id: eventType.name}));
			}
		}
		if (parameters.zoneGuards) {
			const indent = cells.length;
			const zoneVisits = status.zoneVisits;
			if (!zoneVisits) cells.push(null, null, null);
			else {
				const visits = zoneVisits.map(visit => {
					const guard = state.guardMap?.[visit.processorId]?.name || `#${visit.processorId}`;
					const zone = state.zoneMap?.[visit.zoneId]?.name || `#${visit.zoneId}`;
					return [
						visit.enteredAt, formatUTCTicksDuration(report.timeAt.getTime() - visit.enteredAt.getTime())
						, `${zone} (${guard})`
					]
				});
				cells.push(...visits[0]);
				for (let at = 1; at < visits.length; ++at) {
					lines.push(cells);
					cells = new Array(indent).fill();
					cells.push(...visits[at]);
				}
			}
		}
		lines.push(cells);
	});

	return lines.map(cells => cells.map(value => {
		if (value == null) return '';
		if (value instanceof Date) return formatDateTime(value);
		let string = String(value);
		const qutes = 0 <= string.indexOf('"');
		if (qutes) string = string.replaceAll('"', '""');
		if (qutes || 0 <= string.indexOf(',')) string = '"' + string + '"';
		return string; 
	}).join(',')).join('\n');
};

export {addActions, reducer, epic, connect};
