import React, { useImperativeHandle, useState } from 'react';
import { FiChevronDown, FiChevronLeft, FiChevronRight, FiPause, FiPlay, FiSkipBack, FiSkipForward } from 'react-icons/fi';
import { connect } from 'react-redux';
import { Dropdown } from 'rsuite';
import Button from '../../../general/form/Button';
import { actions, HistorySubject } from '../../../../redux/app/timeMachine/player';
import { useI18n } from '../../../../../i18n';
import ButtonGroup from '../../../general/form/ButtonGroup';
import InputNumber from '../../../general/form/InputNumber';
import InputPicker from '../../../general/form/InputPicker';
import Popup from '../../../general/Popup';
import './timeMachinePlayerWidget.scss';

const SPEED_VALUES = [-10, 10, 100, 1000];

const getSpeedLabel = (value) => value < 0 ? ('-x' + Math.abs(value)) : ('x' + value);

function TimeMachinePlayerWidget(props) {
	const { f, fc } = useI18n();
	const [opened, setOpened] = useState(false);

	useImperativeHandle(props.customRef, () => ({
		title: () => fc('history navigator'),
		actions: () => {},
		menuItems: () => {},
		dynamicContent: () => {},
		expandable: false
	}));

	const onSubjectChange = (subject) => {
		props.dispatch(actions.setSubject({ subject }));
	}

	const onPresetSelect = (value) => {
		setOpened(false);
		onSpeedChange(value);
	}

	const onTogglePlay = () => {
		if (props.state.playing) {
			props.dispatch(actions.stop());
		} else {
			props.dispatch(actions.play());
		}
	}

	const onSpeedChange = (value) => {
		let speed = parseFloat(value);
		if (isNaN(speed)) speed = 1;
		props.dispatch(actions.setSpeed({ speed }));
	}

	const onSkipBack = () => {
		props.dispatch(actions.skipBack());
	}

	const onSkipForward = () => {
		props.dispatch(actions.skipForward());
	}

	const onStepBack = () => {
		props.dispatch(actions.stepBack());
	}

	const onStepForward = () => {
		props.dispatch(actions.stepForward());
	}

	return (
		<div className="time-machine-player widget">
			<ButtonGroup>
				<Button title={fc('player.step back')} onClick={onStepBack}><FiChevronLeft /></Button>
				<InputPicker
					data={[
						{ value: HistorySubject.Message, label: fc("message") },
						{ value: HistorySubject.Event, label: fc("event") },
					]}
					value={props.state.subject}
					onChange={onSubjectChange}
					cleanable={false}
				/>
				<Button title={fc('player.step forward')} onClick={onStepForward}><FiChevronRight /></Button>
			</ButtonGroup>
			<ButtonGroup>
				<InputNumber
					onChange={onSpeedChange}
					value={props.state.speed}
					min={-1000} max={1000}
				/>
				<Popup
					isOpen={opened}
					setIsOpen={(value) => setOpened(value)}
					usePortal
					hasBackdrop
					content={(
						<Dropdown.Menu>
							{SPEED_VALUES.map(value => <Dropdown.Item key={value} onClick={() => onPresetSelect(value)}>
								{getSpeedLabel(value)}
							</Dropdown.Item>)}
						</Dropdown.Menu>
					)}
				>
					<Button
						className="chevron"
						appearance="ghost"
						onClick={() => setOpened(!opened)}
					>
						<FiChevronDown />
					</Button>
				</Popup>
				<Button title={fc('player.skip back')} onClick={onSkipBack}><FiSkipBack /></Button>
				<Button
					title={f('player.play/pause')}
					active={props.state.playing}
					onClick={onTogglePlay}
				>
					<FiPlay /><FiPause />
				</Button>
				<Button title={fc('player.skip forward')} onClick={onSkipForward}><FiSkipForward /></Button>
			</ButtonGroup>
		</div>
	);
}

export default connect(
	state => ({
		state: state.timeMachine.player
	})
)(TimeMachinePlayerWidget);
