import { cx, ods } from '../../../api';
import { actions } from './actions';

class Processor {

	constructor (store) {
		this.store = store;
		this.needUpdate = false;
		this.filter = new ods.devices.EventFilter();
		this.filter.window = new ods.devices.EventWindowFilter({ size: 50, forward: true });
	}

	ready() {
		return this.store.getState().latestEvents.map != null;
	}

	start() {
		if (this.started()) return;
		this.timer = setInterval(this.tick, 5000);
	}

	started() {
		return !!this.timer;
	}

	stop() {
		if (this.timer) {
			clearInterval(this.timer);
			this.timer = null;
		}
	}

	lock() {
		if (this.locked()) return;
		this._locked = true;
	}

	locked() {
		return !!this._locked;
	}

	unlock() {
		this._locked = false;
		this.update();
	}

	tick = () => {
		if (this.locked() || !this.needUpdate) return;
		this.needUpdate = false;
		this.store.dispatch(actions.find.request({ filter: this.filter }));
	}

	update() {
		const map = this.store.getState().latestEvents.recentMap;
		Object.keys(map || []).forEach(uri => {
			const events = map[uri];
			if (events) events.forEach(event => {
				this.filter.window.updateAnchor(event);
			});
		});

	}

	updateStarted() {
		if (this.ready() && !this.started()) {
			this.start();
		}
	}

	canReceive() {
		return this.ready() && this.started();
	}

	receive(announcements) {
		this.updateStarted();
		if (this.canReceive()) announcements.forEach(announcement => {
			if (cx.o.typeOf(announcement, ods.devices.DeviceNewEventAnnouncement)) {
				this.needUpdate = true;
			}
		});
	}
}

export default Processor;
