import React from 'react';
import { useI18n } from '../../../../../i18n';
import Menu from '../../../general/menu/Menu';
import iconChart from '../../../../img/icons/chart.png'
import { className } from '../../../../lib/className';

/**
 * @param {Object} props
 * @param {function} props.onClick
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.active]
 */
function SwitchChartMenuItem(props) {
	const { fc } = useI18n();

	return (
		<Menu.Item className={className({ active: props.active })} onClick={props.onClick} disabled={props.disabled}>
			<img src={iconChart} alt="" title={props.active ? fc('hide chart') : fc('show chart')} />
		</Menu.Item>
	);
}

export default SwitchChartMenuItem;