import { switchMap, map, filter, ignoreElements, tap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

import { LogLevel, rootLogger } from 'core/lib/log';

import { rs } from 'core/rs';

import { ActionGeneratorBuilder, deltaReducer, errorMap } from "core/redux/actions";
import {actions as sessionActions} from '../session';

const logger = rootLogger.logger('veta-issueTypes').at(LogLevel.Verbose);

export const actions = new ActionGeneratorBuilder('veta-issueTypes')
	.subtype('load', load => load.request().success('issueTypes').fail())
	.build()
;

const defaultState = {
	list: null,
	map: null,
	pending: false,
	error: null
};

export const reducer = deltaReducer((state, action) => {
	switch (action.type) {
		case actions.load.request.type: return {
			pending: true
			, error: null
		};
		case actions.load.success.type: return {
			pending: false
			, list: action.issueTypes
			, map: Object.fromEntries(action.issueTypes.map(issueType => [issueType.id, issueType]))
		};
		case actions.load.fail.type: return {
			pending: false
			, error: action.errorMessage
		};
	}
	return null;
}, defaultState);

export const epic = combineEpics(
	action$ => action$.pipe(
		ofType(actions.load.request.type)
		, switchMap(action => rs('veta/issues/types').defer$().pipe(
			map(issueTypes => actions.load.success({issueTypes}))
			, errorMap(actions.load.fail)
		))
	)
	, (action$, state$) => action$.pipe(
		ofType(actions.load.success.type)
		, filter(_ => logger.loggable(LogLevel.Debug))
		, withLatestFrom(state$.pipe(map(state => state.veta.issueTypes)))
		, tap(([action, state]) => {
			logger.debug('issues types', state.list);
		})
		, ignoreElements()
	)
	, action$ => action$.pipe(
		ofType(sessionActions.events.started.type)
		, map(() => actions.load.request())
	)
);

