import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import { useI18n } from '../../../../../i18n';
import { generateId } from '../../../../misc/misc';
import { cx } from '../../../../api';
import { actions } from '../../../../redux/api/deviceDigest';
import { actions as registryActions } from '../../../../redux/api/registry';
import { className, fromProps } from '../../../../lib/className';
import Loader from '../../../general/Loader';
import PanelLayout from '../../../general/layout/PanelLayout';
import { FiActivity } from 'react-icons/fi';
import DeviceDigestGraphsView from './DeviceDigestGraphsView';
import { DeviceDetailsProxy } from '../../../../api/device'; // eslint-disable-line
import './deviceDigestView.scss';

/**
 * @param {Object} props
 * @param {number} props.number
 * @param {string} props.title
 * @param {string} props.link
 * @param {boolean} [props.pending]
 */

function DigestCard(props) {

	let content = null;
	if (props.pending) {
		content = <Loader size={Loader.Size.LG} />;
	} else {
		content = (<>
			<div className="wrap">
				{/* <Link to={'#'}> */}
				<div className="count">
					<CountUp end={props.number} preserveValue duration={2} />
				</div>
				<div>{props.title}</div>
				{/* <span className="link-icon"><FiLink /></span> */}
				{/* </Link> */}
			</div>
		</>);
	}

	return (
		<div className={className(fromProps(props), 'digest-card')}>
			{content}
		</div>
	)
}

/**
 * @param {Object} props
 * @param {DeviceDetailsProxy} props.device
 */

function DeviceDigestView(props) {
	const { f } = useI18n();
	const uid = useRef(generateId());
	const digest = props.digest && props.digest[uid.current];

	useEffect(() => {
		if (digest == null) {
			const filter = new cx.ods.devices.DigestFilter(cx.today());
			props.loadDigest(props.device.uri, uid.current, filter);
		}
		if (props.eventTypes.typeMap == null) {
			props.loadEventTypes();
		}
		return () => {
			props.clearDigest(props.device.uri, uid.current);
		}
	}, [props.device]);

	let eventsCount = 0;
	let indcidentsCount = 0;
	let tripsCount = 0;

	if (digest && !digest.pending && props.eventTypes.typeMap != null) {
		if (digest.digest.eventDigests) {
			digest.digest.eventDigests.forEach(eventDigest => {
				const eventType = props.eventTypes.typeMap[eventDigest.eventType];
				if (eventType.incident) {
					indcidentsCount += eventDigest.quantity;
				} else {
					eventsCount += eventDigest.quantity;
				}
			});
		}
		if (digest.digest.tripDigest) {
			tripsCount = digest.digest.tripDigest.quantity;
		}
	}

	const pending = !digest || digest && digest.pending && !!digest.digest;
	return (
		<PanelLayout
			className={className('device-digest-view', fromProps(props))}
			icon={<FiActivity />}
			title={f('recent activity')}
		>
			<DigestCard
				className="events"
				link={'/device/' + props.device.uri + '/events'}
				title={f('events')}
				pending={pending}
				number={eventsCount}
			/>
			<DigestCard
				className="incidents"
				link={'/device/' + props.device.uri + '/events'}
				title={f('incidents')}
				pending={pending}
				number={indcidentsCount}
			/>
			<DigestCard
				className="trips"
				link={'/device/' + props.device.uri + '/trips'}
				title={f('trips')}
				pending={pending}
				number={tripsCount}
			/>
			<DeviceDigestGraphsView digest={digest ? digest.digest : null} uri={props.device.uri} />
		</PanelLayout>
	)
}

export default connect(
	(state, props) => ({
		digest: state.deviceDigest && state.deviceDigest[props.device.uri],
		eventTypes: state.registry.eventTypes
	}),
	(dispatch) => ({
		loadDigest: (uri, uid, filter) => dispatch(actions.filter.request({ uri, uid, filter })),
		clearDigest: (uri, uid) => dispatch(actions.clear({ uri, uid })),
		loadEventTypes: () => dispatch(registryActions.eventTypes.request())
	})
)(DeviceDigestView);
