import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import { cx } from '../../../api';
import { capitalize } from '../../../misc/misc';
import { DragItemType } from '../dnd/DragItemType';
import { fetchChildren } from '../../../redux/app/categories/grouping';
import { actions as contextActions } from '../../../redux/app/context';
import SelectPicker from '../../general/form/SelectPicker';
import { useI18n } from '../../../../i18n';
import { className, fromProps } from '../../../lib/className';
import { sortByName } from '../../../lib/sorting';

const sort = (categories, defaultCategory) => {
	if (defaultCategory) {
		categories = sortByName(categories).filter(category => category.categoryId != defaultCategory.categoryId);
		categories.unshift(defaultCategory);
	}
	return categories;
}

/**
 * Controlled component.
 * @param {Object} props
 * @param {function} props.onChange
 * @param {number} [props.categoryId] fallback to default grouping if absent
 * @param {boolean} [props.dark]
 */

function GroupingPicker(props) {
	const { fc } = useI18n();
	const groupings = props.groupings.root && props.groupings.map[props.groupings.root.categoryId];
	const defaultGrouping = groupings ? groupings.find(category => category.mnemonics == "default-grouping") : null;
	const grouping = groupings && props.categoryId ? groupings.find(category => category.categoryId == props.categoryId) : null;
	const editable = grouping ? grouping.custom : false;

	const onChange = (id) => {
		props.onChange(parseInt(id));
	}

	useEffect(() => {
		if (props.groupings.root != null && groupings == null) {
			fetchChildren(props.groupings.root.categoryId);
		}
	}, [props.groupings.root]);

	useEffect(() => {
		if (groupings != null) {
			if (props.categoryId != null) {
				if (!grouping) props.onChange(null);
			} else {
				if (defaultGrouping) props.onChange(defaultGrouping.categoryId);
			}
		}
	}, [groupings, props.categoryId]);

	// ---------------------------------------------

	const onDrop = (item, targetGroupingId) => {
		switch (item.type) {
			case DragItemType.ACTION_EDIT:
				props.dispatch(contextActions.actionSet({
					actionType: 'edit',
					name: 'category',
					data: {
						title: "scheme",
						offsetTop: cx.dom.at.client(document.getElementById("grouping-picker")).top,
						id: targetGroupingId,
					}
				}));
				break;
			case DragItemType.ACTION_REMOVE:
				props.dispatch(contextActions.actionSet({
					actionType: 'remove',
					name: 'category',
					data: {
						title: "scheme",
						offsetTop: cx.dom.at.client(document.getElementById("grouping-picker")).top,
						id: targetGroupingId,
					}
				}));
				break;
		}
	}

	const [dropState, dropRef] = useDrop({
		accept: [DragItemType.ACTION_EDIT, DragItemType.ACTION_REMOVE],
		drop: item => onDrop(item, props.categoryId),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
	})

	// --------------------------------------------

	let content = null;
	if (props.groupings.root != null) {
		if (groupings != null) {
			const items = sort(groupings, defaultGrouping).map(category => {
				const label = category.custom ? capitalize(category.name) : fc({ prefix: 'category', id: category.name });
				return ({
					value: Number(category.categoryId).toString(),
					label
				});
			});
			content = (
				<div
					ref={editable ? dropRef : null}
					id="grouping-picker"
					className={className('grouping-picker', fromProps(props), { 'droppable': dropState.isOver && dropState.canDrop })}
				>
					<SelectPicker
						className={className({ 'dark': props.dark })}
						data={items}
						cleanable={false}
						searchable={false}
						onChange={onChange}
						value={
							grouping
								? grouping.categoryId.toString()
								: defaultGrouping
									? defaultGrouping.categoryId.toString()
									: null
						}
					/>
				</div>
			)
		}
	}
	return (content);
}


export default connect(
	state => ({
		groupings: state.categories.groupings
	})
)(GroupingPicker);
