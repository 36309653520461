import React, { DispatchWithoutAction, FC } from 'react';

import {
  MapOptionButtonStyled,
  MapOptionLabelStyled,
  MapOptionStyled,
} from 'ui/molecules/map/default-view-map-option.styled';
import { useI18n } from 'i18n';
import Button from 'core/react/general/form/Button';

interface IDefaultViewMapOptionProps {
  saveMapView: DispatchWithoutAction;
}

export const DefaultViewMapOption: FC<IDefaultViewMapOptionProps> = ({ saveMapView }) => {
  const { fc } = useI18n();

  return (
    <MapOptionStyled>
      <MapOptionLabelStyled>{fc('default view')}</MapOptionLabelStyled>
      <MapOptionButtonStyled>
        <Button onClick={saveMapView}>{fc('use current')}</Button>
      </MapOptionButtonStyled>
    </MapOptionStyled>
  );
};
