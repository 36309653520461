import { actions } from '../actions';
import { reduxSwitch } from '../../../tools';

const defaultState = {
	preview: null,
	list: null,
	uri: null,
	parameters: null,
	hasMore: null,
	pending: false,
	error: null,
	at: null,
	loadingAll: false,
	// export
	exporting: false,
	progress: null, // { percent, done, total }
	csv: null,
};

const updateAnchor = (parameters, intervals) => {
	if (intervals && intervals.length > 0) {
		let interval = intervals[intervals.length-1];
		let anchorAt = parameters.timeRange.retrospective
			? interval.since.generatedAt
			: interval.until
		;
		parameters.timeRange.window.anchorAt = anchorAt;
	}
	return parameters;
}

function apiReducer(state, action) {
	let _state;
	switch (action.type) {
		case actions.fuelLevel.request.type:
			if (action.clear) {
				_state = { ...defaultState };
			} else {
				_state = {...state};
			}
			return {
				..._state,
				uri: action.uri,
				parameters: action.parameters,
				pending: true,
				error: null,
				at: action.clear ? new Date() : _state.at
			};
		case actions.fuelLevel.success.type:
			const intervals = action.intervals;
			const batchSize = state.parameters.timeRange.window.size;
			const hasMore = intervals ? intervals.length == batchSize : false;
			const list = state.list ? (intervals ? state.list.concat(intervals) : state.list) : (intervals ? [...intervals] : []);
			_state = {...state};
			return {
				..._state,
				list,
				hasMore,
				parameters: updateAnchor(state.parameters, intervals),
				pending: false
			};
		case actions.fuelLevel.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		case actions.fuelLevel.cancel.type:
			return {
				...state,
				pending: false
			};
		default:
			return state;
	}
}

function exportReducer(state, action) {
	switch (action.type) {
		case actions.fuelLevel.export.type:
			return {
				...state,
				exporting: true,
				progress: null,
				csv: null
			}
		case actions.fuelLevel.exportProgress.type:
			return {
				...state,
				progress: {
					percent: action.progress,
					done: action.count,
					total: action.total
				}
			}
		case actions.fuelLevel.exportDone.type:
			return {
				...state,
				parameters: state.parameters,
				exporting: false,
				csv: action.csv
			}
		case actions.fuelLevel.exportClear.type:
			return {
				...state,
				csv: null
			}
		default:
			return state;
	}
}

function loadingAllReducer(state, action) {
	switch (action.type) {
		case actions.fuelLevel.loadingAll.type:
			return {
				...state,
				loadingAll: state.hasMore
			};
		case actions.fuelLevel.loadingAllCancel.type:
		case actions.fuelLevel.loadingAllDone.type:
			return {
				...state,
				loadingAll: false
			};
		default:
			return state;
	}
}

const reducer = reduxSwitch([apiReducer, exportReducer, loadingAllReducer], defaultState);

export { reducer };
