import React from 'react';
import { useI18n } from '../../../../../../i18n';
import { formatter, datetime } from "../../../../../misc/datetime";
import { cx } from "../../../../../api";

/**
 * @param {Object} props
 * @param {Object} props.data
 */

function WidgetDataSignature({ data }) {
	const { fc, pc } = useI18n();
	let content = null;
	if (!data) return content;
	switch (data.timeRange) {
		case 0:
			content = pc('last X days', data.days);
			break;
		case 1:
			content = (fc('daily since') + ' ' + formatter.date(new Date(data.sinceDaily), true));
			break;
		case 2:
			content = pc('last X hours', data.hours);
			break;
		case 3:
			const nowHourly = cx.now().getHours();
			const sinceHourly = cx.now();
			sinceHourly.setHours(data.sinceHourly); sinceHourly.setMinutes(0); sinceHourly.setSeconds(0);
			if (data.sinceHourly > nowHourly) {
				sinceHourly.setDate(new Date(sinceHourly.getTime() - datetime.DAY).getDate());
			}
			content = (fc('hourly since') + ' ' + formatter.relative(new Date(sinceHourly), cx.now(), true).value);
			break;
		default:
			break;
	}
	return (
		<div className="widget-data-signature">
			{content}
		</div>
	);
}

export default WidgetDataSignature;