import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import * as runways from './runways';
import * as runwayGuardDigest from './runwayGuardDigest';
import * as taxiways from './taxiways';
import * as taxiwayGuardDigest from './taxiwayGuardDigest';
import * as issueTypes from './issueTypes';
import * as resolutionTypes from './resolutionTypes';
import * as issues from './issues';

const reducer = combineReducers({
	runways: runways.reducer
	, runwayGuardDigest: runwayGuardDigest.reducer
	, taxiways: taxiways.reducer
	, taxiwayGuardDigest: taxiwayGuardDigest.reducer
	, issueTypes: issueTypes.reducer
	, resolutionTypes: resolutionTypes.reducer
	, issues: issues.reducer
});

const epic = combineEpics(
	runways.epic
	, runwayGuardDigest.epic
	, taxiways.epic
	, taxiwayGuardDigest.epic
	, issueTypes.epic
	, resolutionTypes.epic
	, issues.epic
);

export {reducer, epic};

