import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import EventField from '../../../../share/events/EventField';
import { className } from '../../../../../lib/className';
import { scrollIntoView } from '../../../../../misc/misc';

/**
 * @param {Object} props
 * @param {cx.ods.devices.DeviceEvents} props.deviceEvents
 * @param {cx.ods.devices.DeviceEvents} [props.focusDeviceEvent]
 * @param {boolean} [props.hideHeader]
 */

function GroupDeviceEventsView(props) {
	const focusRef = useRef();

	const isSelectedEvent = (event) => {
		return props.lastDeviceEvents && props.lastDeviceEvents.some(_event => event.eventId == _event.eventId);
	}

	useEffect(() => {
		if (focusRef.current) scrollIntoView(focusRef.current);
	}, [props.focusDeviceEvent]);

	const items = props.deviceEvents.events && props.deviceEvents.events.map(event => {
		const onClick = () => {
			props.onClick && props.onClick(event, props.deviceEvents.uri);
		}
		const selected = isSelectedEvent(event);
		const now = selected && props.now && Math.floor(props.now.getTime() / 1000) == Math.floor(event.generatedAt.getTime() / 1000);
		const hasFocus = props.focusDeviceEvent && props.focusDeviceEvent.event.eventId == event.eventId;
		return (
			<EventField
				key={event.eventId}
				className={className({ selected, now })}
				customRef={hasFocus ? focusRef : null}
				onClick={onClick}
				event={event}
				showDate
			/>
		)
	});

	return (
		<div className="device-events group">
			{!props.hideHeader && <div className="section-header">
				{props.device && props.device.denomination()}
			</div>}
			<div className="section">{items}</div>
		</div>
	);
}

export default connect(
	(state, props) => {
		const lastDeviceEvents = state.timeMachine.state.map
			&& state.timeMachine.state.map[props.deviceEvents.uri]
			&& state.timeMachine.state.map[props.deviceEvents.uri].events
		;
		const now = state.timeMachine.state.parameters && state.timeMachine.state.parameters.now;
		return {
			lastDeviceEvents, now,
			device: state.devices.map && state.devices.map[props.deviceEvents.uri]
		}
	}
)(GroupDeviceEventsView);