import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import {useI18n} from '../../../../../i18n';

import {actions as registryActions} from '../../../../redux/api/registry/actions';

import Loader from '../../../general/Loader';
import SelectPicker from '../../../general/form/SelectPicker';

import './schedule-types.scss';


const SCHEDULE_TYPE_CATEGORY_NAME = 'ReportScheduleType';


/**
 * @param {Object} props
 * @param {number} props.value - schedule type
 */

export const ScheduleTypeField = withScheduleTypes(props => {
	const i18n  = useI18n();
	const {value: scheduleType, scheduleTypes} = props;

	if (!scheduleTypes?.list) return <Loader className="schedule-type" size={Loader.Size.XS}/>;

	return <span className="schedule-type">{
		scheduleType ? format(i18n, scheduleTypes.map[scheduleType]) : `#${scheduleType}`
	}</span>;
});


/**
 * @param {Object} props
 * @param {number} props.value - schedule type
 */

export const ScheduleTypeSelector = withScheduleTypes(props => {
	const i18n  = useI18n();
	const {scheduleTypes, ...propsRest} = props;

	const options = scheduleTypes?.list ? scheduleTypes.list.map(scheduleType => ({
		value: scheduleType.code, label: format(i18n, scheduleType)
	})) : null;

	return <SelectPicker className="schedule-types" 
		data={options} loading={!options} cleanable={false} searchable={false} {...propsRest}
	/>;
});


function format(i18n, scheduleType) {
	return i18n.fc(`registry-category-${SCHEDULE_TYPE_CATEGORY_NAME}.${scheduleType.name}`);
}


function withScheduleTypes(Wrapped) {
	return connect(state => ({
		scheduleTypes: state.registry.category[SCHEDULE_TYPE_CATEGORY_NAME]
	}))(props => {
		const {scheduleTypes} = props;

		useEffect(() => {
			if (!scheduleTypes?.list) props.dispatch(registryActions.category.request({categoryName: SCHEDULE_TYPE_CATEGORY_NAME}));
		}, [scheduleTypes?.list]);
	
		return <Wrapped {...props}/>;
	});
}
