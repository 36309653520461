import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { generateId } from '../../../misc/misc';
import { actions } from '../../../redux/api/deviceFilteredEvents';
import List from '../../general/list/List';
import EventField from '../events/EventField';
import { cx } from '../../../api'; // eslint-disable-line
import { className, fromProps } from '../../../lib/className';
import NoDataView from '../../general/view/NoDataView';
import './deviceFilteredEvents.scss';

/**
 * @param {Object} props
 * @param {string} props.uri
 * @param {cx.ods.devices.EventFilter} props.filter
 * @param {string} [props.title]
 */

function DeviceFilteredEvents(props) {
	const uid = useRef(generateId());
	let content = null;
 	const events = props.deviceFilteredEvents && props.deviceFilteredEvents[uid.current];

	useEffect(() => {
		return () => props.dispatch(actions.clear({ uri: props.uri, uid: uid.current }));
	}, []);

	useEffect(() => {
		props.filter && props.dispatch(actions.find.request({ uri: props.uri, uid: uid.current, filter: props.filter }));
	}, [props.uri]);

	if (events) {
		if (events.list && events.list.length > 0) {
			content = events.list.map(event => {
				let commandName = null;
				let settingName = null; // TODO
				let monitorName = null; // TODO
				if (event.commandType && props.commands && props.commands.typeMap && props.commands.typeMap[event.commandType]) {
					commandName = props.commands.typeMap[event.commandType].description;
				}

				return (
					<div className="event-item" key={event.eventId}>
						{commandName
							&& <div className="type-name">
								<label>
									<span className="capitalize">
										{commandName}
									</span>
								</label>
							</div>
						}
						{settingName
							&& <div className="type-name">
								<label>
									<span className="capitalize">
										{settingName}
									</span>
								</label>
							</div>}
						{monitorName
							&& <div className="type-name">
								<label>
									<span className="capitalize">
										{monitorName}
									</span>
								</label>
							</div>
						}
						<EventField event={event} showDate />
					</div>
				);
			});
		} else {
			content = <NoDataView />;
		}
	}
	return (
		<div className={className("device-filtered-events", fromProps(props))}>
			{props.title
				&& <div className="title">
					<label>
						<span className="capitalize">
							{props.title}
						</span>
					</label>
				</div>
			}
			<List>
				{content}
			</List>
		</div>
	);
}

export default connect((state, props) => ({
	deviceFilteredEvents: state.deviceFilteredEvents[props.uri],
	commands: state.deviceCommands[props.uri],
}))(DeviceFilteredEvents);
