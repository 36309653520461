import React, { useState, useEffect, useRef } from 'react';
import { className, fromProps } from '../../../lib/className';
import Popup from '../../general/Popup';
import { Icon } from 'rsuite';
import { capitalize, isInModal, isInside } from '../../../misc/misc';
import './actionMenu.scss';

/**
 * @param {Object} props
 * @param {boolean} [props.disabled]
 * @param {string} [props.title]
 * @param {function} [props.onClose]
 * @param {function} [props.onChange]
 * @param {boolean} [props.keepOpen]
 * @param {boolean} [props.opened]
 * @param {function} [props.setOpened]
 */

function ActionMenu(props) {
	const [opened, setOpened] = useState(false);
	const box = useRef(null);
	const buttonBox = useRef(null);
	const controlled = props.opened !== undefined && props.setOpened !== undefined;
	const _className = className(
		'action-menu',
		fromProps(props),
		{
			'clickable': !props.disabled,
			'disabled': props.disabled
		}
	);

	const setIsOpen = (value) => {
		if (props.keepOpen && value || !props.keepOpen) {
			controlled ? props.setOpened(value) : setOpened(value);
		}
	}

	const onClose = () => {
		controlled ? props.setOpened(false) : setOpened(false);
		props.onClose && props.onClose();
	}

	useEffect(() => {
		const onClick = (event) => {
			if (
				!isInside(box.current, event.target)
				&& !isInside(buttonBox.current, event.target)
				&& !isInModal(event.target)
			) {
				onClose();
			}
		}
		document.body.addEventListener('click', onClick);
		return () => {
			document.body.removeEventListener('click', onClick);
		}
	}, []);

	useEffect(() => {
		props.onChange && props.onChange(opened);
	}, [opened]);

	const renderChildren = () => {
		return React.Children.map(props.children, child => {
			return child
				? React.cloneElement(child, { close: onClose })
				: child
			;
		});
	}

	return (
		<Popup
			isOpen={controlled ? props.opened : opened}
			position="bottom-right"
			disabled={props.disabled}
			setIsOpen={setIsOpen}
			className={_className}
			enforceFocus={false}
			usePortal
			content={(
				<div ref={box}>
					{renderChildren()}
				</div>
			)}
		>
			<div className="icon" ref={buttonBox} title={capitalize(props.title)}>
				<Icon icon='ellipsis-v' />
			</div>
		</Popup>
	);
}

export default ActionMenu;
