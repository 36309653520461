import React from 'react';
import { connect } from 'react-redux';
import ListItem from './ListItem';

/**
 * @param {Object} props
 * @param {Array.<Object>} props.group
 * @param {Object} props.selectedAssetTrip
 * @param {function} props.onClick
 */

function GroupListItem(props) {
	const items = props.group.map(assetTrip => {
		const isSelected = props.selectedAssetTrip?.presenceId == assetTrip.presenceId;
		return (
			<ListItem
				key={assetTrip.presenceId}
				selected={isSelected}
				onClick={props.onClick}
				assetTrip={assetTrip}
			/>
		)
	});

	return (
		<React.Fragment>
			<div className="section-header">
				{props.deviceMap[props.group[0].uri].name}
			</div>
			<div className="section">{items}</div>
		</React.Fragment>
	);
}

export default connect(state => ({ deviceMap: state.devices.map }))(GroupListItem);
