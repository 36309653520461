import React, { useRef, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { assert } from '../../../../lib/assert';
import { control, isSimpleProperty } from './switch';
import { cx } from '../../../../api';
// import VectorControl from './VectorControl';
import Form from '../../../general/form/Form';
import { PropertyValuesContext } from './PropertiesControl';
import { useCustomDrop } from './customDrop';
import { className, fromProps } from '../../../../lib/className';

let Control = null;

/**
 * Renders form controls for specified bundle, assumes properties registry loaded.
 * @param {Object} props
 * @param {cx.ods.meta.PropertyComposite|cx.ods.meta.PropertyBundle} props.property
 * @param {function} props.onChange
 */

function CompositeControl(props)  {
	const context = useContext(PropertyValuesContext);
	const map = useRef({});
	assert(props.property != null, "CompositeControl: property should not be null");

	useEffect(() => {
		if (context && context[props.property.propertyId]) {
			map.current = cx.i.hash(context[props.property.propertyId].elements, element => element.propertyId);
		}
	}, []);

	const onChange = (propertyValue) => {
		let value = propertyValue;
		if (!cx.o.typeOf(props.property, cx.ods.meta.PropertyBundle)) {
			if (propertyValue.value === null || propertyValue.elements && propertyValue.elements.length == 0) {
				delete map.current[propertyValue.propertyId];
			} else {
				map.current[propertyValue.propertyId] = propertyValue;
			}
			value = new cx.ods.meta.CompositeValue();
			value.propertyId = props.property.propertyId;
			value.elements = Object.values(map.current);
		}
		props.onChange(value);
	}

	let content = null;
	if (Array.isArray(props.property.elements)) {
		const items = props.property.elements.map(element => {
			let property = null;
			if (cx.o.typeOf(element, cx.ods.meta.BundleElement)) {
				property = props.properties.map[element.elementId];
			} else {
				property = element;
			}
			if (isSimpleProperty(property)) {
				return control(property, {
					property,
					key: property.propertyId,
					onChange
				});
			} else if (cx.o.typeOf(property, cx.ods.meta.PropertyVector)) {
				return null;
				// <VectorControl
				// 	key={property.propertyId}
				// 	property={property}
				// 	onChange={onChange}
				// />;
			} else {
				return <Control
					key={property.propertyId}
					property={property}
					onChange={onChange}
				/>;
			}
		});
		content = items;
	} else {
		// TODO
	}

	// -----------------------------------

	const [dropState, dropRef] = useCustomDrop(() => props.onRemove(props.property));
	const onRef = (element) => props.onRemove && dropRef(element);

	// -----------------------------------

	return (
		<Form.ControlGroup
			customRef={onRef}
			className={className(
				'property-composite',
				fromProps(props),
				{
					'droppable': dropState.isOver && dropState.canDrop,
					'bundle': cx.o.typeOf(props.property, cx.ods.meta.PropertyBundle)
				}
			)}
			label={props.property.label || props.property.name}
		>
			{content}
		</Form.ControlGroup>
	);
}

Control = connect(state => {
	return {
		properties: state.meta.properties
	}
})(CompositeControl);

export default Control;
