import React from 'react';
import SelectPicker from './SelectPicker';

/**
 * For parameters - see rsuite SelectPicker comopnent.
 */

function HoursPicker(props) {
	const data = [];

	for (let i = 0; i < 24; ++i) {
		const label = (i < 10 ? ('0' + i) : i) + ':00';
		data.push({ value: i, label });
	}

	return (
		<SelectPicker
			searchable={false}
			cleanable={false}
			data={data}
			{...props}
		/>
	);
}

export default HoursPicker;