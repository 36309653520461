import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter, generatePath } from 'react-router-dom';
import { useI18n } from '../../../i18n';
import OverviewMap from '../custom/map/OverviewMap';
import PopupActionDispatcher from '../custom/sidebar/DevicePopupActionDispatcher';
import { actions as pageActions } from '../../redux/app/pages';
import WidgetManager from '../general/widgets/WidgetManager';
import { URL_DELIMITER } from '../../misc/url';
import DashboardMapControls from '../custom/dashboard/map/DashboardMapControls';
import { setPageTitle } from '../../misc/page';

const ROOT_URL = '/dashboard';
const PAGE_TITLE = 'dashboard';

function DashboardPage(props) {
	const { fc } = useI18n();
	const map = useRef(null);
	const selectionInSync = useRef(false);
	const uris = props.match.params.uris;
	const selectedUris = props.dashboard.selection;

	useEffect(() => {
		setPageTitle(fc(PAGE_TITLE));
		props.dispatch(pageActions.dashboard.setActive({ status: true }));
		return () => {
			props.dispatch(pageActions.dashboard.setActive({ status: false }));
		}
	}, []);

	useEffect(() => {
		if (uris != null && props.deviceMap != null) {
			const _uris = uris.split(URL_DELIMITER);
			const needUpdate = _uris.length != selectedUris.length || _uris.some((uri) => !selectedUris.includes(uri));
			if (needUpdate) {
				const existingUris = _uris.filter(uri => props.deviceMap[uri]);
				props.dispatch(pageActions.dashboard.setSelection({ uris: existingUris }));
			}
		} else {
			selectedUris.length > 0 && props.dispatch(pageActions.dashboard.selectionClear());
		}
	}, [uris, props.deviceMap]);

	useEffect(() => {
		if (selectionInSync.current) {
			const presentURL = uris !== undefined ? uris : '';
			const url = 0 < selectedUris.length ? selectedUris.join(URL_DELIMITER) : '';
			if (url != presentURL) {
				props.history.push({
					pathname: generatePath(props.match.path) + (url ? ('/devices/' + url) : '')
				});
			}
		}
	}, [props.dashboard.selection]);

	useEffect(() => {
		selectionInSync.current = true;
		return () => {
			selectionInSync.current = false;
		}
	}, []);

	useEffect(() => {
		if (map.current) map.current.getOlMap().updateSize();
	}, [props.sidebarCollapsed]);

	return (
		<div className="app-page dashboard">
			<PopupActionDispatcher />
			<WidgetManager>
				<OverviewMap name="dashboard" control={DashboardMapControls} customRef={map} />
			</WidgetManager>
		</div>
	);
}

DashboardPage.Url = ROOT_URL;

export default connect(
	state => ({
		deviceMap: state.devices.map,
		dashboard: state.pages.dashboard,
		sidebarCollapsed: state.context.sidebarCollapsed
	})
)(withRouter(DashboardPage));
