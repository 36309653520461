import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import { modeMap } from '../../../../redux/api/assets/commute/reducer';
import { getHistoryManagerByName } from '../../../../redux/api/history/managers';
import CoinBoard from '../../../general/boards/CoinBoard';
import Loader from '../../../general/Loader';
import NoDataView from '../../../general/view/NoDataView';
import './commuterEvaluatingBoard.scss';

/**
 * @param {Object} props
 * @param {function} [props.onMounted]
 * @param {string} props.uid
 * for timemachine only
 * @param {string} [props.reduxKey]
 * @param {Array.<string>} [props.uris]
 */

function CommuterEvaluatingBoard(props) {
	const { f } = useI18n();
	let content = null;
	const hasData = Boolean(props.commute?.list);

	useEffect(() => {
		if (props.onMounted) props.onMounted();
	}, [hasData]);

	if (props.commute?.list) {
		let started = 0;
		let ended = 0;
		props.commute.list.forEach(interval => {
			if (interval.startedAt) ++started;
			if (interval.endedAt) ++ended;
		});
		content = [<CoinBoard quantity={started} title={f('in')} key="tap-in" />];
		if (props.commute.mode == modeMap.RECENT_TRIPS) {
			content.push(<CoinBoard quantity={ended} title={f('out')} key="tap-out" />);
		}
	} else if (props.commute?.pending) {
		content = (<Loader size={Loader.Size.MD} />);
	} else content = (<NoDataView message="no commuters data" />);

	return (
		<div className="commuter-evaluating-board">
			{content}
		</div>
	);
}
const mapStateToProps = (state, props) => {
	let commute = state.assets.commute[props.uid] || {};
	if (props.reduxKey == 'timeMachine') {
		let intervals = [];
		const stateMap = state.timeMachine.state.map;
		if (stateMap) {
			props.uris.forEach(uri => {
				if (stateMap[uri]?.assetPresences) intervals = intervals.concat(stateMap[uri].assetPresences);
			});
		}
		intervals.sort(getHistoryManagerByName('assetPresences').compare);
		commute.list = intervals;
		commute.mode = modeMap.CURRENT;
	}
	return { commute }
}
export default connect(mapStateToProps)(CommuterEvaluatingBoard);
