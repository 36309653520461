import { useI18n } from 'i18n';
import { IAttributeRangeDetector } from 'types/detectors';
import { IMnemonic } from 'types/registry';
import { getFormattedAttributeRange } from 'core/misc/getFormattedAttributeRange';
import { unitsString } from 'core/misc/attributeTypes';

interface IEnumerationInfo {
  enumeration: string[];
  label: string;
}

const ENUMERATIONS: Record<string, IEnumerationInfo> = {
  true: { enumeration: ['true'], label: 'on' },
  false: { enumeration: ['false'], label: 'off' },
};

export const useAttributeDescription = (detector: IAttributeRangeDetector | null, attributeType: IMnemonic | null) => {
  const { f, fc } = useI18n();

  if (!detector || !attributeType) {
    return null;
  }

  if (attributeType.mnemonics === 'boolean') {
    const enumeration = detector.enumeration;

    return enumeration ? enumeration.map((item) => fc(ENUMERATIONS[item].label)).join(', ') : null;
  }

  const range = getFormattedAttributeRange(detector);
  const unit = unitsString(attributeType.mnemonics);

  return !unit ? range : `${range}, ${f({ prefix: 'units', id: unit })}`;
};
