import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import { isSimpleProperty, control } from './switch';
import CompositeControl from './CompositeControl';
import { assert } from '../../../../lib/assert';
import { cx } from '../../../../api';
import { meta } from '../../../../redux/api/meta';
import DummyControl from '../../../general/form/DummyControl';
import Form from '../../../general/form/Form';
import DefaultActionBar from '../../actionbar/DefaultActionBar';
import ActionAdd from '../../../share/actionbar/ActionAdd';
import DevicePropertySwitch from '../../../custom/deviceProperties/meta/DevicePropertySwitch';
import Modal from '../../../general/Modal';
import './propertiesControl.scss';

export const PropertyValuesContext = React.createContext();

/**
 * @param {Object} props
 * @param {Object} props.data
 * @param {Array.<number>} props.bundleIds
 */

function PropertiesControl(props) {
	const { f } = useI18n();
	assert(Array.isArray(props.bundleIds), 'BundlesControl: parameter bundleIds should be array');
	const values = useRef(null); // map of all properties values
	const tree = useRef(null); // map of all root properties
	const registry = useRef(null); // map of all properties (excluding bundles)
	const [serial, setSerial] = useState(0);
	const [adding, setAdding] = useState(false);

	useEffect(() => {
		if (props.properties.map == null && !props.properties.pending) {
			props.dispatch(meta.properties.actions.load.request());
		}
	}, []);

	const onChange = (propertyValue) => {
		if (propertyValue.value === null || propertyValue.elements && propertyValue.elements.length == 0) {
			delete values.current[propertyValue.propertyId];
		} else {
			values.current[propertyValue.propertyId] = propertyValue;
		}
		setSerial(serial+1);
	}

	// ------------------------------------------

	const onAdd = () => {
		setAdding(true);
	}

	const onAddReady = (properties) => {
		properties.forEach(property => {
			if (cx.o.typeOf(property, cx.ods.meta.PropertyBundle)) {
				const map = buildBundleRegistry([property]);
				if (Object.keys(map).length > 0) {
					registry.current = { ...registry.current, ...map };
				}
			} else {
				registry.current[property.propertyId] = property;
			}
			tree.current[property.propertyId] = property;
		});
		setAdding(false);
	}

	const onAddCancel = () => {
		setAdding(false);
	}

	const onRemove = (property) => {
		delete tree.current[property.propertyId];
		if (cx.o.typeOf(property, cx.ods.meta.PropertyBundle)) {
			const _registry = buildBundleRegistry([property]);
			Object.keys(_registry).forEach(propertyId => {
				delete registry.current[property.propertyId];
				delete values.current[property.propertyId];
			});
		} else if (cx.o.typeOf(property, cx.ods.meta.PropertyRecord)) {
			const _registry = buildRecordRegistry({[property.propertyId]: property});
			Object.keys(_registry).forEach(propertyId => {
				delete registry.current[property.propertyId];
				delete values.current[property.propertyId];
			});
		} else if (cx.o.typeOf(property, cx.ods.meta.PropertyVector)) {
			 // TODO
		} else {
			delete registry.current[property.propertyId];
			delete values.current[property.propertyId];
		}
		setSerial(serial+1);
	}

	// ------------------------------------------

	const buildRecordRegistry = (map) => { // recursively make all properties map
		let _map = {...map};
		Object.values(_map).forEach(element => {
			if (element.elements) {
				const __map = buildRecordRegistry(cx.i.hash(element.elements, element => element.propertyId));
				_map = { ..._map, ...__map };
			}
		});
		return _map;
	}

	const buildBundleRegistry = (bundles) => { // recursively make all properties registry (including bundles)
		let tree = {};
		bundles.forEach(bundle => {
			if (bundle.elements != null) {
				bundle.elements.forEach(element => {
					const property = props.properties.map[element.elementId];
					tree[property.propertyId] = property;
					if (cx.o.typeOf(property, cx.ods.meta.PropertyBundle)) {
						const sub = buildBundleRegistry([property]);
						if (sub != null) tree = { ...tree, ...sub };
					} else if (cx.o.typeOf(property, cx.ods.meta.PropertyRecord)) {
						const sub = buildRecordRegistry({[property.propertyId]: property});
						if (sub != null) tree = { ...tree, ...sub };
					} else if (cx.o.typeOf(property, cx.ods.meta.PropertyVector)) {
						// TODO
					}
				});
			}
		});
		return tree;
	}

	if (props.properties.map != null && tree.current == null) {
		values.current = {};
		tree.current = {};
		registry.current = {};
		if (props.bundleIds != null) {
			const bundles = props.bundleIds.map(bundleId => props.properties.map[bundleId]);
			tree.current = cx.i.hash(bundles, (bundle) => bundle.propertyId);
			registry.current = buildBundleRegistry(bundles);
		}
		if (props.data != null) {
			values.current = cx.i.hash(props.data, (value) => value.propertyId);
			Object.keys(values.current).forEach(propertyId => {
				if (registry.current[propertyId] == null) {
					tree.current[propertyId] = props.properties.map[propertyId];
				}
			});
		}
		setSerial(serial+1);
	}

	let items = null;
	let excludeIds = null;
	let canRemove = false;
	if (tree.current != null) {
		const data = Object.values(tree.current);
		data.sort((left, right) => { // alphabetically, first bundles then other properties
			const isLeftBundle = cx.o.typeOf(left, cx.ods.meta.PropertyBundle);
			const isRightBundle = cx.o.typeOf(right, cx.ods.meta.PropertyBundle);
			if (isLeftBundle && isRightBundle) {
				return (left.label || left.name).toLowerCase().localeCompare((right.label || right.name).toLowerCase());
			} else if (isLeftBundle) {
				return -1;
			} else if (isRightBundle) {
				return 1;
			}
			return (left.label || left.name).toLowerCase().localeCompare((right.label || right.name).toLowerCase());
		});
		items = data.map(property => {
			if (isSimpleProperty(property)) {
				return control(property, {
					property,
					key: property.propertyId,
					onChange,
					onRemove
				});
			} else if (cx.o.typeOf(property, cx.ods.meta.PropertyVector)) {
				return null; // TODO
			} else { // bundle or record
				const canRemove = props.bundleIds.indexOf(property.propertyId) < 0;
				return (
					<CompositeControl
						key={property.propertyId}
						property={property}
						onChange={onChange}
						className="root"
						onRemove={canRemove ? onRemove : null}
					/>
				);
			}
		});
		excludeIds = Object.keys(registry.current).map(key => +key).concat(props.bundleIds);
		canRemove = Object.keys(tree.current).map(key => +key).some(id => props.bundleIds.indexOf(id) < 0);
	}

	return (
		<div className="form-control properties">
			<div className="header">
				<label>{props.label || f('properties')}</label>
				<DefaultActionBar
					hideEdit
					hideRemove={!canRemove}
					appendActions={<ActionAdd title={f('add property')} onClick={onAdd} />}
				/>
			</div>
			<PropertyValuesContext.Provider value={buildRecordRegistry(values.current)}>
				{items}
				<Form.Control
					controlType={DummyControl}
					controlName="properties"
					value={values.current != null ? Object.values(values.current) : null}
					onChange={() => {}}
					hidden
				/>
			</PropertyValuesContext.Provider>
			{adding &&
				<Modal
					onClose={onAddCancel}
					title={f('select property')}
				>
					<DevicePropertySwitch
						onCancel={onAddCancel}
						onReady={onAddReady}
						excludeIds={excludeIds}
						showBundles
					/>
				</Modal>
			}
		</div>
	);
}

export default connect(state => ({
	properties: state.meta.properties
}))(PropertiesControl);
