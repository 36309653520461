import React, { useState } from 'react';
import { Nav, Avatar } from 'rsuite';
import { FiFileText, FiUser } from 'react-icons/fi';
import { useI18n } from '../../../../i18n';
import PopupNavItem from './PopupNavItem';
import SettingsMenu from './SettingsMenu';
import NavLinkItem from './NavLinkItem';
import DashboardPage from '../../pages/DashboardPage';
import ReportsPage from '../../pages/ReportsPage';
import ProfileControl from './ProfileControl';
import RecentEventList from '../../share/events/RecentEventList';
import AccountPopup from '../profile/AccountPopup';
import iconBell from '../../../img/icons/bell.png';
import iconReport from '../../../img/icons/report.png';
import iconSettings from '../../../img/icons/settings.png';
import iconQuestion from '../../../img/icons/popup-question.png';
import { FiHome as  IconHome } from 'react-icons/fi';
import LangPopupNavItem from './LangPopupNavItem';
import { className, fromProps } from '../../../lib/className';
import './globalMenu.scss';
import { AllRoutes } from 'constants/routes';

function GlobalMenu(props) {
	const { f } = useI18n();
	const [showProfile, setShowProfile] = useState(false);
	const [profileAspect, setProfileAspect] = useState();

	return (
		<Nav className={className("global-menu", fromProps(props))}>
			<NavLinkItem to={DashboardPage.Url} title={f('dashboard')}>
				<IconHome size={20} />
			</NavLinkItem>
			<NavLinkItem to={AllRoutes.Issues} title={f('issues')}>
				<FiFileText size={20} />
			</NavLinkItem>
			<PopupNavItem
				icon={<img src={iconBell} alt="" />}
				content={<RecentEventList />}
				title={f('recent events')}
			/>
			<NavLinkItem to={ReportsPage.Url} title={f('reports')}>
				<img src={iconReport} alt="" />
			</NavLinkItem>
			<PopupNavItem icon={<img src={iconSettings} alt="" />} content={<SettingsMenu />} />
			<PopupNavItem
				icon={<Avatar circle><FiUser /></Avatar>}
				title={f('profile')}
				content={
					<ProfileControl
						onClick={(aspect) => {
							setProfileAspect(aspect);
							setShowProfile(true);
						}}
					/>
				}
			/>
			<LangPopupNavItem title={f('select language')} />
			<PopupNavItem icon={<img src={iconQuestion} alt="" />} disabled />
			{showProfile &&
				<AccountPopup
					aspect={profileAspect}
					setAspect={setProfileAspect}
					onClose={() => setShowProfile(false)}
				/>
			}
		</Nav>
	);
}

export default GlobalMenu;
