import { from, merge, of } from 'rxjs';
import { distinct, filter, groupBy, ignoreElements, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';

import { combineEpics, ofType } from "redux-observable";

import { LogLevel, rootLogger } from 'core/lib/log';
import { throttleMap } from "core/lib/rx";

import { cx } from "core/api";
import { rs } from "core/rs";

import { ActionGeneratorBuilder, deltaReducer, errorMap } from "core/redux/actions";
import { timestamps } from 'core/redux/serializers';

import { actions as announcerActions } from 'core/redux/api/announcer';

const logger = rootLogger.logger('veta-runwayGuardDigest').at(LogLevel.Verbose);

export const actions = new ActionGeneratorBuilder('veta-runwayGuardDigest')
	.subtype('load', load => load.request('runwayId').success({runwayId: true, digest: true}).fail())
	.type('subscribe').type('unsubscribe')
	.build()
;

const defaultState = {
	digests: null
	, pending: false
	, error: null
	, subscribed: 0
};

export const reducer = deltaReducer((state, action) => {
	switch (action.type) {
		case actions.load.request.type: return {
			pending: true
			, error: null
		};
		case actions.load.success.type: return {
			pending: false
			, digests: {...state.digests, [action.runwayId]: action.digest}
		};
		case actions.load.fail.type: return {
			pending: false
			, error: action.errorMessage
		};
		case actions.subscribe.type: return {
			subscribed: 0 < state.subscribed ? state.subscribed + 1 : 1
		};
		case actions.unsubscribe.type: return {
			subscribed: 1 < state.subscribed ? state.subscribed - 1 : 0
		};
	}
	return null;
}, defaultState);

const internalize = timestamps(['since']).internalize;

export const epic = combineEpics(
	action$ => action$.pipe(
		ofType(actions.load.request.type)
		, groupBy(action => action.runwayId)
		, mergeMap(action$ => action$.pipe(
			throttleMap(action => rs(`veta/airside/runway/${action.runwayId}/guard/digest`).defer$().pipe(
				map(digest => ({...digest, guarded: digest.guarded.map(internalize)}))
				, map(digest => actions.load.success({runwayId: action.runwayId, digest}))
				, errorMap(actions.load.fail)
			))
		))
	)
	, (action$, state$) => action$.pipe(
		ofType(announcerActions.announced.type)
		, withLatestFrom(state$.pipe(map(state => state.veta.runwayGuardDigest)))
		, filter(([_, state]) => 0 < state.subscribed)
		, mergeMap(([action]) => from(action.announcements).pipe(
			mergeMap(announcement => merge(
				of(announcement).pipe(
					filter(announcement => cx.o.typeOf(announcement, cx.ods.veta.RunwayGuardStateChangeAnnouncement))
					, map(announcement => announcement.runwayId)
				)
				, of(announcement).pipe(
					filter(announcement => cx.o.typeOf(announcement, cx.ods.veta.RunwayStatusChangeAnnouncement))
					, map(announcement => announcement.objectId)
				)
				, of(announcement).pipe(
					filter(announcement => cx.o.typeOf(announcement, cx.ods.veta.AirsideIssueAnnouncement))
					, withLatestFrom(state$.pipe(map(state => state.veta.runways)))
					, filter(([announcement, state]) => state.map?.[announcement.objectId] != null)
					, map(([announcement]) => announcement.objectId)
				)
			))
			, distinct()
		))
		, map(runwayId => actions.load.request({runwayId}))
	)
	, (action$, state$) => action$.pipe(
		ofType(actions.load.success.type)
		, filter(() => logger.loggable(LogLevel.Debug))
		, withLatestFrom(state$.pipe(map(state => state.veta.runwayGuardDigest)))
		, tap(([_, state]) => {
			logger.debug('runway guards digests', state.digests);
		})
		, ignoreElements()
	)
);


