import { MutableRefObject, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { cx } from 'core/api';
import { actions } from 'core/redux/api/mqttClients';
import { mqttClientsStateSelector } from 'core/redux/selectors/mqttClients';

interface IMqttClientFormData {
  description: string;
}

export const useMqttClientForm = (isPending: MutableRefObject<boolean>) => {
  const params = useParams<Record<string, string>>();

  const dispatch = useDispatch();
  const { list: mqttClients, error, isLoading } = useSelector(mqttClientsStateSelector);

  const { control, reset, formState, handleSubmit } = useForm<IMqttClientFormData>();
  const { errors } = formState;

  const { id: clientId } = params;

  const setDefaultValues = () => {
    if (clientId) {
      const selectedClient = mqttClients?.find((client) => String(client.clientId) === clientId);

      reset({ description: selectedClient?.description });
    }
  };

  const onSubmit = handleSubmit((data: IMqttClientFormData) => {
    const client = new cx.ods.mqtt.Client(data);

    clientId
      ? dispatch(actions.update.request({ clientId, data: client }))
      : dispatch(actions.add.request({ data: client }));

    isPending.current = true;
  });

  useEffect(() => {
    setDefaultValues();
  }, []);

  return { isLoading, errors, formError: error, control, onSubmit };
};
