import React, { DispatchWithoutAction, FC } from 'react';

import { FormHeaderStyled, SelectIconStyled } from 'ui/pages/issues/issues-actions-select.styled';
import { fc } from 'i18n';
import { arrowDown } from 'assets/icons';

interface IFormHeaderProps {
  isOpen: boolean;
  isResolved: boolean;
  toggleDropdown: DispatchWithoutAction;
}

export const FormHeader: FC<IFormHeaderProps> = ({ isOpen, isResolved, toggleDropdown }) => (
  <FormHeaderStyled isResolved={isResolved} onClick={toggleDropdown}>
    <span>{fc(isResolved ? 'resolved' : 'unresolved')}</span>
    <SelectIconStyled width={9} height={5} details={arrowDown} $isOpen={isOpen} />
  </FormHeaderStyled>
);
