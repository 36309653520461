import styled from 'styled-components';

import { Colors } from 'ui/constants/colors';

export const ModalWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  bottom: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.25);
  transform: translateY(-50%);
  overscroll-behavior: none;
  cursor: default;
  z-index: 1010;
`;

export const ModalContentStyled = styled.div`
  position: relative;
  margin: 25px auto;
  border-radius: 10px;
  box-shadow: 0 1px 2px ${Colors.BlackRussian};
  background-color: ${Colors.White};
`;
