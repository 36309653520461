import React, { useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../../general/Loader';
import { className } from '../../../../lib/className';
import { useI18n } from '../../../../../i18n';
import Address from '../../../general/location/Address';
import DatetimeField from '../../../general/datetime/DatetimeField';
import ScrollList from '../../../general/list/ScrollList';
import ReportSummary from './ReportSummary';
import ReportHeader from './ReportHeader';
import { formatter } from '../../../../misc/datetime';
import { scrollReportItemIntoView } from '../../../../misc/misc';
import { actions } from '../../../../redux/api/reports';
import SwitchMapMenuItem from './SwitchMapMenuItem';
import CheckIntervalMap from './CheckIntervalMap';
import NoDataView from '../../../general/view/NoDataView';
import './speeding.scss';

function HeaderListItem() {
	const { f } = useI18n();
	return (
		<>
			<div className="header-item"><span className="capitalize">{f('item No.')}</span></div>
			<div className="header-item"><span className="capitalize">{f('start time')}</span></div>
			<div className="header-item"><span className="capitalize">{f('start location')}</span></div>
			<div className="header-item"><span className="capitalize">{f('end time')}</span></div>
			<div className="header-item"><span className="capitalize">{f('end location')}</span></div>
			<div className="header-item"><span className="capitalize">{f('duration')}</span></div>
			<div className="header-item"><span className="capitalize">{f('maximum speed') + ', ' + f('units.km/h')}</span></div>
		</>
	)
}

function ReportListItem(props) {
	const onClick = () => {
		props.onClick(props.interval, props.index);
	}

	const _className = className('clickable', { 'selected': props.selected });
	return (
		<>
			<div ref={props.customRef} className={_className} onClick={onClick}>{props.index}</div>
			<div className={_className} onClick={onClick}><DatetimeField datetime={props.interval.since.generatedAt} /></div>
			<div className={_className} onClick={onClick}><Address message={props.interval.since} full /></div>
			<div className={_className} onClick={onClick}>
				{props.interval.until
					&& <DatetimeField datetime={props.interval.until.generatedAt} now={props.interval.since.generatedAt} />
				}
			</div>
			<div className={_className} onClick={onClick}><Address message={props.interval.until} full /></div>
			<div className={_className} onClick={onClick}>
				{props.interval.until && formatter.duration(props.interval.since.generatedAt, props.interval.until.generatedAt)}
			</div>
			<div className={_className} onClick={onClick}>{props.interval.maxSpeed}</div>
		</>
	)
}

function EodListItem() {
	const { f } = useI18n();
	return <div className="indicator"><span className="capitalize">{f('you have reached the end')}</span></div>;
}

// -----------------------------------------------------------------


function Speeding(props) {
	const { f } = useI18n();
	const selectedItemBox = useRef();
	const [displayMap, setDisplayMap] = useState(false);
	const [selectedInterval, setSelectedinterval] = useState({}); // { [index]: interval }

	useLayoutEffect(() => {
		if (selectedItemBox.current) {
			scrollReportItemIntoView(selectedItemBox.current);
		}
	}, [selectedInterval, displayMap]);

	const loadNext = () => {
		const uri = props.state.uri;
		const parameters = props.state.parameters;
		props.dispatch(actions.speeding.request({ uri, parameters }));
	}

	const onItemClick = (interval, index) => {
		setSelectedinterval({ [index]: interval });
		if (!displayMap) setDisplayMap(true);
	}

	const switchMapHandler = () => {
		setDisplayMap(!displayMap);
	}

	let content = null;
	let mapBox = null;
	let canExport = false;
	if (props.state.list != null) {
		if (!props.state.list || props.state.list.length == 0) {
			content = <NoDataView message={f('no data for such criteria')} />;
		} else {
			canExport = true;
			let items = props.state.list.map((interval, at) => {
				const index = at + 1;
				const isSelected = Object.keys(selectedInterval)[0] == index;
				return (
					<ReportListItem
						key={at}
						onClick={onItemClick}
						selected={isSelected}
						customRef={isSelected ? selectedItemBox : undefined}
						interval={interval}
						index={index}
					/>
				);
			});
			items.unshift(<HeaderListItem key={1000000} />);
			content = (<ScrollList
				pending={props.state.pending}
				next={loadNext}
				isEod={!props.state.hasMore}
				eodItem={EodListItem}
			>
				{items}
			</ScrollList>);
			if (displayMap) {
				mapBox = (<CheckIntervalMap interval={Object.values(selectedInterval)[0]} />);
			}
		}
	} else {
		content = (
			<div className="center">
				{
					props.state.error
						? <span className="error">{props.state.error}</span>
						: <Loader size={Loader.Size.MD} />
				}
			</div>
		);
	}

	const exportReport = () => {
		props.dispatch(actions.speeding.export({ uri: props.state.uri }));
		props.history.goBack();
	}

	const timeRange = props.state.parameters.timeRange;
	return (
		<div className="report speeding">
			<ReportHeader
				title={f('speeding report')}
				canExport={canExport}
				onExport={exportReport}
				items={<SwitchMapMenuItem disabled={!canExport} active={displayMap} onClick={switchMapHandler} />}
			/>
			<ReportSummary
				since={timeRange.since}
				until={timeRange.until}
				devices={[props.devices[props.state.uri]]}
				generatedAt={props.state.at}
			/>
			<div className={className('content', { 'with-map': displayMap })}>
				{content}
				{mapBox}
			</div>
		</div>
	)
}

export default withRouter(connect(state => {
	return ({
		state: state.reports.speeding,
		devices: state.devices.map
	})
})(Speeding));
