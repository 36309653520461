import React from 'react';
import { connect } from 'react-redux';

import { useI18n } from '../../../../../i18n';
import DeviceSet from '../../../share/devices/DeviceSet';

import ObjectDeviceManager from "../../../share/devices/ObjectDeviceManager";

import './commute.scss';

export const CommuteScheduleControls = props => {
	const {f}  = useI18n();

	return <ObjectDeviceManager
		controlName="uris"
		title={f('devices restriction')}
		emptyMessage={f('all devices')}
		editMode
		uris={props.schedule?.uris}
	/>;
};

export const CommuteScheduleFields = props => {
	const {f}  = useI18n();

	return <DeviceSet
		label={f('devices restriction')}
		emptyMessage={f('all devices')}
		value={props.schedule?.uris}
	/>;
};

export const CommuteScheduleListHeaders = props => {
	const {f}  = useI18n();
	return <div>{f('devices')}</div>;
};

export const CommuteScheduleListValues = connect(
	state => ({devices: state.devices})
)(props => {
	const {f}  = useI18n();
	const {schedule, devices}  = props;

	let content = null;
	if (!schedule.uris) content = f('all');
	else {
		const uri = schedule.uris[0], device = devices.map?.[uri];
		const denomination = device ? device.denomination() : uri;
		if (schedule.uris.length == 1) content = denomination;
		else content = f('DEVICE and X other devices', {device: denomination, quantity: schedule.uris.length - 1});
	}

	return <div>{content}</div>;
});
