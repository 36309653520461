import React from 'react';
import { AbstractSeries } from 'react-vis';
import { getAttributeScale } from 'react-vis/es/utils/scales-utils';
import { className, fromProps } from '../../../../../lib/className';

function getLocs(evt) {
	const xLoc = evt.type === 'touchstart' ? evt.pageX : evt.offsetX;
	const yLoc = evt.type === 'touchstart' ? evt.pageY : evt.offsetY;
	return { xLoc, yLoc };
}

class Panning extends AbstractSeries {

	constructor(props) {
		super(props);
		this.refBox = React.createRef();
		this.state = {
			panning: false
		};
		this.startLocX = 0;
		this.startLocY = 0;
		this.mounted = false;
	}

	_setStartLocs(xLoc, yLoc) {
		this.startLocX = xLoc;
		this.startLocY = yLoc;
	}

	_getDiffLocs(xLoc, yLoc, write) {
		const diff =  {
			x: xLoc - this.startLocX,
			y: yLoc - this.startLocY
		};
		if (write) this._setStartLocs(xLoc, yLoc);
		return diff;
	}

	startPanning(event) {
		if (!this.mounted) return;
		const { onPanStart } = this.props;
		const { xLoc, yLoc } = getLocs(event);

		this.setState({ panning: true });
		this._setStartLocs(xLoc, yLoc);

		if (onPanStart) {
			onPanStart(event);
		}
	}

	onPan(event) {
		if (this.state.panning) {
			const { onPan, minMaxValues } = this.props;
			const { xLoc, yLoc } = getLocs(event);
			const diffLocs = this._getDiffLocs(xLoc, yLoc, true);
			const xScale = getAttributeScale(this.props, 'x');
			const yScale = getAttributeScale(this.props, 'y');
			const diffCoordX = xScale.invert(Math.abs(diffLocs.x)) - xScale.invert(0);
			const diffCoordY = yScale.invert(0) - yScale.invert(Math.abs(diffLocs.y));
			const currentYDomain = this.props.yDomain;
			const currentXDomain = this.props.xDomain;

			const left = diffLocs.x < 0 ? currentXDomain[0] - (-diffCoordX) : currentXDomain[0] - diffCoordX;
			const right = diffLocs.x < 0 ? currentXDomain[1] - (-diffCoordX) : currentXDomain[1] - diffCoordX;
			const top = diffLocs.y < 0 ? currentYDomain[1] - diffCoordY : currentYDomain[1] + diffCoordY;
			const bottom = diffLocs.y < 0 ? currentYDomain[0] - diffCoordY : currentYDomain[0] + diffCoordY;
			const canPanX = right < minMaxValues.maxX && minMaxValues.minX < left;
			const canPanY = top < minMaxValues.maxY && minMaxValues.minY < bottom;

			const area = {
				left: canPanX ? left : currentXDomain[0],
				right: canPanX ? right : currentXDomain[1],
				top: canPanY ? top : currentYDomain[1],
				bottom: canPanY ? bottom : currentYDomain[0]
			}
			if (onPan &&
				(area.left != currentXDomain[0]
				|| area.right != currentXDomain[1]
				|| area.top != currentYDomain[1]
				|| area.bottom != currentYDomain[0])
			) {
				onPan(area);
			}
		}
	}

	stopPanning(event) {
		if (!this.mounted) return;
		switch (event.type) {
			case "touchend":
			case "touchcancel":
			case "contextmenu":
				event.preventDefault();
				this.props.onPan(null);
				break;
		}
		if (this.state.panning) {
			this.setState({ panning: false });
			if (this.props.onPanEnd) {
				this.props.onPanEnd(event);
			}
		}
	}

	stopPropagation(event) {
		if (this.state.panning) {
			event.stopPropagation();
			event.preventDefault();
		}
	}

	componentDidMount() {
		this.mounted = true;
		this.parentNode = this.refBox.current.parentNode;
		this.parentNode.addEventListener("mousedown", this.startPanning.bind(this));
		this.parentNode.addEventListener("mousemove", this.onPan.bind(this));
		this.parentNode.addEventListener("mouseup", this.stopPanning.bind(this));
		this.parentNode.addEventListener("mouseleave", this.stopPanning.bind(this));
		// stopPropagation when is panning
		this.parentNode.addEventListener("mouseover", this.stopPropagation.bind(this));
		this.parentNode.addEventListener("mouseout", this.stopPropagation.bind(this));
		// preventDefault() so that mouse event emulation does not happen
		this.parentNode.addEventListener("touchend", this.stopPanning.bind(this));
		this.parentNode.addEventListener("touchcancel", this.stopPanning.bind(this));
		this.parentNode.addEventListener("contextmenu", this.stopPanning.bind(this));
	}

	componentWillUnmount() {
		this.mounted = false;
		this.parentNode.removeEventListener("mousedown", this.startPanning.bind(this));
		this.parentNode.removeEventListener("mousemove", this.onPan.bind(this));
		this.parentNode.removeEventListener("mouseup", this.stopPanning.bind(this));
		this.parentNode.removeEventListener("mouseleave", this.stopPanning.bind(this));
		// stopPropagation when is panning
		this.parentNode.removeEventListener("mouseover", this.stopPropagation.bind(this));
		this.parentNode.removeEventListener("mouseout", this.stopPropagation.bind(this));
		// preventDefault() so that mouse event emulation does not happen
		this.parentNode.removeEventListener("touchend", this.stopPanning.bind(this));
		this.parentNode.removeEventListener("touchcancel", this.stopPanning.bind(this));
		this.parentNode.removeEventListener("contextmenu", this.stopPanning.bind(this));
	}

	render() {
		const {
			highlightHeight,
			highlightWidth,
			highlightX,
			highlightY,
			innerWidth,
			innerHeight,
			marginLeft,
			marginRight,
			marginTop,
			marginBottom
		} = this.props;

		let leftPos = 0;
		if (highlightX) {
			const xScale = getAttributeScale(this.props, 'x');
			leftPos = xScale(highlightX);
		}

		let topPos = 0;
		if (highlightY) {
			const yScale = getAttributeScale(this.props, 'y');
			topPos = yScale(highlightY);
		}

		const plotWidth = marginLeft + marginRight + innerWidth;
		const plotHeight = marginTop + marginBottom + innerHeight;
		const touchWidth = highlightWidth || plotWidth;
		const touchHeight = highlightHeight || plotHeight;

		return (
			<g
				ref={this.refBox}
				transform={`translate(${leftPos}, ${topPos})`}
				style={{ cursor: this.state.panning ? 'grabbing' : 'grab' }}
				className={className('panning-container', fromProps(this.props))}
			>
				<rect
					className="rv-mouse-target"
					fill="black"
					opacity="0"
					x="0"
					y="0"
					width={Math.max(touchWidth, 0)}
					height={Math.max(touchHeight, 0)}
				/>
			</g>
		);
	}
}

Panning.displayName = 'PanningOverlay';
Panning.defaultProps = {
	color: 'rgb(77, 182, 172)',
	className: '',
	enableX: true,
	enableY: true,
	opacity: 0.3
};

export default Panning;