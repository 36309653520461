import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { actions as registryActions } from '../../../redux/api/registry';
import Loader from '../../general/Loader';
import { useI18n } from '../../../../i18n';
import { sort } from '../../../lib/sorting';
import Cascader from '../../general/form/Cascader';

/**
 * @param {Object} props
 * @param {string} props.value
 * @param {function} props.onChange
 * @param {boolean} props.disabled
 * @param {string} props.placeholder
 * @param {string} props.label
 */

function TimeZonePicker(props) {
	const { f } = useI18n()

	useEffect(() => {
		if (props.timeZones.list == null) {
			props.dispatch(registryActions.timeZones.request());
		}
	}, []);

	let timeZones = null;
	if (props.timeZones && props.timeZones.list) {
		let dataMap = {};

		const collect = (map, names, index) => {
			if (!names[index]) return;
			if (map[names[index]]) {
				collect(map[names[index]].children, names, index + 1);
			} else {
				const label = names[index].split('_').join(' ');
				map[names[index]] = { value: names[index], label, children: {} };
			}

		}
		sort(props.timeZones.list, timeZone => f(timeZone.zoneId).toLowerCase())
			.forEach(timeZone => collect(dataMap, timeZone.zoneId.split('/'), 0))
		;
		timeZones = Object.values(dataMap);

		const handleItem = (item, prefix) => {
			item.children = Object.values(item.children);
			if (prefix) {
				item.value = prefix + item.value;
			}
			if (item.children.length == 0) {
				delete item.children;
			} else {
				item.children.forEach(_item => handleItem(_item, item.value + '/'));
			}
		}

		timeZones.forEach(timeZone => handleItem(timeZone));
	}

	const content = timeZones
		? <Cascader
			data={timeZones}
			placeholder={props.placeholder}
			onChange={props.onChange}
			disabled={props.disabled}
			value={props.value}
		/>
		: <div className="loader"><Loader size={Loader.Size.MD} /></div>
	;

	return (
		<div className="timeZone-picker">
			{props.label && <label>{props.label}</label>}
			{content}
		</div>
	);
}

export default connect(state => {
	return {
		timeZones: state.registry.timeZones
	}
})(TimeZonePicker);