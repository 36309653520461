import { actions } from '../actions';
import { reduxSwitch } from '../../../tools';

const defaultState = {
	preview: {
		valid: false,
		list: null,
		pending: false,
		error: null
	},
	list: null,
	uri: null,
	parameters: null,
	hasMore: null,
	pending: false,
	error: null,
	at: null,
	// export
	exporting: false,
	progress: null, // { percent, done, total }
	csv: null,
};

const updateAnchor = (parameters, report) => {
	if (report) {
		if (report.anchorWindow) report.anchorWindow(parameters.timeRange.window);
		parameters.context = report.context;
	}
	return parameters;
}

function apiReducer(state, action) {
	let _state;
	switch (action.type) {
		case actions.tripHistory.request.type:
			if (action.clear) {
				_state = { ...defaultState };
			} else {
				_state = {...state};
			}
			return {
				..._state,
				uri: action.uri,
				parameters: action.parameters,
				pending: true,
				error: null,
				at: action.clear ? new Date() : _state.at
			};
		case actions.tripHistory.success.type:
			const entries = action.report && action.report.entries;
			const batchSize = state.parameters.timeRange.window.size;
			const hasMore = entries ? entries.length == batchSize : false;
			const list = state.list ? (entries ? state.list.concat(entries) : state.list) : (entries ? [...entries] : []);
			_state = {...state};
			return {
				..._state,
				list,
				parameters: updateAnchor(state.parameters, action.report),
				hasMore,
				pending: false
			};
		case actions.tripHistory.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		case actions.tripHistory.cancel.type:
			return {
				...state,
				pending: false
			};
		default:
			return state;
	}
}

function previewReducer(state, action) {
	switch (action.type) {
		case actions.tripHistory.preview.request.type:
			return {
				...state,
				preview: {
					...state.preview,
					valid: true,
					pending: true,
					error: null
				},
				uri: action.uri,
				parameters: action.parameters
			};
		case actions.tripHistory.preview.success.type:
			return {
				...state,
				preview: {
					...state.preview,
					list: action.preview.entries || [],
					pending: false
				}
			};
		case actions.tripHistory.preview.fail.type:
			return {
				...state,
				preview: {
					...state.preview,
					pending: false,
					error: action.errorMessage
				}
			};
		case actions.tripHistory.preview.cancel.type:
			return {
				...state,
				preview: {
					...state.preview,
					pending: false
				}
			};
		default:
			return state;
	}
}

function invalidateReducer(state, action) {
	switch (action.type) {
		case actions.tripHistory.preview.invalidate.type:
			return {
				...state,
				preview: {
					...state.preview,
					valid: false
				}
			};
		default:
			return state;
	}
}

function exportReducer(state, action) {
	switch (action.type) {
		case actions.tripHistory.export.type:
			return {
				...state,
				exporting: true,
				progress: null,
				csv: null
			}
		case actions.tripHistory.exportProgress.type:
			return {
				...state,
				progress: {
					percent: action.progress,
					done: action.count,
					total: action.total
				}
			}
		case actions.tripHistory.exportDone.type:
			return {
				...state,
				parameters: state.parameters,
				exporting: false,
				csv: action.csv
			}
		case actions.tripHistory.exportClear.type:
			return {
				...state,
				csv: null
			}
		default:
			return state;
	}

}

const reducer = reduxSwitch([apiReducer, previewReducer, invalidateReducer, exportReducer], defaultState);

export { reducer };
