import styled from 'styled-components';

import { Colors } from 'ui/constants/colors';

export const DetectorsPageStyled = styled.div`
  position: relative;
  height: calc(100vh - 70px);
  min-width: 280px;

  //TODO: should be moved to styled-components (blocked by commute detectors)
  .object-device-manager {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 280px;
    border-right: 1px solid ${Colors.PurpleCrystal};

    & > *:last-child {
      flex-grow: 1;
      overflow-y: auto;
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 15px;
    }

    .list-item,
    .no-items {
      overflow-wrap: break-word;
      padding: 15px;
    }
  }

  @media (max-width: 890px) {
    .object-device-manager {
      width: 100%;
      border: none;
    }
  }
`;
