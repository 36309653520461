import React, { useEffect } from 'react';
import { useI18n } from '../../../../i18n';
import { connect } from 'react-redux';
import { actions } from '../../../redux/api/categories';
import { fromProps, className } from '../../../lib/className';

/**
 * @param {Object} props
 * @param {cx.ods.devices.DeviceDetails} props.device
 */

function DevicePresetCategoriesView(props) {
	const { f } = useI18n();
	const { device } = props;

	useEffect(() => {
		if (props.categories.map == null && !props.categories.pending) {
			props.dispatch(actions.load.request());
		}
	}, []);

	let content = null;
	if (device.categoryIds != null) {
		if (props.categories.map != null) {
			const items = [];
			device.categoryIds.forEach(categoryId => {
				const category = props.categories.map[categoryId];
				if (category.preset !== undefined && category.preset) {
					items.push(<div key={categoryId}>{category.name}</div>);
				}
			});
			content = items;
		}
		content = (<span className="empty capitalize">{f('none')}</span>);
	} else {
		content = (<span className="empty capitalize">{f('none')}</span>);
	}

	return (
		<div className={className('device-preset-categories-view', fromProps(props))}>
			<div className="label">{f('preset categories')}</div>
			{content}
		</div>
	);
}

export default connect(
	state => ({
		categories: state.categories.general
	})
)(DevicePresetCategoriesView);