import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReportHeader from './ReportHeader';
import ReportSummary from './ReportSummary';
import ReportType from '../ReportType';
import { actions } from '../../../../redux/api/reports'
import Loader from '../../../general/Loader';
import NoDataView from '../../../general/view/NoDataView';
import { useI18n } from '../../../../../i18n';
import SwitchChartMenuItem from './SwitchChartMenuItem';
import { className } from '../../../../lib/className';
import FuelLevelBalanceChart from './FuelLevelBalanceChart/FuelLevelBalanceChart';
import TripHistoryList from './TripHistoryList';

function EodListItem() {
	const { f } = useI18n();
	return <div className="indicator"><span className="capitalize">{f('you have reached the end')}</span></div>;
}

// --------------------------------------------------

function TripHistory(props) {
	const { f } = useI18n();
	const [displayChart, setDisplayChart] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState();

	const loadNext = () => {
		const uri = props.state.uri;
		const parameters = props.state.parameters;
		props.dispatch(actions.tripHistory.request({ uri, parameters }));
	}

	const onItemClick = (entry, index) => {
		if (index && !displayChart) setDisplayChart(true);
		setSelectedIndex(index);
	}

	let content = null;
	let chartBox = null;
	let canExport = false;
	if (props.state.list != null) {
		if (!props.state.list || props.state.list.length == 0) {
			content = <NoDataView message={f('no data for such criteria')} />;
		} else {
			canExport = true;
			content = (
				<TripHistoryList
					list={props.state.list}
					uri={props.state.uri}
					pending={props.state.pending}
					canClickItem={Boolean(props.state.parameters.fuelLevel)}
					next={loadNext}
					selectedIndex={selectedIndex}
					onClick={onItemClick}
					hasMore={props.state.hasMore}
					hasFuelLevel={Boolean(props.state.parameters.fuelLevel)}
					eodListItem={EodListItem}
				/>
			);
			if (displayChart) {
				chartBox = (
					<FuelLevelBalanceChart
						selectedIndex={selectedIndex}
						data={props.state.list}
						uri={props.state.uri}
						onDataPointClick={setSelectedIndex}
					/>
				);
			}
		}
	} else {
		content = (
			<div className="center">
				{
					props.state.error
						? <span className="error">{props.state.error}</span>
						: <Loader size={Loader.Size.MD} />
				}
			</div>
		);
	}

	const exportReport = () => {
		props.dispatch(actions.tripHistory.export({ uri: props.state.uri }));
		props.history.goBack();
	}

	const switchChartHandler = () => {
		setDisplayChart(!displayChart);
	}

	const timeRange = props.state.parameters.timeRange;
	return (
		<div className={"report trip-history"}>
			<ReportHeader
				title={f(ReportType.name(ReportType.TripHistory) +  ' report')}
				canExport={canExport}
				onExport={exportReport}
				items={<SwitchChartMenuItem disabled={!props.state.parameters.fuelLevel || !canExport} active={displayChart} onClick={switchChartHandler} />}
			/>
			<ReportSummary
				since={timeRange.since}
				until={timeRange.until}
				devices={[props.devices[props.state.uri]]}
				generatedAt={props.state.at}
			/>
			<div className={className('content', { 'with-chart': displayChart })}>
				{chartBox}
				{content}
			</div>
		</div>
	);
}

export default withRouter(connect(state => {
	return ({
		state: state.reports.tripHistory,
		devices: state.devices.map
	})
})(TripHistory));
