import React, { useState, useEffect } from 'react';
import { useI18n } from '../../../../../i18n';
import Form from '../../../general/form/Form';

/**
 * @param {Object} props
 * @param {cx.ods.devices.RequestParameter} props.parameter
 * @param {React.Component} [props.controlType]
 * @param {boolean} [props.isText]
 * @param {string} [props.units]
 * @param {any} [props.value]
 * @param {function} [props.onChange]
 * @param {any} [props.initialValue]
 * @param {any} [props.defaultValue]
 */

function ParameterControl(props) {
	const { parameter, controlType, units, isText, initialValue, defaultValue, ..._props } = props;
	const { f, fc, pc } = useI18n();
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		if (!props.onChange && initialValue) setValue(initialValue);
	}, [initialValue]);

	const controlValidator = (value) => {
		if (!parameter.optional && (value == null || isText && !value.trim())) return fc('please enter a value');
		if (units && !isText && value != null) {
			if (parameter.maximum) {
				const maximum = +parameter.maximum;
				if (Number.isNaN(+value)) return fc('please, enter a correct value');
				if (maximum < +value) return pc('please enter a value not greater then', maximum);
			}
			if (parameter.minimum) {
				const minimum = +parameter.minimum;
				if (Number.isNaN(+value)) return fc('please, enter a correct value');
				if (+value < minimum) return pc('please enter a value not less than', minimum);
			}
		}
		if (isText && value != null) {
			if (parameter.maximum) {
				if (parameter.maximum < value.length) return pc('please enter a value not longer than X symbols', parameter.maximum);
			}
			if (parameter.minimum) {
				if (value.length < parameter.minimum) return pc('please enter a value not shorter than X symbols', parameter.minimum);
			}
		}
	}

	return (
		<Form.Control
			controlType={controlType || (isText ? Form.Control.Type.Input : Form.Control.Type.InputNumber)}
			min={parameter.minimum == null ? null :  +parameter.minimum}
			max={parameter.maximum == null ? null : +parameter.maximum}
			controlName={parameter.name}
			label={units ? (f({ prefix: 'request-parameter', id: parameter.description }) + ', ' + f({ prefix: 'units', id: units })) : f({ prefix: 'request-parameter', id: parameter.description })}
			controlValidator={controlValidator}
			value={props.value ? props.value : value}
			onChange={props.onChange ? props.onChange : setValue}
			{ ..._props }
		/>
	);
}

export default ParameterControl;