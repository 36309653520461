import styled, { css } from 'styled-components';

import { SvgWrapper } from 'components/atoms/svg-wrapper';
import { Colors } from 'ui/constants/colors';

interface IFormHeaderStyledProps {
  isResolved: boolean;
}

interface ISelectOptionsStyledProps {
  isDisabled: boolean;
}

interface ISelectIconStyledProps {
  $isOpen: boolean;
}

export const FormStyled = styled.form`
  position: relative;
  width: 100%;
  font: 12px Muli;
  box-sizing: border-box;
`;

export const FormHeaderStyled = styled.div<IFormHeaderStyledProps>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 30px;
  width: 117px;
  background-color: ${({ isResolved }) => (isResolved ? Colors.AquaBelt : Colors.Akabeni)};
  outline: 0;
  font: bold 12px Muli;
  padding: 0 20px;
  justify-content: center;
  border-radius: 20px;
  color: ${Colors.White};
  user-select: none;
`;

export const FormContentStyled = styled.div`
  box-sizing: border-box;
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 222px;
  padding: 0;
  margin-top: 5px;
  background: ${Colors.White};
  font: 12px Muli;
  border-radius: 5px;
  border: 1px solid ${Colors.SuperSilver};
  box-shadow: 0 3px 6px ${Colors.BlackRussian};
`;

export const SelectOptionsStyled = styled.div<ISelectOptionsStyledProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const SelectOptionStyled = styled.label<ISelectOptionsStyledProps>`
  font: 12px Muli;
  color: ${Colors.DoveGray};
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: ${Colors.White};
  cursor: pointer;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
    `}

  &:hover {
    background-color: ${Colors.DistantHorizon};
  }
`;

export const RemarksFieldStyled = styled.input`
  flex: 1;
  font: 12px Muli;
  color: ${Colors.DoveGray};
  border: 0;
  outline: 0;
  padding: 8px 0 4.5px;
  margin: 0 20px 0 15px;
  border-bottom: 1px solid ${Colors.DreamscapeGrey};
  transition: border-bottom 250ms linear;

  &:disabled {
    background-color: ${Colors.White};
  }

  &::placeholder {
    color: ${Colors.DreamscapeGrey};
  }

  &:focus {
    border-bottom: 1px solid ${Colors.DoveGray};
  }
`;

export const FormButtonsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-top: 1px solid ${Colors.SilverLight};
  padding: 0 10px;
  margin-top: 10px;
`;

export const FormButtonStyled = styled.button`
  font: bold 12px Muli;
  height: 24px;
  border-radius: 12px;
  color: ${Colors.White};

  &:first-child {
    padding: 0 15px;
    margin-right: 10px;
    background-color: ${Colors.SilverPolish};
  }

  &:last-child {
    flex: 1;
    background-color: ${Colors.AquaBelt};
  }

  &:disabled {
    opacity: 0.8;
  }
`;

export const SelectIconStyled = styled(SvgWrapper)<ISelectIconStyledProps>`
  margin-left: 6px;
  transform: ${({ $isOpen }) => `rotate(${$isOpen ? 180 : 0}deg)`};
`;
