import { IReduxState } from 'types/redux';

export const eventTypesListSelector = (state: IReduxState) => state.registry.eventTypes.typeMap;

export const messageFieldsSelector = (state: IReduxState) => state.registry.messageFields.typeMap;

export const attributeTypesSelector = (state: IReduxState) => state.registry.attributeTypes.codeMap;

export const runwaysSelector = (state: IReduxState) => {
  return state.veta.runways;
};

export const taxiwaysSelector = (state: IReduxState) => {
  return state.veta.taxiways;
};

export const ActualIssuesSelector = (state: IReduxState) => {
  return state.veta.issues.actual;
};

export const widgetsSelector = (state: IReduxState) => {
  return state.widgets;
};

export const resolutionTypesSelector = (state: IReduxState) => {
  return state.veta.resolutionTypes;
};

export const digestSelector = (state: IReduxState) => {
  return state.veta.runwayGuardDigest;
};

export const taxiwayDigestSelector = (state: IReduxState) => {
  return state.veta.taxiwayGuardDigest;
};

export const devicesSelector = (state: IReduxState) => {
  return state.devices;
};
