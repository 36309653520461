import { ofType } from "redux-observable";
import { switchMap, catchError, map as rxmap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';
import { rx, api } from "../../api";
import { ActionGeneratorBuilder } from "../actions";

/*
	{uid => {
		{uri => {
			summary: cx.ods.devices.DeviceSummary,
			empty: boolean,
			pending: boolean,
			error: string
		}}
	}}
*/
const defaultState = {};
const defaultSubstate = {
	summary: null,
	empty: false,
	pending: false,
	error: null
};

const actions = new ActionGeneratorBuilder('deviceSummary')
	.subtype(
		'filter',
		filter => filter
			.request({ uid: true, uri: true, filter: true })
			.success({ uid: true, uri: true, summary: true })
			.fail({ uid: true, uri: true, errorMessage: true })
			.cancel()
	)
	.type('clear', { uid: true, uri: true })
	.build()
;

const reducer = (state = defaultState, action) => {
	let copy = null;
	let instance = null;
	switch (action.type) {
		case actions.filter.request.type:
			copy = { ...state };
			if (copy[action.uid] == null) {
				copy[action.uid] = { [action.uri]: { ...defaultSubstate } };
			}
			if (copy[action.uid][action.uri] == null) {
				copy[action.uid][action.uri] = { ...defaultSubstate };
			}
			instance = copy[action.uid][action.uri];
			instance.pending = true;
			instance.error = null;
			break;
		case actions.filter.success.type:
			copy = { ...state };
			instance = copy[action.uid][action.uri];
			instance.summary = action.summary;
			instance.empty = Object.keys(action.summary).length == 0;
			instance.pending = false;
			break;
		case actions.filter.fail.type:
			copy = { ...state };
			instance = copy[action.uid][action.uri];
			instance.pending = false;
			instance.error = action.errorMessage;
			break;
		case actions.clear.type:
			copy = { ...state };
			if (copy[action.uid] && copy[action.uid][action.uri]) {
				delete copy[action.uid][action.uri];
				if (Object.keys(copy[action.uid]).length == 0) {
					delete copy[action.uid];
				}
			}
			return copy;
		default:
			return state;
	}
	return copy ? copy : state;
}

const epic = (action$) => {
	return action$.pipe(
		ofType(actions.filter.request.type),
		switchMap(action =>
			rx(api.devices.deviceSummary, action.uri, action.filter).pipe(
				rxmap(operation => actions.filter.success({ uid: action.uid, uri: action.uri, summary: operation.response() })),
				catchError(error => of(actions.filter.fail({ uid: action.uid, uri: action.uri, errorMessage: error.userMessage || error.message }))),
				takeUntil(action$.pipe(ofType(actions.filter.cancel.type, actions.clear.type)))
			)
		)
	)
}

export { actions, reducer, epic };
