import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import { useI18n } from 'i18n';
import {
  RangeFieldErrorStyled,
  RangeFieldsSeparatorStyled,
  RangeFieldsStyled,
  RangeFieldsWrapperStyled,
  RangeMnemonicStyled,
} from 'ui/pages/attribute-range-detectors/range-fields.styled';
import { useHysteresisFields } from 'hooks/attribute-range-detectors/useHysteresisFields';
import InputNumber from 'core/react/general/form/InputNumber';

interface IHysteresisFieldsProps {
  isDisabled: boolean;
  mnemonics: string;
}

export const HysteresisFields: FC<IHysteresisFieldsProps> = ({ isDisabled, mnemonics }) => {
  const { fc, f } = useI18n();

  const { control, leftBound, rightBound, units, lagError, validateLag } = useHysteresisFields(mnemonics);

  return (
    <RangeFieldsWrapperStyled>
      <label>{fc('hysteresis')}</label>
      <RangeFieldsStyled>
        <Controller
          control={control}
          name="leftLag"
          rules={{ validate: validateLag }}
          render={({ value, onChange }) => (
            <InputNumber disabled={!leftBound || isDisabled} value={value} onChange={onChange} />
          )}
        />
        <RangeFieldsSeparatorStyled />
        <Controller
          control={control}
          name="rightLag"
          rules={{ validate: validateLag }}
          render={({ value, onChange }) => (
            <InputNumber disabled={!rightBound || isDisabled} value={value} onChange={onChange} />
          )}
        />
        {units && <RangeMnemonicStyled>{f({ prefix: 'units', id: units })}</RangeMnemonicStyled>}
      </RangeFieldsStyled>
      {!!lagError && <RangeFieldErrorStyled>{lagError}</RangeFieldErrorStyled>}
    </RangeFieldsWrapperStyled>
  );
};
