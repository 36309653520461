import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import TraceViewMap from '../../map/TraceViewMap';
import DeviceStateMapMarker from '../../map/DeviceStateMapMarker';
import MapButton from '../../map/MapButton';
import { useI18n } from '../../../../../i18n';
import { className, fromProps } from '../../../../lib/className';
import { getOwMap } from '../../../general/location/Map';
import PanelLayout from '../../../general/layout/PanelLayout';
import { FiMaximize2, FiMinimize2, FiMapPin } from 'react-icons/fi';
import { DeviceDetailsProxy } from '../../../../api/device'; // eslint-disable-line
import './deviceMapView.scss';

const MAP_NAME = "DEVICE_MAP_VIEW";
/**
 * @param {Object} props
 * @param {DeviceDetailsProxy} props.device
 */

function DeviceMapView(props) {
	const { f, fc } = useI18n();
	const [fullscreen, setFullscreen] = useState(false);

	const toggle = () => {
		setFullscreen(!fullscreen);
	}

	useEffect(() => {
		getOwMap(MAP_NAME).getOlMap().updateSize();
	}, [fullscreen]);

	return (
		<PanelLayout
			className={className('device-map-view', fromProps(props), { fullscreen })}
			icon={<FiMapPin />}
			title={f('latest position')}
		>
			<TraceViewMap
				keepInView
				mapName={MAP_NAME}
				markers={[
					<DeviceStateMapMarker
						key={1}
						device={props.device}
						alwaysCenter
					/>
				]}
				controls={
					<MapButton onClick={toggle} title={fullscreen ? fc('collapse map') : fc('expand map fullscreen')}>
						{fullscreen ? <FiMinimize2 /> : <FiMaximize2 />}
					</MapButton>
				}
			/>
		</PanelLayout>
	)
}

export default connect(state => ({
	zones: state.zones
}))(DeviceMapView);