import React from 'react';
import { useI18n } from '../../../../i18n';
import { className, fromProps } from '../../../lib/className';
import ActionBar from '../../general/actionbar/ActionBar'
import { FiGitBranch } from 'react-icons/fi';

/**
 * @param {Object} props
 * @param {function} props.onClick
 * @param {string} [props.title]
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.active]
 */

function ActionTrace(props) {
	const { f } = useI18n();
	return (
		<ActionBar.Action
			className={className('trace', fromProps(props))}
			onClick={props.onClick}
			disabled={props.disabled}
			active={props.active}
			title={props.title || f('trace')}
		>
			<FiGitBranch size="16" />
		</ActionBar.Action>
	);
}

export default ActionTrace;