import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useI18n } from '../../../../../i18n';
import { fromProps, className } from '../../../../lib/className';
import PanelLayout from '../../../general/layout/PanelLayout';
import NoDataView from '../../../general/view/NoDataView';
import { FiSend, FiLink, FiMoreHorizontal } from 'react-icons/fi';
import { actions as deviceCommandsActions } from '../../../../redux/api/deviceCommands';
import DeviceCommandTile from '../../../share/devices/deviceCommands/DeviceCommandTile';
import ObjectActionDialogPopup from '../../../general/ObjectActionDialogPopup';
import DeviceCommand from '../../../share/devices/deviceCommands/DeviceCommand';
import { DeviceDetailsProxy } from '../../../../api/device'; // eslint-disable-line
import './deviceRequestsView.scss';

/**
 * @param {Object} props
 * @param {DeviceDetailsProxy} props.device
 */

const MAX_QUANTITY = 4; // maximum quantity of displayed commands

function DeviceRequestsView(props) {
	const { f } = useI18n();
	const [intent, setIntent] = useState(null);
	const disabled = props.commands && props.commands.pending;
	const deviceCommandsPageLink = '/device/' + props.device.uri + '/commands';
	const commandTiles = [];
	const hasCommands = props.commands && props.commands.types && props.commands.types.length > 0;
	let content = null;

	const closePopup = () => {
		setIntent(null);
	}

	useEffect(() => {
		if (!props.commands || !props.commands.types) {
			props.loadCommands(props.device.uri);
		}
	}, []);

	const onItemSelect = (command, data) => {
		setIntent({ subject: 'command', command, data });
	}

	if (hasCommands) {
		const compare = (a, b) => {
			const aName = f({ prefix: 'command', id: a.description });
			const bName = f({ prefix: 'command', id: b.description });
			if (aName.toLowerCase() > bName.toLowerCase()) return 1;
			if (aName.toLowerCase() < bName.toLowerCase()) return -1;
			return 0;
		};
		const filteredCommands = props.commands.types.sort(compare);
		for (let i = 0; i < filteredCommands.length && i < MAX_QUANTITY; ++i) {
			const command = filteredCommands[i];
			commandTiles.push(
				<DeviceCommandTile
					key={command.commandType}
					onClick={onItemSelect}
					uri={props.device.uri}
					command={command}
				/>
			);
		}
		if (props.commands.types.length > MAX_QUANTITY) {
			commandTiles.push(
				<div className="device-command-tile more" key="more-commands">
					<Link to={deviceCommandsPageLink}>
						<FiMoreHorizontal />
					</Link>
				</div>
			);
		}
		content = (
			<PanelLayout
				className={className(fromProps(props), 'device-requests-view')}
				icon={<FiSend />}
				title={<Link to={deviceCommandsPageLink}>{f('commands')}<FiLink /></Link>}
			>
				{commandTiles.length > 0
					? <div className="command-tiles">{commandTiles}</div>
					: <NoDataView />
				}
				{intent && intent.subject == 'command' && (
					<ObjectActionDialogPopup
						offset={intent.data.offset}
						width={intent.data.width}
						onClose={closePopup}
						disabled={disabled}
						title={f({ prefix: 'command', id: intent.command.description })}
					>
						<DeviceCommand hideTitle command={intent.command} onSubmit={closePopup} uri={props.device.uri} />
					</ObjectActionDialogPopup>
				)}
			</PanelLayout>
		);
	}

	return content;
}

export default connect(
	(state, props) => ({
		commands: props.device && state.deviceCommands[props.device.uri]
	}),
	dispatch => ({
		loadCommands: (uri) => {
			dispatch(deviceCommandsActions.types.request({ uri }));
			dispatch(deviceCommandsActions.load.request({ uri }));
		}
	})
)(DeviceRequestsView);
