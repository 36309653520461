import { ofType } from "redux-observable"
import { map as rxmap, filter } from "rxjs/operators";
import { actions as accountActions } from "../api/account";
import { actions as sessionActions } from "../api/session";
import { localStorage, sessionStorage } from "../../app/storage";

const epic = (action$) => {
	return action$.pipe(
		ofType(accountActions.retrieve.success.type, sessionActions.login.success.type),
		rxmap(action => {
			localStorage.setPrefix(action.loginName || action.details.loginName);
			sessionStorage.setPrefix(action.loginName || action.details.loginName);
		}),
		filter(() => false)
	)
}

export { epic };
