import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { INotification } from 'types/notifications';
import { AllRoutes } from 'constants/routes';
import { PortalIds } from 'constants/portal';
import { IssueSeverity } from 'constants/issues';
import { useFormattedIssues } from './useFormattedIssues';

const EMPTY_NOTIFICATIONS = { errors: [], info: [] };

export const useNotifications = () => {
  const location = useLocation();

  const formattedIssues: INotification[] = useFormattedIssues();

  const [isSeverePlaying, setSeverePlaying] = useState<boolean>(false);

  const isNotificationsShown =
    location.pathname !== '/' && location.pathname !== AllRoutes.Login && !location.pathname.includes(AllRoutes.Issues);

  const { errors, info } = useMemo(() => {
    return formattedIssues.reduce(
      ({ errors, info }: Record<string, INotification[]>, notification: INotification) =>
        notification.severity === IssueSeverity.Severe
          ? { errors: [...errors, notification], info }
          : { errors, info: [...info, notification] },
      EMPTY_NOTIFICATIONS,
    );
  }, [formattedIssues]);

  const playSevereSound = () => {
    if (!errors.length && isSeverePlaying) {
      return setSeverePlaying(false);
    }

    if (errors.length && !isSeverePlaying) {
      return setSeverePlaying(true);
    }
  };

  useEffect(() => {
    playSevereSound();
  }, [errors]);

  const topListProps = { portalId: PortalIds.TopNotifications, notifications: errors };
  const bottomListProps = {
    isSeverePlaying: !!errors.length,
    portalId: PortalIds.BottomNotifications,
    notifications: info,
  };

  return { isNotificationsShown, topListProps, bottomListProps, isSeverePlaying };
};
