import { cx, api, rx } from "../../../../api";
import { ofType, combineEpics } from 'redux-observable';
import { switchMap, takeUntil, map as rxmap, filter } from 'rxjs/operators';
import { formatDateTime, getTimezoneString } from '../../../../misc/misc';
import { actions } from '../actions';
import { f, fc } from "../../../../../i18n";
import { errorMap } from "../../../actions";

const requestEpic = (action$) => {
	return action$.pipe(
		ofType(actions.deviceUsages.request.type),
		switchMap(action =>
			rx(api.reports.deviceUsages, action.parameters).pipe(
				rxmap(operation => actions.deviceUsages.success({ usages: operation.response() })),
				errorMap(actions.deviceUsages.fail),
				takeUntil(action$.pipe(ofType(actions.deviceUsages.cancel.type)))
			)
		)
	)
}

const startPrepareEpic = (action$) => {
	return action$.pipe(
		ofType(actions.deviceUsages.export.type),
		rxmap(action => actions.deviceUsages.exportProgress({ progress: 100 }))
	)
}

const exportCsvEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.deviceUsages.exportProgress.type),
		filter(action => action.progress == 100),
		rxmap(action => {
			const state = state$.value.reports.deviceUsages;
			// report header
			var csv = '"'
				+ fc('report type')
				+ '",'
				+ fc('generated')
				+ ','
				+ fc('timezone')
				+ ','
				+ fc('since')
				+ ','
				+ fc('until')
				+ '\n';
			csv += fc('device usages');  // report type
			csv += ',' + formatDateTime(cx.now()); // generated at
			csv += ',' + getTimezoneString(); // timezone at
			csv += ',"' + formatDateTime(state.parameters.timeRange.since) + '"';
			csv += ',"' + formatDateTime(state.parameters.timeRange.until) + '"';
			csv += "\n\n";
			// content header
			csv += f('URI') + ',"'
				+ fc('device name')
				+ '",'
				+ fc('days')
				+ ','
				+ fc('trips')
				+ ',"'
				+ fc('distance') + ', ' + f('units.km')
				+ '"\n';
			// content
			state.list.forEach(usage => {
				const device = state$.value.devices.map[usage.uri];
				csv += '"' + device.uri + '"';
				csv += ',"' + (device.denomination() || '-') + '"';
				csv += ',' + usage.days;
				csv += ',' + usage.trips;
				csv += ',' + usage.distance;
				csv += "\n";
			});
			return actions.deviceUsages.exportDone({ csv });
		}),
	)
}

const epic = combineEpics(requestEpic, startPrepareEpic, exportCsvEpic);

export { epic };
