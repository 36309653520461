import React, { Dispatch, FC } from 'react';

import { TabsStyled, TabStyled, TabUnderlineStyled } from 'ui/molecules/tabs.styled';
import { fc } from 'i18n';

export interface ITab {
  id: string;
  name: string;
}

interface ITabsProps {
  tabs: ITab[];
  currentTabId: string;
  setCurrentTabId: Dispatch<string>;
}

export const Tabs: FC<ITabsProps> = ({ tabs, currentTabId, setCurrentTabId }) => {
  const setTabId = (id: string) => () => setCurrentTabId(id);

  return (
    <TabsStyled>
      {tabs.map((tab) => (
        <TabStyled key={tab.id} isActive={tab.id === currentTabId} onClick={setTabId(tab.id)}>
          {fc(tab.name)}
          {tab.id === currentTabId && <TabUnderlineStyled />}
        </TabStyled>
      ))}
    </TabsStyled>
  );
};
