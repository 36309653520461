import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { List } from 'rsuite';
import Loader from '../../../general/Loader';
import CategoryListItem from './CategoryListItem';
import { fetchChildren as fetchGroupingCategories } from '../../../../redux/app/categories/grouping';
import { GroupingIdContext } from '../DeviceSideMenu';
import { className, fromProps } from '../../../../lib/className';
import { sortByName } from '../../../../lib/sorting';
import './categoryList.scss';

/**
 *
 * @param {Object} props
 * @param {Component} [props.itemType]
 * @param {number} [props.groupingId]
 */

function CategoryList(props) {
	const groupingId = useContext(GroupingIdContext);
	const groupingIdRoot = groupingId || props.groupingId;
	const categories = props.groupings.root && groupingIdRoot && props.groupings.map[groupingIdRoot];
	if (categories && categories.length != 0) sortByName(categories);

	const ListItem = props.itemType || CategoryListItem;
	useEffect(() => {
		if (props.groupings.root != null && groupingIdRoot != null && categories == null) {
			fetchGroupingCategories(groupingIdRoot);
		}
	}, [props.groupings.map, groupingIdRoot]);

	let content = null;
	if (props.groupings.root != null && categories != null) {
		let items = categories && categories.length != 0
			? categories.map((category, at) => {
				return (<ListItem
					key={category.categoryId}
					category={category} at={at+1}
				/>);
			})
			: []
		;
		content = (<List bordered={false} className={className('category-list', fromProps(props))}>{items}</List>);
		items.push((<ListItem
			key={"uncategorized"}
			exceptCategories={categories}
			category={null}
		/>));
	} else {
		content = (
			<div
				style={{
					display: "flex",
					width: "100%",
					height: "100%",
					alignItems: "center",
					justifyContent: "center"
				}}
			>
				<Loader size={Loader.Size.LG} />
			</div>
		);
	}

	return (content);
}

export default connect(
	state => ({
		categories: state.categories.general,
		groupings: state.categories.groupings
	})
)(CategoryList);