import { ofType, combineEpics } from 'redux-observable';
import { switchMap, takeUntil, map, filter, withLatestFrom, tap, ignoreElements } from 'rxjs/operators';
import { api, rx } from "../../../../api";
import { formatDateTime, getTimezoneString, formatUTCTicksDuration } from '../../../../misc/misc';
import { resolver } from './resolver';
import { actions } from '../actions';
import { f, fc } from '../../../../../i18n';
import { errorMap } from '../../../actions';
import { designateEmployee } from '../../assets/employees/reducer';

const requestEpic = (action$) => {
	return action$.pipe(
		ofType(actions.commute.request.type),
		switchMap(action =>
			rx(api.assets.commute.tripHistory, action.parameters).pipe(
				map(operation => actions.commute.success({ trips: operation.response() })),
				errorMap(actions.commute.fail),
				takeUntil(action$.pipe(ofType(actions.commute.cancel.type)))
			)
		)
	)
}

const startPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.commute.export.type),
		withLatestFrom(state$.pipe(map(state => state.reports.commute))),
		map(([action, state]) => {
			resolver.resolve(state.list, !state.hasMore);
			if (state.hasMore) return actions.commute.request({ uri: action.uri, parameters: state.parameters });
			return actions.commute.success();
		})
	)
}

const processPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.commute.success.type),
		withLatestFrom(state$.pipe(map(state => state.reports.commute))),
		filter(([action, state]) => {
			if (state.exporting) resolver.resolve(action.trips && action.trips, !state.hasMore);
			return state.exporting && state.hasMore;
		}),
		map(([action, state]) => actions.commute.request({ uri: state.uri, parameters: state.parameters }))
	)
}

const exportCsvEpic = (action$, state$) => { // TODO
	return action$.pipe(
		ofType(actions.commute.exportProgress.type),
		filter(action => action.progress == 100),
		withLatestFrom(state$.pipe(map(state => ({ 
			state: state.reports.commute
			, deviceMap: state.devices.map
			, employeeMap: state.assets.employees.map 
		})))),
		map(([action, { state, deviceMap, employeeMap }]) => {
			// report header
			let csv = '"'
				+ fc('report type')
				+ '",'
				+ fc('generated')
				+ ','
				+ fc('timezone')
				+ ','
				+ fc('employees')
				+ ','
				+ fc('devices')
				+ ','
				+ fc('since')
				+ ','
				+ fc('until')
				+ '\n';
			csv += fc('commute');  // report type
			csv += ',' + formatDateTime(state.at); // generated at
			csv += ',' + getTimezoneString(); // timezone at
			let employeeNames;
			if (state.parameters?.assetIds?.length > 0) {
				const employee = employeeMap[state.parameters.assetIds[0]];
				employeeNames = employee ? designateEmployee(employee) : '#' + state.parameters.assetIds[0];
				if (state.parameters.assetIds.length > 1) employeeNames += ' ' + f('and X more', { value: state.parameters.assetIds.length - 1 }) ;
			} else employeeNames = fc('all');
			csv += ',"' + employeeNames + '"';
			let deviceNames;
			if (state.parameters?.uris?.length > 0) {
				const device = deviceMap[state.parameters.uris[0]];
				deviceNames = device ? device.denomination() : state.parameters.uris[0];
				if (state.parameters.uris.length > 1) deviceNames += ' ' + f('and X more', { value: state.parameters.uris.length - 1 }) ;
			} else deviceNames = fc('all');
			csv += ',"' + deviceNames + '"';
			csv += ',"' + formatDateTime(state.parameters.timeRange.since) + '"';
			csv += ',"' + formatDateTime(state.parameters.timeRange.until) + '"';
			csv += "\n\n";
			// content header
			csv += '"","","","'
				+ fc('start')
				+ '","","","'
				+ fc('end')
				+ '"\n';
			csv += '"'
				+ fc('employee')
				+ '","'
				+ fc('RFID')
				+ '","'
				+ fc('device')
				+ '","'
				+ fc('start time')
				+ '","'
				+ fc('start location')
				+ '","'
				+ fc('start address')
				+ '","'
				+ fc('end time')
				+ '","'
				+ fc('end location')
				+ '","'
				+ fc('end address')
				+ '","'
				+ fc('duration')
				+ '","'
				+ fc('distance') + ', ' + f('units.km')
				+ '"\n';
			// content
			state.list.forEach(assetTrip => {
				const employee = employeeMap[assetTrip.assetId];
				const designation = employee ? designateEmployee(employee) : '#' + assetTrip.assetId;
				csv += '"' + designation + '"';

				csv += ',"' + assetTrip.rfid + '"';

				const device = deviceMap[assetTrip.uri];
				csv += ',"' + (device ? device.denomination() : assetTrip.uri) + '"';

				csv += ',"' + formatDateTime(assetTrip.startedAt) + '"';
				const startLocation = assetTrip.start;
				if (startLocation != null && startLocation.latitude != null && startLocation.longitude != null) {
					csv += ',"(' + startLocation.latitude + ";" + startLocation.longitude + ')"';
					// start address
					const entryKey = resolver.key(startLocation.latitude, startLocation.longitude);
					const address = resolver.resolved[entryKey]?.getAddress();
					if (address) csv += ',"' + address.format() + '"';
					else csv += ",";
				} else {
					csv += ",,"
				}

				csv += ',"' + formatDateTime(assetTrip.endedAt) + '"';
				const endLocation = assetTrip.end;
				if (endLocation != null && endLocation.latitude != null && endLocation.longitude != null) {
					csv += ',"(' + endLocation.latitude + ";" + endLocation.longitude + ')"';
					// end address
					const entryKey = resolver.key(endLocation.latitude, endLocation.longitude);
					const address = resolver.resolved[entryKey]?.getAddress();
					if (address) csv += ',"' + address.format() + '"';
					else csv += ",";
				} else {
					csv += ",,"
				}

				csv += ',"' + (assetTrip.endedAt ? formatUTCTicksDuration(assetTrip.endedAt.getTime() - assetTrip.startedAt.getTime()) : '') + '"';
				csv += ',"' + (assetTrip.distance != null ? assetTrip.distance / 1000 : '') + '"';
				csv += "\n";
			});
			return actions.commute.exportDone({ csv });
		}),
	)
}

const exportClearEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.commute.exportClear.type),
		tap(() => resolver.clear()),
		ignoreElements()
	);
}

const epic = combineEpics(requestEpic, startPrepareEpic, processPrepareEpic, exportCsvEpic, exportClearEpic);

export { epic };
