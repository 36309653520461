import React from 'react';
import { withRouter, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import Loader from '../general/Loader';

function InitialPage(props) {
	let from = props.location ? (props.location.state ? props.location.state.from : null) : null;

	let content = null;
	if (props.session.pending) {
		content = (<Loader size={Loader.Size.LG} className="vh-center" />);
	} else if (props.session.authorized) {
		content = (<Redirect to={from ? from.pathname : "/dashboard"} />);
	} else {
		const to = { pathname: "/login" };
		if (!props.session.closed) to.state = { from };
		content = (<Redirect to={to} />);
	}

	return (
		<div className="app-page initial">
			{content}
		</div>
	)
}

export default withRouter(connect(state => ({ session: state.session }))(InitialPage));
