import { ActionGeneratorBuilder } from "../../actions";

const buildActions = (builder) => {
	return builder
		.subtype('load', load => load.request('parameters').success({ parameters: true, histories: true }).fail().cancel().type('finish'))
		.type('clear')
		.build()
	;
}

export const actions = new ActionGeneratorBuilder('history')
	.subtype('events', buildActions)
	.subtype('messages', buildActions)
	.subtype('assetPresences', buildActions)
	.build()
;
