import React from 'react';
import MapControls from '../../map/controls/MapControls';
import MapOptions from '../../map/controls/MapOptions';
import MapOptionZones from '../../map/controls/MapOptionZones';
import MapOptionWidgets from '../../map/controls/MapOptionWidgets';
// import MapSearch from '../../map/controls/MapSearch';
import MapWidgetsControl from '../../widgets/MapWidgetsControl';
import DashboardWidgetSelector from '../widgets/DashboardWidgetSelector';
import TimeMachineMapButton from '../../timeMachine/map/TimeMachineMapButton';
import MapOptionDisplayMarkerLabels from '../../map/controls/MapOptionDisplayMarkerLabels';
import { GeoJsonLayerSwitcher } from './GeoJsonLayerSwitcher';
import { DefaultViewMapOption } from 'components/molecules/map/default-view-map-option';

/**
 * @param {Object} props
 * @param {function} props.focusExtent
 * @param {boolean} props.displayZones
 * @param {function} props.setDisplayZones
 * @param {function} props.saveMapView
 * @param {Array.<React.Component>} [props.dropdownOptions]
 * @param {Array.<React.Component>} [props.toggleOptions]
 * @param {Array} [props.selectedGeoJsonLayers]
 * @param {function} [props.onGeoJsonLayerSelect]
 * @param {Array>} [props.geoJsonLayersOptions]
 */

function DashboardMapControls(props) {
	const toggleDisplayZones = () => {
		props.setDisplayZones(!props.displayZones);
	};

	return (
		<MapControls>
			<MapOptions>
				<GeoJsonLayerSwitcher
					value={props.selectedGeoJsonLayers}
					onChange={props.onGeoJsonLayerSelect}
					options={props.geoJsonLayersOptions}
				/>
				{props.dropdownOptions}
				<MapOptionZones value={props.displayZones} onChange={toggleDisplayZones} />
				<MapOptionDisplayMarkerLabels mapName={props.mapName} />
				<MapOptionWidgets />
				{props.toggleOptions}
				<DefaultViewMapOption saveMapView={props.saveMapView}/>
			</MapOptions>
			{/*<MapSearch onReady={props.focusExtent} />*/}
			<MapWidgetsControl>
				<DashboardWidgetSelector />
			</MapWidgetsControl>
			<TimeMachineMapButton />
		</MapControls>
	);
}

export default DashboardMapControls;
