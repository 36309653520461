// import React from 'react';
// import Form from '../Form';

/**
 * @param {Object} props
 * @param {Array.<any>} props.value
 * @param {function} props.onChange
 */

function DummyControl(props) {
	return (null);
}

export default DummyControl;