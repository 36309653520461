import { switchMap, map, filter, ignoreElements, tap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

import { LogLevel, rootLogger } from 'core/lib/log';

import { rs } from 'core/rs';

import { ActionGeneratorBuilder, deltaReducer, errorMap } from "core/redux/actions";
import {actions as sessionActions} from '../session';
import { ResolutionKind } from 'constants/issues';

const logger = rootLogger.logger('veta-resolutionTypes').at(LogLevel.Verbose);

export const actions = new ActionGeneratorBuilder('veta-resolutionTypes')
	.subtype('load', load => load.request().success('resolutionTypes').fail())
	.build()
;

const defaultState = {
	map: null
	, issueTypeIndex: null
	, pending: false
	, error: null
};

const issueTypeIndex = resolutionTypes => {
	const index = resolutionTypes.reduce((index, resolutionType) => {
		const types = index[resolutionType.issueTypeId];
		if (types != null) types.push(resolutionType);
		else index[resolutionType.issueTypeId] = [resolutionType];
		return index;
	}, {});
	const weights = {
		[ResolutionKind.FalsePositive]: 3
		, [ResolutionKind.ConfirmedViolation]: 2
		, [ResolutionKind.AuthorizedSituation]: 1
	};
	Object.values(index).forEach(types => {
		types.sort((left, right) => {
			const leftWeight = weights[left.resolutionKind] || 0, rightWieght = weights[right.resolutionKind] || 0;
			return -(leftWeight - rightWieght);
		});
	});
	return index;
};

export const reducer = deltaReducer((state, action) => {
	switch (action.type) {
		case actions.load.request.type: return {
			pending: true
			, error: null
		};
		case actions.load.success.type: return {
			pending: false
			, map: Object.fromEntries(action.resolutionTypes.map(resolutionType => [resolutionType.id, resolutionType]))
			, issueTypeIndex: issueTypeIndex(action.resolutionTypes)
		};
		case actions.load.fail.type: return {
			pending: false
			, error: action.errorMessage
		};
	}
	return null;
}, defaultState);

export const epic = combineEpics(
	action$ => action$.pipe(
		ofType(actions.load.request.type)
		, switchMap(action => rs('veta/issues/resulutions/types').defer$().pipe(
			map(resolutionTypes => actions.load.success({resolutionTypes}))
			, errorMap(actions.load.fail)
		))
	)
	, (action$, state$) => action$.pipe(
		ofType(actions.load.success.type)
		, filter(_ => logger.loggable(LogLevel.Debug))
		, withLatestFrom(state$.pipe(map(state => state.veta.resolutionTypes)))
		, tap(([_, state]) => {
			logger.debug('resolution types', state.issueTypeIndex);
		})
		, ignoreElements()
	)
	, action$ => action$.pipe(
		ofType(sessionActions.events.started.type)
		, map(() => actions.load.request())
	)
);
