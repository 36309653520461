import React, { useEffect, useRef, useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useI18n } from '../../../../i18n';
import { className } from '../../../lib/className';
import './floatingLabelInput.scss';

/**
 * @param {Object} props
 * @param {string} [props.value]
 * @param {function} [props.onChange]
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.autoFocus]
 * @param {string || React.Component} [props.description]
 * @param {string || React.Component} [props.error]
 * @param {string} [props.label]
 * @param {string} [props.name]
 * @param {string} [props.type]
 */


function FloatingLabelInput(props) {
	const { fc } = useI18n();
	const inputRef = useRef();
	const [value, setValue] = useState(props.value || '');
	const [showPassword, setShowPassword] = useState(false);
	const [focused, setFocused] = useState(Boolean(props.value));

	const _value = props.value == undefined ? value : props.value;
	const _setValue = e => props.onChange ? props.onChange(e.target.value) : setValue(e.target.value);

	useEffect(() => {
		if (props.autoFocus && inputRef.current) inputRef.current.focus();
	}, [_value, props.error]);

	return (
		<div className={className("floating-label-input", { 'error': Boolean(props.error), disabled: props.disabled })}>
			<div className="wrap">
				<input
					ref={inputRef}
					autoCapitalize="off"
					autoComplete="off"
					autoCorrect="off"
					spellCheck="false"
					onFocus={() => setFocused(true)}
					onBlur={e => setFocused(Boolean(e.target.value))}
					onChange={_setValue}
					value={_value}
					id={'floating-label-input-' + props.label}
					label={props.label}
					name={props.name}
					disabled={props.disabled}
					type={(props.type === "password" && showPassword ? 'text' : props.type) || 'text'}
					className={className("input", { focused })}
				/>
				<label htmlFor={'floating-label-input-' + props.label} className={className("placeholder", { 'label' : focused })}>
					{props.label}
				</label>
				{props.type == 'password'
					&& (_value)
					&& <div
						className="show-password"
						onClick={() => setShowPassword(!showPassword)}
						title={showPassword ? fc('hide password') : fc('show password')}
					>
						{showPassword ? <FiEyeOff /> : <FiEye />}
					</div>
				}
			</div>
			{props.description && <div className="description">{props.description}</div>}
			{props.error && <div className="error">{props.error}</div>}
		</div>
	);
}

export default FloatingLabelInput;
