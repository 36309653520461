import styled from 'styled-components';

import { Colors } from 'ui/constants/colors';

interface IEventCellStyledProps {
  isResolved: boolean;
}

export const PageStyled = styled.div`
  padding: 0 32px;
  display: flex;
  flex-direction: column;
`;

export const HeaderStyled = styled.div`
  display: flex;
  align-items: flex-end;
  height: 76px;
  padding-bottom: 14.5px;
  border-bottom: 1px solid ${Colors.SilverLight};
  margin-bottom: 34px;
  font: bold 18px/25px Muli;
  color: ${Colors.Black};
`;

export const CardStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 17px;
  background-color: ${Colors.White};
  box-shadow: 0 0 2px ${Colors.BlackRussian};

  & > div {
    &:first-child {
      margin-bottom: 20px;
    }
  }
`;

export const EventCellStyled = styled.span<IEventCellStyledProps>`
  color: ${({ isResolved }) => (isResolved ? Colors.DoveGray : Colors.KhmerCurry)};
`;
