import React from 'react';
import { FiAlertCircle } from 'react-icons/fi';

/**
 * @param {Object} props
 * @param {string} props.message
 */

function WidgetErrorContent(props) {
	return (
		<div className="widget-error">
			<div className="icon">
				<FiAlertCircle />
			</div>
			<div className="message">
				{props.message}
			</div>
		</div>
	);
}

export default WidgetErrorContent;