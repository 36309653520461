import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useDrop } from 'react-dnd';

import { fc, useI18n } from '../../../../../i18n';

import { className } from '../../../../lib/className';

import { cx } from '../../../../api';

import {actions as reportsActions} from '../../../../redux/api/reports/actions';

import { DragItemType } from '../../../share/dnd/DragItemType';

import Button from '../../../general/form/Button';
import Form from '../../../general/form/Form';
import Loader from '../../../general/Loader';
import DatetimeField from '../../../general/datetime/DatetimeField';
import List from '../../../general/list/List';
import ListItem from '../../../general/list/ListItem';
import ObjectActionDialogPopup from '../../../general/ObjectActionDialogPopup';
import RemoveDialog from '../../../general/RemoveDialog';
import { withRouteMap } from '../../../general/route-map';

import ReportType from '../ReportType';

import {ScheduleTypeField, ScheduleTypeSelector} from './schedule-types';

import { CommuteScheduleControls, CommuteScheduleFields, CommuteScheduleListHeaders, CommuteScheduleListValues } from './commute';

import './scheduleManager.scss';


const ReportTypeContext = {
	[ReportType.Commute]: {
		selector: state => state.reports.commuteSchedules
		, actions: () => reportsActions[ReportType.Commute].schedules
		, className: () => 'commute-history'
		, controls: () => CommuteScheduleControls
		, fields: () => CommuteScheduleFields
		, listHeaders: () => CommuteScheduleListHeaders
		, listValues: () => CommuteScheduleListValues
	}
};

export const isSchedulableReportType = type => type in ReportTypeContext;


/**
 * @param {Object} props
 * @param {string} props.reportType
 */

export const ScheduleManager = withSchedules(withRouteMap({
	list: null
	, create: '/create'
	, view: '/:scheduleId'
	, edit: '/:scheduleId/edit'
})(props => {
	const {routeMap} = props;

	return <div className='schedule-manager'><Switch>
		<Route exact path={routeMap.list.path}><ScheduleList {...props}/></Route>
		<Route exact path={routeMap.create.path}><ScheduleForm {...props}/></Route>
		<Route exact path={routeMap.view.path}><ScheduleCard {...props}/></Route>
		<Route exact path={routeMap.edit.path}><ScheduleForm {...props}/></Route>
	</Switch></div>;
}));


function withSchedules(Wrapped) {
	return connect((state, props) => ({
		schedules: ReportTypeContext[props.reportType].selector(state)
	}))(props => {
		const {schedules} = props;
	
		useEffect(() => {
			if (!schedules.list) props.dispatch(ReportTypeContext[props.reportType].actions().load.request());
		}, [schedules.list]);

		if (!props.schedules.list) return <div className="vh-center"><Loader size={Loader.Size.LG}/></div>;

		return <Wrapped {...props}/>
	});
}


const ScheduleList = props => {
	const {f}  = useI18n();
	const typeContext = ReportTypeContext[props.reportType];
	const routerHistory = useHistory();
	const {schedules, routeMap} = props;
	const [removeRequest, setRemoveRequest] = useState(null);

	useEffect(() => {
		if (!schedules.pending && schedules.list.length == 0) routerHistory.replace(routeMap.create());
		else props.configureActions({
			onAdd: () => routerHistory.push(routeMap.create())
			, modification: 0 < schedules.list?.length
			, disabled: schedules.pending
		});
	}, [schedules.list, schedules.pending]);

	const onSelect = schedule => {
		routerHistory.push(routeMap.view({scheduleId: schedule.scheduleId}));
	};

	const onRemove = (schedule, itemRef) => setRemoveRequest({schedule, anchorRef: itemRef});

	return <div className={className('list', typeContext.className())}>
		<div className='header'><span className='title'>{f('report schedules')}</span></div>
		<ScheduleListHeader reportType={props.reportType}/>
		<List>{schedules.list.map(schedule => <ScheduleListItem
			key={schedule.scheduleId}
			routeMap={routeMap} 
			reportType={props.reportType} schedule={schedule} 
			onClick={onSelect} onRemove={onRemove}
		/>)}</List>
		{removeRequest && <ScheduleRemover
			reportType={props.reportType} schedules={schedules}
			{...removeRequest}
			onDone={() => setRemoveRequest(null)}
		/>}
	</div>;
};


const ScheduleListHeader = props => {
	const {f}  = useI18n();
	const typeContext = ReportTypeContext[props.reportType];

	const Headers = typeContext.listHeaders();
	return <ListItem className='list-header'>
		<div>{f('id')}</div>
		<div>{f('name')}</div>
		<div>{f('type')}</div>
		{Headers && <Headers/>}
		<div>{f('last executed')}</div>
	</ListItem>;
};


const ScheduleListItem = props => {
	const typeContext = ReportTypeContext[props.reportType];
	const routerHistory = useHistory();
	const {schedule, routeMap} = props;
	const itemRef = useRef(null);

	const onDrop = useCallback(item => {
		if (item.type == DragItemType.ACTION_EDIT) routerHistory.push(routeMap.edit({scheduleId: schedule.scheduleId}));
		else if (item.type == DragItemType.ACTION_REMOVE) props.onRemove(schedule, itemRef);
	}, []);

	const [dropState, dropRef] = useDrop({
		accept: [DragItemType.ACTION_EDIT, DragItemType.ACTION_REMOVE]
		, drop: onDrop
		, collect: monitor => ({
			isOver: monitor.isOver()
			, canDrop: monitor.canDrop()
		})
	});

	const setItemRef = value => {
		itemRef.current = value;
		dropRef(value);
	};

	const Values = typeContext.listValues();
	return <ListItem 
		className={className('clickable', {'droppable': dropState.isOver, 'accepts-drop': dropState.canDrop})}
		customRef={setItemRef} onClick={() => props.onClick(schedule)}
	>
		<div>{schedule.scheduleId}</div>
		<div>{schedule.name}</div>
		<div><ScheduleTypeField value={schedule.scheduleType}/></div>
		{Values && <Values schedule={schedule}/>}
		<div><LastExecutedReport schedule={schedule}/></div>
	</ListItem>;
};


const ScheduleForm = props => {
	const {f}  = useI18n();
	const typeContext = ReportTypeContext[props.reportType];
	const routerHistory = useHistory();
	const {schedules, routeMap} = props;
	const {params: {scheduleId}} = useRouteMatch();
	const schedule = schedules.map[scheduleId];
	const [pending, setPending] = useState(false);
	const submitRef = useRef(null);

	useEffect(() => {
		if (scheduleId && !schedule) routerHistory.replace(routeMap.list());
	}, [scheduleId, schedule]);

	useEffect(() => {
		const barActions = {};
		if (0 < schedules.list?.length) barActions.onBack = () => routerHistory.goBack();
		props.configureActions(barActions);
	}, [schedules.list]);

	useEffect(() => {
		if (pending && !schedules.pending) {
			setPending(false);
			if (!schedules.error) routerHistory.replace(routeMap.list());
		}
	}, [schedules.pending]);

	const onSubmit = schedule => {
		if (!scheduleId) props.dispatch(typeContext.actions().add.request({schedule}));
		else props.dispatch(typeContext.actions().update.request({scheduleId, schedule}));
		setPending(true);
	};

	const Controls = typeContext.controls();
	return <div className='form'>
		<div className='header'><span className='title'>{
			f(!schedule ? 'new report schedule' : 'report schedule')
		}</span></div>
		<Form submitHook={submitRef} onSubmit={onSubmit}
			objectType={cx.ods.reports.CommuteHistoryReportSchedule}
			disabled={schedules.pending}
		>
			<Form.Control
				controlName="name"
				label={f('name')}
				controlType={Form.Control.Type.Input}
				initialValue={schedule?.name}
			/>
			<Form.Control
				controlName='scheduleType'
				label={f('schedule type')}
				controlType={ScheduleTypeSelector}
				controlValidator={value => {
					if (value == null) return f('please select schedule type');
				}}
				initialValue={schedule?.scheduleType}
			/>
			{Controls && <Controls schedule={schedule} {...props}/>}
			{schedules.error && <div className="error">{schedules.error}</div>}
			<div className='footer'>
				<Button onClick={() => submitRef.current()}>{f('submit')}</Button>
			</div>
		</Form>
	</div>;
};


const ScheduleCard = props => {
	const typeContext = ReportTypeContext[props.reportType];
	const {f}  = useI18n();
	const routerHistory = useHistory();
	const {schedules, routeMap} = props;
	const {params: {scheduleId}} = useRouteMatch();
	const schedule = schedules.map[scheduleId];
	const anchorRef = useRef(null);
	const [removeRequest, setRemoveRequest] = useState(null);

	useEffect(() => {
		if (!schedule) routerHistory.replace(routeMap.list());
		else props.configureActions({onBack: () => routerHistory.goBack()});
	}, [schedule]);

	if (!schedule) return null;

	const onEdit = () => {
		routerHistory.push(routeMap.edit({scheduleId: schedule.scheduleId}));
	};

	const onRemove = () => setRemoveRequest({schedule, anchorRef});

	const Fields = typeContext.fields();
	return <div className='card'>
		<div className='header'><span className='title'>{f('report schedule')}</span></div>
		<div className='content'>
			<div ref={anchorRef}>
				<label>{f('name')}</label>
				<div>{schedule.name}</div>
			</div>
			<div>
				<label>{f('schedule type')}</label>
				<ScheduleTypeField value={schedule.scheduleType}/>
			</div>
			{Fields && <Fields schedule={schedule} {...props}/>}
			<div>
				<label>{f('last executed')}</label>
				<LastExecutedReport schedule={schedule}/>
			</div>
		</div>
		<div className='footer'>
			<Button onClick={onEdit} disabled={schedules.pending}>{f('edit')}</Button>
			<Button onClick={onRemove} disabled={schedules.pending}>{f('remove')}</Button>
		</div>
		{removeRequest && <ScheduleRemover
			reportType={props.reportType} schedules={schedules}
			{...removeRequest}
			onDone={() => setRemoveRequest(null)}
		/>}
	</div>;
};


const LastExecutedReport = props => {
	const {schedule} = props;

	if (!schedule.executedAt) return null;

	const timestamp = <DatetimeField datetime={schedule.executedAt}/>;
	if (!schedule.report) return timestamp;

	return <a href={schedule.report} title={fc('download report')} onClick={e => e.stopPropagation()}>{timestamp}</a>
};


const ScheduleRemover = ({reportType, schedules, schedule, anchorRef, onDone}) => {
	const {f}  = useI18n();
	const typeContext = ReportTypeContext[reportType];
	const dispatch = useDispatch();

	useEffect(() => {
		if (!(schedule.scheduleId in schedules.map)) onDone();
	}, [schedules.map, schedule.scheduleId]);

	if (!(schedule.scheduleId in schedules.map)) return null;

	const onConfirm = () => {
		dispatch(typeContext.actions().remove.request({scheduleId: schedule.scheduleId}));
	};

	return <ObjectActionDialogPopup
		title={f('delete schedule')}
		error={schedules.error}
		disabled={schedules.pending}
		anchorRef={anchorRef}
		onClose={onDone}
	>
		<RemoveDialog
			text={f('are you sure you want to delete report schedule', {name: designateSchedule(schedule)})}
			disabled={schedules.pending}
			onSubmit={onConfirm} onCancel={onDone} onEnterKeydownHandler={onDone}
		/>
	</ObjectActionDialogPopup>
};


const designateSchedule = schedule => {
	return schedule.name || `#${schedule.scheduleId}`;
};
