import { rx, api } from '../../../api';
import { ofType, combineEpics,  } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, map as rxmap, takeUntil, filter, mergeMap } from 'rxjs/operators';
import { actions as announcerActions } from '../announcer';
import { actions as sessionActions } from '../session';
import { actions } from './actions';
import Processor from './Processor';
import { errorMap } from '../../actions';

let processor = null;
function connect(store) {
	processor = new Processor(store);
}

// ---------------------------------------------------------

const findEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.find.request.type),
		switchMap(action => {
			processor.lock();
			return rx(api.devices.states.find, action.filter).pipe(
				mergeMap(operation => {
					processor.start();
					processor.unlock();
					// first generate success action, then - change action, for each changed state
					const response = operation.response();
					const oldStates = state$.value.deviceStates.map;
					const newStates = {};
					const emit = [];
					response.forEach(deviceState => {
						newStates[deviceState.uri] = deviceState.state;
						emit.push(actions.changed({ uri: deviceState.uri, state: deviceState.state }));
					});
					emit.push(actions.find.success({ map: { ...oldStates, ...newStates } }));
					return of.apply(this, emit);
				}),
				errorMap(actions.find.fail),
				takeUntil(action$.pipe(ofType(actions.find.cancel.type)))
			)
		})
	)
};

const watchAnnouncedEpic = (action$) => {
	return action$.pipe(
		ofType(announcerActions.announced.type),
		rxmap(action => processor.announce(action.announcements)),
		filter(() => false)
	)
};

const watchSessionStartEpic = (action$) => {
	return action$.pipe(
		ofType(sessionActions.events.resumed.type, sessionActions.events.started.type),
		rxmap(() => processor.init()),
		filter(() => false)
	)
}

const watchSessionCloseEpic = (action$) => {
	return action$.pipe(
		ofType(sessionActions.events.paused.type, sessionActions.events.closed.type),
		rxmap(() => processor.stop()),
		filter(() => false)
	)
}

const epic = combineEpics(findEpic, watchAnnouncedEpic, watchSessionStartEpic, watchSessionCloseEpic);

export { epic, connect };
