import React, { useLayoutEffect, useImperativeHandle, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import { useI18n } from '../../../../i18n';
import WidgetErrorContent from '../../general/widgets/WidgetErrorContent';
import * as widgets from '../../../redux/app/widgets';
import DeviceStatisticsView from '../../share/devices/DeviceStatisticsView';
import ActionLockedDevices from '../../general/widgets/ActionLockedDevices';
import WidgetMenuItem from '../../general/widgets/WidgetMenuItem';
import DeviceMessageCharts from '../../share/devices/DeviceMessageCharts';
import DeviceShortInfoView from '../../share/devices/DeviceShortInfoView';
import MessageFieldsTimeLocation from '../../share/message/MessageFieldsTimeLocation';
import LatestEventShortView from '../../share/events/LatestEventShortView';
import MessageFieldsView from '../../share/message/MessageFieldsView';
import NoDataView from '../../general/view/NoDataView';
import DeviceLastEventsStatistics from '../../share/devices/DeviceLastEventsStatistics';
import ActionLock from '../../share/actionbar/ActionLock';
import ActionMaximize from '../../share/actionbar/ActionMaximize';
import { localeSelector } from 'core/redux/selectors/locale';
import './deviceInfoWidget.scss';

/**
 * Widget showing current device info: location, latest message with all fields, latest event, etc.
 * @param {Object} props
 * @param {string} props.uid
 * @param {React.RefObject} props.customRef
 * @param {function} props.update to rerender WidgetContainer
 */

const PIN_ACTIONS = { maximize: 'maximize', lock: 'lockDevices' };

function DeviceInfoWidget(props) {
	const { f, fc } = useI18n();
	const locale = useSelector(localeSelector);
	const device = props.deviceMap && props.deviceMap[props.uri];

	const lockHandler = () => {
		props.dispatch(widgets.actions.update({
			domain: props.reduxKey,
			uid: props.uid,
			data: { uris: props.locked ? null : props.uris }
		}));
	}

	useEffect(() => {
		if (props.deviceMap?.[props.uri]) {
			props.resize()
		}
	}, [props.deviceMap]);

	useLayoutEffect(() => {
		props.update();
	}, [props.locked, props.uris?.length, props.message, props.widgetData]);

	const onMaximize = () => {
		props.dispatch(widgets.actions.maximize({ domain: props.reduxKey, uid: props.maximized ? null : props.uid }));
	}

	const setPinnedActions = (actionName, pinned) => {
		const pinnedActions = props.widgetData && props.widgetData.pinnedActions ? { ...props.widgetData.pinnedActions } : {};
		pinnedActions[actionName] = pinned;
		props.dispatch(widgets.actions.update({ domain: props.reduxKey, uid: props.uid, data: { pinnedActions } }));
	}

	useImperativeHandle(props.customRef, () => ({
		title: () => fc('device info'),
		actions: (onChange) => {
			const actions = [];
			if (props.locked) {
				actions.push(
					<ActionLockedDevices
						onChange={onChange}
						key="action-uris"
						uris={props.widgetData.uris}
						disabledUris={props.disabledUris}
						emptyMessage={fc('all devices')}
					/>
				);
			}
			if (props.widgetData && props.widgetData.pinnedActions) {
				if (props.widgetData.pinnedActions[PIN_ACTIONS.maximize]) {
					actions.push(
						<ActionMaximize
							key={PIN_ACTIONS.maximize}
							onClick={onMaximize}
							active={props.maximized}
						/>
					);
				}
				if (props.widgetData.pinnedActions[PIN_ACTIONS.lock]) {
					actions.push(
						<ActionLock
							key={PIN_ACTIONS.lock}
							disabled={props.uris.length == 0}
							onClick={lockHandler}
							active={props.locked}
							title={fc(props.locked ? 'unlock' : 'lock')}
						/>
					);
				}
			}
			return actions;
		},
		canPin: props.locked || props.uris.length > 0,
		handlePin: lockHandler,
		menuItems: () => {
			return [
				<WidgetMenuItem
					onClick={onMaximize}
					text={fc(props.maximized ? 'minimize' : 'maximize')}
					key={PIN_ACTIONS.maximize}
					canPin
					pinned={props.widgetData && props.widgetData.pinnedActions && props.widgetData.pinnedActions[PIN_ACTIONS.maximize]}
					onChangePin={(pinned) => setPinnedActions(PIN_ACTIONS.maximize, pinned)}
				/>,
				<WidgetMenuItem
					disabled={!props.locked && props.uris.length == 0}
					onClick={lockHandler}
					text={fc(props.locked ? 'unlock' : 'lock')}
					key={PIN_ACTIONS.lock}
					canPin
					pinned={props.widgetData && props.widgetData.pinnedActions && props.widgetData.pinnedActions[PIN_ACTIONS.lock]}
					onChangePin={(pinned) => setPinnedActions(PIN_ACTIONS.lock, pinned)}
				/>
			];
		},
		dynamicContent: () => {
			if (props.uris.length > 0) {
				return props.uris.length == 1
					? <MessageFieldsView full message={props.message} uri={props.uris[0]} resize={props.resize} />
					: <DeviceStatisticsView reduxKey={props.reduxKey} uris={props.uris} className="device-info-widget" />
				;
			}
		},
		expandable: props.uris.length > 0
	}));

	let content = null;
	if (props.uris.length > 0) {
		if (
			props.uris.length == 1 &&
			props.deviceMap && props.deviceMap[props.uri] &&
			props.message
		) {
			const isDeviceExpired = moment(device.expiresAt).isSameOrBefore(moment());
			const isDeviceExpiresSoon = moment(device.expiresAt).isSameOrBefore(moment().add(1, 'M'));
			const deviceExpirationDate = moment(device.expiresAt).locale(locale).format('DD/MM/YYYY');

			content = (<>
				<DeviceShortInfoView device={device} />
				{!!device.expiresAt && (isDeviceExpired || isDeviceExpiresSoon) && (
					<div className="expiration">
						<div>{fc('expires on')}</div>
						<div className={isDeviceExpired ? 'expired' : ''}>{deviceExpirationDate}</div>
					</div>
				)}
				<MessageFieldsTimeLocation message={props.message} />
				<LatestEventShortView reduxKey={props.reduxKey} now={props.now} uri={props.uri} />
			</>);
		} else if (props.uris.length > 1) {
			content = (
				<>
					<DeviceMessageCharts uris={props.uris} reduxKey={props.reduxKey} />
					<DeviceLastEventsStatistics uris={props.uris} reduxKey={props.reduxKey} />
				</>
			);
		} else {
			const message = props.uris.length > 1
				? f('no data for selected devices')
				: f('no data for', { name: props.deviceMap?.[props.uri]?.name || props.uri })
			;
			content = (<NoDataView message={message} />);
		}
	} else {
		content = (<WidgetErrorContent message={f('please select device')} />);
	}

	return (
		<div className="device-info widget">
			{content}
		</div>
	);
}

export default connect((state, props) => {
	const widget = state.widgets[props.reduxKey].map[props.uid];
	const selection = state.pages[props.reduxKey].selection;

	const widgetData = widget && widget.data;
	const locked = !!(widgetData && widgetData.uris);
	let uris = locked  ? widgetData.uris : (selection ? selection : []);
	let disabledUris = null;
	if (locked && props.reduxKey == 'timeMachine') {
		uris = selection.filter(uri => widgetData.uris.includes(uri));
		disabledUris = widgetData.uris.filter(uri => !selection.includes(uri));
	}

	const uri = uris[0];

	let now = null;
	let message = null;
	if (props.reduxKey == 'dashboard') {
		message = state.deviceStates.map && state.deviceStates.map[uri]
			? state.deviceStates.map[uri].message
			: null
		;
	} else if (props.reduxKey == 'timeMachine') {
		if (state.timeMachine.state.parameters) now = state.timeMachine.state.parameters.now;
		if (state.timeMachine.state.map && state.timeMachine.state.map[uri]) {
			message = state.timeMachine.state.map[uri].message
		}
	}
	return {
		selection, widget, message, uris, uri, now, locked, widgetData, disabledUris,
		deviceMap: state.devices.map
	};
})(DeviceInfoWidget);
