import { Address, ComponentType } from "./address";
import { BatchResolver, ResolverFialedError } from "./resolver";


const mosaicKeys = {
	'localhost': '20b5c1857832f5765d67154a83ae0cb23b7412c985c5eda7c89b75d9173bb729566bb236dd0c16fe'
	, 'sandbox-veta.changiairport.com': '2174dc546377d383d7fbb3681269a91fa07dc3eb3d6c382120168108628131672db230fd7a7e3ab6'
	, 'staging-veta.changiairport.com': '8995692489b08f60d7fbb3681269a91fa07dc3eb3d6c38212016810862813167aa869497626d55ea'
	, 'veta.changiairport.com': 'ffe55dc4ab73e005a07dc3eb3d6c38212016810862813167a1d3a1a5374639b358d2caccce7778f1'
};


const parseResponseText = text => {
	const json = /^cx\.jsr\("x",(?<json>.*)\)$/.exec(text)?.groups['json'];
	if (!json) throw new Error(`Can't extract json from ${text}`);
	return JSON.parse(json);
};

export class MosaicResolver extends BatchResolver {

	constructor(batchSize = 50) {
		super(batchSize);
		const hostname = new URL(window.location.href).hostname;
		this.key = mosaicKeys[hostname];
		if (this.key) this.serviceURL = new URL('https://mosaic.covexis.com/resolve');
		else this.submitBatch = batch => batch.forEach(job => job.reject(`domain ${hostname} is not supported`));
	}

	name() {
		return 'mosaic';
	}

	submitBatch(batch) {
		const pointParams = batch.map(({ entry: { longitude, latitude } }) => `POINT=${longitude},${latitude}`).join('&');
		fetch(new URL('?key=' + this.key + '&' + pointParams + '&jsonp=x', this.serviceURL))
			.then(response => response.text())
			.then(text => parseResponseText(text))
			.then(reply => {
				const locatedAddresses = batch.length <= 1 
					? [reply.addressResolve.addressLocation] 
					: reply.poolAddressResolve?.addressList
				;
				if (locatedAddresses?.length != batch.length) throw new Error(`Resolver reply is invalid ${JSON.stringify(reply)}`);
				batch.forEach(({resolve}, at) => {
					resolve(this.constructAddress(locatedAddresses[at]?.address));
				});
			})
			.catch(error => batch.forEach(({reject}) => reject(new ResolverFialedError(error.message))))
		;
	}

	static componentTypeMap = {
		'country': ComponentType.country
		, 'locality': ComponentType.locality
		, 'postalCode': ComponentType.postalCode
		, 'streetName': ComponentType.street, 'houseNumber': ComponentType.streetNumber
	};

	constructAddress(mosaicAddress) {
		if (!mosaicAddress) return null;
		const entries = Object.entries(mosaicAddress)
			.map(([type, value]) => {
				const componentType = MosaicResolver.componentTypeMap[type];
				return componentType ? [componentType, value] : null;
			})
			.filter(Boolean)
		;
		return 0 < entries.length ? new Address(null, Object.fromEntries(entries)) : null;
	}
};
