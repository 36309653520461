import React, { useImperativeHandle, useState, useRef } from 'react';
import { connect } from 'react-redux';
import Form from '../../../general/form/Form';
import { cx } from '../../../../api';
import { useI18n } from '../../../../../i18n';
import { actions } from '../../../../redux/api/reports';
import GroupCheckbox from '../../../general/form/GroupCheckbox';
import SmoothingControl from './tripHistoryMisc/SmoothingControl';
import LeapCriteriaControl from './tripHistoryMisc/LeapCriteriaControl';
import './tripHistory.scss';


const BATCH_SIZE = 50;

/**
 * @param {Object} props
 * @param {cx.ods.TimeRangeParameters} props.timeRange
 * @param {React.RefObject} props.customRef
 * @param {boolean} [props.disabled]
 */

function TripHistory(props) {
	const { f } = useI18n();
	const smoothingRef = useRef();
	const leapCriteriaRef = useRef();
	const parameters = props.state.parameters;
	const [hasFuelLevel, setHasFuelLevel] = useState(parameters && parameters.fuelLevel != null);

	useImperativeHandle(props.customRef, () => ({
		submit: (values) => {
			const parameters = new cx.ods.reports.TripHistoryReportParameters();
			if (hasFuelLevel) {
				parameters.fuelLevel = new cx.ods.reports.FuelLevelParameters();
				parameters.fuelLevel.smoothing = smoothingRef.current.submit(values);
				parameters.fuelLevel.leapCriteria = leapCriteriaRef.current.submit(values);
			}
			parameters.timeRange = props.timeRange;
			parameters.timeRange.window = new cx.ods.TimeWindow(BATCH_SIZE);
			props.dispatch(actions.tripHistory.request({ uri: values.uri, parameters, clear: true }));
		},
		preview: (values) => {
			const parameters = new cx.ods.reports.TripHistoryReportParameters();
			if (hasFuelLevel) {
				parameters.fuelLevel = new cx.ods.reports.FuelLevelParameters();
				parameters.fuelLevel.smoothing = smoothingRef.current.submit(values);
				parameters.fuelLevel.leapCriteria = leapCriteriaRef.current.submit(values);
			}
			parameters.timeRange = props.timeRange;
			parameters.timeRange.window = new cx.ods.TimeWindow(BATCH_SIZE);
			props.dispatch(actions.tripHistory.preview.request({ uri: values.uri, parameters }));
		},
		changed: values => {
			let changed = false;
			if (parameters) {
				changed = Boolean(values.hasFuelLevel) != Boolean(parameters.fuelLevel);
				if (!changed) changed = smoothingRef.current.changed(values);
				if (!changed) changed = leapCriteriaRef.current.changed(values);
			}
			return changed;
		},
		clear: () => {
			props.dispatch(actions.tripHistory.exportClear());
		}
	}));

	return (
		<Form.ControlGroup className="trip-history-parameters" key={'5000'} disabled={props.disabled}>
			<Form.Control
				controlName="hasFuelLevel"
				controlType={GroupCheckbox}
				value={hasFuelLevel}
				onChange={setHasFuelLevel}
				label={f('fuel level processing')}
				disabled={props.disabled}
			/>
			<SmoothingControl
				customRef={smoothingRef}
				smoothing={parameters && parameters.fuelLevel && parameters.fuelLevel.smoothing}
				disabled={props.disabled || !hasFuelLevel}
			/>
			<LeapCriteriaControl
				customRef={leapCriteriaRef}
				disabled={props.disabled || !hasFuelLevel}
				leapCriteria={parameters && parameters.fuelLevel && parameters.fuelLevel.leapCriteria}
			/>
		</Form.ControlGroup>
	);
}

export default connect(state => {
	return {
		state: state.reports.tripHistory
	}
})(TripHistory);
