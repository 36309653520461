import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../../general/Loader';
import { className } from '../../../../lib/className';
import { scrollReportItemIntoView } from '../../../../misc/misc';
import { useI18n } from '../../../../../i18n';
import DatetimeField from '../../../general/datetime/DatetimeField';
import EventField from '../../../share/events/EventField';
import Address from '../../../general/location/Address';
import ScrollList from '../../../general/list/ScrollList';
import ReportSummary from './ReportSummary';
import ReportHeader from './ReportHeader';
import { actions } from '../../../../redux/api/reports';
import { actions as zonesActions } from '../../../../redux/api/zones';
import CheckLocationMap from './CheckLocationMap';
import SwitchMapMenuItem from './SwitchMapMenuItem';
import NoDataView from '../../../general/view/NoDataView';
import './deviceSituations.scss';

function HeaderListItem() {
	const { f } = useI18n();
	return (
		<div className="section header-section">
			<div className="header-item"><span  className="capitalize">{f('time')}</span></div>
			<div className="header-item"><span className="capitalize">{f('event')}</span></div>
			<div className="header-item"><span className="capitalize">{f('location')}</span></div>
		</div>
	)
}

/**
 * @param {Object} props
 * @param {cx.ods.devices.EventDetails} props.event
 * @param {boolean} [props.selected]
 */

function ReportListItem(props) {

	const onClick = () => {
		props.onClick(props.event);
	}

	const _className = className('clickable', { 'selected': props.selected });
	return (
		<>
			<div ref={props.customRef} className={_className} onClick={onClick}><DatetimeField datetime={props.event.generatedAt} /></div>
			<div className={_className} onClick={onClick}>{props.event ? <EventField event={props.event} /> : "-"}</div>
			<div className={_className} onClick={onClick}><Address message={props.event.message} full /></div>
		</>
	);
}

/**
 * @param {Object} props
 * @param {Object} props.deviceMap
 * @param {cx.ods.reports.DeviceSituations} props.situations
 * @param {function} props.onClick
 * @param {number}  props.selectedId
 */

function ReportGroupItem(props) {
	const items = props.situations.events && props.situations.events.map(event => {
		const onClick = (event) => {
			props.onClick(event, props.situations.uri);
		}
		const isSelected = props.selectedId == event.eventId;
		return (
			<ReportListItem
				key={event.eventId}
				selected={isSelected}
				onClick={onClick}
				event={event}
				customRef={isSelected ? props.customRef : undefined}
			/>
		)
	});
	return (
		<>
			<div className="section-header">
				{props.deviceMap[props.situations.uri].name}
			</div>
			<div className="section">{items}</div>
		</>
	);
}

function EodListItem() {
	const { f } = useI18n();
	return <div className="indicator"><span>{f('you have reached the end')}</span></div>;
}

// -----------------------------------------------------------------

const GROUP_HEADER_HEIGHT = 46;

function DeviceSituations(props) {
	const { f } = useI18n();
	const selectedItemBox = useRef();
	const [displayMap, setDisplayMap] = useState(false);
	const [selection, setSelection] = useState(null); // { event, device }

	useLayoutEffect(() => {
		if (selectedItemBox.current) {
			scrollReportItemIntoView(selectedItemBox.current, GROUP_HEADER_HEIGHT);
		}
	}, [selection]);

	useEffect(() => {
		if (!props.zoneMap) {
			props.dispatch(zonesActions.load.request());
		}
	}, []);

	const loadNext = () => {
		const parameters = props.state.parameters;
		props.dispatch(actions.deviceSituations.request({ parameters }));
	}

	const onItemClick = (event, uri) => {
		setSelection({ event, device: props.deviceMap[uri] });
		if (!displayMap) setDisplayMap(true);
	}

	let content = null;
	let mapBox = null;
	let canExport = false;
	if (props.state.list != null) {
		if (!props.state.list || props.state.list.length == 0) {
			content = <NoDataView message={f('no data for such criteria')} />;
		} else {
			canExport = true;
			let items = props.state.list.map((situations, at) => {
				return (
					<ReportGroupItem
						onClick={onItemClick}
						key={at}
						selectedId={selection ? selection.event.eventId : null}
						deviceMap={props.deviceMap}
						situations={situations}
						customRef={selectedItemBox}
					/>
				);
			});
			items.unshift(<HeaderListItem key={1000000} />);
			content = (<ScrollList
				pending={props.state.pending}
				next={loadNext}
				isEod={!props.state.hasMore}
				eodItem={EodListItem}
			>
				{items}
			</ScrollList>);
			if (displayMap) {
				const onClick = () => {
					setSelection({ ...selection });
				}
				mapBox = (
					<CheckLocationMap
						locations={{ [selection.event.eventId]: selection.event.message }}
						title={selection.device.denomination()}
						device={selection.device}
						onClick={onClick}
					/>
				);
			}
		}
	} else {
		content = (
			<div className="center">
				{
					props.state.error
						? <span className="error">{props.state.error}</span>
						: <Loader size={Loader.Size.MD} />
				}
			</div>
		);
	}

	const onMapSwitch = () => {
		const situations = props.state.list[0];
		onItemClick(situations.events[0], situations.uri);
		setDisplayMap(!displayMap);
	}

	const exportReport = () => {
		props.dispatch(actions.deviceSituations.export());
		props.history.goBack();
	}

	const timeRange = props.state.parameters.timeRange;
	return (
		<div className="report device-situations">
			<ReportHeader
				title={f('device situations report')}
				canExport={canExport}
				onExport={exportReport}
				items={<SwitchMapMenuItem disabled={!canExport} active={displayMap} onClick={onMapSwitch} />}
			/>
			<ReportSummary since={timeRange.since} until={timeRange.until} generatedAt={props.state.at} />
			<div className={className("content", { 'with-map': displayMap })}>
				{content}
				{mapBox}
			</div>
		</div>
	)
}

export default withRouter(connect(state => {
	return ({
		state: state.reports.deviceSituations,
		deviceMap: state.devices.map,
		zoneMap: state.zones.map
	})
})(DeviceSituations));
