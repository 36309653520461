import React, { FC, useMemo } from 'react';
import { ReactPaginateProps } from 'react-paginate';

import { OnPageChangeType } from 'types/pagination';
import { PaginationStyled } from 'ui/molecules/pagination.styled';

export const DEFAULT_PAGINATION_PROPS = {
  breakLabel: '...',
  marginPagesDisplayed: 1,
  pageRangeDisplayed: 5,
};

interface IPaginationProps extends Partial<ReactPaginateProps> {
  totalCount: number;
  pageSize: number;
  onPageChange: OnPageChangeType;
  page: number;
}

export const Pagination: FC<IPaginationProps> = ({ onPageChange, pageSize, totalCount, page }) => {
  const pageCount = useMemo(() => Math.ceil(totalCount / pageSize), [totalCount, pageSize]);

  const onPageChangeHandler: OnPageChangeType = ({ selected }) => {
    !!onPageChange && onPageChange({ selected: selected + 1 });
  };

  return (
    <PaginationStyled
      pageCount={pageCount}
      onPageChange={onPageChangeHandler}
      forcePage={page - 1}
      {...DEFAULT_PAGINATION_PROPS}
    />
  );
};
