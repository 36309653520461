import React from 'react';
import { getViewableColor } from '../../../lib/color';
import Chart from "react-apexcharts";
import './pieChart.scss';

/**
 * @param {Object} props
 * @param {Object} props.data // { name: string, value: number, color: string }
 * @param {string} props.label
 * @param {function} [props.onMounted]
 */

function PieChart(props) {
	let content = null;

	if (props.data.length > 0) {
		const values = [];
		const names = [];
		const colors = [];

		props.data.forEach(element => {
			values.push(element.value);
			names.push(element.name);
			colors.push(element.color ? element.color : getViewableColor());
		});

		const state = {
			series: values,
			options: {
				chart: {
					events: {
						mounted: props.onMounted
					}
				},
				colors: colors,
				dataLabels: {
					enabled: false
				},
				labels: names,
				plotOptions: {
					pie: {
						offsetY: 30,
						expandOnClick: false,
						customScale: 1.7,
					}
				},
				fill: {
					opacity: 1
				},
				legend: {
					show: false
				}
			}
		}
		content = (
			<div className="custom-pie-chart">
				<Chart
					options={state.options}
					series={state.series}
					type="pie"
					width={75}
					height={75}
				/>
				{props.label && <label><span className="capitalize">{props.label}</span></label>}
			</div>
		);
	}

	return (content);
}

export default PieChart;
