import { getLayoutByType, validateLayout, WidgetType } from "../../react/general/widgets/widgetRegistry"
import { ofType, combineEpics } from 'redux-observable'
import { map, filter, withLatestFrom, tap, ignoreElements } from 'rxjs/operators';
import { actions as sessionActions } from "../api/session"
import { localStorage as storage } from "../../app/storage";
import { ActionGeneratorBuilder } from "../actions";

//	{
// 		domain: {
//			map: {
// 				uid: {
// 					widgetType: string,
// 					autoSize: boolean,
// 					expanded: boolean,
// 					layout: {},
// 					data?: {}
// 				}
// 			}
//			display: boolean,
//			maximized: uid
//		}
//	}
const defaultState = {};

const defaultSubstate = {
	map: {},
	display: false,
	maximized: null
};

// ---------------------------------------------------------

const actions = new ActionGeneratorBuilder('widgets')
	.type('add', { domain: true, widgetType: true })
	.type('updateLayout', { domain: true, layout: true })
	.type('updateItemLayout', { domain: true, uid: true, layout: true, keepSize: false })
	.type('maximize', { domain: true, uid: true })
	.type('update', { domain: true, uid: true, data: true })
	.type('setAutoSize', { domain: true, uid: true, autoSize: false })
	.type('setExpanded', { domain: true, uid: true, expanded: false })
	.type('remove', { domain: true, uid: true })
	.type('display', { domain: true, display: true })
	.type('restoreState', { state: true })
	.build()
;

// ---------------------------------------------------------

const processLayout = (state, layout) => {
	const result = {};
	layout.forEach(item => {
		result[item.i] = {
			...state.map[item.i],
			layout: item
		}
	});
	return result;
}

const reducer = (state = defaultState, action) => {
	const substate = action.domain ? (state[action.domain] || defaultSubstate) : null;
	switch (action.type) {
		case actions.add.type:
			const layout = getLayoutByType(action.widgetType);
			if (substate.map[layout.i]) return state;
			return {
				...state,
				[action.domain]: {
					...substate,
					map: {
						...substate.map,
						[layout.i]: {
							widgetType: action.widgetType,
							layout
						}
					}
				}
			}
		case actions.updateLayout.type:
			return {
				...state,
				[action.domain]: {
					...substate,
					map: processLayout(substate, action.layout)
				}
			}
		case actions.updateItemLayout.type:
			const _item = substate.map[action.uid];
			return {
				...state,
				[action.domain]: {
					...substate,
					map: {
						...substate.map,
						[action.uid]: {
							..._item,
							layout: action.layout
						}
					}
				}
			}
		case actions.maximize.type:
			return {
				...state,
				[action.domain]: {
					...substate,
					maximized: action.uid
				}
			}
		case actions.update.type:
			const item = substate.map[action.uid];
			const data = { ...item.data };
			Object.keys(action.data).forEach(key => {
				data[key] = action.data[key]
			});
			return {
				...state,
				[action.domain]: {
					...substate,
					map: {
						...substate.map,
						[action.uid]: {
							...item,
							data
						}
					}
				}
			}
		case actions.setAutoSize.type:
			return {
				...state,
				[action.domain]: {
					...substate,
					map: {
						...substate.map,
						[action.uid]: {
							...substate.map[action.uid],
							autoSize: action.autoSize
						}
					}
				}
			}
		case actions.setExpanded.type:
			return {
				...state,
				[action.domain]: {
					...substate,
					map: {
						...substate.map,
						[action.uid]: {
							...substate.map[action.uid],
							expanded: action.expanded
						}
					}
				}
			}
		case actions.remove.type:
			delete substate.map[action.uid];
			return {
				...state,
				[action.domain]: {
					...substate
				}
			}
		case actions.display.type:
			return {
				...state,
				[action.domain]: {
					...substate,
					display: action.display
				}
			}
		case actions.restoreState.type:
			return action.state;
		default:
			return state;
	}
}

// ---------------------------------------------------------

const STORAGE_KEY = 'widgets';

const restoreEpic = (action$) => {
	return action$.pipe(
		ofType(sessionActions.events.started.type),
		map(action => {
			const state = storage.get(STORAGE_KEY);
			if (state != null) {
				const widgetTypes = Object.values(WidgetType);
				Object.keys(state).forEach(domain => {
					const map = state[domain].map;
					if (map) {
						state[domain].map = {};
						Object.keys(map)
							.filter(key => {
								// temporary branch
								if (
									map[key].widgetType == WidgetType.TimeMachinePlayerWidget &&
									key != WidgetType.TimeMachinePlayerWidget
								) {
									state[domain].map[WidgetType.TimeMachinePlayerWidget] = map[key];
									state[domain].map[WidgetType.TimeMachinePlayerWidget].layout.i = WidgetType.TimeMachinePlayerWidget;
									return false;
								}
								//
								return widgetTypes.includes(map[key].widgetType)
							})
							.forEach(key => {
								validateLayout(map[key].layout, getLayoutByType(map[key].widgetType));
								state[domain].map[key] = map[key];
							})
						;
					}
				});
				storage.set(STORAGE_KEY, state);
				return actions.restoreState({ state });
			}
		}),
		filter(action => action != null)
	)
}

const updateEpic = (action$, state$) => {
	return action$.pipe(
		ofType(
			actions.add.type, actions.remove.type,
			actions.update.type, actions.updateLayout.type,
			actions.display.type, actions.updateItemLayout.type,
			actions.setAutoSize.type, actions.setExpanded.type,
			actions.maximize.type
		),
		withLatestFrom(state$.pipe(map(state => state.widgets))),
		tap(([action, widgets]) => storage.set(STORAGE_KEY, widgets)),
		ignoreElements()
	)
}

const epic = combineEpics(restoreEpic, updateEpic);

// ---------------------------------------------------------

export { actions, reducer, epic };
