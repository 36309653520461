import React from 'react';
import { Cascader as RsCascader } from 'rsuite';
import { capitalize } from '../../../misc/misc';
import { className, fromProps } from '../../../lib/className';
import './cascader.scss';

function Cascader(props) {
	const { label, placeholder, ..._props } = props;
	return (
		<div className={className('x-picker', 'cascader', fromProps(props))}>
			{props.label && <label>{props.label}</label>}
			<RsCascader
				block
				placeholder={capitalize(placeholder)}
				renderMenu={(children, menu) => <div className={className('x-picker', 'cascader menu', fromProps(props))}>{menu}</div>}
				{..._props}
			/>
		</div>
	);
}

export default Cascader;