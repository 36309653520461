import React from 'react';
import { className, fromProps } from '../../../../lib/className';
import ActionAdd from './DeviceActionAdd';
import ActionEdit from '../../../share/actionbar/ActionEdit';
import ActionPin from '../../../share/actionbar/ActionPin';
import ActionRemove from '../../../share/actionbar/ActionRemove';
import ActionBar from '../../../general/actionbar/ActionBar';
import MultipleSelectionSwitch from './MultipleSelectionSwitch';
import './deviceActionBar.scss';

/**
 * @param {Object} props
 * @param {number} props.groupingId
 * @param {boolean} [props.hide]
 */

function DeviceActionBar(props) {
	return (!props.hide &&
		<ActionBar className={className('device-action-bar', fromProps(props))}>
			<MultipleSelectionSwitch />
			<ActionAdd groupingId={props.groupingId} />
			<ActionEdit />
			<ActionPin />
			<ActionRemove />
		</ActionBar>
	);
}

export default DeviceActionBar;