import React, { useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import { cx } from '../../../../api';
import Form from '../../../general/form/Form';
import { actions } from '../../../../redux/api/reports';
import ObjectDeviceManager from '../../../share/devices/ObjectDeviceManager';
import EmployeeSelector from '../../../share/employee/EmployeeSelector';
import './commute.scss';

const BATCH_SIZE = 100;

/**
 * @param {Object} props
 * @param {cx.ods.TimeRangeParameters} props.timeRange
 * @param {React.RefObject} props.customRef
 * @param {boolean} [props.disabled]
 */

function Commute(props) {
	const { f } = useI18n();

	useImperativeHandle(props.customRef, () => ({
		submit: (values) => {
			const parameters = new cx.ods.assets.CommuteHistoryParameters();
			parameters.timeRange = new cx.ods.TimeRange();
			parameters.timeRange.since = props.timeRange.since;
			parameters.timeRange.until = props.timeRange.until;
			parameters.retrospective = values.retrospective;
			parameters.uris = values.uris;
			parameters.assetIds = values.assetIds;
			parameters.limit = BATCH_SIZE;
			props.dispatch(actions.commute.request({ uri: values.uri, parameters, clear: true }));
		},
		clear: () => {
			props.dispatch(actions.commute.exportClear());
		}
	}));

	return (
		<div className="commute-parameters">
			<Form.Control
				controlType={EmployeeSelector}
				controlName="assetIds"
				label={f('employees')}
				initialValue={props.state?.parameters?.assetIds || []}
			/>
			<ObjectDeviceManager
				disabled={props.disabled}
				editMode
				uris={props.state?.parameters?.uris || []}
				controlName="uris"
				title={f('devices restriction')}
				emptyMessage={f('all devices')}
			/>
			<Form.Control
				controlName="retrospective"
				controlType={Form.Control.Type.Checkbox}
				label={f('report later items first')}
				initialValue={Boolean(props.state?.parameters?.retrospective)}
				disabled={props.disabled}
			/>
		</div>
	)
}

export default connect(state => ({
	state: state.reports.commute
}))(Commute);
