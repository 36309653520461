import React, { useContext } from 'react';
import Moment from 'react-moment';
import { useSelector, useDispatch } from 'react-redux';
import * as timeMachineState from '../../../redux/app/timeMachine/state';
import { datetime as dt } from '../../../misc/datetime';
import { ReduxKeyContext } from '../../../misc/ReduxKeyContext';
import { className } from '../../../lib/className';
import './datetimeField.scss';

/**
 * @param {Object} props
 * @param {Date} props.datetime
 * @param {Date} [props.now]
 * @param {boolean} [props.full] indicates full datetime format
 * @param {string} [props.format]
 * @param {boolean} [props.unclickable]
 */

function DatetimeField(props) {
	const { datetime, now, full, unclickable, ...rest } = props;
	const reduxKey = useContext(ReduxKeyContext);
	const locale = useSelector(state => state.locale);
	const dispatch = useDispatch();
	const clickable = !unclickable && reduxKey == 'timeMachine';
	const today = dt.today(now);
	const tomorrow = dt.tomorrow(today);
	let format = "L LTS";
	if (!full && props.datetime > today && props.datetime < tomorrow) {
		format = "LTS";
	}

	const onClick = (e) => {
		if (clickable) {
			e.stopPropagation();
			dispatch(timeMachineState.actions.setNow({ now: props.datetime }));
		}
	}

	return (
		<span className={className("datetime-field", { clickable })} onClick={onClick}>
			<Moment 
				locale={locale.lang}
				from={now ? now : undefined}
				date={props.datetime}
				format={format}
				{...rest}
			/>
		</span>
	)
}

export default DatetimeField;
