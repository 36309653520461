import { useEffect, RefObject, DispatchWithoutAction } from 'react';

export const useOnClickOutside = (refs: RefObject<HTMLElement>[], onOutsideClick: DispatchWithoutAction) => {
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent | TouchEvent) => {
      const element = event.target as Element;
      const isNotChildComponent = !refs.some((ref) => ref.current && ref.current.contains(element));

      if (isNotChildComponent) {
        onOutsideClick();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, [refs, onOutsideClick]);
};
