import React from 'react';
import { Sidenav } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import { actions as contextActions } from '../../../redux/app/context';
import CategoryList from './categories/CategoryList';
import GroupingPicker from '../../share/categories/GroupingPicker';
import DeviceSearchControl from '../../share/devices/DeviceSearchControl';
import SidebarToggle from './SidebarToggle';
import ActionBar from './actionbar/DeviceActionBar';
import groupingIcon from '../../../img/icons/grouping.png';
import filterIcon from '../../../img/icons/explore.png';
import useLocalStorage from '../../../misc/useLocalStorage';
import { STORAGE_KEY } from '../../../redux/app/categories/grouping';
import './deviceSideMenu.scss';

export const GroupingIdContext = React.createContext(null);

function SideMenu() {
	const collapsed = useSelector(state => state.context.sidebarCollapsed);
	const dispatch = useDispatch();
	const [groupingId, setGroupingId] = useLocalStorage(STORAGE_KEY);

	const onToggleChanged = (value) => {
		dispatch(contextActions.setSidebarCollapsed({ collapsed: value }));
	}

	return (
		<GroupingIdContext.Provider value={groupingId}>
			<div className="device-side-menu">
				<Sidenav.Header className="side-header">
					<div className="top-line">
						<SidebarToggle
							value={collapsed}
							onChange={onToggleChanged}
						/>
						<ActionBar
							groupingId={groupingId}
							hide={collapsed}
						/>
					</div>
					<div className="side-lines">
						<div className="side-line">
							<img src={groupingIcon} alt="" />
							{!collapsed && <GroupingPicker onChange={setGroupingId} categoryId={groupingId} dark />}
						</div>
						<div className="side-line">
							<img src={filterIcon} alt="" />
							{!collapsed && <DeviceSearchControl />}
						</div>
					</div>
				</Sidenav.Header>
				<Sidenav className="side-body scrollbar dark">
					<Sidenav.Body>
						<CategoryList className="scrollbar dark" groupingId={groupingId} />
					</Sidenav.Body>
				</Sidenav>
			</div>
		</GroupingIdContext.Provider>
	);
}

export default SideMenu;
