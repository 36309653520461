import React, { FC } from 'react';

import {
  AttributeContentStyled,
  AttributeHeaderStyled,
  DetectorAttributeStyled,
} from 'ui/pages/attribute-range-detectors/detector-attribute.styled';
import { useI18n } from 'i18n';
import { AttributeSelect } from './attribute-select';
import { AttributeAspectsFields } from './attribute-aspects-fields';

interface IAttributeFieldsProps {
  isDisabled: boolean;
  mnemonics?: string;
}

export const AttributeFields: FC<IAttributeFieldsProps> = (props) => {
  const { isDisabled, mnemonics } = props;

  const { fc } = useI18n();

  return (
    <DetectorAttributeStyled>
      <AttributeHeaderStyled>
        <label>{fc('attribute')}</label>
      </AttributeHeaderStyled>
      <AttributeContentStyled>
        <AttributeSelect isDisabled={isDisabled} />
        {!!mnemonics && <AttributeAspectsFields isDisabled={isDisabled} mnemonics={mnemonics} />}
      </AttributeContentStyled>
    </DetectorAttributeStyled>
  );
};
