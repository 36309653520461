import { rx, cx, api } from '../../../api';
import { actions } from './actions';
import { ofType, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, map, takeUntil } from 'rxjs/operators';

// const defaultState = {
// 	[categoryName]: {
// 		list: null,
// 		map: null,
// 		pending: false,
// 		error: null
// 	}
// };

// ---------------------------------------------------------

function reducer(state, action) {
	let copy = null;
	switch (action.type) {
		case actions.category.request.type:
			copy = { ...state };
			copy[action.categoryName] = {
				...state[action.categoryName],
				pending: true,
				error: null
			};
			break;
		case actions.category.success.type:
			copy = { ...state };
			copy[action.categoryName] = {
				...state[action.categoryName],
				list: action.list,
				map: cx.i.hash(action.list, category => category.code),
				pending: false
			};
			break;
		case actions.category.fail.type:
			copy = { ...state };
			state[action.categoryName] = {
				...state[action.categoryName],
				pending: false,
				error: action.errorMessage
			};
			break;
		case actions.category.cancel.type: // TODO
			break;
		default:
			break;
	}
	return copy || state || {};
};

// ---------------------------------------------------------

const loadEpic = (action$) => {
	return action$.pipe(
		ofType(actions.category.request.type),
		mergeMap(action =>
			rx(api.registry.category, action.categoryName).pipe(
				map(operation => actions.category.success({ categoryName: action.categoryName, list: operation.response() })),
				catchError(error => of(actions.category.fail({ categoryName: action.categoryName, errorMessage: error.message }))),
				takeUntil(action$.pipe(ofType(actions.category.cancel.type)))
			)
		)
	)
}

const epic = combineEpics(loadEpic);

// ---------------------------------------------------------

export { reducer, epic };
