import { useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import { cx } from '../../../../api';
import { actions } from '../../../../redux/api/reports';

/**
 * @param {Object} props
 * @param {React.RefObject} props.customRef
 * @param {Object} props.timeRange
 * @param {boolean} [props.disabled]
 */

function DeviceUsages(props) {

	useImperativeHandle(props.customRef, () => ({
		submit: () => {
			const parameters = new cx.ods.reports.DeviceUsageReportParameters();
			parameters.timeRange = props.timeRange;
			props.dispatch(actions.deviceUsages.request({ parameters, clear: true }));
		},
		clear: () => {
			props.dispatch(actions.deviceUsages.exportClear());
		}
	}));

	return (null);
}

export default connect(state => {
	return {
		state: state.reports.deviceUsages
	}
})(DeviceUsages);
