import { ofType, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { map, mergeMap, filter, withLatestFrom } from 'rxjs/operators';
import { cx, api, rx } from '../../api';
import { interceptRequest as interceptRSRequest, interceptResponse as interceptRSResponse } from '../../rs';
import { actions as rootActions } from '../root';
import { actions as accountActions } from './account';
import { actions as authorizationActions, tokenManager } from './authorization';
import { ActionGeneratorBuilder, deltaReducer, errorMap } from '../actions';
import { rootLogger } from 'core/lib/log';

const logger = rootLogger.logger('session');

// ---------------------------------------------------------

const defaultState = {
	initialized: false,
	closed: true,
	authorized: false,
	pending: true, // intentionally true
	error: null
};

const actions = new ActionGeneratorBuilder('session')
	.type('restore')
	.type('unauthorized')
	.subtype('login', login => login.request({ loginName: true }).success('loginName').fail())
	.subtype('logout', logout => logout.request().success().fail())
	.subtype('inspect', inspect => inspect.request({ loginName: true, token: true }).success({ loginName: true, signinContext: true }).fail())
	.subtype('events', events => events.type('started').type('paused').type('resumed').type('closed'))
	.type('clear')
	.build()
;

// ---------------------------------------------------------

class SessionAdapter {

	static getInstance() {
		if (!SessionAdapter.$instance) SessionAdapter.$instance = new SessionAdapter();
		return SessionAdapter.$instance;
	}

	connect(store) {
		this.store = store;
		const requestFactory = cx.ods.RequestFactory.getInstance();
		requestFactory.setRequestFilter(request => this.filterCXRequest(request))
		requestFactory.setResponseFilter(request => this.filterCXResponse(request));
		interceptRSRequest(request => this.interceptRSRequest(request));
		interceptRSResponse(response => this.interceptRSRequest(response));
	}

	dispatch(action) {
		this.store.dispatch(action);
	}

	filterCXRequest(request) {
		const token = tokenManager.getSessionToken();
		if (token != null) request.setHeader('x-ow-session', token.value);
	}

	filterCXResponse(request) {
		var response = request.getResponseObject();
		if (response && cx.o.typeOf(response, cx.ods.Unauthorized)) {
			logger.info('Unauthorized request');
			this.dispatch(actions.unauthorized());
		}
	}

	interceptRSRequest(request) {
		const accessJWT = tokenManager.getAccessToken()?.jwt;
		if (accessJWT != null) request.bearer(accessJWT); 
	}

	interceptRSResponse(response) {
		if (response.status == 401) {
			logger.info(`Unauthorized request ${response.url}`);
			this.dispatch(actions.unauthorized());
			throw new Error('Unauthorized request');
		}
	}
}

const connect = (store) => {
	SessionAdapter.getInstance().connect(store);
}

// ---------------------------------------------------------

const reducer = deltaReducer((state, action) => {
	switch (action.type) {
		case actions.restore.type: return {
			pending: true
		};
		case actions.events.started.type:
		case actions.events.resumed.type: return {
			authorized: true,
			pending: false,
			closed: false,
			error: null,
			initialized: true
		};
		case actions.unauthorized.type: return {
			authorized: false,
			pending: false,
			error: null,
			initialized: true
		};
		case actions.events.closed.type: return {
			authorized: false,
			closed: true,
			pending: false,
			error: null
		};
		case actions.login.request.type:
		case actions.logout.request.type:
		case actions.inspect.request.type: return {
			pending: true,
			error: null
		};
		case actions.login.success.type:
		case actions.logout.success.type:
		case actions.inspect.success.type: return {
			pending: false
		};
		case actions.login.fail.type:
		case actions.logout.fail.type:
		case actions.inspect.fail.type: return {
			pending: false,
			error: action.errorMessage
		};
	};
	return null;
}, defaultState);

// ---------------------------------------------------------

const restoreEpic = (action$) => {
	return action$.pipe(
		ofType(actions.restore.type),
		mergeMap(action => of(accountActions.retrieve.request()))
	)
}

const loginEpic = (action$) => {
	return action$.pipe(
		ofType(actions.login.request.type),
		mergeMap(action => of(authorizationActions.signIn.request(action)))
	)
}

const signInSuccessEpic = (action$) => {
	return action$.pipe(
		ofType(authorizationActions.signIn.success.type),
		mergeMap(action => of(actions.login.success({ loginName: action.loginName })))
	)
}

const logoutEpic = combineEpics(
	action$ => action$.pipe(ofType(actions.logout.request.type), mergeMap(action => of(authorizationActions.signOut.request()))),
	action$ => action$.pipe(ofType(authorizationActions.signOut.success.type), mergeMap(action => of(actions.logout.success()))),
	action$ => action$.pipe(ofType(authorizationActions.signOut.fail.type), mergeMap(action => of(actions.logout.fail(action)))),
);

const inspectEpic = (action$) => {
	return action$.pipe(
		ofType(actions.inspect.request.type),
		mergeMap(action => {
			const inspectionRequest = new cx.ods.auth.InspectionRequest({
				loginName: action.loginName, token: action.token
			});
			return rx(api.auth.inspect, inspectionRequest).pipe(
				map(operation => actions.inspect.success({ loginName: action.loginName, signinContext: operation.response() })),
				errorMap(actions.inspect.fail)
			);
		})
	)
}

const stateEpic = combineEpics(
	(action$, state$) => action$.pipe(
		ofType(actions.login.success.type),
		withLatestFrom(state$.pipe(map(state => state.account))),
		mergeMap(([action, account]) => {
			const emit = [];
			if (account.details == null) { // fresh login
				emit.push(accountActions.retrieve.request());
				emit.push(actions.events.started());
			} else if (account.details.loginName == action.loginName) { // same account re-login
				emit.push(actions.events.resumed());
			} else { // different account re-login
				emit.push(rootActions.clear());
				emit.push(accountActions.retrieve.request());
			}
			return of.apply(this, emit);
		})
	),
	(action$, state$) => action$.pipe(
		ofType(actions.unauthorized.type),
		withLatestFrom(state$.pipe(map(state => state.session))),
		filter(([action, state]) => !state.closed),
		map(action => actions.events.paused())
	),
	action$ => action$.pipe(
		ofType(actions.unauthorized.type),
		filter(() => tokenManager.hasSignInToken()),
		mergeMap(() => of(actions.login.request({ loginName: tokenManager.getLoginName() })))
	),
	(action$, state$) => action$.pipe(
		ofType(accountActions.retrieve.success.type),
		withLatestFrom(state$.pipe(map(state => state.session.authorized))),
		filter(([action, authorized]) => !authorized),
		mergeMap(() => of(actions.events.started()))
	),
	action$ => action$.pipe(ofType(actions.logout.success.type), mergeMap(() => of(actions.events.closed()))),
	action$ => action$.pipe(ofType(actions.inspect.success.type), mergeMap(() => of(actions.events.closed()))),
	action$ => action$.pipe(ofType(actions.events.closed.type), map(() => rootActions.clear()))
)

const epic = combineEpics(restoreEpic, loginEpic, signInSuccessEpic, logoutEpic, inspectEpic, stateEpic);

// ---------------------------------------------------------

export { actions, reducer, epic, connect };
