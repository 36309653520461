import { actions } from '../actions';
import { reduxSwitch } from '../../../tools';

const defaultState = {
	preview: null,
	list: null,
	parameters: null,
	hasMore: null,
	pending: false,
	error: null,
	at: null,
	// export
	exporting: false,
	progress: null, // { percent, done, total }
	csv: null,
};

const updateAnchor = (parameters, visits) => {
	if (visits && visits.length > 0) {
		const lastZoneVisit = visits[visits.length - 1];
		parameters.window.anchorURI = lastZoneVisit.uri;
	}
	return parameters;
}

function apiReducer(state = defaultState, action) {
	let _state;
	switch (action.type) {
		case actions.zoneVisits.request.type:
			if (action.clear) {
				_state = { ...defaultState };
			} else {
				_state = {...state};
			}
			return {
				..._state,
				parameters: action.parameters,
				pending: true,
				error: null,
				at: action.clear ? new Date() : _state.at
			};
		case actions.zoneVisits.success.type:
			const visits = action.zoneVisits;
			const batchSize = state.parameters.window.size;
			const uris = {};
			visits && visits.forEach(zoneVisit => uris[zoneVisit.uri] = true);
			const hasMore = visits ? Object.keys(uris).length == batchSize : false;
			const list = state.list ? (visits ? state.list.concat(visits) : state.list) : (visits ? [...visits] : []);
			_state = {...state};
			return {
				..._state,
				list,
				hasMore,
				parameters: updateAnchor(state.parameters, visits),
				pending: false,
				error: null
			};
		case actions.zoneVisits.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		case actions.zoneVisits.cancel.type:
			return {
				...state,
				pending: false
			};
		default:
			return state;
	}
}

function exportReducer(state, action) {
	switch (action.type) {
		case actions.zoneVisits.export.type:
			return {
				...state,
				exporting: true,
				progress: null,
				csv: null
			}
		case actions.zoneVisits.exportProgress.type:
			return {
				...state,
				progress: {
					percent: action.progress,
					done: action.count,
					total: action.total
				}
			}
		case actions.zoneVisits.exportDone.type:
			return {
				...state,
				parameters: state.parameters,
				exporting: false,
				csv: action.csv
			}
		case actions.zoneVisits.exportClear.type:
			return {
				...state,
				csv: null
			}
		default:
			return state;
	}
}

const reducer = reduxSwitch([apiReducer, exportReducer], defaultState);

export { reducer };
