import React from 'react';
import { withRouter } from 'react-router-dom';
import { useI18n } from '../../../../../i18n';
import Menu from '../../../general/menu/Menu';
import iconExport from '../../../../img/icons/export.png';
import ActionClose from '../../../share/actionbar/ActionClose';

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {function} props.onExport
 * @param {boolean} [props.canExport]
 * @param {React.Component|Array.<React.Component>} [props.items]
 */

function ReportHeader(props) {
	const { fc } = useI18n();

	const close = () => {
		props.history.goBack();
	}

	return (
		<div className="header">
			<span className="capitalize">{props.title}</span>
			<Menu horizontal>
				{props.items}
				{props.onExport && <Menu.Item onClick={props.onExport} disabled={!props.canExport}>
					<img src={iconExport} alt="" title={fc('export to CSV')} />
				</Menu.Item>}
				<Menu.Item onClick={close}>
					<ActionClose title={fc('close report')} />
				</Menu.Item>
			</Menu>
		</div>
	);
}

export default withRouter(ReportHeader);
