import React, { DispatchWithoutAction, FC } from 'react';

import { FormContentStyled } from 'ui/pages/issues/issues-actions-select.styled';
import { IssueActionsOptions } from './issue-actions-options';
import { RemarksField } from './remarks-field';
import { FormButtons } from './form-buttons';

interface IFormContentProps {
  isLoading: boolean;
  issueTypeId: number;
  onClose: DispatchWithoutAction;
  onSubmit: DispatchWithoutAction;
}

export const FormContent: FC<IFormContentProps> = ({ isLoading, issueTypeId, ...rest }) => (
  <FormContentStyled>
    <IssueActionsOptions isLoading={isLoading} issueTypeId={issueTypeId} />
    <RemarksField isLoading={isLoading} />
    <FormButtons isLoading={isLoading} {...rest} />
  </FormContentStyled>
);
