import React, { useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { className } from '../../../../lib/className';
import { scrollReportItemIntoView } from '../../../../misc/misc';
import Loader from '../../../general/Loader';
import { useI18n } from '../../../../../i18n';
import ScrollList from '../../../general/list/ScrollList';
import ReportSummary from './ReportSummary';
import ReportHeader from './ReportHeader';
import { actions } from '../../../../redux/api/reports';
import SwitchMapMenuItem from './SwitchMapMenuItem';
import NoDataView from '../../../general/view/NoDataView';
import GroupListItem from './commuteMisc/GroupListItem';
import HeaderListItem from './commuteMisc/HeaderListItem';
import AssetTripTraceViewMap from './commuteMisc/AssetTripTraceViewMap';
import './commute.scss';

function EodListItem() {
	const { f } = useI18n();
	return (
		<div className="indicator">
			<span className="capitalize">{f('you have reached the end')}</span>
		</div>
	);
}

// -----------------------------------------------------------------

function Commute(props) {
	const { f } = useI18n();
	const selectedItemBox = useRef();
	const [selectedAssetTrip, setSelectedAssetTrip] = useState(null);
	const [displayMap, setDisplayMap] = useState(false);
	const device = props.deviceMap && props.deviceMap[props.state.uri];

	useLayoutEffect(() => {
		if (selectedItemBox.current) {
			scrollReportItemIntoView(selectedItemBox.current);
		}
	}, [selectedAssetTrip, displayMap]);

	const loadNext = () => {
		const uri = props.state.uri;
		const parameters = props.state.parameters;
		props.dispatch(actions.commute.request({ uri, parameters }));
	}

	const onItemClick = (assetTrip) => {
		if (!displayMap) setDisplayMap(true);
		if (!selectedAssetTrip || selectedAssetTrip.presenceId != assetTrip.presenceId) {
			setSelectedAssetTrip(assetTrip);
		}
	}

	const switchMapHandler = () => {
		setDisplayMap(!displayMap);
	}

	let content = null;
	let mapBox = null;
	let canExport = false;
	if (props.state.list != null) {
		if (!props.state.list || props.state.list.length == 0) {
			content = <NoDataView message={f('no data for such criteria')} />;
		} else {
			canExport = true;
			const groups = [];
			let group = [];
			const inSameGroup = (assetTrip) => group.length == 0 || group[group.length - 1].uri == assetTrip.uri;
			props.state.list.forEach(assetTrip => {
				if (inSameGroup(assetTrip)) group.push(assetTrip);
				else {
					groups.push(group);
					group = [assetTrip];
				}
			});
			if (group.length > 0) groups.push(group);
			const items = groups.map((_group, at) => {
				return <GroupListItem key={at} group={_group} selectedAssetTrip={selectedAssetTrip} onClick={onItemClick} customRef={selectedItemBox} />;
			});
			items.unshift(<HeaderListItem key={1000000} />);
			content = (
				<ScrollList pending={props.state.pending} next={loadNext} isEod={!props.state.hasMore} eodItem={EodListItem}>
					{items}
				</ScrollList>
			);
			if (displayMap) mapBox = (<AssetTripTraceViewMap assetTrip={selectedAssetTrip} />);
		}
	} else {
		content = (
			<div className="center">
				{
					props.state.error
						? <span className="error">{props.state.error}</span>
						: <Loader size={Loader.Size.MD} />
				}
			</div>
		);
	}

	const exportReport = () => {
		props.dispatch(actions.commute.export({ uri: props.state.uri }));
		props.history.goBack();
	}

	const timeRange = props.state.parameters.timeRange;
	return (
		<div className="report commute">
			<ReportHeader
				title={f('commute report')}
				canExport={canExport}
				onExport={exportReport}
				items={<SwitchMapMenuItem disabled={!canExport} active={displayMap} onClick={switchMapHandler} />}
			/>
			<ReportSummary
				since={timeRange.since}
				until={timeRange.until}
				devices={device && [device]}
				generatedAt={props.state.at}
			/>
			<div className={className('content', { 'with-map': displayMap })}>
				{content}
				{mapBox}
			</div>
		</div>
	);
}

export default withRouter(connect(state => ({
	state: state.reports.commute,
	deviceMap: state.devices.map
}))(Commute));
