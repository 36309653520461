import React, { useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import { cx } from '../../../../api';
import Form from '../../../general/form/Form';
import { actions } from '../../../../redux/api/reports';

const BATCH_SIZE = 70;

/**
 * @param {Object} props
 * @param {cx.ods.TimeRangeParameters} props.timeRange
 * @param {React.RefObject} props.customRef
 * @param {boolean} [props.disabled]
 */

function Trip(props) {
	const { f } = useI18n();

	useImperativeHandle(props.customRef, () => ({
		submit: (values) => {
			const parameters = new cx.ods.reports.TripReportParameters();
			parameters.timeRange = props.timeRange;
			parameters.timeRange.retrospective = values.retrospective;
			parameters.timeRange.window = new cx.ods.TimeWindow(BATCH_SIZE);
			parameters.markers = values.markers;
			props.dispatch(actions.trip.request({ uri: values.uri, parameters, clear: true }));
		},
		clear: () => {
			props.dispatch(actions.trip.exportClear());
		}
	}));

	return (
		<Form.ControlGroup key={50} disabled={props.disabled}>
			<Form.Control
				controlName="markers"
				controlType={Form.Control.Type.Checkbox}
				label={f('locate pre-start markers')}
				initialValue={props.state.parameters ? props.state.parameters.markers : false}
			/>
			<Form.Control
				controlName="retrospective"
				controlType={Form.Control.Type.Checkbox}
				label={f('report later items first')}
				initialValue={props.state.parameters ? props.state.parameters.timeRange.retrospective : false}
			/>
		</Form.ControlGroup>
	)
}

export default connect(state => {
	return {
		state: state.reports.trip
	}
})(Trip);
