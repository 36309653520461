import Resolver from '../Resolver';
import { actions } from '../actions';

const getter = (situation) => {
	const events = situation.events;
	let coordinates = [];
	events && events.forEach(event => {
		event.message && coordinates.push({
			latitude: event.message.latitude,
			longitude: event.message.longitude
		});
	});
	return coordinates.length > 0 ? coordinates : { latitude: null, longitude: null };
}

let resolver = null;
const connect = (store) => {
	resolver = new Resolver(store, actions.deviceSituations.exportProgress, getter);
}

export { resolver, connect }