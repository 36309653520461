import React from 'react';
import { correctBounds, compact } from 'react-grid-layout/build/utils';

function ManInTheMiddle(Component) {
	return function (props) {
		const { colWidth, layout, width, margin = [10, 10], parentNode, height, ..._props } = props;
		let parentHeight = height;
		if (parentNode) parentHeight = parentNode.offsetHeight;
		const maxRows = parentHeight > 0 ? Math.floor(parentHeight / (props.rowHeight + margin[1])) : Infinity;
		let columns = Math.floor(width / colWidth);
		let minColumns = 0;
		props.layout.forEach(element => {
			element.w > minColumns && (minColumns = element.w);
			if (maxRows != Infinity && element.minH < maxRows) element.maxH = maxRows;
			if (element.maxH < element.h) element.h = element.maxH;
		});
		if (columns < minColumns) columns = minColumns;
		let corrected = correctBounds(layout, { cols: columns });
		corrected = compact(corrected, null, columns);
		return (<Component cols={columns} width={columns * colWidth} height={parentHeight} margin={margin} layout={corrected} maxRows={Infinity} {..._props} />);
	};
}

export default ManInTheMiddle;