import { datetime } from "../../../../../misc/datetime";
import { cx } from "../../../../../api";

const END_OF_DAY = cx.today();
END_OF_DAY.setHours(23); END_OF_DAY.setMinutes(59); END_OF_DAY.setSeconds(59);

const buildParameters = (parameters, data) => {
	if (!parameters || !data) return;
	switch (data.timeRange) {
		case 0:
			const sinceDay = new Date(END_OF_DAY - data.days * datetime.DAY + datetime.SECOND);
			parameters.filter.since = sinceDay;
			parameters.histogram.binSize = datetime.DAY / datetime.SECOND;
			break;
		case 1:
			const sinceDate = new Date(data.sinceDaily);
			const sinceDaily = sinceDate.getTime();
			const nowDaily = cx.now().getTime();
			parameters.filter.since = sinceDate;
			if (nowDaily - sinceDaily > datetime.WEEK) {
				parameters.filter.until = new Date(sinceDaily + datetime.WEEK - datetime.SECOND);
			}
			parameters.histogram.binSize = datetime.DAY / datetime.SECOND;
			break;
		case 2:
			const nowHours = cx.now();
			nowHours.setMinutes(0); nowHours.setSeconds(0);
			const sinceHours = new Date(nowHours.getTime() - (data.hours - 1) * datetime.HOUR);
			parameters.filter.since = sinceHours;
			parameters.histogram.binSize = datetime.HOUR / datetime.SECOND;
			break;
		case 3:
			const nowHourly = cx.now().getHours();
			const sinceHourly = cx.now();
			sinceHourly.setHours(data.sinceHourly); sinceHourly.setMinutes(0); sinceHourly.setSeconds(0);
			if (data.sinceHourly > nowHourly) {
				sinceHourly.setDate(new Date(sinceHourly.getTime() - datetime.DAY).getDate());
			}
			parameters.filter.since = sinceHourly;
			parameters.histogram.binSize = datetime.HOUR / datetime.SECOND;
			break;
		default:
			break;
	}
}

export default buildParameters;