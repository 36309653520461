import Resolver from '../Resolver';
import { actions } from '../actions';

const getter = (location) => {
	return {
		latitude: location.latitude,
		longitude: location.longitude
	}
}

let resolver = null;
const connect = (store) => {
	resolver = new Resolver(store, actions.locationHistory.exportProgress, getter);
}

export { resolver, connect }