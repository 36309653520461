import React from 'react';
import { Avatar } from 'rsuite';
import DeviceLocation from '../../../share/devices/DeviceLocation';
import { Link } from 'react-router-dom';
import { FiLink } from 'react-icons/fi';
import { className, fromProps } from '../../../../lib/className';
import DeviceLatestMessageFieldsView from './DeviceLatestMessageFieldsView';
import { DeviceDetailsProxy } from '../../../../api/device'; // eslint-disable-line
import DeviceAssetTypeIcon from '../../../share/devices/DeviceAssetTypeIcon';
import './deviceBriefView.scss';

/**
 * @param {Object} props
 * @param {DeviceDetailsProxy} props.device
 */

function DeviceBriefView(props) {

	return (
		<div className={className(fromProps(props), 'device-brief-view')}>
			<div className="main">
				<div>
					<Avatar size="lg" circle>
						<DeviceAssetTypeIcon categoryIds={props.device.categoryIds} />
					</Avatar>
				</div>
				<div>
					<Link className="title" to={'/device/' + props.device.uri + '/card'}>
						<div>{props.device.denomination()}</div>
						<FiLink />
					</Link>
					<DeviceLocation device={props.device} />
				</div>
			</div>
			<DeviceLatestMessageFieldsView uri={props.device.uri} hasTitle />
		</div>
	);
}

export default DeviceBriefView;