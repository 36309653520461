import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Form from '../../general/form/Form';
import { useI18n } from '../../../../i18n';
import ButtonGroup from '../../general/form/ButtonGroup';
import Button from '../../general/form/Button';
import { actions } from '../../../redux/api/session';
import { actions as authorizationActions } from '../../../redux/api/authorization';
import AuthorizationForm from './AuthorizationForm';
import FloatingLabelInput from '../../general/form/FloatingLabelInput';
import './inspectAccountForm.scss';

/**
 * @param {Object} props
 * @param {function} props.onReady
 * @param {function} props.onCancel
 * @param {function} [props.setModalFooter] possibility to set footer content when used inside modal window
 */

function InspectAccountForm(props) {
	const { f } = useI18n();
	const submitHook = useRef(null);
	const pending = useRef(false);
	const loginName = useRef('');
	const [inited, setInited] = useState(false);
	const auth = props.authorization;
	const token = auth.verificationToken;
	const verification = auth.authType && Boolean(auth.challenge);

	const dispatchInspect = (loginName, token) => {
		pending.current = true;
		props.dispatch(actions.inspect.request({ loginName: loginName.trim(), token }));
	}

	const onSubmit = (values) => {
		if (token && (Date.now() + 60000) < token.expiresAt.getTime()) {
			dispatchInspect(values.loginName, token.value);
		} else {
			loginName.current = values.loginName;
			props.dispatch(authorizationActions.verify.request());
		}
	}

	const submit = () => {
		submitHook.current();
	}

	const footer = (
		<ButtonGroup className="inspect-account-buttons">
			<Button
				onClick={props.onCancel}
				disabled={props.session.pending}
			>
				{f('cancel')}
			</Button>
			<Button
				onClick={submit}
				loading={props.session.pending}
				disabled={props.session.pending || Boolean(auth.authType)}
			>
				{f('inspect')}
			</Button>
		</ButtonGroup>
	);

	useEffect(() => {
		if (inited) {
			if (props.setModalFooter) props.setModalFooter(verification ? null : footer);
			if (!verification && token) {
				dispatchInspect(loginName.current, token.value);
			}
		} else setInited(true);
		return () => {
			props.dispatch(actions.clear());
		}
	}, [verification]);

	useEffect(() => {
		props.setModalFooter && props.setModalFooter(verification ? null : footer);
		if (!props.session.pending && pending.current) {
			pending.current = false;
			if (props.session.error == null) {
				props.onReady();
			}
		}
	}, [props.session]);

	return (
		<div className="inspect-account-form-wrap">
			{verification
				? <AuthorizationForm simple />
				: <Form
					onSubmit={onSubmit}
					submitHook={submitHook}
					className="inspect-account"
					disabled={props.session.pending}
					error={f({ prefix: 'server-error', id: props.session.error })}
					footer={!props.setModalFooter && footer}
				>
					<Form.Control
						controlType={FloatingLabelInput}
						controlName="loginName"
						initialValue={loginName.current}
						controlValidator={(value) => {
							if (value == null || value && !value.trim()) return f('please enter login');
						}}
						label={f('login to inspect')}
						autoFocus
					/>
				</Form>
			}
		</div>
	);
}

export default connect(state => ({
	session: state.session,
	authorization: state.authorization
}))(InspectAccountForm);
