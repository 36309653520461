import React, { useState, useEffect } from 'react';
import RadioGroup from './RadioGroup';
import Radio from './Radio';
import Form from './Form';

/**
 * For other parameters - see RadioGroup component
 * @param {Object} props
 * @param {string} [props.defaultValue]
 * @param {string} [props.controlName]
 * @param {string} [props.label]
 * @param {Array.<Object>} [props.data] [{ value: string, label: string }] value cannot be "undefined"
 */

function RadioControl(props) {
	const { data, defaultValue, controlName, onChange, ..._props } = props;

	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		if (defaultValue != value) {
			setValue(defaultValue !== undefined
				? defaultValue
				: (data ? data[0].value : null)
			);
		}
	}, [defaultValue]);

	const _onChange = (value) => {
		if (onChange) {
			onChange(value);
		}
		setValue(value);
	}

	let content = null;
	if (data) {
		const radioList = data.map(item => <Radio key={item.value} value={item.value}>{item.label}</Radio>);
		content = (
			<Form.Control
				controlType={RadioGroup}
				controlName={controlName || "option"}
				value={value}
				onChange={_onChange}
				{..._props}
			>
				{radioList}
			</Form.Control>
		);
	}
	return content;
}

export default RadioControl;
