import React, { useImperativeHandle, useState, useRef } from 'react';
import Form from '../../../../general/form/Form';
import { cx } from '../../../../../api';
import { useI18n } from '../../../../../../i18n';
import RadioControl from '../../../../general/form/RadioControl';
import TimeFrameControl from './TimeFrameControl';
import SmoothMethodPicker from './SmoothMethodPicker';


/**
 * @param {Object} props
 * @param {cx.ods.reports.FuelLevelSmoothParameters} [props.smoothing]
 * @param {React.Ref} props.customRef
 * @param {boolean} [props.disabled]
 */

function SmoothingControl(props) {
	const { f, fc } = useI18n();
	const [method, setMethod] = useState(props.smoothing && props.smoothing.method ? props.smoothing.method : null);
	const [smoothOption, setSmoothOption] = useState(props.smoothing && props.smoothing.depth ? "depth" : "factor");
	const timeFrameRef = useRef();

	let methodParameter = null;

	switch (method) {
		case cx.ods.reports.FuelLevelSmoothMethods.median:
		case cx.ods.reports.FuelLevelSmoothMethods.slr:
			methodParameter = (
				<TimeFrameControl
					prefix={method}
					customRef={timeFrameRef}
					timeFrame={props.smoothing && props.smoothing.timeFrame}
					disabled={props.disabled}
				/>
			);
			break;
		case cx.ods.reports.FuelLevelSmoothMethods.ewma:
			const optionsData = [
				{ value: "depth", label: fc('number of measurements') },
				{ value: "factor", label: fc('smooth factor') }
			];
			methodParameter = (
				<>
					<RadioControl
						controlName="smoothOption"
						data={optionsData}
						defaultValue={smoothOption}
						onChange={setSmoothOption}
					/>
					<Form.Control
						controlName={smoothOption}
						controlType={Form.Control.Type.InputNumber}
						min={0} max={smoothOption == "factor" ? 1 : Infinity} step={smoothOption == "factor" ? 0.01 : 1}
						controlValidator={(value, values) => {
							if (!props.disabled) {
								if (value == null || value === '') {
									return f('please enter a value');
								} else if (+value == 0) {
									return f('please enter a positive value');
								}
								if (values[smoothOption] == 'depth' && value != null && !/^(\d+)$/.test(value)) {
									return f('please enter an integer value');
								}
							}
						}}
						initialValue={
							props.disabled
								? null
								: (props.smoothing && props.smoothing[smoothOption]
									? props.smoothing[smoothOption]
									: (smoothOption == "factor" ? 0.05 : 5)
								)
						}
						disabled={props.disabled}
					/>
				</>
			);
			break;
		case cx.ods.reports.FuelLevelSmoothMethods.sma:
			methodParameter = (
				<Form.Control
					controlName="depth"
					controlType={Form.Control.Type.InputNumber}
					min={0} step={1}
					controlValidator={(value, values) => {
						if (!props.disabled) {
							if (value == null || value === '') {
								return f('please enter a value');
							} else if (+value == 0) {
								return f('please enter a positive value');
							}
							if (value != null && !/^(\d+)$/.test(value)) {
								return f('please enter an integer value');
							}
						}
					}}
					label={fc('number of measurements')}
					initialValue={props.disabled ? null : (props.smoothing && props.smoothing.depth ? props.smoothing.depth : 5)}
					disabled={props.disabled}
				/>
			);
			break;
		default:
			break;
	}

	useImperativeHandle(props.customRef, () => ({
		submit: (values) => {
			let smoothing = null;
			if (method) {
				smoothing = new cx.ods.reports.FuelLevelSmoothParameters();
				smoothing.method = method;
				switch (method) {
					case cx.ods.reports.FuelLevelSmoothMethods.median:
					case cx.ods.reports.FuelLevelSmoothMethods.slr:
						smoothing.timeFrame = timeFrameRef.current.submit(values);
						break;
					case cx.ods.reports.FuelLevelSmoothMethods.sma:
						smoothing.depth = +values.depth;
						break;
					case cx.ods.reports.FuelLevelSmoothMethods.ewma:
						const smoothOption = values.smoothOption;
						smoothing[smoothOption] = +values[smoothOption];
						break;
					default:
						break;
				}

			}
			return smoothing;
		},
		changed: values => {
			let changed = Boolean(props.smoothing) != Boolean(values.smoothMethod);
			if (!changed && props.smoothing) {
				changed = props.smoothing.method != values.smoothMethod;
				if (!changed) {
					switch (values.smoothMethod) {
						case cx.ods.reports.FuelLevelSmoothMethods.median:
						case cx.ods.reports.FuelLevelSmoothMethods.slr:
							changed = timeFrameRef.current.changed(values);
							break;
						case cx.ods.reports.FuelLevelSmoothMethods.ewma:
							const option = values.smoothOption;
							changed = !(option in props.smoothing);
							if (!changed) changed = Number(values[option]) != Number(props.smoothing[option]);
							break;
						case cx.ods.reports.FuelLevelSmoothMethods.sma:
							changed = Number(props.smoothing.depth) != Number(values.depth);
							break;
						default:
							break;
					}
				}
			}
			return changed;
		}
	}));

	return (
		<div className="group">
			<Form.ControlGroup>
				<Form.Control
					controlName="smoothMethod"
					controlType={SmoothMethodPicker}
					value={method}
					onChange={setMethod}
					disabled={props.disabled}
				/>
				{methodParameter}
			</Form.ControlGroup>
		</div>
	);
}

export default SmoothingControl;
