import React from 'react';
import { actions } from './actions';
import { rx, api, cx } from '../../../api';
import { of } from 'rxjs';
import { ofType, combineEpics } from 'redux-observable';
import { map as rxmap, mergeMap, catchError, takeUntil, filter } from 'rxjs/operators';
import { actions as latestEventsActions } from '../latestEvents';
import { actions as announcerActions } from '../announcer';
import { Notifications } from '../../../../Notifications';
import { fc, f } from '../../../../i18n';
import DeviceCommandNotification from '../../../react/share/devices/deviceCommands/DeviceCommandNotification';

export const EVENT_MNEMONICS_TO_PROCESS = ['failed', 'timeout', 'done'];

const isSameCommand = (left, right) => {
	return left.commandType == right.commandType && left.modifiedAt.getTime() == right.modifiedAt.getTime();
}

const typesEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.types.request.type),
		mergeMap(action =>
			rx(api.commands.types, action.uri).pipe(
				rxmap(operation => actions.types.success({ uri: action.uri, types: operation.response() })),
				catchError(error => of(actions.types.fail({ uri: action.uri, errorMessage: error.userMessage || error.message }))),
				takeUntil(action$.pipe(ofType(actions.types.cancel.type)))
			)
		)
	)
};

const submitEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.submit.request.type),
		mergeMap(action =>
			rx(api.commands.submit, action.uri, action.commandInfo).pipe(
				rxmap(operation => actions.submit.success({ uri: action.uri })),
				catchError(error => {
					const errorMessage = error.userMessage || error.message;
					const deviceMap = state$.value.devices.map;
					const deviceName = deviceMap && deviceMap[action.uri] && deviceMap[action.uri].denomination() || action.uri;
					const command = state$.value.deviceCommands[action.uri].typeMap[action.commandInfo.commandType].description;
					Notifications.add(
						Notifications.types.error,
						errorMessage,
						fc({ prefix: 'command', id: command }) + ' ' + f('for') + ' ' + deviceName
					);
					return of(actions.submit.fail({ uri: action.uri, errorMessage }));
				}),
				takeUntil(action$.pipe(ofType(actions.submit.cancel.type)))
			)
		)
	)
};

const loadEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.load.request.type),
		mergeMap(action =>
			rx(api.commands.load, action.uri).pipe(
				rxmap(operation => {
					const details = operation.response();
					details.forEach(commandDetails => {
						if (!state$.value.deviceCommands[action.uri].pendingCommands.some(pendCommand => isSameCommand(commandDetails, pendCommand))) {
							const deviceMap = state$.value.devices.map;
							const deviceName = deviceMap && deviceMap[action.uri] && deviceMap[action.uri].denomination() || action.uri;
							const command = state$.value.deviceCommands[action.uri].typeMap
								? state$.value.deviceCommands[action.uri].typeMap[commandDetails.commandType].description
								: 'command'
							;
							const pendingStatus = commandDetails.pending && 'pending' || commandDetails.processing && 'processing';
							Notifications.add(
								Notifications.types.info,
								fc('status') + ': ' + fc({ prefix: 'status', id: pendingStatus }),
								fc({ prefix: 'command', id: command }) + ' ' + f('for') + ' ' + deviceName
							);
						}
					});
					return actions.load.success({ uri: action.uri, details });
				}),
				catchError(error => of(actions.load.fail({ uri: action.uri, errorMessage: error.userMessage || error.message }))),
				takeUntil(action$.pipe(ofType(actions.load.cancel.type)))
			)
		)
	)
};

const findEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.find.request.type),
		mergeMap(action =>
			rx(api.commands.find, action.uri, action.filter).pipe(
				rxmap(operation => actions.find.success({ uri: action.uri, details: operation.response() })),
				catchError(error => of(actions.find.fail({ uri: action.uri, errorMessage: error.userMessage || error.message }))),
				takeUntil(action$.pipe(ofType(actions.find.cancel.type)))
			)
		)
	)
};

const retrieveEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.retrieve.request.type),
		mergeMap(action =>
			rx(api.commands.retrieve, action.uri, action.commandType, action.commandIndex).pipe(
				rxmap(operation => actions.retrieve.success({ uri: action.uri, details: operation.response() })),
				catchError(error => of(actions.retrieve.fail({ uri: action.uri, errorMessage: error.userMessage || error.message }))),
				takeUntil(action$.pipe(ofType(actions.retrieve.cancel.type)))
			)
		)
	)
};

const cancelEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.cancel.request.type),
		mergeMap(action =>
			rx(api.commands.cancel, action.uri, action.commandType, action.commandIndex).pipe(
				rxmap(operation => {
					const deviceMap = state$.value.devices.map;
					const deviceName = deviceMap && deviceMap[action.uri] && deviceMap[action.uri].denomination() || action.uri;
					const command = state$.value.deviceCommands[action.uri].typeMap[action.commandType].description;
					Notifications.add(
						Notifications.types.info,
						fc('command was canceled'),
						fc({ prefix: 'command', id: command }) + ' ' + f('for') + ' ' + deviceName
					);
					return actions.cancel.success({ uri: action.uri });
				}),
				catchError(error => {
					const errorMessage = error.userMessage || error.message;
					const deviceMap = state$.value.devices.map;
					const deviceName = deviceMap && deviceMap[action.uri] && deviceMap[action.uri].denomination() || action.uri;
					const command = state$.value.deviceCommands[action.uri].typeMap[action.commandInfo.commandType].description;
					Notifications.add(
						Notifications.types.error,
						errorMessage,
						fc({ prefix: 'command', id: command }) + ' ' + f('for') + ' ' + deviceName
					);
					return of(actions.cancel.fail({ uri: action.uri, errorMessage }))
				}),
				takeUntil(action$.pipe(ofType(actions.cancel.cancel.type)))
			)
		)
	)
};

const watchNewEventsEpic = (action$, state$) => {
	return action$.pipe(
		ofType(latestEventsActions.find.success.type),
		filter(action => {
			const deviceCommandMap = state$.value.deviceCommands;
			const newUris = action.maps.recentMap && Object.keys(action.maps.recentMap).filter(uri => {
				const events = action.maps.recentMap[uri];
				const commands = deviceCommandMap[uri] && deviceCommandMap[uri].types;
				if (!events || !commands) return false;
				else return events.some(event => event.commandType && commands.some(comand => comand.commandType === event.commandType));
			});
			return state$.value.registry.eventTypes.typeMap && newUris && newUris.length > 0;
		}),
		mergeMap(action => {
			const emit = [];
			const map = action.maps.recentMap;
			const deviceCommandMap = state$.value.deviceCommands;
			const eventTypeMap = state$.value.registry.eventTypes.typeMap;
			if (map != null && deviceCommandMap != null) {
				Object.keys(action.maps.recentMap).forEach(uri => {
					const events = action.maps.recentMap[uri];
					if (events) {
						events.forEach(event => {
							if (event.commandType && eventTypeMap) {
								const eventType = event.eventType;
								const canDispatch = eventTypeMap[eventType]
									&& EVENT_MNEMONICS_TO_PROCESS.some(eType => eType == eventTypeMap[eventType].mnemonics);
								if (canDispatch) {
									const deviceMap = state$.value.devices.map;
									const deviceName = deviceMap && deviceMap[uri] && deviceMap[uri].denomination() || uri;
									const command = state$.value.deviceCommands[uri] && state$.value.deviceCommands[uri].typeMap[event.commandType]
										? state$.value.deviceCommands[uri].typeMap[event.commandType].description
										: 'command'
									;
									Notifications.add(
										Notifications.types.success,
										<DeviceCommandNotification eventType={eventTypeMap[eventType]} />,
										fc({ prefix: 'command', id: command }) + ' ' + f('for') + ' ' + deviceName
									);
									emit.push(actions.lastComplete({ uri, commandType: event.commandType, eventType }));
								}
							}
						});
					}
				});
			}
			return of.apply(this, emit);
		})
	)
}

const watchAnnouncerEpic = (action$, state$) => {
	return action$.pipe(
		ofType(announcerActions.announced.type),
		filter(action => {
			return action.announcements.some(announcement =>
				cx.o.typeOf(announcement, cx.ods.devices.CommandStateChangeAnnouncement)
			);
		}),
		mergeMap(action => {
			const emit = [];
			action.announcements.forEach(announcement => {
				if (cx.o.typeOf(announcement, cx.ods.devices.CommandStateChangeAnnouncement)) {
					if (!state$.value.deviceCommands[action.uri] || !state$.value.deviceCommands[action.uri].types) {
						emit.push(actions.types.request({ uri: announcement.uri }));
					}
					emit.push(actions.load.request({ uri: announcement.uri }));
				}
			});
			return of.apply(this, emit);
		})
	)
}

const epic = combineEpics(typesEpic, submitEpic, loadEpic, findEpic, retrieveEpic, cancelEpic, watchNewEventsEpic, watchAnnouncerEpic);

export { epic };
