import React from 'react';
import { className, fromProps } from '../../../lib/className';
import './viewLayout.scss';

/**
 * @param {Object} props
 * @param {React.Component} [props.header]
 * @param {React.Component} [props.footer]
 */

function ViewLayout(props) {
	return (
		<div className={className('layout', 'view-layout', fromProps(props))}>
			{props.header && <div className="header">{props.header}</div>}
			<div className="content">{props.children}</div>
			{props.footer && <div className="footer">{props.footer}</div>}
		</div>
	)
}

export default ViewLayout;