export const ZONES_MAP_NAME = 'zones-map';

const MAP_PATH = `${process.env.REACT_APP_API_ENDPOINT}/data/map`;

export const GEO_JSONS_INFO = [
  { name: 'aircraft stands', path: `${MAP_PATH}/ERA.json`, zoom: 4, color: '#DC681A' },
  { name: 'runways', path: `${MAP_PATH}/Runway Edge.json`, zoom: 3, color: '#D848C6' },
  { name: 'roadways', path: `${MAP_PATH}/Airside Roadway.json`, zoom: 4, color: '#9CD848' },
  { name: 'taxiways', path: `${MAP_PATH}/Taxiway Markings.json`, zoom: 4, color: '#486AD8' },
  { name: 'pushback-related', path: `${MAP_PATH}/EOT, EOP.json`, zoom: 4, color: '#D8AD48' },
  { name: 'pushback-related', path: `${MAP_PATH}/Stopbar.json`, zoom: 4, color: '#D8AD48' },
  { name: 'stand name', path: `${MAP_PATH}/Stand Name.json`, zoom: 6, color: '#D848C7', labelKey: 'Stand Name' },
];
