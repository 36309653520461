import Resolver from '../Resolver';
import { actions } from '../actions';

const getter = (summary) => {
	return [{
		latitude: summary.trip.startMessage.latitude,
		longitude: summary.trip.startMessage.longitude,
	}, {
		latitude: summary.trip.lastMessage.latitude,
		longitude: summary.trip.lastMessage.longitude,
	}]
}

let resolver = null;
const connect = (store) => {
	resolver = new Resolver(store, actions.trip.exportProgress, getter);
}

export { resolver, connect };