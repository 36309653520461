import React from 'react';
import { connect } from 'react-redux';
import { List } from 'rsuite';
import EventField from './EventField';

/**
 * @param {Object} props
 * @param {cx.ods.devices.EventDetails} props.event
 * @param {boolean=false} [props.showDeviceName]
 */

function RecentEventListItem(props) {
	const showDeviceName = props.showDeviceName !== undefined ? props.showDeviceName : false;

	let device = null;
	if (props.latestEvents.indexMap != null && props.devices.map != null) {
		const uri = props.latestEvents.indexMap[props.event.eventId];
		device = props.devices.map[uri];
	}

	return (
		<List.Item className="event">
			<EventField event={props.event} device={showDeviceName ? device : null} showDate />
		</List.Item>
	);
}

export default connect(state => {
	return {
		latestEvents: state.latestEvents,
		eventTypes: state.registry.eventTypes,
		devices: state.devices
	}
})(RecentEventListItem);