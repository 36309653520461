import React, { useRef, useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { formatter } from '../../../../../core/misc/datetime';
import { textColorMain, colorPrimary } from '../../../../defaults.scss';
import { useI18n } from '../../../../../i18n';

/**
 * @param {Object} props
 * @param {Array.<cx.ods.reports.FuelLevelSpan>} props.data
 * @param {function} props.onSelect
 * @param {cx.ods.reports.FuelLevelSpan} [props.selectedInterval]
 * @param {boolean} [props.retrospective]
 */

function CheckIntervalLevelsChart(props) {
	const [state, setState] = useState({});
	const { f, fc } = useI18n();
	const ref = useRef(null);
	const skip = useRef(null);
	const selectedSeriesInd = useRef(null);
	const selectedDataInd = useRef(null);

	let content = null;

	useEffect(() => {
		if (props.selectedInterval && state.series && !skip.current) {
			let seriesIndex = null;
			let dataPointIndex = null;
			state.series.some((ser, i) => {
				return ser.data.some((item, y) => {
					if (
						item.x.getTime() == props.selectedInterval.since.generatedAt.getTime()
						|| item.x.getTime() == props.selectedInterval.lastAt.getTime()
					) {
						seriesIndex = i;
						dataPointIndex = y;
						return true;
					}
					return false;
				});
			});
			if (seriesIndex != undefined && dataPointIndex != undefined && (selectedSeriesInd.current != seriesIndex || selectedDataInd.current != dataPointIndex)) {
				ref.current.chart.toggleDataPointSelection(seriesIndex, dataPointIndex);
			}
		}
		skip.current = false;
	}, [props.selectedInterval]);

	useEffect(() => {
		if (props.data && props.data.length > 0) {
			const series = [];
			let data = []
			for (
				let i = props.retrospective ? props.data.length - 1 : 0;
				props.retrospective ? i >= 0 : i < props.data.length;
				props.retrospective ? i-- : i++
			) {
				const fuelLevel = props.data[i];
				const next = props.retrospective ? props.data[i - 1] : props.data[i + 1];
				if (next) {
					data.push({ x: fuelLevel.since.generatedAt, y: fuelLevel.level });
					if (fuelLevel.until && (fuelLevel.until.getTime() != next.since.generatedAt.getTime())) {
						data.push({ x: fuelLevel.until, y: fuelLevel.lastLevel });
						series.push({ type: 'line', data });
						data = [];
					}
				} else {
					data.push({ x: fuelLevel.since.generatedAt, y: fuelLevel.level });
					data.push({ x: fuelLevel.lastAt, y: fuelLevel.lastLevel });
				}
			}
			if (data.length > 0) {
				series.push({ type: 'line', data });
			}
			const dataPointSelection = (event, chartContext, config) => {
				const selectedDataPoint = series[config.seriesIndex].data[config.dataPointIndex];
				const selectedInterval = props.data.find(interval => {
					return (
						interval.since.generatedAt.getTime() == selectedDataPoint.x.getTime() && interval.level == selectedDataPoint.y
						|| interval.lastLevel == selectedDataPoint.y && (interval.until ? interval.until.getTime() == selectedDataPoint.x.getTime() : interval.lastAt.getTime())
					);
				});
				skip.current = true;
				if (selectedSeriesInd.current != config.seriesIndex || selectedDataInd.current != config.dataPointIndex) {
					selectedSeriesInd.current = config.seriesIndex;
					selectedDataInd.current = config.dataPointIndex;
					props.onSelect(selectedInterval);
				} else {
					selectedSeriesInd.current = null;
					selectedDataInd.current = null;
					props.onSelect(null);
				}

			}
			const options = {
				colors: [() => colorPrimary],
				dataLabels: {
					style: {
						fontSize: '15px',
						fontFamily: 'inherit',
						fontWeight: 'bold',
					},
					dropShadow: true
				},
				plotOptions: {
					bar: {
						columnWidth: '40%',
						distributed: true,
						dataLabels: { position: 'top' }
					}
				},
				chart: {
					animations: { enabled: false },
					events: { dataPointSelection },
					toolbar: {
						offsetY: 10,
						export: {
							csv: {
								headerCategory: fc('datetime'),
								dateFormatter: (timestamp) => formatter.format(new Date(timestamp))
							}
						},
						autoSelected: "pan"
					}
				},
				fill: { opacity: 1 },
				legend: { show: false },
				markers: { size: 5, strokeColors: '#eff9f9' },
				stroke: { width: 2 },
				tooltip: {
					shared: false,
					intersect: true,
					x: {
						show: true,
						format: 'dd MMM',
						formatter: datetime => formatter.format(new Date(datetime)),
					},
					y: {
						formatter: ser => ser + f({ prefix: 'units', id: '%'}),
						title: { formatter: ser => fc('fuel level') }
					}
				},
				xaxis: {
					type: 'datetime',
					position: 'bottom',
					axisBorder: { show: false },
					axisTicks: { offsetX: 1, offsetY: -1 },
					tooltip: { enabled: false },
					labels: {
						trim: true,
						style: { fontWeight: 'bold', fontFamily: 'inherit' },
						offsetX: 10,
						formatter: (ms) => formatter.format((new Date(ms)))
					}
				},
				yaxis: {
					axisBorder: { show: true },
					forceNiceScale: true,
					min: 0,
					max: (max) => max === 1 ? max + 2 : Math.ceil(max * 1.01),
					labels: {
						show: true,
						style: { fontWeight: 'bold', fontFamily: 'inherit' }
					}
				},
				title: {
					text: fc('fuel level change chart'),
					align: 'left',
					offsetX: 5,
					offsetY: 10,
					style: {
						fontSize: '13px',
						color: textColorMain,
						fontFamily: 'inherit'
					}
				}
			}
			setState({ series, options });
		}
	}, [props.data]);

	if (state.options && state.series) {
		content = (
			<Chart
				options={state.options}
				series={state.series}
				type="line"
				width="100%"
				height={280}
				ref={ref}
			/>
		);
	}

	return (
		<div>{content}</div>
	);
}

export default CheckIntervalLevelsChart;