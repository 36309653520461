export const binarySearch = (array, filter) => {
	const result = [];
	let start = 0;
	let middle = 0;
	let end = array.length - 1;
	while (start <= end && result.length == 0) {
		middle = Math.floor((end + start) / 2);
		const _filter = filter(array[middle]);
		if (_filter < 0) {
			start = middle + 1;
		} else if (0 < _filter) {
			end = middle - 1;
		} else {
			result.push(array[middle]);
		}
	}
	if (result.length == 1) {
		let toLeft = middle != 0;
		let toRight = middle != array.length - 1;
		for (let iL = middle - 1; 0 < iL && toLeft; --iL) {
			if (filter(array[iL]) == 0) {
				result.unshift(array[iL]);
			} else toLeft = false;
		}
		for (let iR = middle + 1; iR < array.length -1 && toRight; ++iR) {
			if (filter(array[iR]) == 0) {
				result.push(array[iR]);
			} else toRight = false;
		}

	}
	return [ result, middle ];
}