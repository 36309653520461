import React, { useRef, useState, useEffect, useContext, useLayoutEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { List } from 'rsuite';
import { useDrop } from 'react-dnd';
import { useI18n } from '../../../../../i18n';
import { DragItemType } from '../../../share/dnd/DragItemType';
import DeviceList from '../devices/DeviceList';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { cx } from '../../../../api';
import { actions as devicesActions } from '../../../../redux/api/devices';
import useLocalStorage from '../../../../misc/useLocalStorage';
import { getDevicesList, getDevicesCategoryList } from '../../../../redux/app/deviceFilter';
import { actions as contextActions } from '../../../../redux/app/context';
import uncategorizedIcon from '../../../../img/icons/uncategorized.png';
import { getOwMap } from '../../../general/location/Map';
import { ReduxKeyContext } from '../../../../misc/ReduxKeyContext';
import { className } from '../../../../lib/className';

/**
 * @param {Object} props
 * @param {cx.ods.categories.CategoryData} [props.category] - null for uncategorized
 */

function CategoryListItem(props) {
	const { f } = useI18n();

	const deviceListRef = useRef();
	const reduxKey = useContext(ReduxKeyContext);
	const state = useSelector(state => state.pages[reduxKey]);

	const categoryId = props.category ? props.category.categoryId : null;
	const [usCollapsed, usSetCollapsed] = useState(false);
	const [ulsCollapsed, ulsSetCollapsed] = useLocalStorage('Category_' + categoryId);
	const collapsed = props.deviceFilter.name === '' ? ulsCollapsed : usCollapsed;
	const setCollapsed = props.deviceFilter.name === '' ? ulsSetCollapsed : usSetCollapsed;

	const lastSelected = state && state.lastSelected;

	useEffect(() => {
		usSetCollapsed(false);
	}, [props.deviceFilter.name]);


	// ------------- drop device -------------------

	const adoptDevice = (item, targetId) => {
		const categoryIds = item.device.categoryIds;
		if (item.categoryId === targetId) return;
		const deviceInfo = item.device.createInfo();
		if (item.categoryId) deviceInfo.categoryIds.splice(categoryIds.indexOf(item.categoryId), 1);
		if (targetId) deviceInfo.categoryIds.push(targetId);
		props.dispatch(devicesActions.update.request({ uri: item.device.uri, deviceInfo }));
	}

	const [stageDropDevice, dropDevice] = useDrop({
		accept: DragItemType.DEVICE,
		drop: item => adoptDevice(item, categoryId),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
	})

	const isActiveDevice = stageDropDevice.isOver && stageDropDevice.canDrop;

	// --------------- drop actions ------------------

	const adoptActions = (item, targetCategory) => {
		switch (item.type) {
			case DragItemType.ACTION_EDIT:
				props.dispatch(contextActions.actionSet({
					actionType: 'edit',
					name: 'category',
					data: {
						title: "group",
						offsetTop: cx.dom.at.client(document.getElementById('category-' + categoryId)).top,
						id: targetCategory.categoryId,
						elementHeight: document.getElementById('category-' + categoryId).clientHeight
					}
				}));
				break;
			case DragItemType.ACTION_REMOVE:
				props.dispatch(contextActions.actionSet({
					actionType: 'remove',
					name: 'category',
					data: {
						title: "group",
						offsetTop: cx.dom.at.client(document.getElementById('category-' + categoryId)).top,
						id: targetCategory.categoryId,
						elementHeight: document.getElementById('category-' + categoryId).clientHeight
					}
				}));
				break;
		}
	}

	const [stageDropActions, dropAction] = useDrop({
		accept: [DragItemType.ACTION_EDIT, DragItemType.ACTION_REMOVE],
		drop: item => adoptActions(item, props.category),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
	})

	const isActiveAction = stageDropActions.isOver && stageDropActions.canDrop;

	// ----------------- device list ------------------

	function getDevices() {
		return categoryId
			? getDevicesCategoryList(categoryId)
			: getDevicesList().filter(device => {
				let categories = device.categoryIds;
				let except = props.exceptCategories;
				if (categories && except) {
					let result = true;
					for (let i = 0; i < categories.length; i++) {
						for (let y = 0; y < except.length; y++) (except[y].categoryId === categories[i]) && (result = false);
					}
					return result;
				} else return true;
			})
		;
	}

	let deviceList = null;
	let devices = null;
	if (props.devices.categoriesMap != null) {
		devices = getDevices();
		if (devices && devices.length > 0) {
			deviceList = (<DeviceList
				devices={devices}
				customRef={deviceListRef}
				categoryId={categoryId}
			/>);
		}
	}

	// ---------------- expand / collapse ----------------

	const dirtyFocus = useRef(false);
	const containsSelecteDevice = lastSelected && devices && devices.find(device => device.uri == lastSelected.uri) != null;
	// tricky way to focus device list item only after device list already expanded (if it's collapsed)
	// useLayoutEffects fire before any useEffect but in same order (child -> parent)

	useLayoutEffect(() => {
		if (containsSelecteDevice) {
			dirtyFocus.current = true;
			if (collapsed) { // if collapsed - set dirty flag and update state
				setCollapsed(false);
			}
		}
	}, [lastSelected]);

	useEffect(() => {
		if (dirtyFocus.current && deviceListRef.current != null) {
			deviceListRef.current.focus(lastSelected.uri);
			dirtyFocus.current = false;
		}
	}, [dirtyFocus.current]);

	const focus = () => {
		const markersId = devices.map(device => device.uri);
		getOwMap(reduxKey).focusMarkers(markersId);
	}

	const toggleView = () => {
		setCollapsed(!collapsed)
	}

	const toggleIcon = collapsed ? (<FiPlus />) : (<FiMinus />);

	// ---------------------------------------------------

	return (
		<div ref={dropDevice}>
			<div ref={categoryId ? dropAction : null} id={'category-' + categoryId}>
				<List.Item className={className('category', { 'droppable': (isActiveDevice || isActiveAction) })}>
					<div className="content">
						<span className={className('category-icon', { 'dynamic': categoryId })} onClick={focus}>
							{props.at ? props.at : <img src={uncategorizedIcon} alt="" />}
							{categoryId && (<span className="badge"><span>{devices ? devices.length : 0}</span></span>)}
						</span>
						{!props.sidebarCollapsed && <>
							<span className="category-name" onClick={focus}>{props.category ? props.category.name : f('uncategorized')}</span>
							<span className="toggle-view" onClick={toggleView}>
								{toggleIcon}
							</span>
						</>}
					</div>
				</List.Item>
			</div>
			{!props.sidebarCollapsed &&
				<List.Item className={className('sub-list', { 'collapsed': collapsed })}>
					{deviceList}
				</List.Item>
			}
		</div>
	);
}

export default connect(
	state => ({
		devices: state.devices,
		deviceFilter: state.deviceFilter,
		sidebarCollapsed: state.context.sidebarCollapsed
	})
)(CategoryListItem);
