import { from } from 'rxjs';
import { filter, groupBy, ignoreElements, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { combineEpics, ofType } from 'redux-observable';

import { LogLevel, rootLogger } from 'core/lib/log';

import { cx } from "core/api";
import { rs } from 'core/rs';

import { ActionGeneratorBuilder, deltaReducer, errorMap } from 'core/redux/actions';
import { timestamps } from 'core/redux/serializers';

import { actions as announcerActions } from 'core/redux/api/announcer';

const logger = rootLogger.logger('veta-runways').at(LogLevel.Verbose);

export const actions = new ActionGeneratorBuilder('veta-runways')
	.subtype('load', load => load.request().success('runways').fail())
	.subtype('operation', operation => operation
		.type('status', {runwayId: true, open: true})
		.success({runwayId: true}).fail({runwayId: true, errorMessage: true})
	)
	.build()
;

const defaultState = {
	list: null
	, map: null
	, pending: false
	, error: null
	, operations: {}
};

export const reducer = deltaReducer((state, action) => {
	switch (action.type) {
		case actions.load.request.type: return {
			pending: true
			, error: null
		};
		case actions.load.success.type: return {
			pending: false
			, list: action.runways
			, map: Object.fromEntries(action.runways.map(runway => [runway.id, runway]))
		};
		case actions.load.fail.type: return {
			pending: false
			, error: action.errorMessage
		};
		case actions.operation.status.type: return {
			operations: {...state.operations, [action.runwayId]: {
				pending: true
				, error: null
			}}
		};
		case actions.operation.success.type: return {
			operations: {...state.operations, [action.runwayId]: {
				pending: false
			}}
		};
		case actions.operation.fail.type: return {
			operations: {...state.operations, [action.runwayId]: {
				pending: false
				, error: action.errorMessage
			}}
		};
	}
	return null;
}, defaultState);

const internalize = timestamps(['createdAt', 'modifiedAt']).internalize;

export const epic = combineEpics(
	action$ => action$.pipe(
		ofType(actions.load.request.type)
		, switchMap(() => rs('veta/airside/runway').defer$().pipe(
			map(runways => runways.map(internalize))
			, map(runways => actions.load.success({runways}))
			, errorMap(actions.load.fail)
		))
	)
	, (action$, state$) => action$.pipe(
		ofType(announcerActions.announced.type)
		, withLatestFrom(state$.pipe(map(state => state.veta.runways)))
		, filter(([_, state]) => state.list != null)
		, mergeMap(([action]) => from(action.announcements).pipe(
			filter(announcement => cx.o.typeOf(announcement, cx.ods.veta.RunwayStatusChangeAnnouncement))
			, take(1)
		))
		, map(() => actions.load.request())
	)
	, action$ => action$.pipe(
		ofType(actions.operation.status.type)
		, groupBy(action => action.runwayId)
		, mergeMap(action$ => action$.pipe(
			switchMap(action => rs(`veta/airside/runway/${action.runwayId}/status`).put({open: action.open}).defer$().pipe(
				map(() => actions.operation.success({runwayId: action.runwayId}))
				, errorMap(error => actions.operation.fail({runwayId: action.runwayId, errorMessage: error.errorMessage}))
			))
		))
	)
	, (action$, state$) => action$.pipe(
		ofType(actions.load.success.type)
		, filter(_ => logger.loggable(LogLevel.Debug))
		, withLatestFrom(state$.pipe(map(state => state.veta.runways)))
		, tap(([_, state]) => {
			logger.debug('runways', state.list);
		})
		, ignoreElements()
	)
);

