import React, { useRef, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import List from '../../../../general/list/List';
import GroupDeviceEventsView from './GroupDeviceEventsView';
import NoDataView from '../../../../general/view/NoDataView';
import * as timeMachineState from '../../../../../redux/app/timeMachine/state';
import { className } from '../../../../../lib/className';

/**
 * @param {Object} props
 * @param {Array.<string>} props.uris
 * @param {Array.<number||string>} [props.categotyIdsFilter]
 * @param {Array.<number||string>} [props.eventTypesFilter]
 * @param {function} [props.onLoad] return quantity events
 */

function DeviceEventList(props) {
	const focusDeviceEvent = useRef();
	let deviceEvents = [];

	useLayoutEffect(() => {
		if (!props.eventsScope.pending) {
			props.onLoad && props.onLoad(deviceEvents.length);
		}
	}, [props.uris?.length, props.since, props.until]);

	const hasCategoryFilter = props.categotyIdsFilter && props.categotyIdsFilter.length > 0;
	const hasEventTypeFilter = props.eventTypesFilter && props.eventTypesFilter.length > 0;

	const getSortedByGroups = (deviceEvents) => {
		const groups = [];
		let group = null;
		deviceEvents.forEach(deviceEvent => {
			if (!group) {
				group = {
					uri: deviceEvent.uri,
					events: [deviceEvent.event]
				}
			} else {
				if (deviceEvent.uri == group.uri) {
					group.events.push(deviceEvent.event);
				} else {
					groups.push(group);
					group = {
						uri: deviceEvent.uri,
						events: [deviceEvent.event]
					}
				}
			}

		});
		group && groups.push(group);
		return groups;
	}

	const inRange = (message) => {
		return props.since <= message.generatedAt && message.generatedAt <= props.until;
	}

	const isMatched = (deviceEvent) => {
		let matched = false;
		if (hasEventTypeFilter) {
			matched = props.eventTypesFilter.some(eventType => deviceEvent.eventType == eventType);
		}
		if (!matched && hasCategoryFilter) {
			const categoryIds = props.eventTypeMap[deviceEvent.eventType] && props.eventTypeMap[deviceEvent.eventType].categoryIds;
			if (categoryIds) {
				matched = props.categotyIdsFilter.some(id => categoryIds.some(categoryId => categoryId == id));
			}
		}
		if (!matched && !hasEventTypeFilter && !hasCategoryFilter) {
			matched = true;
		}
		return matched;
	}

	const onDeviceEventsClick = (event, uri) => {
		const now = event && event.generatedAt;
		if (now) {
			props.dispatch(timeMachineState.actions.setNow({ now }));
		}
	}

	const compareDeviceEvents = (left, right) => left.event.generatedAt.getTime() - right.event.generatedAt.getTime();

	if (props.eventsScope.map != null && props.eventTypeMap) {
		props.uris.forEach(uri => {
			const scopes = props.eventsScope.map[uri];
			if (scopes) {
				scopes.forEach(scope => {
					if (scope.events) {
						scope.events.forEach(event => {
							if (inRange(event) && isMatched(event)) deviceEvents.push({ uri, event });
						});
					}
				});
			}
		});
		deviceEvents.sort(compareDeviceEvents);
		deviceEvents = getSortedByGroups(deviceEvents);
	}

	const hasDeviceEvent = (event, uri) => {
		return deviceEvents.some(deviceEvent => {
			const events = deviceEvent.events;
			return deviceEvent.uri == uri && events.some(_event => _event.eventId == event.eventId);
		});
	}
	if (props.deviceStatesMap != null && props.uris) {
		const deviceStateDeviceEvents = [];
		Object.keys(props.deviceStatesMap).forEach(uri => {
			if (props.uris.includes(uri) && props.deviceStatesMap[uri].events) {
				props.deviceStatesMap[uri].events.forEach(event => {
					if (hasDeviceEvent(event, uri)) {
						deviceStateDeviceEvents.push({ uri, event });
					}
				});
			}
		});
		deviceStateDeviceEvents.sort(compareDeviceEvents);
		const isSame = (deviceEvent) => {
			if (!focusDeviceEvent.current && deviceEvent || focusDeviceEvent.current && !deviceEvent) return false;
			if (!focusDeviceEvent.current && !deviceEvent) return true;
			return focusDeviceEvent.current.uri == deviceEvent.uri && focusDeviceEvent.current.event.eventId == deviceEvent.event.eventId;
		}
		if (!isSame(deviceStateDeviceEvents[deviceStateDeviceEvents.length - 1])) {
			focusDeviceEvent.current = deviceStateDeviceEvents[deviceStateDeviceEvents.length - 1];
		}
	}

	const empty = !props.eventsScope.pending && deviceEvents.length == 0;

	const getEventList = () => {
		if (empty) {
			return <NoDataView />;
		}
		return deviceEvents.map((deviceEvent, i) => {
			const key = deviceEvent.events[0] ? deviceEvent.events[0].generatedAt.getTime() + deviceEvent.uri : deviceEvent.uri;
			return <GroupDeviceEventsView
				key={key}
				focusDeviceEvent={focusDeviceEvent.current}
				onClick={onDeviceEventsClick}
				deviceEvents={deviceEvent}
				hideHeader={props.uris.length == 1}
			/>;
		});
	}

	return (
		<List className={className({ 'empty': empty })} pending={props.eventsScope.pending}>
			{getEventList()}
		</List>
	);
}

export default connect(
	state => ({
		eventsScope: state.history.events,
		eventTypeMap: state.registry.eventTypes.typeMap,
		deviceStatesMap: state.timeMachine.state.map,
		since: state.timeMachine.state.parameters && state.timeMachine.state.parameters.since,
		until: state.timeMachine.state.parameters && state.timeMachine.state.parameters.until
	})
)(DeviceEventList);
