import { actions } from '../actions';
import { reduxSwitch } from '../../../tools';

const defaultState = {
	preview: null,
	list: null,
	parameters: null,
	hasMore: null,
	pending: false,
	error: null,
	at: null,
	// export
	exporting: false,
	progress: null, // { percent, done, total }
	csv: null,
};

const updateAnchor = (parameters, situations) => {
	parameters.anchorOn(situations);
	return parameters;
}

const appendSituations = (list, situations) => {
	if (list.length == 0) return situations;
	if (situations.length == 0) return list;
	const lastItem = list[list.length - 1], firstSituations = situations[0];
	const lastMarker = lastItem.marker, firstMarker = firstSituations.marker;
	const differs = lastItem.uri != firstSituations.uri
		|| lastMarker == null != firstMarker == null
		|| lastMarker != null && lastMarker.eventId != firstMarker.eventId
	;
	if (differs) return list.concat(situations);
	lastItem.events = lastItem.events.concat(firstSituations.events);
	if (1 < situations.length) return list.concat(situations.slice(1));
	return list;
}

function apiReducer(state = defaultState, action) {
	let _state;
	switch (action.type) {
		case actions.deviceSituations.request.type:
			if (action.clear) {
				_state = { ...defaultState };
			} else {
				_state = {...state};
			}
			return {
				..._state,
				parameters: action.parameters,
				pending: true,
				error: null,
				at: action.clear ? new Date() : _state.at
			};
		case actions.deviceSituations.success.type:
			const situations = action.situations;
			// const batchSize = state.parameters.timeRange.window.size;
			// const events = action.situations[action.situations.length -1].events;
			// const hasMore = events ? events.length == batchSize : false;
			const hasMore = situations ? situations.length > 0 : false;
			// const list = state.list ? (situations ? state.list.concat(situations) : state.list) : (situations ? [...situations] : []);
			const list = state.list ? (situations ? appendSituations(state.list, situations) : state.list) : (situations ? [...situations] : []);
			_state = {...state};
			return {
				..._state,
				list,
				hasMore,
				parameters: updateAnchor(state.parameters, situations),
				pending: false
			};
		case actions.deviceSituations.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		case actions.deviceSituations.cancel.type:
			return {
				...state,
				pending: false
			};
		default:
			return state;
	}
}

function exportReducer(state, action) {
	switch (action.type) {
		case actions.deviceSituations.export.type:
			return {
				...state,
				exporting: true,
				progress: null,
				csv: null
			}
		case actions.deviceSituations.exportProgress.type:
			return {
				...state,
				progress: {
					percent: action.progress,
					done: action.count,
					total: action.total
				}
			}
		case actions.deviceSituations.exportDone.type:
			return {
				...state,
				parameters: state.parameters,
				exporting: false,
				csv: action.csv
			}
		case actions.deviceSituations.exportClear.type:
			return {
				...state,
				csv: null
			}
		default:
			return state;
	}
}

const reducer = reduxSwitch([apiReducer, exportReducer], defaultState);

export { reducer };
