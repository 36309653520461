import React from 'react';
import { Container, Content } from 'rsuite';
import WorkspaceLayout from './WorkspaceLayout';

/**
 * @param {Object} props
 * @param {React.Component} props.sidebar
 */

function SidebarLayout(props) {

	return (
		<WorkspaceLayout>
			{props.sidebar}
			<Container>
				<Content>{props.children}</Content>
			</Container>
		</WorkspaceLayout>
	);
}

export default SidebarLayout;