import { actions } from './actions';

/*
	{uri => {
		{uid => {
			list: [EventDetails],
			filter: {}
			pending: boolean,
			error: string
		}}
	}}
*/

const defaultState = {};

function getDefaultState(filter) {
	return {
		list: null,
		filter,
		pending: true,
		error: null
	};
}

export const reducer = (state = defaultState, action) => {
	let copy = null;
	switch (action.type) {
		case actions.find.request.type:
			copy = { ...state };
			const byUri = copy[action.uri];
			const byUid = byUri && byUri[action.uid];
			if (!byUri) { // new
				copy[action.uri] = {
					[action.uid]: getDefaultState(action.filter)
				};
			} else if (!byUid) { // new instance
				byUri[action.uid] = getDefaultState(action.filter);
			} else { // existing instance
				byUid.pending = true;
				byUid.error = null;
			}
			return copy;
		case actions.find.success.type:
			copy = { ...state };
			copy[action.uri] = {
				...copy[action.uri],
				[action.uid]: {
					...copy[action.uri][action.uid],
					list: action.list,
					pending: false
				}
			}
			return copy;
		case actions.find.fail.type:
			copy = { ...state };
			copy[action.uri] = {
				...copy[action.uri],
				[action.uid]: {
					...copy[action.uri][action.uid],
					pending: false,
					error: action.errorMessage
				}
			}
			return copy;
		case actions.find.cancel.type: // TODO
			return state;
		case actions.clear.type:
			copy = { ...state };
			if (copy[action.uri] && copy[action.uri][action.uid]) {
				delete copy[action.uri][action.uid];
				if (Object.keys(copy[action.uri]).length === 0) {
					delete copy[action.uri];
				}
			}
			return copy;
		default:
			return state;
	}
}
