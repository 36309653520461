import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Radio, RadioGroup } from 'rsuite';

import { useI18n } from 'i18n';
import { IAttributeDetectorForm } from 'hooks/attribute-range-detectors/useAttributeDetectorForm';

interface IEnumerationSelectProps {
  isDisabled: boolean;
}

const ENUMERATION_OPTIONS = [
  { value: 'true', label: 'on' },
  { value: 'false', label: 'off' },
];

export const EnumerationSelect: FC<IEnumerationSelectProps> = ({ isDisabled }) => {
  const { fc } = useI18n();

  const { control } = useFormContext<IAttributeDetectorForm>();

  return (
    <div>
      <label>{fc('range values')}</label>
      <Controller
        control={control}
        name="enumeration"
        render={({ value, onChange }) => (
          <RadioGroup value={value} onChange={onChange}>
            {ENUMERATION_OPTIONS.map((option) => (
              <Radio key={option.label} value={option.value} disabled={isDisabled}>
                {fc(option.label)}
              </Radio>
            ))}
          </RadioGroup>
        )}
      />
    </div>
  );
};
