import React from 'react';
import { Navbar } from 'rsuite';
import { NavLink } from 'react-router-dom';
import { useI18n } from '../../../../i18n';
import logo from '../../../img/logo.png';
import AdaptiveGlobalMenu from './AdaptiveGlobalMenu';

function Header() {
	const { fc } = useI18n();
	return (
		<div className="header" appearance="subtle">
			<Navbar.Header>
				<NavLink to="/dashboard" title={fc('dashboard')}>
					<img src={logo} alt="" />
				</NavLink>
			</Navbar.Header>
			<Navbar.Body>
				<AdaptiveGlobalMenu />
			</Navbar.Body>
		</div>
	);
}

export default Header;