import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { sortByName } from '../../../lib/sorting';
import { actions as registryActions } from '../../../redux/api/registry';
import InputPicker from '../../general/form/InputPicker';
import Loader from '../../general/Loader';

/**
 * @param {Object} props
 * @param {number} props.value
 * @param {function} props.onChange
 * @param {boolean} [props.disabled]
 * @param {string} [props.placeholder]
 * @param {string} [props.label]
 */

function CountryPicker(props) {
	const { f } = useI18n();

	useEffect(() => {
		if (props.countries.list == null) {
			props.dispatch(registryActions.countries.request());
		}
	}, []);

	let countries = null;
	if (props.countries.list != null) {
		countries = sortByName(props.countries.list).map(country => ({ value: country.code, label: f(country.name) }));
	}

	const content = countries
		? <InputPicker
			block
			data={countries}
			placeholder={props.placeholder}
			onChange={props.onChange}
			disabled={props.disabled}
			value={+props.value}
		/>
		: <div className="loader"><Loader size={Loader.Size.MD} /></div>
	;

	return (
		<div className="country-picker">
			{props.label && <label>{props.label}</label>}
			{content}
		</div>
	);
}

export default connect(state => {
	return {
		countries: state.registry.countries
	}
})(CountryPicker);