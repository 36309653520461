import styled, { css } from 'styled-components';

import { SvgWrapper } from 'components/atoms/svg-wrapper';
import { Colors } from 'ui/constants/colors';

interface IDropdownStyledProps {
  rounded?: boolean;
  isPlaceholder?: boolean;
}

interface IDropdownContainerStyledProps {
  isDisabled?: boolean;
  isError?: boolean;
}

interface IDropdownOptionProps {
  isSelected?: boolean;
}

export const SelectStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SelectContentStyled = styled.div<IDropdownContainerStyledProps>`
  cursor: pointer;
  position: relative;
  width: 100%;
  font: 12px Muli;
  box-sizing: border-box;

  ${({ isError }) =>
    isError &&
    css`
      border: 1px solid ${Colors.RossoCorsa};
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      color: ${Colors.ShadowMountain};

      & > div {
        pointer-events: none;
        background-color: ${Colors.SnowFlake};
        border: none;

        & > input {
          background-color: ${Colors.SnowFlake};
          color: ${Colors.ShadowMountain};
        }
      }
    `}
`;

export const SelectHeaderStyled = styled.div<IDropdownStyledProps>`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${Colors.Black};
  height: 30px;
  background-color: ${Colors.White};
  outline: 0;
  font: 12px Muli;
  padding: 0 10px;

  span {
    ${({ isPlaceholder }) =>
      isPlaceholder &&
      css`
        color: ${Colors.Pewter};
      `}
  }

  ${({ rounded }) =>
    rounded &&
    css`
      justify-content: center;
      border-radius: 20px;
      padding: 0;
      border: 1px solid ${Colors.SilverSand};
      height: 32px;
      color: ${Colors.Boulder};
    `}
`;

export const SelectOptionsStyled = styled.ul<IDropdownStyledProps>`
  margin: 5px 0;
  position: absolute;
  z-index: 10;
  width: 100%;
  padding: 0;
  background: ${Colors.White};
  box-sizing: border-box;
  color: ${({ rounded }) => (rounded ? Colors.Boulder : Colors.Black)};
  font: 12px Muli;
  border-radius: ${({ rounded }) => (rounded ? '5px' : 0)};
  overflow-y: auto;
  max-height: 200px;
  box-shadow: 0 0 6px ${Colors.BlackRussian};

  scrollbar-color: ${Colors.Alto} ${Colors.WhiteSmoke};

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-clip: padding-box;
    background: ${Colors.White};
    border-right: 2px solid ${Colors.WhiteSmoke};
    border-left: 2px solid ${Colors.WhiteSmoke};
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
    background: ${Colors.WhiteSmoke};
  }
`;

export const SelectOptionStyled = styled.li<IDropdownOptionProps>`
  list-style: none;
  padding: 10px;
  background-color: ${({ isSelected }) => (isSelected ? Colors.PinkSmoke : Colors.White)};

  &:hover {
    background-color: ${Colors.Concrete}CC;
  }
`;

export const SelectIconStyled = styled(SvgWrapper)`
  margin-left: 6px;
`;

export const SelectNoOptionsStyled = styled(SelectOptionsStyled)`
  display: flex;
  justify-content: center;
  align-items: center;
  font: 14px Muli;
  height: 100px;
`;
