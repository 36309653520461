import { ofType, combineEpics } from 'redux-observable';
import { switchMap, takeUntil, map as rxmap, filter, tap, ignoreElements } from 'rxjs/operators';
import { api, rx } from "../../../../api";
import { formatDateTime, getTimezoneString } from '../../../../misc/misc';
import { resolver } from './resolver';
import { actions } from '../actions';
import { fc } from '../../../../../i18n';
import { errorMap } from '../../../actions';

const requestEpic = (action$) => {
	return action$.pipe(
		ofType(actions.ridership.request.type),
		switchMap(action =>
			rx(api.reports.ridership, action.uri, action.parameters).pipe(
				rxmap(operation => actions.ridership.success({ report: operation.response() })),
				errorMap(actions.ridership.fail),
				takeUntil(action$.pipe(ofType(actions.ridership.cancel.type)))
			)
		)
	)
}

const startPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.ridership.export.type),
		rxmap(action => {
			const state = state$.value.reports.ridership;
			resolver.resolve(state.list, !state.hasMore);
			if (state.hasMore) {
				return actions.ridership.request({ uri: action.uri, parameters: state.parameters });
			} else {
				return actions.ridership.success({});
			}
		})
	)
}

const processPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.ridership.success.type),
		filter(action => {
			const state = state$.value.reports.ridership;
			if (state.exporting) resolver.resolve(action.report && action.report.entries, !state.hasMore);
			return state.exporting && state.hasMore;
		}),
		rxmap(action => {
			const state = state$.value.reports.ridership;
			return actions.ridership.request({ uri: state.uri, parameters: state.parameters });
		})
	)
}

const exportCsvEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.ridership.exportProgress.type),
		filter(action => action.progress == 100),
		rxmap(action => {
			const state = state$.value.reports.ridership;
			const device = state$.value.devices.map[state.uri];
			// report header
			let csv = '"'
				+ fc('report type')
				+ '",'
				+ fc('generated')
				+ ','
				+ fc('timezone')
				+ ','
				+ fc('device')
				+ ','
				+ fc('since')
				+ ','
				+ fc('until')
				+ '\n';
			csv += fc('ridership');  // report type
			csv += ',' + formatDateTime(state.at); // generated at
			csv += ',' + getTimezoneString(); // timezone at
			csv += ',"' + (device.denomination() || device.uri) + '"';
			csv += ',"' + formatDateTime(state.parameters.timeRange.since) + '"';
			csv += ',"' + formatDateTime(state.parameters.timeRange.until) + '"';
			csv += "\n\n";
			// content header
			csv += '"'
				+ fc('time')
				+ '","'
				+ fc('location')
				+ '","'
				+ fc('address')
				+ '","'
				+ fc('boarded')
				+ '","'
				+ fc('alighted')
				+ '","'
				+ fc('aboard')
				+ '"\n';
			// content
			state.list.forEach(entry => {
				csv += '"' + formatDateTime(entry.event.generatedAt) + '"';
				const message = entry.event && entry.event.message;
				if (message != null && message.latitude != null  && message.longitude != null) {
					csv += ',"(' + message.latitude + ";" + message.longitude + ')"';
					const entryKey = resolver.key(message.latitude, message.longitude);
					const address = resolver.resolved[entryKey]?.getAddress();
					if (address) csv += ',"' + address.format() + '"';
					else csv += ",-";
				} else {
					csv += ",-,-";
				}
				csv += ',' + (entry.ingress == null ? ' ' : entry.ingress);
				csv += ',' + (entry.egress == null ? ' ' : entry.egress);
				csv += ',' + (entry.passengers == null ? ' ' : entry.passengers);
				csv += "\n";
			});
			return actions.ridership.exportDone({ csv });
		}),
	)
}

const exportClearEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.ridership.exportClear.type),
		tap(() => resolver.clear()),
		ignoreElements()
	);
}

const epic = combineEpics(requestEpic, startPrepareEpic, processPrepareEpic, exportCsvEpic, exportClearEpic);

export { epic };
