import styled, { css, keyframes } from 'styled-components';

import { Colors } from 'ui/constants/colors';
import { SvgWrapper } from 'components/atoms/svg-wrapper';

interface INotificationStyledProps {
  isSevere: boolean;
  isClosing: boolean;
}

interface INotificationHeaderStyledProps {
  isSevere: boolean;
}

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
`;

export const NotificationStyled = styled.div<INotificationStyledProps>`
  box-sizing: border-box;
  position: relative;
  width: 250px;
  font-size: 10px;
  padding: 11px 14px;
  margin-bottom: 10px;
  transition: transform 0.3s ease-out;
  cursor: default;

  &:first-child {
    margin-bottom: 15px;
  }

  ${({ isSevere }) =>
    isSevere
      ? css`
          color: ${Colors.White};
          background-color: ${Colors.Akabeni};
          box-shadow: 0 0 3px 3px ${Colors.ShadowedAkabeni};

          &:first-child {
            margin-bottom: 20px;
          }
        `
      : css`
          color: ${Colors.DoveGray};
          background-color: ${Colors.White};
          box-shadow: 0 3px 6px ${Colors.BlackRussian};
          border: 0.25px solid ${Colors.BlueSmoke};
        `}

  ${({ isClosing }) =>
    isClosing
      ? css`
          transform: translateX(150%);
          flex: 0;
        `
      : css`
          animation-name: ${slideIn};
          animation-duration: 0.3s;
          animation-timing-function: ease-in-out;
        `}
`;

export const NotificationHeaderStyled = styled.div<INotificationHeaderStyledProps>`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: ${({ isSevere }) => (isSevere ? '12px' : '10px')};

  & > div {
    max-width: 190px;
  }

  svg {
    margin-right: 6px;
  }
`;

export const NotificationDateStyled = styled.div`
  font-size: 8px;
  margin-top: 10px;
`;

export const NotificationCloseButtonStyled = styled(SvgWrapper)`
  position: absolute;
  top: 12px;
  right: 9px;
  cursor: pointer;
`;

export const NotificationsButtonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  background-color: ${Colors.White};
  box-shadow: 0 3px 6px ${Colors.BlackRussian};
  border: 0.25px solid ${Colors.BlueSmoke};
  cursor: pointer;
  transition: 0.2s;
  opacity: 0;
  transform: translateY(-10px);

  &.enter,
  &.entered {
    opacity: 1;
    transform: translateY(0px);
  }
  &.exit,
  &.exited {
    opacity: 0;
    transform: translateY(-10px);
  }
`;
