import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { List } from 'rsuite';
import { useI18n } from '../../../../i18n';
import { actions as latestEventsActions } from '../../../redux/api/latestEvents';
import Loader from '../../general/Loader';
import RecentEventListItem from './RecentEventListItem';
import NoDataView from '../../general/view/NoDataView';
import './recentEventList.scss';

/**
 * @param {Object} props
 * @param {string} [props.uri] if present - show only events for uri
 * @param {number} [props.count] number of latest events to display
 */

function RecentEventList(props) {
	const { f } = useI18n();
	const latestCount = props.count ? props.count : 5;

	useEffect(() => {
		if (props.uri) {
			props.dispatch(latestEventsActions.findForUri.request({ uri: props.uri, size: latestCount }));
		}
	}, [props.uri]);

	const recentForAll = (count) => {
		let events = [];
		Object.keys(props.latestEvents.map).forEach(uri => {
			events = events.concat(props.latestEvents.map[uri]);
		});
		events.sort((left, right) => right.generatedAt.getTime() - left.generatedAt.getTime());
		return events.slice(0, count);
	}

	const recentForUri = (uri, count) => {
		const events = props.latestEvents.map[uri];
		return events ? events.slice(0, count) : [];
	}

	let content = null;
	if (props.latestEvents.recentMap != null) {
		const events = props.uri
			? recentForUri(props.uri, latestCount)
			: recentForAll(latestCount)
		;
		const items = events.map(event =>
			<RecentEventListItem
				key={event.eventId}
				event={event}
				showDeviceName={!props.uri}
			/>
		);
		content = items.length > 0
			? (<List className="recent-event-list">{items}</List>)
			: (<NoDataView message={f('no events')} />)
		;
	} else {
		content = (<Loader size={Loader.Size.MD} />);
	}

	return (<div className="recent-event-list-wrap">{content}</div>);
}

export default connect(state => {
	return {
		latestEvents: state.latestEvents
	}
})(RecentEventList);
