import React from 'react';
import { URL_DELIMITER } from '../../../../misc/url';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DeviceMapMarker from '../../map/DeviceMapMarker';

/**
 * @param {Object} props
 * @param {string} props.uri
 * @param {cx.ods.devices.MessageDetails} [props.message] marker will not be rendered if absent
 * @param {boolean} [props.alwaysCenter]
 * @param {function} [props.onClick]
 * @param {Map} props.map implicitly passed by Map
 */

function HistoryMapMarker(props) {
	const uris = props.match.params.uris ? props.match.params.uris.split(URL_DELIMITER) : [];
	const selected = props.device && uris.includes(props.uri);

	let marker = null;
	if (props.message) {
		const message = props.message;
		if (message && message.longitude && message.latitude) {
			const coordinates = [message.longitude, message.latitude];
			marker = (<DeviceMapMarker
				status={props.status && props.status.toLowerCase()}
				map={props.map}
				coordinates={coordinates}
				alwaysCenter={props.alwaysCenter}
				device={props.device}
				heading={message.heading}
				selected={selected}
				params={props.params}
			/>);
		}
	}

	return (marker);
}

export default connect(
	(state, props) => ({
		device: state.devices.map ? state.devices.map[props.uri] : null,
		status: state.timeMachine.deviceStatus.map
			&& state.timeMachine.deviceStatus.map[props.uri]
			&& state.timeMachine.deviceStatus.map[props.uri].status
	})
)(withRouter(HistoryMapMarker));
