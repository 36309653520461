import React, { FC, MutableRefObject } from 'react';

import { ReactPortal } from './react-portal';
import { ModalContentStyled, ModalWrapperStyled } from 'ui/molecules/modal-wrapper.styled';

interface IModalWrapperProps {
  modalRef?: MutableRefObject<HTMLDivElement | null>;
  children: JSX.Element;
}

export const ModalWrapper: FC<IModalWrapperProps> = ({ modalRef, children }) => (
  <ReactPortal>
    <ModalWrapperStyled ref={modalRef}>
      <ModalContentStyled>{children}</ModalContentStyled>
    </ModalWrapperStyled>
  </ReactPortal>
);
