import React from 'react';
import { Slider as RsSlider } from 'rsuite';
import { className, fromProps } from '../../../lib/className';
import './slider.scss';

/**
 * For other parameters - see rsuite Slider component
 * @param {Object} props
 * @param {string} [props.label]
 */

function Slider(props) {
	const { label, ..._props } = props;
	return (
		<div className={className('slider', fromProps(props))}>
			{label && <label>{label}</label>}
			<RsSlider {..._props} />
		</div>
	)
}

export default Slider;