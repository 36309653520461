import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';

import {useI18n} from '../../../../../i18n';

import {actions} from '../../../../redux/api/reports';
import {actions as zonesActions} from '../../../../redux/api/zones';
import {actions as guardsActions} from '../../../../redux/api/zoneGuards';

import {formatter as datetimeFormatter} from '../../../../misc/datetime';

import EventIcon from '../../../share/events/EventIcon';

import Address from '../../../general/location/Address';
import DatetimeField from '../../../general/datetime/DatetimeField';
import Loader from '../../../general/Loader';
import NoDataView from '../../../general/view/NoDataView';
import ScrollList from '../../../general/list/ScrollList';

import ReportHeader from '../renderer/ReportHeader';
import ReportSummary from '../renderer/ReportSummary';

import './renderer.scss';


export default withRouter(connect(state => ({
	state: state.reports.deviceStatus
	, deviceMap: state.devices.map
}))(props => {
	const {f} = useI18n();

	const reporting = props.state;
	const parameters = reporting.parameters;
	const devices = parameters.uris && parameters.uris.map(uri => props.deviceMap[uri]);
	const report = reporting.report;

	let content = null;
	if (reporting.error) content = <div className="center"><span className="error">{reporting.error}</span></div>;
	else if (report == null) content = <Loader size={Loader.Size.MD}/>;
	else if (report.statuses == null) content = <NoDataView message={f('no data for such parameters')}/>
	else content = <ReportContent pending={reporting.pending} parameters={parameters} report={report}/>;

	const exportReport = () => {
		props.dispatch(actions.deviceStatus.export.request());
		props.history.goBack();
	};

	return <div className="report device-status">
		<ReportHeader 
			title={f('device status report')}
			canExport={0 < report?.statuses?.length} onExport={exportReport}
		/>
		<ReportSummary generatedAt={report?.timeAt} devices={devices}/>
		<div className='content'>{content}</div>
	</div>;
}));


const ReportContent = props => {
	const parameters = props.parameters;
	const items = props.report.statuses.map((status, at) => {
		return <ListItem key={at} parameters={parameters} report={props.report} itemNo={at + 1} status={status}/>;
	});
	return <ScrollList pending={props.pending}>
		<ListHeaders parameters={parameters}/>
		{items}
	</ScrollList>;
};

const ListHeaders = ({parameters}) => {
	const {f} = useI18n();

	let column = 3;

	const gridRowStyle = parameters.zoneGuards && {gridRowEnd: 'span 2'};

	let positionHeader = null;
	if (parameters.position) {
		positionHeader = <div style={Object.assign({gridColumn: `${column} / span 2`}, gridRowStyle)}>
			<span>{f('location')}</span>
		</div>;
		column += 2;
	}

	let eventHeader = null;
	if (parameters.event) {
		eventHeader = <div style={Object.assign({gridColumn: `${column} / span 2`}, gridRowStyle)}>
			<span>{f('event')}</span>
		</div>;
		column += 2;
	}

	let vistisHeader = null;
	if (parameters.zoneGuards) {
		vistisHeader = <>
			<div className='group-header' style={{gridColumn: `${column} / span 3`}}><span>{f('zone visit')}</span></div>
			<div className='sub-header' style={{gridColumn: `${column}`}}><span>{f('entered')}</span></div>
			<div className='sub-header'><span>{f('staying for')}</span></div>
			<div className='sub-header'><span>{f('zone')}</span></div>
		</>;
		column += 3;
	}

	return <div className='headers'>
		<div style={gridRowStyle}><span>{f('item No.')}</span></div>
		<div style={gridRowStyle}><span>{f('device')}</span></div>
		{positionHeader}
		{eventHeader}
		{vistisHeader}
	</div>;
};

const ListItem = connect(state => ({
	deviceMap: state.devices.map
	, eventTypes: state.registry.eventTypes.typeMap
	, guardMap: state.zoneGuards.map
	, zoneMap: state.zones.map
}))(props => {
	const {f} = useI18n();

	const parameters = props.parameters;
	const report = props.report;
	const status = props.status;
	const device = props.deviceMap[status.uri];

	let now = null;
	let column = 3;

	let positionCell = null;
	if (parameters.position) {
		const message = status.state?.message;
		if (!message) positionCell = <div style={{gridColumnEnd: 'span 2'}}></div>;
		else {
			positionCell = <>
				<div className='time'><DatetimeField datetime={message.generatedAt}/></div>
				<div><Address message={message}/></div>
			</>;
			now = message.generatedAt;
		}
		column += 2;
	}

	let eventCell = null;
	if (parameters.event) {
		const event = status.state?.event;
		if (!event) eventCell = <div style={{gridColumnEnd: 'span 2'}}></div>;
		else {
			const eventType = props.eventTypes[event.eventType];
			eventCell = <>
				<div className='time'><DatetimeField datetime={event.generatedAt} now={now}/></div>
				<div className='name'>
					<EventIcon event={event}/>
					<span>{eventType && f({prefix: 'device-event', id: eventType.name})}</span>
				</div>
			</>;
			now = event.generatedAt;
		}
		column += 2;
	}

	useEffect(() => {
		if (!props.guardMap) props.dispatch(guardsActions.load.request());
		if (!props.zoneMap) props.dispatch(zonesActions.load.request());
	}, []);

	let visitCells = null;
	if (parameters.zoneGuards) {
		const zoneVisits = status.zoneVisits;
		if (!zoneVisits) visitCells = <div style={{gridColumnEnd: 'span 3'}}></div>;
		else {
			visitCells = zoneVisits.map((visit, at) => {
				const gridStyle = 0 < at ? {gridColumnStart: column} : null;
				const guard = props.guardMap?.[visit.processorId]?.name || `#${visit.processorId}`;
				const zone = props.zoneMap?.[visit.zoneId]?.name || `#${visit.zoneId}`;
				return <React.Fragment key={at}>
					<div style={gridStyle} className='time'><DatetimeField datetime={visit.enteredAt} now={now}/></div>
					<div>{datetimeFormatter.duration(visit.enteredAt, report.timeAt)}</div>
					<div>{zone} ({guard})</div>
				</React.Fragment>;
			});
			now = zoneVisits[zoneVisits.length - 1].enteredAt;
		}
		column += 3;
	}

	return <div className='item'>
		<div>{props.itemNo}</div>
		<div>{device?.denomination()}</div>
		{positionCell}
		{eventCell}
		{visitCells}
	</div>;
});
