import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Cell, CellProps } from 'react-table';
import moment from 'moment/moment';

import { EventCellStyled } from 'ui/pages/issues/issues.styled';
import { fc } from 'i18n';
import { IIssue } from 'types/issues';
import { formatter } from 'core/misc/datetime';
import { devicesStateSelector } from 'core/redux/selectors/devices';
import { issuesTypesStateSelector, resolutionTypesSelector } from 'core/redux/selectors/issues';
import { runwaysStateSelector } from 'core/redux/selectors/runways';
import { IssueActionForm } from 'components/organisms/issues/issue-action-form';
import { RuleGroup } from 'constants/issues';
import { taxiwaysSelector } from '../../core/redux/selectors/registry';

export const useIssuesColumns = (currentTabId: string) => {
  const { map: issuesTypes } = useSelector(issuesTypesStateSelector);
  const { map: resolutions } = useSelector(resolutionTypesSelector);
  const { map: devices } = useSelector(devicesStateSelector);
  const { map: runways } = useSelector(runwaysStateSelector);
  const { map: taxiways } = useSelector(taxiwaysSelector);
  const getRunwayCell = ({ cell }: CellProps<IIssue>) => (
    <>{currentTabId === RuleGroup.TAXIWAY ? taxiways?.[cell.value]?.name || '-' : runways?.[cell.value]?.name || '-'}</>
  );

  const getVehicleCell = ({ cell }: CellProps<IIssue>) => (
    <>{devices && cell.value ? devices[cell.value].denomination() : '-'}</>
  );

  const getEventCell = ({ cell }: CellProps<IIssue>) => (
    <EventCellStyled isResolved={!!issuesTypes && !!cell.row.original.resolutionTypeId}>
      {issuesTypes ? issuesTypes[cell.value].name : '-'}
    </EventCellStyled>
  );

  const getDurationCell = ({ cell }: CellProps<IIssue>) => {
    const { registeredAt, endedAt } = cell.row.original;

    return <>{endedAt ? formatter.duration(new Date(registeredAt), new Date(endedAt)) : '-'}</>;
  };

  const getDateCell = ({ cell }: CellProps<IIssue>) => (
    <>{cell.value ? moment(cell.value).format('DD/MM/YYYY, HH:mm:ss') : '-'}</>
  );

  const getResolutionCell = ({ cell }: CellProps<IIssue>) => {
    const resolution = resolutions && cell.value ? fc(resolutions[cell.value].name) : null;

    return <>{resolution || '-'}</>;
  };

  const getActionsCell = ({ cell }: { cell: Cell<IIssue> }) => <IssueActionForm issue={cell.row.original} />;

  return useMemo(
    () => [
      { Header: fc('id'), accessor: 'id', width: 100, minWidth: 100, isFlexUnset: true },
      currentTabId === RuleGroup.TAXIWAY
        ? { Header: fc('taxiway'), accessor: 'objectId', Cell: getRunwayCell, width: 100 }
        : { Header: fc('runway'), accessor: 'objectId', Cell: getRunwayCell, width: 100 },
      { Header: fc('vehicle'), accessor: 'deviceURI', Cell: getVehicleCell, width: 100 },
      { Header: fc('event'), accessor: 'issueTypeId', Cell: getEventCell, width: 100 },
      ...(currentTabId === RuleGroup.OPEN_RUNWAY
        ? [
            { Header: fc('event start'), accessor: 'registeredAt', Cell: getDateCell, width: 120 },
            { Header: fc('event end'), accessor: 'endedAt', width: 120, Cell: getDateCell },
            { Header: fc('event duration'), accessor: 'eventDuration', width: 100, Cell: getDurationCell },
          ]
        : [{ Header: fc('event timestamp'), accessor: 'registeredAt', Cell: getDateCell, width: 120 }]),
      { Header: fc('actions taken'), accessor: 'resolutionTypeId', width: 100, Cell: getResolutionCell },
      {
        Header: fc('actions'),
        accessor: 'actions',
        width: 220,
        minWidth: 220,
        Cell: getActionsCell,
        isFlexUnset: true,
      },
    ],
    [issuesTypes, devices, runways, taxiways, currentTabId],
  );
};
