import React, { FC } from 'react';
import moment from 'moment';

import {
  NotificationCloseButtonStyled,
  NotificationDateStyled,
  NotificationHeaderStyled,
  NotificationStyled,
} from 'ui/molecules/notifications.styled';
import { INotification } from 'types/notifications';
import { closeSign, ringBell, warningAlert } from 'assets/icons';
import { useNotification } from 'hooks/notifications/useNotification';
import { SvgWrapper } from 'components/atoms/svg-wrapper';

interface INotificationProps {
  isSeverePlaying?: boolean;
  notification: INotification;
}

const SEVERE_NOTIFICATION_PROPS = { width: 8, height: 10, details: ringBell };
const NON_SEVERE_NOTIFICATION_PROPS = { width: 21, height: 18, details: warningAlert };

export const Notification: FC<INotificationProps> = ({ isSeverePlaying = false, notification }) => {
  const { title, text, date, severity, onClose } = notification;

  const { isClosing, isSevere, closeNotification } = useNotification(isSeverePlaying, onClose, severity);

  return (
    <NotificationStyled isSevere={isSevere} isClosing={isClosing}>
      <NotificationHeaderStyled isSevere={isSevere}>
        <SvgWrapper {...(isSevere ? SEVERE_NOTIFICATION_PROPS : NON_SEVERE_NOTIFICATION_PROPS)} />
        <div>{title}</div>
      </NotificationHeaderStyled>
      <div>{text}</div>
      <NotificationDateStyled>{moment(date).format('DD/MM/YYYY, HH:mm:ss')}</NotificationDateStyled>
      <NotificationCloseButtonStyled width={8} height={8} details={closeSign} onClick={closeNotification} />
    </NotificationStyled>
  );
};
