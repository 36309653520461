import React, { useState } from 'react';
import { useI18n } from '../../../../../i18n';
import Toggle from '../../../general/form/Toggle';
import { getOwMap } from '../../../general/location/Map';

/**
 * @param {Object} props
 * @param {boolean} props.mapName
 */

function MapOptionDisplayMarkerLabels(props) {
	const { f } = useI18n();
	const map = getOwMap(props.mapName);
	const [value, setValue] = useState(map?.isDisplayedMarkerLabels());

	const onChange = () => {
		map.displayMarkerLabels(!value);
		setValue(map.isDisplayedMarkerLabels());
	}

	return (
		map &&
			<div className="map-option display-marker-labels">
				<Toggle
					label={f('display marker labels')}
					value={value}
					onChange={onChange}
				/>
			</div>
	);
}

export default MapOptionDisplayMarkerLabels;
