import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fc, useI18n } from '../../../../i18n';
import { actions as accountActions } from '../../../redux/api/account';
import { actions as authorizationActions } from '../../../redux/api/authorization';
import Form from '../../general/form/Form';
import Checkbox from '../../general/form/Checkbox';
import Button from '../../general/form/Button';
import ButtonGroup from '../../general/form/ButtonGroup';
import AuthorizationForm from './AuthorizationForm';
import FloatingLabelInput from '../../general/form/FloatingLabelInput';
import './signInOptionsForm.scss';

/**
 * @param {Object} props
 * @param {boolean} [props.create] reserved for future use
 * @param {function} props.onReady
 * @param {function} props.onCancel
 * @param {function} [props.setModalFooter] possibility to set footer content when used inside modal window
 */

function SignInOptionsForm(props) {
	const { f } = useI18n();
	const minSignInPeriod = 30 + 1;
	const maxSignInPeriod = 90 * 24 * 60;
	const [otpEmail, setOtpEmail] = useState(props.account.details.otpEmail);
	const [signInPeriod, setSignInPeriod] = useState(props.account.details.signInPeriod);
	const [toughenAuth, setToughenAuth] = useState(props.account.details.toughenAuth);
	const submitHook = useRef(null);
	const [inited, setInited] = useState(false);
	const pending = useRef(false);
	const [sendByEmail, setSendByEmail] = useState(Boolean(otpEmail));
	const [overridePeriod, setOverridePeriod] = useState(Boolean(signInPeriod));
	const auth = props.authorization;
	const token = auth.verificationToken;
	const verification = auth.authType && Boolean(auth.challenge);

	const dispatchSignInOptions = (otpEmail, toughenAuth, signInPeriod, token) => {
		pending.current = true;
		props.dispatch(accountActions.signInOptions.request({
			otpEmail: sendByEmail ? otpEmail : null, toughenAuth, signInPeriod: overridePeriod ? signInPeriod : null, token
		}));
	}

	const onSubmit = (values) => {
		if (token && (Date.now() + 60000) < token.expiresAt.getTime()) {
			dispatchSignInOptions(values.otpEmail, values.toughenAuth, values.signInPeriod, token.value);
		} else {
			setOtpEmail(values.otpEmail);
			setToughenAuth(values.toughenAuth);
			setSignInPeriod(Boolean(values.signInPeriod) ? Number(values.signInPeriod) : null);
			props.dispatch(authorizationActions.verify.request());
		}
	}

	const submit = () => {
		submitHook.current();
	}

	const footer = (
		<ButtonGroup className="change-signIn-options-buttons">
			<Button
				onClick={props.onCancel}
				disabled={props.account.pending}
			>
				{f('cancel')}
			</Button>
			<Button
				onClick={submit}
				loading={props.account.pending}
				disabled={props.account.pending || Boolean(auth.authType)}
			>
				{f('save')}
			</Button>
		</ButtonGroup>
	);

	useEffect(() => {
		if (inited) {
			if (props.setModalFooter) props.setModalFooter(verification ? null : footer);
			if (!verification && token) {
				dispatchSignInOptions(otpEmail, toughenAuth, signInPeriod, token.value);
			}
		} else setInited(true);
		return () => {
			props.dispatch(accountActions.clear());
		}
	}, [verification]);

	useEffect(() => {
		props.setModalFooter && props.setModalFooter(verification ? null : footer);
		if (!props.account.pending && pending.current) {
			pending.current = false;
			if (props.account.error == null) {
				props.onReady();
			}
		}
	}, [props.account.pending]);

	return (
		<div className="sign-in-options-form-wrap">
			{verification
				? <AuthorizationForm simple />
				: <Form
					onSubmit={onSubmit}
					submitHook={submitHook}
					className="change-signIn-options"
					disabled={props.account.pending || Boolean(auth.authType)}
					error={props.account.error}
					footer={!props.setModalFooter && footer}
				>
					<Form.ControlGroup>
						<Checkbox
							value={sendByEmail}
							onChange={setSendByEmail}
							label={fc('send verification code by email message')}
						/>
						<Form.Control
							controlType={FloatingLabelInput}
							controlName="otpEmail"
							initialValue={otpEmail}
							disabled={!sendByEmail}
							controlValidator={(value) => {
								if (sendByEmail) {
									if (!value) return f('please enter a value');
									if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(value))) return f('please, enter a valid email address');
								}
							}}
							label={f('email address')}
							type="email"
							autoFocus
						/>
						<Checkbox
							value={overridePeriod}
							onChange={setOverridePeriod}
							label={fc('override signIn period')}
						/>
						<Form.Control
							controlType={FloatingLabelInput}
							controlName="signInPeriod"
							initialValue={signInPeriod}
							disabled={!overridePeriod}
							controlValidator={(value) => {
								if (overridePeriod) {
									if (!value) return f('please enter a value');
									if (value < minSignInPeriod || value > maxSignInPeriod)
										return f('please enter a number from X minute to Y days in minutes', {minSignInPeriod, maxSignInPeriod: maxSignInPeriod / 60 / 24});
								}
							}}
							label={f('signIn period')}
							type="number"
							autoFocus
						/>
					</Form.ControlGroup>
					<Form.Control
						controlType={Checkbox}
						initialValue={props.account.details.toughenAuth}
						controlName="toughenAuth"
						label={fc('Toughen sign-in')}
					/>
				</Form>
			}
		</div>
	);
}

export default connect(state => ({
	account: state.account,
	authorization: state.authorization
}))(SignInOptionsForm);
