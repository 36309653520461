import { ofType, combineEpics } from 'redux-observable';
import { switchMap, takeUntil, map as rxmap, filter, tap, ignoreElements } from 'rxjs/operators';
import { cx, api, rx } from "../../../../api";
import { formatDateTime, getTimezoneString, formatUTCTicksDuration } from '../../../../misc/misc';
import { resolver } from './resolver';
import { actions } from '../actions';
import { fc, f } from '../../../../../i18n';
import { errorMap } from '../../../actions';

const requestEpic = (action$) => {
	return action$.pipe(
		ofType(actions.trip.request.type),
		switchMap(action =>
			rx(api.reports.trip, action.uri, action.parameters).pipe(
				rxmap(operation => actions.trip.success({ report: operation.response() })),
				errorMap(actions.trip.fail),
				takeUntil(action$.pipe(ofType(actions.trip.cancel.type)))
			)
		)
	)
}

const startPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.trip.export.type),
		rxmap(action => {
			const state = state$.value.reports.trip;
			resolver.resolve(state.list, !state.hasMore);
			if (state.hasMore) {
				return actions.trip.request({ uri: action.uri, parameters: state.parameters });
			} else {
				return actions.trip.success({});
			}
		})
	)
}

const processPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.trip.success.type),
		filter(action => {
			const state = state$.value.reports.trip;
			if (state.exporting) resolver.resolve(action.report && action.report.summaries, !state.hasMore);
			return state.exporting && state.hasMore;
		}),
		rxmap(action => {
			const state = state$.value.reports.trip;
			return actions.trip.request({ uri: state.uri, parameters: state.parameters });
		})
	)
}

const exportCsvEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.trip.exportProgress.type),
		filter(action => action.progress == 100),
		rxmap(action => {
			const state = state$.value.reports.trip;
			const device = state$.value.devices.map[state.uri];
			// report header
			let csv = '"'
				+ fc('report type')
				+ '",'
				+ fc('generated')
				+ ','
				+ fc('timezone')
				+ ','
				+ fc('device')
				+ ','
				+ fc('since')
				+ ','
				+ fc('until')
				+ '\n';
			csv += fc('trip');  // report type
			csv += ',' + formatDateTime(cx.now()); // generated at
			csv += ',' + getTimezoneString(); // timezone at
			csv += ',"' + (device.denomination() || device.uri) + '"';
			csv += ',"' + formatDateTime(state.parameters.timeRange.since) + '"';
			csv += ',"' + formatDateTime(state.parameters.timeRange.until) + '"';
			csv += "\n\n";
			// content header
			csv += '"'
				+ fc('start time')
				+ '","'
				+ fc('end time')
				+ '","'
				+ fc('start location')
				+ '","'
				+ fc('start address')
				+ '","'
				+ fc('last location')
				+ '","'
				+ fc('last address')
				+ '"';

			csv += ',' + fc('duration') + ',"' + fc('distance') + ', ' + f('units.km') + '"';
			csv += ',"' + fc('speed max') + ', ' + f('units.km/h') + '"';
			csv += ',"' + fc('fuel used') + ', ' + f('units.l') + '","' + fc('fuel efficiency') + ', ' + f('units.km/l') + '"';
			csv += ',"' + fc('battery min') + ', ' + f('units.V') + '", "' + fc('battery max') + ', ' + f('units.V') + '"';
			csv += ',"' + fc('engine temp. max') + ', \u00B0C", "' + fc('engine RPM max') + '", "' + fc('engine load max') + ', %"';
			csv += ',' + f('MIL') + ',' + fc('idling') + ',"' + fc('harsh brake') + '","' + fc('harsh acceleration') + '",' + fc('speeding');
			if (state.parameters.markers) {
				csv += ',' + fc('marker');
			}
			csv += "\n";
			// content
			state.list.forEach(reportItem => {
				const trip = reportItem.trip;
				// start time
				csv += formatDateTime(trip.startAt);
				// end time
				if (trip.endAt) {
					csv += ',' + formatDateTime(trip.endAt);
				}
				// start location
				const start = trip.startMessage;
				if (start != null && start.latitude != null && start.longitude != null) {
					csv += ",(" + start.latitude + ";" + start.longitude + ")";
					// start address
					const entryKey = resolver.key(start.latitude, start.longitude);
					const address = resolver.resolved[entryKey]?.getAddress();
					if (address) csv += ',"' + address.format() + '"';
					else csv += ",-";
				} else {
					csv += ",-,-"
				}
				// last location
				const last = trip.lastMessage;
				if (last != null && last.latitude != null && last.longitude != null) {
					csv += ",(" + last.latitude + ";" + last.longitude + ")";
					// last address
					const entryKey = resolver.key(last.latitude, last.longitude);
					const address = resolver.resolved[entryKey]?.getAddress();
					if (address) csv += ',"' + address.format() + '"';
					else csv += ",-";
				} else {
					csv += ",-,-";
				}
				// duration
				if (trip.endAt) {
					csv += ',"' + formatUTCTicksDuration(trip.endAt.getTime() - trip.startAt.getTime()) + '"';
				} else {
					csv += ",-";
				}
				// SUMMARY
				const summary = reportItem.summary;
				// distance
				csv += "," + (summary.distance ? summary.distance : "-") ;
				// speed max
				csv += "," + (summary.speedMax ? summary.speedMax : "-");
				// fuel used
				csv += "," + (summary.fuelUsed ? summary.fuelUsed : "-");
				// fuel efficiency
				if (summary.fuelUsed > 0) {
					csv += "," + (summary.distance / summary.fuelUsed).toFixed(2);
				} else {
					csv += ",-";
				}
				// battery min, battery max
				csv += "," + (summary.voltageMin ? summary.voltageMin : "-");
				csv += "," + (summary.voltageMax ? summary.voltageMax : "-");
				// engine temp max, engine rpm max, engine load max
				csv += "," + (summary.engineTemperatureMax ?  summary.engineTemperatureMax : "-");
				csv += "," + (summary.rpmMax ? summary.rpmMax : "-");
				csv += "," + (summary.engineLoadMax ? summary.engineLoadMax : "-");
				// INCIDENTS
				const eventTypes = state$.value.registry.eventTypes.mnemonicMap;
				var incidents = cx.i.hash(summary.incidentDigests, function (digest) {
					return digest.eventType;
				}, this) || {};
				var types = ["mil", "idling", "braking", "acceleration", "speeding"];
				cx.i.each(types, function (mnemonics) {
					var type = eventTypes[mnemonics];
					if (incidents) {
						csv += "," + (incidents[type.eventType] ? incidents[type.eventType].quantity : "-");
					} else {
						csv += ",";
					}
				}, this);
				// PARAMETERS' SPECIFIC COLUMNS
				if (state.parameters.markers) {
					if (reportItem.marker) {
						var value = reportItem.marker.message.fields.mapped[19];
						csv += "," + (value ? value : "-");
					} else {
						csv += ",-";
					}
				}
				//
				csv += "\n";
			});
			return actions.trip.exportDone({ csv });
		}),
	)
}

const exportClearEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.trip.exportClear.type),
		tap(() => resolver.clear()),
		ignoreElements()
	);
}

const epic = combineEpics(requestEpic, startPrepareEpic, processPrepareEpic, exportCsvEpic, exportClearEpic);

export { epic };
