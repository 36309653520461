import React from 'react';
import MapButton from '../../map/MapButton';
import { FiClock } from 'react-icons/fi'
import { withRouter } from 'react-router-dom';
import { useI18n } from '../../../../../i18n';

function TimeMachineMapButton(props) {
	const { fc } = useI18n();

	const onClick = () => {
		let path = '/time-machine';
		if (props.match.params.uris) {
			path += '/' + props.match.params.uris;
		}
		props.history.push(path);
	}

	return (
		<MapButton onClick={onClick} title={fc('time machine')}>
			<FiClock />
		</MapButton>
	)
}

export default withRouter(TimeMachineMapButton);