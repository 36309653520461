import React, { Dispatch, DispatchWithoutAction, FC } from 'react';
import { FiExternalLink as IconZoneGuards } from 'react-icons/fi';

import {
  ColumnedPageStyled,
  MainColumnStyled,
  SideColumnsStyled,
  SideColumnStyled,
} from 'ui/layout/columned-page.styled';
import { IAttributeRangeDetector } from 'types/detectors';
import { useAttributeDetectorsView } from 'hooks/attribute-range-detectors/useAttributeDetectorsView';
import Intent from 'core/misc/ObjectActionIntent';
import { EntitiesActions } from 'components/molecules/entities-actions';
import { DeviceManager } from 'core/react/share/devices/DeviceManager';
import { AttributeDetectorsList } from './attribute-detectors-list';
import { AttributeDetectorCard } from './attribute-detector-card';
import { AttributeInfo } from './attribute-info';
import { RemoveDetectorDialogue } from './remove-detector-dialogue';

interface IAttributeDetectorViewsProps {
  intent: Intent | null;
  selectedDetector: IAttributeRangeDetector | null;
  onIntent: Dispatch<Intent>;
  onRemove: Dispatch<IAttributeRangeDetector>;
  onPopupClose: DispatchWithoutAction;
}

export const AttributeDetectorsView: FC<IAttributeDetectorViewsProps> = (props) => {
  const { selectedDetector, onIntent, ...rest } = props;

  const { actionsProps, listProps, isDisabled } = useAttributeDetectorsView();

  return (
    <ColumnedPageStyled>
      <MainColumnStyled>
        <EntitiesActions icon={<IconZoneGuards size={20} />} {...actionsProps} />
        <AttributeDetectorsList selectedId={selectedDetector?.processorId || null} onIntent={onIntent} {...listProps} />
        {!!selectedDetector && <AttributeDetectorCard detector={selectedDetector} />}
      </MainColumnStyled>
      <SideColumnsStyled>
        {!!selectedDetector && (
          <>
            <SideColumnStyled>
              <DeviceManager uris={selectedDetector?.uris || []} />
            </SideColumnStyled>
            <SideColumnStyled>
              <AttributeInfo detector={selectedDetector} />
            </SideColumnStyled>
          </>
        )}
        <RemoveDetectorDialogue isDisabled={isDisabled} {...rest} />
      </SideColumnsStyled>
    </ColumnedPageStyled>
  );
};
