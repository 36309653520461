import React from 'react';
import { useI18n } from '../../../../i18n';
import { className, fromProps } from '../../../lib/className';
import ActionBar from '../../general/actionbar/ActionBar'
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';

/**
 * @param {Object} props
 * @param {function} props.onClick
 * @param {string} [props.title]
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.active]
 */

function ActionMaximize(props) {
	const { f } = useI18n();
	return (
		<ActionBar.Action
			className={className('maximize', fromProps(props))}
			onClick={props.onClick}
			disabled={props.disabled}
			active={props.active}
			title={props.title || props.active ? f('minimize') : f('maximize')}
		>
			{props.active ? <FiMinimize2 size="16" /> : <FiMaximize2 size="16" />}
		</ActionBar.Action>
	);
}

export default ActionMaximize;