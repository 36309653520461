import React, { useState, useEffect } from 'react';
import { className, fromProps } from '../../../lib/className';
import CoordinateSelectMap from './CoordinateSelectMap';
import { fromEPSG4326, toEPSG4326 } from 'ol/proj/epsg3857';
import Input from '../form/Input';
import ButtonGroup from '../form/ButtonGroup';
import Button from '../form/Button';
import { useI18n } from '../../../../i18n';
import { isEnterButton } from '../../../misc/misc';
import './mapCoordinateSelector.scss';

/**
 * @param {Object} props
 * @param {Array.<number>} [props.coordinate] [longitude, latitude]
 * @param {function} [props.onChange] return object [ longitude, latitude ]
 * @param {function} [props.onCancel]
 */

function MapCoordinateSelector(props) {
	const { f, fc } = useI18n();
	const [longitude, setLongitude] = useState(props.coordinate && props.coordinate[0] || '');
	const [latitude, setLatitude] = useState(props.coordinate && props.coordinate[1] || '');
	const [error, setError] = useState(null);

	const onSubmit = () => {
		if (!!props.onChange && longitude && latitude) {
			if (!Number.isNaN(+longitude) && !Number.isNaN(+latitude)) {
				if (error) setError(null);
				props.onChange([ +longitude, +latitude ]);
			} else {
				setError('please, enter a correct value');
			}
		}
	}

	// coordinate only for map
	const getCoordinate = () => {
		if (longitude == '' && latitude == '') return;
		const long = +longitude;
		const lat = +latitude;
		if (!Number.isNaN(long) && !Number.isNaN(lat)) {
			if (error) setError(null);
			return fromEPSG4326([long, lat]);
		}
	}

	useEffect(() => {
		props.setModalFooter && props.setModalFooter(footer);
		const enterHandler = (event) => {
			if (isEnterButton(event)) {
				event.stopPropagation();
				onSubmit();
			}
		}
		window.document.addEventListener('keydown', enterHandler);
		return () => window.document.removeEventListener('keydown', enterHandler);
	}, [props.longitude, props.latitude, longitude, latitude]);

	const footer = (
		<ButtonGroup className="map-coordinate-slector-buttons">
			<Button onClick={props.onCancel}>
				{f('cancel')}
			</Button>
			<Button onClick={onSubmit}>
				{f('save')}
			</Button>
		</ButtonGroup>
	);

	const onClick = (coordinates) => {
		const copyCoord = [...toEPSG4326(coordinates)];
		setLongitude(copyCoord[0].toFixed(7));
		setLatitude(copyCoord[1].toFixed(7));
	}

	return (
		<div className={className('map-coordinate-slector', fromProps(props))}>
			<CoordinateSelectMap coordinate={getCoordinate()} onClick={onClick} />
			<div className="coordinate-fields">
				<Input label={fc('longitude')} value={longitude} onChange={setLongitude} />
				<Input label={fc('latitude')} value={latitude} onChange={setLatitude} />
				{error && <div className="error"><span className="capitalize">{f(error)}</span></div>}
			</div>
		</div>
	);
}

export default MapCoordinateSelector;