import React from 'react';

const orderOptionMap = {
	time: {
		key: 'time',
		description: 'update time'
	},
	latitude: {
		key: 'latitude',
		description: 'latitude'
	}
}

export const MarkerOrderManagerContext = React.createContext();

class MarkerOrderManager extends React.Component {
	constructor(props) {
		super(props);
		this.markerMap = {}; // { id: marker }; marker = { id, coordinates, time, index, setZIndex, selected };
		this.selectedMarkerMap = {};
		this.highlightedMarkerId = null;
		this.quantityMarkers = 0;
		this.quantitySelectedMarkers = 0;
		this.orderOption = this.props.orderOption || orderOptionMap.time.key;
	}

	setOrderOption(option) {
		this.orderOption = option;
		this.buildIndexForMarkers();
		this.refresh();
	}

	addMarker(marker) {
		if (!this.markerMap[marker.id]) {
			this.markerMap[marker.id] = marker;
			++this.quantityMarkers;
			this.buildIndexForMarkers();
			this.refresh();
		} else this.updateMarkerData(marker);
	}

	removeMarker(id) {
		delete this.markerMap[id];
		delete this.selectedMarkerMap[id];
		--this.quantityMarkers;
	}

	applyIndex(id, index) {
		this.markerMap[id].setZIndex(index);
	}

	resetIndex(id) {
		this.applyIndex(id, this.selectedMarkerMap[id] ? this.selectedMarkerMap[id].index : this.markerMap[id].index);
	}

	setIndex(id, index) {
		if (id && index) {
			this.markerMap[id] = { ...this.markerMap[id], index };
			this.applyIndex(id, index);
		}
	}

	buildIndexForMarkers() {
		const markers = Object.values(this.markerMap)
		this.sort(markers);
		markers.forEach((marker, index) => {
			this.setIndex(marker.id, index + 1);
			if (marker.selected) this.selectedMarkerMap[marker.id] = marker;
		});
	}

	sort(markers) {
		switch (this.orderOption) {
			case orderOptionMap.time.key: return this.sortByTime(markers);
			default: return this.sortByCoordinates(markers);
		}
	}

	sortByTime(markers) {
		const compare = (a, b) => {
			if (b.time.getTime() < a.time.getTime()) return 1;
			else if (a.time.getTime() < b.time.getTime()) return -1;
			else return 0;
		}
		return markers.sort(compare);
	}

	sortByCoordinates(markers) {
		const compare = (a, b) => {
			if (b.coordinates[1] < a.coordinates[1]) return -1;
			else if (a.coordinates[1] < b.coordinates[1]) return 1;
			else return 0;
		}
		return markers.sort(compare);
	}

	refresh() {
		const selectedMarkers = Object.values(this.selectedMarkerMap);
		this.quantitySelectedMarkers = selectedMarkers.length;
		this.sort(selectedMarkers).forEach((marker, i) => {
			const index = this.quantityMarkers + i + 1;
			this.selectedMarkerMap[marker.id].index = index;
			this.applyIndex(marker.id, index);
		});
	}

	checkNeedRebuild(oldData, newData) {
		switch (this.orderOption) {
			case orderOptionMap.time.key: return oldData.time.getTime() != newData.time.getTime();
			default: return oldData.coordinates[0] != newData.coordinates[0] || oldData.coordinates[1] != newData.coordinates[1];
		}
	}

	updateMarkerData(marker) {
		const updatingMarker = this.markerMap[marker.id];
		if (updatingMarker) {
			const needRebuild = this.checkNeedRebuild(updatingMarker, marker);
			if (this.selectedMarkerMap[marker.id] && !marker.selected) {
				delete this.selectedMarkerMap[marker.id];
				this.resetIndex(marker.id);
			} else if (!this.selectedMarkerMap[marker.id] && marker.selected) {
				this.selectedMarkerMap[marker.id] = marker;
			}
			updatingMarker.coordinates = marker.coordinates;
			updatingMarker.time = marker.time;
			updatingMarker.selected = marker.selected;
			if (needRebuild) this.buildIndexForMarkers();
			this.refresh();
		}
	}

	highlightMarker(id) {
		if (this.markerMap[id]) {
			if (this.highlightedMarkerId && this.highlightedMarkerId != id) this.resetIndex(this.highlightedMarkerId);
			this.highlightedMarkerId = id;
			this.applyIndex(this.highlightedMarkerId, this.quantityMarkers + this.quantitySelectedMarkers + 10);
		}
	}

	unHighlightMarker() {
		if (this.highlightedMarkerId) {
			this.resetIndex(this.highlightedMarkerId);
			this.highlightedMarkerId = null;
		}
	}

	componentDidUpdate() {
		if (this.props.orderOption && this.orderOption != this.props.orderOption) this.setOrderOption(this.props.orderOption);
	}

	render() {
		return (
			<MarkerOrderManagerContext.Provider value={this}>
				{this.props.children}
			</MarkerOrderManagerContext.Provider>
		);
	}
};
MarkerOrderManager.orderOptionMap = orderOptionMap;
export { MarkerOrderManager };
