import React, { FC } from 'react';

import { useI18n } from 'i18n';
import { IAttributeRangeDetector } from 'types/detectors';
import { DetectorAttributesStyled, DetectorCardStyled } from 'ui/pages/attribute-range-detectors/detector-card.styled';

interface IAttributeDetectorCardProps {
  detector: IAttributeRangeDetector;
}

export const AttributeDetectorCard: FC<IAttributeDetectorCardProps> = ({ detector }) => {
  const { fc } = useI18n();

  const { name, onIn, onOut } = detector;

  return (
    <DetectorCardStyled>
      <div>
        <label>{fc('name')}</label>
        <div>{name}</div>
      </div>
      {(onIn || onOut) && (
        <DetectorAttributesStyled>
          <label>{fc('attributes')}</label>
          {onIn && <div>{fc('in range')}</div>}
          {onOut && <div>{fc('out of range')}</div>}
        </DetectorAttributesStyled>
      )}
    </DetectorCardStyled>
  );
};
