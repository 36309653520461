/**
 * Whether ranges have common part.
 * @param {Object} left { since, until }
 * @param {Object} right { since, until }
 */
export const intersects = (left, right) => {
	return !(
		left.since.getTime() < right.since.getTime() && left.until.getTime() < right.since.getTime() ||
		left.since.getTime() > right.until.getTime() && left.until.getTime() > right.until.getTime()
	);
}

/**
 * If ranges have common part - returns it.
 * @param {Object} left { since, until }
 * @param {Object} right { since, until }
 */
export const intersection = (left, right) => {
	if (intersects(left, right)) return {
		since: new Date(Math.max(left.since.getTime(), right.since.getTime())),
		until: new Date(Math.min(left.until.getTime(), right.until.getTime()))
	}
	return null;
}

/**
 * If ranges have common part - cuts it and returns the rest.
 * @param {Object} left { since, until }
 * @param {Object} right { since, until }
 */
export const difference = (left, right) => {
	const leftSinceMs = left.since.getTime(), leftUntilMs = left.until.getTime();
	const rightSinceMs = right.since.getTime(), rightUntilMs = right.until.getTime();
	if (leftSinceMs == rightSinceMs && leftUntilMs == rightUntilMs || leftUntilMs < rightSinceMs || rightUntilMs < leftSinceMs) {
		return null;
	}
	const sinceMs1 = Math.min(leftSinceMs, rightSinceMs), untilMs1 = Math.max(leftSinceMs, rightSinceMs);
	const sinceMs2 = Math.min(leftUntilMs, rightUntilMs), untilMs2 = Math.max(leftUntilMs, rightUntilMs);
	return [
		{ since: new Date(sinceMs1), until: new Date(untilMs1) },
		{ since: new Date(sinceMs2), until: new Date(untilMs2) }
	];
}

/**
 * If ranges have common part - returns union of ranges.
 * @param {Object} left { since, until }
 * @param {Object} right { since, until }
 */
export const union = (left, right) => {
	if (intersects(left, right)) return {
		since: new Date(Math.min(left.since.getTime(), right.since.getTime())),
		until: new Date(Math.max(left.until.getTime(), right.until.getTime()))
	}
	return null;
}

/**
 * Calcualtes missing ranges inside the frame and returns them.
 * @param {Object} frame { since, until }
 * @param {Array.<Object>} ranges [{ since, until }]
 */
export const invert = (frame, ranges) => {
	const result = [];
	const frameSinceMs = frame.since.getTime();
	const frameUntilMs = frame.until.getTime();
	let anchorMs = frameSinceMs;
	if (ranges.length == 0) {
		result.push({ since:  frame.since, until: frame.until });
	} else {
		for (let rangeAt = 0; rangeAt < ranges.length; ++rangeAt) {
			const since = ranges[rangeAt].since, until = ranges[rangeAt].until;
			const sinceMs = since.getTime(), untilMs = until.getTime();
			if (sinceMs <= anchorMs && untilMs > anchorMs && untilMs < frameUntilMs) { // --s--|--u--| --s|--u--|
				anchorMs = untilMs;
			} else if (sinceMs > anchorMs && sinceMs < frameUntilMs && untilMs < frameUntilMs) { // |--s--u--|
				result.push({ since: new Date(anchorMs), until: since });
				anchorMs = untilMs;
			} else if (sinceMs > anchorMs && sinceMs < frameUntilMs && untilMs >= frameUntilMs) { // |--s--|--u |--s--u|
				result.push({ since: new Date(anchorMs), until: since });
				break;
			} else if (sinceMs >= frameUntilMs && untilMs > frameUntilMs) { // |--|--s--u
				break;
			} else if (sinceMs <= anchorMs && untilMs >= frameUntilMs) { // --s|--|u--
				anchorMs = frameUntilMs;
			}
			if (anchorMs < frameUntilMs && rangeAt == ranges.length-1) {
				result.push({ since: new Date(anchorMs), until: new Date(frameUntilMs) });
			}
		}
	}
	return result;
}

/**
 * Calculates difference between frame and time ranges.
 * @param {Object} frame { since, until }
 * @param {Array.<Object>} ranges [{ since, until }]
 */
export const subtract = (frame, ranges) => {
	// get rid of ranges which are entirely not in frame
	const overlapping = ranges.filter(range =>
		// range "since" inside frame
		range.since.getTime() >= frame.since.getTime()
		&& range.since.getTime() < frame.until.getTime()
		// range "until" inside frame
		|| range.until.getTime() >= frame.since.getTime()
		&& range.until.getTime() < frame.until.getTime()
		// range entirely cover frame
		|| frame.since.getTime() >= range.since.getTime()
		&& frame.until.getTime() <= range.until.getTime()
	);
	const diff = [];
	let anchorAt = frame.since.getTime();
	for (let at = 0; at < overlapping.length; ++at) {
		const range = overlapping[at];
		if (range.since.getTime() > anchorAt) diff.push({ since: new Date(anchorAt), until: range.since });
		anchorAt = range.until.getTime();
		if (range.until.getTime() > frame.until.getTime()) break;
	}
	if (anchorAt < frame.until.getTime()) diff.push({ since: new Date(anchorAt), until: frame.until });
	return diff;
}