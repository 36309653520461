import React, { FC } from 'react';

import { useAttributeRangeDetectors } from 'hooks/attribute-range-detectors/useAttributeRangeDetectors';
import Loader from 'core/react/general/Loader';
import { AttributeDetectorForm } from './attribute-detector-form';
import { AttributeDetectorsView } from './attribute-detectors-view';

export const AttributeRangeDetectors: FC = () => {
  const { isFormPage, isLoading, viewProps, formProps } = useAttributeRangeDetectors();

  if (isLoading) {
    return (
      <div className="vh-center">
        <Loader size={Loader.Size.LG} />
      </div>
    );
  }

  return isFormPage ? <AttributeDetectorForm {...formProps} /> : <AttributeDetectorsView {...viewProps} />;
};
