import { cx, api, rx } from "../../../../api";
import { ofType, combineEpics } from 'redux-observable';
import { switchMap, takeUntil, map as rxmap, filter } from 'rxjs/operators';
import { formatDateTime, getTimezoneString } from '../../../../misc/misc';
import { actions } from '../actions';
import { fc, f } from "../../../../../i18n";
import { formatter } from "../../../../misc/datetime";
import { errorMap } from "../../../actions";

const requestEpic = (action$) => {
	return action$.pipe(
		ofType(actions.fuelLevel.request.type),
		switchMap(action =>
			rx(api.reports.fuelLevelChange, action.uri, action.parameters).pipe(
				rxmap(operation => actions.fuelLevel.success({ intervals: operation.response() })),
				errorMap(actions.fuelLevel.fail),
				takeUntil(action$.pipe(ofType(actions.fuelLevel.cancel.type)))
			)
		)
	)
}

const startPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.fuelLevel.export.type),
		rxmap(action => {
			const state = state$.value.reports.fuelLevel;
			if (state.hasMore) {
				return actions.fuelLevel.request({ uri: action.uri, parameters: state.parameters });
			} else {
				return actions.fuelLevel.exportProgress({ progress: 100 });
			}
		})
	)
}

const processPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.fuelLevel.success.type),
		filter(action => {
			const state = state$.value.reports.fuelLevel;
			return state.exporting || state.loadingAll;
		}),
		rxmap(action => {
			const state = state$.value.reports.fuelLevel;
			if (state.hasMore) return actions.fuelLevel.request({ uri: state.uri, parameters: state.parameters });
			else return actions.fuelLevel.exportProgress({ progress: 100 });
		})
	)
}

const exportCsvEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.fuelLevel.exportProgress.type),
		filter(action => action.progress == 100),
		rxmap(action => {
			const state = state$.value.reports.fuelLevel;
			const device = state$.value.devices.map[state.uri];
			// report header
			var csv = '"'
				+ fc('report type')
				+ '",'
				+ fc('generated')
				+ ','
				+ fc('timezone')
				+ ','
				+ fc('device')
				+ ','
				+ fc('since')
				+ ','
				+ fc('until')
				+ ',"'
				+ fc('fuel level delta', { unit: f({ prefix: 'units', id: '%' }) })
				+ '"\n';
			csv += fc('fuel level');  // report type
			csv += ',' + formatDateTime(cx.now()); // generated at
			csv += ',' + getTimezoneString(); // timezone at
			csv += ',"' + (device.denomination() || device.uri) + '"';
			csv += ',"' + formatDateTime(state.parameters.timeRange.since) + '"';
			csv += ',"' + formatDateTime(state.parameters.timeRange.until) + '"';
			csv += ',' + state.parameters.delta;
			csv += "\n\n";
			// content header
			csv += fc('since')
				+ ','
				+ fc('until')
				+ ','
				+ fc('duration')
				+ ',"'
				+ fc('fuel level') + ', ' + f({ prefix: 'units', id: '%' })
				+ '"\n';
			// content
			state.list.forEach(interval => {
				// since
				const sinceMessage = interval.since;
				csv += '"' + formatDateTime(sinceMessage.generatedAt) + '"';
				// until
				if (interval.until) {
					csv += ',"' + formatDateTime(interval.until) + '"';
				} else {
					csv += ',-';
				}
				// duration
				if (interval.until) {
					csv += ',"' + formatter.duration(interval.since.generatedAt, interval.until) + '"';
				} else {
					csv += ',-';
				}
				// fuel level
				csv += ',' + interval.level;
				//
				csv += "\n";
			});
			return actions.fuelLevel.exportDone({ csv });
		}),
	)
}

const epic = combineEpics(requestEpic, startPrepareEpic, processPrepareEpic, exportCsvEpic);

export { epic };
