import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { RemarksFieldStyled } from 'ui/pages/issues/issues-actions-select.styled';
import { fc } from 'i18n';
import { IIssueActionForm } from 'hooks/issues/useIssueActionForm';

interface IRemarksFieldProps {
  isLoading: boolean;
}

export const RemarksField: FC<IRemarksFieldProps> = ({ isLoading }) => {
  const { control } = useFormContext<IIssueActionForm>();

  return (
    <Controller
      control={control}
      name="resolution"
      render={({ value, onChange }) => (
        <RemarksFieldStyled placeholder={fc('remarks')} disabled={isLoading} value={value} onChange={onChange} />
      )}
    />
  );
};
