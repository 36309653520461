import React from 'react';
import { cx } from "../../../../api"
import IntegerControl from './IntegerControl';
import DecimalControl from "./DecimalControl";
import BooleanControl from "./BooleanControl";
import DatetimeControl from "./DatetimeControl";
import DateControl from "./DateControl";
import TextControl from "./TextControl";
import VectorControl from './VectorControl';
import CompositeControl from './CompositeControl';

const typeMap = {
	[cx.o.markerOf(cx.ods.meta.IntegerProperty)]: IntegerControl,
	[cx.o.markerOf(cx.ods.meta.DecimalProperty)]: DecimalControl,
	[cx.o.markerOf(cx.ods.meta.BooleanProperty)]: BooleanControl,
	[cx.o.markerOf(cx.ods.meta.DatetimeProperty)]: DatetimeControl,
	[cx.o.markerOf(cx.ods.meta.DateProperty)]: DateControl,
	[cx.o.markerOf(cx.ods.meta.TextProperty)]: TextControl
};

export const isSimpleProperty = (property) => {
	const marker = cx.o.markerOf(property.constructor);
	return Object.keys(typeMap).indexOf(marker) >= 0;
}

export const isCompositeProperty = (property) => {
	return cx.o.typeOf(property, cx.ods.meta.PropertyComposite) || cx.o.typeOf(property, cx.ods.meta.PropertyBundle);
}

/**
 * @description returns corresponding react component instance for given property's datatype
 * @param {cx.ods.meta.Property} property
 */

export const control = (property, props) => {
	if (isSimpleProperty(property)) {
		const marker = cx.o.markerOf(property.constructor);
		const Control = typeMap[marker];
		return <Control {...props} />;
	}
}

/**
 * @description returns corresponding react component type for given property's datatype
 * @param {cx.ods.meta.Property} property
 */

export const controlType = (property) => {
	if (isSimpleProperty(property)) {
		const marker = cx.o.markerOf(property.constructor);
		return typeMap[marker];
	} else if (cx.o.typeOf(property, cx.ods.meta.PropertyVector)) {
		return VectorControl;
	} else if (cx.o.typeOf(property, cx.ods.meta.PropertyRecord)) {
		return CompositeControl;
	}
}