import React, { useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import { cx } from '../../../../api';
import { actions } from '../../../../redux/api/reports';
import Form from '../../../general/form/Form';
import { useI18n } from '../../../../../i18n';

const BATCH_SIZE = 70;

/**
 * @param {Object} props
 * @param {React.RefObject} props.customRef
 * @param {Object} props.timeRange
 * @param {boolean} [props.disabled]
 */

function FuelLevel(props) {
	const { f } = useI18n();

	useImperativeHandle(props.customRef, () => ({
		submit: (values) => {
			const parameters = new cx.ods.reports.FuelLevelChangeReportParameters();
			parameters.timeRange = props.timeRange;
			parameters.timeRange.retrospective = values.retrospective;
			parameters.timeRange.window = new cx.ods.TimeWindow(BATCH_SIZE);
			parameters.delta = +values.delta;
			props.dispatch(actions.fuelLevel.request({ uri: values.uri, parameters, clear: true }));
		},
		clear: () => {
			props.dispatch(actions.fuelLevel.exportClear());
		}
	}));

	return (
		<Form.ControlGroup key={50} disabled={props.disabled}>
			<Form.Control
				controlName="delta"
				controlType={Form.Control.Type.InputNumber}
				controlValidator={(value) => {
					value = parseInt(value);
					return !(Number.isInteger(value) && value > 0) && f('please enter integer number above zero');
				}}
				min={1} max={100}
				label={f('fuel level delta', { unit: f({ prefix: 'units', id: '%' }) })}
				type="number"
				initialValue={props.state.parameters ? props.state.parameters.delta : 5}
			/>
			<Form.Control
				controlName="retrospective"
				controlType={Form.Control.Type.Checkbox}
				label={f('report later items first')}
				initialValue={props.state.parameters ? props.state.parameters.timeRange.retrospective : false}
			/>
		</Form.ControlGroup>
	);
}

export default connect(state => ({
	state: state.reports.fuelLevel
}))(FuelLevel);
