import React, { FC } from 'react';

import { INotification } from 'types/notifications';
import { VISIBLE_ALERTS_COUNT } from 'constants/issues';
import { ReactPortal } from 'components/molecules/react-portal';
import { Notification } from './notification';
import { SeeAllButton } from './see-all-button';

interface INotificationsListProps {
  isSeverePlaying?: boolean;
  portalId: string;
  notifications: INotification[];
}

export const NotificationsList: FC<INotificationsListProps> = ({ isSeverePlaying, portalId, notifications }) => (
  <ReactPortal portalId={portalId}>
    <div>
      {notifications.slice(0, VISIBLE_ALERTS_COUNT).map((notification) => (
        <Notification key={notification.id} isSeverePlaying={isSeverePlaying} notification={notification} />
      ))}
      {/*TODO: uncomment when it's ready to use*/}
      {/*<SeeAllButton isShown={notifications.length > VISIBLE_ALERTS_COUNT} />*/}
    </div>
  </ReactPortal>
);
