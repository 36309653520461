import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import CategoryList from '../../../custom/sidebar/categories/CategoryList';
import GroupingPicker from '../../categories/GroupingPicker';
import CategoryListItem from './CategoryListItem';
import DeviceSearchControl from '../DeviceSearchControl';
import ButtonGroup from '../../../general/form/ButtonGroup';
import Button from '../../../general/form/Button';
import { localStorage } from '../../../../app/storage';
import { STORAGE_KEY } from '../../../../redux/app/categories/grouping';
import { actions as deviceFilterActions } from '../../../../redux/app/deviceFilter';
import './deviceSelector.scss';

export const DeviceSelectorContext = React.createContext(null);

/**
 * @param {Object} props
 * @param {Array.<string>} props.uris
 * @param {Array.<string> | undefined} [props.excludeUris]
 * @param {function} props.onSubmit
 * @param {function} props.onCancel
 * @param {function} [props.setModalFooter] possibility to set footer content when used inside modal window
 */

function DeviceSelector(props) {
	const { f } = useI18n();
	const dispatch = useDispatch();
	const [selectedUris, setSelectedUris] = useState(props.uris);
	const [groupingId, setGroupingId] = useState(localStorage.get(STORAGE_KEY));

	const onSubmit = () => {
		props.onSubmit(selectedUris);
	}

	const footer = (
		<ButtonGroup className="device-selector-buttons">
			<Button onClick={props.onCancel}>
				{f('cancel')}
			</Button>
			<Button onClick={onSubmit}>
				{f('save')}
			</Button>
		</ButtonGroup>
	);

	useEffect(() => {
		if (props.excludeUris) dispatch(deviceFilterActions.excludeUris({ excludeUris: props.excludeUris }));
		return () => {
			if (props.excludeUris) dispatch(deviceFilterActions.excludeUris({}));
		}
	}, [props.excludeUris]);

	useEffect(() => {
		if (props.setModalFooter) props.setModalFooter(footer);
	}, [selectedUris]);

	useEffect(() => {
		setSelectedUris(props.uris);
	}, [props.uris]);

	const onDeviceClick = (uri) => {
		const selected = [...selectedUris];
		if (selected.includes(uri)) {
			selected.splice(selected.indexOf(uri), 1);
		} else {
			selected.push(uri);
		}
		setSelectedUris(selected);
	}

	const onDevicesClick = (uris) => {
		const selected = [...selectedUris];
		const partially = uris.some(uri => !selected.includes(uri));
		uris.forEach(uri => {
			if (partially) {
				!selected.includes(uri) && selected.push(uri);
			} else {
				selected.splice(selected.indexOf(uri), 1);
			}
		});
		setSelectedUris(selected);
	}

	return (
		<DeviceSelectorContext.Provider value={{ selectedUris, onDeviceClick, onDevicesClick }}>
			<div className="device-selector">
				<GroupingPicker onChange={setGroupingId} categoryId={groupingId} />
				<DeviceSearchControl />
				<CategoryList groupingId={groupingId} itemType={CategoryListItem} />
			</div>
			{!props.setModalFooter && footer}
		</DeviceSelectorContext.Provider>
	)
}

export default DeviceSelector;
