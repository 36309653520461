import React from 'react';
import DatetimeField from '../../../../general/datetime/DatetimeField';
import { useI18n } from '../../../../../../i18n';
import FuelLevelBalance from '../../../../share/message/FuelLevelBalance';
import './tooltip.scss';

/**
 * @param {Object} props
 * @param {Object} props.value
 * @param {string} props.uri
 */

function Tooltip(props) {
	const { f } = useI18n();

	const getTruthValue = () => {
		if (props.value.dataName == 'refueled') {
			return props.value.y0 - props.value.y;
		} else if (props.value.dataName == 'drained' || props.value.dataName == 'consumed') {
			return props.value.y - props.value.y0;
		} else return props.value.y;
	}

	return (
		<div className="custom-tooltip unselectable">
			<div className="date-time">
				<DatetimeField datetime={props.value.x} />
			</div>
			<div className="main-info">
				<span className="capitalize label unselectable">{f(props.value.title) + ':'}&nbsp;</span>
				<FuelLevelBalance uri={props.uri} value={getTruthValue()} withUnits />
			</div>
		</div>
	);
}

export default Tooltip;