import React from 'react';
import { connect } from 'react-redux';
import { className, fromProps } from '../../../lib/className';
import { fc } from '../../../../i18n';
import DatetimeField from '../../general/datetime/DatetimeField';
import { DeviceDetailsProxy } from '../../../api/device'; // eslint-disable-line
import EventIcon from './EventIcon';
import PeerEncounterEventMessageField from './PeerEncounterEventMessageField';
import { getEventDetails } from '../../../../helpers/getEventDetails';

import './eventField.scss';

/**
 * @param {Object} props
 * @param {cx.ods.devices.EventDetails} [props.event]
 * @param {number} [props.eventType]
 * @param {boolean} [props.withoutIcon]
 * @param {function} [props.onClick]
 * @param {DeviceDetailsProxy} [props.device]
 * @param {boolean} [props.showDate]
 * @param {string} [props.uri]
 * @param {React.RefObject} [props.customRef]
 */

function EventField(props) {
	let content = null;
	let details = null;

	if (props.eventTypes && (props.event || props.eventType)) {
		const eventType = props.event && props.eventTypes[props.event.eventType] || props.eventTypes[props.eventType];
		const eventDetails = getEventDetails(props.event, eventType, props.zones);

		if (eventType.mnemonics == PeerEncounterEventMessageField.mnemonics) {
			details = (<PeerEncounterEventMessageField {...props} />);
		}
		content = (
			<div
				ref={props.customRef}
				className={className("event-field", fromProps(props), { 'clickable': !!props.onClick })} onClick={props.onClick}
			>
				{!props.withoutIcon && <EventIcon event={props.event} eventType={props.eventType} />}
				<div>
					<div>{fc({ prefix: 'device-event', id: eventType.name })}</div>
					{props.device && <div className="aux">{props.device.denomination()}</div>}
					{!!eventDetails && <div>{eventDetails}</div>}
					{props.event  &&
						<div className="aux">
							{props.showDate &&
								<DatetimeField datetime={props.event.generatedAt} unclickable />
							}
							{details}
						</div>
					}
				</div>
			</div>
		);
	}
	return content;
}

export default connect(
	(state, props) => ({
		eventTypes: state.registry.eventTypes.typeMap,
		device: props.device ? props.device : state.devices.map && state.devices.map[props.uri],
		zones: state.zones
	})
)(EventField);
