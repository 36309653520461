import { fromEPSG4326, toEPSG4326 } from 'ol/proj/epsg3857';
import { getDistance } from 'ol/sphere';
import { AbstractObjectProxy, makeProxy } from './proxy';

const NEARBY_DISTANCE = 500; // SI m

export class MessageDetailsProxy extends AbstractObjectProxy {

	lonLat() {
		const object = this.getObject();
		return [object.longitude, object.latitude];
	}

	flatCoords() {
		return fromEPSG4326(this.lonLat());
	}

	hasLonLat() {
		const object = this.getObject();
		return object.longitude && object.latitude;
	}

	sameLonLat(message) {
		const object = this.getObject();
		return message && message.longitude == object.longitude && message.latitude == object.latitude;
	}

	distanceTo(message) {
		return getDistance(this.lonLat(), [message.longitude, message.latitude]);
	}

	distanceToPoint(point, isFlat) {
		if (isFlat) point = toEPSG4326(point);
		return getDistance(this.lonLat(), point);
	}

	isEqual(message) {
		const object = this.getObject();
		return (
			message &&
			message.latitude == object.latitude &&
			message.longitude == object.longitude &&
			message.heading == object.heading &&
			message.generatedAt.getTime() == object.generatedAt.getTime()
		);
	}

	isNearby(message) {
		return getDistance([message.longitude, message.latitude], this.lonLat()) <= NEARBY_DISTANCE;
	}

	getMessage() {
		return this.getObject();
	}
}

export const messageProxy = makeProxy(MessageDetailsProxy);