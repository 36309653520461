import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import { useI18n } from 'i18n';
import {
  RangeFieldErrorStyled,
  RangeFieldsColumnStyled,
  RangeFieldsSeparatorStyled,
  RangeFieldsStyled,
  RangeFieldsWrapperStyled,
  RangeMnemonicStyled,
} from 'ui/pages/attribute-range-detectors/range-fields.styled';
import { useRangeBoundsFields } from 'hooks/attribute-range-detectors/useRangeBoundsFields';
import InputNumber from 'core/react/general/form/InputNumber';
import SelectPicker from 'core/react/general/form/SelectPicker';

interface IAttributeRangeFieldsProps {
  isDisabled: boolean;
  mnemonics: string;
}

export const RangeBoundsFields: FC<IAttributeRangeFieldsProps> = ({ isDisabled, mnemonics }) => {
  const { fc, f } = useI18n();

  const {
    control,
    boundModes,
    isLeftBound,
    isRightBound,
    units,
    boundsError,
    validateLeftBound,
    handleLeftBoundChange,
    validateRightBound,
    handleRightBoundChange,
    handleBoundModeChange,
  } = useRangeBoundsFields(mnemonics);

  return (
    <RangeFieldsWrapperStyled>
      <label>{fc('value range')}</label>
      <RangeFieldsStyled>
        <RangeFieldsColumnStyled>
          <Controller
            control={control}
            name="leftBound"
            rules={{ validate: validateLeftBound }}
            render={({ value }) => <InputNumber disabled={isDisabled} value={value} onChange={handleLeftBoundChange} />}
          />
          {isLeftBound && (
            <Controller
              control={control}
              name="leftOpen"
              render={({ value }) => (
                <SelectPicker
                  disabled={isDisabled}
                  data={boundModes}
                  searchable={false}
                  cleanable={false}
                  value={value}
                  onChange={handleBoundModeChange('leftOpen')}
                />
              )}
            />
          )}
        </RangeFieldsColumnStyled>
        <RangeFieldsSeparatorStyled>
          <span />
        </RangeFieldsSeparatorStyled>
        <RangeFieldsColumnStyled>
          <Controller
            control={control}
            name="rightBound"
            rules={{ validate: validateRightBound }}
            render={({ value }) => (
              <InputNumber disabled={isDisabled} value={value} onChange={handleRightBoundChange} />
            )}
          />
          {isRightBound && (
            <Controller
              control={control}
              name="rightOpen"
              render={({ value }) => (
                <SelectPicker
                  disabled={isDisabled}
                  data={boundModes}
                  searchable={false}
                  cleanable={false}
                  value={value}
                  onChange={handleBoundModeChange('rightOpen')}
                />
              )}
            />
          )}
        </RangeFieldsColumnStyled>
        {units && <RangeMnemonicStyled>{f({ prefix: 'units', id: units })}</RangeMnemonicStyled>}
      </RangeFieldsStyled>
      {!!boundsError && <RangeFieldErrorStyled>{fc(boundsError)}</RangeFieldErrorStyled>}
    </RangeFieldsWrapperStyled>
  );
};
