import React, { FC } from 'react';
import { HeaderGroup } from 'react-table';

import { ColumnHeaderStyled } from 'ui/table.styled';
import { IHeaderGroup } from 'types/table';

interface IColumnHeaderProps {
  column: HeaderGroup<Record<string, unknown>>;
}

export const ColumnHeader: FC<IColumnHeaderProps> = ({ column }) => {
  const getHeaderProps = (column: IHeaderGroup) => ({
    ...column.getHeaderProps([{ style: { ...(column.isFlexUnset && { flex: 'unset' }) } }]),
  });

  return (
    <ColumnHeaderStyled {...getHeaderProps(column)}>
      <span>{column.render('Header')}</span>
    </ColumnHeaderStyled>
  );
};
