import React, { Dispatch, FC } from 'react';
import { Row } from 'react-table';

import { PreloaderWrapperStyled, TableContentStubStyled, TableNotContentFound } from 'ui/table.styled';
import { PreloaderStyled } from 'ui/atoms/preloader.styled';
import { fc } from 'i18n';
import { TableRow } from './table-row';

interface ITableContent {
  isLoading: boolean;
  data: Record<string, unknown>[] | null;
  rows: Row<Record<string, unknown>>[];
  prepareRow: Dispatch<Row<Record<string, unknown>>>;
}

export const TableContent: FC<ITableContent> = (props) => {
  const { isLoading, data, rows, prepareRow } = props;

  if (isLoading) {
    return (
      <TableContentStubStyled>
        <PreloaderWrapperStyled>
          <PreloaderStyled />
        </PreloaderWrapperStyled>
        {rows.map((row, index) => {
          prepareRow(row);

          return <TableRow key={index} index={index} row={row} />;
        })}
      </TableContentStubStyled>
    );
  }

  if (!isLoading && !data?.length) {
    return (
      <TableContentStubStyled>
        <TableNotContentFound>
          <span>{fc('no content found')}</span>
        </TableNotContentFound>
      </TableContentStubStyled>
    );
  }

  if (data) {
    return (
      <div>
        {rows.map((row, index) => {
          prepareRow(row);

          return <TableRow key={index} index={index} row={row} />;
        })}
      </div>
    );
  }

  return null;
};
