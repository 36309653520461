import React from 'react';

function CustomAxisLabel(props) {

	const yLabelOffset = {
		y: props.marginTop + props.innerHeight / 2 + props.title.length*2,
		x: 10
	};

	const xLabelOffset = {
		x: props.marginLeft + (props.innerWidth)/2 - props.title.length*2,
		y: 1.25 * props.innerHeight
	};

	const transform = props.xAxis
		? `translate(${xLabelOffset.x}, ${xLabelOffset.y})`
		: `translate(${yLabelOffset.x}, ${yLabelOffset.y}) rotate(-90)`
	;

	return (
		<g transform={transform}>
			<text className='unselectable axis-labels'>
				{props.title}
			</text>
		</g>
	);
}

CustomAxisLabel.displayName = 'CustomAxisLabel';
CustomAxisLabel.requiresSVG = true;
export default CustomAxisLabel;