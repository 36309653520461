import styled from 'styled-components';

import { Colors } from 'ui/constants/colors';
import { FieldErrorStyled } from './detector-form.styled';

export const RangeFieldsWrapperStyled = styled.div`
  position: relative;
`;

export const RangeFieldsStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

export const RangeFieldsColumnStyled = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    &:nth-of-type(2) {
      margin-top: 15px;
    }
  }
`;

export const RangeFieldsSeparatorStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 5px;
  width: 50px;
  height: 100%;

  & > span {
    width: 100%;
    border: 1px solid ${Colors.PurpleCrystal};
  }
`;

export const RangeMnemonicStyled = styled.div`
  margin: 7px 0 0 10px;
`;

export const RangeFieldErrorStyled = styled(FieldErrorStyled)`
  position: absolute;
  margin-top: 0;
  bottom: -17px;
`;
