import { EMPTY, concat, defer } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';


export const throttleMap = project => source => defer(() => {
	let projecting = false, last = null;
	const next = (value, index) => defer(() => {
		if (projecting) {
			last = {value, index};
			return EMPTY;
		}
		last = null;
		projecting = true;
		return project(value, index).pipe(
			tap({ complete: () => projecting = false})
			, source => concat(source, defer(() => {
				return last != null ? next(last.value, last.index) : EMPTY
			}))
		);
	});
	return source.pipe(mergeMap(next));
});
