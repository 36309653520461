import React, { useImperativeHandle } from 'react';

import { connect } from 'react-redux';

import { useI18n } from '../../../../../i18n';
import { cx } from '../../../../api';

import { actions } from '../../../../redux/api/reports';

import ObjectDeviceManager from "../../../share/devices/ObjectDeviceManager";

import './parametrizator.scss';

export default connect(state => ({
	state: state.reports.deviceUtilization
}))(props => {
	const {f} = useI18n();

	useImperativeHandle(props.customRef, () => ({
		submit: values => {
			const parameters = new cx.ods.reports.UtilizationReportParameters();
			parameters.timeRange = new cx.ods.TimeRange({since: props.timeRange.since, until: props.timeRange.until});
			if (0 < values.uris?.length) parameters.uris = values.uris;
			props.dispatch(actions.deviceUtilization.generate.request({parameters}));
		}
		, clear: () => {
			props.dispatch(actions.deviceUtilization.export.clear());
 		}
	}));

	const {disabled, state: {parameters}} = props;
	return <div className="device-utilization-parameters">
		<ObjectDeviceManager
			controlName="uris"
			title={f('devices restriction')}
			disabled={disabled}
			emptyMessage={f('all devices')}
			editMode
			uris={parameters?.uris}
		/>
	</div>;
});
