export interface IDebounced {
  (...args: unknown[]): void;
  cancel: () => void;
}

export interface IDebounce {
  // eslint-disable-next-line
  (callback: (...args: any[]) => unknown, delay?: number): IDebounced;
}

export const debounce: IDebounce = (callback, delay = 1000) => {
  let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined;

  const cancel = () => clearTimeout(timeoutId);

  const debounced: IDebounced = (...args) => {
    timeoutId && cancel();

    timeoutId = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  debounced.cancel = cancel;

  return debounced;
};
