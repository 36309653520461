import React from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../../i18n';
import List from '../../../../general/list/List';
import NoDataView from '../../../../general/view/NoDataView';
import { className } from '../../../../../lib/className';
import './deviceTop.scss';

/**
 * @param {Object} props
 * @param {Object} props.deviceTopItem
 * @param {string} props.type
 * @param {number} props.at
 */

function _DeviceTopItem(props) {
	const { p } = useI18n();
	const quantity = props.deviceTopItem.quantity;

	return (
		<div className="device">
			<div className="at">
				<div>{props.at}</div>
			</div>
			<div className="info">
				<div className="name">
					{props.device && props.device.denomination()}
				</div>
				<div className="quantity">
					{p(('X ' + props.type), quantity)}
				</div>
			</div>
		</div>
	);
}

const DeviceTopItem = connect(
	(state, props) => ({
		device: state.devices.map && state.devices.map[props.deviceTopItem.uri]
	})
)(_DeviceTopItem);

/**
 * @param {Object} props
 * @param {cx.ods.stomach.DeviceTop} props.deviceTop
 * @param {string} props.type
 */

function DeviceTop(props) {
	const { f } = useI18n();
	let topItems = null;
	if (props.deviceTop) {
		const counters = props.deviceTop.counters;
		if (counters) {
			topItems = (
				counters.map((topItem, i) => {
					return (
						<DeviceTopItem
							key={topItem.uri}
							at={i + 1}
							deviceTopItem={topItem}
							type={props.type}
						/>
					);
				})
			);
		} else topItems = (<NoDataView />);

	}

	return (
		<List
			bordered={false}
			className={className("device-top-list", { 'empty': !props.deviceTop || props.deviceTop && !props.deviceTop.counters })}
		>
			<div className="header">
				{f('device top')}
			</div>
			{topItems}
		</List>
	);
}

export default DeviceTop;
