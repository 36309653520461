import React, { Dispatch, FC } from 'react';

import { DetectorsFilterStyled, DetectorsListStyled } from 'ui/pages/attribute-range-detectors/detectors-list.styled';
import { useI18n } from 'i18n';
import { IAttributeRangeDetector } from 'types/detectors';
import { className } from 'core/lib/className';
import Intent from 'core/misc/ObjectActionIntent';
import Input from 'core/react/general/form/Input';
import List from 'core/react/general/list/List';
import { AttributeDetectorListItem } from './attribute-detector-list-item';

interface IAttributeDetectorsList {
  selectedId: number | null;
  filter: string;
  detectors: IAttributeRangeDetector[];
  setFilter: Dispatch<string>;
  onSelect: Dispatch<IAttributeRangeDetector>;
  onIntent: Dispatch<Intent>;
}

export const AttributeDetectorsList: FC<IAttributeDetectorsList> = (props) => {
  const { selectedId, filter, detectors, setFilter, onSelect, onIntent } = props;

  const { f, fc } = useI18n();

  return (
    <>
      <DetectorsFilterStyled>
        <Input label={f('filter')} value={filter} onChange={setFilter} cleanable />
      </DetectorsFilterStyled>
      <DetectorsListStyled>
        <label>{fc('attribute range detectors')}</label>
        {!!detectors?.length && (
          <List>
            {detectors.map((detector) => (
              <AttributeDetectorListItem
                className={className({ selected: selectedId && selectedId === detector.processorId })}
                key={detector.processorId}
                detector={detector}
                onSelect={onSelect}
                onIntent={onIntent}
              />
            ))}
          </List>
        )}
      </DetectorsListStyled>
    </>
  );
};
