import { ofType, combineEpics } from 'redux-observable';
import { map as rxmap } from 'rxjs/operators';
import { cx } from '../../../api';
import { reduxSwitch } from '../../tools';
import { actions as categoriesActions } from '../../api/categories';
import { ActionGeneratorBuilder } from '../../actions';

const ROOT_MNEMONICS = "group";

const defaultState = {
	root: null,
	list: null,
	map: null,
	pending: false,
	error: null
};

const actions = new ActionGeneratorBuilder('groupCategories')
	.subtype('add', add => add.request('data').success('data').fail())
	.subtype('load', load => load.request().success('root').fail())
	.subtype('update', update => update.request({ categoryId: true, data: true }).success().fail())
	.subtype('remove', remove => remove.request('categoryId').success().fail())
	.type('set', { root: true, categories: true, map: true })
	.type('clear')
	.build()
;

// ---------------------------------------------------------



function loadReducer(state, action) {
	switch (action.type) {
		case actions.load.request.type:
			return {
				...state,
				pending: true,
				error: null
			};
		case categoriesActions.load.success.type:
			return {
				...state,
				pending: false
			}
		case categoriesActions.load.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		case actions.set.type:
			return {
				...state,
				pending: false,
				root: action.root,
				list: action.categories,
				map: action.map
			};
		default:
			return state;
	}
}

function addReduecer(state, action) {
	switch (action.type) {
		case actions.add.request.type:
			return {
				...state,
				pending: true,
				error: null
			};
		case categoriesActions.add.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		default:
			return state;
	}
}

function updateReducer(state, action) {
	switch (action.type) {
		case actions.update.request.type:
			return {
				...state,
				pending: true,
				error: null
			};
		case categoriesActions.update.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		default:
			return state;
	}
}

function removeReducer(state, action) {
	switch (action.type) {
		case actions.remove.request.type:
			return {
				...state,
				pending: true,
				error: null
			};
		case categoriesActions.remove.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		default:
			return state;
	}
}

function clearReducer(state, action) {
	switch (action.type) {
		case actions.clear.type:
			return {
				...state,
				error: null
			};
		default:
			return state;
	}
}

const reducer = reduxSwitch([loadReducer, addReduecer, updateReducer, removeReducer, clearReducer], defaultState);

// ---------------------------------------------------------

const filter = (categories) => {
	const root = categories.find(category => category.mnemonics == ROOT_MNEMONICS);
	const filtered = categories.filter(category =>
		category.comprisingIds && category.comprisingIds.indexOf(root.categoryId) >= 0
	);
	filtered.sort((left, right) => {
		return left.name && right.name ? (left.name.toLowerCase() > right.name.toLowerCase()) : left.categoryId > right.categoryId;
	});
	return { root, categories: filtered, map: cx.i.hash(filtered, (category) => category.categoryId) };
}

const loadEpic = (action$) => {
	return action$.pipe(
		ofType(actions.load.request.type),
		rxmap(action => categoriesActions.load.request()),
	)
}

const addEpic = (action$) => {
	return action$.pipe(
		ofType(actions.add.request.type),
		rxmap(action => categoriesActions.add.request({ data: action.data }))
	)
}

const updateEpic = (action$) => {
	return action$.pipe(
		ofType(actions.update.request.type),
		rxmap(action => categoriesActions.update.request({ categoryId: action.categoryId, data: action.data }))
	)
}

const removeEpic = (action$) => {
	return action$.pipe(
		ofType(actions.remove.request.type),
		rxmap(action => categoriesActions.remove.request({ categoryId: action.categoryId }))
	)
}

const watchCategoriesLoadEpic = (action$, state$) => {
	return action$.pipe(
		ofType(categoriesActions.load.success.type),
		rxmap(action => actions.set(filter(state$.value.categories.general.list)))
	)
}

const epic = combineEpics(loadEpic, addEpic, updateEpic, removeEpic, watchCategoriesLoadEpic);

// ---------------------------------------------------------

export { actions, reducer, epic };
