import React, { useImperativeHandle, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { Checkbox } from 'rsuite';
import { useI18n } from '../../../../i18n';
import DeviceAssetTypeIcon from '../../share/devices/DeviceAssetTypeIcon';
import * as deviceTrace from '../../../redux/app/deviceTrace';
import * as appDeviceEvents from '../../../redux/app/appDeviceEvents';
import List from '../../general/list/List';
import ColorPickerControl, { ColorFormat } from '../../general/color/ColorPickerControl';
import WidgetErrorContent from '../../general/widgets/WidgetErrorContent';
import MapOptionDisplayTrace from '../map/controls/MapOptionDisplayTrace';
import MapOptionDisplayTraceDirection from '../map/controls/MapOptionDisplayTraceDirection';
import MapOptionDisplayEvents from '../map/controls/MapOptionDisplayEvents';
import './timeMachineSettingsWidget.scss';

/**
 * @param {Object} props
 * @param {boolean} props.checked
 * @param {string} props.uri
 * @param {boolean} [props.displayTrace]
 * @param {function} props.onFocus to focus WidgetContainer
 */

function _DeviceSettingItem(props) {

	const onChangeColor = (color) => {
		props.dispatch(deviceTrace.actions.setTraceColor({ domain: props.reduxKey, uri: props.uri, color }));
	}

	const onChangeSelectTrace = () => {
		props.dispatch(deviceTrace.actions.toggleTraceUri({ domain: props.reduxKey, uri: props.uri }));
	}

	const onChangeSelectEvents = () => {
		props.dispatch(appDeviceEvents.actions.toggleEventsUri({ domain: props.reduxKey, uri: props.uri }));
	}

	useEffect(() => {
		if (!props.color) props.dispatch(deviceTrace.actions.setTraceColor({ domain: props.reduxKey, uri: props.uri }));
	}, []);

	return (
		<div className="item">
			<div>
				<DeviceAssetTypeIcon categoryIds={props.device && props.device.categoryIds} />
			</div>
			<div className="device-name">
				<label>
					{props.device && props.device.denomination()}
				</label>
			</div>
			<Checkbox disabled={props.disabledEvents} checked={props.checkedEvents} onChange={onChangeSelectEvents} />
			<ColorPickerControl
				onOpenChange={props.onFocus}
				disabled={props.displayTrace}
				colorFormat={ColorFormat.Hex}
				value={props.color}
				onChange={onChangeColor}
			/>
			<Checkbox disabled={props.displayTrace} checked={props.checkedTrace} onChange={onChangeSelectTrace} />
		</div>
	);
}

const DeviceSettingItem = connect((state, props) => ({
	device: state.devices.map && state.devices.map[props.uri],
	color: state.deviceTrace[props.reduxKey].traceColorMap[props.uri],
	checkedTrace: state.deviceTrace[props.reduxKey] && state.deviceTrace[props.reduxKey].traceUriMap[props.uri],
	checkedEvents: state.appDeviceEvents[props.reduxKey] && state.appDeviceEvents[props.reduxKey].eventsUriMap[props.uri]
}))(_DeviceSettingItem);


function _DeviceSettingItemHeader(props) {
	const { f } = useI18n();

	const handleCheckAllTrace = (value, checked) => {
		props.dispatch(deviceTrace.actions.setAllTrace({ domain: props.reduxKey, all: checked }));
	}

	const handleCheckAllEvents = (value, checked) => {
		props.dispatch(appDeviceEvents.actions.setAllEvents({ domain: props.reduxKey, all: checked }));
	}

	return (
		<div className="item header">
			<div className="device-name">
				<label>
					<span className="capitalize">{f('device')}</span>
				</label>
			</div>
			<div className="checkbox-custom">
				<label>
					<span className="capitalize">{f('events')}</span>
				</label>
				<Checkbox
					inline
					disabled={!props.displayEvents}
					indeterminate={props.selectedEventsUris && props.selectedEventsUris.length > 0 && !props.allEvents}
					checked={props.allEvents}
					onChange={handleCheckAllEvents}
				/>
			</div>
			<div className="checkbox-custom">
				<label>
					<span className="capitalize">{f('trace')}</span>
				</label>
				<Checkbox
					inline
					disabled={!props.displayTrace}
					indeterminate={props.selectedTraceUris && props.selectedTraceUris.length > 0 && !props.allTrace}
					checked={props.allTrace}
					onChange={handleCheckAllTrace}
				/>
			</div>

		</div>
	);
}

const DeviceSettingItemHeader = connect((state, props) => ({
	displayTrace: state.deviceTrace[props.reduxKey] && state.deviceTrace[props.reduxKey].display,
	allTrace: state.deviceTrace[props.reduxKey] && state.deviceTrace[props.reduxKey].allTrace,
	selectedTraceUris: state.deviceTrace[props.reduxKey] && state.deviceTrace[props.reduxKey].selectedTraceUris,
	displayEvents: state.appDeviceEvents[props.reduxKey] && state.appDeviceEvents[props.reduxKey].display,
	allEvents: state.appDeviceEvents[props.reduxKey] && state.appDeviceEvents[props.reduxKey].allEvents,
	selectedEventsUris: state.appDeviceEvents[props.reduxKey] && state.appDeviceEvents[props.reduxKey].selectedEventsUris
}))(_DeviceSettingItemHeader);

/**
 * @param {Object} props
 * @param {string} props.uid
 * @param {React.RefObject} props.customRef
 * @param {function} props.update to rerender WidgetContainer
 * @param {function} props.onFocus to focus WidgetContainer
 */

function TimeMachineSettingsWidget(props) {
	const { f, fc } = useI18n();
	const traceUris = props.traceUriMap && Object.keys(props.traceUriMap);

	let content = null;

	useImperativeHandle(props.customRef, () => ({
		title: () => fc('time machine settings'),
		dynamicContent: true,
		expandable: traceUris && traceUris.length > 0
	}));

	useLayoutEffect(() => {
		props.update();
	}, [props.traceUriMap]);

	if (traceUris && traceUris.length > 0) {
		content = (<>
			<div>
				<div className="inline-block">
					<MapOptionDisplayTrace label={f('traces')} />
					<MapOptionDisplayTraceDirection label={f('trace direction')} />
				</div>
				<div className="inline-block">
					<MapOptionDisplayEvents label={f('events')}  />
				</div>
			</div>
			<List>
				<DeviceSettingItemHeader reduxKey={props.reduxKey} />
				{traceUris.map((uri) => {
					return (
						<DeviceSettingItem
							key={uri}
							uri={uri}
							reduxKey={props.reduxKey}
							displayTrace={!props.displayTrace}
							disabledEvents={!props.displayEvents}
							onFocus={props.onFocus}
						/>
					);
				})}
			</List>
		</>);
	} else {
		content = (<WidgetErrorContent message={f('please select device')} />);
	}

	return (
		<div className="time-machine-settings widget">
			{content}
		</div>
	);
}

export default connect((state, props) => ({
	traceUriMap: state.deviceTrace[props.reduxKey] && state.deviceTrace[props.reduxKey].traceUriMap,
	displayTrace: state.deviceTrace[props.reduxKey] && state.deviceTrace[props.reduxKey].display,
	selectedTraceUris: state.deviceTrace[props.reduxKey] && state.deviceTrace[props.reduxKey].selectedTraceUris,
	allTrace: state.deviceTrace[props.reduxKey] && state.deviceTrace[props.reduxKey].allTrace,
	displayEvents: state.appDeviceEvents[props.reduxKey] && state.appDeviceEvents[props.reduxKey].display
}))(TimeMachineSettingsWidget);
