import React from 'react';
import { connect } from 'react-redux';
import { fc } from '../../../../i18n';
import { className } from '../../../lib/className';
import './eventIcon.scss';

/**
 * @param {Object} props
 * @param {cx.ods.devices.EventDetails} [props.event]
 * @param {number} [props.eventType]
 */

function EventIcon(props) {
	let { event, eventType, eventTypes } = props;
	let content = null;

	if (eventTypes && (event || eventType)) {
		const _eventType = event && eventTypes[event.eventType] || eventTypes[eventType];
		content = (
			<span
				className={className('event-icon', _eventType.mnemonics, { 'incident': _eventType.incident })}
				title={fc({ prefix: 'device-event', id: _eventType.name })}
			></span>
		);
	}

	return content;
}

export default connect(state => {
	return {
		eventTypes: state.registry.eventTypes.typeMap
	};
})(EventIcon);