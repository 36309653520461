import { cx, api, rx } from "../../../../api";
import { ofType, combineEpics } from 'redux-observable';
import { switchMap, takeUntil, map as rxmap, filter, tap, ignoreElements } from 'rxjs/operators';
import { formatDateTime, getTimezoneString } from '../../../../misc/misc';
import { resolver } from './resolver';
import { actions } from '../actions';
import { fc, f } from "../../../../../i18n";
import { errorMap } from "../../../actions";
import { getEventDetails } from '../../../../../helpers/getEventDetails';

const requestEpic = (action$) => {
	return action$.pipe(
		ofType(actions.deviceSituations.request.type),
		switchMap(action =>
			rx(api.reports.deviceSituations, action.parameters).pipe(
				rxmap(operation => actions.deviceSituations.success({ situations: operation.response() })),
				errorMap(actions.deviceSituations.fail),
				takeUntil(action$.pipe(ofType(actions.deviceSituations.cancel.type)))
			)
		)
	)
}

const startPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.deviceSituations.export.type),
		rxmap(action => {
			const state = state$.value.reports.deviceSituations;
			resolver.resolve(state.list, !state.hasMore);
			if (state.hasMore) {
				return actions.deviceSituations.request({ parameters: state.parameters });
			} else {
				return actions.deviceSituations.success({});
			}
		})
	)
}

const processPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.deviceSituations.success.type),
		filter(action => {
			const state = state$.value.reports.deviceSituations;
			if (state.exporting) resolver.resolve(action.situations, !state.hasMore);
			return state.exporting && state.hasMore;
		}),
		rxmap(action => {
			const state = state$.value.reports.deviceSituations;
			return actions.deviceSituations.request({ parameters: state.parameters });
		})
	)
}

const exportCsvEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.deviceSituations.exportProgress.type),
		filter(action => action.progress == 100),
		rxmap(action => {
			const state = state$.value.reports.deviceSituations;
			const eventTypes = state$.value.registry.eventTypes.typeMap;
			// report header
			let csv = '"'
				+ fc('report type')
				+ '",'
				+ fc('generated')
				+ ','
				+ fc('timezone')
				+ ','
				+ fc('since')
				+ ','
				+ fc('until')
				+ '\n';
			csv += fc('device situations');  // report type
			csv += ',"' + formatDateTime(cx.now()) + '"'; // generated at
			csv += ',' + getTimezoneString(); // timezone at
			csv += ',"' + formatDateTime(state.parameters.timeRange.since) + '"';
			csv += ',"' + formatDateTime(state.parameters.timeRange.until) + '"';
			csv += "\n\n";
			// content header
			csv += fc('time')
				+ ',' + f('URI') + ',"'
				+ fc('device name')
				+ '",'
				+ fc('event')
				+ ','
				+ fc('details')
				+ ','
				+ fc('location')
				+ ','
				+ fc('address');
			if (state.parameters.markers) {
				csv += ',' + fc('marker');
			}
			csv += "\n";
			// content
			state.list.forEach(situation => {
				const device = state$.value.devices.map[situation.uri];
				situation.events && situation.events.forEach(event => {
					const eventType = eventTypes[event.eventType];
					const eventDetails = getEventDetails(event, eventType, state$.value.zones) || '-';

					csv += '"' + formatDateTime(event.generatedAt) + '",'; // time
					csv += '"' + device.uri + '"';
					csv += ',"' + (device.denomination() || '-') + '"';
					csv += ',"' + eventType.name + '"'; // event
					csv += ',"' + eventDetails + '"';
					if (event.message && event.message.latitude && event.message.longitude) { // location, address
						csv += ",(" + event.message.latitude + ";" + event.message.longitude + ")";
						const entryKey = resolver.key(event.message.latitude, event.message.longitude);
						const address = resolver.resolved[entryKey]?.getAddress();
						if (address) csv += ',"' + address.format() + '"';
						else csv += ",-";
					} else {
						csv += ',-,-';
					}
					if (state.parameters.markers) {
						csv += ',' + (situation.marker ? situation.marker.message.fields.mapped[19] : "-");
					}
					csv +=  "\n";
				});
			});
			return actions.deviceSituations.exportDone({ csv });
		}),
	)
}

const exportClearEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.deviceSituations.exportClear.type),
		tap(() => resolver.clear()),
		ignoreElements()
	);
}

const epic = combineEpics(requestEpic, startPrepareEpic, processPrepareEpic, exportCsvEpic, exportClearEpic);

export { epic };
