import React, { useRef, useEffect, useState } from 'react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { className, fromProps } from '../../lib/className';
import './galleryCards.scss';

const CARD_WIDTH = 60;
const CARD_MARGIN = 7;

const GALLERY_SIZE = {
	xs: { CARD_WIDTH, CARD_MARGIN, SIZE: 'xs' },
	s: {
		CARD_WIDTH: Math.floor(CARD_WIDTH * 1.5),
		CARD_MARGIN: Math.floor(CARD_MARGIN * 1.5),
		SIZE: 's'
	},
	m: {
		CARD_WIDTH: CARD_WIDTH * 2,
		CARD_MARGIN: CARD_MARGIN * 2,
		SIZE: 'm'
	},
	l: {
		CARD_WIDTH: Math.floor(CARD_WIDTH * 2.5),
		CARD_MARGIN: Math.floor(CARD_MARGIN * 2.5),
		SIZE: 'l'
	},
	xl: {
		CARD_WIDTH: CARD_WIDTH * 3,
		CARD_MARGIN: CARD_MARGIN * 3,
		SIZE: 'xl'
	}
}
/**
 * @param {Object} props
 * @param {Array.<Component>} props.cards
 * @param {GALLERY_SIZE} [props.size]
 */

function GalleryCards(props) {
	const size = props.size || GALLERY_SIZE.m;
	const fullCardWidth = size.CARD_WIDTH + size.CARD_MARGIN;
	const clearboxWidth = size.CARD_MARGIN;
	const [quantityCard, setQuantityCard] = useState(null);
	const [maxWidth, setMaxWidth] = useState(0);
	const wrapRef = useRef();
	const galleryBox = useRef();
	const [visibleLeft, setVisibleLeft] = useState(false);
	const [visibleRight, setVisibleRight] = useState(false);
	const [scrollable, setScrollable] = useState(false);

	const setUnvisible = () => {
		setVisibleLeft(false);
		setVisibleRight(false);
	}

	const updateVisibles = (element, scrollLeft) => {
		setVisibleLeft(0 < scrollLeft);
		setVisibleRight(scrollLeft < element.scrollWidth - element.clientWidth);
	}

	const updateGalleryBox = () => {
		const maxWidth = wrapRef.current.getBoundingClientRect().width;
		const _quantityCard = Math.floor((maxWidth - size.CARD_MARGIN - clearboxWidth)/fullCardWidth);
		setMaxWidth(maxWidth);
		setQuantityCard(_quantityCard);
		setScrollable(props.children.length > _quantityCard);
	}

	useEffect(() => {
		const resetQuantityCard = () => {
			setQuantityCard(null);
		}
		const mouseEnterHandler = (event) => {
			if (galleryBox.current) {
				updateVisibles(galleryBox.current, galleryBox.current.scrollLeft);
			}
		}
		const mouseLeaveHandler = (event) => {
			setUnvisible();
		}

		window.addEventListener('resize', resetQuantityCard);
		wrapRef.current.addEventListener('mouseenter', mouseEnterHandler);
		wrapRef.current.addEventListener('mouseleave', mouseLeaveHandler);
		return () => {
			window.removeEventListener('resize', resetQuantityCard);
			wrapRef.current.removeEventListener('mouseenter', mouseEnterHandler);
			wrapRef.current.removeEventListener('mouseleave', mouseLeaveHandler);
		}
	}, []);

	useEffect(() => {
		if (quantityCard && props.children.length > quantityCard != scrollable) {
			setScrollable(props.children.length > quantityCard);
		}
	}, [props.children.length, quantityCard]);

	useEffect(() => {
		const handleWheel = (event) => {
			event.preventDefault();
			let left = 0;
			if (event.deltaY < 0) {
				left = getStartScrollPosition(galleryBox.current) * fullCardWidth - fullCardWidth * Math.ceil(quantityCard/3);
			} else {
				left = getStartScrollPosition(galleryBox.current) * fullCardWidth + fullCardWidth * Math.ceil(quantityCard/3);
			}
			scrollTo(left);
		}

		const touchstartHandle = (event) => {
			if (event.touches.length == 1) {
				setUnvisible();
			}
		}
		if (!quantityCard) updateGalleryBox();
		if (galleryBox.current && scrollable) {
			galleryBox.current.addEventListener('touchstart', touchstartHandle);
			galleryBox.current.addEventListener('wheel', handleWheel);
		}
		return () => {
			if (galleryBox.current) {
				galleryBox.current.removeEventListener('touchstart', touchstartHandle);
				galleryBox.current.removeEventListener('wheel', handleWheel);
			}
		}
	}, [quantityCard, scrollable]);

	const scrollTo = (left) => {
		galleryBox.current.scrollTo({
			left,
			behavior: 'smooth'
		});
		updateVisibles(galleryBox.current, left);
	}

	const getStartScrollPosition = (element) => {
		return Math.floor(element.scrollLeft/fullCardWidth);
	}

	const onLeft = () => {
		scrollTo(getStartScrollPosition(galleryBox.current) * fullCardWidth - fullCardWidth * quantityCard);
	}

	const onRight = () => {
		scrollTo(getStartScrollPosition(galleryBox.current) * fullCardWidth + fullCardWidth * quantityCard);
	}

	const cardStyle = {
		minWidth: size.CARD_WIDTH,
		maxWidth: size.CARD_WIDTH,
		height: '100%',
		marginRight: size.CARD_MARGIN,
		border: '1px solid #e5e5ea',
		borderRadius: '5px',
		boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.08),0 1px 1px 0 rgba(0, 0, 0, 0.00)'
	}

	const children = React.Children.map(props.children, (child) => {
		return <div className="card" key={child.key} style={cardStyle}>
			{React.cloneElement(child, {
				disabled: props.disabled,
				error: props.error
			})}
		</div>;
	});

	return (
		<div className={className("gallery-cards", size.SIZE, fromProps(props))} ref={wrapRef}>
			{quantityCard &&
				<div className="gallery-cards-wrap" style={{ maxWidth }}>
					<div className={className("gallery-arrow left clickable", { visible: visibleLeft })} onClick={onLeft}>
						<FiArrowLeft />
					</div>
					<div className="gallery-content-inner">
						<div className="gallery-content" ref={galleryBox}>
							{children}
							<div className="clear-box" style={{ minWidth: clearboxWidth, maxWidth: clearboxWidth, width: clearboxWidth }}></div>
						</div>
					</div>
					<div className={className("gallery-arrow right clickable", { visible: visibleRight })} onClick={onRight}>
						<FiArrowRight />
					</div>
				</div>
			}
		</div>
	);
}

GalleryCards.size = GALLERY_SIZE;

export default GalleryCards;