import React from 'react';
import { withRouter } from 'react-router-dom';
import { FiHome } from 'react-icons/fi';
import { useI18n } from '../../../../../i18n';
import MapButton from '../../map/MapButton';

function DashboardMapButton(props) {
	const { fc } = useI18n();

	const onClick = () => {
		let path = '/dashboard';
		if (props.match.params.uris) {
			path += '/' + props.match.params.uris;
		}
		props.history.push(path);
	}

	return (
		<MapButton onClick={onClick} title={fc('dashboard')}>
			<FiHome />
		</MapButton>
	)
}

export default withRouter(DashboardMapButton);