export enum Colors {
  Black = '#000000',
  BlackRussian = '#00000029',
  UsafaBlue = '#005095',
  DarkCobaltBlue = '#33558B',
  BlueSmoke = '#D7DFE2',
  SilverPhoenix = '#ECEFF6',
  Lochinvar = '#2A9995',
  Keppel = '#3EA39F',
  AquaBelt = '#79cdd0',
  KhmerCurry = '#ED4F4F',
  RossoCorsa = '#d60000',
  Akabeni = '#C92828',
  ShadowedAkabeni = '#C9282899',
  MaximumRed = '#d42b22',
  White = '#ffffff',
  WhiteSmoke = '#F0F0F0',
  Concrete = '#F2F2F2',
  PinkSmoke = '#FFEFEF',
  SnowFlake = '#F1F2F2',
  LynxWhite = '#f7f7f7',
  PurpleCrystal = '#e5e5ea',
  DistantHorizon = '#F9FDFD',
  SuperSilver = '#f0f0f0',
  SilverLight = '#CCCCCC',
  DreamscapeGrey = '#C7C5C5',
  Alto = '#DADADA',
  SilverSand = '#BABBBB',
  SilverPolish = '#C7C7C7',
  SilverMedal = '#d6d6d6',
  Pewter = '#959B9A',
  ShadowMountain = '#5A5A5A',
  DoveGray = '#707070',
  Boulder = '#777777',
  SilverColor = '#bfbfbf',
}
