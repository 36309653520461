import { cx, api, rx } from '../../api';
import { reduxSwitch } from '../tools';
import { ofType, combineEpics } from 'redux-observable';
import { exhaustMap, mergeMap, map as rxmap, takeUntil } from 'rxjs/operators';
import { actions as sessionActions } from './session';
import { deviceProxy } from '../../api/device';
import { ActionGeneratorBuilder, errorMap } from '../actions';

const defaultState = {
	list: null,
	map: null, // uri => DeviceDetails
	categoriesMap: null, // categoryId => [DeviceDetails]
	pending: false,
	error: null
};

const actions = new ActionGeneratorBuilder('devices')
	.subtype('load', load => load.request().success('details').fail().cancel())
	.subtype('update', update => update.request({ uri: true, deviceInfo: true }).success().fail().cancel())
	.build()
;

// ---------------------------------------------------------

function processDevices(devices) {
	let map = cx.i.hash(devices, (device) => device.uri);
	let categoriesMap = {};
	devices.forEach(device => {
		device.categoryIds && device.categoryIds.forEach((categoryId) => {
			if (!categoriesMap[categoryId]) categoriesMap[categoryId] = [];
			categoriesMap[categoryId].push(device);
		});
		// !device.categoryIds && categoriesMap[0].push(device);
	});
	return {map, categoriesMap};
}

function loadReducer(state, action) {
	switch (action.type) {
		case actions.load.request.type:
			state = {
				...state,
				pending: true
			};
			break;
		case actions.load.success.type:
			const proxies = action.details && action.details.map(device => deviceProxy(device));
			state = {
				...state,
				list: proxies,
				...processDevices(proxies),
				pending: false,
				error: null
			};
			break;
		case actions.load.fail.type:
			state = {
				...state,
				pending: false,
				error: action.errorMessage
			};
			break;
		case actions.load.cancel.type:
			state = {
				...state,
				pending: false
			};
			break;
		default:
	}
	return state;
}

function updateReducer(state, action) {
	switch (action.type) {
		case actions.update.request.type:
			state = {
				...state,
				pending: true
			};
			break;
		case actions.update.success.type:
			state = {
				...state,
				pending: false
			}
			break;
		case actions.update.fail.type:
			state = {
				...state,
				pending: false,
				error: action.errorMessage
			};
			break;
		case actions.update.cancel.type:
			state = {
				...state,
				pending: false
			}
			break;
		default:
	}
	return state;
}

const reducer = reduxSwitch([loadReducer, updateReducer], defaultState);

// ---------------------------------------------------------

const loadEpic = (action$) => {
	return action$.pipe(
		ofType(actions.load.request.type),
		exhaustMap(action =>
			rx(api.devices.load).pipe(
				rxmap(operation => actions.load.success({ details: operation.response() })),
				errorMap(actions.load.fail),
				takeUntil(action$.pipe(ofType(actions.load.cancel.type)))
			)
		)
	)
}

const updateEpic = (action$) => {
	return action$.pipe(
		ofType(actions.update.request.type),
		mergeMap(action =>
			rx(api.devices.update, action.uri, action.deviceInfo).pipe(
				rxmap(operation => actions.load.request()),
				errorMap(actions.update.fail),
				takeUntil(action$.pipe(ofType(actions.update.cancel.type)))
			)
		)
	)
}

const watchSessionEpic = (action$) => {
	return action$.pipe(
		ofType(sessionActions.events.started.type),
		rxmap(action => actions.load.request())
	)
}

const epic = combineEpics(loadEpic, updateEpic, watchSessionEpic);

// ---------------------------------------------------------

export { actions, reducer, epic };
