import React, { useState, useContext, useEffect, useRef } from 'react';
import { useI18n } from '../../../../../i18n';
import { cx } from '../../../../api';
import Form from '../../../general/form/Form';
import DatePicker from '../../../general/form/DatePicker';
import { PropertyValuesContext } from './PropertiesControl';
import { useCustomDrop } from './customDrop';
import { className } from '../../../../lib/className';

/**
 * @param {Object} props
 * @param {cx.ods.meta.DateProperty} props.property
 * @param {function} props.onChange
 */

function DateControl(props) {
	const { f } = useI18n();
	const context = useContext(PropertyValuesContext);
	const initialized = useRef(false);
	const [value, setValue] = useState(null);

	useEffect(() => {
		if (!initialized.current) {
			setValue(
				context && context[props.property.propertyId] && new Date(context[props.property.propertyId].value) || null
			);
			initialized.current = true;
		}
	}, [context]);

	const onChange = (value) => {
		const propertyValue = new cx.ods.meta.ScalarValue();
		propertyValue.propertyId = props.property.propertyId;
		propertyValue.value = value != null ? cx.datetime.format.iso.date(value, true, true) : null;
		props.onChange(propertyValue);
		setValue(value);
	}

	const validator = (value) => {
		if (value != null) {
			if (props.property.minimum !== null && value < props.property.minimum) {
				return f('please enter a value not earlier than', { value: props.property.minimum });
			}
			if (props.property.maximum !== null && value > props.property.maximum) {
				return f('please enter a value not later than', { value: props.property.maximum });
			}
		} else if (props.property.required) {
			return f('please enter a value');
		}
	}

	// -----------------------------------

	const [dropState, dropRef] = useCustomDrop(() => props.onRemove(props.property));
	const onRef = (element) => props.onRemove && dropRef(element);

	// -----------------------------------

	return (
		<Form.Control
			controlType={DatePicker}
			controlValidator={validator}
			value={value}
			onChange={onChange}
			label={props.property.name}
			placement="auto"
			customRef={onRef}
			className={className({ 'droppable': dropState.isOver && dropState.canDrop })}
		/>
	);
}

export default DateControl;