import { Collection } from "ol";
import {
	DragPan, KeyboardPan, MouseWheelZoom, PinchZoom,
	DragZoom, DoubleClickZoom, KeyboardZoom
} from "ol/interaction";
import { Zoom, ScaleLine } from "ol/control";
import OlLayerTile from 'ol/layer/Tile';
import OlSourceXYZ from 'ol/source/XYZ';
import OlSourceOSM from 'ol/source/OSM';
import { fc } from "../../../../i18n";


function produceInteractions(options) {
	return new Collection([
		new DragPan(options),
		new KeyboardPan(options),
		new MouseWheelZoom(options),
		new PinchZoom(options),
		new DragZoom(options),
		new DoubleClickZoom(options),
		new KeyboardZoom(options)
	]);
};

const defaultInteractions = () => produceInteractions({
	duration: 250,
	kinetic: false
});

// -----------------------------------------------------

const produceControls = function (options) {
	return new Collection([
		new Zoom(options),
		new ScaleLine()
	]);
};

const defaultControls = () => produceControls({ duration: 250, zoomInTipLabel: fc('zoom in'), zoomOutTipLabel: fc('zoom out') });

//

const layers = {};
const layersEnum = {};

([
	{
		name: "ONE",
		title: "OneMap",
		interactions: defaultInteractions,
		controls: defaultControls,
		getViewOptions: () => ({
			minResolution: 0.2986241247767554,
			maxResolution: 305.87499067443434,
		}),
		get: function () {
			return new OlLayerTile({
				source: new OlSourceXYZ({
					projection: "EPSG:3857",
					url: "https://www.onemap.gov.sg/maps/tiles/Default/{z}/{x}/{y}.png"
				})
			});
		},

	},
	{
		name: "OSM",
		title: "OpenStreetMaps",
		interactions: defaultInteractions,
		controls: defaultControls,
		getViewOptions: () => ({
			minResolution: 0.2985821608482268,
			maxResolution: 39135.487975647615,
		}),
		get: function () {
			return new OlLayerTile({
				source: new OlSourceOSM({
					projection: "EPSG:3857"
				})
			});
		}
	},
	// TODO for future support
	// {
	// 	name: "COVEXIS",
	// 	title: "Covexis Map",
	// 	interactions: defaultInteractions,
	// 	controls: defaultControls,
	// 	viewOptions: {
	// 		minResolution: 0.2985821608482268,
	// 		maxResolution: 19567.95939103849,
	// 	},
	// 	get: function () {
	// 		return new OlLayerTile({
	// 			source: new OlSourceTileWMS({
	// 				preload: 1,
	// 				url: window.location.protocol + "//mosaic.covexis.com/map?",
	// 				params: {
	// 					'VERSION': '1.1.1',
	// 					'KEY': ''
	// 				},
	// 				projection: "EPSG:4326"
	// 			}),
	// 			cacheSize: 0
	// 		});
	// 	}
	// },
]).forEach(layerMeta => {
	layers[layerMeta.name] = layerMeta;
	layersEnum[layerMeta.name] = layerMeta.name;
});

export { layers, layersEnum };
