import React, { useState, useContext, useEffect, useRef } from 'react';
import { useI18n } from '../../../../../i18n';
import { cx } from '../../../../api';
import Form from '../../../general/form/Form';
import InputNumber from '../../../general/form/InputNumber';
import SelectPicker from '../../../general/form/SelectPicker';
import { PropertyValuesContext } from './PropertiesControl';
import { useCustomDrop } from './customDrop';
import { className } from '../../../../lib/className';

/**
 * @param {Object} props
 * @param {cx.ods.meta.IntegerProperty} props.property
 * @param {function} props.onChange
 */

function IntegerControl(props) {
	const { f } = useI18n();
	const context = useContext(PropertyValuesContext);
	const initialized = useRef(false);
	const [value, setValue] = useState(null);

	useEffect(() => {
		if (!initialized.current) {
			setValue(context && context[props.property.propertyId] && +context[props.property.propertyId].value || null);
			initialized.current = true;
		}
	}, [context]);

	const onChange = (value) => {
		const number = value !== '' ? +value : NaN;
		const propertyValue = new cx.ods.meta.ScalarValue();
		propertyValue.propertyId = props.property.propertyId;
		propertyValue.value = !isNaN(number) ? value : null;
		props.onChange(propertyValue);
		setValue(value);
	}

	const validator = (value, values, number) => {
		if (number != null) {
			if (props.property.minimum !== null && number < props.property.minimum) {
				return f('please enter a value not less than', { value: props.property.minimum });
			}
			if (props.property.maximum !== null && number > props.property.maximum) {
				return f('please enter a value not greater than', { value: props.property.maximum });
			}
		} else if (props.property.required) {
			return f('please enter a value');
		}
	}

	let Type = null, config = null;
	if (props.property.enumeration) {
		Type = SelectPicker;
		config = {
			data: props.property.enumeration.map(item => ({
				label: item,
				value: item
			})),
		};
	} else {
		Type = InputNumber;
		config = {
			integer: true,
			minimum: props.property.minimum,
			maximum: props.property.maximum,
			step: 1,
		};
	}

	// -----------------------------------

	const [dropState, dropRef] = useCustomDrop(() => props.onRemove(props.property));
	const onRef = (element) => props.onRemove && dropRef(element);

	// -----------------------------------

	return (
		<Form.Control
			controlType={Type}
			controlValidator={validator}
			value={value}
			onChange={onChange}
			label={props.property.name}
			customRef={onRef}
			className={className({ 'droppable': dropState.isOver && dropState.canDrop })}
			{...config}
		/>
	);
}

export default IntegerControl;