import { deltaReducer } from "../../actions";

const defaultState = {
	map: null, // { uri: [{ timeRange, ['events' | 'messages' | 'assetPresences' | more] }] }
	pending: false,
	error: null
};

export const createReducer = (actions, Manager) => deltaReducer((state, action) => {
	switch (action.type) {
		case actions.load.request.type: return {
			pending: true,
			error: null
		};
		case actions.load.success.type: return {
			...Manager.getInstance().merge(action.histories, state)
		};
		case actions.load.fail.type: return {
			pending: false,
			error: action.errorMessage
		};
		case actions.load.finish.type: return {
			pending: false
		};
		case actions.clear.type: return { ...defaultState };
	}
	return null;
}, defaultState);
