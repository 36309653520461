import React from 'react';
import { connect } from 'react-redux';
import MapMarker from '../../general/location/MapMarker';
import { className } from '../../../lib/className';
import { messageProxy } from '../../../api/message';
import Hover from '../../general/Hover';
import EventField from '../../share/events/EventField';
import './eventMapMarker.scss';

/**
 * @param {Object} props
 * @param {cx.ods.devices.EventDetails} props.event
 * @param {function} [props.onClick]
 * @param {string} props.uri
 * @param {Map} props.map implicitly passed by Map
 */

function EventMapMarker(props) {
	let marker = null;

	const onClick = (e) => {
		props.onClick && props.onClick(props.event);
	}

	if (props.event.message) {
		const message = messageProxy(props.event.message);
		if (message && message.hasLonLat() && props.device) {
			const eventType = props.eventType && props.eventType.name;
			const customElement = (
				<Hover onHover={<EventField event={props.event} device={props.device} showDate />}>
					<div
						className={className(
							"event-map-marker",
							eventType,
							{
								'incident': props.eventType && props.eventType.incident
							}
						)}
						onClick={onClick}
					></div>
				</Hover>
			);
			marker = (
				<MapMarker
					className={"hide"}
					customElement={customElement}
					map={props.map}
					coordinates={message.lonLat()}
					id={props.event.eventId}
					properties={{ domain: 'event' }}
				/>
			);
		}
	}

	return (marker);
}

export default connect(
	(state, props) => ({
		eventType: state.registry.eventTypes.typeMap && state.registry.eventTypes.typeMap[props.event.eventType],
		device: state.devices.map && state.devices.map[props.uri]
	})
)(EventMapMarker);