import { actions } from './actions';
import { reduxSwitch } from '../../tools';
import { cx } from '../../../api';

const defaultState = {}; // {uri => { types, pendingCommands, pending, error, lastCompleteEventType, commandIndexMap }}

const getDefaultState = () => {
	return {
		types: null,
		typeMap: null,
		pendingCommands: [],
		lastCompleteEventType: null,
		commandIndexMap: null,
		pending: false,
		error: null,
	};
}

function typesReducer(state, action) {
	switch (action.type) {
		case actions.types.request.type:
			const copyState = { ...state };
			if (!copyState[action.uri]) {
				copyState[action.uri] = getDefaultState();
			}
			copyState[action.uri].pending = true;
			return copyState;
		case actions.types.success.type:
			return {
				...state,
				[action.uri]: {
					...state[action.uri],
					types: action.types,
					typeMap: cx.i.hash(action.types, command => command.commandType),
					pending: false
				}
			};
		case actions.types.fail.type:
			return {
				...state,
				[action.uri]: {
					...state[action.uri],
					types: action.types,
					pending: false,
					error: action.errorMessage
				}
			};
	}
	return state;
}

function submitReducer(state, action) {
	switch (action.type) {
		case actions.submit.request.type:
			const copyState = { ...state };
			const commandType = action.commandInfo.commandType;
			if (
				copyState[action.uri].lastCompleteEventType
				&& copyState[action.uri].lastCompleteEventType[commandType]
			) {
				delete copyState[action.uri].lastCompleteEventType[commandType]
			}
			copyState[action.uri].pending = true;
			copyState[action.uri].error = null;

			return copyState;
		case actions.submit.success.type:
			return state;
		case actions.submit.fail.type:
			return {
				...state,
				[action.uri]: {
					...state[action.uri],
					pending: false,
					error: action.errorMessage
				}
			};
	}
	return state;
}

function loadReducer(state, action) {
	switch (action.type) {
		case actions.load.request.type:
			return {
				...state,
				[action.uri]: {
					...state[action.uri],
					pending: true,
					error: null
				}
			};
		case actions.load.success.type:
			const copyState = { ...state };
			if (!copyState[action.uri]) {
				copyState[action.uri] = getDefaultState();
			}
			if (copyState[action.uri].lastCompleteEventType && action.details) {
				Object.values(action.details).forEach(command => {
					if (
						copyState[action.uri].lastCompleteEventType
						&& copyState[action.uri].lastCompleteEventType[command.commandType]
					) {
						delete copyState[action.uri].lastCompleteEventType[command.commandType]
					}
				});
			}
			copyState[action.uri].pendingCommands = action.details;
			copyState[action.uri].pending = false;
			return copyState;
		case actions.load.fail.type:
			return {
				...state,
				[action.uri]: {
					...state[action.uri],
					pending: false,
					error: action.errorMessage
				}
			};
	}
	return state;
}

function findReducer(state, action) {
	switch (action.type) {
		case actions.find.request.type:
			return {
				...state,
				[action.uri]: {
					...state[action.uri],
					pending: true,
					error: null
				}
			};
		case actions.find.success.type:
			return {
				...state,
				[action.uri]: {
					...state[action.uri],
					pendingCommands: action.details,
					pending: false
				}
			};
		case actions.find.fail.type:
			return {
				...state,
				[action.uri]: {
					...state[action.uri],
					pending: false,
					error: action.errorMessage
				}
			};
	}
	return state;
}

function retrieveReducer(state, action) {
	switch (action.type) {
		case actions.retrieve.request.type:
			return {
				...state,
				[action.uri]: {
					...state[action.uri],
					commandIndexMap: { ...state[action.uri].commandIndexMap[action.commandIndex] },
					pending: true,
					error: null
				}
			};
		case actions.retrieve.success.type:
			return {
				...state,
				[action.uri]: {
					...state[action.uri],
					commandIndexMap: {
						...state[action.uri].commandIndexMap[action.commandIndex],
						[action.commandIndex]: action.details
					},
					pending: false
				}
			};
		case actions.retrieve.fail.type:
			return {
				...state,
				[action.uri]: {
					...state[action.uri],
					pending: false,
					error: action.errorMessage
				}
			};
	}
	return state;
}

function cancelReducer(state, action) {
	switch (action.type) {
		case actions.cancel.request.type:
			return {
				...state,
				[action.uri]: {
					...state[action.uri],
					pending: false,
					error: null
				}
			};
		case actions.cancel.success.type:
			const copyState = { ...state };
			if (copyState[action.uri].commandIndexMap && copyState[action.uri].commandIndexMap[action.commandIndex]) {
				delete copyState[action.uri].commandIndexMap[action.commandIndex];
			}
			copyState[action.uri].pending = false;
			return copyState;
		case actions.cancel.fail.type:
			return {
				...state,
				[action.uri]: {
					...state[action.uri],
					pending: false,
					error: action.errorMessage
				}
			};
	}
	return state;
}

function lastCompleteReducer(state, action) {
	switch (action.type) {
		case actions.lastComplete.type:
			const copyState = { ...state };
			if (!copyState[action.uri]) {
				copyState[action.uri] = getDefaultState();
			}
			if (copyState[action.uri].lastCompleteEventType) {
				copyState[action.uri].lastCompleteEventType = {
					...copyState[action.uri].lastCompleteEventType,
					[action.commandType]: action.eventType
				}
			} else {
				copyState[action.uri].lastCompleteEventType = {
					[action.commandType]: action.eventType
				}
			}
			return copyState;
		default:
			return state;
	}
}

const reducer = reduxSwitch([typesReducer, submitReducer, loadReducer, findReducer, retrieveReducer, cancelReducer, lastCompleteReducer], defaultState);

export { reducer };
