import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { className, fromProps } from '../../lib/className';
import { actions } from '../../redux/api/devices';
import { FiFileText as Icon } from 'react-icons/fi';
import PagePanel from '../general/panel/PagePanel';
import { useI18n } from '../../../i18n';
import DeviceBriefView from '../custom/deviceControlPanel/views/DeviceBriefView';
import DeviceMapView from '../custom/deviceControlPanel/views/DeviceMapView';
import DeviceRecentEventsView from '../custom/deviceControlPanel/views/DeviceRecentEventsView';
// import DeviceSummaryView from '../custom/deviceControlPanel/views/DeviceSummaryView';
import DeviceDigestView from '../custom/deviceControlPanel/views/DeviceDigestView';
import DeviceRequestsView from '../custom/deviceControlPanel/views/DeviceRequestsView';
import { setPageTitle } from '../../misc/page';
import './deviceControlPanelPage.scss';

const PAGE_TITLE = 'device control panel';

function DeviceControlPanelPage(props) {
	const { f, fc } = useI18n();
	const uri = props.match.params.uri;
	if (uri == null) { /* TODO */ }
	const device = props.devices.map && props.devices.map[uri];

	useEffect(() => {
		setPageTitle(fc(PAGE_TITLE));
		if (props.devices.map == null && !props.devices.pending) {
			props.loadDevices();
		}
	}, []);

	return (
		<div className={className('app-page device-control-panel', fromProps(props))}>
			<PagePanel
				header={<>
					<div className="icon"><Icon size="22" /></div>
					<div className="title">{f('device control panel')}</div>
				</>}
			>
				{device && <>
					<div className="column">
						<DeviceBriefView device={device} />
						<DeviceMapView device={device} />
						<DeviceRecentEventsView device={device} />
					</div>
					<div className="column">
						<DeviceDigestView device={device} />
						<DeviceRequestsView device={device} />
						{/* <DeviceSummaryView device={device} /> */}
					</div>
				</>}
			</PagePanel>
		</div>
	)
}

export default connect(
	(state) => ({
		devices: state.devices
	}),
	(dispatch) => ({
		loadDevices: () => dispatch(actions.load.request())
	})
)(DeviceControlPanelPage);