import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { className, fromProps } from '../../../../lib/className';
import Button from '../../../general/form/Button';
import { actions as deviceCommandsActions } from '../../../../redux/api/deviceCommands';
import { cx } from '../../../../api';
import { useI18n } from '../../../../../i18n';
import { useDispatch } from 'react-redux';
import Form from '../../../general/form/Form';
import DeviceCommandParameter from './DeviceCommandParameter';
import SetPositionParameters from './SetPositionParameters';
import EventField from '../../events/EventField';
import { FiClock } from 'react-icons/fi';
import './deviceCommand.scss';

/**
 * @param {Object} props
 * @param {cx.ods.devices.CommandType} props.command
 * @param {function} [props.onSubmit]
 * @param {boolean} [props.hideTitle]
 * @param {string} props.uri
 */

function DeviceCommand(props) {
	const { f, fc } = useI18n();
	const dispatch = useDispatch();
	const submitHook = useRef(null);
	const commandType = props.command.commandType;
	const pendingCommand = props.commandsState && props.commandsState.pendingCommands && props.commandsState.pendingCommands.find(command => command.commandType === commandType);
	const pendingStatus = pendingCommand && (pendingCommand.pending && 'pending' || pendingCommand.processing && 'processing');
	const lastCompleteEventType = props.commandsState && props.commandsState.lastCompleteEventType && props.commandsState.lastCompleteEventType[commandType];
	const disabled = (props.commandsState && props.commandsState.pending) || (pendingCommand && pendingCommand.processing);

	const onClick = () => {
		submitHook.current();
	}

	const onApply = (values) => {
		if (pendingCommand) {
			dispatch(deviceCommandsActions.cancel.request({ uri: props.uri, commandType }));
		} else {
			let commandInfo = null;
			if (props.command.parameters) {
				const properties = {};
				props.command.parameters.forEach(parameter => {
					if (values[parameter.name] != null) properties[parameter.name] = values[parameter.name];
				});
				commandInfo = new cx.ods.devices.CommandInfo(commandType, new cx.ods.Properties(properties));
			} else commandInfo = new cx.ods.devices.CommandInfo(commandType);
			dispatch(deviceCommandsActions.submit.request({ uri: props.uri, commandInfo }));
		}
		if (props.onSubmit) props.onSubmit();
	}

	const getButtonTitle = () => {
		if (pendingCommand) return f('cancel');
		switch (props.command.name) {
			case "position":
			case "get-dtc":
			case "request-counters":
			case "request-switch":
				return f('get');
			case "blast":
				return f('blast');
			case "reboot":
				return f('reboot');
			case "odometer":
			case "set-position":
				return f('set');
			case "reset-fuel":
			case "reset-counters":
				return f('reset');
			case "detect-obd":
				return f('detect');
			case "lock-door":
				return f('lock');
			case "unlock-door":
				return f('unlock');
			default:
				return f('submit');
		}
	}

	const parameters = [];
	if (props.command.parameters) {
		props.command.parameters.forEach(parameter => {
			let initialValue = null;
			if (pendingCommand && pendingCommand.parameters) {
				initialValue =  pendingCommand.parameters.mapped[parameter.name];
			}
			if (parameter.name == 'longitude') {
				parameters.push(
					<SetPositionParameters
						key={parameter.name}
						initialLocation={props.deviceState && props.deviceState.message}
						command={props.command}
					/>
				);
			} else if (parameter.name === 'latitude') {

			} else {
				parameters.push(
					<DeviceCommandParameter
						key={parameter.name}
						initialValue={initialValue}
						disabled={!!initialValue}
						parameter={parameter}
					/>
				);
			}
		});
	}

	return (
		<div className={className("device-command", fromProps(props))}>
			<div className="header">
				{!props.hideTitle && <div className="title">
					<label>
						<span className="capitalize">
							{f({ prefix: 'command', id: props.command.description })}
						</span>
					</label>
				</div>}
				<div className="commentary">
					<span className="capitalize">
						{f({ prefix: 'command', id: props.command.commentary })}
					</span>
				</div>
			</div>
			<Form
				onSubmit={onApply}
				submitHook={submitHook}
				disabled={disabled}
			>
				{parameters}
				{lastCompleteEventType && <EventField eventType={lastCompleteEventType} />}
				{pendingCommand
					&& <div className="status">
						<span className="icon"><FiClock title={fc({ prefix: 'status', id: pendingStatus })} /></span>
						<span className="capitalize">{f({ prefix: 'status', id: pendingStatus })}</span>
					</div>
				}
				<div className="buttons">
					<Button
						onClick={onClick}
						disabled={disabled}
						loading={props.commandsState && props.commandsState.pending}
					>
						{getButtonTitle()}
					</Button>
				</div>
			</Form>
		</div>
	);
}

export default connect((state, props) => ({
	commandsState: state.deviceCommands && state.deviceCommands[props.uri],
	deviceState: state.deviceStates.map && state.deviceStates.map[props.uri]
}))(DeviceCommand);
