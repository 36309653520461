import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useI18n } from 'i18n';
import { IAttributeRangeDetector } from 'types/detectors';
import { AllRoutes } from 'constants/routes';
import { attributeRangeDetectorsSelector } from 'core/redux/selectors/processors';
import { actions as processorActions } from 'core/redux/api/processors';
import Intent from 'core/misc/ObjectActionIntent';
import { setPageTitle } from 'core/misc/page';

const actions = processorActions.attributeRange;

const PAGE_TITLE = 'attribute range detectors';

export const useAttributeRangeDetectors = () => {
  const { fc } = useI18n();

  const history = useHistory();
  const params = useParams<Record<string, string>>();

  const dispatch = useDispatch();
  const detectors = useSelector(attributeRangeDetectorsSelector);

  // TODO: should be refactored. used to go to a previous route when a detector updated/created.
  const pending = useRef(false);

  const [intent, setIntent] = useState<Intent | null>(null);

  const { 0: route, id: processorId } = params;
  const isFormPage = route === 'edit' || route === 'create';

  const selectedDetector = useMemo(
    () => (!!processorId && !!detectors.map ? detectors.map[Number(processorId)] : null),
    [processorId, detectors],
  );

  const onPopupClose = () => {
    intent && setIntent(null);
  };

  const onIntent = (intent: Intent) => {
    if (intent.action() === Intent.Action.Remove) {
      return setIntent(intent);
    }

    if (intent.action() === Intent.Action.Edit) {
      history.push(`${AllRoutes.AttributeRangeDetectors}/${intent.object().processorId}/edit`);
    }
  };

  const onRemove = (detector: IAttributeRangeDetector) => {
    dispatch(actions.remove.request({ processorId: detector.processorId }));
  };

  const clearError = () => {
    detectors.error && dispatch(actions.clear());
  };

  useEffect(() => {
    setPageTitle(fc(PAGE_TITLE));

    if (!detectors.list) {
      dispatch(actions.load.request());
    }

    return () => {
      clearError();
    };
  }, []);

  useEffect(() => {
    clearError();
  }, [intent]);

  useEffect(() => {
    clearError();
    onPopupClose();
  }, [params]);

  useEffect(() => {
    if (!detectors.pending && pending.current) {
      pending.current = false;

      if (!detectors.error) {
        history.goBack();
      }
    }
  }, [detectors]);

  useEffect(() => {
    if (intent && detectors.map && !detectors.map[intent.object().processorId]) {
      onPopupClose();
    }
  }, [detectors, params]);

  const viewProps = { selectedDetector, intent, onIntent, onRemove, onPopupClose };

  const formProps = { detector: selectedDetector, pending };

  return { isFormPage, isLoading: detectors.pending && !detectors.list, viewProps, formProps };
};
