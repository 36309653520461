import React, { useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import { cx } from '../../../../api';
import Form from '../../../general/form/Form';
import { actions } from '../../../../redux/api/reports';

const BATCH_SIZE = 70;

/**
 * @param {Object} props
 * @param {React.RefObject} props.customRef
 * @param {Object} props.timeRange
 * @param {boolean} [props.disabled]
 */

function Speeding(props) {
	const { f } = useI18n();

	useImperativeHandle(props.customRef, () => ({
		submit: (values) => {
			const parameters = new cx.ods.reports.SpeedingReportParameters();
			parameters.timeRange = props.timeRange;
			parameters.timeRange.retrospective = values.retrospective;
			parameters.timeRange.window = new cx.ods.TimeWindow(BATCH_SIZE);
			parameters.minPeriod = values.minPeriod;
			parameters.speedThreshold = values.speedThreshold;
			props.dispatch(actions.speeding.request({ uri: values.uri, parameters, clear: true }));
		},
		clear: () => {
			props.dispatch(actions.speeding.exportClear());
		}
	}));

	return (
		<Form.ControlGroup key={50} disabled={props.disabled}>
			<Form.Control
				controlName="speedThreshold"
				controlType={Form.Control.Type.Input}
				controlValidator={(value) => {
					value = parseInt(value);
					return !(Number.isInteger(value) && value > 0) && f('please enter integer number above zero');
				}}
				label={f('speed threshold') + ', ' + f('units.km/h')}
				type="number"
				initialValue={props.state.parameters ? props.state.parameters.speedThreshold : 5}
			/>
			<Form.Control
				controlName="minPeriod"
				controlType={Form.Control.Type.Input}
				controlValidator={(value) => {
					value = parseInt(value);
					return !(Number.isInteger(value) && value > 0) && f('please enter integer number above zero');
				}}
				label={f('minimum period') + ', ' + f('units.s')}
				type="number"
				initialValue={props.state.parameters ? props.state.parameters.minPeriod : 5}
			/>
			<Form.Control
				controlName="retrospective"
				controlType={Form.Control.Type.Checkbox}
				label={f('report later items first')}
				initialValue={props.state.parameters ? props.state.parameters.timeRange.retrospective : false}
			/>
		</Form.ControlGroup>
	)
}

export default connect(state => {
	return {
		state: state.reports.speeding
	}
})(Speeding);
