import React, {useMemo} from 'react';
import { useRouteMatch } from 'react-router-dom';

/**
 * Provides routeMap prop which represents url subtree configured by templates parameter. Subtree is relative to 
 * the current router match. For each key in templates parameter resulting routeMap object contains an absolute 
 * url formatter with an additional absolute path property.
 */

export const withRouteMap = templates => {
	return Wrapped => props => {
		const {path: basePath, url: baseURL} = useRouteMatch();
		const routeMap = useMemo(() => Object.fromEntries(Object.entries(templates).map(
			([name, template]) => {
				let format;
				if (template == null) format = () => baseURL;
				else if (!/:\w/.test(template)) format = () => baseURL + template;
				else format = args => baseURL + template.replace(/:(\w+)/g, (match, name) => {
					const value = args[name];
					if (value === undefined) throw new Error(`No value for ${name} parameter supplied for template ${template}`);
					return value;
				});
				return [name, Object.assign(format, {path: template ? basePath + template : basePath})];
			}
		)), [basePath, baseURL]);

		return <Wrapped routeMap={routeMap} {...props}/>
	};
};
