import { ofType } from "redux-observable";
import { mergeMap, map as rxmap, catchError, takeUntil } from 'rxjs/operators';
import { rx, api } from "../../api";
import { ActionGeneratorBuilder } from "../actions";

const defaultState = {};  //  { tripId => { list, pending, error } }

const actions = new ActionGeneratorBuilder('tripTrace')
	.subtype('load', load => load.request({ uri: true, tripId: true }).success({ tripId: true, trace: true }).fail({ tripId: true, errorMessage: true }).cancel())
	.type('clear')
	.build()
;

function reducer(state = defaultState, action) {
	let copy = null;
	switch (action.type) {
		case actions.load.request.type:
			return {
				...state,
				[action.tripId]: {
					list: null,
					pending: true,
					error: false
				}
			};
		case actions.load.success.type:
			copy = { ...state };
			copy[action.tripId].list = action.trace;
			copy[action.tripId].pending = false;
			return copy;
		case actions.load.fail.type:
			copy = { ...state };
			copy[action.tripId].pending = false;
			copy[action.tripId].error = action.errorMessage;
			return copy;
		case actions.clear.type:
			return {};
		default:
			return state;
	}
}

const epic = (action$) => {
	return action$.pipe(
		ofType(actions.load.request.type),
		mergeMap(action =>
			rx(api.trips.trace, action.uri, action.tripId).pipe(
				rxmap(operation => actions.load.success({ tripId: action.tripId, trace: operation.response() })),
				catchError(error => actions.load.fail({ tripId: action.tripId, errorMessage: error.userMessage || error.message })),
				takeUntil(action$.pipe(ofType(actions.load.cancel.type, actions.clear.type)))
			)
		)
	)
}

export { actions, reducer, epic };
