import { ActionGeneratorBuilder } from "../../actions";

export const actions = new ActionGeneratorBuilder('deviceFilteredEvents')
	.subtype(
		'find',
		find => find
			.request({ uri: true, uid: true, filter: true })
			.success({ uri: true, uid: true, list: true })
			.fail({ uri: true, uid: true, errorMessage: true })
			.cancel()
	)
	.type('clear', { uri: true, uid: true })
	.build()
;
