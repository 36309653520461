import React from 'react';
import { connect } from 'react-redux';
import Form from '../../../general/form/Form';
import { useI18n } from '../../../../../i18n';
import { unitsString } from '../../../../misc/attributeTypes';
import ParameterControl from './ParameterControl';
import RegistryCategoryPicker from '../../registries/RegistryCategoryPicker';

/**
 * @param {Object} props
 * @param {cx.ods.devices.RequestParameter} props.parameter
 * @param {any} [props.initialValue]
 * @param {boolean} [props.disabled]
 */

function DeviceCommandParameter(props) {
	const { fc } = useI18n();
	const units = props.attributeTypeMap
		&& props.attributeTypeMap[props.parameter.parameterType]
		&& props.attributeTypeMap[props.parameter.parameterType].mnemonics
	;
	let params = null;

	if (props.parameter.category) {
		params = {};
		if (props.parameter.enumeration) {
			params.availableMnemonics = props.parameter.enumeration.split(',');
		}
		params.cleanable = false;
		params.categoryName = props.parameter.category;
		params.label = fc({ prefix: 'request-parameter', id: props.parameter.name });
		params.controlType = RegistryCategoryPicker;
	} else if (props.parameter.enumeration) {
		params = {};
		params.data = props.parameter.enumeration.split(',').map(enumeration => {
			return { label: fc(enumeration), value: enumeration }
		});
		params.controlType = Form.Control.Type.InputPicker;
		params.cleanable = false;
	} else if (units == "boolean") {
		params = {};
		params.defaultValue = false;
		if (props.initialValue && props.initialValue == 'false') {
			params.initialValue = false;
		};
		params.controlType = Form.Control.Type.Checkbox;
	}
	if (params == null) {
		params = {};
		params.isText = units == "text";
		if (!params.isText) params.units = unitsString(units);
	}

	return (
		<ParameterControl
			defaultValue={props.parameter.fallback}
			parameter={props.parameter}
			initialValue={props.initialValue}
			disabled={props.disabled}
			{...params}
		/>
	);
}

export default connect(state => ({
	attributeTypeMap: state.registry.attributeTypes.codeMap
}))(DeviceCommandParameter);