import { useDrop } from "react-dnd";
import { DragItemType } from "../../dnd/DragItemType";

export function useCustomDrop(onDrop) {
	return useDrop({
		accept: DragItemType.ACTION_REMOVE,
		drop: item => onDrop(item),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
	})
}