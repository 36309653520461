import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ods } from '../../../../../api';
import TraceViewMap from '../../../map/TraceViewMap';
import { actions } from '../../../../../redux/api/trace';

/**
 * @param {Object} props
 * @param {cx.ods.assets.AssetTrip} [props.assetTrip]
 */

const UID = 'AssetTripTraceViewMap'; // for trace

function AssetTripTraceViewMap(props) {

	useEffect(() => {
		if (props.assetTrip) {
			const filter = new ods.devices.MessageFilter();
			filter.minGeneratedAt = props.assetTrip.startedAt;
			filter.maxGeneratedAt = props.assetTrip.endedAt || props.assetTrip.startedAt;
			filter.window = new ods.devices.MessageWindowFilter({ size: 50 });
			props.dispatch(actions.load.request({ uid: UID, uri: props.assetTrip.uri, filter, clear: true }));
		} else props.trace && props.dispatch(actions.clear({ uid: UID }));
	}, [props.assetTrip]);

	return (<TraceViewMap trace={props.trace?.list} pending={props.trace?.pending} />);
}

export default connect(state => ({
	trace: state.trace[UID]
}))(AssetTripTraceViewMap);
