import React, { useEffect, useRef } from 'react';
import { Map } from './Map';
import { Style, Icon } from 'ol/style';
import { containsCoordinate } from 'ol/extent';
import { Point } from 'ol/geom';
import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import LayerGroup from 'ol/layer/Group';
import tripEnd from '../../../img/markers/tripend.png';

const MAP_NAME = "CoordinateSelectMap";

/**
 * @param {Object} props
 * @param {Array.<number>} [props.coordinate]
 * @param {function} props.onClick
 */

function CoordinateSelectMap(props) {
	const map = useRef();
	const featureRef = useRef(null);

	const styleFunction = (feature) => {
		const coordinate = feature.getGeometry().getCoordinates();
		const styles = [
			new Style({
				image: new Icon({
					src: tripEnd,
					anchor: [0.5, 1],
				}),
				zIndex: 4,
				geometry: () => {
					return new Point(coordinate);
				}
			})
		];
		return styles;
	}

	useEffect(() => {
		featureRef.current = new Feature({name: MAP_NAME });
		const layer = new VectorLayer({
			source: new VectorSource({ features: [featureRef.current] }),
			style: styleFunction
		});
		map.current.getOlMap().addLayer(new LayerGroup({ layers: [layer] }));

		const click = (event) => {
			props.onClick(map.current.getOlMap().getCoordinateFromPixel(event.pixel));
		}

		map.current.getOlMap().on('click', click);
		return () => {
			map.current.getOlMap().removeLayer(layer);
		};
	}, []);

	useEffect(() => {
		if (featureRef.current && props.coordinate) {
			const geometry = new Point(props.coordinate);
			featureRef.current.setGeometry(geometry);
			const size = map.current.getOlMap().getSize();
			const apertureSize = [size[0] - 30, size[1] - 30];
			const aperture = map.current.getOlMap().getView().calculateExtent(apertureSize);
			if (!containsCoordinate(aperture, props.coordinate)) {
				map.current.getOwMap().focus(props.coordinate);
			}
		}
	}, [props.coordinate]);

	return (<Map className="coordinate-select-map" name={MAP_NAME} ref={map} baseLayer={Map.Layers.ONE} />);
}

export default CoordinateSelectMap;
