import styled from 'styled-components';

import { Colors } from 'ui/constants/colors';

interface ITableRowStyledProps {
  isEven: boolean;
}

export const TableStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 538px;
  margin-bottom: 20px;

  .highlighted {
    color: ${Colors.Keppel};
  }
`;

export const TableHeaderStyled = styled.div`
  display: flex;
  height: 38px;
  font: bold 12px Muli;
  padding: 0 22px;
  color: ${Colors.Black};
  background-color: ${Colors.White};
  border-bottom: 1px solid ${Colors.SilverPolish};
`;

export const ColumnHeaderStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;

  &:last-child {
    padding-right: 10px;
  }

  & > span {
    position: relative;
  }
`;

export const TableContentStubStyled = styled.div`
  position: relative;
  flex: 1;
`;

export const TableRowStyled = styled.div<ITableRowStyledProps>`
  font: bold 12px Muli;
  height: 50px;
  padding: 0 22px;
  background-color: ${({ isEven }) => (isEven ? Colors.White : Colors.DistantHorizon)};

  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${Colors.DoveGray};
  }
`;

export const CellStyled = styled.div`
  width: 100%;
  padding-left: 10px;
`;

export const PreloaderWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1;
  height: calc(100%);
  background-color: ${Colors.White};
  opacity: 0.5;
`;

export const TableNotContentFound = styled(PreloaderWrapperStyled)`
  flex-direction: column;
`;
