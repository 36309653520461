import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { cx } from '../../../api';
import { regex } from '../../../app/regex';
import { actions as accountActions } from '../../../redux/api/account';
import Form from '../../general/form/Form';
import CountryPicker from '../../share/registries/CountryPicker';
import TimeZonePicker from '../../share/registries/TimeZonePicker';
import Button from '../../general/form/Button';
import ButtonGroup from '../../general/form/ButtonGroup';
import './accountDetailsForm.scss';

/**
 * @param {Object} props
 * @param {Object} props.account
 * @param {function} props.onReady
 * @param {function} props.onCancel
 * @param {function} [props.setModalFooter] possibility to set footer content when used inside modal window
 */

function AccountDetailsForm(props) {
	const { f } = useI18n();
	const submitRef = useRef(null);
	const [country, setCountry] = useState(props.account && props.account.country ? props.account.country : null);
	const pending = useRef(false);

	const onSubmit = (accountDetails) => {
		pending.current = true;
		props.dispatch(accountActions.update.request({ details: accountDetails }));
	}

	const submit = () => {
		submitRef.current();
	}

	const footer = (
		<ButtonGroup className="account-details-buttons">
			<Button
				onClick={props.onCancel}
				disabled={props.redux.pending}
			>
				{f('cancel')}
			</Button>
			<Button
				onClick={submit}
				loading={props.redux.pending}
				disabled={props.redux.pending}
			>
				{f('save')}
			</Button>
		</ButtonGroup>
	);

	useEffect(() => {
		props.setModalFooter && props.setModalFooter(footer);
		return () => {
			props.dispatch(accountActions.clear());
		}
	}, []);

	useEffect(() => {
		if (!props.redux.pending && pending.current) {
			pending.current = false;
			if (props.redux.error == null) {
				props.onReady();
			}
		}
	}, [props.redux]);

	return (
		<Form
			className="account-details"
			onSubmit={onSubmit}
			submitHook={submitRef}
			objectType={cx.ods.accounts.AccountDetails}
			error={props.redux.error}
			disabled={props.redux.pending}
			footer={!props.setModalFooter && footer}
		>
			<Form.ControlGroup>
				{/* <div className="block">
					<Form.Control
						controlType={Form.Control.Type.Input}
						controlName="fullName"
						label="Full Name"
						initialValue={props.account && props.account.fullName}
					/>
				</div> */}
				<div className="block">
					<Form.Control
						controlType={CountryPicker}
						controlName="country"
						label={f('country')}
						value={country}
						onChange={setCountry}
					/>
					<Form.Control
						controlType={TimeZonePicker}
						controlName="timeZone"
						label={f('time zone')}
						initialValue={props.account && props.account.timeZone}
					/>
				</div>
				<div className="block">
					<Form.Control
						controlType={Form.Control.Type.Input}
						controlName="email"
						controlValidator={(value) => {
							if (value && !regex.email.test(value)) return f('please enter correct e-mail');
						}}
						label={f('e-mail')}
						type="email"
						initialValue={props.account && props.account.email}
					/>
					<Form.Control
						controlType={Form.Control.Type.Input}
						controlName="phone"
						controlValidator={(value) => {
							if (value != null && !regex.phone.test(value)) {
								return f("please enter value without '+' sign and not longer than 15 digits");
							}
						}}
						label={f('phone')}
						type="tel"
						initialValue={props.account && props.account.phone}
					/>
				</div>
				<div className="block">
					<Form.Control
						controlType={Form.Control.Type.Checkbox}
						controlName="emailTrips"
						label={f('e-mail trips')}
						initialValue={props.account && props.account.emailTrips}
					/>
					<Form.Control
						controlType={Form.Control.Type.Checkbox}
						controlName="emailEvents"
						label={f('e-mail events')}
						initialValue={props.account && props.account.emailEvents}
					/>
				</div>
				<div className="block">
					<Form.Control
						controlType={Form.Control.Type.Input}
						controlName="address"
						label={f('address')}
						initialValue={props.account && props.account.address}
					/>
				</div>
			</Form.ControlGroup>
		</Form>
	);
}

export default connect(state => ({
	redux: state.account
}))(AccountDetailsForm);
