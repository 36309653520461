import React, { useState, useRef, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import * as widgets from '../../../redux/app/widgets';
import MapButton from '../map/MapButton';
import Popup from '../../general/Popup';
import { cx } from '../../../api';
import { ReduxKeyContext } from '../../../misc/ReduxKeyContext';
import { FiGrid } from 'react-icons/fi';
import './mapWidgetsControl.scss';

/**
 * Displays button on map that will enable widgets if they are disabled and show popup with widgets list if enabled.
 */

function MapWidgetsControl(props) {
	const { f } = useI18n();
	const [opened, setOpened] = useState(false);
	const box = useRef(null);
	const buttonBox = useRef(null);
	const reduxKey = useContext(ReduxKeyContext);

	useEffect(() => {
		const onClick = (event) => {
			const insideBox = cx.dom.i.traverse(box.current, (node) => node == event.target);
			const insideButton = cx.dom.i.traverse(buttonBox.current, (node) => node == event.target);
			if (!insideBox && !insideButton) {
				setOpened(false);
			}
		}
		document.body.addEventListener('click', onClick);
		return () => {
			document.body.removeEventListener('click', onClick);
		}
	}, []);

	const enableWidgets = () => {
		props.dispatch(widgets.actions.display({ domain: reduxKey, display: true }));
	}

	let button = null;
	if (props.widgets[reduxKey]?.display) {
		button = (
			<MapButton title={f('widgets')}>
				<FiGrid />
			</MapButton>
		);
	} else {
		button = (
			<MapButton title={f('show widgets')} onClick={enableWidgets}>
				<FiGrid />
			</MapButton>
		);
	}

	const selector = React.cloneElement(React.Children.only(props.children), {
		customRef: box,
		onCancel: () => setOpened(false)
	});

	return (
		<Popup
			className="map-widgets-control"
			content={selector}
			isOpen={opened}
			customRef={buttonBox}
			setIsOpen={(value) => !opened && setOpened(value)}
		>
			{button}
		</Popup>
	);
}

export default connect(state => ({
	widgets: state.widgets
}))(MapWidgetsControl);
