import React from 'react';
import { Notification } from 'rsuite';
import { capitalize } from './core/misc/misc';

class NotificationsClass {
	constructor() {
		this.types = {
			info: 'info',
			success: 'success',
			warning: 'warning',
			error: 'error'
		};
	}

	add(type, description, title) { // type = this.types, description = any, title = string
		Notification[type || 'open']({
			title: <span title={title} className="notification-title custom">{title || capitalize(type)}</span>,
			placement: 'bottomEnd',
			description: <div>{description}</div>
		});
	}

	clean() {
		Notifications.closeAll();
	}
}

export const Notifications = new NotificationsClass();