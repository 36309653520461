import React from 'react';
import { className } from '../../../lib/className';
import { useI18n } from '../../../../i18n';
import PinIcon from '../../../img/icons/pin-full.png';
import { capitalize } from '../../../misc/misc';

const FIELD_NAME = "odometer";

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.value
 * @param {string} props.units
 * @param {string} props.fieldName
 * @param {boolean} [props.pinned]
 * @param {function} [props.onClick]
 */

function OdometerFieldView(props) {
	const { fc } = useI18n();

	const onClick = () => {
		if (props.onClick) {
			props.onClick(props.fieldName);
		}
	}

	return (
		<div
			className={
				className(
					"message-field " + FIELD_NAME,
					{
						'clickable': !!props.onClick && !props.pinned,
						'pinned': props.pinned
					}
				)
			}
			title={props.pinned ? null : fc('pin')}
			onClick={props.pinned ? null : onClick}
		>
			<div className="main-block">
				{/*
					Use of capitalize function instead of 'capitalize' class intentionlly
					because 'text-transform: capitalize' applies only to block elements and here we need inline element
				*/}
				<span className="title">{capitalize(props.title) + ':'}</span>
				<span>{props.value}&nbsp;km</span>
			</div>
			{props.pinned &&
				<div className="pin-block clickable" onClick={onClick} title={fc('unpin')}>
					<img className="pin-icon" alt="" src={PinIcon} />
				</div>
			}
		</div>
	);
}

OdometerFieldView.FieldName = FIELD_NAME;

export default OdometerFieldView;