import { actions } from '../actions';

const defaultState = {
	preview: null,
	map: null,
	parameters: null,
	pending: false,
	error: null,
	// export
	exporting: false,
	csv: null,
};

function reducer(state = defaultState, action) {
	switch (action.type) {
		case actions.deviceRegistry.request.type:
			return {
				...state,
				parameters: action.parameters,
				pending: true,
				error: null,
			};
		case actions.deviceRegistry.success.type:
			return {
				...state,
				map: action.map,
				pending: false,
				error: null
			};
		case actions.deviceRegistry.exportDone.type:
			return {
				...state,
				exporting: false,
				csv: action.csv
			}
		case actions.deviceRegistry.exportClear.type:
			return {
				...state,
				csv: null
			}
		default:
			return state;
	}
}

export { reducer };
