import React from 'react';
import { className, fromProps } from '../../../lib/className';
import CountUp from 'react-countup';
import './coinBoard.scss';

/**
 * @param {Object} props
 * @param {number} props.quantity
 * @param {string} props.title
 */
function CoinBoard(props) {
	return (
		<div className={className('coin-board', fromProps(props))}>
			<div className="count"><CountUp end={props.quantity} preserveValue duration={2} /></div>
			{props.title && <div className="title"><span className="capitalize">{props.title}</span></div>}
		</div>
	);
}

export default CoinBoard;
