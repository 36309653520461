import { rx, cx, api } from '../../../api';
import { ofType, combineEpics } from 'redux-observable';
import { mergeMap, map, takeUntil, filter } from 'rxjs/operators';
import { actions as announcerActions } from '../announcer';
import { actions } from './actions';
import Processor from './Processor';
import { actions as sessionActions } from '../session';
import { errorMap } from '../../actions';

let processor = null;
function connect(store) {
	processor = new Processor(store);
}

// ---------------------------------------------------------

const HEAD_SIZE = 5;

// TODO: build only recentMap, map needs to be merged in the reducer
// TODO: build global recent events (over all devices), in the reducer
function processDevicesEvents(devicesEvents, state) {
	const map = { ...state.map }, recentMap = {};
	devicesEvents.forEach(deviceEvents => {
		if (deviceEvents.events) {
			let events = deviceEvents.events;
			if (events.length < HEAD_SIZE && map[deviceEvents.uri]) events = events.concat(map[deviceEvents.uri]);
			if (HEAD_SIZE < events.length) events = events.slice(0, HEAD_SIZE);
			map[deviceEvents.uri] = events;
			recentMap[deviceEvents.uri] = deviceEvents.events;
		}
	});
	return { map, recentMap };
}

// TODO: just build recentMap
function processDeviceEvents(uri, eventDetails, state) {
	let map = { ...state.map };
	map[uri] = eventDetails;
	return map;
}

// TODO: move to the reducer
function buildIndex(map) {
	let indexMap = {};
	Object.keys(map).forEach(uri => {
		const events = map[uri];
		events.forEach(event => indexMap[event.eventId] = uri);
	});
	return indexMap;
}

// ---------------------------------------------------------

const findEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.find.request.type),
		mergeMap(action => {
			processor.lock();
			return rx(api.devices.events.find, action.filter).pipe(
				map(operation => {
					processor.unlock();
					let { map, recentMap } = processDevicesEvents(operation.response(), state$.value.latestEvents);
					const indexMap = buildIndex(map);
					return actions.find.success({ maps: { map, recentMap, indexMap } });
				}),
				errorMap(actions.find.fail),
				takeUntil(action$.pipe(ofType(actions.find.cancel.type))),
			)
		})
	)
};

const findForUriEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.findForUri.request.type),
		mergeMap(action => {
			processor.lock();
			const filter = new cx.ods.devices.EventFilter();
			filter.window = new cx.ods.devices.EventWindowFilter({ size: action.size, forward: false });
			return rx(api.events.find, action.uri, filter).pipe(
				map(operation => {
					processor.unlock();
					let map = processDeviceEvents(action.uri, operation.response(), state$.value.latestEvents);
					const indexMap = buildIndex(map);
					return actions.findForUri.success({ maps: { map, recentMap: state$.value.latestEvents.recentMap, indexMap } });
				}),
				errorMap(actions.findForUri.fail),
				takeUntil(action$.pipe(ofType(actions.findForUri.cancel.type))),
			)
		})
	)
};

const watchSessionEpic = (action$) => {
	return action$.pipe(
		ofType(sessionActions.events.started.type),
		map(() => {
			const filter = new cx.ods.devices.EventFilter();
			filter.window = new cx.ods.devices.EventWindowFilter({ size: 5, forward: false });
			return actions.find.request({ filter });
		})
	)
}

const monitorSuccessEpic = (action$) => {
	return action$.pipe(
		ofType(actions.find.success.type),
		map(action => {
			processor.update();
			return action;
		}),
		filter(() => false) // HACK?
	)
}

const monitorAnnouncedEpic = (action$) => {
	return action$.pipe(
		ofType(announcerActions.announced.type),
		map(action => {
			processor.receive(action.announcements)
			return action;
		}),
		filter(() => false) // HACK?
	)
};


const epic = combineEpics(findEpic, findForUriEpic, watchSessionEpic, monitorSuccessEpic, monitorAnnouncedEpic);

export { epic, connect };
