import React from 'react';
import { FiUser } from 'react-icons/fi';
import { Avatar } from 'rsuite';
import { capitalize } from '../../../misc/misc';
import './accountInfo.scss';

/**
 * @param {Object} props
 * @param {cx.ods.accounts.AccountDetails} props.account
 * @param {boolean} [props.bare]
 */

function AccountInfo(props) {
	const name = props.account.fullName || props.account.loginName.split('@')[0];

	return (
		<div className="account-info">
			{!props.bare &&
				<div className="avatar">
					<Avatar size="lg"><FiUser /></Avatar>
				</div>
			}
			<div className="info">
				<div className="name">
					<span className="capitalize" title={capitalize(name)}>
						{name}
					</span>
				</div>
				<div className="login-name">
					<span title={props.account.loginName}>
						{props.account.loginName}
					</span>
				</div>
			</div>
		</div>
	)
}

export default AccountInfo;