import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import { ReduxKeyContext } from '../../../../misc/ReduxKeyContext';
import * as widgets from '../../../../redux/app/widgets';
import List from '../../../general/list/List';
import ListItem from '../../../general/list/ListItem';
import { WidgetType } from '../../../general/widgets/widgetRegistry';
import ActionClose from '../../../share/actionbar/ActionClose';

/**
 * @param {Object} props
 * @param {function} props.onCancel
 * @param {React.RefObject} props.customRef
 */

function TimeMachineWidgetSelector(props) {
	const { f } = useI18n();
	const reduxKey = useContext(ReduxKeyContext);
	const dispatch = useDispatch();

	const addWidget = (type) => {
		dispatch(widgets.actions.add({ domain: reduxKey, widgetType: type }));
	}

	const items = {
		[f('commuters')]: WidgetType.Commuters,
		[f('device info')]: WidgetType.DeviceInfo,
		[f('device events')]: WidgetType.DeviceEventsWidget,
		[f('history navigator')]: WidgetType.TimeMachinePlayerWidget,
		[f('time machine settings')]: WidgetType.TimeMachineSettingsWidget
	};

	return (
		<div className="widget-selector time-machine" ref={props.customRef}>
			<div className="header">
				<div className="title">
					{f('widgets')}
				</div>
				<ActionClose onClick={props.onCancel} />
			</div>
			<List>
				{Object.keys(items).sort().map(key =>
					<ListItem key={key} onClick={() => addWidget(items[key])}>{key}</ListItem>
				)}
			</List>
		</div>
	);
}

export default TimeMachineWidgetSelector;
