import React from 'react'
import { MenuItem } from "@blueprintjs/core";
import Checkbox from '../form/Checkbox';
import { className } from '../../../lib/className';
import { useI18n } from '../../../../i18n';
import './widgetMenuItem.scss';

/**
 * @param {Object} props
 * @param {function} props.onClick
 * @param {function} props.close
 * @param {string} props.text
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.canPin]
 * @param {boolean} [props.pinned]
 * @param {function} [props.onChangePin]
 */
function WidgetMenuItem(props) {
	const { fc } = useI18n();

	const onClickHandler = () => {
		if (!props.disabled) {
			props.onClick && props.onClick();
			props.close && props.close();
		}
	}

	return (
		<div className="widget-menu-item">
			<MenuItem onClick={onClickHandler} disabled={props.disabled} text={props.text} />
			{props.canPin &&
				<div className={className("checkbox-pin-action", { 'pinned': props.pinned })}>
					<Checkbox value={props.pinned} onChange={props.onChangePin} title={fc(props.pinned ? 'remove from toolbar' : 'add to toolbar')} />
				</div>
			}
		</div>
	);
}

export default WidgetMenuItem;