import { store } from '../redux/store';
import { f } from "../../i18n";
import { decimalRound } from './misc';

export const getDeviceFuelLevelBalance = (value, uri, withUnits) => {

	const state = store.getState();
	const device = state.devices.map && state.devices.map[uri];
	const propertieMap = state.meta.properties.map;
	const unitMap = state.meta.units.map;
	const _value = value ? ' (' + decimalRound(value, 2) + (withUnits ? f({ prefix: 'units', id: '%' }) : '') + ')' : '';

	let result = (' ' + _value);
	if (value != null && device && device.properties && propertieMap && unitMap) {
		let capacity = null, unitId = null;
		device.properties.some(__value => {
			const property = propertieMap[__value.propertyId];
			if (property && property.mnemonics == "fuel-tank-capacity") {
				capacity = __value.value;
				unitId = property.unitId;
				return true;
			}
			return false
		});
		if (capacity) {
			const units = withUnits && unitMap[unitId]
				? (' ' + f({ prefix: 'units', id: unitMap[unitId].symbol }))
				: ''
			;
			result = decimalRound(capacity / 100 * value, 1) + units + _value;
		}
	}
	return result;
}