import React from 'react';
import DevicesSidebar from '../custom/sidebar/DeviceSidebar';
import SidebarLayout from './SidebarLayout';

function DashboardLayout(props) {

	return (
		<SidebarLayout sidebar={<DevicesSidebar />}>
			{props.children}
		</SidebarLayout>
	);
}

export default DashboardLayout;