import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../redux/api/assets';
import { designateEmployee } from '../../../redux/api/assets/employees/reducer';
import Loader from '../../general/Loader';
import TagPicker from '../../general/form/TagPicker';
import { useI18n } from '../../../../i18n';

/**
 * @param {Object} props
 * @param {Array.<number>} props.value
 * @param {function} props.onChange
 * @param {string} props.label
 * @param {boolean} [props.disabled]
 */

function EmployeeSelector(props) {
	const { f } = useI18n();
	let content;
	useEffect(() => {
		if (props.employees.list == null && !props.employees.pending) {
			props.dispatch(actions.employees.load.request());
		}
	}, []);

	if (props.employees.list != null) {
		const items = props.employees.list.map(employee => ({
			value: employee.assetId,
			label: designateEmployee(employee)
		}));

		content = <TagPicker
			data={items}
			value={props.value}
			onChange={props.onChange}
			placeholder={f('select employees')}
			label={props.label}
			disabled={props.disabled}
			style={{ minHeight: '37px' }}
		/>;
	} else if (props.employees.pending) content = <Loader />;

	return (
		<div className="employee-selector">
			{content}
		</div>
	);
}

export default connect(state => ({
	employees: state.assets.employees
}))(EmployeeSelector);
