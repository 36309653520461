import React, { FC } from 'react';
import { FormProvider } from 'react-hook-form';

import { FormStyled } from 'ui/pages/issues/issues-actions-select.styled';
import { IIssue } from 'types/issues';
import { useIssueActionForm } from 'hooks/issues/useIssueActionForm';
import { FormHeader } from './form-header';
import { ConfirmModal } from './confirm-modal';
import { FormContent } from './form-content';

interface IIssueActionFormProps {
  issue: IIssue;
}

export const IssueActionForm: FC<IIssueActionFormProps> = ({ issue }) => {
  const { isDropdownOpen, wrapperRef, formMethods, headerProps, contentProps, modalProps } = useIssueActionForm(issue);

  return (
    <FormProvider {...formMethods}>
      <FormStyled ref={wrapperRef}>
        <FormHeader {...headerProps} />
        {isDropdownOpen && <FormContent issueTypeId={issue.issueTypeId} {...contentProps} />}
        <ConfirmModal {...modalProps} />
      </FormStyled>
    </FormProvider>
  );
};
