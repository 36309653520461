import React, { useEffect, useState, useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import { actions as processorActions } from '../../../../redux/api/processors';
import { actions as widgetsActions } from '../../../../redux/app/widgets';
import ListItem from '../../../general/list/ListItem';
import PresenceDetectorPicker from '../../devicePresenceDetectors/PresenceDetectorPicker';
import iconOk from '../../../../img/widgets/device-presence-ok.png';
import iconIssue from '../../../../img/widgets/device-presence-issue.png';
import PresenseDetectorControl from '../../devicePresenceDetectors/PresenseDetectorControl';
import DeviceAssetTypeIcon from '../../../share/devices/DeviceAssetTypeIcon';
import { className, fromProps } from '../../../../lib/className';
import NoDataView from '../../../general/view/NoDataView';
import './xDetectorWidget.scss';

const matchesActions = processorActions.devicePresenceMatches;
const actions = processorActions.devicePresence;

/**
 * @param {Object} props
 * @param {cx.ods.processors.DevicePresenceMatch} props.match
 */

function _DeviceListItem(props) {
	return (
		<ListItem className={className(fromProps(props))}>
			<div className="icon">
				<DeviceAssetTypeIcon categoryIds={props.device && props.device.categoryIds} />
			</div>
			<div className="content">
				<div>{props.device && props.device.denomination()}</div>
				<div>{props.match.detectedAt.toLocaleString()}</div>
			</div>
		</ListItem>
	)
}

const DeviceListItem = connect(
	(state, props) => ({
		device: state.devices.map && state.devices.map[props.match.uri]
	})
)(_DeviceListItem);


/**
 * @param {Object} props
 * @param {string} props.uid
 * @param {React.RefObject} props.customRef
 * @param {function} props.update to rerender WidgetContainer
 */

function PresenceDetectorWidget(props) {
	const { f, fc, p } = useI18n();
	const widget = props.widget;
	const _processorId = widget && widget.data && widget.data.processorId;
	const [processorId, setProcessorId] = useState(_processorId || null);

	const absents = [];
	const presents = [];

	let detector = null;
	if (props.detectors.map != null && _processorId != null) {
		detector = props.detectors.map[_processorId];
	}

	useEffect(() => {
		if (props.detectors.map == null) {
			props.dispatch(actions.load.request());
		}
	}, []);

	useEffect(() => {
		if (_processorId != null) {
			props.dispatch(matchesActions.subscribe({ processorId: _processorId }));
		}
		return () => {
			if (_processorId != null) {
				props.dispatch(matchesActions.unsubscribe({ processorId: _processorId }));
			}
		}
	}, [_processorId]);

	useEffect(() => {
		if (processorId != null) {
			props.dispatch(widgetsActions.update({ domain: props.reduxKey, uid: props.uid, data: { processorId } }));
		}
	}, [processorId]);

	useEffect(() => {
		if (detector != null) {
			props.dispatch(matchesActions.matches.request({ processorId: _processorId }));
		}
	}, [detector]);

	const matches = _processorId != null
		&& props.matches[_processorId]
		&& props.matches[_processorId].matches
	;

	useEffect(() => {
		props.update();
	}, [matches]);

	let content = null;
	if (detector != null) {
		if (matches != null && matches.length > 0) {
			matches.forEach(match => {
				if (match.targetURI != null) {
					presents.push(match);
				} else {
					absents.push(match);
				}
			});
			content = (<>
				<div className="indicator">
					{absents.length > 0 &&
						<img src={iconIssue} alt="" />
					}
					{absents.length == 0 &&
						<img src={iconOk} alt="" />
					}
				</div>
				<div className="summary">
					{p('X issues', absents.length)}
				</div>
			</>);
		} else {
			content = <NoDataView />;
		}
	}

	const listItems = absents.map(match => <DeviceListItem key={match.uri} className="x-detector-widget-list-item" match={match} />);

	useImperativeHandle(props.customRef, () => ({
		title: () => detector && detector.name || fc('select detector'),
		configuration: () => {
			return (
				<PresenseDetectorControl
					value={_processorId}
					label={f('device presence detector')}
					placeholder={f('select detector')}
				/>
			)
		},
		dynamicContent: () => {
			return (absents.length > 0 && listItems);
		},
		expandable: matches != null && matches.length > 0 && absents.length > 0
	}));

	return (
		<div className="x-detector-widget device-presence">
			{detector == null &&
				<PresenceDetectorPicker
					value={processorId}
					onChange={setProcessorId}
					placeholder={f('select detector')}
				/>
			}
			{content}
		</div>
	);
}

export default connect(
	(state, props) => ({
		widget: state.widgets[props.reduxKey].map[props.uid],
		detectors: state.processors.devicePresence,
		matches: state.processors.devicePresenceMatches
	})
)(PresenceDetectorWidget);
