import { defer, from } from 'rxjs';

const baseURL = new URL(`${process.env.REACT_APP_API_ENDPOINT}/v3/`);

const interceptors = {
	request: []
	, response: []
};

export const interceptRequest = interceptor => {
	interceptors.request.push(interceptor);
};

export const interceptResponse = interceptor => {
	interceptors.request.push(interceptor);
};

export const rs = uri => {
	const url = new URL(uri, baseURL);
	const options = {
		cors: 'cors', credentials: 'include'
		, headers: {}
	};
	let includeAuth = true;
	let bearerToken = null;
	let contentType = 'application/json';
	const builder = {
		param: (name, value) => {
			url.searchParams.append(name, value);
			return builder;
		}
		, optional: (name, value, predicate = value => value != null) => {
			if (predicate(value)) builder.param(name, value);
			return builder;
		}
		, skipAuth: () => {
			includeAuth = false;
			return builder;
		}
		, bearer: token => {
			bearerToken = token;
		}
		, contentType: mimeType => {
			contentType = mimeType;
		}
		, get: () => {
			options.method = 'GET';
			return builder;
		}
		, post: payload => {
			options.method = 'POST';
			if (payload != null) {
				options.headers['Content-Type'] = contentType;
				options.body = JSON.stringify(payload);
			}
			return builder;
		}
		, put: payload => {
			options.method = 'PUT';
			if (payload != null) {
				options.headers['Content-Type'] = contentType;
				options.body = JSON.stringify(payload);
			}
			return builder;
		}
		, delete: () => {
			options.method = 'DELETE';
			return builder;
		}
		, send: async () => {
			interceptors.request.forEach(interceptor => interceptor(builder));
			if (includeAuth && bearerToken != null) options.headers['Authorization'] = `Bearer ${bearerToken}`;
			let response$ = fetch(url, options);
			response$ = interceptors.response.reduce((response$, interceptor) => response$.then(interceptor), response$);
			return response$.then(response => {
				const hasJSON = response.headers.get('Content-Type') == 'application/json' && !(response.headers.get('Content-Length') == 0);
				const payload = hasJSON ? response.json() : null;
				if (response.ok) return payload;
				const abort = error => {
					let message = `Request failed, #${response.status}`;
					if (error?.message != null) message += `, ${error?.message}`;
					throw new Error(message);
				};
				if (payload == null) abort();
				return payload.then(abort);
			});
		}
		, defer$: () => defer(() => from(builder.send()))
	};
	return builder;
};
