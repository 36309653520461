import { api, rx, ods } from '../../../api';
import { ofType } from 'redux-observable';
import { mergeMap, filter, catchError } from 'rxjs/operators';
import { APP_NAME } from './const';
import { ActionGeneratorBuilder } from '../../actions';

const level = {
	FINEST: 'FINEST',
	FINER: 'FINER',
	FINE: 'FINE',
	INFO: 'INFO',
	WARNING: 'WARNING',
	SEVERE: 'SEVERE',
}

const actions = new ActionGeneratorBuilder('appLog')
	.subtype('log', log => log.request({ level: true, message: true }).success().fail())
	.build()
;

const ws = api.application;


const epic = (action$) => {
	return action$.pipe(
		ofType(actions.log.request.type),
		mergeMap(action => {
			const message = new ods.application.LogMessage();
			message.level = action.level;
			message.message = action.message;
			return rx(ws.log, APP_NAME, message).pipe(
				catchError(error => console.log(error)),
				filter(() => false)
			)
		})
	)
}

export { actions, epic, level };
