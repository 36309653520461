import React from 'react';
import { cx } from '../../../../../api';
import { useI18n } from '../../../../../../i18n';
import InputPicker from '../../../../general/form/InputPicker';


/**
 * For other parameters - see InputPicker component
 * @param {Object} props
 */
function SmoothMethodPicker(props) {
	const { fc } =useI18n();
	const methodMap = cx.ods.reports.FuelLevelSmoothMethods;
	const methodsDescription = {
		SIMPLE_AVERAGE: {
			name: 'simple average',
			description: 'Simplified average over specified number of preceding measurements.'
		},
		EXPONENTIAL_AVERAGE: {
			name: 'exponential average',
			description: 'Exponentially weighted moving average. Averages preceding measurements, accounting older ones lesser.'
		},
		MEDIAN: {
			name: 'median',
			description: 'Middle value of measurements of moving time frame.'
		},
		LINEAR_REGRESSION: {
			name: 'linear regression',
			description: 'Value estimation using line fit on measurements of moving time frame.'
		}
	};
	const methods = Object.values(methodMap).map(value => ({
		value,
		label: fc({ prefix: 'smoothing-method', id: methodsDescription[value] ? methodsDescription[value].name : value })
	}));
	const compare = (left, right) => {
		const leftName = left.label.toLowerCase(), rightName = right.label.toLowerCase();
		return leftName < rightName ? -1 : leftName > rightName ? 1 : 0;
	}
	methods.sort(compare);

	return (
		<InputPicker
			block
			data={methods}
			label={fc('smooth method')}
			placeholder={fc('smooth method')}
			description={props.value && methodsDescription[props.value]
				? fc({ prefix: 'smoothing-method', id: methodsDescription[props.value] ? methodsDescription[props.value].description : props.value })
				: null
			}
			{...props}
		/>
	);
}

export default SmoothMethodPicker;