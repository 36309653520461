import { rx, cx, api } from '../../../api';
import { ofType, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, map as rxmap, filter } from 'rxjs/operators';
import { actions as latestEventsActions } from '../latestEvents';
import { actions } from './actions';
import { BATCH_SIZE, WINDOW_SIZE } from './misc';

const context = {}; // key => EventFilter

const getKey = (uri, uid) => uri + "-" + uid;

const getFilter = (forward) => {
	const filter = new cx.ods.devices.EventFilter();
	filter.window = new cx.ods.devices.EventWindowFilter({ size: BATCH_SIZE, forward });
	return filter;
}

const updateFilter = (filter, events) => {
	events.forEach(event => {
		filter.window.anchorOn(event);
	});
}

// ---------------------------------------------------------

const findEpic = (action$) => {
	return action$.pipe(
		ofType(actions.findEvents.type),
		rxmap(action => {
			const filter = context[getKey(action.uri, action.uid)] = getFilter(false);
			filter.minGeneratedAt = action.since;
			filter.maxGeneratedAt = action.until;
			filter.eventTypes = action.eventTypes;
			return actions.find.request({ uri: action.uri, uid: action.uid, filter });
		})
	)
}

const moveForwardEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.moveForward.type),
		rxmap(action => {
			let filter = context[getKey(action.uri, action.uid)];
			if (!filter.window.forward) {
				filter = context[getKey(action.uri, action.uid)] = getFilter(true);
				const state = state$.value.deviceEvents[action.uri][action.uid];
				state.list && updateFilter(filter, state.list);
			}
			return actions.find.request({ uri: action.uri, uid: action.uid, filter });
		})
	)
}

const moveBackwardEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.moveBackward.type),
		rxmap(action => {
			let filter = context[getKey(action.uri, action.uid)];
			if (filter.window.forward) {
				filter = context[getKey(action.uri, action.uid)] = getFilter(false);
				const state = state$.value.deviceEvents[action.uri][action.uid];
				state.list && updateFilter(filter, state.list);
			}
			return actions.find.request({ uri: action.uri, uid: action.uid, filter });
		})
	)
}

const _updateFilterEpic = (action$) => {
	return action$.pipe(
		ofType(actions.find.success.type),
		rxmap(action => {
			const filter = context[getKey(action.uri, action.uid)];
			updateFilter(filter, action.list);
		}),
		filter(() => false)
	)
}

const _findEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.find.request.type),
		mergeMap(action => {
			return rx(api.events.find, action.uri, action.filter).pipe(
				mergeMap(operation => {
					const filter = context[getKey(action.uri, action.uid)];
					const list = operation.response();
					const state = state$.value.deviceEvents[action.uri][action.uid];
					const overflow = state.list && state.list.length + list.length > WINDOW_SIZE;
					const emit = [actions.find.success({ uri: action.uri, uid: action.uid, filter, list })];
					if (filter.window.forward) {
						emit.push(actions.addHead({ uri: action.uri, uid: action.uid, list }));
						if (overflow) emit.push(actions.removeTail({ uri: action.uri, uid: action.uid }));
					} else {
						emit.push(actions.addTail({ uri: action.uri, uid: action.uid, list }));
						if (overflow) emit.push(actions.removeHead({ uri: action.uri, uid: action.uid }));
					}
					return of.apply(this, emit);
				}),
				catchError(error => actions.find.fail({ uri: action.uri, uid: action.uid, errorMessage: error.userMessage || error.message })),
				// takeUntil(action$.pipe(ofType(actions.load.cancel.type))) // TODO ???
			)
		})
	)
}

const _watchNewEventsEpic = (action$, state$) => {
	return action$.pipe(
		ofType(latestEventsActions.find.success.type),
		filter(action => {
			const newUris = action.maps.recentMap && Object.keys(action.maps.recentMap);
			const uris = Object.keys(state$.value.deviceEvents);
			const intersection = newUris.filter(uri => uris.indexOf(uri) >= 0);
			return intersection.length > 0;
		}),
		mergeMap(action => {
			const emit = [];
			const map = action.maps.recentMap;
			if (map != null) {
				Object.keys(state$.value.deviceEvents).forEach(uri => {
					state$.value.deviceEvents[uri] && Object.keys(state$.value.deviceEvents[uri]).forEach(uid => {
						if (map[uri] != null) {
							emit.push(actions.addHead({ uri, uid, list: map[uri] }));
						}
					});
				});
			}
			return of.apply(this, emit);
		})
	)
}

const epic = combineEpics(findEpic, _updateFilterEpic, moveForwardEpic, moveBackwardEpic, _findEpic, _watchNewEventsEpic);

export { epic };
