/**
 * @enum {string}
 */
const reportTypes = {
	LocationHistory: 'locationHistory',
	Trip: 'trip',
	TripHistory: 'tripHistory',
	Speeding: 'speeding',
	Idling: 'idling',
	TemperatureChange: 'temperatureChange',
	TemperatureHistory: 'temperatureHistory',
	DeviceUsages: 'deviceUsages',
	DeviceSituations: 'deviceSituations',
	DeviceStatus: 'deviceStatus',
	DeviceUtilization: 'deviceUtilization',
	DrivingBehaviour: 'drivingBehaviour',
	DeviceRegistry: 'deviceRegistry',
	ZoneVisits: 'zoneVisits',
	FuelLevel: 'fuelLevel',
	Ridership: 'ridership',
	Commute: 'commute'
};

/**
 * @param {ReportType} type
 * @returns {string}
 */
const toUrl = type => type.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();

/**
 * @param {string} url
 * @returns {ReportType}
 */
const fromUrl = url => url.replace(
	/([-_][a-z])/g
	, group => group.toUpperCase().replace('-', '').replace('_', '')
);

const name = type => {
	const str = type.replace(/([A-Z])/g, ' $1');
	const tokens = str.split(' ');
	return tokens.map(token => token.toLowerCase()).join(' ');
};

const ReportType = Object.assign({toUrl, fromUrl, name, enum: reportTypes}, reportTypes);

export default ReportType;
