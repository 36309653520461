import React, { FC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { SelectOptionStyled } from 'ui/pages/issues/issues-actions-select.styled';
import { RadioButtonStyled } from 'ui/atoms/radio-button.styled';
import { fc } from 'i18n';
import { IIssueActionForm } from 'hooks/issues/useIssueActionForm';

interface ISelectOptionProps {
  isLoading: boolean;
  value: number;
  label: string;
}

export const SelectOption: FC<ISelectOptionProps> = (props) => {
  const { isLoading, value, label } = props;

  const { control, setValue } = useFormContext<IIssueActionForm>();

  const resolutionTypeId = useWatch({ control, name: 'resolutionTypeId' });

  const onChange = () => setValue('resolutionTypeId', value);

  return (
    <Controller
      control={control}
      name="resolutionTypeId"
      render={() => (
        <SelectOptionStyled htmlFor={String(value)} isDisabled={isLoading}>
          <RadioButtonStyled
            type="radio"
            name="resolutionTypeId"
            id={String(value)}
            checked={value === resolutionTypeId}
            onChange={onChange}
          />
          {fc(label)}
        </SelectOptionStyled>
      )}
    />
  );
};
