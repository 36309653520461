import React from 'react';
import { connect } from 'react-redux';
import GroupMessageFieldsView from '../message/GroupMessageFieldsView';
import { fromProps, className } from '../../../lib/className';

/**
 * @param {Object} props
 * @param {Array.<string>} props.uris
 */

function DeviceStatisticsView(props) {
	const messages = [];

	let content = null;

	if (props.uris.length > 0) {
		props.uris.forEach(uri => {
			if (props.deviceStatesMap && props.deviceStatesMap[uri] && props.deviceStatesMap[uri].message) {
				messages.push(props.deviceStatesMap[uri].message);
			}
		});
	}

	content = (
		<div className={className("devices-statistics-view", fromProps(props))}>
			<GroupMessageFieldsView messages={messages} uris={props.uris} />
		</div>
	);

	return (content);
}

export default connect((state, props) => {
	let deviceStatesMap = null;
	if (props.reduxKey == 'dashboard') {
		deviceStatesMap = state.deviceStates.map;
	} else if (props.reduxKey == 'timeMachine') {
		deviceStatesMap = state.timeMachine.state.map
	}
	return { deviceStatesMap };
})(DeviceStatisticsView);