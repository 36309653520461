import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { className, fromProps } from '../../../../lib/className';
import { useI18n } from '../../../../../i18n';
import { getDevicesCategoryList, getDevicesList } from '../../../../redux/app/deviceFilter';
import ListItem from '../../../general/list/ListItem';
import DeviceList from './DeviceList';
import { DeviceSelectorContext } from './DeviceSelector';
import { FiPlus, FiMinus } from 'react-icons/fi';

/**
 * @param {Object} props
 * @param {cx.ods.categories.CategoryData} [props.category]
 */

function CategoryListItem(props) {
	const { f, fc } = useI18n();
	const [collapsed, setCollapsed] = useState(true);
	const deviceContext = useContext(DeviceSelectorContext);

	let deviceList = null;
	let devices = null;
	const categoryId = props.category ? props.category.categoryId : null;

	useEffect(() => {
		props.deviceFilter.name && devices && devices.length > 0 && setCollapsed(false);
	}, [props.deviceFilter.name]);

	function getDevices() {
		return categoryId
			? getDevicesCategoryList(categoryId)
			: getDevicesList().filter(device => {
				let categories = device.categoryIds;
				let except = props.exceptCategories;
				if (categories && except) {
					let result = true;
					for (let i = 0; i < categories.length; i++) {
						for (let y = 0; y < except.length; y++) (except[y].categoryId === categories[i]) && (result = false);
					}
					return result;
				} else return true;
			})
		;
	}

	devices = getDevices();

	useEffect(() => {
		if (devices.some(device => collapsed && deviceContext.selectedUris.includes(device.uri))) {
			setCollapsed(false);
		}
	}, [props]);

	const onSelectAll = () => {
		if (collapsed) toggleView();
		deviceContext.onDevicesClick(devices.map(device => device.uri));
	}

	if (props.devices.categoriesMap != null) {
		if (devices && devices.length > 0) {
			deviceList = (
				<DeviceList
					devices={devices}
					categoryId={categoryId}
				/>
			);
		}
	}

	const toggleView = () => {
		setCollapsed(!collapsed)
	}

	return (
		<>
			<ListItem
				className={className(
					'category',
					fromProps(props),
					{
						'expandable': devices && devices.length == 0 && collapsed,
						'empty': devices && devices.length == 0
					}
				)}
			>
				<div className="content">
					<span
						className="category-name clickable"
						onClick={onSelectAll}
						title={fc('select/deselect group')}
					>
						{props.category ? props.category.name : f('uncategorized')}
					</span>
					<span
						className="toggle-view"
						onClick={toggleView}
						title={collapsed ? fc('expand') : fc('collapse')}
					>
						{collapsed ? (<FiPlus />) : (<FiMinus />)}
					</span>
				</div>
			</ListItem>
			<ListItem className={className('sub-list', { collapsed })}>
				{deviceList}
			</ListItem>
		</>
	);
}

export default connect(state => {
	return {
		devices: state.devices,
		deviceFilter: state.deviceFilter
	};
})(CategoryListItem);