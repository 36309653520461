import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { IIssue } from 'types/issues';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { actions as issuesActions } from 'core/redux/api/veta/issues';
import { issuesStateSelector } from 'core/redux/selectors/issues';

export interface IIssueActionForm {
  issueId: number | null;
  resolutionTypeId: number | null;
  resolution: string;
}

export const useIssueActionForm = (issue: IIssue) => {
  const { id, resolutionTypeId, resolution } = issue;
  const defaultValues = { resolutionTypeId: resolutionTypeId || null, resolution: resolution || '' };

  const modalRef = useRef(null);
  const wrapperRef = useRef(null);
  const loadingRef = useRef(false);

  const dispatch = useDispatch();

  const { operations } = useSelector(issuesStateSelector);

  const formMethods = useForm<IIssueActionForm>({ mode: 'onSubmit', defaultValues });

  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const { handleSubmit, reset } = formMethods;

  const closeDropdown = () => {
    reset(defaultValues);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => (isDropdownOpen ? closeDropdown() : setDropdownOpen(true));

  const closeModal = () => {
    closeDropdown();
    setModalOpen(false);
  };

  const openModal = () => setModalOpen(true);

  const updateResolution = handleSubmit(({ resolutionTypeId, resolution }) => {
    const payload = { issueId: id, resolution: { resolutionTypeId, resolution: resolution || null } };

    dispatch(issuesActions.operation.resolve(payload));
    loadingRef.current = true;
  });

  const onSubmit = () => {
    resolutionTypeId ? openModal() : updateResolution();
  };

  useOnClickOutside([wrapperRef, modalRef], closeDropdown);

  useEffect(() => {
    if (!operations[id]?.pending && loadingRef.current) {
      loadingRef.current = false;

      closeModal();
    }
  }, [operations[id]]);

  useEffect(() => {
    reset(defaultValues);
  }, [issue]);

  const headerProps = { isOpen: isDropdownOpen, isResolved: !!resolutionTypeId, toggleDropdown };
  const contentProps = { isLoading: loadingRef.current, onClose: closeDropdown, onSubmit };
  const modalProps = {
    isOpen: isModalOpen,
    isLoading: loadingRef.current,
    modalRef,
    closeModal,
    updateResolution,
  };

  return { isDropdownOpen, wrapperRef, formMethods, headerProps, contentProps, modalProps };
};
