import React, { FC } from 'react';

import {
  AttributeContentStyled,
  AttributeHeaderStyled,
  AttributeRangeStyled,
  DetectorAttributeStyled,
  AttributeTileStyled,
} from 'ui/pages/attribute-range-detectors/detector-attribute.styled';
import { useI18n } from 'i18n';
import { IAttributeRangeDetector } from 'types/detectors';
import { useAttribute } from 'hooks/attribute-range-detectors/useAttribute';
import { useAttributeDescription } from 'hooks/attribute-range-detectors/useAttributeDescription';

interface IAttributeInfoProps {
  detector: IAttributeRangeDetector | null;
}

export const AttributeInfo: FC<IAttributeInfoProps> = ({ detector }) => {
  const { fc } = useI18n();

  const { messageField, attributeType } = useAttribute(detector?.fieldId);
  const attributeRangeDescription = useAttributeDescription(detector, attributeType);

  return (
    <DetectorAttributeStyled>
      <AttributeHeaderStyled>
        <label>{fc('attribute')}</label>
      </AttributeHeaderStyled>
      <AttributeContentStyled>
        {detector && (
          <>
            <AttributeTileStyled>
              {messageField && fc({ prefix: 'message-field', id: messageField.description })}
            </AttributeTileStyled>
            <AttributeRangeStyled>
              <label>{fc('value range')}</label>
              {attributeRangeDescription}
            </AttributeRangeStyled>
          </>
        )}
      </AttributeContentStyled>
    </DetectorAttributeStyled>
  );
};
