import React, { useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { className } from '../../../../lib/className';
import { scrollReportItemIntoView } from '../../../../misc/misc';
import Loader from '../../../general/Loader';
import { useI18n } from '../../../../../i18n';
import Address from '../../../general/location/Address';
import DatetimeField from '../../../general/datetime/DatetimeField';
import ScrollList from '../../../general/list/ScrollList';
import ReportSummary from './ReportSummary';
import ReportHeader from './ReportHeader';
import { actions } from '../../../../redux/api/reports';
import SwitchMapMenuItem from './SwitchMapMenuItem';
import RouteInspectionMap from './RouteInspectionMap';
import NoDataView from '../../../general/view/NoDataView';
import './ridership.scss';

const SEARCH_LIMIT_FOR_START = 100;

function HeaderListItem() {
	const { f } = useI18n();
	return (
		<>
			<div className="header-item"><span className="capitalize">{f('item No.')}</span></div>
			<div className="header-item"><span className="capitalize">{f('time')}</span></div>
			<div className="header-item"><span className="capitalize">{f('location')}</span></div>
			<div className="header-item"><span className="capitalize">{f('boarded')}</span></div>
			<div className="header-item"><span className="capitalize">{f('alighted')}</span></div>
			<div className="header-item"><span className="capitalize">{f('aboard')}</span></div>
		</>
	);
}

/**
 * @param {Object} props
 * @param {cx.ods.reports.RidershipReportEntry} props.entry
 * @param {number} props.index
 * @param {function} props.onClick
 * @param {React.Ref} [props.customRef]
 * @param {boolean} [props.selected]
 */

function ReportListItem(props) {
	const { fc } = useI18n();
	const entry = props.entry;
	const isServiceStart = entry.serviceStart;
	const event = props.entry.event;

	const onClick = () => {
		props.onClick(entry, props.index);
	}

	const _className = className('clickable', { 'selected': props.selected, 'start': isServiceStart });
	return (
		<>
			<div ref={props.customRef} className={_className} onClick={onClick}>{props.index}{isServiceStart && (' - '+ fc('start'))}</div>
			<div className={_className} onClick={onClick}><DatetimeField datetime={event.generatedAt} /></div>
			<div className={_className} onClick={onClick}>{entry.event.message && <Address message={entry.event.message} full />}</div>
			<div className={_className} onClick={onClick}>{entry.ingress || ' '}</div>
			<div className={_className} onClick={onClick}>{entry.egress || ' '}</div>
			<div className={_className} onClick={onClick}>{entry.passengers || ' '}</div>
		</>
	);
}

function EodListItem() {
	const { f } = useI18n();
	return (
		<div className="indicator">
			<span className="capitalize">
				{f('you have reached the end')}
			</span>
		</div>
	);
}

// -----------------------------------------------------------------

function Ridership(props) {
	const { f } = useI18n();
	const selectedItemBox = useRef();
	const [selectedEntry, setSelectedEntry] = useState(null);
	const [routeEntries, setRouteEntries] = useState(null);
	const [displayMap, setDisplayMap] = useState(false);
	const device = props.deviceMap && props.deviceMap[props.state.uri];

	useLayoutEffect(() => {
		if (selectedItemBox.current) {
			scrollReportItemIntoView(selectedItemBox.current);
		}
	}, [selectedEntry, displayMap]);

	const loadNext = () => {
		const uri = props.state.uri;
		const parameters = props.state.parameters;
		props.dispatch(actions.ridership.request({ uri, parameters }));
	}

	const onItemClick = (entry, index) => {
		if (!displayMap) setDisplayMap(true);
		if (!selectedEntry || selectedEntry.event.eventId != entry.event.eventId) {
			setSelectedEntry(entry);
			let serviceStartEntryIndex;
			let remainingLimit = SEARCH_LIMIT_FOR_START;
			for (let at = index - 1; at >= 0 && remainingLimit > 0 && serviceStartEntryIndex == null; --at, --remainingLimit) {
				const _entry = props.state.list[at];
				if (_entry.serviceStart) serviceStartEntryIndex = at;
			}
			if (serviceStartEntryIndex != null) {
				let foundRouteEnd = false;
				const _routeEntries = [props.state.list[serviceStartEntryIndex]];
				for (let at = serviceStartEntryIndex + 1; at < props.state.list.length && !foundRouteEnd; ++at) {
					const _entry = props.state.list[at];
					if (_entry.serviceStart) foundRouteEnd = true;
					else _routeEntries.push(_entry);
				}
				setRouteEntries(_routeEntries);
			} else {
				setRouteEntries([entry]);
			}
		}
	}

	const switchMapHandler = () => {
		setDisplayMap(!displayMap);
	}

	let content = null;
	let mapBox = null;
	let canExport = false;
	if (props.state.list != null) {
		if (!props.state.list || props.state.list.length == 0) {
			content = <NoDataView message={f('no data for such criteria')} />;
		} else {
			canExport = true;
			const items = props.state.list.map((entry, at) => {
				const isSelected = selectedEntry && selectedEntry.event.eventId === entry.event.eventId;
				return (
					<ReportListItem
						key={at}
						entry={entry}
						onClick={onItemClick}
						selected={isSelected}
						customRef={isSelected ? selectedItemBox : undefined}
						index={at+1}
					/>
				);
			});
			items.unshift(<HeaderListItem key={1000000} />);
			content = (
				<ScrollList pending={props.state.pending} next={loadNext} isEod={!props.state.hasMore} eodItem={EodListItem}>
					{items}
				</ScrollList>
			);
			if (displayMap) mapBox = (<RouteInspectionMap uri={props.state.uri} entries={routeEntries} selectedEntry={selectedEntry} />);
		}
	} else {
		content = (
			<div className="center">
				{
					props.state.error
						? <span className="error">{props.state.error}</span>
						: <Loader size={Loader.Size.MD} />
				}
			</div>
		);
	}

	const exportReport = () => {
		props.dispatch(actions.ridership.export({ uri: props.state.uri }));
		props.history.goBack();
	}

	const timeRange = props.state.parameters.timeRange;
	return (
		<div className="report ridership">
			<ReportHeader
				title={f('ridership report')}
				canExport={canExport}
				onExport={exportReport}
				items={<SwitchMapMenuItem disabled={!canExport} active={displayMap} onClick={switchMapHandler} />}
			/>
			<ReportSummary
				since={timeRange.since}
				until={timeRange.until}
				devices={device && [device]}
				generatedAt={props.state.at}
			/>
			<div className={className('content', { 'with-map': displayMap })}>
				{content}
				{mapBox}
			</div>
		</div>
	);
}

export default withRouter(connect(state => ({
	state: state.reports.ridership,
	deviceMap: state.devices.map
}))(Ridership));
