import { useFormContext, useWatch } from 'react-hook-form';

import { useI18n } from 'i18n';
import { IAttributeDetectorForm } from './useAttributeDetectorForm';
import { unitsString } from 'core/misc/attributeTypes';

export const useHysteresisFields = (mnemonics: string) => {
  const { fc } = useI18n();

  const { control, formState } = useFormContext<IAttributeDetectorForm>();
  const { errors } = formState;

  const units = unitsString(mnemonics);

  const leftBound = useWatch({ control, name: 'leftBound' });
  const rightBound = useWatch({ control, name: 'rightBound' });

  const lagError = errors.leftLag?.message || errors.rightLag?.message;

  const validateLag = (value: string) =>
    value &&
    (Number(value) <= 0 || (!!rightBound && !!leftBound && Number(value) >= Number(rightBound) - Number(leftBound)))
      ? fc('lag value must be positive, not exceeding interval length')
      : true;

  return { control, leftBound, rightBound, units, lagError, validateLag };
};
