import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import {
  FieldErrorStyled,
  FormErrorStyled,
  GeneralFieldsStyled,
} from 'ui/pages/attribute-range-detectors/detector-form.styled';
import { useI18n } from 'i18n';
import { useAttributeDetectorGeneralFields } from 'hooks/attribute-range-detectors/useAttributeDetectorGeneralFields';
import Input from 'core/react/general/form/Input';
import Checkbox from 'core/react/general/form/Checkbox';
import InputPicker from 'core/react/general/form/InputPicker';
import Button from 'core/react/general/form/Button';

interface IGeneralFieldsFieldsProps {
  isDisabled: boolean;
  error: string | null;
}

export const GeneralFields: FC<IGeneralFieldsFieldsProps> = ({ isDisabled, error }) => {
  const { f, fc } = useI18n();

  const {
    control,
    errors,
    eventTypesOptions,
    isOnInRange,
    isOnOutRange,
    handleRangeChange,
  } = useAttributeDetectorGeneralFields();

  //TODO: we should get error form fieldState when react-hook-form is upgraded
  return (
    <GeneralFieldsStyled>
      <Controller
        control={control}
        name="name"
        rules={{ required: fc('please enter a value') }}
        render={({ value, onChange }) => (
          <div>
            <Input autoFocus label={f('name')} disabled={isDisabled} value={value} onChange={onChange} />
            {/*TODO: error messages should be moved to corresponding form components when they are being refactored*/}
            {!!errors.name?.message && <FieldErrorStyled>{errors.name.message}</FieldErrorStyled>}
          </div>
        )}
      />
      <Controller
        control={control}
        name="onIn"
        render={({ value }) => (
          <Checkbox label={fc('in range')} disabled={isDisabled} value={value} onChange={handleRangeChange('onIn')} />
        )}
      />
      <Controller
        control={control}
        name="inEventType"
        render={({ value, onChange }) => (
          <InputPicker
            disabled={!isOnInRange}
            cleanable={false}
            data={eventTypesOptions}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="onOut"
        render={({ value }) => (
          <Checkbox
            label={fc('out of range')}
            disabled={isDisabled}
            value={value}
            onChange={handleRangeChange('onOut')}
          />
        )}
      />
      <Controller
        control={control}
        name="outEventType"
        render={({ value, onChange }) => (
          <InputPicker
            disabled={!isOnOutRange}
            cleanable={false}
            data={eventTypesOptions}
            value={value}
            onChange={onChange}
          />
        )}
      />
      {!!error && <FormErrorStyled>{error}</FormErrorStyled>}
      <Button type="submit" disabled={isDisabled}>
        {fc('save')}
      </Button>
    </GeneralFieldsStyled>
  );
};
