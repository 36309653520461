import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {useI18n} from '../../../../../i18n';

import {actions} from '../../../../redux/api/reports';
import {percentShare} from '../../../../redux/api/reports/drivingBehaviour';

import Loader from '../../../general/Loader';
import NoDataView from '../../../general/view/NoDataView';
import ScrollList from '../../../general/list/ScrollList';

import ReportHeader from '../renderer/ReportHeader';
import ReportSummary from '../renderer/ReportSummary';

import './renderer.scss';

export default withRouter(connect(state => ({
	state: state.reports.drivingBehaviour
	, deviceMap: state.devices.map
}))(props => {
	const {f} = useI18n();

	const reporting = props.state;
	const parameters = reporting.parameters;
	const devices = parameters.uris && parameters.uris.map(uri => props.deviceMap[uri]);
	const report = reporting.report;

	let content = null;
	if (reporting.error) content = <div className="center"><span className="error">{reporting.error}</span></div>;
	else if (report == null) content = <Loader size={Loader.Size.MD}/>;
	else if (report.length == 0) content = <NoDataView message={f('no data for such parameters')}/>
	else content = <ReportContent pending={reporting.pending} report={report}/>;

	const exportReport = () => {
		props.dispatch(actions.drivingBehaviour.export.request());
		props.history.goBack();
	};

	return <div className="report driving-behaviour">
		<ReportHeader 
			title={f('driving behaviour report')}
			canExport={0 < report?.length} onExport={exportReport}
		/>
		<ReportSummary 
			since={parameters.timeRange.since} until={parameters.timeRange.until}
			devices={devices} generatedAt={report?.timeAt}
		/>
		<div className='content'>{content}</div>
	</div>;
}));

const ReportContent = props => {
	const utilizations = props.report;

	return <ScrollList pending={props.pending}>
		<ListHeaders/>
		{utilizations.map((utilization, at) => <ListItem key={utilization.uri} itemNo={at + 1} utilization={utilization}/>)}
	</ScrollList>;
};

const ListHeaders = () => {
	const {f} = useI18n();

	return <div className='headers'>
		<div><span>{f('item No.')}</span></div>
		<div><span>{f('device')}</span></div>
		<div><span>{f('device-event.speeding')}</span></div>
		<div><span>{f('device-event.harsh acceleration')}</span></div>
		<div><span>{f('device-event.harsh braking')}</span></div>
		<div><span>{f('device-event.harsh cornering')}</span></div>
		<div><span>{f('violations')}</span></div>
		<div className='group-header statistics' key="odometer">
			<span>{f("odometer")}</span>
		</div>
		<div><span>{f('distance')}</span></div>
		<div><span>{f('utilization-phase.working')}</span></div>
		<div><span>{f('utilization-phase.parked')}</span></div>
		<div className='group-header idling'><span>{f('utilization-phase.idling')}</span></div>
		<div className='group-header shares'><span>{f('shares')}</span></div>
		<div className='sub-header statistics' key="sub-odometer">
			<span>{f('first')}</span><span>{f('last')}</span>
		</div>
		<div className='sub-header'><span>{f('hours')}</span></div>
		<div className='sub-header'><span>{f('units.%')}</span></div>
		<div className='sub-header'><span>{f('quantity')}</span></div>
		<div className='sub-header'><span>{f('violations')}</span></div>
		<div className='sub-header'><span>{f('distance')}</span></div>
		<div className='sub-header'><span>{f('utilization-phase.working')}</span></div>
		<div className='sub-header'><span>{f('utilization-phase.idling')}</span></div>
	</div>;
};

const ListItem = connect(state => ({
	deviceMap: state.devices.map
}))(props => {
	const device = props.deviceMap[props.utilization.uri];
	const {phases, totals, shares} = props.utilization;

	const idling = phases.idling?.duration || 0;
	const idlingShare = 0 < idling ? percentShare(idling / totals.duration) : '';

	const firstOdometer = Math.round(phases.total?.firstOdometer / 1000) || 0;
	const lastOdometer = Math.round(phases.total?.lastOdometer / 1000) || 0;

	return <div className='item'>
		<div>{props.itemNo}</div>
		<div>{device?.denomination()}</div>
		<div>{totals.violationTypes?.speeding}</div>
		<div>{totals.violationTypes?.acceleration}</div>
		<div>{totals.violationTypes?.braking}</div>
		<div>{totals.violationTypes?.cornering}</div>
		<div>{totals.violations || null}</div>
		<div className='statistics' key="odometer">
			<span>{firstOdometer}</span>
			<span>{lastOdometer}</span>
		</div>
		<div>{totals.distance || null}</div>
		<div>{phases.working?.duration}</div>
		<div>{phases.parked?.duration}</div>
		<div>{phases.idling?.duration}</div>
		<div>{idlingShare}</div>
		<div>{phases.idling?.quantity}</div>
		<div>{shares.violations}</div>
		<div>{shares.distance}</div>
		<div>{shares.working}</div>
		<div>{shares.idling}</div>
	</div>;
});
