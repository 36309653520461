import React, { useRef, useEffect } from 'react';
import { useI18n, f } from '../../../../../i18n';
import { formatter } from '../../../../misc/datetime';
import DatetimeField from '../../../general/datetime/DatetimeField';
import Address from '../../../general/location/Address';
import FuelLevelBalance from '../../../share/message/FuelLevelBalance';
import ScrollList from '../../../general/list/ScrollList';
import { scrollReportItemIntoView } from '../../../../misc/misc';
import { className } from '../../../../lib/className';
import './tripHistoryList.scss';

/**
 * @param {Object} props
 * @param {boolean} [props.hasFuelLevel] list item might be without fuel level
 */

function HeaderListItem(props) {
	const { f } = useI18n();
	return (
		<>
			<div className="header-item"><span className="capitalize">{f('item No.')}</span></div>
			<div className="header-item message">
				<div><span className="capitalize">{f('start')}</span></div>
				<div>
					<div><span className="capitalize">{f('time')}</span></div>
					<div><span className="capitalize">{f('location')}</span></div>
				</div>
			</div>
			<div className="header-item message">
				<div><span className="capitalize">{f('end')}</span></div>
				<div>
					<div><span className="capitalize">{f('time')}</span></div>
					<div><span className="capitalize">{f('location')}</span></div>
				</div>
			</div>
			<div className="header-item"><span className="capitalize">{f('duration')}</span></div>
			<div className="header-item"><span className="capitalize">{f('distance') + ', ' + f({ prefix: 'units', id: 'km'})}</span></div>
			{props.hasFuelLevel && <div className="header-item rows">
				<div><span className="capitalize">
					{f('fuel level balance') + ', ' + f({ prefix: 'units', id: 'l' }) + ' (' + f({ prefix: 'units', id: '%' }) + ')'}
				</span></div>
				<div>
					<div><span className="capitalize">{f('consumed')}</span></div>
					<div><span className="capitalize">{f('drained')}</span></div>
					<div><span className="capitalize">{f('refueled')}</span></div>
				</div>
			</div>}
		</>
	)
}

/**
 * @param {Object} props
 * @param {cx.ods.reports.TripHistoryReportEntry} props.entry
 * @param {number} props.index
 * @param {function} props.onClick
 * @param {boolean} [props.selected]
 * @param {boolean} [props.canClick]
 * @param {string} props.uri
 * @param {boolean} [props.hasFuelLevel] list item might be without fuel level
 * @param {Recat.Ref} [props.customRef]
 */

function ReportListItem(props) {
	const { fc } = useI18n();
	const entry = props.entry;

	const isTrip = !!entry.trip;
	const startMessage = isTrip ? entry.trip.startMessage : entry.startMessage;
	const lastMessage = isTrip ? entry.trip.lastMessage : entry.lastMessage;
	const onClick = () => {
		if (props.canClick) props.onClick(props.entry, props.index);
	}

	const _className = className({ 'selected': props.selected, 'clickable': props.canClick });
	const fuelLevelBalance = entry.fuelLevelBalance;
	const isUnfinished = isTrip && !entry.trip.endAt;
	return (
		<>
			<div ref={props.customRef} className={_className} onClick={onClick}>{props.index}{isTrip && (' - '+ fc('trip'))}</div>
			<div className={"message-group " + _className} onClick={onClick}>
				<div className={_className} onClick={onClick}><DatetimeField datetime={startMessage.generatedAt} /></div>
				<div className={_className} onClick={onClick}><Address message={startMessage} full /></div>
			</div>
			<div className={"message-group " + _className} onClick={onClick}>
				<div className={_className} onClick={onClick}><DatetimeField datetime={lastMessage.generatedAt} now={startMessage.generatedAt} /></div>
				<div className={_className} onClick={onClick}><Address message={lastMessage} full /></div>
			</div>
			<div className={_className} onClick={onClick}>
				{formatter.duration(startMessage.generatedAt, lastMessage.generatedAt)}
				{isUnfinished && <span className="capitalize">{f('unfinished')}</span>}
			</div>
			<div className={_className} onClick={onClick}>{entry.distance != null && (entry.distance / 1000)}</div>
			{props.hasFuelLevel && <div className={"fuel-level-balance-group " + _className} onClick={onClick}>
				<div>
					<FuelLevelBalance uri={props.uri} value={fuelLevelBalance && fuelLevelBalance.consumed} />
				</div>
				<div>
					<FuelLevelBalance uri={props.uri} value={fuelLevelBalance && fuelLevelBalance.drained} />
				</div>
				<div>
					<FuelLevelBalance uri={props.uri} value={fuelLevelBalance && fuelLevelBalance.refueled} />
				</div>
			</div>}
		</>
	)
}

/**
 * @param {Object} props
 * @param {function} props.onClick
 * @param {number} [props.selectedIndex]
 * @param {function} props.next
 * @param {function} props.previous
 * @param {boolean} [props.hasMore]
 * @param {boolean} [props.hasFuelLevel] list might be without fuel level
 * @param {boolean} [props.pending]
 * @param {boolean} [props.canClickItem]
 * @param {React.Component} [props.eodListItem] react component rendered in case of reaching EOD
 */

function TripHistoryList(props) {
	const selectedItemBox = useRef();

	useEffect(() => {
		if (selectedItemBox.current) {
			scrollReportItemIntoView(selectedItemBox.current);
		}
	}, [props.selectedIndex]);

	const items = props.list.map((entry, at) => {
		const index = at + 1;
		const isSelected = props.selectedIndex === index;
		return (
			<ReportListItem
				key={at}
				onClick={props.onClick}
				selected={isSelected}
				customRef={isSelected ? selectedItemBox : undefined}
				entry={entry}
				index={index}
				canClick={props.canClickItem}
				hasFuelLevel={props.hasFuelLevel}
				uri={props.uri}
			/>
		);
	});
	items.unshift(<HeaderListItem key={Number.MAX_SAFE_INTEGER} hasFuelLevel={props.hasFuelLevel} />);

	return (
		<ScrollList
			className={className("trip-history-list", { fuelLevel: props.hasFuelLevel })}
			pending={props.pending}
			next={props.next}
			isEod={!props.hasMore}
			eodItem={props.eodListItem}
		>
			{items}
		</ScrollList>
	);
}

export default TripHistoryList;