import React, { FC } from 'react';

import GeneralLayout from 'core/react/layout/GeneralLayout';
import { Issues } from 'components/organisms/issues';

export const IssuesPage: FC = () => (
  <GeneralLayout>
    <Issues />
  </GeneralLayout>
);
