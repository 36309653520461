import React, { useState } from 'react';
import { className } from '../../../lib/className';
import GlobalMenu from './GlobalMenu';
import './adaptiveGlobalMenu.scss';

function AdaptiveGlobalMenu(props) {
	const [open, setOpen] = useState(false);

	return (<>
		<div className={className('mobile-global-menu-button', { open })} onClick={() => setOpen(!open)}>
			<div />
			<div />
			<div />
		</div>
		<GlobalMenu className={className({ open })} />
	</>);
}

export default AdaptiveGlobalMenu;