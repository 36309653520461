import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import GridLayout from 'react-grid-layout';
import { useResizeDetector } from 'react-resize-detector';
import { fromProps, className } from '../../../lib/className';
import WidgetContainer from './WidgetContainer';
import ManInTheMiddle from './ManInTheMiddle';
import './widgetLayoutManager.scss';

const Layout = ManInTheMiddle(GridLayout);
const ROW_HEIGHT = 5;
const COL_WIDTH = 50;
const MARGIN = [10, 10];
const COMPACTING = 'vertical';
export const DRAG_HANDLE_CLASS = 'to-do-drag';
export const DRAG_CANCEL_CLASS = 'do-not-drag';

/**
 * @param {Object} props
 * @param {Object} props.layout
 * @param {function} props.setLayout
 * @param {function} props.onResizeWidget
 */

function WidgetLayoutManager(props) {
	const { width, ref: box } = useResizeDetector();
	const [ctrl, setCtrl] = useState(false);

	useEffect(() => {
		const onKeyDown = (event) => {
			if (event.key == 'Control') {
				setCtrl(true);
			}
		}
		const onKeyUp = (event) => {
			if (event.key == 'Control') {
				setCtrl(false);
			}
		}
		const onScroll = (event) => {
			if (event.ctrlKey) {
				event.preventDefault();
				if (event.deltaY > 0) {
					box.current.scrollTop += 150;
				} else {
					box.current.scrollTop -= 150;
				}
				return false;
			}
		}
		const onBlur = (event) => {
			setCtrl(false);
		}
		window.addEventListener("blur", onBlur);
		window.addEventListener("keydown", onKeyDown);
		window.addEventListener("keyup", onKeyUp);
		box.current.addEventListener("wheel", onScroll);
		return () => {
			window.removeEventListener("blur", onBlur);
			window.removeEventListener("keydown", onKeyDown);
			window.removeEventListener("keyup", onKeyUp);
			box.current.removeEventListener("wheel", onScroll);
		}
	}, []);

	const children = [];
	React.Children.forEach(props.children, (child, at) => {
		const dataItem = props.layout[at];
		children.push(
			<WidgetContainer
				key={dataItem.i}
				uid={dataItem.i}
				rowHeight={ROW_HEIGHT}
				margin={MARGIN}
			>
				{child}
			</WidgetContainer>
		);
	});

	const onLayoutChange = (layout) => {
		props.setLayout(layout);
	}

	const onResize = (layout, oldItem, newItem) => {
		props.onResizeWidget(newItem);
	}

	const height = box.current != null ? box.current.offsetHeight : 0;

	return (
		<div className={className('widget-layout-manager', fromProps(props), { ctrl })} ref={box}>
			<Layout
				width={width}
				layout={props.layout}
				height={height}
				margin={MARGIN}
				rowHeight={ROW_HEIGHT}
				colWidth={COL_WIDTH}
				compactType={COMPACTING}
				preventCollision={false}
				measureBeforeMount
				onDragStop={onLayoutChange}
				onResizeStop={onLayoutChange}
				onResize={onResize}
				draggableHandle={'.' + DRAG_HANDLE_CLASS}
				draggableCancel={'.' + DRAG_CANCEL_CLASS}
				parentNode={box.current}
			>
				{children}
			</Layout>
		</div>
	);
}

export default connect(state => {
	return {
		widgets: state.widgets
	}
})(WidgetLayoutManager);
