import React from 'react';

import { ISVGPersonalProps } from 'types/svg';

export const closeSign: ISVGPersonalProps = {
  viewBox: '0 0 7.845 7.845',
  content: (
    <g transform="translate(-6 -6)">
      <path
        d="M13.653,12.729a.654.654,0,1,1-.924.924L9.922,10.847,7.116,13.653a.654.654,0,1,1-.924-.924L9,9.922,6.192,7.116a.654.654,0,1,1,.924-.924L9.922,9l2.806-2.806a.654.654,0,1,1,.924.924L10.847,9.922Z"
        fill="currentColor"
      />
    </g>
  ),
};
