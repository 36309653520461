import React from 'react';
import { className } from '../../../../../lib/className';
import { useI18n } from '../../../../../../i18n';

/**
 * @param {Object} props
 * @param {Array.<ReactElement>} [props.prependInterActions] - array of react elements
 * @param {Array.<Object>} [props.interactions]
 * @param {Array.<ReactElement>} [props.appendInterActions] - array of react elements
 * @param {function} [props.onSelect]
 * @param {string} [props.selectedName]
 */


function InterActions(props) {
	const { fc } = useI18n();

	return (
		<div className="chart-interactions">
			{props.prependInterActions}
			{props.interactions && props.interactions.map(interaction => {
				const Icon = interaction.icon;
				return (
					<div
						className={className('clickable', { 'selected': interaction.name == props.selectedName })}
						onClick={() => props.onSelect(interaction.name)}
						title={fc(interaction.title)}
						key={interaction.name}
					>
						<Icon />
					</div>
				);
			})}
			{props.appendInterActions}
		</div>
	);
}

export default InterActions;