import React, { Dispatch, FC, useRef } from 'react';
import { CheckPicker } from 'rsuite';

import { useI18n } from 'i18n';
import Loader from 'core/react/general/Loader';
import { generateId } from 'core/misc/misc';
import { className } from 'core/lib/className';

import './GeoJsonSwitcher.scss';

interface IGeoJsonLayerSwitcherProps {
  defaultValue?: string[];
  value: string[];
  options: Record<string, string>[] | null;
  disabled?: boolean;
  onChange: Dispatch<string[]>;
}

export const GeoJsonLayerSwitcher: FC<IGeoJsonLayerSwitcherProps> = (props) => {
  const { disabled, defaultValue, value, options, onChange } = props;

  const uid = useRef(generateId());

  const { fc } = useI18n();

  //TODO: temporary solution. should be improved when rsuite is upgraded or removed
  const getFieldContainer = () => {
    const container = document.getElementById(uid.current);

    return container ? { container: () => container } : {};
  };

  return (
    <div className="map-option geo-json-switcher" id={uid.current}>
      {options ? (
        <div className={className('x-picker')}>
          <label>{fc('layer')}</label>
          <CheckPicker
            {...getFieldContainer()}
            data={options}
            value={value}
            onChange={onChange}
            placeholder={fc('select layers')}
            disabled={disabled}
            defaultValue={defaultValue}
          />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};
