import { ofType } from "redux-observable";
import { map as rxmap } from 'rxjs/operators';
import { actions } from "../actions";
import { isBundle } from "../../../../react/share/properties/meta/filter";

const processing = (parameters, properties) => {
	const structure = {
		uris: parameters.uris,
		properties: {}
	};

	const collectProperty = (propertyId) => structure.properties[propertyId] = properties.map[propertyId];

	const handleBundleProperty = (propertyId) => {
		const bundleProperty = properties.map[propertyId];
		bundleProperty.elements.forEach(element => {
			const property = properties.map[element.elementId];
			if (isBundle(property)) handleBundleProperty(property.propertyId);
			else collectProperty(property.propertyId);
		});
	}

	if (parameters.propertyIds && parameters.propertyIds.length > 0) {
		parameters.propertyIds.forEach(id => handleBundleProperty(id));
	} else {
		properties.list.forEach(property => {
			if (!isBundle(property)) collectProperty(property.propertyId);
		});
	}
	structure.properties = Object.values(structure.properties);
	return structure;
}

const epic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.deviceRegistry.request.type),
		rxmap(action => actions.deviceRegistry.success({
			map: processing(action.parameters, state$.value.meta.properties)
		}))
	)
}

export { epic };
