import { actions } from './actions';
import { reduxSwitch } from '../../tools';

const defaultState = {
	map: null, // {uri => [EventDetails]}
	recentMap: null, // {uri => [EventDetails]}
	indexMap: null, // { eventId => uri }
	pending: false,
	error: null
};

function findReducer(state, action) {
	switch (action.type) {
		case actions.find.request.type:
			state = {
				...state,
				pending: true,
				error: null
			}
			break;
		case actions.find.success.type:
			state = {
				...state,
				...action.maps,
				pending: false,
			};
			break;
		case actions.find.fail.type:
			state = {
				...state,
				pending: false,
				error: action.errorMessage
			};
			break;
		case actions.find.cancel.type:
			state = {
				...state,
				pending: false
			};
			break;
	}
	return state || defaultState;
}

function findForUriReducer(state, action) {
	switch (action.type) {
		case actions.findForUri.request.type:
			state = {
				...state,
				pending: true,
				error: null
			}
			break;
		case actions.findForUri.success.type:
			state = {
				...state,
				...action.maps,
				pending: false,
			};
			break;
		case actions.findForUri.fail.type:
			state = {
				...state,
				pending: false,
				error: action.errorMessage
			};
			break;
		case actions.findForUri.cancel.type:
			state = {
				...state,
				pending: false
			};
			break;
	}
	return state || defaultState;
}

export const reducer = reduxSwitch([findReducer, findForUriReducer], defaultState);
