import React, { useRef, useEffect } from 'react';
import moment from "moment/moment";
import { TimeMachineRangeScale as TRScale } from './plain';
import StatusLine from './StatusLine';
import { datetime } from '../../../misc/datetime';
import './timeRangeScale.scss';

const MAX_INTERVAL = datetime.WEEK * 2;
const MIN_INTERVAL = datetime.HOUR;
const MAX_PAST_DAYS = 90;

/**
 * @param {Object} props
 * @param {Date} props.since
 * @param {Date} props.until
 * @param {Date} props.now
 * @param {Array.<Object>} props.ranges
 * @param {Object} props.uriEventMap
 * @param {React.Ref} [props.customRef]
 */
function TimeRangeScale(props) {
	const scale = useRef(null);
	const container = useRef(null);

	const maxDate = moment();
	const minDate = maxDate.clone().subtract(MAX_PAST_DAYS, 'd');

	useEffect(() => {
		scale.current = new TRScale(container.current, {
			start: props.since,
			current: props.now,
			end: props.until,
			options: {
				maxInterval: MAX_INTERVAL,
				minInterval: MIN_INTERVAL,
				maxDate: maxDate.toDate().getTime(),
				minDate: minDate.toDate().getTime(),

			}
		});
		if (props.customRef) props.customRef.current = scale.current;
	}, [])

	useEffect(() => {
		scale.current.setHandlers({
			onChange: (start, end, now) => {
				props.onChange && props.onChange(new Date(start), new Date(end), new Date(now));
			}
		});
	}, [props.onChange]);

	useEffect(() => {
		scale.current.setRange(props.since, props.until);
	}, [props.since, props.until]);

	useEffect(() => {
		scale.current.setCurrent(props.now);
	}, [props.now]);

	useEffect(() => {
		scale.current.setLoadedRanges(props.ranges);
	}, [props.ranges]);

	useEffect(() => {
		scale.current.setLoadedEvents(props.uriEventMap);
	}, [props.uriEventMap]);

	return (<div className="time-range-scale">
		<StatusLine
			{...props}
			maxInterval={MAX_INTERVAL}
			minInterval={MIN_INTERVAL}
			maxDate={maxDate}
			minDate={minDate}
		/>
		<div className="container" ref={container}></div>
	</div>);
}

export default TimeRangeScale;
