import React, { FC, MutableRefObject } from 'react';
import { FormProvider } from 'react-hook-form';
import { FiExternalLink as IconZoneGuards } from 'react-icons/fi';

import {
  ColumnedPageStyled,
  MainColumnStyled,
  SideColumnsStyled,
  SideColumnStyled,
} from 'ui/layout/columned-page.styled';
import { DetectorFormStyled } from 'ui/pages/attribute-range-detectors/detector-form.styled';
import { IAttributeRangeDetector } from 'types/detectors';
import { useAttributeDetectorForm } from 'hooks/attribute-range-detectors/useAttributeDetectorForm';
import { EntitiesActions } from 'components/molecules/entities-actions';
import { GeneralFields } from './general-fields';
import { DevicesSelect } from './devices-select';
import { AttributeFields } from './attribute-fields';

interface IAttributeDetectorFormProps {
  detector: IAttributeRangeDetector | null;
  pending: MutableRefObject<boolean>;
}

export const AttributeDetectorForm: FC<IAttributeDetectorFormProps> = (props) => {
  const { actionsProps, attributeFieldsProps, formMethods, error, isDisabled, onSubmit } = useAttributeDetectorForm(
    props,
  );

  return (
    <FormProvider {...formMethods}>
      <DetectorFormStyled onSubmit={onSubmit}>
        <ColumnedPageStyled>
          <MainColumnStyled>
            <EntitiesActions icon={<IconZoneGuards size={20} />} {...actionsProps} />
            <GeneralFields isDisabled={isDisabled} error={error} />
          </MainColumnStyled>
          <SideColumnsStyled>
            <SideColumnStyled>
              <DevicesSelect isDisabled={isDisabled} />
            </SideColumnStyled>
            <SideColumnStyled>
              <AttributeFields {...attributeFieldsProps} />
            </SideColumnStyled>
          </SideColumnsStyled>
        </ColumnedPageStyled>
      </DetectorFormStyled>
    </FormProvider>
  );
};
