import React, { Dispatch, MouseEvent, useMemo, useRef, useState } from 'react';

import {
  SelectContentStyled,
  SelectHeaderStyled,
  SelectIconStyled,
  SelectNoOptionsStyled,
  SelectOptionsStyled,
  SelectOptionStyled,
  SelectStyled,
} from 'ui/molecules/select.styled';
import { ISelectOption } from 'types/select';
import { fc } from 'i18n';
import { arrowDown } from 'assets/icons';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

interface ISelectProps<T> {
  selectedValue?: ISelectOption<T> | null;
  options: ISelectOption<T>[] | null;
  defaultText?: string;
  rounded?: boolean;
  withPlaceholder?: boolean;
  isDisabled?: boolean;
  onOptionChange?: Dispatch<ISelectOption<T>>;
}

export const Select = <T,>(props: ISelectProps<T>) => {
  const { selectedValue, options, defaultText, rounded, onOptionChange, isDisabled, withPlaceholder } = props;

  const wrapperRef = useRef(null);
  const headerText = useMemo(() => selectedValue?.label || defaultText || 'selectOption', [selectedValue]);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useOnClickOutside([wrapperRef], () => setIsOpen(false));

  const toggleDropdown = () => setIsOpen(!isOpen);

  const selectOption = (option: ISelectOption<T>) => (event: MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    !!onOptionChange && onOptionChange(option);
    setIsOpen(false);
  };

  return (
    <SelectStyled>
      <SelectContentStyled ref={wrapperRef} isDisabled={isDisabled}>
        <SelectHeaderStyled
          isPlaceholder={withPlaceholder && !selectedValue}
          rounded={rounded}
          onClick={toggleDropdown}
        >
          <span>{fc(headerText, { count: selectedValue?.value })}</span>
          <SelectIconStyled width={9} height={5} details={arrowDown} />
        </SelectHeaderStyled>
        {isOpen && (
          <>
            {options?.length ? (
              <SelectOptionsStyled rounded={rounded}>
                {options?.map((option, index) => (
                  <SelectOptionStyled key={index} onClick={selectOption(option)}>
                    {fc(option?.label, { count: option.value })}
                  </SelectOptionStyled>
                ))}
              </SelectOptionsStyled>
            ) : (
              <SelectNoOptionsStyled>{fc('noOptionsFound')}</SelectNoOptionsStyled>
            )}
          </>
        )}
      </SelectContentStyled>
    </SelectStyled>
  );
};
