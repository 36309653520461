import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { SelectOptionsStyled } from 'ui/pages/issues/issues-actions-select.styled';
import { resolutionTypesSelector } from 'core/redux/selectors/issues';
import { SelectOption } from './select-option';

interface IIssueActionsOptionsProps {
  isLoading: boolean;
  issueTypeId: number;
}

export const IssueActionsOptions: FC<IIssueActionsOptionsProps> = ({ isLoading, issueTypeId }) => {
  const { issueTypeIndex } = useSelector(resolutionTypesSelector);

  return (
    <SelectOptionsStyled isDisabled={isLoading}>
      {!!issueTypeIndex &&
        issueTypeIndex[issueTypeId].map(({ id, name }) => (
          <SelectOption key={id} isLoading={isLoading} value={id} label={name} />
        ))}
    </SelectOptionsStyled>
  );
};
