import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { Menu, Divider } from '@blueprintjs/core';
import { useI18n } from '../../../../i18n';
import { actions as sessionActions } from '../../../redux/api/session';
import AccountInfo from '../profile/AccountInfo';
import { FiUser, FiLogOut } from 'react-icons/fi';
import { MdSettings } from 'react-icons/md';
import { FaUserSecret } from 'react-icons/fa';
import { Aspect } from '../profile/AccountPopup';
import './profileControl.scss';

/**
 * @param {Object} props
 * @param {function} props.onClick
 */

function ProfileControl(props) {
	const { fc } = useI18n();
	const account = props.account.details;
	let content = null;

	function handleLogout() {
		props.dispatch(sessionActions.logout.request());
	}

	if (props.roleMap && account) {
		content = (
			<div className="profile-control">
				<AccountInfo account={account}/>
				<Divider></Divider>
				<Menu>
					{props.roleMap[account.role].mnemonics == 'admin'
						&& <Menu.Item icon={<FaUserSecret/>} text={fc('inspect another account')} onClick={() => props.onClick(Aspect.inspect)} />
					}
					<Menu.Item icon={<FiUser/>} text={fc('edit profile')} onClick={() => props.onClick(Aspect.profile)} />
					<Menu.Item icon={<MdSettings/>} text={fc('sign-in settings')} onClick={() => props.onClick(Aspect.signInSettings)} />
					<Menu.Item icon={<FiLogOut/>} text={fc('logout')} onClick={handleLogout} />
				</Menu>
			</div>
		);
	}

	return (content);
}

export default connect((state) => {
	return {
		account: state.account,
		roleMap: state.registry.roles.map
	}
})(withRouter(ProfileControl));
