import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { actions as accountActions } from '../../../redux/api/account';
import { actions as authorizationActions } from '../../../redux/api/authorization';
import Form from '../../general/form/Form';
import Button from '../../general/form/Button';
import ButtonGroup from '../../general/form/ButtonGroup';
import AuthorizationForm from './AuthorizationForm';
import FloatingLabelInput from '../../general/form/FloatingLabelInput';
import './accountPasswordForm.scss';

/**
 * @param {Object} props
 * @param {boolean} [props.create] reserved for future use
 * @param {function} props.onReady
 * @param {function} props.onCancel
 * @param {function} [props.setModalFooter] possibility to set footer content when used inside modal window
 */

function AccountPasswordForm(props) {
	const { f } = useI18n();
	const password = useRef('');
	const submitHook = useRef(null);
	const pending = useRef(false);
	const [inited, setInited] = useState(false);
	const auth = props.authorization;
	const token = auth.verificationToken;
	const verification = auth.authType && Boolean(auth.challenge);

	const dispatchUpdate = (password, token) => {
		pending.current = true;
		props.dispatch(accountActions.password.request({ password, token }));
	}

	const onSubmit = (values) => {
		if (token && (Date.now() + 60000) < token.expiresAt.getTime()) {
			dispatchUpdate(values.password, token.value);
		} else {
			password.current = values.password;
			props.dispatch(authorizationActions.verify.request());
		}
	}

	const submit = () => {
		submitHook.current();
	}

	const footer = (
		<ButtonGroup className="change-password-buttons">
			<Button
				onClick={props.onCancel}
				disabled={props.account.pending}
			>
				{f('cancel')}
			</Button>
			<Button
				onClick={submit}
				loading={props.account.pending}
				disabled={props.account.pending || Boolean(auth.authType)}
			>
				{f('save')}
			</Button>
		</ButtonGroup>
	);

	useEffect(() => {
		if (inited) {
			if (props.setModalFooter) props.setModalFooter(verification ? null : footer);
			if (!verification && token) {
				dispatchUpdate(password.current, token.value);
			}
		} else setInited(true);
		return () => {
			props.dispatch(accountActions.clear());
		}
	}, [verification]);

	useEffect(() => {
		props.setModalFooter && props.setModalFooter(verification ? null : footer);
		if (!props.account.pending && pending.current) {
			pending.current = false;
			if (props.account.error == null) {
				props.onReady();
			}
		}
	}, [props.account.pending]);

	return (
		<div className="password-form-wrap">
			{verification
				? <AuthorizationForm simple />
				: <Form
					onSubmit={onSubmit}
					submitHook={submitHook}
					className="change-password"
					disabled={props.account.pending || Boolean(auth.authType)}
					error={props.account.error}
					footer={!props.setModalFooter && footer}
				>
					<Form.ControlGroup>
						<Form.Control
							controlType={FloatingLabelInput}
							controlName="password"
							initialValue={password.current}
							controlValidator={(value) => {
								if (!value) return f('please enter a value');
							}}
							label={f('new password')}
							type="password"
							autoFocus
						/>
						<Form.Control
							controlType={FloatingLabelInput}
							controlName="confirm"
							initialValue={password.current}
							controlValidator={(value, values) => {
								if (!value) return f('please enter a value');
								else if (value !== values['password']) return f('passwords do not match');
							}}
							label={f('confirm new password')}
							type="password"
						/>
					</Form.ControlGroup>
				</Form>
			}
		</div>
	);
}

export default connect(state => ({
	account: state.account,
	authorization: state.authorization
}))(AccountPasswordForm);
