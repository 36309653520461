import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import Toggle from '../../../general/form/Toggle';
import { actions } from '../../../../redux/app/deviceTrace';
import { ReduxKeyContext } from '../../../../misc/ReduxKeyContext';
import { className, fromProps } from '../../../../lib/className';

/**
 * Map options control switching widgets visibility.
 * @param {Object} props
 * @param {string} [props.label]
 */

function MapOptionDisplayTrace(props) {
	const { f } = useI18n();
	const reduxKey = useContext(ReduxKeyContext);
	const display = useSelector(state => state.deviceTrace[reduxKey] && state.deviceTrace[reduxKey].display);
	const dispatch = useDispatch();

	const onChangeDisplay = (value) => {
		dispatch(actions.setDisplayTrace({ domain: reduxKey, display: value }));
	}

	return (
		<div className={className('map-option', 'map-device-trace', fromProps(props))}>
			<Toggle
				label={props.label || f('show devices trace')}
				value={display}
				onChange={onChangeDisplay}
			/>
		</div>
	);
}

export default MapOptionDisplayTrace;
