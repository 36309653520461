import React, { useContext } from 'react';
import { useSelector } from 'react-redux'
import MessageFieldsView from '../../../share/message/MessageFieldsView';
import { useI18n } from '../../../../../i18n';
import { ReduxKeyContext } from '../../../../misc/ReduxKeyContext';
import { formatter } from "../../../../misc/datetime";

/**
 * @param {Object} props
 * @param {string} props.uri
 * @param {boolean} [props.hasTitle]
 */

function DeviceLatestMessageFieldsView(props) {
	const { f } = useI18n();
	const reduxKey = useContext(ReduxKeyContext);
	const message = useSelector(state => {
		if (reduxKey == 'timeMachine') {
			return state.timeMachine.state.map && state.timeMachine.state.map[props.uri]
				? state.timeMachine.state.map[props.uri].message
				: null
			;
		} else {
			return state.deviceStates.map && state.deviceStates.map[props.uri]
				? state.deviceStates.map[props.uri].message
				: null
			;
		}
	});

	return (
		<div className="device-latest-message-fields-view">
			{props.hasTitle && <div className="header">
				<label>
					<span className="capitalize">{f('latest status')}</span>
				</label>
				{message && <span className="date">
					{f({ prefix: 'datetime', id: 'as of' }) + ' ' + formatter.relative(message.generatedAt, null, true).value}
				</span>}
			</div>}
			<MessageFieldsView full message={message} uri={props.uri} />
		</div>
	);
}

export default DeviceLatestMessageFieldsView;