import {combineEpics, ofType} from "redux-observable";
import {map, switchMap, withLatestFrom} from 'rxjs/operators';

import {fc, f} from '../../../../i18n';

import {formatDateTime} from '../../../misc/misc';

import {cx, api, rx} from "../../../api";
import {denominate as denominateDevices} from '../../../api/device'

import {deltaReducer, errorMap} from "../../actions";


const {addActions, getActions} = (() => {
	let builder, actions;
	const addActions = report => {
		builder = report
			.subtype('generate', generate => generate.request({parameters: true}).success({utilizations: true}).fail())
			.subtype('export', subtype => subtype.request().progress().success({csv: true}).clear())
		;
	};
	const getActions = () => {
		if (!actions) {
			if (!builder) throw new Error('Driving behaviour report actions weren\'t registered');
			actions = builder.build();
			builder = null;
		}
		return actions;
	};
	return {addActions, getActions};
})();

const withActions = target => (...args) => target(getActions(), ...args);


const defaultState = {
	parameters: null
	, report: null
	, pending: false
	, error: null
};

const reducer = deltaReducer(withActions((actions, state, action) => {
	switch (action.type) {
		case actions.generate.request.type: return {
			pending: true, error: undefined
			, parameters: action.parameters
			, report: undefined
		};
		case actions.generate.success.type: return {
			pending: false
			, report: indexUtilizations(action.utilizations) || []
		};
		case actions.generate.fail.type: return {
			pending: false, error: action.errorMessage
		};
		case actions.export.request.type: return {
			exporting: true
		};
		case actions.export.success.type: return {
			exporting: undefined
			, csv: action.csv
		};
		case actions.export.clear.type: return {
			csv: undefined
		}
	}
}), defaultState);

const indexUtilizations = utilizations => {
	if (utilizations == null || utilizations.length == 0) return [];

	const totalSplit = [cx.ods.reports.UtilizationPhase.parked, cx.ods.reports.UtilizationPhase.idling, cx.ods.reports.UtilizationPhase.working];
	let grandTotals = statisticsZero(), phaseTotals = {};
	const indexed = utilizations.map(utilization => {
		const totals = statisticsZero();
		let phases = {};
		if (utilization.phases) phases = Object.fromEntries(utilization.phases.map(indexViolations).map(({phase, ...statistics}) => {
			if (totalSplit.includes(phase)) accumulateStatistics(totals, statistics);
			if (!phaseTotals[phase]) phaseTotals[phase] = statisticsZero();
			accumulateStatistics(phaseTotals[phase], statistics);
			return [phase, roundStatistics(statistics)];
		}));
		accumulateStatistics(grandTotals, totals);
		return {
			uri: utilization.uri
			, phases, totals: roundStatistics(totals)
		};
	});
	grandTotals = roundStatistics(grandTotals);
	phaseTotals = Object.fromEntries(Object.entries(phaseTotals).map(([phase, totals]) => [phase, roundStatistics(totals)]));
	indexed.forEach(utilization => {
		const shares = {}, totals = utilization.totals;
		if (0 < grandTotals.violations) shares.violations = percentShare(totals.violations / grandTotals.violations);
		if (0 < grandTotals.distance) shares.distance = percentShare(totals.distance / grandTotals.distance);
		if (0 < phaseTotals.working?.duration) {
			if (!utilization.phases.working?.duration) shares.working = 0;
			else shares.working = percentShare(utilization.phases.working.duration / phaseTotals.working.duration);
		}
		if (0 < phaseTotals.idling?.duration) {
			if (!utilization.phases.idling?.duration) shares.idling = 0;
			else shares.idling = percentShare(utilization.phases.idling.duration / phaseTotals.idling.duration);
		}
		utilization.shares = shares;
	});

	return indexed;
};

const indexViolations = (statistics) => {
	if (statistics.violationTypes) statistics.violationTypes = Object.fromEntries(
		statistics.violationTypes.map(({eventType, quantity}) => [eventType, quantity])
	);
	return statistics;
};

const statisticsZero = () => ({
	distance: 0, duration: 0, quantity: 0, violations: 0, violationTypes: {}
});

const accumulateStatistics = (statistics, addition) => {
	if (!addition) return statistics;
	for (const counter of ['distance', 'duration', 'quantity', 'violations']) {
		const value = addition[counter];
		if (value) statistics[counter] += value;
	}
	if (addition.violationTypes) {
		if (!statistics.violationTypes) statistics.violationTypes = Object.assign({}, addition.violationTypes);
		else Object.entries(addition.violationTypes).forEach(([eventType, quantity]) => {
			statistics.violationTypes[eventType] = (statistics.violationTypes[eventType] || 0) + quantity; 
		});
	}
	return statistics;
};

const roundStatistics = statistics => ({
	...statistics
	, duration: Math.round(statistics.duration * 100 / (1000 * 60 * 60)) / 100
	, distance: Math.round(statistics.distance * 10 / 1000) / 10
});

const percentShare = share => Math.round(share * 100 * 100) / 100;


const epic = combineEpics(
	withActions((actions, action$) => action$.pipe(
		ofType(actions.generate.request.type)
		, switchMap(action => rx(api.reports.drivingBehaviour, action.parameters).pipe(
			map(operation => actions.generate.success({utilizations: operation.response()}))
			, errorMap(actions.generate.fail)
		))
	))
	, withActions((actions, action$, state$) => action$.pipe(
		ofType(actions.export.request.type)
		, withLatestFrom(state$.pipe(map(state => ({
			reporting: state.reports.drivingBehaviour
			, deviceMap: state.devices.map
		}))))
		, map(([action, state]) => actions.export.success({csv: formatCSV(state)}))
	))
);


const formatCSV = state => {
	const {parameters, report} = state.reporting;
	const lines = [];

	lines.push(['report type', 'generated', 'from', 'to', 'devices'].map(fc));
	lines.push([
		fc('driving behaviour report')
		, new Date()
		, parameters.timeRange.since, parameters.timeRange.until
		, parameters.uris ? denominateDevices(parameters.uris.map(uri => state.deviceMap[uri])) : fc('all devices')
	]);

	const headers = [fc('device')];
	headers.push(
		`${fc('device-event.speeding')}`, `${f('device-event.harsh acceleration')}`
		, `${fc('device-event.harsh braking')}`, `${f('device-event.harsh cornering')}`
		, `${fc('violations')}`
	);
	headers.push(`${fc("odometer")}, ${f('first')}`, f('last'));
	headers.push(`${fc('distance')}, ${f('units.km')}`);
	headers.push(`${fc('utilization-phase.working')}, ${f('units.h')}`);
	headers.push(`${fc('utilization-phase.parked')}, ${f('units.h')}`);
	headers.push(`${fc('utilization-phase.idling')}, ${f('units.h')}`, f('units.%'), f('quantity'));
	headers.push(`${fc('violations')}, ${f('units.%')}`);
	headers.push(`${fc('distance')}, ${f('units.%')}`);
	headers.push(`${fc('utilization-phase.working')}, ${f('units.%')}`);
	headers.push(`${fc('utilization-phase.idling')}, ${f('units.%')}`);
	lines.push(headers);

	const utilizations = report;

	utilizations.forEach(utilization => {
		const cells = [state.deviceMap[utilization.uri].denomination()];
		const {phases, totals, shares} = utilization;
	

		cells.push(totals.violationTypes?.speeding);
		cells.push(totals.violationTypes?.acceleration);
		cells.push(totals.violationTypes?.braking);
		cells.push(totals.violationTypes?.cornering);
		cells.push(totals.violations || null);

		const firstOdometer = Math.round(phases.total?.firstOdometer / 1000) || 0;
		const lastOdometer = Math.round(phases.total?.lastOdometer / 1000) || 0;
		cells.push(firstOdometer, lastOdometer);

		cells.push(totals.distance || null);

		cells.push(phases.working?.duration);
		cells.push(phases.parked?.duration);
		cells.push(phases.idling?.duration);
		const idling = phases.idling?.duration || 0;
		const idlingShare = 0 < idling ? percentShare(idling / totals.duration) : '';
		cells.push(idlingShare);
		cells.push(phases.idling?.quantity);

		cells.push(shares.violations);
		cells.push(shares.distance);
		cells.push(shares.working);
		cells.push(shares.idling);

		lines.push(cells);
	});


	return lines.map(cells => cells.map(value => {
		if (value == null) return '';
		if (value instanceof Date) return formatDateTime(value);
		let string = String(value);
		const qutes = 0 <= string.indexOf('"');
		if (qutes) string = string.replaceAll('"', '""');
		if (qutes || 0 <= string.indexOf(',')) string = '"' + string + '"';
		return string; 
	}).join(',')).join('\n');
};

export {addActions, reducer, epic, percentShare};
