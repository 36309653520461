import {combineEpics, ofType} from "redux-observable";
import {map, switchMap, withLatestFrom} from 'rxjs/operators';

import {fc, f} from '../../../../i18n';

import {formatDateTime} from '../../../misc/misc';

import {cx, api, rx} from "../../../api";
import {denominate as denominateDevices} from '../../../api/device'

import {deltaReducer, errorMap} from "../../actions";

const {addActions, getActions} = (() => {
	let builder, actions;
	const addActions = report => {
		builder = report
			.subtype('generate', generate => generate.request({parameters: true}).success({utilizations: true}).fail())
			.subtype('export', subtype => subtype.request().progress().success({csv: true}).clear())
		;
	};
	const getActions = () => {
		if (!actions) {
			if (!builder) throw new Error('Device utilization report actions weren\'t registered');
			actions = builder.build();
			builder = null;
		}
		return actions;
	};
	return {addActions, getActions};
})();

const withActions = target => (...args) => target(getActions(), ...args);


const defaultState = {
	parameters: null
	, report: null
	, pending: false
	, error: null
};

const reducer = deltaReducer(withActions((actions, state, action) => {
	switch (action.type) {
		case actions.generate.request.type: return {
			pending: true, error: undefined
			, parameters: action.parameters
			, report: undefined
		};
		case actions.generate.success.type: return {
			pending: false
			, report: indexUtilizations(action.utilizations) || []
		};
		case actions.generate.fail.type: return {
			pending: false, error: action.errorMessage
		};
		case actions.export.request.type: return {
			exporting: true
		};
		case actions.export.success.type: return {
			exporting: undefined
			, csv: action.csv
		};
		case actions.export.clear.type: return {
			csv: undefined
		}
	}
}), defaultState);

const indexUtilizations = utilizations => {
	if (utilizations == null || utilizations.length == 0) return [];

	const totalSplit = [cx.ods.reports.UtilizationPhase.inactive, cx.ods.reports.UtilizationPhase.active];
	let grandTotals = statisticsZero(), phaseTotals = {};
	const indexed = utilizations.map(utilization => {
		const totals = statisticsZero();
		let phases = {};
		if (utilization.phases) phases = Object.fromEntries(utilization.phases.map(({phase, ...statistics}) => {
			if (totalSplit.includes(phase)) accumulateStatistics(totals, statistics);
			if (!phaseTotals[phase]) phaseTotals[phase] = statisticsZero();
			accumulateStatistics(phaseTotals[phase], statistics);
			return [phase, roundStatistics(statistics)];
		}));
		accumulateStatistics(grandTotals, totals);
		return {
			uri: utilization.uri
			, phases, totals: roundStatistics(totals)
		};
	});
	grandTotals = roundStatistics(grandTotals);
	phaseTotals = Object.fromEntries(Object.entries(phaseTotals).map(([phase, totals]) => [phase, roundStatistics(totals)]));

	indexed.forEach(utilization => {
		let score = 0;
		if (grandTotals.distance) score += utilization.totals.distance / grandTotals.distance;
		if (phaseTotals.shift && utilization.phases.shift) score += utilization.phases.shift.quantity / phaseTotals.shift.quantity;
		if (grandTotals.violations) score -= utilization.totals.violations / grandTotals.violations;
		if (phaseTotals.idling && utilization.phases.idling) score -= utilization.phases.idling.quantity / phaseTotals.idling.quantity;
		utilization.score = Math.round(score * 100) / 100;
	});

	return indexed;
};

const statisticsZero = () => ({
	distance: 0, duration: 0, quantity: 0, violations: 0
});

const accumulateStatistics = (statistics, addition) => {
	if (addition) for (const item in statistics) {
		const value = addition[item];
		if (value) statistics[item] += value;
	}
	return statistics;
};

const roundStatistics = statistics => ({
	...statistics
	, duration: Math.round(statistics.duration * 100 / (1000 * 60 * 60)) / 100
	, distance: Math.round(statistics.distance * 10 / 1000) / 10
});


const epic = combineEpics(
	withActions((actions, action$) => action$.pipe(
		ofType(actions.generate.request.type)
		, switchMap(action => rx(api.reports.deviceUtilization, action.parameters).pipe(
			map(operation => actions.generate.success({utilizations: operation.response()}))
			, errorMap(actions.generate.fail)
		))
	))
	, withActions((actions, action$, state$) => action$.pipe(
		ofType(actions.export.request.type)
		, withLatestFrom(state$.pipe(map(state => ({
			reporting: state.reports.deviceUtilization
			, deviceMap: state.devices.map
		}))))
		, map(([action, state]) => actions.export.success({csv: formatCSV(state)}))
	))
);


const statisticsGroups = (() => {
	const Phase = cx.ods.reports.UtilizationPhase;
	return [
		{label: 'parked', phase: Phase.parked}
		, {label: 'shift', phase: Phase.shift}
		, {label: 'break', phase: Phase.break}
		, {label: 'idling', phase: Phase.idling}
		, {label: 'active', phase: Phase.active}
	];
})();


const formatCSV = state => {
	const {parameters, report} = state.reporting;
	const lines = [];

	lines.push(['report type', 'generated', 'devices'].map(fc));
	lines.push([
		fc('device utilization report')
		, new Date()
		, parameters.uris ? denominateDevices(parameters.uris.map(uri => state.deviceMap[uri])) : fc('all devices')
	]);

	const headers = [fc('device')];
	statisticsGroups.forEach(group => headers.push(`${fc(group.label)}, ${f('units.h')}`, f('units.%')));
	headers.push(`${fc("odometer")}, ${f('first')}`, f('last'));
	headers.push(`${fc('distance')}, ${f('units.km')}`);
	headers.push(fc('shifts'), fc('shifts per hour'));
	headers.push(fc('violations'), fc('idling'), fc('score'));
	lines.push(headers);

	const utilizations = report.slice().sort((left, right) => {
		const leftValue = left.phases.active?.duration || 0;
		const rightValue = right.phases.active?.duration || 0;
		return -(leftValue - rightValue);
	});

	utilizations.forEach(utilization => {
		const cells = [state.deviceMap[utilization.uri].denomination()];
		const {phases, totals, score} = utilization;
	
		statisticsGroups.forEach(group => {
			const duration = phases[group.phase]?.duration || 0;
			const percent = totals.duration ? Math.round(duration * 100 / totals.duration) / 100 : null;
			cells.push(duration, percent);
		});

		cells.push(totals.distance);

		const firstOdometer = Math.round(phases.total?.firstOdometer / 1000) || 0;
		const lastOdometer = Math.round(phases.total?.lastOdometer / 1000) || 0;
		cells.push(firstOdometer, lastOdometer);

		const activeHours = phases.active?.duration || 0;
		const shifts = phases.shift?.quantity || 0;
		const shiftsFrequency = 0 < activeHours ? Math.round(shifts * 100 / activeHours) / 100 : '';
		cells.push(shifts, shiftsFrequency);
		
		cells.push(totals.violations, phases.idling?.quantity || 0, score);

		lines.push(cells);
	});

	return lines.map(cells => cells.map(value => {
		if (value == null) return '';
		if (value instanceof Date) return formatDateTime(value);
		let string = String(value);
		const qutes = 0 <= string.indexOf('"');
		if (qutes) string = string.replaceAll('"', '""');
		if (qutes || 0 <= string.indexOf(',')) string = '"' + string + '"';
		return string; 
	}).join(',')).join('\n');
};


export {addActions, reducer, epic, statisticsGroups};
