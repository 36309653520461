import {ActionGeneratorBuilder} from '../../actions';

import ReportType from '../../../react/custom/reports/ReportType';

import {addActions as addCommuteSchedulesActions} from './commute/schedules';
import {addActions as addDeviceStatusReportActions} from './deviceStatus';
import {addActions as addDeviceUtilizationReportActions} from './deviceUtilization';
import {addActions as addDrivingBehaviourReportActions} from './drivingBehaviour';

export const actions = new ActionGeneratorBuilder('reports')
	.subtype(
		ReportType.LocationHistory,
		report => report
			.request({ uri: true, parameters: true, clear: false })
			.success({ report: false })
			.fail()
			.cancel()
			.type('export', 'uri')
			.type('exportProgress', { progress: true, count: false, total: false })
			.type('exportDone', 'csv')
			.type('exportClear')
	)
	.subtype(
		ReportType.Trip,
		report => report
			.request({ uri: true, parameters: true, clear: false })
			.success({ report: false })
			.fail()
			.cancel()
			.type('export', 'uri')
			.type('exportProgress', { progress: true, count: false, total: false })
			.type('exportDone', 'csv')
			.type('exportClear')
	)
	.subtype(
		ReportType.TripHistory,
		report => report
			.request({ uri: true, parameters: true, clear: false })
			.success({ report: false })
			.fail()
			.cancel()
			.type('export', 'uri')
			.type('exportProgress', { progress: true, count: false, total: false })
			.type('exportDone', 'csv')
			.type('exportClear')
			.subtype(
				'preview',
				preview => preview
					.request({ uri: true, parameters: true })
					.success('preview')
					.fail()
					.cancel()
					.type('invalidate')
			)
	)
	.subtype(
		ReportType.TemperatureChange,
		report => report
			.request({ uri: true, parameters: true, clear: false })
			.success({ intervals: false })
			.fail()
			.cancel()
			.type('export', 'uri')
			.type('exportProgress', { progress: true, count: false, total: false })
			.type('exportDone', 'csv')
			.type('exportClear')
	)
	.subtype(
		ReportType.TemperatureHistory,
		report => report
			.request({ uri: true, parameters: true, clear: false })
			.success({ temperatures: false })
			.fail()
			.cancel()
			.type('export', 'uri')
			.type('exportProgress', { progress: true, count: false, total: false })
			.type('exportDone', 'csv')
			.type('exportClear')
	)
	.subtype(
		ReportType.DeviceUsages,
		report => report
			.request({ parameters: true, clear: false })
			.success({ usages: false })
			.fail()
			.cancel()
			.type('export')
			.type('exportProgress', { progress: true, count: false, total: false })
			.type('exportDone', 'csv')
			.type('exportClear')
	)
	.subtype(
		ReportType.DeviceSituations,
		report => report
			.request({ parameters: true, clear: false })
			.success({ situations: false })
			.fail()
			.cancel()
			.type('export')
			.type('exportProgress', { progress: true, count: false, total: false })
			.type('exportDone', 'csv')
			.type('exportClear')
	)
	.subtype(ReportType.DeviceStatus, addDeviceStatusReportActions)
	.subtype(ReportType.DeviceUtilization, addDeviceUtilizationReportActions)
	.subtype(ReportType.DrivingBehaviour, addDrivingBehaviourReportActions)
	.subtype(
		ReportType.Speeding,
		report => report
			.request({ uri: true, parameters: true, clear: false })
			.success({ intervals: false })
			.fail()
			.cancel()
			.type('export', 'uri')
			.type('exportProgress', { progress: true, count: false, total: false })
			.type('exportDone', 'csv')
			.type('exportClear')
	)
	.subtype(
		ReportType.Idling,
		report => report
			.request({ uri: true, parameters: true, clear: false })
			.success({ intervals: false })
			.fail()
			.cancel()
			.type('export', 'uri')
			.type('exportProgress', { progress: true, count: false, total: false })
			.type('exportDone', 'csv')
			.type('exportClear')
	)
	.subtype(
		ReportType.DeviceRegistry,
		report => report
			.request({ parameters: true })
			.success('map')
			.type('exportDone', 'csv')
			.type('exportClear')
	)
	.subtype(
		ReportType.ZoneVisits,
		report => report
			.request({ parameters: true, clear: false })
			.success({ zoneVisits: false })
			.fail()
			.cancel()
			.type('export')
			.type('exportProgress', { progress: true, count: false, total: false })
			.type('exportDone', 'csv')
			.type('exportClear')
	)
	.subtype(
		ReportType.FuelLevel,
		report => report
			.request({ uri: true, parameters: true, clear: false })
			.success({ intervals: false })
			.fail()
			.cancel()
			.type('export', 'uri')
			.type('exportProgress', { progress: true, count: false, total: false })
			.type('exportDone', 'csv')
			.type('exportClear')
			.type('loadingAll', 'uri')
			.type('loadingAllCancel')
			.type('loadingAllDone')
	)
	.subtype(
		ReportType.Ridership,
		report => report
			.request({ uri: true, parameters: true, clear: false })
			.success({ report: false })
			.fail()
			.cancel()
			.type('export', 'uri')
			.type('exportProgress', { progress: true, count: false, total: false })
			.type('exportDone', 'csv')
			.type('exportClear')
	)
	.subtype(
		ReportType.Commute,
		report => report
			.request({ uri: true, parameters: true, clear: false })
			.success({ trips: false })
			.fail()
			.cancel()
			.type('export', 'uri')
			.type('exportProgress', { progress: true, count: false, total: false })
			.type('exportDone', 'csv')
			.type('exportClear')
			.subtype('schedules', addCommuteSchedulesActions)
	)
	.build()
;
