import styled from 'styled-components';

import { Colors } from 'ui/constants/colors';

export const DetectorsListStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;

  label {
    padding: 15px;
    margin: 0;
  }

  .list-item.droppable {
    background-color: ${Colors.AquaBelt} !important;
    color: ${Colors.White} !important;
    box-shadow: 0 2px 7px -2px rgba(0, 0, 0, 0.4);
  }

  .list .list-item {
    overflow-wrap: break-word;
    padding: 15px;
  }
`;

export const DetectorsFilterStyled = styled.div`
  padding: 15px;
`;
