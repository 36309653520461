import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

import { Colors } from 'ui/constants/colors';

export const PaginationStyled = styled(ReactPaginate).attrs({
  activeClassName: 'active',
})`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li a {
    box-sizing: border-box;
    font: 12px Muli;
    border: 1px solid ${Colors.SilverSand};
    cursor: pointer;
    color: ${Colors.SilverSand};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 28px;
    outline: none;
    margin-right: 20px;
  }

  li.break a {
    display: flex;
    align-items: flex-end;
    border-color: transparent;
  }

  li.previous a,
  li.next a {
    display: none;
  }

  li.active a {
    background: ${Colors.Lochinvar} 0 0 no-repeat padding-box;
    border-color: ${Colors.Lochinvar};
    color: ${Colors.White};
  }

  li.disabled a {
    background-color: ${Colors.SilverSand};
  }

  li.disable,
  li.disabled a {
    cursor: default;
  }
`;
