import React, { useEffect } from 'react';
import { Menu } from '@blueprintjs/core';
import { FiKey, FiMail } from 'react-icons/fi';
import Button from '../../general/form/Button';
import ButtonGroup from '../../general/form/ButtonGroup';
import { useI18n } from '../../../../i18n';
import { Aspect } from './AccountPopup';
import './signInSettings.scss';

/**
 * @param {Object} props
 * @param {function} props.setAspect
 * @param {function} props.onCancel
 */
function SignInSettings(props) {
	const { fc, f } = useI18n();

	const footer = (
		<ButtonGroup className="change-password-buttons">
			<Button onClick={props.onCancel}>
				{f('close')}
			</Button>
		</ButtonGroup>
	);

	useEffect(() => {
		props.setModalFooter && props.setModalFooter(footer);
	}, []);

	return (
		<div className="account-auth-settings">
			<div className="info-header">
				<span className="capitalize">
					{f('sign-in settings')}
				</span>
			</div>
			<Menu>
				<Menu.Item icon={<FiKey/>} text={fc('change password')} onClick={() => props.setAspect(Aspect.password)} />
				<Menu.Item icon={<FiMail/>} text={fc('sign-in options')} onClick={() => props.setAspect(Aspect.signInOptions)} />
			</Menu>
		</div>
	);
}

export default SignInSettings;
