import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hotIssuesSelector, issuesTypesStateSelector } from 'core/redux/selectors/issues';
import { devicesStateSelector } from 'core/redux/selectors/devices';
import { runwaysStateSelector } from 'core/redux/selectors/runways';
import { actions as issuesActions } from 'core/redux/api/veta/issues';

export const useFormattedIssues = () => {
  const dispatch = useDispatch();

  const issues = useSelector(hotIssuesSelector);
  const { map: issuesTypes } = useSelector(issuesTypesStateSelector);
  const { map: devices } = useSelector(devicesStateSelector);
  const { map: runways } = useSelector(runwaysStateSelector);

  const markIssueRead = (issueId: number) => () => {
    dispatch(issuesActions.operation.receive({ issueId }));
  };

  return useMemo(() => {
    if (!issues?.length || !issuesTypes || !devices || !runways) {
      return [];
    }

    return issues.map((issue) => {
      const { id, issueTypeId, deviceURI, objectId, registeredAt } = issue;
      const { name, severity } = issuesTypes[issueTypeId];
      const text = `${devices?.[deviceURI]?.denomination()}; ${runways?.[objectId]?.name || ''}`;

      return { id, severity, title: name, text, date: registeredAt, onClose: markIssueRead(id) };
    });
  }, [issues, issuesTypes, devices, runways]);
};
