import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { useI18n, fc } from '../../../../i18n';
import PieChart from '../../general/charts/PieChart';
import { capitalize } from '../../../misc/misc';
import defaultStyles from '../../../defaults.scss';
import { ResizeContext } from '../../../misc/ResizeContext';
import NoDataView from '../../general/view/NoDataView';
import './deviceMessageCharts.scss';

const processData = (data, colorPrefix, nameChanged) => {
	const processedData = [];
	if (!data) return processedData;
	Object.keys(data).forEach(key => {
		const itdemData = {
			name: nameChanged && nameChanged[key]
				? capitalize(nameChanged[key])
				: fc('status.' + key)
			,
			value: data[key]
		};
		if (colorPrefix) {
			itdemData.color = defaultStyles[colorPrefix + capitalize(key)];
		}
		processedData.push(itdemData);
	});
	return processedData;
}

const fieldsWriter = (objMap, name) => {
	objMap[name] = objMap[name]
		? ++objMap[name]
		: 1
	;
}

/**
 * @param {Object} props
 * @param {Array.<string>} props.uris
 * @param {string} props.reduxKey
 */

function DeviceMessageCharts(props) {
	const resizeContext = useContext(ResizeContext);
	const { f } = useI18n();

	useEffect(() => {
		resize();
	}, [props.deviceStatesMap, props.uris]);

	const resize = () => {
		resizeContext && resizeContext.resize && resizeContext.resize();
	}

	let content = null;

	const messages = [];
	const status = {};
	const ignition = {};
	const gnssFix = {};

	// Status
	if (props.uris.length > 0) {
		props.uris.forEach(uri => {
			const stateMap = props.deviceStatesMap;
			if (stateMap && stateMap[uri] && stateMap[uri].message) {
				messages.push(stateMap[uri].message);
			}
			const _status = props.statusMap && props.statusMap[uri];
			if (_status) {
				const stringStatus = _status.status.toLowerCase();
				fieldsWriter(status, stringStatus);
			}
		});
	}

	if (messages && messages.length > 0 && props.messageFields.typeMap) {
		const fieldMeta = props.messageFields.nameMap["no-gnss-fix"];
		messages.forEach(message => {
			const fieldTypeMap = message.fields && message.fields.map();
			// GNSS Fix
			let hasValue = false;
			let value = null;
			if (message.fields != null) {
				const string = fieldTypeMap && fieldTypeMap[fieldMeta.fieldId];
				hasValue = string !== undefined;
				if (hasValue) value = string === "true";
			}
			if (hasValue && value === true) {
				fieldsWriter(gnssFix, "inactive");
			} else if (message.latitude != null && message.longitude != null && (!hasValue || hasValue && value === false)) {
				fieldsWriter(gnssFix, "active");
			}
			// Ignition
			if (message.ignition !== undefined) {
				const ignitionString = message.ignition ? "active" : "inactive";
				fieldsWriter(ignition, ignitionString);
			}
		});

		const statusData = processData(status, 'deviceStatusColor');
		const ignitionData = processData(
			ignition,
			'deviceStateColor',
			{ active: f('on'), inactive: f('off') }
		);
		const gnssFixData = processData(
			gnssFix,
			'deviceStateColor',
			{ active: f('yes'), inactive: f('no') }
		);
		content = (
			<>
				{statusData.length > 0 && <PieChart onMounted={resize} data={statusData} label={f('status')} />}
				{ignitionData.length > 0 && <PieChart onMounted={resize} data={ignitionData} label={f('ignition')} />}
				{gnssFixData.length > 0 && <PieChart onMounted={resize} data={gnssFixData} label={f('GNSS fix')} />}
			</>
		);
	} else {
		content = (<NoDataView />);
	}

	return (
		<div className="device-message-charts">
			{content}
		</div>
	);
}

export default connect((state, props) => {
	let deviceStatesMap = null, statusMap = null;
	if (props.reduxKey == 'dashboard') {
		statusMap = state.deviceStatuses.map;
		deviceStatesMap = state.deviceStates.map;
	} else if (props.reduxKey == 'timeMachine') {
		statusMap = state.timeMachine.deviceStatus.map;
		deviceStatesMap = state.timeMachine.state.map
	}

	return {
		messageFields: state.registry.messageFields,
		attributeTypes: state.registry.attributeTypes,
		statusMap,
		deviceStatesMap
	};
})(DeviceMessageCharts);
