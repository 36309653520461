import React from 'react';
import { className } from '../../../../lib/className';
import { fc } from '../../../../../i18n';
import './deviceCommandNotification.scss';

/**
 * @param {Object} props
 * @param {cx.ods.devices.EventDetails} props.eventType
 */

function DeviceCommandNotification(props) {

	return (
		<div className="device-command-notification">
			<span
				className={className('event-icon', props.eventType.mnemonics, { 'incident': props.eventType.incident })}
				title={fc({ prefix: 'device-event', id: props.eventType.name })}
			></span>
			<span>{fc({ prefix: 'device-event', id: props.eventType.name })}</span>
		</div>
	);
}

export default DeviceCommandNotification;