import { ofType, combineEpics } from "redux-observable";
import { mergeMap, map as rxmap, catchError, takeUntil, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { api, cx, rx, ods } from "../../api";
import { reduxSwitch } from "../tools";
import { actions as widgetActions } from '../app/widgets';
import { actions as announcerActions } from './announcer';
import buildParameters from "../../react/custom/dashboard/widgets/aux/buildParameters";
import { ActionGeneratorBuilder } from "../actions";

// { uid => { parameters, digest, pending, error } }
const defaultState = {
	trips: null,
	events: null
};

const actions = new ActionGeneratorBuilder('stomach')
	.subtype('trips', trips => trips.request({ uid: true, parameters: true }).success({ uid: true, tripDigest: true }).fail({ uid: true, errorMessage: true }).cancel())
	.subtype('events', events => events.request({ uid: true, parameters: true }).success({ uid: true, eventDigest: true }).fail({ uid: true, errorMessage: true }).cancel())
	.type('remove', 'uid')
	.build()
;

const processDigest = (_digest, stateTripData, nameDigest) => {
	const newStateTripData = { ...stateTripData };
	newStateTripData.pending = false;
	newStateTripData.error = null;
	const parameters = newStateTripData.parameters;
	const digest = { ..._digest };

	const timeHistogram = digest.timeHistogram;
	const deviceTop = digest.deviceTop;

	// handling the date time bins
	const binSize = parameters.histogram.binSize * 1000;
	let since = parameters.filter.since.getTime();
	const until = parameters.filter.until
		? parameters.filter.until.getTime()
		: cx.now().getTime()
	;
	const binsMap = {};
	const hashBinMap = cx.i.hash(timeHistogram.bins, (bin) => bin.since.getTime());
	while (since < until) {
		binsMap[since] = hashBinMap && hashBinMap[since];
		since = since + binSize;
	}
	digest.timeHistogram = timeHistogram;
	digest.timeHistogram.binsMap = binsMap;
	// ------------------------------------

	if (parameters.top) {
		newStateTripData.parameters.top.buffer = deviceTop.buffer;
	}
	digest.deviceTop = deviceTop;
	newStateTripData[nameDigest] = digest;

	return newStateTripData;
}

function tripReducer(state, action) {
	switch (action.type) {
		case actions.trips.request.type:
			const trips = { ...state.trips };
			trips[action.uid] = {
				...trips[action.uid],
				parameters: action.parameters,
				pending: true,
				error: null
			};
			return {
				...state,
				trips
			}
		case actions.trips.success.type:
			const tripsCopy = { ...state.trips };
			tripsCopy[action.uid] =  processDigest(action.tripDigest, state.trips[action.uid], 'tripDigest');
			return {
				...state,
				trips: tripsCopy
			}
		case actions.trips.fail.type:
			const copyState = { ...state.trips };
			copyState[action.uid] = {
				...copyState[action.uid],
				pending: false,
				error: action.errorMessage
			}
			return copyState;
		default:
			return state;
	}
}

function eventReducer(state, action) {
	switch (action.type) {
		case actions.events.request.type:
			const events = { ...state.events };
			events[action.uid] = {
				...events[action.uid],
				parameters: action.parameters,
				pending: true,
				error: null
			};
			return {
				...state,
				events
			}
		case actions.events.success.type:
			const eventsCopy = { ...state.events };
			eventsCopy[action.uid] = processDigest(action.eventDigest, state.events[action.uid], 'eventDigest');
			return {
				...state,
				events: eventsCopy
			}
		case actions.events.fail.type:
			const copyState = { ...state.events };
			copyState[action.uid] = {
				...copyState[action.uid],
				pending: false,
				error: action.errorMessage
			}
			return copyState;
		default:
			return state;
	}
}

const removeReducer = (state, action) => {
	switch (action.type) {
		case actions.remove.type:
			const newState = { ...state };
			Object.keys(newState).forEach(item => {
				if (newState[item]) {
					delete newState[item][action.uid]
				}
			});
			return newState;
		default:
			return state;
	}
}

const reducer = reduxSwitch([tripReducer, eventReducer, removeReducer], defaultState);

const tripEpic = (action$) => {
	return action$.pipe(
		ofType(actions.trips.request.type),
		mergeMap(action =>
			rx(api.stomach.trip, action.parameters).pipe(
				rxmap(operation => actions.trips.success({ uid: action.uid, tripDigest: operation.response() })),
				catchError(error => of(actions.trips.fail({ uid: action.uid, errorMessage: error.userMessage || error.message }))),
				takeUntil(action$.pipe(ofType(actions.trips.cancel.type)))
			)
		)
	);
}

const eventEpic = (action$) => {
	return action$.pipe(
		ofType(actions.events.request.type),
		mergeMap(action =>
			rx(api.stomach.event, action.parameters).pipe(
				rxmap(operation => actions.events.success({ uid: action.uid, eventDigest: operation.response() })),
				catchError(error => of(actions.events.fail({ uid: action.uid, errorMessage: error.userMessage || error.message }))),
				takeUntil(action$.pipe(ofType(actions.events.cancel.type)))
			)
		)
	);
}

const removeEpic = (action$) => {
	return action$.pipe(
		ofType(widgetActions.remove.type),
		rxmap(action => actions.remove({ uid: action.uid }))
	)
}

const getWidget = (uid, widgetState, pages) => {
	let widget = null, reduxKey = null;
	if (widgetState) {
		Object.keys(widgetState).some(_reduxKey => {
			if (pages[_reduxKey].active &&  widgetState[_reduxKey] && widgetState[_reduxKey].display) {
				reduxKey = _reduxKey;
				return widget = widgetState[_reduxKey].map[uid];
			} else return false;
		});
	}
	return { widget, reduxKey };
}

const canUpdate = (uris, announcements) => {
	return announcements.some(announcement => uris.includes(announcement.uri));
}

const watchLastEventEpic = (action$, state$) => {
	return action$.pipe(
		ofType(announcerActions.announced.type),
		filter((action) => action.announcements.some(announcement => cx.o.typeOf(announcement, ods.devices.DeviceNewEventAnnouncement))),
		mergeMap(action => {
			const emit = [];
			const stomachState = state$.value.stomach;
			Object.keys(stomachState).forEach(type => {
				const stomachItem = stomachState[type];
				if (stomachItem) {
					Object.keys(stomachItem).forEach(uid => {
						const { widget, reduxKey } = getWidget(uid, state$.value.widgets, state$.value.pages);
						if (widget) {
							const parameters = cx.meta.clone(stomachItem[uid].parameters);
							if (!parameters.filter.until) {
								const locked = !!(widget.data && widget.data.uris);
								if (locked) {
									if (widget.data.uris.length == 0 || canUpdate(widget.data.uris, action.announcements)) {
										buildParameters(parameters, widget.data);
										emit.push(actions[type].request({ uid, parameters }));
									}
								} else {
									const selection = state$.value.pages[reduxKey].selection;
									if (selection.length == 0 || canUpdate(selection, action.announcements)) {
										buildParameters(parameters, widget.data);
										emit.push(actions[type].request({ uid, parameters }));
									}
								}
							}
						}
					});
				}
			});
			return of.apply(this, emit);
		})
	)
}

const epic = combineEpics(tripEpic, eventEpic, removeEpic, watchLastEventEpic);

export { actions, reducer, epic };
