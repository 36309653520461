import { ITab } from 'components/molecules/tabs';
import { RuleGroup } from 'constants/issues';

export const ISSUES_TABS: ITab[] = [
  { id: RuleGroup.OPEN_RUNWAY, name: 'open runway incidents' },
  { id: RuleGroup.CLOSED_RUNWAY, name: 'closed runway incidents' },
  { id: RuleGroup.TAXIWAY, name: 'taxiway incidents' },
];

export const PAGE_SIZES = [
  { value: 10, label: 'display X entries' },
  { value: 20, label: 'display X entries' },
  { value: 30, label: 'display X entries' },
];
