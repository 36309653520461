import React from 'react';
import { connect } from 'react-redux';
import Loader from '../../general/Loader';
import { useI18n } from '../../../../i18n';
import List from '../../general/list/List';
import DatetimeField from '../../general/datetime/DatetimeField';
import "./latestEventShortView.scss";

/**
 * @param {Object} props
 * @param {string} props.eventName
 * @param {Date} props.eventTime
 */

function LastEventItem(props) {
	const { f, fc } = useI18n();
	return (<div>{fc({ prefix: 'device-event', id: props.eventName })} {f('at')} <DatetimeField datetime={props.eventTime} /></div>)
}

/**
 * @param {Object} props
 * @param {string} props.uri
 * @param {Date} [props.now]
 */

function LatestEventShortView(props) {
	const { f } = useI18n();
	const uri = props.uri;

	let content = null;
	const state = props.deviceStatesMap[uri];
	const events = state ? (state.events ? state.events : (state.event ? [state.event] : null)) : null;
	if (props.deviceStatesMap != null && props.eventTypes.typeMap != null) {
		if (events && events.length > 0) {
			content = events.map(event => {
				const eventType = props.eventTypes.typeMap[event.eventType];
				return (<LastEventItem key={event.eventId} eventName={eventType.name} eventTime={event.generatedAt} />);
			});
		} else {
			content = f('n/a');
		}
	} else {
		content = <Loader />;
	}

	let header = 'event'
	if (events && events.length > 1) header += 's';
	if (
		!props.now
		|| events
		&& events[0]
		&& events[0].generatedAt.getTime() != props.now.getTime()
	) {
		header = 'last ' + header;
	}

	return (
		<div className="latest-event-short-view">
			<div>{f(header)}:</div>
			<List>
				{content}
			</List>
		</div>
	);
}

export default connect((state, props) => {
	let deviceStatesMap = null;
	if (props.reduxKey == 'dashboard') {
		deviceStatesMap = state.deviceStates.map;
	} else if (props.reduxKey == 'timeMachine') {
		deviceStatesMap = state.timeMachine.state.map
	}
	return {
		deviceStatesMap,
		eventTypes: state.registry.eventTypes
	};
})(LatestEventShortView);
