import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { cx } from '../../api';
import { useI18n } from '../../../i18n';
import { datetime } from '../../misc/datetime';
import SidebarLayout from '../general/layout/SidebarLayout';
import PagePanel from '../general/panel/PagePanel';
import Input from '../general/form/Input';
import { actions as deviceCommandsActions } from '../../redux/api/deviceCommands';
import DeviceCommandTile from '../share/devices/deviceCommands/DeviceCommandTile';
import ObjectActionDialogPopup from '../general/ObjectActionDialogPopup';
import DeviceCommand from '../share/devices/deviceCommands/DeviceCommand';
import DeviceCommandIsPending from '../share/devices/deviceCommands/DeviceCommandIsPending';
import List from '../general/list/List';
import DeviceFilteredEvents from '../share/devices/DeviceFilteredEvents';
import { EVENT_MNEMONICS_TO_PROCESS } from '../../redux/api/deviceCommands/rx';
import { FiFileText as Icon } from 'react-icons/fi';
import { setPageTitle } from '../../misc/page';
import './deviceCommandsPage.scss';

const PAGE_TITLE = 'device commands';

const createEventFilter = (eventTypes) => {
	const filter = new cx.ods.devices.EventFilter();
	filter.minGeneratedAt = new Date(new Date() - datetime.DAY); // limit to one day in depth
	filter.eventTypes = eventTypes;
	filter.window = new cx.ods.devices.EventWindowFilter();
	filter.window.forward = false;
	filter.window.size = 7; // limit to 7 events
	return filter;
}

function DeviceCommandsPage(props) {
	const { f, fc } = useI18n();
	const [filter, setFilter] = useState('');
	const [intent, setIntent] = useState(null);
	const uri = props.match.params.uri;
	const commands = props.commandsMap[uri];
	const disabled = commands && commands.pending;

	useEffect(() => {
		setPageTitle(fc(PAGE_TITLE));
		if (!commands || !commands.types) {
			props.loadCommands(uri);
		}
	}, []);

	useEffect(() => {
		intent && setIntent(null);
	}, [filter]);

	const closePopup = () => {
		setIntent(null);
	}

	const onItemSelect = (command, data) => {
		setIntent({ subject: 'command', command, data });
	}

	const onCancel = (commandType) => {
		props.cancelCommand(uri, commandType);
	}

	const pending = [];

	let readyToStart = [];
	let eventsFilter = null;

	if (commands && commands.types) {
		const compare = (a, b) => {
			const aName = f({ prefix: 'command', id: a.description });
			const bName = f({ prefix: 'command', id: b.description });
			if (aName.toLowerCase() > bName.toLowerCase()) return 1;
			if (aName.toLowerCase() < bName.toLowerCase()) return -1;
			return 0;
		};
		const _filter = (command) => {
			if (!filter) return true;
			const commandName = f({ prefix: 'command', id: command.description });
			return commandName.toUpperCase().includes(filter.toUpperCase())
		}
		const filteredCommands = commands.types.filter(_filter).sort(compare);
		if (!commands.pendingCommands || (commands.pendingCommands && commands.pendingCommands.length == 0)) {
			readyToStart = filteredCommands.map(command => {
				return <DeviceCommandTile
					key={command.commandType}
					onClick={onItemSelect}
					uri={uri}
					command={command}
				/>;
			});
		} else {
			filteredCommands.forEach(command => {
				const isPending = commands.pendingCommands.find(com => com.commandType === command.commandType);
				if (isPending) {
					pending.push(
						<DeviceCommandIsPending
							processing={isPending.processing}
							key={command.commandType}
							command={command}
							onCancel={onCancel}
						/>
					);
				} else {
					readyToStart.push(
						<DeviceCommandTile
							key={command.commandType}
							onClick={onItemSelect}
							uri={uri}
							command={command}
						/>
					);
				}
			});
		}
	}

	if (props.eventMnemonicMap) {
		const filteredEvents = EVENT_MNEMONICS_TO_PROCESS.map(eventMnemonic => props.eventMnemonicMap[eventMnemonic].eventType);
		eventsFilter = createEventFilter(filteredEvents);
	}

	const sidebarContent = (<>
		<div className="body">
			<div className="filter">
				<Input label={f('filter')} value={filter} onChange={setFilter} cleanable />
			</div>
			<List className="pending-command-list">
				{pending}
			</List>
		</div>
		{eventsFilter && <DeviceFilteredEvents title={f('recent history')} uri={uri} filter={eventsFilter} />}
	</>);

	return (
		<div className="app-page device-commands">
			<PagePanel>
				<SidebarLayout
					className="body"
					icon={<Icon size="22" />}
					title={f("device commands")}
					sidebarContent={sidebarContent}
				>
					<div className="device-commands-view">
						{readyToStart}
					</div>
					{intent && intent.subject == 'command' && (
						<ObjectActionDialogPopup
							offset={intent.data.offset}
							width={intent.data.width}
							onClose={closePopup}
							disabled={disabled}
							title={f({ prefix: 'command', id: intent.command.description })}
						>
							<DeviceCommand hideTitle command={intent.command} onSubmit={closePopup} uri={uri} />
						</ObjectActionDialogPopup>
					)}
				</SidebarLayout>
			</PagePanel>
		</div>
	);
}

export default connect(
	state => ({
		commandsMap: state.deviceCommands,
		eventMnemonicMap: state.registry.eventTypes.mnemonicMap,

	}),
	dispatch => ({
		loadCommands: (uri) => {
			dispatch(deviceCommandsActions.types.request({ uri }));
			dispatch(deviceCommandsActions.load.request({ uri }));
		},
		cancelCommand: (uri, commandType) => {
			dispatch(deviceCommandsActions.cancel.request({ uri, commandType }));
		}
	})
)(DeviceCommandsPage);
