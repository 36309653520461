import { actions } from './actions';
import * as eventTypes from './eventTypes';
import * as messageFields from './messageFields';
import * as attributeTypes from './attributeTypes';
import * as countries from './countries';
import * as roles from './roles';
import * as timeZones from './timeZones';
import * as category from './category';
import { combineReducers } from 'redux';

const reducer = combineReducers({
	eventTypes: eventTypes.reducer,
	messageFields: messageFields.reducer,
	attributeTypes: attributeTypes.reducer,
	countries: countries.reducer,
	roles: roles.reducer,
	timeZones: timeZones.reducer,
	category: category.reducer
});

export { actions, reducer };
