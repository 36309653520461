export enum IssueSeverity {
  Severe = 'SEVERE',
  Alert = 'ALERT',
  Warning = 'WARNING',
  Notice = 'NOTICE',
}

export enum ResolutionKind {
  FalsePositive = 'FALSE_POSITIVE',
  ConfirmedViolation = 'CONFIRMED_VIOLATION',
  AuthorizedSituation = 'AUTHORIZED_SITUATION',
}

export enum RuleGroup {
  OPEN_RUNWAY = 'OPEN_RUNWAY',
  CLOSED_RUNWAY = 'CLOSED_RUNWAY',
  TAXIWAY = 'TAXIWAY',
}

export const VISIBLE_ALERTS_COUNT = 2;
