import { Dispatch, useRef } from 'react';
import { DragObjectWithType, useDrop } from 'react-dnd';

import { IAttributeRangeDetector } from 'types/detectors';
import { cx } from 'core/api';
import { generateId } from 'core/misc/misc';
import Intent from 'core/misc/ObjectActionIntent';
import { DragItemType } from 'core/react/share/dnd/DragItemType';

interface IUseAttributeDetectorListItemProps {
  className: string;
  detector: IAttributeRangeDetector;
  onSelect: Dispatch<IAttributeRangeDetector>;
  onIntent: Dispatch<Intent>;
}

export const useAttributeDetectorListItem = (props: IUseAttributeDetectorListItemProps) => {
  const { detector, onSelect, onIntent } = props;

  const uid = useRef(generateId());

  const selectDetector = () => {
    onSelect(detector);
  };

  const onEdit = () => {
    onIntent(new Intent(null, Intent.Action.Edit, detector));
  };

  const onRemove = () => {
    const offset = cx.dom.at.offset(document.getElementById(uid.current));

    onIntent(new Intent(null, Intent.Action.Remove, detector, { offset }));
  };

  const onDrop = (item: DragObjectWithType) => {
    item.type === DragItemType.ACTION_EDIT ? onEdit() : onRemove();
  };

  const [dropState, dropRef] = useDrop({
    accept: [DragItemType.ACTION_EDIT, DragItemType.ACTION_REMOVE],
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return { id: uid.current, dropState, dropRef, name: detector.name, selectDetector };
};
