import { ofType, combineEpics } from 'redux-observable';
import { switchMap, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { api, ods, rx } from '../../../api';
import { errorMap } from '../../actions';
import { createParameters, createScope } from './adapters';

export const createEpic = (actions, Manager, apiKey) => {
	const loadEpic = (action$) => {
		return action$.pipe(
			ofType(actions.load.request.type),
			switchMap(action =>
				rx(api.datascope[apiKey], action.parameters).pipe(
					map(operation => actions.load.success({ parameters: action.parameters, histories: operation.response() })),
					errorMap(actions.load.fail),
					takeUntil(action$.pipe(ofType(actions.load.cancel.type)))
				)
			)
		)
	}

	/**
	 * Check if received scopes cover requested scopes.
	 * Calculate and request missing scopes if any.
	 */
	const processEpic = (action$, state$) => {
		return action$.pipe(
			ofType(actions.load.success.type),
			withLatestFrom(state$.pipe(map(state => state.timeMachine.state))),
			map(([action, timeMachineState]) => {
				const parameters = timeMachineState.parameters;
				const manager = Manager.getInstance();
				const observed = Boolean(timeMachineState.subscribers[manager.stateName()]);
				const frame = createScope(new ods.TimeRange(parameters.since, parameters.until), parameters.uris);
				const missing = manager.subtract(frame);
				if (missing && observed) return actions.load.request({ parameters: createParameters(frame, parameters.now, missing) });
				else return actions.load.finish();
			})
		)
	}

	return combineEpics(loadEpic, processEpic);
}
