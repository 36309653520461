import { ActionGeneratorBuilder } from '../../actions';

export const actions = new ActionGeneratorBuilder('deviceCommands')
	.subtype('types', types => types.request('uri').success({ uri: true, types: true }).fail({ uri: true, errorMessage: true }).cancel())
	.subtype('submit', submit => submit.request({ uri: true, commandInfo: true }).success().fail({ uri: true, errorMessage: true }).cancel())
	.subtype('load', load => load.request('uri').success({ uri: true, details: true }).fail({ uri: true, errorMessage: true }).cancel())
	.subtype('find', find => find.request({ uri: true, filter: true }).success({ uri: true, details: true }).fail({ uri: true, errorMessage: true }).cancel())
	.subtype('retrieve', retrieve => retrieve.request({ uri: true, commandType: true, commandIndex: true }).success({ uri: true, details: true }).fail({ uri: true, errorMessage: true }).cancel())
	.subtype('cancel', cancel => cancel.request({ uri: true, commandType: true, commandIndex: false }).success({ uri: true }).fail({ uri: true, errorMessage: true }).cancel())
	.type('lastComplete', { uri: true, commandType: true, eventType: true })
	.build()
;
