import React, { useState, useContext, useEffect, useRef } from 'react';
import { useI18n } from '../../../../../i18n';
import Form from '../../../general/form/Form';
import { cx } from '../../../../api';
import { PropertyValuesContext } from './PropertiesControl';
import { useCustomDrop } from './customDrop';
import { className } from '../../../../lib/className';

/**
 * @param {Object} props
 * @param {cx.ods.meta.BooleanProperty} props.property
 * @param {function} props.onChange
 */

function BooleanControl(props) {
	const { f, fc } = useI18n();
	const context = useContext(PropertyValuesContext);
	const initialized = useRef(false);
	const [value, setValue] = useState(null);

	useEffect(() => {
		if (!initialized.current) {
			if (context && context[props.property.propertyId]) {
				setValue(context[props.property.propertyId].value === 'true');
			}
			initialized.current = true;
		}
	}, [context]);

	const validator = () => {
		if (value === '' || value === null) {
			return props.property.required && f('please select a value');
		}
	}

	const onChange = (value) => {
		const propertyValue = new cx.ods.meta.ScalarValue();
		propertyValue.propertyId = props.property.propertyId;
		propertyValue.value = value === null ? null : value.toString();
		props.onChange(propertyValue);
		setValue(value);
	}

	let Type = null, config = null;
	if (props.property.required) {
		if (!props.property.enumeration || props.property.enumeration.length == 2) {
			Type = Form.Control.Type.Checkbox;
			config = {};
		} else if (props.property.enumeration && props.property.enumeration.length == 1) {
			Type = Form.Control.Type.Checkbox;
			config = {
				disabled: true
			};
			setValue(props.property.enumeration[0]);
		}
	} else {
		Type = Form.Control.Type.SelectPicker;
		if (!props.property.enumeration || props.property.enumeration.length == 2) {
			config = {
				data: [
					{ label: fc('true'), value: true },
					{ label: fc('false'), value: false }
				],
			};
		} else if (props.property.enumeration && props.property.enumeration.length == 1) {
			const value = props.property.enumeration[0];
			config = {
				data: [
					{ label: fc(value.toString()), value }
				],
			};
		}
	}

	// -----------------------------------

	const [dropState, dropRef] = useCustomDrop(() => props.onRemove(props.property));
	const onRef = (element) => props.onRemove && dropRef(element);

	// -----------------------------------

	return (
		<Form.Control
			controlType={Type}
			controlValidator={validator}
			value={value}
			onChange={onChange}
			label={props.property.name}
			customRef={onRef}
			className={className({ 'droppable': dropState.isOver && dropState.canDrop })}
			{...config}
		/>
	);
}

export default BooleanControl;