import React from 'react';
// import { Link } from 'react-router-dom';
import { FiAlertCircle } from 'react-icons/fi';
import { useI18n } from '../../../../../i18n';
import { className, fromProps } from '../../../../lib/className';
import RecentEventList from '../../../share/events/RecentEventList';
import PanelLayout from '../../../general/layout/PanelLayout';
import { DeviceDetailsProxy } from '../../../../api/device'; // eslint-disable-line
import './deviceRecentEventsView.scss';

/**
 * @param {Object} props
 * @param {DeviceDetailsProxy} props.device
 */

function DeviceRecentEventsView(props) {
	const { f } = useI18n();
	return (
		<PanelLayout
			className={className('device-recent-events-view', fromProps(props))}
			icon={<FiAlertCircle />}
			title={f('recent events')}
			// <>
			// <Link to={'/device/' + props.device.uri + '/events'}>
			// {f('recent events')}
			// <FiLink />
			// </Link>
			// </>}
		>
			<RecentEventList uri={props.device.uri} />
		</PanelLayout>
	);
}

export default DeviceRecentEventsView;