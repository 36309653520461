import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../../general/Loader';
import { useI18n } from '../../../../../i18n';
import DatetimeField from '../../../general/datetime/DatetimeField';
import ScrollList from '../../../general/list/ScrollList';
import ReportSummary from './ReportSummary';
import ReportHeader from './ReportHeader';
import { formatter } from '../../../../misc/datetime';
import { actions } from '../../../../redux/api/reports';
import NoDataView from '../../../general/view/NoDataView';
import './temperatureChange.scss';

function HeaderListItem() {
	const { f } = useI18n();
	return (
		<>
			<div className="header-item"><span className="capitalize">{f('item No.')}</span></div>
			<div className="header-item"><span className="capitalize">{f('start time')}</span></div>
			<div className="header-item"><span className="capitalize">{f('end time')}</span></div>
			<div className="header-item"><span className="capitalize">{f('duration')}</span></div>
			<div className="header-item rows">
				<div><span className="capitalize">{f('temperature')} 1</span></div>
				<div>
					<div><span className="capitalize">{f('minimum')}, &deg;C</span></div>
					<div><span className="capitalize">{f('maximum')}, &deg;C</span></div>
					<div><span className="capitalize">{f('average')}, &deg;C</span></div>
					<div><span className="capitalize">{f('quantity')}</span></div>
				</div>
			</div>
			<div className="header-item rows">
				<div><span className="capitalize">{f('temperature')} 2</span></div>
				<div>
					<div><span className="capitalize">{f('minimum')}, &deg;C</span></div>
					<div><span className="capitalize">{f('maximum')}, &deg;C</span></div>
					<div><span className="capitalize">{f('average')}, &deg;C</span></div>
					<div><span className="capitalize">{f('quantity')}</span></div>
				</div>
			</div>
		</>
	)
}

function ReportListItem({interval, index}) {
	const t1 = interval.temperature1;
	const t2 = interval.temperature2;
	return (
		<>
			<div>{index}</div>
			<div><DatetimeField datetime={interval.since} /></div>
			<div><DatetimeField datetime={interval.until} now={interval.since} /></div>
			<div>{formatter.duration(interval.since, interval.until)}</div>
			<div className="temperature-group">
				<div>{t1 && t1.minimum || "-"}</div>
				<div>{t1 && t1.maximum || "-"}</div>
				<div>{t1 && t1.average || "-"}</div>
				<div>{t1 && t1.quantity || "-"}</div>
			</div>
			<div className="temperature-group">
				<div>{t2 && t2.minimum || "-"}</div>
				<div>{t2 && t2.maximum || "-"}</div>
				<div>{t2 && t2.average || "-"}</div>
				<div>{t2 && t2.quantity || "-"}</div>
			</div>
		</>
	)
}

function EodListItem() {
	const { f } = useI18n();
	return <div className="indicator"><span className="capitalize">{f('you have reached the end')}</span></div>;
}

// -----------------------------------------------------------------

function TemperatureChange(props) {
	const { f } = useI18n();

	const loadNext = () => {
		const uri = props.state.uri;
		const parameters = props.state.parameters;
		props.dispatch(actions.temperatureChange.request({ uri, parameters }));
	}

	let content = null;
	let canExport = false;
	if (props.state.list != null) {
		if (!props.state.list || props.state.list.length == 0) {
			content = <NoDataView message={f('no data for such criteria')} />;
		} else {
			canExport = true;
			let items = props.state.list.map((interval, at) => <ReportListItem key={at} interval={interval} index={at+1} />);
			items.unshift(<HeaderListItem key={1000000} />);
			content = <ScrollList
				pending={props.state.pending}
				next={loadNext}
				isEod={!props.state.hasMore}
				eodItem={EodListItem}
			>
				{items}
			</ScrollList>
		}
	} else {
		content = (
			<div className="center">
				{
					props.state.error
						? <span className="error">{props.state.error}</span>
						: <Loader size={Loader.Size.MD} />
				}
			</div>
		);
	}

	const exportReport = () => {
		props.dispatch(actions.temperatureChange.export({ uri: props.state.uri }));
		props.history.goBack();
	}

	const timeRange = props.state.parameters.timeRange;
	return (
		<div className="report temperature-change">
			<ReportHeader title={f('temperature change report')} canExport={canExport} onExport={exportReport} />
			<ReportSummary
				since={timeRange.since}
				until={timeRange.until}
				devices={[props.devices[props.state.uri]]}
				generatedAt={props.state.at}
			/>
			<div className="content">
				{content}
			</div>
		</div>
	)
}

export default withRouter(connect(state => {
	return ({
		state: state.reports.temperatureChange,
		devices: state.devices.map
	})
})(TemperatureChange));
