import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from '../../../redux/store';
import EventTick from './EventTick';

const ox = {
	after: (timeout, procedure) => window.setTimeout(procedure, timeout || 0)
	, later: procedure => window.setTimeout(procedure, 0)
	, exception: (message) => {throw message || "Generic error";}
	, time: {
		ms: {
			second: 1000
			, minute: 60000
			, hour: 60 * 60000
			, day: 24 * 60 * 60000
			, week: 7 * 24 * 60 * 60000
		}
		, modulusHour: (date) => {
			return date.getMinutes() * ox.time.ms.minute + date.getSeconds() * ox.time.ms.second + date.getMilliseconds();
		}
		, modulusDay: (date) => {
			return date.getHours() * ox.time.ms.hour + ox.time.modulusHour(date);
		}
		, format: {
			iso: (value, options) => {
				options = Object.assign({seconds: true, milliseconds: false, tz: true}, options);
				const pad = value => String(value).padStart(2, '0');
				let datetime = `${value.getFullYear()}-${pad(value.getMonth() + 1)}-${pad(value.getDate())}`
					+ `T${pad(value.getHours())}:${pad(value.getMinutes())}`
				;
				if (options.seconds) datetime += `:${pad(value.getSeconds())}`;
				if (options.milliseconds) datetime += `.${value.getMilliseconds()}`;
				if (!options.tz) return datetime;
				let offset = value.getTimezoneOffset();
				if (offset == 0) return datetime + 'Z';
				let sign, absolute;
				if (0 <= offset) {sign = '+'; absolute = offset}
				else {sign = '-'; absolute = -offset}
				return datetime + `${sign}${pad(Math.trunc(absolute / 60))}:${pad(absolute % 60)}`;
			}
		}
	}
	, ease: {
		quadIn: x => x * x
		, quadOut: x => x * (2 - x)
		, quadInOut: x => x < 0.5 ? 2 * x * x : -1 + (4 - 2 * x) * x
		, cubicIn: x => Math.pow(x, 3)
		, cuibcOut: x => 1 - Math.pow(1 - x, 3)
		, cubicInOut: x => x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2
		, circIn: x => 1 - Math.sqrt(1 - Math.pow(x, 2))
		, circOut: x => Math.sqrt(1 - Math.pow(x - 1, 2))
		, circInOut:  x => x < 0.5
			? (1 - Math.sqrt(1 - Math.pow(2 * x, 2))) / 2
			: (Math.sqrt(1 - Math.pow(-2 * x + 2, 2)) + 1) / 2
	}
	, dom: {
		element: (tag, ...classes) => {
			const element = document.createElement(tag);
			if (0 < classes.length) element.classList.add(...classes);
			return element;
		}
		, purge: (element) => {
			while (element.hasChildNodes()) element.removeChild(element.lastChild);
			return element;
		}
		, node: (value) => {
			if (!(value instanceof Object)) return document.createTextNode(value);
			if (value instanceof Node) return value;
			if (!value[Symbol.iterator]) return document.createTextNode(value);
			const fragment = document.createDocumentFragment();
			for (const item of value) fragment.appendChild(ox.dom.node(item));
			return fragment;
		}
	}
	// , log: (() => {
	// 	const levels = {
	// 		silent: 0, info: 1, debug: 2, trace: 3
	// 	};
	// 	const index = Object.fromEntries(Object.entries(levels).map(([level, value]) => [value, level]));
	// 	let logLevel = levels.silent;
	// 	const log = (level, ...args) => {
	// 		if (logLevel < level) return;
	// 		console.log((index[level] || "no level") + ": ", ...(args.map((arg) => typeof arg === "function" ? arg() : arg)));
	// 	};
	// 	return {
	// 		levels
	// 		, level: () => logLevel, setLevel: (level) => logLevel = level
	// 		, log
	// 		, info: log.bind(null, levels.info)
	// 		, debug: log.bind(null, levels.debug)
	// 		, trace: log.bind(null, levels.trace)
	// 	};
	// })()
};

class ScaleResolution {

	constructor(name, maxDivision, options) {
		this.name = name;
		this.minDivisionWidth = options && options.minDivisionWidth != null ? options.minDivisionWidth : 4;
		this.maxResolution = maxDivision / this.minDivisionWidth;
	}

	fits(extent, width) {
		return (extent.end - extent.start) / width <= this.maxResolution;
	}

	cover(extent) {
		const start = this.floor(extent.start), end = this.ceil(extent.end);
		if (extent.start == start && extent.end == end) return extent;
		return {start, end};
	}

	snap(extent) {
		const start = this.round(extent.start), end = this.round(extent.end);
		if (extent.start == start && extent.end == end) return extent;
		return {start, end};
	}

	floor(value) {
		return value;
	}

	ceil(value) {
		return value;
	}

	label(value) {
		return value;
	}

	header(value) {
		return this.label(value);
	}
}

class ProportionalResolution extends ScaleResolution {

	constructor(name, division, options) {
		super(name, division, options);
		this.division = division;
	}

	values(extent) {
		const {start, end} = extent;
		return {
			[Symbol.iterator]: () => {
				const division = this.division, last = this.floor(end);
				let at = this.ceil(start);
				return {
					next: () => {
						if (last < at) return {done: true};
						const current = at;
						at += division;
						return {value: current};
					}
				}
			}
		};
	}

	floor(value) {
		return Math.floor(value / this.division) * this.division;
	}

	ceil(value) {
		return Math.ceil(value / this.division) * this.division;
	}

	round(value) {
		return Math.round(value / this.division) * this.division;
	}
}

const TimeResolutionAspects = (T) => class extends T {

	constructor(name, formats, ...args) {
		super(name, ...args);
		const options = args[args.length - 1], locale = options && options.locale ? options.locale : undefined;
		const label = new Intl.DateTimeFormat(locale, formats.label);
		this.formatters = {
			label, header: formats.header ? new Intl.DateTimeFormat(locale, formats.header): label
		}
	}

	label(value) {
		return this.formatters.label.format(new Date(value))
	}

	header(value) {
		return this.formatters.header.format(new Date(value))
	}
}

class HourDivisionResolution extends TimeResolutionAspects(ProportionalResolution) {

	constructor(divisions, options) {
		super(`${Math.round(60 * 100 / divisions) / 100}-min`
			, {label: {minute: "numeric"}}
			, ox.time.ms.hour / divisions, options
		);
	}

	floor(value) {
		const hourTime = ox.time.modulusHour(new Date(value));
		return value - hourTime + super.floor(hourTime);
	}

	ceil(value) {
		const hourTime = ox.time.modulusHour(new Date(value));
		return value - hourTime + super.ceil(hourTime);
	}

	round(value) {
		const hourTime = ox.time.modulusHour(new Date(value));
		return value - hourTime + super.round(hourTime);
	}
}

class HourResolution extends TimeResolutionAspects(ProportionalResolution) {

	constructor(options) {
		super("hour"
			, {label: {hour: "numeric", hour12: false}}
			, ox.time.ms.hour, options
		);
		this.half = ox.time.ms.hour / 2;
	}

	floor(value) {
		const hourTime = ox.time.modulusHour(new Date(value));
		return value - hourTime;
	}

	ceil(value) {
		const hourTime = ox.time.modulusHour(new Date(value));
		if (hourTime == 0) return value;
		return value - hourTime + ox.time.ms.hour;
	}

	round(value) {
		const hourTime = ox.time.modulusHour(new Date(value));
		return hourTime < this.half ? value - hourTime : value - hourTime + ox.time.ms.hour;
	}
}

class SteppingResolution extends ScaleResolution {

	constructor(name, maxDivision, stepSize, options) {
		super(name, maxDivision, options);
		this.stepSize = stepSize;
	}

	values(extent) {
		const {start, end} = extent;
		return {
			[Symbol.iterator]: () => {
				const stepSize = this.stepSize, last = this.floor(end);
				let at = this.ceil(start), dateAt = new Date(at);
				return {
					next: () => {
						if (last < at) return {done: true};
						const current = at;
						at = this.step(dateAt, stepSize);
						return {value: current};
					}
				}
			}
		};
	}

	/* step(date, offset) {} */
}

class DayDivisionResolution extends TimeResolutionAspects(SteppingResolution) {

	constructor(divisions, options) {
		if (24 % divisions) throw new Error("divisions should be hour multiple");
		const divisionHours = 24 / divisions, division = divisionHours * ox.time.ms.hour;
		super(`${divisionHours}-h`
			, {label: {hour: "numeric", hour12: false}}
			, division, divisionHours, options
		);
		this.divisionHours = divisionHours;
		this.division = division;
	}

	floor(value) {
		const date = new Date(value), offset = ox.time.modulusDay(date) % this.division;
		return offset != 0 ? this.step(date, -Math.floor(offset / ox.time.ms.hour), -this.divisionHours) : value;
	}

	ceil(value) {
		const date = new Date(value), offset = ox.time.modulusDay(date) % this.division;
		return offset != 0 ? this.step(date, this.divisionHours - Math.floor(offset / ox.time.ms.hour), this.divisionHours) : value;
	}

	round(value) {
		const date = new Date(value), offset = ox.time.modulusDay(date) % this.division;
		if (offset == 0) return value;
		const floor = this.step(date, -Math.floor(offset / ox.time.ms.hour), -this.divisionHours), ceil = this.step(date, this.divisionHours);
		return value - floor < ceil - value ? floor : ceil;
	}

	step(date, offset, adjustment = offset) {
		const hours = date.getHours() + offset;
		const shifted = date.setHours(hours, 0, 0, 0);
		if (date.getHours() == (hours + 24) % 24) return shifted;
		return date.setHours(hours + adjustment, 0, 0, 0);
	}
}

class DayResolution extends TimeResolutionAspects(SteppingResolution) {

	constructor(options) {
		super("day"
			, {label: {day: "numeric"}, header: {month: "numeric", day: "numeric"}}
			, ox.time.ms.day, 1, options
		);
	}

	floor(value) {
		return new Date(value).setHours(0, 0, 0, 0);
	}

	ceil(value) {
		const date = new Date(value), floor = date.setHours(0, 0, 0, 0);
		return floor != value ? date.setDate(date.getDate() + 1) : floor;
	}

	round(value) {
		const date = new Date(value), floor = date.setHours(0, 0, 0, 0);
		if (floor == value) return value;
		const ceil = date.setDate(date.getDate() + 1);
		return value - floor < ceil - value ? floor : ceil;
	}

	step(date, offset) {
		return date.setDate(date.getDate() + offset);
	}
}

class WeekResolution extends TimeResolutionAspects(SteppingResolution) {

	constructor(options) {
		super("week"
			, {label: {day: "numeric"}, header: {month: "numeric", day: "numeric"}}
			, ox.time.ms.day * 7, 1, options
		);
		this.weekStart = options && options.weekStart != null ? options.weekStart : 1;
	}

	floor(value) {
		const date = new Date(value), offset = (7 + date.getDay() - this.weekStart) % 7;
		date.setHours(0, 0, 0, 0);
		return offset != 0 ? date.setDate(date.getDate() - offset) : date.getTime();
	}

	ceil(value) {
		const date = new Date(value), offset = (7 + date.getDay() - this.weekStart) % 7;
		if (date.setHours(0, 0, 0, 0) == value && offset == 0) return value;
		return date.setDate(date.getDate() - offset + 7);
	}

	round(value) {
		const date = new Date(value), offset = (7 + date.getDay() - this.weekStart) % 7;
		if (date.setHours(0, 0, 0, 0) == value && offset == 0) return value;
		const day = date.getDate(), floor = offset != 0 ? date.setDate(day - offset) : date.getTime(), ceil = date.setDate(day - offset + 7);
		return value - floor < ceil - value ? floor : ceil;
	}

	step(date, offset) {
		return date.setDate(date.getDate() + offset * 7);
	}
}

class MonthResolution extends TimeResolutionAspects(SteppingResolution) {

	constructor(options) {
		super("month"
			, {label: {day: "numeric"}, header: {year: "numeric", month: "numeric"}}
			, ox.time.ms.day * 31, 1, options
		);
	}

	floor(value) {
		const date = new Date(value);
		date.setHours(0, 0, 0, 0);
		return date.setDate(1);
	}

	ceil(value) {
		const date = new Date(value), day = date.getDate();
		if (date.setHours(0, 0, 0, 0) == value && day == 1) return value;
		if (day != 1) date.setDate(1);
		return date.setMonth(date.getMonth() + 1);
	}

	round(value) {
		const date = new Date(value), day = date.getDate();
		if (date.setHours(0, 0, 0, 0) == value && day == 1) return value;
		const floor = day != 1 ? date.setDate(1) : date.getTime(), ceil = date.setMonth(date.getMonth() + 1);
		return value - floor < ceil - value ? floor : ceil;
	}

	step(date, offset) {
		return date.setMonth(date.getMonth() + offset);
	}
}

class ResolutionRuler {

	constructor(resolutions) {
		this.name = resolutions.map(resolution => resolution.name).join('/');
		this.resolutions = resolutions.slice();
		this.primary = resolutions.reduce((found, resolution) => resolution.maxResolution < found.maxResolution ? resolution : found);
		this.aligning = resolutions.reduce((found, resolution) => found.maxResolution < resolution.maxResolution ? resolution : found);
	}

	fits(extent, width) {
		return this.primary.fits(extent, width);
	}

	cover(extent, width) {
		return this.aligning.cover(extent, width);
	}

	snap(extent, width) {
		return this.aligning.snap(extent, width);
	}

	ticks(extent) {
		const {start, end} = extent;
		return {
			[Symbol.iterator]: () => {
				const extent = {start, end};
				const contexts = this.resolutions.map(resolution => {
					const iterator = resolution.values(extent)[Symbol.iterator]();
					return {resolution, iterator, at: iterator.next()};
				});
				let minimum = null;
				return {
					next: () => {
						const tick = contexts.reduce((tick, context, at) => {
							while (!context.at.done && context.at.value <= minimum) context.at = context.iterator.next();
							if (context.at.done) return tick;
							if (!tick || context.at.value < tick.value) {
								const resolutions = new Array(contexts.length);
								resolutions[at] = context.resolution;
								return {value: context.at.value, resolutions};
							}
							if (context.at.value == tick.value) tick.resolutions[at] = context.resolution;
							return tick;
						}, null);
						if (!tick) return {done: true};
						minimum = tick.value;
						return {value: tick};
					}
				};
			}
		};
	}
}

// --------------------------------------------

const abstractScaleFallbacks = {
	options: {
		minDivisionWidth: 4
		, divisionLevels: 3
		, scalePadding: 0.25
		, aperturePadding: 0.1
		, rollDuration: 250
		, zoomProportion: 0.1
	}
	, rulers: options => {throw new Error("No rulers defined");}
	, format: {
		value: value => String(value)
		, extent: extent => `[${extent.start}, ${extent.end}]`
	}
};

class AbstractScale {

	constructor(root, parameters) {
		this.options = Object.assign({}, abstractScaleFallbacks.options, parameters ? parameters.options : null);
		this.format = Object.assign({}, abstractScaleFallbacks.format, parameters ? parameters.format : null);
		this.rulers = (parameters && parameters.rulers ? parameters.rulers : abstractScaleFallbacks.rulers)(this.options);
		this.dom = this.attach(root);
		this.extent = this.initializeExtent(parameters);
		this.current = this.initializeCurrent(parameters);
		this.updateScale();
	}

	initializeExtent(parameters) {
		throw new Error("No initial extent provided");
	}

	attach(root) {
		const dom = {};
		dom.frame = root.appendChild(ox.dom.element("div", "time-scale")).appendChild(ox.dom.element("div", "frame"));
		dom.assy = dom.frame.appendChild(ox.dom.element("div", "assy"));
		dom.display = {body: dom.assy.appendChild(ox.dom.element("div", "display"))};
		this.attachDisplay(dom.display);
		const scale = dom.assy.appendChild(ox.dom.element("div", "scale"));
		scale.appendChild(ox.dom.element("div", "axis"));
		dom.ticks = scale.appendChild(ox.dom.element("div", "ticks"));
		dom.display.body.addEventListener('wheel', this.handleRangeZoomed.bind(this));
		scale.addEventListener('click', this.handleScaleClicked.bind(this));
		scale.addEventListener('wheel', this.handleScaleScrolled.bind(this));
		return dom;
	}

	attachDisplay(display) {}

	alignExtent(extent, align = (ruler, extent) => ruler.cover(extent)) {
		return this.chooseRuler(extent, align).extent;
	}

	// scale.rollTo({start: scale.extent.start - 12 * ox.time.ms.hour, end: scale.extent.end});
	// scale.rollTo({start: scale.extent.start - 3 * ox.time.ms.hour, end: scale.extent.end + 18 * ox.time.ms.hour});
	rollTo(extent, duration = this.options.rollDuration) {
		const aperture = this.aperture(), interval = aperture.end - aperture.start;
		const tx = {
			offset: aperture.start - extent.start
			, scale: interval / (extent.end - extent.start)
		};
		this.extent = extent;
		this.apertureTx = tx;
		this.updateScale();
		this.compensateTx(duration);
		// ox.log.trace(() => `roll to: ${this.format.extent(extent)}`
		// 	+ `, tx(${tx.offset} (${Math.round(tx.offset / interval * 100) / 100}), ${Math.round(tx.scale * 100) / 100})`
		// );
	}

	// scale.moveTo({start: scale.extent.start - 3 * ox.time.ms.hour, end: scale.extent.end - 3 * ox.time.ms.hour});
	moveTo(extent) {
		this.extent = extent;
		this.apertureTx = null;
		this.updateScale(true);
		this.cancelTxCompensation();
		this.handleScaleSettled();
	}

	compensateTx(duration) {
		const tx = this.apertureTx, compensating = {offset: tx.offset, scale: tx.scale};
		let startedAt = null;
		const progress = frameAt => {
			if (!startedAt)  startedAt = frameAt;
			else {
				let fraction = (frameAt - startedAt) / duration;
				if (1 <= fraction) {
					this.apertureTx = null;
					this.updateScale();
					this.compensateId = null;
					this.handleScaleSettled();
					return;
				} else {
					fraction = 1 - ox.ease.circOut(fraction);
					tx.offset = compensating.offset * fraction;
					tx.scale = 1 + (compensating.scale - 1) * fraction;
					this.updateScale();
				}
			}
			this.compensateId = window.requestAnimationFrame(progress);
		};
		if (this.compensateId) window.cancelAnimationFrame(this.compensateId);
		this.compensateId = window.requestAnimationFrame(progress);
	}

	cancelTxCompensation() {
		if (this.compensateId) {
			window.cancelAnimationFrame(this.compensateId);
			this.compensateId = null;
		}
	}

	aperture() {
		const tx = this.apertureTx;
		if (!tx) return this.extent;
		const {start, end} = this.extent;
		return {start: Math.round(start + tx.offset), end: Math.round(start + (end - start) * tx.scale + tx.offset)};
	}

	displayTx() {
		const scaleStart = this.scale.start, scaleInterval = this.scale.end - scaleStart;
		return {
			fraction: value => (value - scaleStart) / scaleInterval
			, value: fraction => Math.round(scaleStart + fraction * scaleInterval)
		};
	}

	updateScale(padded = false) {
		const aperture = this.aperture();
		let scale = this.scale, changed = false;
		if (aperture == this.extent && !padded) {
			if (!scale || scale.start != aperture.start || aperture.end != scale.end) {
				this.scale = scale = aperture;
				changed = true;
				// ox.log.trace(() => `scale, clamped: ${this.format.extent(scale)}`);
			}
		} else {
			const interval = aperture.end - aperture.start;
			let contents;
			if (!padded) contents = aperture;
			else {
				const padding = padded ? Math.round(interval * this.options.aperturePadding) : 0;
				contents = {start: aperture.start - padding, end: aperture.end + padding};
			}
			if (contents.start < scale.start || scale.end < contents.end) {
				const padding = Math.round(interval * this.options.scalePadding);
				this.scale = scale = {
					start: padded || this.extent.start < aperture.start ? aperture.start - padding : aperture.start
					, end: padded || aperture.end < this.extent.end ? aperture.end + padding : aperture.end
				};
				changed = true;
				// ox.log.trace(() => `scale, padded: ${this.format.extent(scale)}`);
			}
		}
		const transformed = this.updateScaleTx(aperture);
		this.updateScaleTicks(aperture, changed);
		if (transformed) this.handleScaleTransformed();
		if (changed) this.handleScaleChanged(scale);
	}

	updateScaleTx(aperture) {
		const scale = this.scale, scaleTx = this.scaleTx, interval = aperture.end - aperture.start;
		const tx = {offset: scale.start - aperture.start, scale: (scale.end - scale.start) / interval};
		if (scaleTx && scaleTx.offset == tx.offset && scaleTx.scale == tx.scale) return false;
		this.dom.assy.style.left = tx.offset / interval * 100 + "%";
		this.dom.assy.style.width = tx.scale * 100 + "%";
		this.scaleTx = tx;
		// ox.log.trace(() => `scale tx: (${tx.offset} (${Math.round(tx.offset / interval * 100) / 100}), ${Math.round(tx.scale * 100) / 100})`);
		return true;
	}

	updateScaleTicks(aperture, regraduate) {
		const ruler = this.chooseRuler(aperture).ruler;
		if (ruler == this.ruler && !regraduate) return;
		const start = this.scale.start, interval = this.scale.end - start;
		const root = this.dom.ticks.cloneNode();
		for (const tick of ruler.ticks(this.scale)) {
			const element = ox.dom.element("div");
			element.style.left = (tick.value - start) * 100 / interval + "%";
			let higestAt;
			tick.resolutions.forEach((resolution, at) => {
				element.classList.add(`l-${at}`);
				higestAt = at;
			});
			if (0 < higestAt || tick.resolutions.length == 1) {
				const top = higestAt == tick.resolutions.length - 1, resolution = tick.resolutions[higestAt];
				const caption = !top ? resolution.label(tick.value) : resolution.header(tick.value);
				element.appendChild(ox.dom.element("div")).appendChild(ox.dom.node(caption));
			}
			root.appendChild(element);
		}
		this.dom.ticks.parentNode.replaceChild(root, this.dom.ticks);
		this.dom.ticks = root;
		this.ruler = ruler;
		// ox.log.trace(() => `scale ticks: "${ruler.name}" for ${this.format.extent(aperture)}`);
	}

	handleScaleTransformed() {}

	handleScaleSettled() {}

	handleScaleChanged(scale) {}

	handleScaleClicked(event) {}

	handleScaleScrolled(event) {
		let range = this.range;
		const margin = Math.round((range.end - range.start) * this.options.rangeMargin);
		const step = Math.round(Math.abs(event.deltaY * margin / 100));
		if (event.deltaY < 0) {
			if (range.start - step > this.options.minDate) {
				range = { start: range.start - step, end: range.end - step };
			}
		} else {
			if (range.end + step < this.options.maxDate) {
				range = { start: range.start + step, end: range.end + step };
			}
		}
		this.updateRange(range);
		this.moveTo({ start: range.start - margin, end: range.end + margin });
	}

	handleRangeZoomed(event) {
		let range = this.range;
		const display = this.dom.display;
		const displayLeft = display.body.getBoundingClientRect().left;
		const pointerAt = event.clientX - displayLeft;
		const current = this.displayTx().value(pointerAt / this.dom.display.body.offsetWidth);
		let startStep = Math.floor((current - range.start) * this.options.zoomProportion);
		let endStep = Math.floor((range.end - current) * this.options.zoomProportion);
		if (startStep < 0) startStep = 0;
		if (endStep < 0) endStep = 0;
		if (event.deltaY < 0) {
			range = { start: range.start + startStep, end: range.end - endStep };
		} else {
			if (range.start - startStep > this.options.minDate && range.end + endStep < this.options.maxDate)
				range = { start: range.start - startStep, end: range.end + endStep };
		}
		const interval = range.end - range.start;
		const startPercent = startStep * 100 / (startStep + endStep);
		const endPercent = endStep * 100 / (startStep + endStep);
		if (this.options.maxInterval < interval) {
			const excess = (interval - this.options.maxInterval);
			range = {
				start: range.start + (excess * startPercent / 100),
				end: range.end - (excess * endPercent / 100)
			};
		} else if (interval < this.options.minInterval) {
			const excess = (this.options.minInterval - interval);
			range = {
				start: range.start - (excess * startPercent / 100),
				end: range.end + (excess * endPercent / 100)
			};
		}
		const margin = Math.round((range.end - range.start) * this.options.rangeMargin);
		this.moveTo({start: range.start - margin, end: range.end + margin});
		this.updateRange(range);
	}

	// scale.chooseRuler({start: new Date("2020-03-13T01:38:13-03:00").getTime(), end: new Date("2020-03-16T03:00:00-03:00").getTime()}).ruler.name;
	chooseRuler(extent, align = null) {
		const width = this.dom.frame.clientWidth;
		for (let at = 0; at < this.rulers.length; ++at) {
			const ruler = this.rulers[at];
			if (ruler.fits(extent, width)) {
				if (align == null) return {ruler, extent};
				const aligned = align(ruler, extent);
				if (ruler.fits(aligned, width)) return {ruler, extent: aligned};
			}
		}
		const ruler = this.rulers[this.rulers.length - 1];
		return {ruler, extent: align != null ? align(ruler, extent) : extent};
	}

	detach() {
	}
}

const rangeScaleFallbacks = {
	options: {
		rangeMargin: 0.1
		, handleWidth: 20
		, minInterval: ox.time.ms.hour
		, maxInterval: ox.time.ms.week
		, maxExpantStep: 4
	}
	, interval: 1 * ox.time.ms.week
	, rulers: options => {
		const resolutions = [
			new HourDivisionResolution(60 / 5, options) // 12 intervals
			, new HourDivisionResolution(60 / 15, options) // 4 intervals
			, new HourDivisionResolution(60 / 30, options) // 2 intervals
			, new HourResolution(options)
			, new DayDivisionResolution(24 / 3, options)
			, new DayDivisionResolution(24 / 6, options)
			, new DayDivisionResolution(24 / 12, options)
			, new DayResolution(options)
			, new WeekResolution(options)
			, new MonthResolution(options)
		].reduce((map, resolution) => {
			map[resolution.name] = resolution;
			return map;
		}, {});
		return [
			new ResolutionRuler([resolutions["5-min"], resolutions["15-min"], resolutions["hour"]])
			, new ResolutionRuler([resolutions["15-min"], resolutions["hour"], resolutions["3-h"]])
			, new ResolutionRuler([resolutions["30-min"], resolutions["3-h"], resolutions["12-h"]])
			, new ResolutionRuler([resolutions["hour"], resolutions["3-h"], resolutions["12-h"]])
			, new ResolutionRuler([resolutions["3-h"], resolutions["day"], resolutions["week"]])
			, new ResolutionRuler([resolutions["day"], resolutions["week"], resolutions["month"]])
			, new ResolutionRuler([resolutions["month"]])
		];
	}
	, format: {
		value: value => ox.time.format.iso(new Date(value))
		, extent: extent => `[${ox.time.format.iso(new Date(extent.start))}, ${ox.time.format.iso(new Date(extent.end))}]`
		, resolution: resolution => String(Math.round(resolution / ox.time.ms.hour * 100) / 100)
	}
};

class ScaleHandle {

	constructor(scale, root) {
		this.scale = scale;
		this.dom = this.attach(root);
	};

	/* getPosition() {} */

	/* invalidatePosition() {} */

	activable(pointerAt) {
		const position = this.getPosition();
		return position.start <= pointerAt && pointerAt <= position.end;
	}

	activate(active = true) {
		if (active) this.dom.classList.add('hovered');
		else {
			this.started = false;
			this.dom.classList.remove('hovered');
		}
	}

	isRunning() {
		return this.running;
	}

	on() {
		this.dom.classList.add('on');
		this.running = true;
		this.timeout = 400;
	}

	off() {
		this.dom.classList.remove('on');
		this.setError(false);
		this.running = this.expanding = null;
		this.pointerAt = this.pointerOffset = null;
	}

	scaleChanged() {
		this.invalidatePosition();
	}

	rangeChanged() {
	}

	currentChanged() {
	}

	expandExtent(direction, expandStep, extent) {
		if (this.expanding == null) {
			let extent = this.scale.extent;
			for (let step = 0; step < expandStep; ++step) {
				if (0 < direction) {
					extent = this.scale.alignExtent({start: extent.start, end: extent.end + 1});
				} else {
					extent = this.scale.alignExtent({start: extent.start - 1, end: extent.end});
				}
			}
			this.expanding = expandStep;
			this.scale.rollTo(extent);
			// this.scaleSettled();
		}
	}

	scaleSettled() {
		if (this.running) {
			ox.after(this.timeout, () => {
				const maxStep = this.scale.options.maxExpantStep;
				let step = this.expanding;
				if (this.expanding < maxStep) step = step * 2;
				this.expanding = null;
				this.updateFromPointer(step);
			});
		}
	}

	scaleTxChanged() {
		this.updateFromPointer();
	}

	updateFromPointer(expandStep) {
		if (this.pointerAt != null) {
			const pointerAt = this.displayLeft + this.pointerAt - this.scale.dom.display.body.getBoundingClientRect().left;
			this.update(this.value(pointerAt), expandStep);
		}
	}

	pointerMoved(displayLeft, pointerAt) {
		let pointerOffset = this.pointerOffset;
		if (pointerOffset == null) pointerOffset = this.pointerOffset = this.anchor() - pointerAt;
		this.displayLeft = displayLeft;
		this.pointerAt = pointerAt += pointerOffset;
		this.update(this.value(this.pointerAt));
	}

	value(pointerAt) {
		const tx = this.scale.displayTx();
		return tx.value(pointerAt / this.scale.dom.display.body.offsetWidth);
	}

	setError(error = true) {
		if (this.error != error) {
			error ? this.dom.classList.add('error') : this.dom.classList.remove('error');
			this.error = error;
		}
	}
}

class ScaleStartHandle extends ScaleHandle {

	attach(range) {
		return range.appendChild(ox.dom.element("div", "handle", "start"));
	}

	getPosition() {
		const position = this.position;
		if (position) return position;
		const tx = this.scale.displayTx();
		const start = this.scale.dom.display.body.offsetWidth * tx.fraction(this.scale.range.start) - this.scale.options.handleWidth;
		return this.position = {start, end: start + this.scale.options.handleWidth * 1.3};
	}

	invalidatePosition() {
		this.position = null;
	}

	anchor() {
		return this.getPosition().start + this.scale.options.handleWidth;
	}

	rangeChanged(silent) {
		this.position = null;
	}

	update(value, expandStep = 1) {
		const scale = this.scale;
		const range = scale.range;
		const minimum = range.end - scale.options.maxInterval;
		const maximum = range.end - scale.options.minInterval;
		if (value < scale.options.minDate) {
			value = scale.options.minDate;
			this.setError();
		} else if (value < minimum) {
			value = minimum;
			this.setError();
		} else if (maximum < value) {
			value = maximum;
			this.setError();
		} else this.setError(false);
		const aperture = scale.aperture();
		if (value < aperture.start) {
			this.expandExtent(-1, expandStep, scale.extent);
			value = aperture.start;
		}
		scale.updateRange({start: value, end: range.end});
	}
}

class ScaleEndHandle extends ScaleHandle {

	attach(range) {
		return range.appendChild(ox.dom.element("div", "handle", "end"));
	}

	getPosition() {
		const position = this.position;
		if (position) return position;
		const tx = this.scale.displayTx();
		const end = this.scale.dom.display.body.offsetWidth * tx.fraction(this.scale.range.end) + this.scale.options.handleWidth;
		return this.position = {start: end - this.scale.options.handleWidth * 1.3, end};
	}

	invalidatePosition() {
		this.position = null;
	}

	anchor() {
		return this.getPosition().end - this.scale.options.handleWidth;
	}

	scaleChanged() {
		this.position = null;
	}

	rangeChanged(silent) {
		this.position = null;
	}

	update(value, expandStep = 1) {
		const scale = this.scale;
		const range = scale.range;
		const minimum = range.start + scale.options.minInterval;
		const maximum = range.start + scale.options.maxInterval;
		if (value > scale.options.maxDate) {
			value = scale.options.maxDate;
			this.setError();
		} else if (value < minimum) {
			value = minimum;
			this.setError();
		} else if (maximum < value) {
			value = maximum;
			this.setError();
		} else this.setError(false);
		const aperture = scale.aperture();
		if (aperture.end < value) {
			this.expandExtent(1, expandStep, scale.extent);
			value = aperture.end;
		}
		scale.updateRange({start: range.start, end: value});
	}
}

class ScaleCurrentHandle extends ScaleHandle {

	attach(display) {
		display.current = display.body.appendChild(ox.dom.element("div", "current"));
		return display.current.appendChild(ox.dom.element("div", "handle"));
	}

	getPosition() {
		const position = this.position;
		if (position) return position;
		const scale = this.scale; // , range = scale.range;
		const handleWidth = scale.options.handleWidth;
		const tx = scale.displayTx();
		const current = scale.dom.display.body.offsetWidth * tx.fraction(scale.current);
		return this.position = {start: current - handleWidth, end: current + handleWidth};
	}

	invalidatePosition() {
		this.position = null;
	}

	anchor() {
		return this.getPosition().end - this.scale.options.handleWidth;
	}

	scaleChanged() {
		this.position = null;
	}

	rangeChanged(silent) {
		const scale = this.scale;
		if (!silent && scale.range.end < scale.current < scale.range.start) this.update(scale.current);
	}

	currentChanged() {
		this.position = null;
	}

	update(value, expandStep = 1) {
		const scale = this.scale, range = scale.range;
		const maximum = range.end, minimum = range.start;
		if (value < minimum) {
			value = minimum;
			this.setError();
		} else if (maximum < value) {
			value = maximum;
			this.setError();
		} else this.setError(false);
		scale.updateCurrent(value);
	}

}

// --------------------------------------------

class RangeScale extends AbstractScale {

	constructor(root, parameters) {
		if (!parameters) parameters = {};
		parameters.options = Object.assign({}, rangeScaleFallbacks.options, parameters.options);
		parameters.format = Object.assign({}, rangeScaleFallbacks.format, parameters.format);
		parameters.rulers = parameters.rulers ? parameters.rulers : rangeScaleFallbacks.rulers;
		super(root, parameters);
	}

	attachDisplay(display) {
		display.range = display.body.appendChild(ox.dom.element("div", "range"));
		this.rangeHandles = [
			new ScaleStartHandle(this, display.range),
			new ScaleCurrentHandle(this, display),
			new ScaleEndHandle(this, display.range)
		];
		window.addEventListener('resize', this.updateScale.bind(this));
		window.addEventListener('resize', this.handleScaleChanged.bind(this));
		window.addEventListener('mousemove', this.moveHandler.bind(this));
		window.addEventListener('mouseup', this.mouseUpHandler.bind(this));
		display.body.addEventListener('mouseleave', this.mouseLeaveHandler.bind(this));
		display.body.addEventListener('touchmove', this.touchMoveHandler.bind(this));
		display.body.addEventListener('touchstart', this.touchStartHandler.bind(this));
		display.body.addEventListener('touchend', this.touchEndHandler.bind(this));
		display.body.addEventListener('touchcancel', this.touchEndHandler.bind(this));
		display.body.addEventListener('mousedown', this.mouseDownHandler.bind(this));
	}

	initializeExtent(parameters) {
	}

	initializeCurrent(parameters) {
	}

	rangeExtent(range) {
		const margin = Math.round((range.end - range.start) * this.options.rangeMargin);
		return this.alignExtent({start: range.start - margin, end: range.end + margin});
	}

	mouseDownHandler(event) {
		if (this.activeHandle) {
			event.preventDefault();
			this.dom.display.body.classList.add('interacting');
			this.activeHandle.on();
		}
	}

	mouseUpHandler(event) {
		const active = this.activeHandle;
		if (active) {
			const offset = event.clientX - this.dom.display.body.getBoundingClientRect().left;
			if (!active.activable(offset)) {
				active.activate(false);
			}
			this.dom.display.body.classList.remove('interacting');
			active.off();
		}
		const margin = Math.round((this.range.end - this.range.start) * this.options.rangeMargin);
		this.rollTo({start: this.range.start - margin, end: this.range.end + margin});
	}

	mouseLeaveHandler(event) {
		const active = this.activeHandle;
		if (active && !active.isRunning()) {
			active.activate(false);
			this.activeHandle = null;
		}
	}

	moveHandler(event) {
		const active = this.activeHandle;
		const display = this.dom.display;
		const displayLeft = display.body.getBoundingClientRect().left;
		const pointerAt = event.clientX - displayLeft;
		if (active) {
			if (active.isRunning()) {
				active.pointerMoved(displayLeft, pointerAt);
			} else if (!active.activable(pointerAt)) {
				active.activate(false);
				this.activeHandle = null;
			}
		} else if (event.target == display.body) {
			const activables = this.rangeHandles.filter(handle => handle.activable(pointerAt));
			if (activables.length == 0) return;
			let activable = activables[0];
			if (1 < activables.length) {
				let distance = Math.abs(pointerAt - activable.anchor());
				for (let i = 1; i < activables.length; i++) {
					const handleDistance = Math.abs(pointerAt - activables[i].anchor());
					if (handleDistance < distance) {
						activable = activables[i];
						distance = handleDistance;
					}
				}
			}
			activable.activate();
			this.activeHandle = activable;
		}
	}

	touchStartHandler(event) {
		if (event.touches.length == 1) {
			const display = this.dom.display;
			const displayLeft = display.body.getBoundingClientRect().left;
			const pointerAt = event.touches[0].pageX - displayLeft;
			const activables = this.rangeHandles.filter(handle => handle.activable(pointerAt));
			if (activables.length == 0) return;
			let activable = activables[0];
			if (1 < activables.length) {
				let distance = Math.abs(pointerAt - activable.anchor());
				for (let i = 1; i < activables.length; i++) {
					const handleDistance = Math.abs(pointerAt - activables[i].anchor());
					if (handleDistance < distance) {
						activable = activables[i];
						distance = handleDistance;
					}
				}
			}
			this.dom.display.body.classList.add('interacting');
			activable.activate();
			activable.on();
			this.activeHandle = activable;
		}
	}

	touchMoveHandler(event) {
		if (event.touches.length == 1) {
			const display = this.dom.display;
			const displayLeft = display.body.getBoundingClientRect().left;
			const pointerAt = event.touches[0].pageX - displayLeft;
			const active = this.activeHandle;
			if (active && active.isRunning()) {
				active.pointerMoved(displayLeft, pointerAt);
			}
		}
	}

	touchEndHandler(event) {
		const active = this.activeHandle;
		if (active) {
			this.dom.display.body.classList.remove('interacting');
			active.activate(false);
			active.off();
			this.activeHandle = null;
		}
		this.rollTo(this.rangeExtent(this.range));
	}

	handleScaleTransformed() {
		if (this.activeHandle) this.activeHandle.scaleTxChanged();
	}

	handleScaleSettled() {
		if (this.activeHandle) this.activeHandle.scaleSettled();
	}

	handleScaleChanged() {
		this.updatePositions();
		this.rangeHandles.forEach(handle => handle.scaleChanged());
	}

	handleScaleClicked(event) {
		const displayLeft = this.dom.display.body.getBoundingClientRect().left;
		const pointerAt = event.clientX - displayLeft;
		const tx = this.displayTx();
		this.updateCurrent(tx.value(pointerAt / this.dom.display.body.offsetWidth));
	}

	updatePositions() {
		this.updateRangePosition();
		this.updateCurrentPosition();
	}

	updateRange(range, silent) {
		if (range && range != this.range) {
			this.range = range;
			this.rangeHandles.forEach(handle => handle.rangeChanged(silent));
			// ox.log.trace(() => `range: ${this.format.extent(this.range)}`);
		}
		this.updatePositions();
	}

	updateCurrent(current) {
		if (this.current != current) {
			if (current < this.range.start) {
				this.current = this.range.start;
			} else if (this.range.end < current) {
				this.current = this.range.end;
			} else {
				this.current = current;
			}
			this.rangeHandles.forEach(handle => handle.currentChanged());
			this.updateCurrentPosition();
		}
	}

	updateRangePosition() {
		const tx = this.displayTx();
		this.dom.display.range.style.left = tx.fraction(this.range.start) * 100 + "%";
		this.dom.display.range.style.right = `calc(${(1 - tx.fraction(this.range.end)) * 100}% - 1px)`;
	}

	updateCurrentPosition() {
		const tx = this.displayTx();
		this.dom.display.current.style.left = tx.fraction(this.current) * 100 + "%";
	}

	// scale.rollTo({start: scale.extent.start - 1 * ox.time.ms.hour, end: scale.extent.end});
}

export class TimeRangeScale extends RangeScale {

	constructor(root, parameters) {
		if (!parameters) parameters = {};
		parameters.options = Object.assign({}, rangeScaleFallbacks.options, parameters.options);
		parameters.format = Object.assign({}, rangeScaleFallbacks.format, parameters.format);
		parameters.rulers = parameters.rulers ? parameters.rulers : rangeScaleFallbacks.rulers;
		super(root, parameters);
	}

	initializeExtent(parameters) {
		const interval = parameters.interval || rangeScaleFallbacks.interval;
		let start, end;
		if (parameters && parameters.start) {
			start = parameters.start.getTime();
			if (!parameters.end) end = start + interval;
			else {
				end = parameters.end.getTime();
				if (end < start) throw new Error("Invalid range requested");
			}
		} else {
			end = parameters && parameters.end ? parameters.end.getTime() : Date.now();
			start = end - interval;
		}
		this.range = {start, end};
		// ox.log.trace(() => `initial range: (${this.format.extent(this.range)}`);
		return this.rangeExtent(this.range);
	}

	initializeCurrent(parameters) {
		let current;
		const range = this.range;
		if (parameters && parameters.current) {
			current = parameters.current.getTime();
		} else if (range) {
			const half = (range.end - range.start) / 2;
			current = range.end - half;
		} else current = Date.now();
		return current;
	}

	updateRange(range, silent) {
		super.updateRange(range, silent);
		if (!silent && this.handlers && this.handlers.onChange) {
			this.handlers.onChange(this.range.start, this.range.end, this.current);
		}
	}

	updateCurrent(current, silent) {
		super.updateCurrent(current);
		if (!silent && this.handlers && this.handlers.onChange) {
			this.handlers.onChange(this.range.start, this.range.end, this.current);
		}
	}

	setHandlers(handlers) {
		this.handlers = handlers;
	}

	setRange(start, end) {
		start = start.getTime(); end = end.getTime();
		if (start != this.range.start || end != this.range.end) {
			if (this.current < start || this.current > end) this.updateCurrent(start + (end - start) / 2, true);
			this.updateRange({ start, end }, true);
			this.rollTo(this.rangeExtent(this.range));
		}
	}

	setCurrent(current) {
		current = current.getTime();
		if (current != this.current) this.updateCurrent(current, true);
	}
}

// --------------------------------------------

class RangeScaleInfoLine {

	constructor(scale, root) {
		this.scale = scale;
		this.dom = this.attach(root);
	}

	attach(range) {
		this.dom = {};
		this.dom.body = this.body = ox.dom.element("div", "info-line");
		range.insertBefore(this.body, range.childNodes[0]);
		return this.body;
	}

	setRanges(ranges) {
		this.ranges = ranges;
		this.update();
	}

	update() {
		ox.dom.purge(this.body);
		this.dom.ranges = [];
		const tx = this.scale.displayTx();
		const frameWidth = this.scale.dom.display.body.clientWidth;
		const offsetLeft = tx.fraction(this.scale.range.start) * frameWidth;
		if (this.ranges) this.ranges.forEach(range => {
			const element = this.body.appendChild(ox.dom.element("div", "range"));
			const since = range.since < this.scale.range.start ? this.scale.range.start : range.since;
			const until = range.until > this.scale.range.end ? this.scale.range.end : range.until;
			element.style.left = (tx.fraction(since) * frameWidth - offsetLeft) + "px";
			element.style.width = (tx.fraction(until) - tx.fraction(since)) * frameWidth + "px";
			this.dom.ranges.push(element);
		});
	}
}

class RangeScaleUriLines extends React.Component {

	constructor(props) {
		super(props);
		this.scale = props.scale;
	}

	render() {
		const uriLines = Object.keys(this.props.uriEventMap).map(uri => {
			return (
				<div className="uri-line" key={uri}>
					{this.props.uriEventMap[uri].map(event => {
						const tx = this.scale.displayTx();
						const frameWidth = this.scale.dom.display.body.clientWidth;
						const offsetLeft = tx.fraction(this.scale.range.start) * frameWidth;
						const left = (tx.fraction(event.generatedAt.getTime()) * frameWidth - offsetLeft - 4) + "px";
						const onClick = () => {
							if (this.props.onClick) this.props.onClick(event);
						}
						return (
							<EventTick
								key={event.eventId}
								event={event}
								uri={uri}
								incident={event.incident}
								left={left}
								onClick={onClick}
							/>
						)
					})}
				</div>
			);
		});

		return (
			<Provider store={store}>
				{uriLines}
			</Provider>
		);
	}
}

class RangeScaleEventsLine {

	constructor(scale, root) {
		this.scale = scale;
		this.dom = this.attach(root);
	}

	attach(range) {
		this.dom = {};
		this.dom.body = this.body = ox.dom.element("div", "events-line");
		range.appendChild(this.body);
		return this.body;
	}

	setEvents(uriEventMap) {
		this.uriEventMap = uriEventMap;
		this.update();
	}

	update() {
		const onClick = (event) => {
			this.scale.handlers.onChange(this.scale.range.start, this.scale.range.end, event.generatedAt.getTime());
		}
		// TODO remove all react code from this file and redesign it
		this.uriLines = <RangeScaleUriLines onClick={onClick} scale={this.scale} uriEventMap={this.uriEventMap} />
		ReactDOM.render(this.uriLines, this.body);
	}
}

export class TimeMachineRangeScale extends TimeRangeScale {

	setLoadedRanges(ranges) {
		this.infoLine.setRanges(ranges);
	}

	setLoadedEvents(uriEventMap) {
		this.eventsLine.setEvents(uriEventMap);
	}

	attachDisplay(display) {
		super.attachDisplay(display);
		this.infoLine = new RangeScaleInfoLine(this, display.range);
		this.eventsLine = new RangeScaleEventsLine(this, display.range);
	}

	updatePositions() {
		super.updatePositions();
		this.infoLine.update();
		this.eventsLine.update();
	}
}
