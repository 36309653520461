import React from 'react';

import {useI18n} from '../../../../../i18n';

import {denominate as denominateDevices} from '../../../../api/device';

import DatetimeField from '../../../general/datetime/DatetimeField';

import './reportSummary.scss';


function ReportSummary({since, until, devices, generatedAt}) {
	const {f} = useI18n();

	const deviceDenomination = denominateDevices(devices);

	return (
		<div className="report-summary">
			<div className="generated">
				{f('generated at')} <DatetimeField datetime={generatedAt} full />
			</div>
			<div className="data">
				{since && until && <div className="time-range">
					<span className="label capitalize">{f('period')}:</span>
					<span>{f('from')}&nbsp;<DatetimeField datetime={since} full />,</span>
					<span>{f('to')}&nbsp;<DatetimeField datetime={until} now={since} />;</span>
				</div>}
				{deviceDenomination && <div className="fleet">
					<span className="label capitalize">{f(devices.length == 1 ? 'device' : 'devices')}:</span>
					<span> {deviceDenomination}</span>
				</div>}
			</div>
		</div>
	);
}

export default ReportSummary;
