import React, { FC } from 'react';
import { Row } from 'react-table';

import { CellStyled, TableRowStyled } from 'ui/table.styled';
import { ICell } from 'types/table';

interface ITableRowProps {
  index: number;
  row: Row<Record<string, unknown>>;
}

export const TableRow: FC<ITableRowProps> = ({ index, row }) => {
  const getCellProps = ({ getCellProps, column }: ICell) => ({
    ...getCellProps([{ className: column?.className, style: { ...(column.isFlexUnset && { flex: 'unset' }) } }]),
  });

  return (
    <TableRowStyled isEven={index % 2 === 0} {...row.getRowProps()}>
      {row.cells.map((cell: ICell) => (
        <div {...getCellProps(cell)}>
          {cell.column.withoutCellWrapper ? cell.render('Cell') : <CellStyled>{cell.render('Cell')}</CellStyled>}
        </div>
      ))}
    </TableRowStyled>
  );
};
