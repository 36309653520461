import { actions } from '../actions';
import { reduxSwitch } from '../../../tools';

const defaultState = {
	preview: null,
	list: null,
	uri: null,
	parameters: null,
	hasMore: null,
	pending: false,
	error: null,
	// export
	exporting: false,
	progress: null, // { percent, done, total }
	csv: null,
};

const updateAnchor = (parameters, temperatures) => {
	if (temperatures && temperatures.length > 0) {
		let temperature = temperatures[temperatures.length-1];
		let anchorAt = parameters.timeRange.retrospective
			? temperature.since
			: temperature.until
		;
		parameters.timeRange.window.anchorAt = anchorAt;
	}
	return parameters;
}

function apiReducer(state = defaultState, action) {
	let _state;
	switch (action.type) {
		case actions.temperatureHistory.request.type:
			if (action.clear) {
				_state = { ...defaultState };
			} else {
				_state = {...state};
			}
			return {
				..._state,
				uri: action.uri,
				parameters: action.parameters,
				pending: true,
				error: null,
				at: action.clear ? new Date() : _state.at
			};
		case actions.temperatureHistory.success.type:
			const temperatures = action.temperatures;
			const batchSize = state.parameters.timeRange.window.size;
			const hasMore = temperatures ? temperatures.length == batchSize : false;
			const list = state.list ? (temperatures ? state.list.concat(temperatures) : state.list) : (temperatures ? [...temperatures] : []);
			_state = {...state};
			return {
				..._state,
				list,
				hasMore,
				parameters: updateAnchor(state.parameters, temperatures),
				pending: false
			};
		case actions.temperatureHistory.fail.type:
			return {
				...state,
				pending: false,
				error: action.errorMessage
			};
		case actions.temperatureHistory.cancel.type:
			return {
				...state,
				pending: false
			};
		default:
			return state;
	}
}

function exportReducer(state, action) {
	switch (action.type) {
		case actions.temperatureHistory.export.type:
			return {
				...state,
				exporting: true,
				progress: null,
				csv: null
			}
		case actions.temperatureHistory.exportProgress.type:
			return {
				...state,
				progress: {
					percent: action.progress,
					done: action.count,
					total: action.total
				}
			}
		case actions.temperatureHistory.exportDone.type:
			return {
				...state,
				parameters: state.parameters,
				exporting: false,
				csv: action.csv
			}
		case actions.temperatureHistory.exportClear.type:
			return {
				...state,
				csv: null
			}
		default:
			return state;
	}
}

const reducer = reduxSwitch([apiReducer, exportReducer], defaultState);

export { reducer };
