import React, { useState, useEffect } from 'react';
import { useI18n } from '../../../../i18n';
import Form from '../form/Form';

/**
 * @param {Object} props
 * @param {string} [props.title]
 * @param {string} [props.defaultTitle]
 */

function WidgetTitleConfiguration(props) {
	const { f } = useI18n();
	const [value, setValue] = useState(props.title);

	useEffect(() => {
		props.title ? setValue(props.title) : setValue('');
	}, [props.title]);

	return (
		<div className="widget-title-configuration">
			<Form.Control
				controlType={Form.Control.Type.Input}
				controlName="title"
				label={f('title')}
				value={value}
				onChange={setValue}
				placeholder={props.defaultTitle}
				autoFocus
				cleanable
			/>
		</div>
	);
}

export default WidgetTitleConfiguration;