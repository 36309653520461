import React, { useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { className } from '../../../../lib/className';
import { withRouter } from 'react-router-dom';
import Loader from '../../../general/Loader';
import { useI18n } from '../../../../../i18n';
import Address from '../../../general/location/Address';
import DatetimeField from '../../../general/datetime/DatetimeField';
import ScrollList from '../../../general/list/ScrollList';
import EventField from '../../../share/events/EventField';
import ReportSummary from './ReportSummary';
import ReportHeader from './ReportHeader';
import { formatter } from '../../../../misc/datetime';
import { scrollReportItemIntoView } from '../../../../misc/misc';
import { actions } from '../../../../redux/api/reports';
import SwitchMapMenuItem from './SwitchMapMenuItem';
import CheckLocationMap from './CheckLocationMap';
import NoDataView from '../../../general/view/NoDataView';
import './locationHistory.scss';

function HeaderListItem({withEvent}) {
	const { f } = useI18n();
	return (
		<>
			<div className="header-item"><span className="capitalize">{f('item No.')}</span></div>
			<div className="header-item"><span className="capitalize">{f('start time')}</span></div>
			<div className="header-item"><span className="capitalize">{f('end time')}</span></div>
			<div className="header-item"><span className="capitalize">{f('duration')}</span></div>
			<div className="header-item"><span className="capitalize">{f('location')}</span></div>
			<div className="header-item"><span className="capitalize">{f('range') + ', ' + f('units.m')}</span></div>
			<div className="header-item"><span className="capitalize">{f('quantity')}</span></div>
			{withEvent && <div className="header-item"><span className="capitalize">{f('event')}</span></div>}
		</>
	)
}

/**
 * @param {Object} props
 * @param {cx.ods.reports.HistoryLocation} props.location
 * @param {number} props.index
 * @param {boolean} props.withEvent
 * @param {function} props.onClick
 * @param {boolean} props.selected
 * @param {React.Ref} [props.customRef]
 */

function ReportListItem(props) {

	const onClick = () => {
		props.onClick(props.location, props.index);
	}

	const _className = className('clickable', { 'selected': props.selected });
	return (
		<>
			<div ref={props.customRef} className={_className} onClick={onClick}>{props.index}</div>
			<div className={_className} onClick={onClick}><DatetimeField datetime={props.location.since} /></div>
			<div className={_className} onClick={onClick}><DatetimeField datetime={props.location.upTo} now={props.location.since} /></div>
			<div className={_className} onClick={onClick}>{formatter.duration(props.location.since, props.location.upTo)}</div>
			<div className={_className} onClick={onClick}><Address message={props.location} full /></div>
			<div className={_className} onClick={onClick}>{props.location.areaSize}</div>
			<div className={_className} onClick={onClick}>{props.location.quantity}</div>
			{props.withEvent && (props.location.event
				? <EventField event={props.location.event} className={_className} onClick={onClick} />
				: <div className={_className} onClick={onClick}>-</div>
			)}
		</>
	)
}

function EodListItem() {
	const { f } = useI18n();
	return <div className="indicator"><span className="capitalize">{f('you have reached the end')}</span></div>;
}

// -----------------------------------------------------------------

function LocationHistory(props) {
	const { f } = useI18n();
	const selectedItemBox = useRef();
	const [displayMap, setDisplayMap] = useState(false);
	const [lastSelectedLocation, setLastSelectedLocation] = useState({});
	const [selectedLocations, setSelectedLocations] = useState({});
	const withEvents = !!props.state.parameters.events;
	const uri = props.state.uri;
	const device = props.deviceMap[uri];

	useLayoutEffect(() => {
		if (selectedItemBox.current) {
			scrollReportItemIntoView(selectedItemBox.current);
		}
	}, [lastSelectedLocation.at]);

	const setNewLastSelectedLocation = (location) => {
		setLastSelectedLocation({ at: new Date(), location });
	}
	const loadNext = () => {
		const parameters = props.state.parameters;
		props.dispatch(actions.locationHistory.request({ uri, parameters }));
	}

	const onItemClick = (location, at) => {
		if (selectedLocations[at]) {
			delete selectedLocations[at];
		} else {
			selectedLocations[at] = location;
		}
		setSelectedLocations({...selectedLocations});
		setNewLastSelectedLocation(location);
		if (!displayMap) setDisplayMap(true);
	}

	const onMapSwitch = () => {
		setDisplayMap(!displayMap);
	}

	let content = null;
	let mapBox = null;
	let canExport = false;
	if (props.state.list != null) {
		if (!props.state.list || props.state.list.length == 0) {
			content = <NoDataView message={f('no data for such criteria')} />;
		} else {
			canExport = true;
			let items = props.state.list.map((location, at) => {
				return (
					<ReportListItem
						key={at}
						location={location}
						onClick={onItemClick}
						selected={selectedLocations[at+1] == location}
						customRef={lastSelectedLocation.location == location ? selectedItemBox : undefined}
						index={at+1}
						withEvent={withEvents}
					/>);
			});
			items.unshift(<HeaderListItem key={1000000} withEvent={withEvents} />);
			content = <ScrollList
				pending={props.state.pending}
				next={loadNext}
				isEod={!props.state.hasMore}
				eodItem={EodListItem}
			>
				{items}
			</ScrollList>;
			if (displayMap) {
				mapBox = (<CheckLocationMap onClick={setNewLastSelectedLocation} locations={selectedLocations} device={device} />);
			}
		}
	} else {
		content = (
			<div className="center">
				{
					props.state.error
						? <span className="error">{props.state.error}</span>
						: <Loader size={Loader.Size.MD} />
				}
			</div>
		);
	}

	const exportReport = () => {
		props.dispatch(actions.locationHistory.export({ uri }));
		props.history.goBack();
	}

	const timeRange = props.state.parameters.timeRange;
	return (
		<div className={"report location-history" + (withEvents ? " events" : " base")}>
			<ReportHeader
				title={f('location history report')}
				canExport={canExport}
				onExport={exportReport}
				items={<SwitchMapMenuItem disabled={!canExport} active={displayMap} onClick={onMapSwitch} />}
			/>
			<ReportSummary
				since={timeRange.since}
				until={timeRange.until}
				devices={[device]}
				generatedAt={props.state.at}
			/>
			<div className={className("content", { 'with-map': displayMap })}>
				{content}
				{mapBox}
			</div>
		</div>
	)
}

export default withRouter(connect(state => {
	return ({
		state: state.reports.locationHistory,
		deviceMap: state.devices.map
	})
})(LocationHistory));
