import React from 'react';
import { className, fromProps } from '../../../lib/className';
import MenuItem from './MenuItem';
import './menu.scss';

/**
 * @enum {string}
 */

const Alignment = {
	LEFT: 'left',
	RIGHT: 'right',
	CENTER: 'center'
}

/**
 * @param {Object} props
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.horizontal]
 * @param {Alignment} [props.align]
 */

function Menu(props) {

	const renderChildren = () => {
		return React.Children.map(props.children, child => {
			return child && props.disabled !== undefined
				? React.cloneElement(child, { disabled: props.disabled })
				: child
			;
		});
	}

	return (
		<div className={className('menu', fromProps(props), { 'horizontal': props.horizontal }, props.align || Alignment.LEFT)}>
			{renderChildren()}
		</div>
	)
}

Menu.Item = MenuItem;
Menu.Alignment = Alignment;

export default Menu;