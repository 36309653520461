import React from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import DeviceReferenceFieldView from '../message/DeviceReferenceFieldView';

/**
 * @param {Object} props
 * @param {cx.ods.devices.EventDetails} props.event
 */

const MNEMONICS = 'encounter';

function PeerEncounterEventMessageField(props) {
	const { f } = useI18n();
	let content = null;

	if (props.messageFields && props.attributeTypes && props.event.message && props.event.message.fields) {
		const peerField = props.messageFields.nameMap['peer'];
		const fieldValue = props.event.message.fields.map()[peerField.fieldId];
		if (fieldValue) content = (
			<DeviceReferenceFieldView
				fieldName={peerField.name}
				value={fieldValue}
				title={f({ prefix: 'message-field', id: peerField.description })}
			/>
		);
	}

	return (content);
}

PeerEncounterEventMessageField.mnemonics = MNEMONICS;

export default connect(
	state => ({
		messageFields: state.registry.messageFields,
		attributeTypes: state.registry.attributeTypes
	})
)(PeerEncounterEventMessageField);