import React from 'react';
import { Avatar } from 'rsuite';
import DeviceAssetTypeIcon from './DeviceAssetTypeIcon';
import { IconSize } from '../../../img';
import { DeviceDetailsProxy } from '../../../api/device'; // eslint-disable-line
import "./deviceShortInfoView.scss";

/**
 * @param {Object} props
 * @param {DeviceDetailsProxy} props.device
 */

function DeviceShortInfoView(props) {
	const device = props.device;
	return (
		<div className="device-short-info-view">
			<Avatar circle>
				<DeviceAssetTypeIcon size={IconSize.MD} categoryIds={device.categoryIds} />
			</Avatar>
			<div className="main">
				<div className="device-name">{device.denomination()}</div>
			</div>
		</div>
	)
}

export default DeviceShortInfoView;