import React, { useImperativeHandle, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import * as widgets from '../../../../../redux/app/widgets';
import { useI18n } from '../../../../../../i18n';
import ActionLockedDevices from '../../../../general/widgets/ActionLockedDevices';
import WidgetMenuItem from '../../../../general/widgets/WidgetMenuItem';
import WidgetErrorContent from '../../../../general/widgets/WidgetErrorContent';
import DeviceEventsConfiguration from './DeviceEventsConfiguration';
import FilterInfoView from './FilterInfoView';
import DeviceEventList from './DeviceEventList';
import ActionLock from '../../../../share/actionbar/ActionLock';
import './deviceEventsWidget.scss';

/**
 * Widget showing current device events.
 * @param {Object} props
 * @param {string} props.uid
 * @param {string} props.reduxKey
 * @param {React.RefObject} props.customRef
 * @param {function} props.update to rerender WidgetContainer
 */

const PIN_ACTIONS = { lock: 'lockDevices' };

function DeviceEventsWidget(props) {
	const { f, fc } = useI18n();
	const expandable = useRef(props.uris.length > 0);

	const lockHandler = () => {
		props.dispatch(widgets.actions.update({
			domain: props.reduxKey,
			uid: props.uid,
			data: { uris: props.locked ? null : props.uris }
		}));
	}

	useLayoutEffect(() => {
		props.update();
	}, [props.locked, props.widgetData, props.selection, props.eventsScopeMap, props.eventTypeMap, props.deviceMap]);

	const setPinnedActions = (actionName, pinned) => {
		const pinnedActions = props.widgetData && props.widgetData.pinnedActions ? { ...props.widgetData.pinnedActions } : {};
		pinnedActions[actionName] = pinned;
		props.dispatch(widgets.actions.update({  domain: props.reduxKey, uid: props.uid, data: { pinnedActions } }));
	}

	useImperativeHandle(props.customRef, () => ({
		title: () => fc('device events'),
		dynamicContent: true,
		actions: (onChange) => {
			const actions = [];
			if (props.locked) {
				actions.push(
					<ActionLockedDevices
						onChange={onChange}
						key="action-uris"
						uris={props.widgetData.uris}
						disabledUris={props.disabledUris}
						emptyMessage={fc('all devices')}
					/>
				);
			}
			if (props.widgetData && props.widgetData.pinnedActions) {
				if (props.widgetData.pinnedActions[PIN_ACTIONS.lock]) {
					actions.push(
						<ActionLock
							key={PIN_ACTIONS.lock}
							disabled={props.uris.length == 0}
							onClick={lockHandler}
							active={props.locked}
							title={fc(props.locked ? 'unlock' : 'lock')}
						/>
					);
				}
			}
			return actions;
		},
		configuration: () => {
			return (
				<DeviceEventsConfiguration
					categoryIds={props.widgetData && props.widgetData.categoryIds}
					eventTypes={props.widgetData && props.widgetData.eventTypes}
				/>
			);
		},
		canPin: props.locked || props.uris.length > 0,
		handlePin: lockHandler,
		menuItems: () => {
			return <WidgetMenuItem
				disabled={!props.locked && props.uris.length == 0}
				onClick={lockHandler}
				text={fc(props.locked ? 'unlock' : 'lock')}
				canPin
				pinned={props.widgetData && props.widgetData.pinnedActions && props.widgetData.pinnedActions[PIN_ACTIONS.lock]}
				onChangePin={(pinned) => setPinnedActions(PIN_ACTIONS.lock, pinned)}
			/>;
		},
		expandable: () => expandable.current
	}));

	const onLoadEvents = (value) => {
		if (expandable.current != (0 < value)) {
			expandable.current = (0 < value);
		}
		props.update();
	}

	let content = null;
	if (props.uris.length > 0) {
		content = (<>
			<FilterInfoView
				categoryIds={props.widgetData && props.widgetData.categoryIds}
				eventTypes={props.widgetData && props.widgetData.eventTypes}
			/>
			<DeviceEventList
				uris={props.uris}
				onLoad={onLoadEvents}
				categotyIdsFilter={props.widgetData && props.widgetData.categoryIds}
				eventTypesFilter={props.widgetData && props.widgetData.eventTypes}
			/>
		</>);
	} else {
		content = (<WidgetErrorContent message={f('please select device')} />);
	}

	return (
		<div className="device-events widget">
			{content}
		</div>
	);
}

export default connect((state, props) => {
	const widget = state.widgets.timeMachine.map[props.uid];
	const selection = state.pages.timeMachine.selection;
	const widgetData = widget && widget.data;
	const locked = !!(widgetData && widgetData.uris);
	const eventsScopeMap = state.history.events.map;
	let uris = locked  ? widgetData.uris : (selection ? selection : []);
	let disabledUris = null;
	if (locked) {
		uris = selection.filter(uri => widgetData.uris.includes(uri));
		disabledUris = widgetData.uris.filter(uri => !selection.includes(uri));
	}

	return {
		selection, uris, locked, widgetData, disabledUris, eventsScopeMap,
		eventTypeMap: state.registry.eventTypes.typeMap,
		eventTypeCategories: state.categories.eventTypes,
		deviceMap: state.devices.map,
		eventsFilter: state.appDeviceEvents.filter
	}
})(DeviceEventsWidget);
