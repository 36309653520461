import { ofType, combineEpics } from 'redux-observable';
import { switchMap, takeUntil, map as rxmap, filter, tap, ignoreElements } from 'rxjs/operators';
import { api, rx, cx } from "../../../../api";
import { resolver } from './resolver';
import { fc, f } from '../../../../../i18n';
import { actions } from '../actions';
import { formatDateTime, getTimezoneString, formatUTCTicksDuration } from '../../../../misc/misc';
import { getDeviceFuelLevelBalance } from '../../../../misc/fuelLevelBalance';
import { errorMap } from '../../../actions';

const requestEpic = (action$) => {
	return action$.pipe(
		ofType(actions.tripHistory.request.type),
		switchMap(action =>
			rx(api.reports.tripHistory, action.uri, action.parameters).pipe(
				rxmap(operation => actions.tripHistory.success({ report: operation.response() })),
				errorMap(actions.tripHistory.fail),
				takeUntil(action$.pipe(ofType(actions.tripHistory.cancel.type)))
			)
		)
	)
}

const previewEpic = (action$) => {
	return action$.pipe(
		ofType(actions.tripHistory.preview.request.type),
		switchMap(action =>
			rx(api.reports.tripHistory, action.uri, action.parameters, true).pipe(
				rxmap(operation => actions.tripHistory.preview.success({ preview: operation.response() })),
				errorMap(actions.tripHistory.preview.fail),
				takeUntil(action$.pipe(ofType(actions.tripHistory.preview.cancel.type)))
			)
		)
	)
}

const startPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.tripHistory.export.type),
		rxmap(action => {
			const state = state$.value.reports.tripHistory;
			resolver.resolve(state.list, !state.hasMore);
			if (state.hasMore) {
				return actions.tripHistory.request({ uri: action.uri, parameters: state.parameters });
			} else {
				return actions.tripHistory.success({});
			}
		})
	)
}

const processPrepareEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.tripHistory.success.type),
		filter(action => {
			const state = state$.value.reports.tripHistory;
			if (state.exporting) resolver.resolve(action.report && action.report.entries, !state.hasMore);
			return state.exporting && state.hasMore;
		}),
		rxmap(action => {
			const state = state$.value.reports.tripHistory;
			return actions.tripHistory.request({ uri: state.uri, parameters: state.parameters });
		})
	)
}

const exportCsvEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.tripHistory.exportProgress.type),
		filter(action => action.progress == 100),
		rxmap(action => {
			const state = state$.value.reports.tripHistory;
			const device = state$.value.devices.map[state.uri];
			const hasFuelLevel = Boolean(state$.value.reports.tripHistory.parameters.fuelLevel);
			// report header
			let csv = '"'
				+ fc('report type')
				+ '",'
				+ fc('generated')
				+ ','
				+ fc('timezone')
				+ ','
				+ fc('device')
				+ ','
				+ fc('since')
				+ ','
				+ fc('until')
				+ '\n';
			csv += fc('trip history');  // report type
			csv += ',' + formatDateTime(cx.now()); // generated at
			csv += ',' + getTimezoneString(); // timezone at
			csv += ',"' + (device.denomination() || device.uri) + '"';
			csv += ',"' + formatDateTime(state.parameters.timeRange.since) + '"';
			csv += ',"' + formatDateTime(state.parameters.timeRange.until) + '"';
			csv += "\n\n";
			// content header
			csv += '"'
				+ fc('start')
				+ '",'
				+ ','
				+ ',"'
				+ fc('end')
				+ '",'
				+ ', '
				+ ', '
				+ ', '
			;
			if (hasFuelLevel) {
				csv	+= ',"' + fc('fuel level balance') + ', ' + f({ prefix: 'units', id: 'l' }) + ' (' + f({ prefix: 'units', id: '%' }) + ')"';
			}
			csv += "\n";

			csv += '"'
				+ fc('time')
				+ '","'
				+ fc('location')
				+ '","'
				+ fc('address')
				+ '","'
				+ fc('time')
				+ '","'
				+ fc('location')
				+ '","'
				+ fc('address')
				+ '"'
			;

			csv += ',' + fc('duration') + ',"' + fc('distance') + ', ' + f('units.km') + '"';
			if (hasFuelLevel) {
				csv += ',"' + fc('consumed') + '"';
				csv += ',"' + fc('drained') + '"';
				csv += ',"' + fc('refueled') + '"';
			}
			csv += "\n";
			// content
			state.list.forEach(entry => {
				const isTrip = !!entry.trip;
				const start = isTrip ? entry.trip.startMessage : entry.startMessage;
				const last = isTrip ? entry.trip.lastMessage : entry.lastMessage;
				// start time
				csv += formatDateTime(start.generatedAt);
				// start location
				if (start != null && start.latitude != null && start.longitude != null) {
					csv += ',"(' + start.latitude + ";" + start.longitude + ')"';
					// start address
					const entryKey = resolver.key(start.latitude, start.longitude);
					const address = resolver.resolved[entryKey]?.getAddress();
					if (address) csv += ',"' + address.format() + '"';
					else csv += ",";
				} else {
					csv += ",,"
				}
				// end time
				csv += ',' + formatDateTime(last.generatedAt);
				// last location
				if (last != null && last.latitude != null && last.longitude != null) {
					csv += ',"(' + last.latitude + ";" + last.longitude + ')"';
					// last address
					const entryKey = resolver.key(last.latitude, last.longitude);
					const address = resolver.resolved[entryKey]?.getAddress();
					if (address) csv += ',"' + address.format() + '"';
					else csv += ",";
				} else {
					csv += ",,";
				}
				// duration
				csv += ',"' + formatUTCTicksDuration(start.generatedAt.getTime() - last.generatedAt.getTime()) + '"';
				// distance
				csv += "," + (entry.distance != null ? entry.distance / 1000 : "-") ;
				if (hasFuelLevel) {
					// fuel level balance
					const fuelLevelBalance = entry.fuelLevelBalance;
					// consumed
					csv += ',"' + (fuelLevelBalance ? getDeviceFuelLevelBalance(fuelLevelBalance.consumed, state.uri) : ' ') + '"';
					csv += ',"' + (fuelLevelBalance ? getDeviceFuelLevelBalance(fuelLevelBalance.drained, state.uri) : ' ') + '"';
					csv += ',"' + (fuelLevelBalance ? getDeviceFuelLevelBalance(fuelLevelBalance.refueled, state.uri) : ' ') + '"';
				}
				csv += "\n";
			});
			return actions.tripHistory.exportDone({ csv });
		}),
	)
}

const exportClearEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.tripHistory.exportClear.type),
		tap(() => resolver.clear()),
		ignoreElements()
	);
}

const epic = combineEpics(requestEpic, previewEpic, startPrepareEpic, processPrepareEpic, exportCsvEpic, exportClearEpic);

export { epic };
