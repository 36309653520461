import React from 'react';
import { className } from '../../lib/className';
import { Menu } from '@blueprintjs/core';
import { capitalize } from '../../misc/misc';
import { getLocaleIcon } from '../../img';
import './languageSelector.scss';

/**
 * @param {Object} props
 * @param {function} props.onClick
 * @param {string} props.active
 * @param {boolean} [props.hideIcon]
 */

function LanguageSelector(props) {
	const dataLanguages = [
		{ value: 'id', label: capitalize('bahasa Indonesia') },
		{ value: 'ru', label: capitalize('русский') },
		{ value: 'en', label: capitalize('english') },
		{ value: 'vi', label: capitalize('tiếng Việt') },
		{ value: 'th', label: capitalize('ไทย') },
		{ value: 'ar', label: capitalize('عربي') },
		{ value: 'zh-cn', label: capitalize('中文(简体)') }
	];
	const content = dataLanguages.map(language => {
		return <Menu.Item
			key={language.value}
			className={className('language-item', { 'active': props.active == language.value })}
			icon={!props.hideIcon && <img src={getLocaleIcon(language.value)} alt="" />}
			text={language.label}
			onClick={() => props.onClick(language.value)}
		/>
	});

	return (
		<Menu className="languages-menu">
			{content}
		</Menu>
	);
}

export default LanguageSelector;
