import React, { FC } from 'react';

import { EnumerationSelect } from './enumeration-select';
import { RangeFields } from './range-fields';

interface IAttributeAspectsFieldsProps {
  isDisabled: boolean;
  mnemonics: string;
}

export const AttributeAspectsFields: FC<IAttributeAspectsFieldsProps> = ({ mnemonics, isDisabled }) =>
  mnemonics === 'boolean' ? (
    <EnumerationSelect isDisabled={isDisabled} />
  ) : (
    <RangeFields mnemonics={mnemonics} isDisabled={isDisabled} />
  );
