import React, { DispatchWithoutAction, FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { FormButtonsStyled, FormButtonStyled } from 'ui/pages/issues/issues-actions-select.styled';
import { fc } from 'i18n';
import { IIssueActionForm } from 'hooks/issues/useIssueActionForm';

interface IFormButtonsProps {
  isLoading: boolean;
  onClose: DispatchWithoutAction;
  onSubmit: DispatchWithoutAction;
}

export const FormButtons: FC<IFormButtonsProps> = ({ isLoading, onClose, onSubmit }) => {
  const { control } = useFormContext<IIssueActionForm>();

  const resolutionTypeId = useWatch({ control, name: 'resolutionTypeId' });

  return (
    <FormButtonsStyled>
      <FormButtonStyled disabled={isLoading} type="button" onClick={onClose}>
        {fc('cancel')}
      </FormButtonStyled>
      <FormButtonStyled disabled={!resolutionTypeId || isLoading} type="button" onClick={onSubmit}>
        {fc('confirm selection')}
      </FormButtonStyled>
    </FormButtonsStyled>
  );
};
