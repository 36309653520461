import styled from 'styled-components';

import { Colors } from 'ui/constants/colors';

export const PreloaderStyled = styled.div`
  width: 100px;
  height: 100px;

  &:after {
    content: '';
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 6px solid ${Colors.AquaBelt};
    border-color: ${Colors.AquaBelt} transparent ${Colors.AquaBelt} transparent;
    animation: Preloader 1.2s linear infinite;
  }

  @keyframes Preloader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
