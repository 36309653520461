import { pc } from "../../i18n";

/**
 * @param {Object} attributeRangeDetector { leftOpen, leftBound, leftLag, rightOpen, rightBound, rightLag }
 */

export const getFormattedAttributeRange = (attributeRangeDetector) => {
	const { leftOpen, leftBound, leftLag, rightOpen, rightBound, rightLag } = attributeRangeDetector;
	const leftBracket = leftOpen ? "(" : "[";
	const rightBracket = rightOpen ? ")" : "]";
	const leftBoundWithLag = `${leftBound}${leftLag ? ` (+${leftLag})` : ''}`;
	const rightBoundWithLag = `${rightBound}${rightLag ? ` (-${rightLag})` : ''}`;

	if (leftBound != null && rightBound != null) {
		return `${leftBracket}${leftBoundWithLag} - ${rightBoundWithLag}${rightBracket}`;
	}

	if (leftBound != null) {
		return pc(leftOpen ? 'from X exclusive' : 'from X', leftBoundWithLag);
	}

	if (rightBound != null) {
		return  pc(rightOpen ? 'up to X exclusive' : 'up to X', rightBoundWithLag);
	}

	return ''
};
