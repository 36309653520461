import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'rsuite';
import Loader from '../../general/Loader';
import { useI18n } from '../../../../i18n';
import { unitsString } from '../../../misc/attributeTypes';
import { className, fromProps } from '../../../lib/className';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import useLocalStorage from '../../../misc/useLocalStorage';
import NoDataView from '../../general/view/NoDataView';
import DeviceReferenceFieldView from './DeviceReferenceFieldView';
import MessageFieldView from './MessageFieldView';
import FuelLevelFieldView from './FuelLevelFieldView';
import FixTimeFieldView from './FixTimeFieldView';
import OdometerFieldView from './OdometerFieldView';
import "./messageFieldsView.scss";

function Group(props) {
	return (
		<div className={className('group', fromProps(props))}>
			{props.children}
		</div>
	);
}

// ------------------------------------------------------

const MAIN_FIELDS = ["altitude", "satellites"];

/**
 * @param {Object} props
 * @param {cx.ods.devices.MessageDetails} props.message
 * @param {boolean} [props.full]
 */

function MessageFieldsView(props) {
	const { f } = useI18n();
	const [pinnedFieldMap, setPinnedFieldMap] = useLocalStorage(props.uri + '_pinned_field_map');
	const message = props.message;
	let content = null;
	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		if (props.messageFields.nameMap != null && props.attributeTypes.codeMap != null && props.resize) {
			props.resize();
		}
	}, [props.messageFields.nameMap, props.attributeTypes.codeMap]);

	function handleButtonClick() {
		setExpanded(!expanded);
	}

	const handleClickMessageField = (fieldName) => {
		const copyState = { ...pinnedFieldMap };
		if (copyState[fieldName]) {
			delete copyState[fieldName];
		} else {
			copyState[fieldName] = true;
		}
		setPinnedFieldMap(copyState);
	}

	const getTypeView = (subject) => {
		if (subject.rawUnits == DeviceReferenceFieldView.Units) return DeviceReferenceFieldView;
		if (subject.fieldName == FuelLevelFieldView.FieldName) return FuelLevelFieldView;
		if (subject.rawUnits == FixTimeFieldView.Units) return FixTimeFieldView;
		if (subject.fieldName == OdometerFieldView.FieldName) return OdometerFieldView;
		return MessageFieldView;
	}

	function getContent() {
		const pinnedSection = [];
		const mainSection = [];
		const detailsSection = [];

		if (message.heading != null) {
			const heading = { title: f('heading'), value: message.heading, units: '°', fieldName: 'heading' };
			if (pinnedFieldMap && pinnedFieldMap['heading']) pinnedSection.push(heading);
			else mainSection.push(heading);
		}
		if (message.ignition != null) {
			const ignition = { title: f('ignition'), value: message.ignition ? f('on') : f('off'), fieldName: 'ignition' };
			if (pinnedFieldMap && pinnedFieldMap['ignition']) pinnedSection.push(ignition);
			else mainSection.push(ignition);
		}
		if (message.speed != null) {
			const speed = { title: f('speed'), value: message.speed, units: 'km/h', fieldName: 'speed' };
			if (pinnedFieldMap && pinnedFieldMap['speed']) pinnedSection.push(speed);
			else mainSection.push(speed);
		}
		if (message.odometer != null) {
			const odometer = { title: f('odometer'), value: message.odometer, units: 'm', fieldName: 'odometer' };
			if (pinnedFieldMap && pinnedFieldMap['odometer']) pinnedSection.push(odometer);
			else mainSection.push(odometer);
		}
		if (message.fields) {
			const types = props.messageFields.typeMap;
			const map = message.fields.map();
			Object.keys(map).forEach(fieldId => {
				const type = types[fieldId];
				if (type) {
					const aType = props.attributeTypes.codeMap[type.fieldType];
					const fieldName = type.name || aType.mnemonics;
					const subject = {
						title: f({ prefix: 'message-field', id: type.description }),
						value: map[fieldId],
						units: unitsString(aType.mnemonics),
						rawUnits: aType.mnemonics,
						fieldName
					};
					if (pinnedFieldMap && pinnedFieldMap[fieldName]) {
						pinnedSection.push(subject);
					} else if (MAIN_FIELDS.indexOf(fieldName) >= 0) {
						mainSection.push(subject);
					} else {
						detailsSection.push(subject);
					}
				}
			});
		}
		const compare = (a, b) => {
			if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
			if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
			return 0;
		};
		pinnedSection.sort(compare);
		mainSection.sort(compare);
		detailsSection.sort(compare);
		const mainContent = pinnedSection.concat(mainSection.concat(detailsSection)).map(subject => {
			const Type = getTypeView(subject);
			return (
				<Type
					key={subject.title}
					{...subject}
					uri={props.uri}
					pinned={pinnedFieldMap && pinnedFieldMap[subject.fieldName]}
					onClick={handleClickMessageField}
					title={subject.title}
				/>
			);
		});
		if (mainContent.length == 0) {
			return <NoDataView />;
		}

		return (mainContent);
	}

	if (message) {
		const ready = props.messageFields.nameMap != null && props.attributeTypes.codeMap != null;
		let details = props.full || expanded
			? (ready
				? <Group>
					{getContent()}
				</Group>
				: <div><Loader /></div>
			)
			: null
		;
		content = (
			<div className={className('message-fields-view', { 'bare': props.bare })}>
				{details}
				{!props.full && <Button block onClick={handleButtonClick} appearance="ghost">{expanded ? <FiChevronUp/> : <FiChevronDown/> }</Button>}
			</div>
		);
	} else {
		content = (<NoDataView />);
	}

	return (content);
}

export default connect(state => {
	return {
		messageFields: state.registry.messageFields,
		attributeTypes: state.registry.attributeTypes
	};
})(MessageFieldsView);
