import { f } from '../i18n';

const SPEEDING_EVENTS = ['speeding', 'zone-speeding', 'speed-exceeded'];

export const getEventDetails = (event, eventType, zones) => {
	const isSpeedingEvent = SPEEDING_EVENTS.includes(eventType.mnemonics);
	const zone = zones?.map && event?.zoneId ? zones.map[event.zoneId] : null;
	const speedingInfo = isSpeedingEvent ? `${event.message.speed} ${f('units.km/h')}` : '';

	if (!isSpeedingEvent && !zone) {
		return '';
	}

	return `${speedingInfo}${speedingInfo && zone ? ', ' : ''}${zone?.name || ''}`;
};
