
export class AbstractObjectProxy {

	constructor(object) {
		Object.defineProperty(this, 'object', { value: object });
		Object.defineProperty(this, 'isProxy', { value: true });
		Object.keys(object).forEach(key => this[key] = object[key]);
	}

	getObject() {
		return this.object;
	}

	destroy() {
		this.object = null;
	}
}

export const makeProxy = (type) => {
	return (object) => {
		if (object && object instanceof type) return object;
		return object ? new type(object) : null;
	};
}