import Resolver from '../Resolver';
import { actions } from '../actions';

const getter = (interval) => {
	return [{
		latitude: interval.since.latitude,
		longitude: interval.since.longitude
	}, {
		latitude: interval.until.latitude,
		longitude: interval.until.longitude
	}]
}

let resolver = null;
const connect = (store) => {
	resolver = new Resolver(store, actions.idling.exportProgress, getter);
}

export { resolver, connect }