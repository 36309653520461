import Resolver from '../Resolver';
import { actions } from '../actions';

const getter = (assetTrip) => {
	const coordinates = [];
	if (assetTrip) {
		if (assetTrip.start) coordinates.push(assetTrip.start);
		if (assetTrip.end) coordinates.push(assetTrip.end);
	}
	return coordinates;
}

let resolver = null;
const connect = (store) => {
	resolver = new Resolver(store, actions.commute.exportProgress, getter);
}

export { resolver, connect };
