import styled from 'styled-components';

import { Colors } from 'ui/constants/colors';

export const DetectorCardStyled = styled.div`
  border-top: 1px solid ${Colors.PurpleCrystal};
  padding: 15px;

  label {
    display: block;
    font-weight: 700;
    margin-bottom: 8px;
    overflow-wrap: break-word;
  }
`;

export const DetectorAttributesStyled = styled.div`
  margin-top: 10px;
`;
