import React, { useState, useRef, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useI18n } from '../../../../../../i18n';
import DatePicker from '../../../../general/form/DatePicker';
import RadioControl from '../../../../general/form/RadioControl';
import { cx } from '../../../../../api';
import HoursPicker from '../../../../general/form/HoursPicker';
import Form from '../../../../general/form/Form';
import { ReduxKeyContext } from '../../../../../misc/ReduxKeyContext';
import { fromProps, className } from '../../../../../lib/className';
import './digestConfiguration.scss';

/**
 * @param {Object} props
 */

function DigestConfiguration(props) {
	const { f, fc, p } = useI18n();

	const reduxKey = useContext(ReduxKeyContext);
	const config = useSelector(state => state.widgets[reduxKey].map[props.uid]);

	const endOfDay = cx.today();
	endOfDay.setHours(23); endOfDay.setMinutes(59); endOfDay.setSeconds(59);

	const pickerBoxRef = useRef(null);
	const [timeRange, setTimeRange] = useState(0);
	const [days, setDays] = useState(7);
	const [hours, setHours] = useState(8);
	const [sinceHourly, setSinceHourly] = useState(8);
	const [sinceDaily, setSinceDaily] = useState(new Date(endOfDay - 7 * 24 * 3600 * 1000 + 1000));

	useEffect(() => {
		if (config.data) {
			const data = config.data;
			data.timeRange && setTimeRange(data.timeRange);
			data.days && setDays(data.days);
			data.hours && setHours(data.hours);
			data.sinceHourly && setSinceHourly(data.sinceHourly);
			data.sinceDaily && setSinceDaily(new Date(data.sinceDaily));
		}
	}, [props]);

	const timeRangeData = [
		{ value: 0, label: fc('last X days', { value: days }) },
		{ value: 1, label: fc('daily') },
		{ value: 2, label: fc('last X hours', { value: hours }) },
		{ value: 3, label: fc('hourly') }
	];

	const onChangeValidator = (value, setter, max) => {
		if (+value > max || value && +value <= 0) return;
		setter(value);
	}

	const preventDefault = (value, event) => {
		event.preventDefault();
	}

	let content = null;
	switch (timeRange) {
		case 0:
			content = (
				<Form.Control
					controlType={Form.Control.Type.InputNumber}
					controlName="days"
					className="field"
					label={p("X days'", +days)}
					max={14} min={1}
					value={days}
					onChange={(value) => onChangeValidator(value, setDays, 14)}
				/>
			);
			break;
		case 1:
			content = (
				<Form.Control
					controlType={DatePicker}
					controlName="sinceDaily"
					className="since"
					label={f('since')}
					placement="autoHorizontalStart"
					container={() => pickerBoxRef.current}
					value={sinceDaily}
					onOk={preventDefault}
					onChange={setSinceDaily}
					cleanable={false}
					disabledDate={(date) => date > cx.now()}
				/>
			);
			break;
		case 2:
			content = (
				<Form.Control
					controlType={Form.Control.Type.InputNumber}
					controlName="hours"
					className="field"
					label={p("X hours'", +hours)}
					max={24} min={1}
					value={hours}
					onChange={(value) => onChangeValidator(value, setHours, 24)}
				/>
			);
			break;
		case 3:
			content = (
				<Form.Control
					controlType={HoursPicker}
					controlName="sinceHourly"
					className="since"
					label={f('since')}
					container={() => pickerBoxRef.current}
					value={sinceHourly}
					onChange={setSinceHourly}
				/>
			);
			break;
		default:
			break;
	}

	const changeTimeRange = (value) => {
		setTimeRange(value);
	}
	const optionsData = [
		{ value: "top", label: fc('top devices') },
		{ value: "recents", label: fc('recents') },
		{ value: null, label: fc('none') }
	];

	return (
		<div className={className("digest-configuration", fromProps(props))}>
			<Form.ControlGroup>
				<div className="general-picker" ref={pickerBoxRef}>
					<label>{f('chart parameters')}</label>
					<div className="collected-inputs">
						<Form.Control
							controlType={Form.Control.Type.InputPicker}
							controlName="timeRange"
							className="picker"
							container={() => pickerBoxRef.current}
							block
							cleanable={false}
							data={timeRangeData}
							disabled={props.disabled}
							value={timeRange}
							onChange={changeTimeRange}
						/>
						<div className="fields" ref={pickerBoxRef}>
							{content}
						</div>
					</div>
				</div>
				{props.children}
				<RadioControl
					controlName="option"
					data={optionsData}
					label={f('details')}
					defaultValue={config.data && config.data.option}
				/>
			</Form.ControlGroup>
		</div>
	);
}

export default DigestConfiguration;