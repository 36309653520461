import styled from 'styled-components';

export const MapOptionStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
`;

export const MapOptionLabelStyled = styled.div`
  font-weight: bold;
`;

export const MapOptionButtonStyled = styled.div`
  width: 95px;
`;
