import { f, fc, pc } from "./index";

export const getRsLocale = () => {

	const Calendar = {
		sunday: fc('short.su'),
		monday: fc('short.mo'),
		tuesday: fc('short.tu'),
		wednesday: fc('short.we'),
		thursday: fc('short.th'),
		friday: fc('short.fr'),
		saturday: fc('short.sa'),
		ok: fc('apply'),
		today: fc('today'),
		yesterday: fc('yesterday'),
		hours: fc('hours'),
		minutes: fc('minutes'),
		seconds: fc('seconds'),
		/**
		 * Format of the string is based on Unicode Technical Standard #35:
		 * https://www.unicode.org/reportsintl/tr35/tr35-dates.html#Date_Field_Symbol_Table
		 **/
		formattedMonthPattern: 'MMM YYYY',
		formattedDayPattern: 'DD MMM YYYY'
	};

	return {
		Pagination: {
			more: fc('more'),
			prev: fc('previous'),
			next: fc('next'),
			first: fc('first'),
			last: fc('last')
		},
		Table: {
			emptyMessage: fc('no data found'),
			loading: fc('loading') + '...'
		},
		TablePagination: {
			lengthMenuInfo: '{0} / ' + f('page'),
			totalInfo: fc('total') + ': {0}'
		},
		Calendar,
		DatePicker: {
			...Calendar
		},
		DateRangePicker: {
			...Calendar,
			last7Days: pc('last X days', 7)
		},
		Picker: {
			noResultsText: fc('no results found'),
			placeholder: fc('select'),
			searchPlaceholder: fc('search'),
			checkAll: fc('all')
		},
		InputPicker: {
			newItem: fc('new item'),
			createOption: fc('create option') + ' "{0}"'
		},
		Uploader: {
			inited: fc('initial'),
			progress: fc('uploading'),
			error: fc('error'),
			complete: fc('finished'),
			emptyFile: fc('empty'),
			upload: fc('upload')
		}
	}
};