import React, { useImperativeHandle, useState, useRef } from 'react';
import Form from '../../../../general/form/Form';
import { cx } from '../../../../../api';
import { useI18n } from '../../../../../../i18n';
import GroupCheckbox from '../../../../general/form/GroupCheckbox';
import TimeFrameControl from './TimeFrameControl';


/**
 * @param {Object} props
 * @param {cx.ods.reports.FuelLevelLeapCriteria} [props.leapCriteria]
 * @param {React.Ref} props.customRef
 * @param {boolean} [props.disabled]
 */

function LeapCriteriaControl(props) {
	const timeFrameRef = useRef();
	const [hasLeapCriteria, setHasLeapCriteria] = useState(!!props.leapCriteria);
	const [hasTimeFrame, setHasTimeFrame] = useState(props.leapCriteria && props.leapCriteria.timeFrame != null);
	const { f, fc } = useI18n();

	const disabledTimeFrame = props.disabled || !hasLeapCriteria || !hasTimeFrame;

	useImperativeHandle(props.customRef, () => ({
		submit: (values) => {
			let criteria = null;
			if (values.hasLeapCriteria) {
				criteria = new cx.ods.reports.FuelLevelLeapCriteria();
				criteria.threshold = +values.threshold;
				criteria.timeFrame = timeFrameRef.current.submit(values);
			}
			return criteria;
		},
		changed: values => {
			let changed = Boolean(props.leapCriteria) != Boolean(values.hasLeapCriteria);
			if (!changed && props.leapCriteria) {
				changed = Number(props.leapCriteria.threshold) != Number(values.threshold);
				if (!changed) changed = Boolean(props.leapCriteria.timeFrame) != Boolean(values.hasTimeFrame);
				if (!changed && props.leapCriteria.timeFrame) changed = timeFrameRef.current.changed(values);
			}
			return changed;
		}
	}));

	return (
		<div className="group">
			<Form.Control
				controlName="hasLeapCriteria"
				controlType={GroupCheckbox}
				value={hasLeapCriteria}
				onChange={setHasLeapCriteria}
				label={fc('leap detection')}
				disabled={props.disabled}
			/>
			<Form.ControlGroup key={50} disabled={props.disabled}>
				<Form.Control
					controlName="threshold"
					controlType={Form.Control.Type.InputNumber}
					min={0} max={100} step={0.1}
					controlValidator={(value, values) => {
						if (!props.disabled && hasLeapCriteria) {
							if (value == null || value === '') {
								return f('please enter a value');
							} else if (+value == 0) {
								return f('please enter a positive value');
							}
						}
					}}
					label={f('threshold') + ', ' + f({ prefix: 'units', id: '%' })}
					initialValue={props.disabled || !hasLeapCriteria ? null : (props.leapCriteria ? props.leapCriteria.threshold : 5)}
					disabled={props.disabled || !hasLeapCriteria}
				/>
				<Form.Control
					controlName="hasTimeFrame"
					controlType={GroupCheckbox}
					value={hasTimeFrame}
					onChange={setHasTimeFrame}
					label={fc('time frame')}
					disabled={props.disabled || !hasLeapCriteria}
				/>
				<TimeFrameControl
					prefix={'leapCreteria'}
					customRef={timeFrameRef}
					timeFrame={props.leapCriteria && props.leapCriteria.timeFrame}
					disabled={disabledTimeFrame}
				/>
			</Form.ControlGroup>
		</div>
	);
}

export default LeapCriteriaControl;
