import React from 'react';
import { useI18n } from '../../../../../i18n';
import Toggle from '../../../general/form/Toggle';

/**
 * @param {Object} props
 * @param {boolean} props.value
 * @param {function} props.onChange
 */

function MapOptionZones(props) {
	const { f } = useI18n();
	return (
		<div className="map-option map-option-zones">
			<Toggle
				label={f('show zones')}
				value={props.value}
				onChange={props.onChange}
			/>
		</div>
	);
}

export default MapOptionZones;