import React from 'react';
import { Menu } from "@blueprintjs/core";
import { useI18n } from '../../../../i18n';
import { WidgetMenuTypes } from './WidgetContainer';
import WidgetMenuItem from './WidgetMenuItem';

/**
 * @param {Object} props
 * @param {function} props.onWidgetClose
 * @param {function} props.onClick
 * @param {function} [props.close]
 * @param {React.Component} [props.additionalItems]
 */

function WidgetMenu(props) {
	const { fc } = useI18n();

	const children = () => {
		return React.Children.map(props.children, child =>
			child && React.cloneElement(child, { close: props.close }) || child
		);
	}

	const onConfig = () => {
		props.onClick(WidgetMenuTypes.CONFIGURATION)
	}

	return (
		<Menu>
			<WidgetMenuItem onClick={onConfig} text={fc('configuration')} />
			{children()}
			<WidgetMenuItem onClick={props.onWidgetClose} text={fc('close widget')} />
		</Menu>
	);
}

export default WidgetMenu;