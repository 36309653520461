import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { className, fromProps } from '../../../lib/className';
import { FiLayers } from 'react-icons/fi';
import { actions as appActions } from '../../../redux/app/appActions';
import { AppActionType } from '../../../app/AppActionType';
import Popup from '../Popup';
import List from '../list/List';
import ListItem from '../list/ListItem';
import { isEscapeButton, capitalize } from '../../../misc/misc';
import DeviceAssetTypeIcon from '../../share/devices/DeviceAssetTypeIcon';
import { useI18n } from '../../../../i18n';
import './actionLockedDevices.scss';

/**
 * @param {Object} props
 * @param {Array.<string>} [props.uris]
 * @param {Array.<string>} [props.disabledUris]
 * @param {string} [props.emptyMessage]
 * @param {string} [props.title]
 * @param {string} [props.titleContent]
 * @param {boolean} [props.disabled]
 * @param {function} [props.onChange]
 */

function ActionLockedDevices(props) {
	const { f, fc } = useI18n();
	const [opened, setOpened] = useState(false);
	let content = (<div className="empty"><span className="capitalize">{props.emptyMessage}</span></div>);

	const _className = className(
		'action-locked-devices',
		fromProps(props),
		{
			'clickable': !props.disabled,
			'disabled': props.disabled
		}
	);

	const onClick = (uri) => {
		props.dispatch(appActions.action(
			AppActionType.DEVICE_SET_SELECTED, { uri }
		))
		setOpened(false);
	}

	const setIsOpen = (value) => {
		setOpened(value)
	}

	useEffect(() => {
		const handleEscape = (event) => {
			if (isEscapeButton(event) && !window.document.body.className) setOpened(false);
		}
		window.document.addEventListener('keydown', handleEscape);
		return () => window.document.removeEventListener('keydown', handleEscape);
	}, []);

	useEffect(() => {
		props.onChange && props.onChange(opened);
	}, [opened]);

	if (props.deviceMap && props.uris && props.uris.length > 0) {
		const activeItems = [];
		const disabledItems = [];

		props.uris.forEach(uri => {
			const device = props.deviceMap && props.deviceMap[uri];
			const disabled =  props.disabledUris && props.disabledUris.includes(uri);
			if (disabled) {
				disabledItems.push(
					<ListItem key={uri} className={className({ disabled })}>
						<div className="icon">
							<DeviceAssetTypeIcon categoryIds={device ? device.categoryIds : null} />
						</div>
						<div>{device ? device.denomination() : uri}</div>
					</ListItem>
				);
			} else {
				activeItems.push(
					<ListItem key={uri} onClick={() => onClick(uri)}>
						<div className="icon">
							<DeviceAssetTypeIcon categoryIds={device ? device.categoryIds : null} />
						</div>
						<div>{device ? device.denomination() : uri}</div>
					</ListItem>
				);
			}
		});
		const list = (
			<List>
				{activeItems}
				{disabledItems}
			</List>
		);
		content = (<>
			<div className="header">
				<label>
					<span className="capitalize">
						{props.titleContent ? props.titleContent : f('devices')}
					</span>
				</label>
			</div>
			{list}
		</>);
	}

	return (
		<Popup
			isOpen={opened}
			position="auto-start"
			disabled={props.disabled}
			setIsOpen={setIsOpen}
			className={_className}
			title={props.title ? capitalize(props.title) : fc("selected devices")}
			content={(
				<div className="locked-devices content">
					{content}
				</div>
			)}
		>
			<div className="icon">
				<FiLayers />
			</div>
			<div className="quantity">
				{props.disabledUris && props.disabledUris.length > 0
					? (props.uris.length - props.disabledUris.length + '/' + props.uris.length)
					:  props.uris.length
				}
			</div>
		</Popup>
	);
}

export default connect(state => ({
	deviceMap: state.devices.map
}))(ActionLockedDevices);