import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../i18n';
import { actions as authorizationActions } from '../../../redux/api/authorization';
import Form from '../../general/form/Form';
import FloatingLabelInput from '../../general/form/FloatingLabelInput';
import Button from '../../general/form/Button';
import ButtonGroup from '../../general/form/ButtonGroup';
import { ods } from '../../../api';
import { className } from '../../../lib/className';
import './authorizationForm.scss';

const getLabel = (type) => {
	switch (type) {
		case ods.auth.CredentialType.password:
		case ods.auth.CredentialType.newPassword:
			return 'password';
		case ods.auth.CredentialType.otp:
			return 'verification code';
		case ods.auth.CredentialType.token:
			return 'token';
		default:
			return 'user name';
	}
}

const getInputType = (type) => {
	switch (type) {
		case ods.auth.CredentialType.password:
		case ods.auth.CredentialType.newPassword:
			return 'password';
		default:
			return 'text';
	}
}


/**
 * @param {object} props
 * @param {function} props.firstStepAction
 * @param {boolean} [props.simple]
 */

function AuthorizationForm(props) {
	const { f, fc } = useI18n();

	const submitHook = useRef(null);
	const [value, setValue] = useState('');
	const disabled = props.authorization.pending;
	const started = props.authorization.started;
	const loginName = props.authorization.loginName;
	const challenge = props.authorization.challenge;
	const isResendDisabled = disabled || props.authorization.resend?.pending;

	useEffect(() => {
		setValue('');
	}, [challenge?.challengeNo]);

	// useEffect(() => {
	// 	if (!loginName) setValue('');
	// }, [loginName]);

	useEffect(() => () => props.dispatch(authorizationActions.clear()), []);

	const onSubmit = () => {
		if (!started) props.firstStepAction({ loginName: value.trim() }); // this data should be passed to authorization for first request
		else props.dispatch(authorizationActions.step.request({ value }));
	}

	const submit = () => {
		if (submitHook.current) submitHook.current();
	}

	const onCancel = () => {
		props.dispatch(authorizationActions.restart());
	}

	const resend = () => {
		if (!isResendDisabled) {
			props.dispatch(authorizationActions.resend.request({ challengeNo: challenge.challengeNo }));
		}
	}

	const description = (
		<>
			<span className="capitalize">
				{challenge?.request}
			</span>
			{challenge?.resendable
				&& <div className="resend">
					<span className={className("capitalize", "clickable", { 'disabled': isResendDisabled })} onClick={resend}>
						{f('resend')}
					</span>
				</div>
			}
		</>
	);
	return (
		<Form
			onSubmit={onSubmit}
			className="authorization-form"
			submitHook={submitHook}
			disabled={disabled}
			error={props.authorization.error}
			header={!props.simple
				&& <div className="greeting">
					{started ? loginName : fc('sign in to continue')}
				</div>
			}
			footer={
				<ButtonGroup>
					{started
						&& <Button disabled={disabled} onClick={onCancel}>
							{f('cancel')}
						</Button>
					}
					<Button onClick={submit} className="active" disabled={disabled || !value.trim()}>
						{f('next')}
					</Button>
				</ButtonGroup>
			}
		>
			<Form.Control
				controlType={FloatingLabelInput}
				onChange={setValue}
				value={value}
				autoFocus={true}
				controlValidator={() => !value.trim() && f('please enter a value')}
				label={fc(getLabel(challenge?.credentialType))}
				type={getInputType(challenge?.credentialType)}
				description={description}
				error={challenge?.error && <span className="capitalize">{challenge?.error}</span>}
			/>
		</Form>
	);
}

export default connect(state => ({ authorization: state.authorization }))(AuthorizationForm);
