import React from 'react';
import { connect } from 'react-redux';
import Address from '../../general/location/Address';

/**
 * @param {Object} props
 * @param {cx.ods.devices.DeviceDetails} props.device
 */

function DeviceLocation(props) {
	let content = null;
	if (props.state != null) {
		content = <Address message={props.state.message} />
	}

	return (
		<div className="device-location">
			{content}
		</div>
	)
}

export default connect(
	(state, props) => ({
		state: state.deviceStates.map && props.device && state.deviceStates.map[props.device.uri]
	}),
	(dispatch) => ({

	})
)(DeviceLocation);