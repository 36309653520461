import React, { useState } from 'react';
import { connect } from 'react-redux';
import { className, fromProps } from '../../lib/className';
import Popup from './Popup';
import LanguageSelector from './LanguageSelector';
import { actions as langActions } from '../../redux/app/locale';
import './languageChanger.scss';

/**
 * @param {Object} props
 * @param {string} [props.text]
 */

function LanguageChanger(props) {
	const [opened, setOpened] = useState(false);

	const onChange = (lang) => {
		props.dispatch(langActions.setLang({ lang }));
	}

	return (
		<Popup
			className={className('language-changer', 'clickable', fromProps(props))}
			isOpen={opened}
			setIsOpen={setOpened}
			content={<LanguageSelector hideIcon onClick={onChange} active={props.lang} />}
		>
			{props.text || 'language'}
		</Popup>
	);
}

export default connect(state => ({
	lang: state.locale.lang
}))(LanguageChanger);
