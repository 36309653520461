import React from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../../i18n';
import { capitalize } from '../../../../../misc/misc';

/**
 * @param {Object} props
 * @param {Array.<number>} [props.categoryIds] event type categories
 * @param {Array.<number>} [props.eventTypes] event types
 */

function FilterInfoView(props) {
	const { f } = useI18n();
	let content = null;
	const categoryNames = [];
	let categoryNamesString = null;
	if (props.categoryIds && props.eventTypeCategoryMap) {
		props.categoryIds.forEach(id => props.eventTypeCategoryMap[id] && categoryNames.push(props.eventTypeCategoryMap[id].name));
	}
	if (categoryNames.length > 0) categoryNamesString = categoryNames.join(', ');

	const eventNames = [];
	let eventNamesString = null; // TODO
	if (props.eventTypes && props.eventTypeMap) {
		props.eventTypes.forEach(eventType => {
			props.eventTypeMap[eventType] && eventNames.push(f({ prefix: 'device-event', id: props.eventTypeMap[eventType].name }));
		});
	}
	if (eventNames.length > 0) eventNamesString = eventNames.join(', ');

	if (categoryNamesString || eventNamesString) {
		content = (
			<div className="filter-info-view">
				{categoryNamesString && <div>
					{eventNamesString && <label>
						<span className="capitalize">{f('tags') + ':'}&nbsp;</span>
					</label>}
					<span className="capitalize names" title={capitalize(categoryNamesString)}>
						{categoryNamesString}
					</span>
				</div>}
				{eventNamesString && <div>
					{categoryNamesString && <label>
						<span className="capitalize">{f('event types') + ':'}&nbsp;</span>
					</label>}
					<span className="capitalize names" title={capitalize(eventNamesString)}>
						{eventNamesString}
					</span>
				</div>}
			</div>
		);
	}
	return (content);
}

export default connect(state => ({
	eventTypeCategoryMap: state.categories.eventTypes.map,
	eventTypeMap: state.registry.eventTypes.typeMap
}))(FilterInfoView);