import React, { useState } from 'react';
import { className, fromProps } from '../../../../lib/className';
import { useI18n } from '../../../../../i18n';
import Modal from '../../../general/Modal';
import MapCoordinateSelector from '../../../general/location/MapCoordinateSelector';
import ParameterControl from './ParameterControl';
import './setPositionParameters.scss';

/**
 * @param {Object} props
 * @param {cx.ods.devices.CommandType} props.command // command must have longitude and latitude parameters
 * @param {Object} [props.initialLocation] { longitude, latitude }
 */

function SetPositionParameters(props) {
	const { f } = useI18n();
	const [adding, setAdding] = useState(false);
	const parameters = {
		longitude: null,
		latitude: null
	};
	props.command.parameters.forEach(param => parameters[param.name] = param);

	const [longitude, setLongitude] = useState(props.initialLocation ? props.initialLocation.longitude : null);
	const [latitude, setLatitude] = useState(props.initialLocation ? props.initialLocation.latitude : null);

	const closeModal = () => {
		setAdding(false);
	}

	const onChangePosition = (coordinate) => {
		setLongitude(coordinate[0]);
		setLatitude(coordinate[1]);
		closeModal();
	}

	return (
		<div className={className("set-position-parameters", fromProps(props))}>
			<div className="map-control">
				<label>
					<span className="capitalize clickable" onClick={() => setAdding(true)}>
						{f('select position on map')}
					</span>
				</label>
			</div>
			<ParameterControl
				parameter={parameters.longitude}
				value={longitude}
				onChange={setLongitude}
			/>
			<ParameterControl
				parameter={parameters.latitude}
				value={latitude}
				onChange={setLatitude}
			/>
			{adding &&
				<Modal
					title={f('select position on map')}
					onClose={closeModal}
				>
					<MapCoordinateSelector
						coordinate={[longitude, latitude]}
						onCancel={closeModal}
						onChange={onChangePosition}
					/>
				</Modal>
			}
		</div>
	);
}

export default SetPositionParameters;