import React, { FC } from 'react';

import { CardStyled, HeaderStyled, PageStyled } from 'ui/pages/issues/issues.styled';
import { fc } from 'i18n';
import { ISSUES_TABS } from 'constants/table';
import { useIssuesTable } from 'hooks/issues/useIssuesTable';
import { Table } from 'components/organisms/table';
import { Tabs } from 'components/molecules/tabs';
import { IssuesTableFooter } from './issues-table-footer';

export const Issues: FC = () => {
  const { tabsProps, tableProps, tableFooterProps } = useIssuesTable();

  return (
    <PageStyled>
      <HeaderStyled>{fc('follow up issues')}</HeaderStyled>
      <CardStyled>
        <Tabs tabs={ISSUES_TABS} {...tabsProps} />
        <Table {...tableProps} />
        {!!tableProps.data?.length && <IssuesTableFooter {...tableFooterProps} />}
      </CardStyled>
    </PageStyled>
  );
};
