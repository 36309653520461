import { filter, ignoreElements, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { combineEpics, ofType } from 'redux-observable';

import { LogLevel, rootLogger } from 'core/lib/log';

import { rs } from 'core/rs';

import { ActionGeneratorBuilder, deltaReducer, errorMap } from 'core/redux/actions';
import { timestamps } from 'core/redux/serializers';

const logger = rootLogger.logger('veta-taxiways').at(LogLevel.Verbose);

export const actions = new ActionGeneratorBuilder('veta-taxiways')
	.subtype('load', load => load.request().success('taxiways').fail())
	.build()
;

const defaultState = {
	list: null
	, map: null
	, pending: false
	, error: null
};

export const reducer = deltaReducer((state, action) => {
	switch (action.type) {
		case actions.load.request.type: return {
			pending: true
			, error: null
		};
		case actions.load.success.type: return {
			pending: false
			, list: action.taxiways
			, map: Object.fromEntries(action.taxiways.map(taxiway => [taxiway.id, taxiway]))
		};
		case actions.load.fail.type: return {
			pending: false
			, error: action.errorMessage
		};
	}
	return null;
}, defaultState);

const internalize = timestamps(['createdAt', 'modifiedAt']).internalize;

export const epic = combineEpics(
	action$ => action$.pipe(
		ofType(actions.load.request.type)
		, switchMap(() => rs('veta/airside/taxiway').defer$().pipe(
			map(taxiways => taxiways.map(internalize))
			, map(taxiways => actions.load.success({taxiways}))
			, errorMap(actions.load.fail)
		))
	)
	, (action$, state$) => action$.pipe(
		ofType(actions.load.success.type)
		, filter(_ => logger.loggable(LogLevel.Debug))
		, withLatestFrom(state$.pipe(map(state => state.veta.taxiways)))
		, tap(([_, state]) => {
			logger.debug('taxiways', state.list);
		})
		, ignoreElements()
	)
);
