import { ActionGeneratorBuilder } from "../../actions";

export const actions = new ActionGeneratorBuilder('deviceEvents')
	.subtype(
		'find',
		find => find
			.request({ uri: true, uid: true, filter: true })
			.success({ uri: true, uid: true, filter: true, list: true })
			.fail({ uri: true, uid: true, errorMessage: true })
			.cancel()
	)
	.type('addHeadEvent', { uri: true, uid: true, event: true })
	.type('addHead', { uri: true, uid: true, list: true })
	.type('removeHead', { uri: true, uid: true })
	.type('addTail', { uri: true, uid: true, list: true })
	.type('removeTail', { uri: true, uid: true })
	.type('findEvents', { uri: true, uid: true, since: false, until: false, eventTypes: false })
	.type('moveForward', { uri: true, uid: true })
	.type('moveBackward', { uri: true, uid: true })
	.build()
;
