import React from 'react';
import { className, fromProps } from '../../../lib/className';
import Action from './BarAction';
import './actionBar.scss';

/**
 * @param {Object} props
 * @param {boolean} [props.disabled]
 */

function ActionBar(props) {

	const renderChildren = () => {
		return React.Children.map(props.children, child => {
			return child && props.disabled !== undefined
				? React.cloneElement(child, { disabled: props.disabled })
				: child
			;
		});
	}

	return (
		<div className={className('action-bar', fromProps(props))}>
			{renderChildren()}
		</div>
	);
}

ActionBar.Action = Action;

export default ActionBar;