import React from 'react';
import { connect } from 'react-redux';
import { Avatar } from 'rsuite';
import AccountInfo from './AccountInfo';
import AccountDetailsForm from './AccountDetailsForm';
import AccountPasswordForm from './AccountPasswordForm';
import Modal from '../../general/Modal';
import { FiUser } from 'react-icons/fi';
import InspectAccountForm from './InspectAccountForm';
import SignInSettings from './SignInSettings';
import SignInOptionsForm from './SignInOptionsForm';
import './accountPopup.scss';

export const Aspect = {
	inspect: 'inspect',
	profile: 'profile',
	signInSettings: 'signInSettings',
	password: 'password',
	signInOptions: 'signInOptions'
}

/**
 * @param {Object} props
 * @param {function} props.onClose
 * @param {string} props.aspect
 * @param {function} props.setAspect
 */

function AccountPopup(props) {
	const account = props.account.details;
	let content = null;

	if (account) {
		content = (
			<Modal
				size="md"
				className="account-popup"
				onClose={props.onClose}
				header={
					<div className="wrap">
						<Avatar size="lg" circle><FiUser size="40" /></Avatar>
					</div>
				}
			>
				<AccountInfo account={account} bare />
				{props.aspect == Aspect.profile &&
					<AccountDetailsForm account={account} onReady={props.onClose} onCancel={props.onClose} />
				}
				{props.aspect == Aspect.signInSettings &&
					<SignInSettings
						onReady={props.onClose}
						setAspect={props.setAspect}
						onCancel={props.onClose}
					/>
				}
				{props.aspect == Aspect.password &&
					<AccountPasswordForm
						onReady={() => props.setAspect(Aspect.signInSettings)}
						onCancel={() => props.setAspect(Aspect.signInSettings)}
					/>
				}
				{props.aspect == Aspect.signInOptions &&
					<SignInOptionsForm
						onReady={() => props.setAspect(Aspect.signInSettings)}
						onCancel={() => props.setAspect(Aspect.signInSettings)}
					/>
				}
				{props.aspect == Aspect.inspect &&
					<InspectAccountForm onReady={props.onClose} onCancel={props.onClose} />
				}
			</Modal>
		);
	}

	return (content);
}

export default connect((state) => {
	return {
		account: state.account
	}
})(AccountPopup);
