import React from 'react';
import { Container, Header as RsHeader } from 'rsuite';
import Header from '../custom/header/Header';
import './workspaceLayout.scss';

function WorkspaceLayout(props) {

	return (
		<Container className="workspace-layout">
			<RsHeader>
				<Header />
			</RsHeader>
			<Container className="body-wrap">
				{props.children}
			</Container>
		</Container>
	);
}

export default WorkspaceLayout;