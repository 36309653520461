import React, { useRef, useState, useContext, useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDrop } from 'react-dnd';
import { DragItemType } from '../../share/dnd/DragItemType';
import { useI18n } from '../../../../i18n';
import ActionBar from '../actionbar/ActionBar';
import * as widgets from '../../../redux/app/widgets';
import { ReduxKeyContext } from '../../../misc/ReduxKeyContext';
import ActionMenu from '../../share/actionbar/ActionMenu';
import WidgetMenu from './WidgetMenu';
import WidgetConfigurationWrap from './WidgetConfigurationWrap';
import WidgetTitleConfiguration from './WidgetTitleConfiguration';
import arrowIcon from '../../../img/icons/arrow.png';
import { ResizeContext } from '../../../misc/ResizeContext';
import { DRAG_HANDLE_CLASS, DRAG_CANCEL_CLASS } from './WidgetLayoutManager';
import { cx } from '../../../api';
import { className, fromProps } from '../../../lib/className';
import './widgetContainer.scss';

export const WidgetMenuTypes = { CONFIGURATION: 'CONFIGURATION' };

/**
 * @param {Object} props
 * @param {string} props.uid
 * @param {boolean} [props.maximized]
 * @param {number} [props.rowHeight]
 * @param {Array.<number>} [props.margin]
 */

function WidgetContainer(props) {
	const { f } = useI18n();
	const box = useRef(null);
	const widgetRef = useRef(null);
	const missUpdateHeight = useRef(false);
	const reduxKey = useContext(ReduxKeyContext);
	const config = useSelector(state => state.widgets[reduxKey]?.map[props.uid]);
	const dispatch = useDispatch();
	const [serial, setSerial] = useState(0);
	const [openedMenu, setOpenedMenu] = useState(false);
	const [typeMenu, setTypeMenu] = useState(null);
	const widget = widgetRef.current;
	const widgetData = config.data;
	const layout = config.layout;
	const hasDynamicContent = widget && typeof widget.dynamicContent != 'boolean';
	const isDynamicWidget = Boolean(widget && (widget.dynamicContent && !hasDynamicContent || widget.fullHeight));
	const isDWWDC = Boolean(isDynamicWidget && hasDynamicContent); // is Dynamic Widget With Dynamic Content
	const expanded = config.expanded;
	const initiallyExpandable = Boolean(widget && (typeof widget.expandable == 'function' ? widget.expandable() : widget.expandable));
	const [expandable, setExpandable] = useState(initiallyExpandable);
	let contentMenuButton = null;
	let droppable;

	const applyLayoutHeight = (layout, height) => {
		layout.h = height > layout.maxH ? layout.maxH : height;
		if (layout.h < layout.minH) layout.h = layout.minH;
		return layout.h;
	}

	const getWidgetInfo = () => {
		if (props.margin && props.rowHeight) {
			const rowHeight = props.rowHeight;
			const margin = props.margin[1];
			let totalHeight = 0;
			let dynamicContentVisibleHeight = 0;
			if (box.current) {
				const children = box.current.childNodes;
				children.forEach(child => {
					if (child.className.indexOf("content") >= 0) {
						child.childNodes.forEach(item => {
							if (item.className.indexOf("dynamic") >= 0) {
								totalHeight += item.scrollHeight;
								dynamicContentVisibleHeight = item.offsetHeight;
							} else {
								if (isDynamicWidget) {
									const widgetBlock = item.childNodes[0];
									const style = window.getComputedStyle(widgetBlock);
									if (style.flexDirection == 'row') {
										let biggerHeight = 0;
										widgetBlock.childNodes.forEach(child => {
											if (biggerHeight < child.scrollHeight) biggerHeight = child.scrollHeight;
										});
										totalHeight += biggerHeight;
									} else widgetBlock.childNodes.forEach(child => totalHeight += child.scrollHeight);
								} else totalHeight += item.scrollHeight;
							}
						});
					} else totalHeight += child.scrollHeight;
				});
			}
			const needRows = Math.ceil((totalHeight + margin) / (rowHeight + margin));
			const visibleDynamicRows = Math.ceil((dynamicContentVisibleHeight + margin) / (rowHeight + margin));
			const fullHeight = totalHeight + margin;
			return { needRows, fullHeight, visibleDynamicRows };
		}
	}

	const updateHeight = (widgetInfo = getWidgetInfo()) => {
		if (widget && !props.maximized && widgetInfo && props.margin && props.rowHeight) {
			const copyLayout = { ...layout };
			const couldBeExpanded = widgetInfo.needRows <= layout.h || layout.h == layout.maxH;
			if (isDynamicWidget && couldBeExpanded && expanded) setExpanded(couldBeExpanded);

			const isNeedHideScroll = copyLayout.h >= widgetInfo.needRows && expanded;
			box.current.querySelector('.content').style.overflow = isNeedHideScroll ? "visible" : "hidden";

			if (copyLayout.h != widgetInfo.needRows && config.autoSize && (!isDynamicWidget || expanded) && !isDWWDC) {
				applyLayoutHeight(copyLayout, widgetInfo.needRows);
				sizeChange(copyLayout);
			}
		}
	}

	const ratifyLayout = () => {
		if (widget && !props.maximized) {
			const widgetInfo = getWidgetInfo();
			const shouldBeExpandable = (layout.minH < widgetInfo.needRows) || (layout.minH < layout.h);
			if (isDWWDC || hasDynamicContent) setExpandable(initiallyExpandable);
			else if (expandable != shouldBeExpandable) setExpandable(shouldBeExpandable);
			updateHeight(widgetInfo);
		}
	}

	useLayoutEffect(() => {
		if (!isDWWDC) updateHeight();
	}, [expandable]);

	useLayoutEffect(() => {
		if (props.maximized) setExpandable(initiallyExpandable);
	}, [initiallyExpandable]);

	useEffect(() => {
		if (config.autoSize === undefined) setAutoSize(true);
	}, [config.autoSize]);

	useLayoutEffect(() => {
		ratifyLayout();
	}, [serial]);

	useLayoutEffect(() => {
		if (!missUpdateHeight.current) update();
		missUpdateHeight.current = false;
	}, [layout.h]);

	useLayoutEffect(() => {
		if (widget && !props.maximized && hasDynamicContent) {
			const widgetInfo = getWidgetInfo();
			const copyLayout = { ...layout };
			if (isDWWDC) {
				if (expanded && !props.maximized) {
					if (layout.h - widgetInfo.visibleDynamicRows < layout.minH) {
						applyLayoutHeight(copyLayout, layout.h + widgetInfo.visibleDynamicRows);
						sizeChange(copyLayout);
					}
				}
			} else {
				applyLayoutHeight(copyLayout, widgetInfo.needRows);
				sizeChange(copyLayout);
			}
		}
	}, [expanded]);

	const update = () => {
		setSerial(serial+1);
	}

	const setAutoSize = (value) => {
		if (config.autoSize != value) dispatch(widgets.actions.setAutoSize({ domain: reduxKey, uid: props.uid, autoSize: value }));
	}

	const sizeChange = (layout) => {
		dispatch(widgets.actions.updateItemLayout({ domain: reduxKey, uid: props.uid, layout }));
	}

	const onClose = () => {
		dispatch(widgets.actions.remove({ domain: reduxKey, uid: props.uid }));
	}

	const onActionChange = (opened) => {
		if (box.current) {
			cx.dom.toggleClass(box.current, opened, "interacting");
		}
	}

	const child = React.cloneElement(Array.isArray(props.children) ? props.children[0] : props.children, {
		uid: props.uid,
		customRef: widgetRef,
		sizeChange,
		resize: updateHeight,
		onFocus: onActionChange,
		update,
		reduxKey
	});

	const setExpanded = (exp) => {
		if (expanded != exp) dispatch(widgets.actions.setExpanded({ domain: reduxKey, uid: props.uid, expanded: exp }));
	}

	const toggleForExpanded = () => {
		setExpanded(!expanded);
		setAutoSize(true);
	}

	const onArrowButtonClick = () => {
		missUpdateHeight.current = true;
		if (!props.maximized) {
			const copyLayout = { ...layout };
			const widgetInfo = getWidgetInfo();
			if (isDWWDC) {
				if (expanded) {
					if (layout.h - widgetInfo.visibleDynamicRows <= layout.minH) {
						applyLayoutHeight(copyLayout, layout.h - widgetInfo.visibleDynamicRows);
						sizeChange(copyLayout);
					}
				}
			} else if (isDynamicWidget) {
				copyLayout.h = expanded ? copyLayout.minH : applyLayoutHeight(copyLayout, widgetInfo.needRows);
				sizeChange(copyLayout);
			}
		}
		toggleForExpanded();
	}

	switch (typeMenu) {
		case WidgetMenuTypes.CONFIGURATION:
			contentMenuButton = (
				<WidgetConfigurationWrap uid={props.uid} className="widget-container">
					<WidgetTitleConfiguration
						uid={props.uid}
						title={widgetData && widgetData.title}
						defaultTitle={widget && widget.title && widget.title()}
					/>
					{widget && widget.configuration && widget.configuration()}
				</WidgetConfigurationWrap>
			);
			break;
		default:
			contentMenuButton = (
				<WidgetMenu onWidgetClose={onClose} onClick={setTypeMenu}>
					{widget && widget.menuItems && widget.menuItems()}
				</WidgetMenu>
			);
			break;
	}

	// --------------- drop actions ------------------

	const dropHandler = (item) => {
		switch (item.type) {
			case DragItemType.ACTION_REMOVE:
				onClose();
				break;
			case DragItemType.ACTION_EDIT:
				setOpenedMenu(true);
				setTypeMenu(WidgetMenuTypes.CONFIGURATION);
				onActionChange(true)
				break;
			case DragItemType.ACTION_PIN:
				widget.handlePin();
				break;
			default:
				break;
		}
	}

	const canDrop = (item) => {
		switch (item.type) {
			case DragItemType.ACTION_PIN:
				return widget.canPin;
			default:
				return true;
		}
	}

	const [stageDropActions, dropAction] = useDrop({
		accept: [DragItemType.ACTION_REMOVE, DragItemType.ACTION_EDIT, DragItemType.ACTION_PIN],
		drop: dropHandler,
		canDrop,
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
	});

	const isActiveAction = stageDropActions.isOver && stageDropActions.canDrop;
	if (isActiveAction) droppable = true;

	const handleRef = (element) => {
		box.current = element;
		dropAction(element);
	}

	return (
		<ResizeContext.Provider value={{ resize: updateHeight }}>
			<div
				ref={handleRef}
				className={className('widget-container', fromProps(props), { droppable, expandable })}
				style={props.style}
				transform={props.transform}
				onMouseDown={props.onMouseDown}
				onMouseUp={props.onMouseUp}
			>
				<div className={className("header", DRAG_HANDLE_CLASS)}>
					<div className="title" title={widgetData && widgetData.title || widget && widget.title()}>
						{widgetData && widgetData.title || widget && widget.title()}
					</div>
					<ActionBar className={DRAG_CANCEL_CLASS}>
						{widget && widget.actions && widget.actions(onActionChange)}
						<ActionMenu
							opened={openedMenu}
							setOpened={setOpenedMenu}
							onClose={() => setTypeMenu(null)}
							onChange={onActionChange}
							title={f('menu')}
							keepOpen={true}
						>
							{contentMenuButton}
						</ActionMenu>
					</ActionBar>
				</div>
				<div className="content">
					<div className={className('static', { 'fullHeight': widget && widget.fullHeight || isDynamicWidget, 'hasDynamicContent': hasDynamicContent })}>
						{child}
					</div>
					{
						expanded
						&& widget
						&& expandable
						&& hasDynamicContent
						&& (
							<div className="dynamic">
								{widget.dynamicContent()}
							</div>
						)
					}
				</div>
				{widget
					&& widget.dynamicContent
					&& expandable
					&& <div className="footer clickable">
						<div className="arrow-button" onClick={onArrowButtonClick}>
							<img className={className({ 'rotate': expanded })} src={arrowIcon} title="" alt="" />
						</div>
					</div>
				}
				{layout.isResizable && props.children[1]}
			</div>
		</ResizeContext.Provider>
	);
}

export default WidgetContainer;
