import Resolver from '../Resolver';
import { actions } from '../actions';

const getter = (historyTemperature) => {
	return {
		latitude: historyTemperature.latitude,
		longitude: historyTemperature.longitude
	}
}

let resolver = null;
const connect = (store) => {
	resolver = new Resolver(store, actions.temperatureHistory.exportProgress, getter);
}

export { resolver, connect }