import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { store } from './core/redux/store';
import I18nApp from './I18nApp';
import * as serviceWorker from './serviceWorker';
import MultiBackend, { TouchTransition } from 'react-dnd-multi-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

const HTML5toTouch = {
	backends: [
		{
			backend: HTML5Backend
		},
		{
			backend: TouchBackend,
			preview: true,
			options: {
				enableMouseEvents: true,
				delayTouchStart: 150,
				delayMouseStart: 0,
				ignoreContextMenu: true
			},
			transition: TouchTransition
		}
	]
};

ReactDOM.render(
	<Provider store={store}>
		<DndProvider backend={MultiBackend} options={HTML5toTouch}>
			<I18nApp />
		</DndProvider>
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
