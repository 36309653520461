export const DeviceStatus = {
	Stale: "Stale",
	Offline: "Offline",
	Online: "Online",
	Active: "Active",
	Idle: "Idle"
};

const MINUTE = 60 * 1000;

/**
 * @param {cx.ods.devices.MessageDetails} message
 * @param {Date} now
 */
export const status = (message, now = new Date()) => {
	if (message) {
		const timePassed = now.getTime() - message.generatedAt.getTime();
		if (timePassed <= MINUTE) {
			if (message.speed > 3) {
				return DeviceStatus.Active;
			} else { // not greater than 3 or unknown
				if (message.ignition) {
					return DeviceStatus.Idle;
				} else {
					return DeviceStatus.Online;
				}
			}
		} else if (timePassed <= 15 * MINUTE) {
			return DeviceStatus.Offline;
		}
	}
	return DeviceStatus.Stale;
}