import React from 'react';
import { Container, Content } from 'rsuite';
import WorkspaceLayout from './WorkspaceLayout';

function GeneralLayout(props) {

	return (
		<WorkspaceLayout>
			<Container>
				<Content>{props.children}</Content>
			</Container>
		</WorkspaceLayout>
	);
}

export default GeneralLayout;