import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import Chart from "react-apexcharts";
import { deviceStateColorActive, deviceStatusColorOffline } from '../../../../defaults.scss';
import { useI18n } from '../../../../../i18n';
import { textColorMain } from '../../../../defaults.scss';
import ObjectActionDialogPopup from '../../../general/ObjectActionDialogPopup';
import { cx } from '../../../../api';
import RecentDataEvent from './RecentDataEvent';

/**
 * @param {Object} props
 * @param {Object} props.digest
 * @param {string} props.uri
 */
function DeviceDigestGraphsView(props) {
	let content = null;
	const { f, fc } = useI18n();
	const box = useRef();
	const [state, setState] = useState(null);
	const [intent, setIntent] = useState(null);
	const ref = useRef(null);
	const indexRef = useRef(null);

	const closePopup = () => {
		setIntent(null);
		if (ref.current && indexRef.current != null) {
			ref.current.chart.toggleDataPointSelection(0, indexRef.current);
			indexRef.current = null;
		}
	}

	useEffect(() => {
		const handleClick = (event) => {
			const insideBox = cx.dom.i.traverse(box.current, (node) => node == event.target);
			if (!insideBox) closePopup();
		}
		intent && window.addEventListener('click', handleClick);
		return () => window.removeEventListener('click', handleClick);
	}, [intent]);

	useEffect(() => {
		if (props.eventTypeMap) {
			const eventValues = [];
			const eventNames = [];
			const colors = [];
			props.digest && props.digest.eventDigests && props.digest.eventDigests.forEach(digest => {
				const eventType = props.eventTypeMap[digest.eventType];
				eventValues.push(digest.quantity);
				eventNames.push(fc({ prefix: 'device-event' , id: eventType.name }))
				colors.push(eventType.incident ? deviceStatusColorOffline : deviceStateColorActive);
			});

			const dataPointSelection = (event, chartContext, config) => {
				const selectedIndex = indexRef.current = config.selectedDataPoints[0][0];
				if (selectedIndex != null) {
					const data = {
						offset: {
							left: event.clientX,
							top: event.clientY
						}
					};
					setIntent({ subject: 'eventDigest', eventDigest: props.digest.eventDigests[selectedIndex], data });
				} else closePopup();
			}

			const newState = {
				series: [{
					name: fc('quantity'),
					data: eventValues
				}],
				options: {
					colors: colors,
					dataLabels: {
						offsetY: -25,
						style: {
							fontSize: '15px',
							fontFamily: 'inherit',
							fontWeight: 'bold',
							colors: eventNames.map(name => textColorMain)
						},
						dropShadow: true
					},
					chart: {
						events: {
							dataPointSelection,
						}
					},
					plotOptions: {
						bar: {
							columnWidth: '40%',
							distributed: true,
							dataLabels: {
								position: 'top'
							}
						}
					},
					fill: {
						opacity: 1
					},
					legend: {
						show: false
					},
					xaxis: {
						categories: eventNames,
						position: 'bottom',
						axisBorder: {
							show: false
						},
						axisTicks: {
							offsetX: 1,
							offsetY: -1
						},
						labels: {
							trim: true,
							style: {
								fontWeight: 'bold',
								fontFamily: 'inherit',
								colors: eventNames.map(name => textColorMain)
							}
						}
					},
					yaxis: {
						axisBorder: {
							show: true
						},
						forceNiceScale: true,
						max: (max) => max === 1 ? max + 2 : Math.ceil(max * 1.01),
						labels: {
							show: true,
							formatter: (val) => val.toFixed(),
							style: {
								fontWeight: 'bold',
								fontFamily: 'inherit',
								colors: eventValues.map(name => textColorMain)
							}
						}
					},
					title: {
						text: fc('recent events digest'),
						align: 'left',
						style: {
							fontSize: '16px',
							color: textColorMain,
							fontFamily: 'inherit'
						}
					}
				}
			}
			setState(newState);
		}
	}, [props.digest]);

	if (state) {
		content = (
			<Chart
				options={state.options}
				series={state.series}
				type="bar"
				width="100%"
				height={300}
				ref={ref}
			/>
		);
	}

	return (
		<div ref={box} className="device-digest-graphs-view">
			<div>{content}</div>
			{intent && intent.subject == 'eventDigest' && (
				<ObjectActionDialogPopup
					offset={intent.data.offset}
					width={'auto'}
					onClose={closePopup}
					preventAutoClose
					title={f({ prefix: 'device-event' , id: props.eventTypeMap[intent.eventDigest.eventType].name })}
				>
					<RecentDataEvent digest={intent.eventDigest} uri={props.uri} />
				</ObjectActionDialogPopup>
			)}
		</div>
	);
}

export default connect(state => ({
	eventTypeMap: state.registry.eventTypes.typeMap
}))(DeviceDigestGraphsView);
