import React, { useEffect, useState, useRef } from 'react';
import { useI18n } from '../../../../../../i18n';
import { colorPrimary } from '../../../../../defaults.scss';
import { formatter } from '../../../../../misc/datetime';
import {
	XYPlot,
	VerticalGridLines,
	HorizontalGridLines,
	XAxis,
	YAxis,
	AreaSeries,
	MarkSeries,
	Hint,
	DiscreteColorLegend,
	Borders,
	LineSeries
} from 'react-vis';
import Highlight from './Highlight';
import Tooltip from './Tooltip';
import CustomAxisLabel from './CustomAxisLabel';
import { decimalRound } from '../../../../../misc/misc';
import Panning from './Panning';
import { FaHandPaper } from 'react-icons/fa';
import { FiZoomIn } from 'react-icons/fi';
import { FiRotateCcw } from 'react-icons/fi';
import { className } from '../../../../../lib/className';
import ActionExpand from '../../../../share/actionbar/ActionExpand';
import InterActions from './InterActions';
import '../../../../../../../node_modules/react-vis/dist/style.css';
import './fuelLevelBalanceChart.scss';

const VALUES = {
	rawFuelLevel: {
		title: 'raw fuel level',
		color: '#808080',
		dataName: 'rawFuelLevel'
	},
	fuelLevel: {
		title: 'fuel level',
		color: '#ff0000',
		dataName: 'fuelLevel'
	},
	balance: {
		title: 'balance',
		color: colorPrimary,
		dataName: 'balance'
	},
	drained: {
		title: 'drained',
		color: '#F7B751',
		dataName: 'drained'
	},
	refueled: {
		title: 'refueled',
		color: '#74D659',
		dataName: 'refueled'
	},
	consumed: {
		title: 'consumed',
		color: '#ffe600',
		dataName: 'consumed'
	}
}

const MISC = {
	selected: {
		color: '#808080'
	},
	chartSpaceExtension: {
		color: '#00000000'
	}
}

const INTERACTIONS = {
	zoom: {
		title: 'zoom',
		name: 'ZOOM',
		icon: FiZoomIn
	},
	panning: {
		title: 'panning',
		name: 'PANNING',
		icon: FaHandPaper
	},
	reset: {
		title: 'reset',
		name: 'RESET',
		icon: FiRotateCcw
	}
}

const CHART_TOOLS_WIDTH = 150;

/**
 * @param {Object} props
 * @param {Array.<cx.ods.reports.TripHistoryReportEntry>} props.data
 * @param {string} props.uri
 * @param {function} props.onDataPointClick
 * @param {number} [props.selectedIndex]
 */

function FuelLevelBalanceChart(props) {
	const boxRef = useRef();
	const [fullscreen, setFullscreen] = useState(false);
	const [interactionName, setInteractionName] = useState(INTERACTIONS.zoom.name);
	const [lastDrawLocation, setLastDrawLocation] = useState(null);
	const [disabledSeries, setDisabledSeries] = useState({});
	const [selectedAreaPoints, setSelectedAreaPoints] = useState();
	const [tooltipValue, setTooltipValue] = useState(null);
	const [height, setHeight] = useState(null);
	const [width, setWidth] = useState(null);
	const { f, fc } = useI18n();

	const getStartEnd = (first, next) => {
		const isTrip = !!first.trip;
		const startMessage = isTrip ? first.trip.startMessage : first.startMessage;
		let lastMessage = null;
		if (next) {
			const isTrip = !!next.trip;
			lastMessage = isTrip ? next.trip.startMessage : next.startMessage;
		} else {
			lastMessage = isTrip ? first.trip.lastMessage : first.lastMessage;
		}
		return [startMessage, lastMessage];
	}

	const toggleFullscreen = () => {
		setFullscreen(!fullscreen);
		setHeight(null);
		setWidth(null);
	}

	useEffect(() => {
		const resetSize = () => {
			setHeight(null);
			setWidth(null);
		}
		window.addEventListener('resize', resetSize);
		return () => {
			window.removeEventListener('resize', resetSize);
		}
	}, []);

	useEffect(() => {
		if (!height || !width) {
			setHeight(boxRef.current.clientHeight);
			setWidth(boxRef.current.clientWidth);
		}
	}, [height, width]);

	useEffect(() => {
		if (interactionName == INTERACTIONS.reset.name) {
			setLastDrawLocation(null);
			setInteractionName(null);
		}
	}, [interactionName]);

	let chartSpaceExtension = [];
	let state = {};
	const minMaxValues = {
		minY: 0,
		maxY: 0,
		minX: 0,
		maxX: 0
	}
	if (height && width && props.data && props.data.length > 0) {
		const balanceData = [];
		const drainedData = [];
		const refueledData = [];
		const consumedData = [];
		const fuelLevelData = [];
		const rawFuelLevelData = [];
		let summ = 0;
		const calculateMaxY = (value) => {
			if (minMaxValues.maxY < value) minMaxValues.maxY = value;
			return value;
		}
		const calculateMinY = (value) => {
			if (value < minMaxValues.minY) minMaxValues.minY = value;
			return value;
		}
		const calculateValue = (value) => {
			return calculateMaxY(calculateMinY(value));
		}
		props.data.forEach((entry, i) => {
			const index = i + 1;
			if (entry.rawFuelLevelPoints && !disabledSeries[VALUES.rawFuelLevel.dataName]) {
				entry.rawFuelLevelPoints.forEach(point => {
					rawFuelLevelData.push({ y: calculateValue(point.value), x: point.timeAt });
				});
			}
			if (entry.fuelLevelPoints  && !disabledSeries[VALUES.fuelLevel.dataName]) {
				entry.fuelLevelPoints.forEach(point => {
					fuelLevelData.push({ y: calculateValue(point.value), x: point.timeAt });
				});
			}
			const drained = [];
			const refueled = [];
			const consumed = [];
			const [startMessage, lastMessage] = getStartEnd(entry, props.data[i + 1]);
			const fuelLevelBalance = entry.fuelLevelBalance;
			// first data -------------------------
			if (i == 0) {
				fuelLevelBalance && fuelLevelBalance.initial && (summ = fuelLevelBalance.initial);
				balanceData.push({
					...VALUES.balance,
					x: startMessage.generatedAt,
					y: summ,
					y0: 0
				});
				minMaxValues.minX = startMessage.generatedAt.getTime();
			}
			// calculate summary -------------------
			const fuelLevel = fuelLevelBalance && (fuelLevelBalance.consumed + fuelLevelBalance.drained - fuelLevelBalance.refueled) || 0;
			summ -= fuelLevel;
			// fuelLevelBalance data points -------------------
			if (fuelLevelBalance) {
				if (fuelLevelBalance.consumed && !disabledSeries[VALUES.consumed.dataName]) {
					consumed.push({
						...VALUES.consumed,
						x: startMessage.generatedAt,
						y: calculateValue(summ + fuelLevelBalance.consumed),
						y0: summ,
						index
					});
					consumed.push({
						...VALUES.consumed,
						x: lastMessage.generatedAt,
						y: summ + fuelLevelBalance.consumed,
						y0: summ,
						index
					});
				}
				if (fuelLevelBalance.refueled && !disabledSeries[VALUES.refueled.dataName]) {
					const at = !disabledSeries[VALUES.consumed.dataName]
						&& fuelLevelBalance.consumed
						&& fuelLevelBalance.consumed < 0
						? summ + fuelLevelBalance.consumed
						: summ
					;
					refueled.push({
						...VALUES.refueled,
						x: startMessage.generatedAt,
						y: calculateMinY(at - fuelLevelBalance.refueled),
						y0: at,
						index
					});
					refueled.push({
						...VALUES.refueled,
						x: lastMessage.generatedAt,
						y: at - fuelLevelBalance.refueled,
						y0: at,
						index
					});
				}
				if (fuelLevelBalance.drained && !disabledSeries[VALUES.drained.dataName]) {
					const at = !disabledSeries[VALUES.consumed.dataName]
						&& fuelLevelBalance.consumed
						&& 0 < fuelLevelBalance.consumed
						? summ + fuelLevelBalance.consumed
						: summ
					;
					drained.push({
						...VALUES.drained,
						x: startMessage.generatedAt,
						y: calculateMaxY(at + fuelLevelBalance.drained),
						y0: at,
						index
					});
					drained.push({
						...VALUES.drained,
						x: lastMessage.generatedAt,
						y: at + fuelLevelBalance.drained,
						y0: at,
						index
					});
				}
			}
			// balance data points -------------------
			if (!disabledSeries[VALUES.balance.dataName]) {
				balanceData.push({
					...VALUES.balance,
					x: startMessage.generatedAt,
					y: calculateValue(summ),
					y0: 0,
					index
				});
				balanceData.push({
					...VALUES.balance,
					x: lastMessage.generatedAt,
					y: summ,
					y0: 0,
					index
				});
			}
			drained.length > 0 && drainedData.push(drained);
			refueled.length > 0 && refueledData.push(refueled);
			consumed.length > 0 && consumedData.push(consumed);
		});
		//  the order is important -----------------------
		// first: balance
		state = {
			[VALUES.balance.dataName]: [balanceData],
			[VALUES.drained.dataName]: drainedData,
			[VALUES.refueled.dataName]: refueledData,
			[VALUES.consumed.dataName]: consumedData,
			[VALUES.rawFuelLevel.dataName]: [rawFuelLevelData],
			[VALUES.fuelLevel.dataName]: [fuelLevelData]
		};
		const [startMessage, lastMessage] = getStartEnd(props.data[0], props.data[1]);
		chartSpaceExtension = [
			{ x: startMessage.generatedAt, y: minMaxValues.maxY = minMaxValues.maxY * 1.05 },
			{ x: lastMessage.generatedAt, y: minMaxValues.minY = minMaxValues.minY * 1.1 }
		];
		// write max X value
		const messages = getStartEnd(props.data[props.data.length - 1]);
		minMaxValues.maxX = messages[0].generatedAt.getTime();
	}

	useEffect(() => {
		if (height && width && props.selectedIndex && props.data && props.data[props.selectedIndex - 1]) {
			const selectedData = [];
			const [startMessage, lastMessage] = getStartEnd(props.data[props.selectedIndex - 1], props.data[props.selectedIndex]);
			const yPointValue = chartSpaceExtension[0].y;
			selectedData.push({ y: yPointValue, x: startMessage.generatedAt });
			selectedData.push({ y: yPointValue, x: lastMessage.generatedAt });
			setSelectedAreaPoints(selectedData);
		} else {
			setSelectedAreaPoints(null);
		}
	}, [props.selectedIndex, height, width]);

	let content = null;

	if (state[VALUES.balance.dataName]) {
		const getMarkSeries = () => {
			const markSeries = [];
			const filter = (key) => {
				if (
					key == VALUES.balance.dataName
					|| key == VALUES.drained.dataName
					|| key == VALUES.refueled.dataName
					|| key == VALUES.consumed.dataName
				) {
					return true;
				}
			}
			const onValueClick = (datapoint) => {
				props.onDataPointClick(datapoint.index)
			}
			Object.keys(state).filter(filter).forEach(key => {
				state[key].forEach((data, i) => {
					markSeries.push(
						<MarkSeries
							key={key + i}
							color={VALUES[key].color}
							onValueClick={onValueClick}
							onValueMouseOver={setTooltipValue}
							onValueMouseOut={() => setTooltipValue(null)}
							size={3}
							data={data}
						/>
					);
				});
			});
			return markSeries;
		}
		const getAreaSeries = () => {
			const areaSeries = [];
			const filter = (key) => {
				if (
					key == VALUES.balance.dataName
					|| key == VALUES.drained.dataName
					|| key == VALUES.refueled.dataName
					|| key == VALUES.consumed.dataName
				) {
					return true;
				}
			}
			Object.keys(state).filter(filter).forEach(key => {
				state[key].forEach((data, i) => {
					areaSeries.push(<AreaSeries opacity={0.3} key={key + i} color={VALUES[key].color} data={data} />);
				});
			});
			return areaSeries;
		}
		const getLineSeries = () => {
			const lineSeries = [];
			Object.keys(state).forEach(key => {
				state[key].forEach((data, i) => {
					lineSeries.push(<LineSeries strokeWidth={1} key={key + i} color={VALUES[key].color} data={data} />);
				});
			});
			return lineSeries;
		}

		const getLegendItems = () => {
			const items = Object.values(VALUES).filter(value => disabledSeries[value.dataName] || (state[value.dataName][0] && state[value.dataName][0].length > 0));
			return items.map(item => {
				item.title = fc(item.title);
				item.disabled = disabledSeries[item.dataName];
				return item;
			});
		}

		const onLegendItemClick = (objValue) => {
			setDisabledSeries({ ...disabledSeries, [objValue.dataName]: !disabledSeries[objValue.dataName] });
		}

		content = (
			<>
				<XYPlot
					width={width - CHART_TOOLS_WIDTH}
					height={height}
					margin={{ left: 50, right: 20, top: 10, bottom: 45 }}
					xDomain={lastDrawLocation && [lastDrawLocation.left, lastDrawLocation.right]}
					yDomain={lastDrawLocation && [lastDrawLocation.bottom, lastDrawLocation.top]}
				>
					<VerticalGridLines />
					<HorizontalGridLines />
					{selectedAreaPoints && <AreaSeries opacity={0.3} color={MISC.selected.color} data={selectedAreaPoints} />}
					{getAreaSeries()}
					{getLineSeries()}
					<AreaSeries opacity={0} color={MISC.chartSpaceExtension.color} data={chartSpaceExtension} />
					{interactionName == INTERACTIONS.panning.name && <Panning minMaxValues={minMaxValues} onPan={area => setLastDrawLocation(area)} />}
					{interactionName == INTERACTIONS.zoom.name && <Highlight onBrushEnd={area => setLastDrawLocation(area)} />}
					{tooltipValue && <Hint value={tooltipValue}><Tooltip value={tooltipValue} uri={props.uri} /></Hint>}
					{getMarkSeries()}
					<Borders style={{ all: {fill: '#fff'} }} />
					<XAxis
						tickTotal={decimalRound((width - CHART_TOOLS_WIDTH) / 130)}
						tickSizeInner={0}
						style={{ text: { stroke: 'none', fontWeight: 600 }, line: {stroke: '#707070'}, ticks: { stroke: '#707070' } }}
						tickFormat={(v, i, scale, tickTotal) => {
							const tickValues = scale.ticks(tickTotal);
							const prevDate = tickValues[i-1] ? new Date(tickValues[i-1]) : null;
							return (<tspan className="unselectable">{formatter.relative(new Date(v), prevDate).value}</tspan>);
						}}
					/>
					<YAxis
						style={{ text: { stroke: 'none', fontWeight: 900 }, line: {stroke: '#707070'}, ticks: { stroke: '#707070' } }}
						tickSizeInner={0}
						tickFormat={(v) => <tspan className="unselectable">{v}</tspan>}
					/>
					<CustomAxisLabel title={fc('fuel') + ', ' + f({ prefix: 'units', id: '%' })} />
				</XYPlot>
				<div className="chart-tools" style={{ height, width: CHART_TOOLS_WIDTH }}>
					<InterActions
						appendInterActions={<ActionExpand expanded={fullscreen} onClick={toggleFullscreen} />}
						interactions={Object.values(INTERACTIONS)}
						selectedName={interactionName}
						onSelect={setInteractionName}
					/>
					<DiscreteColorLegend width={143} items={getLegendItems()} onItemClick={onLegendItemClick} />
				</div>
			</>
		);
	}

	return (
		<div className={className("fuel-level-balance-chart", { fullscreen })}>
			<div className="wrap">
				<div className="title">
					<label>{fc('fuel balance')}</label>
				</div>
				<div className="chart-container unselectable" ref={boxRef} onDragStart={(e) => e.preventDefault()}>
					{content}
				</div>
			</div>
		</div>
	);
}

export default FuelLevelBalanceChart;