import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { className, fromProps } from '../../../../lib/className';
import List from '../../../general/list/List';
import { generateId } from '../../../../misc/misc';
import { actions } from '../../../../redux/api/deviceFilteredEvents';
import { cx } from '../../../../api';
import Loader from '../../../general/Loader';
import DatetimeField from '../../../general/datetime/DatetimeField';
import Address from '../../../general/location/Address';
import ListItem from '../../../general/list/ListItem';
import { FiMoreHorizontal } from 'react-icons/fi';
import './recentDataEvent.scss';

const EVENTS_LIMIT = 11;

const createEventFilter = (digest) => {
	const filter = new cx.ods.devices.EventFilter();
	filter.maxGeneratedAt = digest.lastAt;
	filter.eventTypes = [digest.eventType];
	filter.window = new cx.ods.devices.EventWindowFilter();
	filter.window.forward = false;
	filter.window.size = digest.quantity <= EVENTS_LIMIT ? digest.quantity : EVENTS_LIMIT; // limit max quantity
	return filter;
}

function LinkListItem() {
	return (
		<ListItem className="see-more">
			<span><FiMoreHorizontal size={48} /></span>
		</ListItem>
	)
}

function EventListItem(props) {
	return (
		<ListItem className="event-data-item">
			<DatetimeField datetime={props.event.generatedAt} />
			{props.event.message && <Address message={props.event.message} />}
		</ListItem>
	)
}

/**
 * @param {Object} props
 * @param {Object} props.digest
 * @param {string} props.uri
 */

function RecentDataEvent(props) {
	const uid = useRef(generateId());
	let content = null;
	const events = props.deviceFilteredEvents && props.deviceFilteredEvents[uid.current];

	useEffect(() => {
		return () => props.dispatch(actions.clear({ uri: props.uri, uid: uid.current }));
	}, []);

	useEffect(() => {
		props.dispatch(actions.clear({ uri: props.uri, uid: uid.current }));
		props.dispatch(actions.find.request({ uri: props.uri, uid: uid.current, filter: createEventFilter(props.digest) }));
	}, [props.uri, props.digest]);

	if (events) {
		if (events.list) {
			content = events.list.map(event => <EventListItem event={event} key={event.eventId} />);
			if (events.list.length == EVENTS_LIMIT) content.push(<LinkListItem key={Number.MAX_SAFE_INTEGER} />);
		} else if (events.pending) {
			content = (
				<Loader size={Loader.Size.SM} />
			);
		}
	}

	return (
		<div className={className("recent-data-event", fromProps(props))}>
			{props.title
				&& <div className="title">
					<label>
						<span className="capitalize">
							{props.title}
						</span>
					</label>
				</div>
			}
			<List>
				{content}
			</List>
		</div>
	);
}

export default connect((state, props) => ({
	deviceFilteredEvents: state.deviceFilteredEvents[props.uri]
}))(RecentDataEvent);
