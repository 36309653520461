import { Fill, Stroke, Style, Text } from 'ol/style';
import { FeatureLike } from 'ol/Feature';
import GeometryType from 'ol/geom/GeometryType';
import CircleStyle from 'ol/style/Circle';

const getLabelStyle = (label: string | null) =>
  label
    ? {
        text: new Text({
          text: label,
          font: 'bold 11px Muli',
          placement: 'point',
          fill: new Fill({ color: '#000' }),
          stroke: new Stroke({ color: '#fff', width: 2 }),
        }),
      }
    : {};

export const getVectorStyles = (color: string, labelKey?: string) => (feature: FeatureLike): Style => {
  const type = feature?.getGeometry()?.getType();

  const label = labelKey ? feature.get(labelKey) : null;

  return type === GeometryType.POINT
    ? new Style({
        image: !label ? new CircleStyle({ radius: 3, fill: new Fill({ color }) }) : undefined,
        ...getLabelStyle(label),
      })
    : new Style({ stroke: new Stroke({ color, width: 2 }) });
};
