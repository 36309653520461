import { combineEpics } from 'redux-observable';
import { ofType } from 'redux-observable';
import { map as rxmap, filter } from 'rxjs/operators';
import { store } from '../../../redux/store';
import { actions as stateActions } from './state';
import { ActionGeneratorBuilder } from '../../actions';

class HistoryPlayer {
	static instance = null;

	static getInstance() {
		if (!HistoryPlayer.instance) HistoryPlayer.instance = new HistoryPlayer();
		return HistoryPlayer.instance;
	}

	constructor(options) {
		Object.assign(this, {
			period: 1000, minInterval: 100
		}, options);
	}

	play() {
		if (!this.timerId) this.scheduleUpdate();
	}

	scheduleUpdate() {
		if (this.timerId) clearTimeout(this.timerId);
		const {playing, speed} = store.getState().timeMachine.player;
		if (!playing || speed == 0) this.timerId = null;
		else {
			const interval = Math.max(Math.abs(this.period / speed), this.minInterval);
			this.intervalStart = Date.now();
			this.timerId = setTimeout(() => {
				this.timerId = null;
				this.update();
			}, interval);
		}
	}

	update() {
		const period = (Date.now() - this.intervalStart) * store.getState().timeMachine.player.speed;
		const params = store.getState().timeMachine.state.parameters;
		const nowTime = params.now.getTime()  + period, sinceTime = params.since.getTime(), untilTime = params.until.getTime();
		if (sinceTime < nowTime && nowTime < untilTime) {
			store.dispatch(stateActions.setNow({ now: new Date(nowTime)}));
			this.scheduleUpdate();
		} else {
			store.dispatch(stateActions.setNow({ now: nowTime <= sinceTime ? params.since : params.until }));
			store.dispatch(actions.stop());
		}
	}

	stop() {
		if (this.timerId) {
			clearTimeout(this.timerId);
			this.timerId = null;
		}
	}
}

const HistorySubject = {
	Message: 1,
	Event: 2
};

const defaultState = {
	playing: false,
	speed: 1,
	subject: HistorySubject.Message
};

const actions = new ActionGeneratorBuilder('timeMachinePlayer')
	.type('play')
	.type('stop')
	.type('stepBack')
	.type('stepForward')
	.type('skipBack')
	.type('skipForward')
	.type('setSpeed', 'speed')
	.type('setSubject', 'subject')
	.build()
;

const reducer = (state = defaultState, action) => {
	switch (action.type) {
		case actions.play.type:
			state = {
				...state,
				playing: true
			};
			break;
		case actions.stop.type:
			state = {
				...state,
				playing: false
			};
			break;
		case actions.setSpeed.type:
			state = {
				...state,
				speed: action.speed
			};
			break;
		case actions.setSubject.type:
			state = {
				...state,
				subject: action.subject
			};
			break;
	}
	return state;
}

const playEpic = (action$) => {
	return action$.pipe(
		ofType(actions.play.type),
		rxmap(action => HistoryPlayer.getInstance().play()),
		filter(() => false)
	);
}

const stopEpic = (action$) => {
	return action$.pipe(
		ofType(actions.stop.type),
		rxmap(action => HistoryPlayer.getInstance().stop()),
		filter(() => false)
	);
}

const skipBackEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.skipBack.type),
		rxmap(action => {
			const params = state$.value.timeMachine.state.parameters;
			return stateActions.setParameters({ since: params.since, until: params.until, now: params.since, uris: params.uris });
		})
	);
}

const skipForwardEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.skipForward.type),
		rxmap(action => {
			const params = state$.value.timeMachine.state.parameters;
			return stateActions.setParameters({ since: params.since, until: params.until, now: params.until, uris: params.uris });
		})
	);
}

const stepBackEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.stepBack.type),
		rxmap(action => {
			const subject = state$.value.timeMachine.player.subject;
			return stateActions.vicinityDate({ forward: false, subject });
		})
	);
}

const stepForwardEpic = (action$, state$) => {
	return action$.pipe(
		ofType(actions.stepForward.type),
		rxmap(action => {
			const subject = state$.value.timeMachine.player.subject;
			return stateActions.vicinityDate({ forward: true, subject });
		})
	);
}

const epic = combineEpics(
	playEpic, stopEpic,
	stepBackEpic, stepForwardEpic,
	skipBackEpic, skipForwardEpic
);

export { actions, reducer, epic, HistorySubject };
