import React, { useEffect, useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import { className, fromProps } from '../../../../../lib/className';
import Loader from '../../../../general/Loader';
import { useI18n } from '../../../../../../i18n';
import { actions as stomachActions } from '../../../../../redux/api/stomach';
import BarChart from '../aux/BarChart';
import DeviceTop from '../aux/DeviceTop';
import { cx } from '../../../../../api';
import DigestConfiguration from '../aux/DigestConfiguration';
import WidgetDataSignature from '../aux/WidgetDataSignature';
import ActionLockedDevices from '../../../../general/widgets/ActionLockedDevices';
import WidgetMenuItem from '../../../../general/widgets/WidgetMenuItem';
import * as widgets from '../../../../../redux/app/widgets';
import CategoriesControl from '../../../../share/categories/CategoriesControl';
import RecentEvents from './RecentEvents';
import buildParameters from '../aux/buildParameters';
import ActionLock from '../../../../share/actionbar/ActionLock';
import './eventsWidget.scss';

/**
 * @param {Object} props
 * @param {string} props.uid
 * @param {React.RefObject} props.customRef
 * @param {function} props.sizeChange ( uid, { w, h } )
 * @param {function} props.update to rerender WidgetContainer
 */

const PIN_ACTIONS = { lock: 'lockDevices' };

function EventsWidget(props) {
	const { fc } = useI18n();
	let content = null;
	let widgetTitle = fc('events');

	const eventData = props.events;
	const eventDigest = eventData && eventData.eventDigest;

	const widgetData = props.widget.data;
	const layout = props.widget.layout;
	const locked = !!(widgetData && widgetData.uris);
	const uris = locked
		? widgetData.uris
		: (props.selection ? props.selection : [])
	;

	if (props.eventTypeCategories.map && widgetData && widgetData.categoryIds && widgetData.categoryIds.length > 0) {
		const categoryMap = props.eventTypeCategories.map;
		widgetTitle = categoryMap[widgetData.categoryIds[0]].name;
		for (let i = 1; i < widgetData.categoryIds.length; ++i) {
			widgetTitle += (', ' + categoryMap[widgetData.categoryIds[i]].name);
		}
	}

	const lockHandler = () => {
		props.dispatch(widgets.actions.update({ domain: props.reduxKey, uid: props.uid, data: { uris: locked ? null : uris } }));
	}

	const setPinnedActions = (actionName, pinned) => {
		const pinnedActions = props.widgetData && props.widgetData.pinnedActions ? { ...props.widgetData.pinnedActions } : {};
		pinnedActions[actionName] = pinned;
		props.dispatch(widgets.actions.update({ domain: props.reduxKey, uid: props.uid, data: { pinnedActions } }));
	}

	useImperativeHandle(props.customRef, () => ({
		title: () => widgetTitle,
		actions: (onChange) => {
			const actions = [];
			if (locked) {
				actions.push(
					<ActionLockedDevices
						uris={widgetData.uris}
						onChange={onChange}
						key="action-uris"
						emptyMessage={fc('all devices')}
					/>
				);
			}
			if (widgetData && widgetData.pinnedActions) {
				if (widgetData.pinnedActions[PIN_ACTIONS.lock]) {
					actions.push(
						<ActionLock
							key={PIN_ACTIONS.lock}
							onClick={lockHandler}
							active={locked}
							title={fc(locked ? 'unlock' : 'lock')}
						/>
					);
				}
			}
			return actions;
		},
		dynamicContent: Boolean(eventData && (eventData.parameters.recents || (eventDigest && eventDigest.deviceTop))),
		canPin: true,
		handlePin: lockHandler,
		menuItems: () => {
			return (
				<WidgetMenuItem
					onClick={lockHandler}
					text={fc(locked ? 'unlock' : 'lock')}
					canPin
					pinned={widgetData && widgetData.pinnedActions && widgetData.pinnedActions[PIN_ACTIONS.lock]}
					onChangePin={(pinned) => setPinnedActions(PIN_ACTIONS.lock, pinned)}
				/>
			);
		},
		configuration: () => {
			return (
				<DigestConfiguration
					uid={props.uid}
				>
					<CategoriesControl
						className="event-categories widget-configuration"
						categoryIds={widgetData ? widgetData.categoryIds : []}
						comprisingIds={[props.eventTypeCategories.root && props.eventTypeCategories.root.categoryId]}
					/>
				</DigestConfiguration>
			);
		}
	}));

	useEffect(() => {
		if (eventDigest && !eventData.pending && props.widget.autoSize) {
			if (!eventData.parameters.recents) {
				const length = eventDigest.timeHistogram && Object.keys(eventDigest.timeHistogram.binsMap).length;
				const copyLayout = { ...layout };
				if (eventDigest.deviceTop) {
					copyLayout.w = (length < 9 ? 7 : (length > 15 ? (length > 21 ? 10 : 9) : 8));
				} else {
					copyLayout.w = (length < 9 ? 4 : (length > 15 ? (length > 21 ? 7 : 6) : 5));
				}
				props.sizeChange(copyLayout);
			} else {
				const length = eventDigest.timeHistogram && Object.keys(eventDigest.timeHistogram.binsMap).length;
				const copyLayout = { ...layout };
				copyLayout.w = (length < 9 ? 6 : (length > 15 ? (length > 21 ? 9 : 8) : 7));
				props.sizeChange(copyLayout);
			}
		}
		props.update();
	}, [eventDigest]);

	useEffect(() => {
		if (widgetData) {
			const parameters = new cx.ods.stomach.EventDigestParameters();
			parameters.filter = new cx.ods.stomach.EventDigestFilter();
			if (widgetData.categoryIds && widgetData.categoryIds.length > 0) {
				const eventTypeFilter = new cx.ods.devices.EventTypeFilter();
				eventTypeFilter.categoryIdRestriction = new cx.ods.categories.CategoryIdsRestriction();
				eventTypeFilter.categoryIdRestriction.categoryIds = widgetData.categoryIds;
				parameters.filter.eventTypeFilter = eventTypeFilter;
			}
			if (uris.length > 0) {
				parameters.filter.uriSetFilter = new cx.ods.devices.DeviceURISetFilter();
				parameters.filter.uriSetFilter.uriSet = uris;
			}
			parameters.histogram = new cx.ods.stomach.TimeHistogramParameters();
			buildParameters(parameters, widgetData);
			if (widgetData.option) {
				if (widgetData.option == 'top') {
					parameters.top = new cx.ods.stomach.DeviceTopParameters();
				} else if (widgetData.option == 'recents') {
					parameters.recents = new cx.ods.stomach.RecentEventsParameters();
				}
				parameters[widgetData.option].size = 5;
			}
			props.dispatch(stomachActions.events.request({ uid: props.uid, parameters }));
		} else {
			const values = {
				timeRange: 0,
				days: 7,
				option: null,
				categoryIds: null
			}
			props.dispatch(widgets.actions.update({ domain: props.reduxKey, uid: props.uid, data: values }));
		}
	}, [widgetData, !locked ? props.selection : null]);

	if (eventDigest != null && eventDigest.timeHistogram) {
		content = (
			<div className={className(
				fromProps(props),
				'widget events',
				{ 'options': !!(eventData.parameters.recents || eventDigest.deviceTop) }
			)}>
				<div>
					<BarChart histogram={eventDigest.timeHistogram} />
					<WidgetDataSignature data={widgetData} />
				</div>
				{eventDigest.deviceTop && <DeviceTop deviceTop={eventDigest.deviceTop} type="events" />}
				{eventData.parameters.recents && <RecentEvents recents={eventDigest.recents} />}
			</div>
		);
	} else {
		content = (<Loader size={Loader.Size.MD} />);
	}

	return (content);
}

export default connect(
	(state, props) => ({
		widget: state.widgets[props.reduxKey].map[props.uid],
		events: state.stomach.events && state.stomach.events[props.uid],
		eventTypeCategories: state.categories.eventTypes,
		selection: state.pages[props.reduxKey] && state.pages[props.reduxKey].selection
	})
)(EventsWidget);
