import { MutableRefObject, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { cx } from 'core/api';
import { IAttributeRangeDetector } from 'types/detectors';
import { actions as processorActions } from 'core/redux/api/processors';
import { attributeRangeDetectorsSelector } from 'core/redux/selectors/processors';
import { useAttribute } from './useAttribute';

interface IUseAttributeDetectorFormProps {
  detector: IAttributeRangeDetector | null;
  pending: MutableRefObject<boolean>;
}

export interface IAttributeDetectorForm {
  fieldId: number | null;
  name: string;
  onIn: boolean;
  inEventType: number | null;
  onOut: boolean;
  outEventType: number | null;
  uris: string[];
  enumeration: string;
  leftBound: string;
  rightBound: string;
  leftOpen: boolean;
  rightOpen: boolean;
  leftLag: string;
  rightLag: string;
}

export const FORM_DEFAULT_VALUES = {
  fieldId: null,
  name: '',
  onIn: false,
  inEventType: 0,
  onOut: false,
  outEventType: 0,
  uris: [],
  enumeration: 'true',
  leftBound: '',
  rightBound: '',
  leftOpen: false,
  rightOpen: false,
  leftLag: '',
  rightLag: '',
};

const actions = processorActions.attributeRange;

export const useAttributeDetectorForm = (props: IUseAttributeDetectorFormProps) => {
  const { detector, pending } = props;

  const history = useHistory();

  const dispatch = useDispatch();
  const { error, pending: isDisabled } = useSelector(attributeRangeDetectorsSelector);

  const formMethods = useForm<IAttributeDetectorForm>({
    defaultValues: FORM_DEFAULT_VALUES,
  });

  const { control, reset } = formMethods;

  const fieldId = useWatch({ control, name: 'fieldId' });
  const attributeId = fieldId ? Number(fieldId) : undefined;

  const { attributeType } = useAttribute(attributeId);

  const resetCommuteDetector = (detector: IAttributeRangeDetector) => {
    reset({
      name: detector.name,
      onIn: detector.onIn,
      inEventType: detector.inEventType || 0,
      onOut: detector.onOut,
      outEventType: detector.outEventType || 0,
      fieldId: detector.fieldId,
      uris: detector.uris || [],
      enumeration: detector.enumeration?.[0] || 'true',
      leftBound: detector.leftBound ? String(detector.leftBound) : '',
      rightBound: detector.rightBound ? String(detector.rightBound) : '',
      leftOpen: !!detector.leftOpen,
      rightOpen: !!detector.rightOpen,
      leftLag: detector.leftLag ? String(detector.leftLag) : '',
      rightLag: detector.rightLag ? String(detector.rightLag) : '',
    });
  };

  const getBounds = (data: Omit<IAttributeDetectorForm, 'enumeration'>) => {
    const { leftBound, rightBound, leftOpen, rightOpen, leftLag, rightLag } = data;

    return {
      ...(leftBound && { leftOpen, leftBound: Number(leftBound), leftLag: leftLag ? Number(leftLag) : null }),
      ...(rightBound && { rightOpen, rightBound: Number(rightBound), rightLag: rightLag ? Number(rightLag) : null }),
    };
  };

  const getAttributeFields = ({ enumeration, ...rest }: IAttributeDetectorForm) => {
    if (!attributeType?.mnemonics) {
      return {};
    }

    return attributeType.mnemonics === 'boolean' ? { enumeration: [enumeration] } : getBounds(rest);
  };

  const onSubmit = formMethods.handleSubmit((formData: IAttributeDetectorForm) => {
    const { name, uris, fieldId, onIn, onOut, inEventType, outEventType } = formData;

    const payload = {
      name,
      uris,
      fieldId,
      onIn,
      onOut,
      inEventType: inEventType || null,
      outEventType: outEventType || null,
      ...getAttributeFields(formData),
    };

    const data = new cx.ods.processors.AttributeRangeDetector(payload);

    detector
      ? dispatch(actions.update.request({ processorId: detector.processorId, data }))
      : dispatch(actions.add.request({ data }));

    pending.current = true;
  });

  const onBack = () => {
    history.goBack();
  };

  useEffect(() => {
    !!detector && resetCommuteDetector(detector);
  }, [detector]);

  useEffect(() => {
    return () => {
      reset(FORM_DEFAULT_VALUES);
    };
  }, []);

  const actionsProps = { isDisabled, title: 'attribute range detector', onBack };
  const attributeFieldsProps = { mnemonics: attributeType?.mnemonics, isDisabled };

  return { isDisabled, actionsProps, attributeFieldsProps, formMethods, error, onSubmit };
};
