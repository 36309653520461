import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AllRoutes } from 'constants/routes';
import { ISSUES_TABS } from 'constants/table';
import { RuleGroup } from 'constants/issues';
import { useEntitiesTable } from 'hooks/useEntitiesTable';
import { actions as runwayActions } from 'core/redux/api/veta/runways';
import { actions as taxiwayActions } from 'core/redux/api/veta/taxiways';
import { actions as issuesActions } from 'core/redux/api/veta/issues';
import { actions as issuesTypesActions } from 'core/redux/api/veta/issueTypes';
import { issuesStateSelector, issuesTypesStateSelector } from 'core/redux/selectors/issues';
import { devicesStateSelector } from 'core/redux/selectors/devices';
import { runwaysStateSelector } from 'core/redux/selectors/runways';
import { useIssuesColumns } from './useIssuesColumns';
import { taxiwaysSelector } from '../../core/redux/selectors/registry';

const ISSUES_TAB_ID = ISSUES_TABS[0].id;

export const useIssuesTable = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const { page, pageSize, tab, searchParams, updatePage, updatePageSize } = useEntitiesTable(AllRoutes.Issues);
  const { page: issuesPage, pending: issuesPending } = useSelector(issuesStateSelector);
  const { pending: issuesTypesPending } = useSelector(issuesTypesStateSelector);
  const { pending: devicesPending } = useSelector(devicesStateSelector);
  const { pending: runwaysPending, list: runways } = useSelector(runwaysStateSelector);
  const { pending: taxiwaysPending, list: taxiways } = useSelector(taxiwaysSelector);

  const currentTabId = useMemo(() => tab || ISSUES_TAB_ID, [searchParams]);

  const columns = useIssuesColumns(currentTabId);

  const isLoading = issuesPending || issuesTypesPending || devicesPending || runwaysPending || taxiwaysPending;

  const handleTabChange = (tabId: string) => {
    push(`${AllRoutes.Issues}?tab=${tabId}`);
  };

  const fetchIssues = () => {
    dispatch(issuesActions.page.request({ ruleGroup: tab || RuleGroup.OPEN_RUNWAY, no: page, size: pageSize.value }));
  };

  const fetchIssuesTypes = () => {
    dispatch(issuesTypesActions.load.request());
  };

  const fetchRunways = () => {
    dispatch(runwayActions.load.request());
  };
  const fetchTaxiways = () => {
    dispatch(taxiwayActions.load.request());
  };

  const tabsProps = { currentTabId, setCurrentTabId: handleTabChange };

  const tableProps = { isLoading, data: issuesPage?.objects || [], columns };

  const tableFooterProps = {
    totalCount: issuesPage?.total || 0,
    pageSize,
    page,
    onPageChange: updatePage,
    updatePageSize,
  };

  useEffect(() => {
    fetchIssuesTypes();
    currentTabId === RuleGroup.TAXIWAY ? !taxiways && fetchTaxiways() : !runways && fetchRunways();
  }, [currentTabId]);

  useEffect(() => {
    fetchIssues();
  }, [searchParams]);

  return { tabsProps, tableProps, tableFooterProps };
};
