import React, { Dispatch, FC } from 'react';

import { IAttributeRangeDetector } from 'types/detectors';
import { useAttributeDetectorListItem } from 'hooks/attribute-range-detectors/useAttributeDetectorListItem';
import Intent from 'core/misc/ObjectActionIntent';
import { className, fromProps } from 'core/lib/className';
import ListItem from 'core/react/general/list/ListItem';

interface IAttributeDetectorListItemProps {
  className: string;
  detector: IAttributeRangeDetector;
  onSelect: Dispatch<IAttributeRangeDetector>;
  onIntent: Dispatch<Intent>;
}

export const AttributeDetectorListItem: FC<IAttributeDetectorListItemProps> = (props) => {
  const { id, dropState, dropRef, name, selectDetector } = useAttributeDetectorListItem(props);

  return (
    <ListItem
      customRef={dropRef}
      className={className(fromProps(props), { droppable: dropState.isOver && dropState.canDrop })}
      onClick={selectDetector}
      id={id}
    >
      {name}
    </ListItem>
  );
};
