import { ofType } from 'redux-observable';
import { mergeMap, map, takeUntil } from 'rxjs/operators';
import { actions } from './actions';
import { api, rx, cx } from '../../../api';
import { errorMap } from '../../actions';

const defaultState = {
	list: null,
	map: null,
	pending: false,
	error: null
}

// -----------------------------------------------------

function reducer(state, action) {
	switch (action.type) {
		case actions.timeZones.request.type:
			return state = {
				...state,
				pending: true,
				error: null
			};
		case actions.timeZones.success.type:
			return state = {
				...state,
				list: action.list,
				map: cx.i.hash(action.list, timeZone => timeZone.zoneId),
				pending: false,
				error: null
			};
		case actions.timeZones.fail.type:
			return state = {
				...state,
				pending: false,
				error: action.errorMessage
			};
		case actions.timeZones.cancel.type:
			return state = {
				...state,
				pending: false
			};
		default:
			return state || defaultState;
	}
}

// --------------------------------------------------------

const epic = (action$) => {
	return action$.pipe(
		ofType(actions.timeZones.request.type),
		mergeMap(action =>
			rx(api.registry.timeZones).pipe(
				map(operation => actions.timeZones.success({ list: operation.response() })),
				errorMap(actions.timeZones.fail),
				takeUntil(action$.pipe(ofType(actions.timeZones.cancel.type)))
			)
		)
	)
}

export { reducer, epic };
