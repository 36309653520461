import React, { Dispatch, DispatchWithoutAction, FC } from 'react';
import { useSelector } from 'react-redux';

import { useI18n } from 'i18n';
import { IAttributeRangeDetector } from 'types/detectors';
import { attributeRangeDetectorsSelector } from 'core/redux/selectors/processors';
import Intent from 'core/misc/ObjectActionIntent';
import ObjectActionDialogPopup from 'core/react/general/ObjectActionDialogPopup';
import RemoveDialog from 'core/react/general/RemoveDialog';

interface IRemoveDetectorDialogueProps {
  intent: Intent | null;
  isDisabled: boolean;
  onRemove: Dispatch<IAttributeRangeDetector>;
  onPopupClose: DispatchWithoutAction;
}

export const RemoveDetectorDialogue: FC<IRemoveDetectorDialogueProps> = (props) => {
  const { intent, isDisabled, onRemove, onPopupClose } = props;

  const { f } = useI18n();

  const { error } = useSelector(attributeRangeDetectorsSelector);

  return intent && intent.action() === Intent.Action.Remove ? (
    <ObjectActionDialogPopup
      offset={intent.data().offset}
      onClose={onPopupClose}
      title={f('delete attribute range detector')}
      disabled={isDisabled}
      error={error}
    >
      <RemoveDialog
        object={intent.object()}
        title={f('attribute range detector')}
        text={f('are you sure you want to delete attribute range detector', { name: intent.object().name })}
        onSubmit={onRemove}
        onCancel={onPopupClose}
      />
    </ObjectActionDialogPopup>
  ) : null;
};
