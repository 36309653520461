import { switchMap, map } from 'rxjs/operators';

import { combineEpics, ofType } from "redux-observable";

import { api, rx } from "../../../../api";

import { deltaReducer, errorMap } from "../../../actions";

const {addActions, getActions} = (() => {
	let builder, actions;
	const addActions = schedules => {
		builder = schedules.subtype('add', add => add.request({schedule: true}).success().fail())
			.subtype('load', load => load.request().success({schedules: true}).fail())
			.subtype('update', update => update.request({scheduleId: true, schedule: true}).success().fail())
			.subtype('remove', remove => remove.request({scheduleId: true}).success().fail())
		;
	};
	const getActions = () => {
		if (!actions) {
			if (!builder) throw new Error('Commute report schedules actions weren\'t registered');
			actions = builder.build();
			builder = null;
		}
		return actions;
	};
	return {addActions, getActions};
})();

const defaultState = {
	list: null
	, map: null
	, pending: false
	, error: null
};

const reducer = deltaReducer((state, action) => {
	const actions = getActions();
	switch (action.type) {
		case actions.add.request.type: 
		case actions.load.request.type:
		case actions.update.request.type:
		case actions.remove.request.type:
			return {
				pending: true, error: undefined
			};
		case actions.load.success.type: 
			return {
				pending: false
				, list: action.schedules
				, map: Object.fromEntries(action.schedules.map(schedule => [schedule.scheduleId, schedule]))
			};
		case actions.add.fail.type:
		case actions.load.fail.type:
		case actions.update.fail.type:
		case actions.remove.fail.type:
			return {
				pending: false, error: action.errorMessage
			};
	}
}, defaultState);

const epic = combineEpics(
	action$ => {
		const actions = getActions();
		return action$.pipe(
			ofType(actions.add.request.type)
			, switchMap(action => rx(api.reports.schedules.commuteHistory.add, action.schedule).pipe(
				map(operation => actions.load.request())
				, errorMap(actions.add.fail)
			))
		);
	}
	, action$ => {
		const actions = getActions();
		return action$.pipe(
			ofType(actions.load.request.type)
			, switchMap(action => rx(api.reports.schedules.commuteHistory.load).pipe(
				map(operation => actions.load.success({schedules: operation.response()}))
				, errorMap(actions.load.fail)
			))
		);
	}
	, action$ => {
		const actions = getActions();
		return action$.pipe(
			ofType(actions.update.request.type)
			, switchMap(action => rx(api.reports.schedules.commuteHistory.update, action.scheduleId, action.schedule).pipe(
				map(operation => actions.load.request())
				, errorMap(actions.update.fail)
			))
		);
	}
	, action$ => {
		const actions = getActions();
		return action$.pipe(
			ofType(actions.remove.request.type)
			, switchMap(action => rx(api.reports.schedules.commuteHistory.remove, action.scheduleId).pipe(
				map(operation => actions.load.request())
				, errorMap(actions.remove.fail)
			))
		);
	}
);

export {addActions, reducer, epic};
