import React, { useEffect, useState } from 'react';
import Toggle from '../../../../general/form/Toggle';
import Slider from '../../../../general/form/Slider';
import { useI18n } from '../../../../../../i18n';
import Form from '../../../../general/form/Form';

/**
 * @param {Object} props
 * @param {boolean} [props.displayTrace]
 * @param {number} props.traceDuration in minutes
 */


function TraceConfiguration(props) {
	const { f } = useI18n();
	const [displayTrace, setDisplayTrace] = useState(false);
	const [traceDuration, setTraceDuration] = useState(props.traceDuration);

	useEffect(() => {
		setDisplayTrace(props.displayTrace !== undefined ? props.displayTrace : false);
		setTraceDuration(props.traceDuration);
	}, [props]);

	return (
		<div className="trace-configuration">
			<Form.Control
				controlType={Toggle}
				controlName="displayTrace"
				label={f('display trace')}
				max={14} min={1}
				value={displayTrace}
				onChange={setDisplayTrace}
			/>
			<Form.Control
				controlType={Slider}
				controlName="traceDuration"
				label={f('trace duration')}
				disabled={!displayTrace}
				max={30} min={1} step={1}
				value={traceDuration}
      			progress
				onChange={setTraceDuration}
			/>
		</div>
	);
}

export default TraceConfiguration;