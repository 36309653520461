import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../redux/api/assets';
import { useI18n } from '../../../../i18n';
import Loader from '../../general/Loader';
import NoDataView from '../../general/view/NoDataView';
import DatetimeField from '../../general/datetime/DatetimeField';
import { designateEmployee } from '../../../redux/api/assets/employees/reducer';
import { className } from '../../../lib/className';
import { modeMap } from '../../../redux/api/assets/commute/reducer';
import { getHistoryManagerByName } from '../../../redux/api/history/managers';
import './commuteIntervalList.scss';


/**
 * @param {Object} props
 * @param {cx.ods.assets.AssetPresenceInterval} props.interval
 * @param {boolean} [props.full]
 */

function _CommuteIntervalListListItem(props) {
	const designation = designateEmployee(props.employee);
	return (
		<React.Fragment>
			<div>
				<span title={designation}>
					{designation}
				</span>
			</div>
			<div>
				{props.interval.startedAt && <DatetimeField datetime={props.interval.startedAt} />}
			</div>
			{props.full && <div>
				{props.interval.endedAt && <DatetimeField datetime={props.interval.endedAt} />}
			</div>}
		</React.Fragment>
	);
}

const CommuteIntervalListListItem = connect((state, props) => ({
	employee: state.assets.employees.map?.[props.interval.assetId]
}))(_CommuteIntervalListListItem);


/**
 * @param {Object} props
 * @param {boolean} [props.full]
 */
function CommuteIntervalListHeaderListItem(props) {
	const { fc } = useI18n();

	const name = fc('designation');
	const inName = fc('in');
	const outName = fc('out');
	return (
		<React.Fragment>
			<div className="header" title={name}>
				<span>{name}</span>
			</div>
			<div className="header" title={inName}>
				<span>{inName}</span>
			</div>
			{props.full && <div className="header" title={outName}>
				<span>{outName}</span>
			</div>}
		</React.Fragment>
	);
}


/**
 * @param {Object} props
 * @param {string} props.uid
 * for timemachine only
 * @param {string} [props.reduxKey]
 * @param {Array.<string>} [props.uris]
 */

function CommuteIntervalList(props) {
	let content = null, full = false;

	useEffect(() => {
		if (!props.employees.map && !props.employees.pending) props.dispatch(actions.employees.load.request());
	}, []);

	if (props.commute?.list?.length > 0 && props.employees.map) {
		full = props.commute.mode == modeMap.RECENT_TRIPS;
		content = [];
		content.push(<CommuteIntervalListHeaderListItem key="header" full={full} />);
		props.commute.list.forEach(interval => {
			content.push(<CommuteIntervalListListItem interval={interval} key={interval.assetId + interval.startedAt.getTime()}  full={full} />);
		});
	} else if (props.commute?.pending) {
		content = (<Loader size={Loader.Size.MD} />);
	} else content = (<NoDataView message="no commuters data" />);

	return (
		<div className={className("commute-interval-list", { filled: Boolean(props.commute?.list?.length > 0), full })}>
			{content}
		</div>
	);
}

const mapStateToProps = (state, props) => {
	let commute;
	if (props.reduxKey == 'timeMachine') {
		let intervals = [];
		const stateMap = state.timeMachine.state.map;
		if (stateMap) {
			props.uris.forEach(uri => {
				if (stateMap[uri]?.assetPresences) intervals = intervals.concat(stateMap[uri].assetPresences);
			});
		}
		intervals.sort(getHistoryManagerByName('assetPresences').compare);
		commute = { list: intervals, mode: modeMap.CURRENT };
	} else commute = state.assets.commute[props.uid];
	return {
		commute,
		employees: state.assets.employees
	}
}

export default connect(mapStateToProps)(CommuteIntervalList);
