import React from 'react';
import { Popover, Whisper } from 'rsuite';

/**
 * @param {Object} props
 * @param {Any} props.onHover
 * @param {function} [props.onClick]
 * @param {Object} [props.style]
 */

function Hover(props) {

	const speaker = (
		<Popover className="custom-hover">
			{props.onHover}
		</Popover>
	);

	return (
		<Whisper preventOverflow placement="top" trigger="hover" speaker={speaker}>
			{props.children}
		</Whisper>
	);
}

export default Hover;