import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { cx } from '../../../../api';
import { DeviceDetailsProxy } from '../../../../api/device'; // eslint-disable-line
import PropertiesControl from '../../../share/properties/runtime/PropertiesControl';

/**
 * @param {Object} props
 * @param {DeviceDetailsProxy} props.device
 */

function DeviceBundlesControl(props) {
	const [bundles, setBundles] = useState(null);
	const operation = useRef(null);

	useEffect(() => {
		operation.current = cx.ods.ws.devices.propertyBundles(props.device.uri);
		operation.current.onready(operation => {
			if (!operation.failed()) {
				setBundles(operation.response());
			} else {
				// TODO
			}
		});
		return () => {
			if (operation.current != null) {
				operation.current.cancel();
				operation.current = null;
			}
		}
	}, []);

	let content = null;
	if (bundles != null) {
		const ids = bundles.map(bundle => bundle.propertyId);
		content = <PropertiesControl bundleIds={ids} data={props.device.properties} />;
	}

	return (content);
}

export default connect(state => ({

}))(DeviceBundlesControl);