import React from 'react';
import { useI18n } from '../../../../../i18n';
import RadioControl from '../../../general/form/RadioControl';

/**
 * @param {Object} props
 * @param {Array.<Object>} props.modes
 * @param {string} props.initialMode
 */

function ModeConfiguration(props) {
	const { f } = useI18n();
	return (
		<RadioControl
			controlName="mode"
			data={props.modes}
			label={f('mode')}
			defaultValue={props.initialMode}
		/>
	);
}

export default ModeConfiguration;
