import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from '../../../general/Loader';
import { useI18n } from '../../../../../i18n';
import ScrollList from '../../../general/list/ScrollList';
import ReportSummary from './ReportSummary';
import ReportHeader from './ReportHeader';
import { actions } from '../../../../redux/api/reports';
import NoDataView from '../../../general/view/NoDataView';
import './deviceUsages.scss';

function HeaderListItem() {
	const { f } = useI18n();
	return (
		<>
			<div className="header-item"><span className="capitalize">{f('item No.')}</span></div>
			<div className="header-item"><span className="capitalize">{f('device')}</span></div>
			<div className="header-item"><span className="capitalize">{f('days')}</span></div>
			<div className="header-item"><span className="capitalize">{f('trips')}</span></div>
			<div className="header-item"><span className="capitalize">{f('distance') + ', ' + f('units.km')}</span></div>
		</>
	)
}

function ReportListItem({usage, index, device}) {
	return (
		<>
			<div>{index}</div>
			<div>{device && device.denomination()}</div>
			<div>{usage.days}</div>
			<div>{usage.trips}</div>
			<div>{usage.distance}</div>
		</>
	)
}

function EodListItem() {
	const { f } = useI18n();
	return <div className="indicator"><span className="capitalize">{f('you have reached the end')}</span></div>;
}

function DeviceUsages(props) {
	const { f } = useI18n();
	let content = null;
	let canExport = false;
	if (props.state.list != null) {
		if (!props.state.list || props.state.list.length == 0) {
			content = <NoDataView message={f('no data for such criteria')} />;
		} else {
			canExport = true;
			let items = props.state.list.map((usage, at) => {
				return (
					<ReportListItem
						key={at}
						usage={usage}
						index={at+1}
						device={props.devices && props.devices[usage.uri]}
					/>
				)
			});
			items.unshift(<HeaderListItem key={1000000} />);
			content = <ScrollList
				pending={props.state.pending}
				isEod={!props.state.hasMore}
				eodItem={EodListItem}
			>
				{items}
			</ScrollList>
		}
	} else {
		content = (
			<div className="center">
				{
					props.state.error
						? <span className="error">{props.state.error}</span>
						: <Loader size={Loader.Size.MD} />
				}
			</div>
		);
	}

	const exportReport = () => {
		props.dispatch(actions.deviceUsages.export());
		props.history.goBack();
	}

	const timeRange = props.state.parameters.timeRange;
	return (
		<div className="report device-usages">
			<ReportHeader title={f('device usages report')} canExport={canExport} onExport={exportReport} />
			<ReportSummary since={timeRange.since} until={timeRange.until} generatedAt={props.state.at} />
			<div className="content">
				{content}
			</div>
		</div>
	)
}

export default withRouter(connect(state => {
	return ({
		state: state.reports.deviceUsages,
		devices: state.devices.map
	})
})(DeviceUsages));
