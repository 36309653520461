import { useFormContext, useWatch } from 'react-hook-form';

import { DEFAULT_SET_VALUE_PROPS } from 'constants/form';
import { useEventTypesOptions } from 'hooks/useEventTypesOptions';
import { IAttributeDetectorForm } from './useAttributeDetectorForm';

export const useAttributeDetectorGeneralFields = () => {
  const { control, setValue, formState } = useFormContext<IAttributeDetectorForm>();
  const { errors } = formState;

  const isOnInRange = useWatch({ control, name: 'onIn' });
  const isOnOutRange = useWatch({ control, name: 'onOut' });

  const eventTypesOptions = useEventTypesOptions();

  const handleRangeChange = (rangeType: string) => (isChecked: boolean) => {
    const isOnInRange = rangeType === 'onIn';

    setValue(isOnInRange ? 'onIn' : 'onOut', isChecked, DEFAULT_SET_VALUE_PROPS);
    setValue(isOnInRange ? 'inEventType' : 'outEventType', 0, DEFAULT_SET_VALUE_PROPS);
  };

  return { control, errors, eventTypesOptions, isOnInRange, isOnOutRange, handleRangeChange };
};
