import React from 'react';
import { useI18n } from '../../../../i18n';
import Address from '../../general/location/Address';
import clockIcon from '../../../img/icons/clock.png';
import DatetimeField from '../../general/datetime/DatetimeField';
import './messageFieldsTimeLocation.scss';

/**
 * @param {Object} props
 * @param {cx.ods.devices.MessageDetails} props.message
 */
function MessageFieldsTimeLocation(props) {
	const { f } = useI18n();
	return (
		<div className="message-fields-time-location">
			<div className="updated-at">
				{!props.bare && <div>{f('updated at')}</div>}
				<div className="v-center">
					<img src={clockIcon} alt="" />
					{props.message && <DatetimeField datetime={props.message.generatedAt} />}
				</div>
			</div>
			<div>
				<div className="v-center">
					<Address message={props.message} full showIcon />
				</div>
			</div>
		</div>
	);
}

export default MessageFieldsTimeLocation;