import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';
import { status } from "../../../lib/device";
import { actions as tmActions } from '../timeMachine/state';
import { ActionGeneratorBuilder } from '../../actions';

const defaultState = {
	map: null // uri => { status: "" }
};

const actions = new ActionGeneratorBuilder('timeMachineDeviceStatus').type('changed', { uri: true, status: true }).build();

// ---------------------------------------------------------

const reducer = (state, action) => {
	switch (action.type) {
		case actions.changed.type:
			const map = { ...state.map };
			map[action.uri] = { status: action.status };
			state = { map };
			break;
	}
	return state || defaultState;
}

// ---------------------------------------------------------

const epic = (action$, state$) => {
	return action$.pipe(
		ofType(tmActions.setState.type),
		switchMap(action => {
			const result = [];
			const state = state$.value.timeMachine.deviceStatus;
			const tmState = state$.value.timeMachine.state;
			const now = state$.value.timeMachine.state.parameters.now;
			let statusObj, oldStatus, newStatus;
			Object.keys(action.map).forEach(uri => {
				statusObj = state.map ? state.map[uri] : null;
				oldStatus = statusObj ? statusObj.status : null;
				newStatus = status(tmState.map[uri].message, now);
				if (newStatus != oldStatus) result.push(actions.changed({ uri, status: newStatus }));
			});
			return result.length > 0 ? of(...result) : EMPTY;
		})
	);
}

// ---------------------------------------------------------

export { actions, reducer, epic };
