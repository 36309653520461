import { actions } from './actions';

const defaultState = {
	map: null, // {uri => StateDetails}
	pending: false,
	error: null
};

export function reducer(state, action) {
	switch (action.type) {
		case actions.find.request.type:
			state = {
				...state,
				pending: true,
				error: null
			}
			break;
		case actions.find.success.type:
			state = {
				...state,
				map: action.map,
				pending: false,
			};
			break;
		case actions.find.fail.type:
			state = {
				...state,
				pending: false,
				error: action.errorMessage
			};
			break;
		case actions.find.cancel.type:
			state = {
				...state,
				pending: false
			};
			break;
	}
	return state || defaultState;
}
