
import {f} from '../../i18n';

import { store } from '../redux/store';
import { cx } from '../api';
import { AbstractObjectProxy, makeProxy } from './proxy';

export class DeviceDetailsProxy extends AbstractObjectProxy {

	denomination() {
		const object = this.getObject();
		return object.name || object.msisdn || object.uri;
	}

	compare(device) {
		if (!device) return -1;
		if (!(device instanceof DeviceDetailsProxy)) device = deviceProxy(device);
		const leftName = this.denomination().toLowerCase(), rightName = device.denomination().toLowerCase();
		return leftName < rightName ? -1 : leftName > rightName ? 1 : 0;
	}

	isEqual(device) {
		const object = this.getObject();
		return device && device.uri == object.uri;
	}

	createInfo() {
		const object = this.getObject();
		const info = new cx.ods.devices.DeviceInfo();
		info.name = object.name;
		info.categoryIds = object.categoryIds ? [...object.categoryIds] : [];
		info.properties = object.properties;
		return info;
	}

	getDevice() {
		return this.getObject();
	}
}

export const deviceProxy = makeProxy(DeviceDetailsProxy);

export const sortUris = (uris) => {
	const map = store.getState().devices.map;
	if (map) {
		const devices = uris.map(uri => map[uri]);
		devices.sort((left, right) => left.compare(right));
		return devices.map(device => device.uri);
	}
	return uris;
};

export const denominate = (devices, maxNamed = 3) => {
	if (!devices) return null;
	if (devices.length == 0) return '';
	const named = devices.slice(0, maxNamed).map(device => device.denomination()).join(', ');
	if (devices.length <= maxNamed) return named;
	return f('DEVICE and X other devices', {device: named, quantity: devices.length - maxNamed});
};
