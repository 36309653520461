import React from 'react';

import { ISVGPersonalProps } from 'types/svg';

export const ringBell: ISVGPersonalProps = {
  viewBox: '0 0 7.736 10',
  content: (
    <g transform="translate(-31.2 -24.9)" fill="currentColor">
      <path
        transform="translate(-11.12 -33.994)"
        d="M47.134,67.947a1.033,1.033,0,0,0-.041-.247H45.241a1.033,1.033,0,0,0-.041.247.944.944,0,0,0,.946.946A.93.93,0,0,0,47.134,67.947Z"
      />
      <path
        transform="translate(0)"
        d="M38.936,32.43h0a.286.286,0,0,0-.1-.206,5.009,5.009,0,0,1-.823-3.251,2.763,2.763,0,0,0-2.243-2.839V25.6a.7.7,0,0,0-1.4,0v.535a2.73,2.73,0,0,0-2.243,2.839,5.009,5.009,0,0,1-.823,3.251.484.484,0,0,0-.1.206h0v.638h7.736Z"
      />
    </g>
  ),
};
