import styled from 'styled-components';

import { Colors } from 'ui/constants/colors';

export const DetectorAttributeStyled = styled.div`
  height: 100%;
  max-width: 380px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${Colors.PurpleCrystal};

  label {
    margin-bottom: 8px;
    display: block;
    font-weight: 700;
  }

  .x-list.list {
    display: block;
  }

  @media (max-width: 890px) {
    border-top: 1px solid ${Colors.PurpleCrystal};
    max-width: 100%;
  }
`;

export const AttributeHeaderStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
`;

export const AttributeContentStyled = styled.div`
  padding: 0 15px 15px;
  overflow-y: auto;

  & > div {
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }

  .x-list.list .list-item {
    width: 100%;
    border-radius: 0;
    background-color: ${Colors.White};
  }

  .x-list .list-item:nth-child(odd) {
    background-color: ${Colors.LynxWhite};
  }
`;

export const AttributeTileStyled = styled.div`
  display: inline-block;
  width: 100%;
  background-color: ${Colors.SuperSilver};
  border-radius: 5px;
  padding: 15px;
`;

export const AttributeRangeStyled = styled.div`
  label {
    margin-bottom: 8px;
    display: block;
    font-weight: 700;
  }
`;
