import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useI18n } from 'i18n';
import { IAttributeRangeDetector } from 'types/detectors';
import { AllRoutes } from 'constants/routes';
import { attributeRangeDetectorsSelector } from 'core/redux/selectors/processors';

export const useAttributeDetectorsView = () => {
  const { fc } = useI18n();

  const history = useHistory();

  const { list, pending: isDisabled } = useSelector(attributeRangeDetectorsSelector);

  const [filter, setFilter] = useState<string>('');

  const detectors = list || [];

  const getFilteredDetectors = () =>
    filter
      ? detectors.filter((attributeDetector) => attributeDetector.name.toLowerCase().includes(filter.toLowerCase()))
      : detectors;

  const getSortedDetectors = (detectors: IAttributeRangeDetector[]) =>
    detectors.sort((a, b) => (fc(a.name) > fc(b.name) ? 1 : -1));

  const detectorsList = useMemo(() => getSortedDetectors(getFilteredDetectors()), [detectors, filter]);

  const onAdd = () => {
    history.push(`${AllRoutes.AttributeRangeDetectors}/create`);
  };

  const onSelect = (detector: IAttributeRangeDetector) => {
    history.push(`${AllRoutes.AttributeRangeDetectors}/${detector.processorId}`);
  };

  const actionsProps = { isDisabled, title: 'attribute range detectors', onAdd, isEditShown: true };
  const listProps = { filter, detectors: detectorsList, setFilter, onSelect };

  return { actionsProps, listProps, isDisabled };
};
