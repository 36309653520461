import React from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../../i18n';
import List from '../../../../general/list/List';
import Address from '../../../../general/location/Address';
import { formatter } from '../../../../../misc/datetime';
import { cx } from '../../../../../api';
import NoDataView from '../../../../general/view/NoDataView';
import { className } from '../../../../../lib/className';
import './recentTrips.scss';

/**
 * @param {Object} props
 * @param {cx.ods.devices.DeviceTrip} props.deviceTrip
 */

function _TripItem(props) {
	const { f } = useI18n();
	const trip = props.deviceTrip.trip;
	const startDateTime = formatter.relative(trip.startAt, cx.now(), true).value;
	const endDateTime = trip.endId && formatter.relative(trip.endAt, trip.startAt, true).value;
	const datetimeRangeMessage = (
		endDateTime
			? (startDateTime + ' - ' + endDateTime)
			: (f('started at') + ' ' + startDateTime)
	);

	return (
		<div className="trip-item">
			<div className="device-name">
				{props.device && props.device.denomination()}
			</div>
			<div className="date-time">
				{datetimeRangeMessage}
			</div>
			<div className="location-message">
				<Address message={trip.startMessage} />
			</div>
			{trip.endId
				&& <div className="location-message">
					<Address message={trip.lastMessage} />
				</div>
			}
		</div>
	);
}

const TripItem = connect(
	(state, props) => ({
		device: state.devices.map && state.devices.map[props.deviceTrip.uri]
	})
)(_TripItem);

/**
 * @param {Object} props
 * @param {Array.<cx.ods.devices.DeviceTrip>} props.recents
 */

function RecentTrips(props) {
	const { f } = useI18n();
	let items = null;

	if (props.recents) {
		items = (props.recents.map(deviceTrip => <TripItem key={deviceTrip.trip.tripId} deviceTrip={deviceTrip} />));
	} else items = (<NoDataView />);

	return (
		<List className={className("recent-trips", { 'empty': !props.recents })}>
			<div className="header">
				{f('most recent')}
			</div>
			{items}
		</List>
	);
}

export default RecentTrips;