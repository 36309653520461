import { FC } from 'react';
import { createPortal } from 'react-dom';

import { PortalIds } from 'constants/portal';

interface IReactPortalProps {
  portalId?: string;
  children: JSX.Element;
}

export const ReactPortal: FC<IReactPortalProps> = ({ portalId, children }) => {
  const portalNode = document.getElementById(portalId || PortalIds.App);

  if (portalNode !== null) {
    return createPortal(children, portalNode);
  }

  return null;
};
