import React from 'react';
import { formatter } from '../../../../../misc/datetime';
import './barChart.scss';

/**
 * @param {Object} props
 * @param {cx.ods.stomach.TimeHistogramBin} props.bin
 * @param {Date} props.datetime
 * @param {number} props.height
 */

function BarItem(props) {
	const quantityTitle = props.bin ? props.bin.quantity : 0;
	const style = { height: props.height + '%' };

	return (
		<div className="container" title={formatter.format(props.datetime, true)}>
			<div className="bin" style={style} title={quantityTitle}></div>
		</div>
	);
}

/**
 * @param {Object} props
 * @param {cx.ods.stomach.TimeHistogram} props.histogram
 */

function BarChart(props) {
	let barItems = null;
	let totalQuantity = 0;

	if (props.histogram) {
		let maximum = 0;
		const binsMap = props.histogram.binsMap;
		const bins = Object.keys(binsMap);
		Object.values(binsMap).forEach(bin => {
			if (bin) {
				totalQuantity += bin.quantity;
				bin.quantity > maximum && (maximum = bin.quantity);
			}

		});

		barItems = (bins.map(bin => <BarItem
			key={bin}
			bin={binsMap[bin]}
			datetime={new Date(+bin)}
			height={binsMap[bin] && maximum ? (100 / maximum) * binsMap[bin].quantity : 0}
		/>));
	}
	return (
		<div className="bar-chart">
			<div className="histogram">
				{barItems}
			</div>
			<div className="total-quantity">
				{totalQuantity}
			</div>
		</div>
	);
}

export default BarChart;