import React from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../../i18n';
import List from '../../../../general/list/List';
import { cx } from '../../../../../api'; // eslint-disable-line
import EventField from '../../../../share/events/EventField';
import NoDataView from '../../../../general/view/NoDataView';
import { className } from '../../../../../lib/className';
import './recentEvents.scss';

/**
 * @param {Object} props
 * @param {cx.ods.devices.DeviceEvent} props.deviceEvent
 */

function _EventItem(props) {
	const event = props.deviceEvent.event;

	return (
		<div className="event-item">
			<div className="device-name">
				{props.device && props.device.denomination()}
			</div>
			<div className="message">
				<EventField event={event} showDate />
				{/* <Address message={event.message} /> */}
			</div>
		</div>
	);
}

const EventItem = connect(
	(state, props) => ({
		device: state.devices.map && state.devices.map[props.deviceEvent.uri]
	})
)(_EventItem);

/**
 * @param {Object} props
 * @param {Array.<cx.ods.devices.DeviceEvent>} props.recents
 */

function RecentEvents(props) {
	const { f } = useI18n();
	let items = null;

	if (props.recents) {
		items = (props.recents.map(deviceEvent => <EventItem key={deviceEvent.event.eventId} deviceEvent={deviceEvent} />));
	} else items = (<NoDataView />);

	return (
		<List className={className("recent-events", { 'empty': !props.recents })}>
			<div className="header">
				{f('most recent')}
			</div>
			{items}
		</List>
	);
}

export default RecentEvents;
