import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { fromEPSG4326 } from 'ol/proj/epsg3857';
import GeometryType from 'ol/geom/GeometryType';
import { Feature } from 'ol';
import { LineString, Point } from 'ol/geom';
import { setProps } from '../../../misc/ol';
import { readMultiPolygon } from '../../../misc/wkt';
import { makeStyle, StyleType } from '../zones/zoneStyle';
import { Map } from '../../general/location/Map';
import MapOptions from '../map/controls/MapOptions';
import MapOptionZones from '../../custom/map/controls/MapOptionZones';
import { actions as zonesActions } from '../../../redux/api/zones';
import { Style, Stroke, Icon, Circle, Fill } from 'ol/style';
import LayerGroup from 'ol/layer/Group';
import tripEnd from '../../../img/markers/tripend.png';
import css from '../../../defaults.scss';
import { className, fromProps } from '../../../lib/className';
import MapControls from './controls/MapControls';
import useLocalStorage from '../../../misc/useLocalStorage';

const MAP_NAME = 'trace-view';

/**
 * @param {Object} props
 * @param {Array.<cx.ods.devices.MessageDetails>} [props.trace]
 * @param {string} [props.mapName]
 * @param {boolean} [props.pending]
 * @param {boolean} [props.keepInView=true] default true
 * @param {Array.<React.Component>} [props.markers]
 * @param {boolean} [props.showOptions=true]
 */

function TraceViewMap(props) {
	const mapName = props.mapName ? props.mapName : MAP_NAME;
	const map = useRef(null);
	const featureRef = useRef(null);
	const layerGroup = useRef(null);
	const [displayZones, setDisplayZones] = useLocalStorage(mapName + '_display_zones');
	const keepInView = props.keepInView === undefined ? true : props.keepInView;
	const showOptions = props.showOptions === undefined ? true : props.showOptions;


	const styleFunction = (feature) => {
		const geometryType = feature.getGeometry().getType();
		const coordinates = feature.getGeometry().getCoordinates();
		const styles = [
			new Style({
				stroke: new Stroke({
					color: css.traceStrokeColor1,
					width: css.traceStrokeWidth1
				})
			}),
			new Style({
				stroke: new Stroke({
					color: css.traceStrokeColor2,
					width: css.traceStrokeWidth2
				})
			})
		];
		switch (geometryType) {
			case GeometryType.POINT:
				styles.push(
					new Style({
						image: new Circle({
							radius: css.tracePointRadius,
							fill: new Fill({ color: css.tracePointFillColor }),
							stroke: new Stroke({
								color: css.traceStrokeColor1,
								width: css.tracePointStrokeWidth
							})
						}),
						geometry: () => {
							return new Point(coordinates);
						}
					})
				);
				break;
			case GeometryType.LINE_STRING:
				if (!props.markers) {
					styles.push(
						new Style({
							image: new Circle({
								radius: css.tracePointRadius,
								fill: new Fill({ color: css.tracePointFillColor }),
								stroke: new Stroke({
									color: css.traceStrokeColor1,
									width: css.tracePointStrokeWidth
								})
							}),
							geometry: () => {
								return new Point(coordinates[0]);
							}
						})
					);
					styles.push(
						new Style({
							image: new Icon({
								src: tripEnd,
								anchor: [0.5, 1],
							}),
							zIndex: 4,
							geometry: () => {
								return new Point(coordinates[coordinates.length - 1]);
							}
						})
					);
				}
				break;
		}
		return styles;
	}

	useEffect(() => {
		featureRef.current = new Feature({ name: mapName });
		const layer = new VectorLayer({
			source: new VectorSource({ features: [featureRef.current] }),
			style: styleFunction
		});
		map.current.getOlMap().addLayer(new LayerGroup({ layers: [layer] }));
		return () => {
			map.current.getOlMap().removeLayer(layer);
		};
	}, []);

	useEffect(() => {
		if (featureRef.current && props.trace && props.trace.length > 0) {
			const coordinates = [];
			props.trace.forEach(item => {
				if (item.longitude && item.latitude) {
					coordinates.push(fromEPSG4326([item.longitude, item.latitude]));
				}
			});
			const geometry = coordinates.length == 1
				? new Point(coordinates[0])
				: new LineString(coordinates)
			;
			featureRef.current.setGeometry(geometry);
			if (keepInView) {
				if (coordinates.length == 1) {
					map.current.getOwMap().focus(coordinates[0], 18);
				} else {
					map.current.getOwMap().fitExtent(geometry.getExtent());
				}
			}
		} else {
			featureRef.current.setGeometry(null);
		}
	}, [props.trace]);

	useEffect(() => {
		if (props.zones.map != null) {
			const zones = Object.values(props.zones.map);
			const layers = {};
			zones.forEach(zone => {
				const layer = new VectorLayer({
					source: new VectorSource({
						features: setProps(readMultiPolygon(zone.geometry), {
							zoneId: zone.zoneId,
							name: zone.name,
							style: zone.style
						})
					}),
					style: makeStyle(StyleType.Default),
				});
				layer.setProperties({ zoneId: zone.zoneId });
				layers[zone.zoneId] = layer;
			});
			layerGroup.current = new LayerGroup({ layers: Object.values(layers) });
			map.current.getOlMap().addLayer(layerGroup.current);
			layerGroup.current.setVisible(displayZones);
		}
	}, [props.zones]);

	useEffect(() => {
		if (props.zones.map == null) {
			props.dispatch(zonesActions.load.request());
		}
		if (layerGroup.current != null) {
			layerGroup.current.setVisible(displayZones);
		}
	}, [displayZones]);

	return (
		<Map
			className={className('trace-view', fromProps(props))}
			name={mapName}
			ref={map}
			baseLayer={Map.Layers.ONE}
			pending={props.pending}
			controls={showOptions &&
				<MapControls>
					<MapOptions>
						<MapOptionZones value={displayZones} onChange={setDisplayZones} />
					</MapOptions>
					{props.controls}
				</MapControls>
			}
		>
			{props.markers}
		</Map>
	);
}

export default connect(state => ({
	zones: state.zones
}))(TraceViewMap);
