import { cx } from "../api";

export const hasTarget = (event, subject) => {
	if (!subject) return false;
	if (NodeList.prototype.isPrototypeOf(subject) || HTMLCollection.prototype.isPrototypeOf(subject) || Array.isArray(subject)) {
		return Array.from(subject).some(node => hasTarget(event, node));
	} else {
		let result = cx.dom.i.traverse(subject, (node) => node == event.target);
		if (!result && subject.childNodes.length > 0) {
			result = Array.from(subject.childNodes).some((node) => hasTarget(event, node));
		}
		return result;
	}
}