import React, { FC, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useI18n } from '../../../../i18n';
import { useDispatch, useSelector } from 'react-redux';
import {
  devicesSelector,
  digestSelector,
  resolutionTypesSelector,
  runwaysSelector,
  widgetsSelector,
} from '../../../redux/selectors/registry';
import {
  CardCounter,
  CardIndicator,
  ClosedRunwayCard,
  DeviceListItem,
  DeviceListName,
  DevicesList,
  OpenedRunwayCard,
  RunwayCards,
  RunwaysListStyled,
} from '../../../../ui/widgets/RunwayInfo.styled';
import { actions } from 'core/redux/api/veta/runwayGuardDigest';
import { actions as runwayActions } from 'core/redux/api/veta/runways';
import NoDataView from '../../general/view/NoDataView';
import { IconSize } from '../../../img';
import DeviceAssetTypeIcon from '../../share/devices/DeviceAssetTypeIcon';
import { Select } from '../../../../components/molecules/select';
import { ISelectOption } from '../../../../types/select';
import { useHistory } from 'react-router-dom';
import { AllRoutes } from '../../../../constants/routes';
import { ResolutionKind, RuleGroup } from '../../../../constants/issues';
import { actions as widgetsActions } from '../../../redux/app/widgets';

interface IRunwayInfoProps {
  uid: any;
  reduxKey: any;
  expanded: boolean;
  customRef: React.RefObject<any>;
  update(): void;
}

export enum BadgeTypes {
  Unknown = 0,
  NearOpenedRunwayGreen = 1,
  NearOpenedRunwayOrange = 2,
  InTaxiwayRed = 9,
  InTaxiwayGreen = 10,
  InOpenedRunwayGreen = 3,
  InOpenedRunwayOrange = 4,
  InOpenedRunwayRed = 5,
  InClosedRunwayGreen = 6,
  InClosedRunwayOrange = 7,
  InClosedRunwayRed = 8,
}

const RunwayInfo: FC<IRunwayInfoProps> = (props) => {
  const { f, fc } = useI18n();
  const [selectedRunway, setSelectedRunway] = useState<ISelectOption<number> | null>(null);
  const runways = useSelector(runwaysSelector);
  const digest = useSelector(digestSelector);
  const devices = useSelector(devicesSelector);
  const resolutionTypes = useSelector(resolutionTypesSelector);
  const widgets = useSelector(widgetsSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  const getDeviceListItem = (device: { uri: string; issue: { issueTypeId: number; resolutionTypeId: number } }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const foundDevice = (devices && devices.list?.find((d) => d.uri === device.uri)) || null;
    return foundDevice ? (
      <DeviceListItem key={device?.uri}>
        <DeviceListName>
          <DeviceAssetTypeIcon
            size={IconSize.MD}
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            categoryIds={foundDevice.categoryIds}
          />
          <div className="device-name">{foundDevice.denomination()}</div>
        </DeviceListName>

        {selectedRunway && digest.digests[selectedRunway.value].runwayOpen && (
          <CardIndicator
            color={getColorByIssueType(
              device.issue.issueTypeId || 0,
              resolutionTypes?.map ? resolutionTypes?.map[device.issue.resolutionTypeId]?.resolutionKind : null,
              device.uri,
            )}
          >
            {getBadgeText(device.issue.issueTypeId || 0)}
          </CardIndicator>
        )}
      </DeviceListItem>
    ) : null;
  };

  const getBadgeText = (id: number) => {
    if (id === 3 || id === 4) {
      return 'RWY';
    }

    return '';
  };

  const getColorByIssueType = (id: number | undefined, kind: ResolutionKind | null, uid?: any) => {
    switch (true) {
      case id === 0: {
        return '';
      }

      case id === 3:
      case id === 2 && (kind === ResolutionKind.AuthorizedSituation || kind === ResolutionKind.FalsePositive):
      case id === 4 && (kind === ResolutionKind.AuthorizedSituation || kind === ResolutionKind.FalsePositive):
      case id === 1: {
        return '#1C7903';
      }

      case id === 2: {
        return '#DE8D0D';
      }

      case id === 4 && !kind:
      case id === 4 && kind === ResolutionKind.ConfirmedViolation: {
        return '#C92828';
      }

      default: {
        return '';
      }
    }
  };

  const handleRunwayClick = (runway: ISelectOption<number>) => {
    dispatch(actions.load.request({ runwayId: runway.value }));

    setSelectedRunway(selectedRunway?.value === runway.value ? null : runway);
  };

  useEffect(() => {
    if (selectedRunway != null) {
      dispatch(widgetsActions.update({ domain: props.reduxKey, uid: props.uid, data: { selectedRunway } }));
    }
  }, [selectedRunway]);

  const navigateToClosedRunwayIssues = () => {
    history.push(`${AllRoutes.Issues}?tab=${RuleGroup.CLOSED_RUNWAY}`);
  };

  const navigateToOpenedRunwayIssues = () => {
    history.push(`${AllRoutes.Issues}?tab=${RuleGroup.OPEN_RUNWAY}`);
  };

  useEffect(() => {
    dispatch(actions.subscribe());
    dispatch(runwayActions.load.request());
    if (widgets[props.reduxKey].map[props.uid]?.data?.selectedRunway) {
      setSelectedRunway(widgets[props.reduxKey].map[props.uid].data.selectedRunway);
      dispatch(actions.load.request({ runwayId: widgets[props.reduxKey].map[props.uid].data.selectedRunway.value }));
    }

    return () => {
      dispatch(actions.unsubscribe());
    };
  }, []);

  useEffect(() => {
    props.update();
  }, [digest]);

  const getTitle = useCallback(() => (selectedRunway ? selectedRunway.label : fc('Runway info')), [selectedRunway]);
  console.log(digest);
  const getDevicesList = useCallback(
    () =>
      selectedRunway && (
        <>
          <>
            {digest.digests && digest.digests[selectedRunway.value]?.guarded?.length ? (
              <DevicesList>
                {digest.digests[selectedRunway.value]?.guarded.map((device) => getDeviceListItem(device))}
              </DevicesList>
            ) : (
              <NoDataView />
            )}
          </>
          {selectedRunway &&
            digest.digests &&
            digest.digests[selectedRunway.value] &&
            digest.digests[selectedRunway.value].runwayOpen && (
              <RunwayCards>
                <OpenedRunwayCard onClick={navigateToOpenedRunwayIssues}>
                  <CardIndicator color={'#1C7903'}></CardIndicator>
                  <CardCounter color={'#1C7903'}>
                    {(digest.digests && digest.digests[selectedRunway.value]?.innerZone?.permitted) || 0}
                  </CardCounter>
                </OpenedRunwayCard>
                <OpenedRunwayCard onClick={navigateToOpenedRunwayIssues}>
                  <CardIndicator color={'#DE8D0D'}></CardIndicator>
                  <CardCounter color={'#DE8D0D'}>
                    {(digest.digests && digest.digests[selectedRunway.value]?.innerZone?.forbidden) || 0}
                  </CardCounter>
                </OpenedRunwayCard>
                <OpenedRunwayCard onClick={navigateToOpenedRunwayIssues}>
                  <CardIndicator color={'#1C7903'}>RWY</CardIndicator>
                  <CardCounter color={'#1C7903'}>
                    {(digest.digests && digest.digests[selectedRunway.value]?.incursionZone?.permitted) || 0}
                  </CardCounter>
                </OpenedRunwayCard>
                <OpenedRunwayCard onClick={navigateToOpenedRunwayIssues}>
                  <CardIndicator color={'#DE8D0D'}>RWY</CardIndicator>
                  <CardCounter color={'#DE8D0D'}>
                    {(digest.digests && digest.digests[selectedRunway.value]?.incursionZone?.unresolved) || 0}
                  </CardCounter>
                </OpenedRunwayCard>
                <OpenedRunwayCard onClick={navigateToOpenedRunwayIssues}>
                  <CardIndicator color={'#C92828'}>RWY</CardIndicator>
                  <CardCounter color={'#C92828'}>
                    {(digest.digests && digest.digests[selectedRunway.value]?.incursionZone?.forbidden) || 0}
                  </CardCounter>
                </OpenedRunwayCard>
              </RunwayCards>
            )}
          {selectedRunway &&
            digest.digests &&
            digest.digests[selectedRunway.value] &&
            !digest.digests[selectedRunway.value]?.runwayOpen && (
              <RunwayCards>
                <ClosedRunwayCard onClick={navigateToClosedRunwayIssues}>
                  <CardIndicator color={'#005095'}>Total No. Of Vehicles In Runway</CardIndicator>
                  <CardCounter color={'#005095'}>
                    {(digest.digests && digest.digests[selectedRunway.value]?.guardZone?.present) || 0}
                  </CardCounter>
                </ClosedRunwayCard>
                <ClosedRunwayCard onClick={navigateToClosedRunwayIssues}>
                  <CardIndicator color={'#C92828'}>Unauthorised Entry</CardIndicator>
                  <CardCounter color={'#C92828'}>
                    {(digest.digests && digest.digests[selectedRunway.value]?.guardZone?.unauthorizedEntries) || 0}
                  </CardCounter>
                </ClosedRunwayCard>
                <ClosedRunwayCard onClick={navigateToClosedRunwayIssues}>
                  <CardIndicator color={'#C92828'}>Unauthorised Exit</CardIndicator>
                  <CardCounter color={'#C92828'}>
                    {(digest.digests && digest.digests[selectedRunway.value]?.guardZone?.unauthorizedExits) || 0}
                  </CardCounter>
                </ClosedRunwayCard>
              </RunwayCards>
            )}
        </>
      ),
    [selectedRunway, digest],
  );

  useImperativeHandle(props.customRef, () => ({
    title: getTitle,
    configuration: () => {
      return (
        <div style={{ height: 90 }}>
          <RunwaysListStyled>
            Runway
            <Select
              defaultText={'Select runway'}
              selectedValue={selectedRunway}
              options={runwaysItems()}
              onOptionChange={handleRunwayClick}
            />
          </RunwaysListStyled>
        </div>
      );
    },
  }));

  const runwaysItems = () => {
    return runways.list?.map((runway) => ({ value: runway.id, label: runway.name })) || null;
  };

  return (
    <RunwaysListStyled>
      <div>
        {!selectedRunway && (
          <Select
            defaultText={'Select runway'}
            selectedValue={selectedRunway}
            options={runwaysItems()}
            onOptionChange={handleRunwayClick}
          />
        )}
      </div>
      {getDevicesList()}
    </RunwaysListStyled>
  );
};

export default RunwayInfo;
