import React, { useEffect, useImperativeHandle, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { ods } from '../../../api';
import { useI18n } from '../../../../i18n';
import * as widgets from '../../../redux/app/widgets';
import { actions as assetsActions } from '../../../redux/api/assets';
import { modeMap } from '../../../redux/api/assets/commute/reducer';
import WidgetErrorContent from '../../general/widgets/WidgetErrorContent';
import ActionLockedDevices from '../../general/widgets/ActionLockedDevices';
import WidgetMenuItem from '../../general/widgets/WidgetMenuItem';
import DeviceMessageCharts from '../../share/devices/DeviceMessageCharts';
import DeviceShortInfoView from '../../share/devices/DeviceShortInfoView';
import MessageFieldsTimeLocation from '../../share/message/MessageFieldsTimeLocation';
import NoDataView from '../../general/view/NoDataView';
import ActionLock from '../../share/actionbar/ActionLock';
import ActionMaximize from '../../share/actionbar/ActionMaximize';
import ModeConfiguration from './aux/ModeConfiguration';
import CommuterEvaluatingBoard from './commuteWidget/CommuterEvaluatingBoard';
import CommuteIntervalList from '../../share/commute/CommuteIntervalList';
import * as timeMachineState from '../../../redux/app/timeMachine/state';
import './commutersWidget.scss';

const actions = assetsActions.commute;

/**
 * Widget showing current device info: location, latest message with all fields, latest event, etc.
 * @param {Object} props
 * @param {string} props.uid
 * @param {React.RefObject} props.customRef
 * @param {function} props.update to rerender WidgetContainer
 */

const PIN_ACTIONS = { maximize: 'maximize', lock: 'lockDevices' };

function CommutersWidget(props) {
	const { f, fc } = useI18n();
	const modeData = {
		[modeMap.CURRENT]: { value: modeMap.CURRENT, label: fc('current') },
		[modeMap.RECENT_TRIPS]: { value: modeMap.RECENT_TRIPS, label: fc('recent trip') }
	};

	const selectedMode = props.widgetData?.mode || modeMap.DEFAULT;

	const device = props.deviceMap && props.deviceMap[props.uri];

	const lockHandler = () => {
		props.dispatch(widgets.actions.update({
			domain: props.reduxKey,
			uid: props.uid,
			data: { uris: props.locked ? null : props.uris }
		}));
	}

	const clearCommuteData = () => {
		props.dispatch(actions.remove({ uid: props.uid }));
	}

	useEffect(() => {
		if (props.reduxKey == 'timeMachine') {
			props.dispatch(timeMachineState.actions.subscribe({ historyAspect: 'assetPresences' }));
		}
		return () => {
			clearCommuteData();
			if (props.reduxKey == 'timeMachine') {
				props.dispatch(timeMachineState.actions.unsubscribe({ historyAspect: 'assetPresences' }));
			}
		}
	}, []);

	useEffect(() => {
		if (props.reduxKey != 'timeMachine') {
			if (props.uris.length > 0) {
				const parameters = new ods.assets.CommuteStatusParameters();
				parameters.uris = props.uris;
				props.dispatch(actions.request({ uid: props.uid, mode: selectedMode, parameters, clear: true }));
			} else clearCommuteData();
		}
	}, [selectedMode, props.uris?.length]);

	useLayoutEffect(() => {
		props.update();
	}, [props.locked, props.uris?.length, props.message, props.widgetData]);

	const onMaximize = () => {
		props.dispatch(widgets.actions.maximize({ domain: props.reduxKey, uid: props.maximized ? null : props.uid }));
	}

	const setPinnedActions = (actionName, pinned) => {
		const pinnedActions = props.widgetData && props.widgetData.pinnedActions ? { ...props.widgetData.pinnedActions } : {};
		pinnedActions[actionName] = pinned;
		props.dispatch(widgets.actions.update({ domain: props.reduxKey, uid: props.uid, data: { pinnedActions } }));
	}

	useImperativeHandle(props.customRef, () => ({
		title: () => fc('commuters') + ' - ' + modeData[selectedMode].label,
		actions: (onChange) => {
			const actions = [];
			if (props.locked) {
				actions.push(
					<ActionLockedDevices
						onChange={onChange}
						key="action-uris"
						uris={props.widgetData.uris}
						disabledUris={props.disabledUris}
						emptyMessage={fc('all devices')}
					/>
				);
			}
			if (props.widgetData && props.widgetData.pinnedActions) {
				if (props.widgetData.pinnedActions[PIN_ACTIONS.maximize]) {
					actions.push(
						<ActionMaximize
							key={PIN_ACTIONS.maximize}
							onClick={onMaximize}
							active={props.maximized}
						/>
					);
				}
				if (props.widgetData.pinnedActions[PIN_ACTIONS.lock]) {
					actions.push(
						<ActionLock
							key={PIN_ACTIONS.lock}
							disabled={props.uris.length == 0}
							onClick={lockHandler}
							active={props.locked}
							title={fc(props.locked ? 'unlock' : 'lock')}
						/>
					);
				}
			}
			return actions;
		},
		canPin: props.locked || props.uris.length > 0,
		handlePin: lockHandler,
		menuItems: () => {
			return [
				<WidgetMenuItem
					onClick={onMaximize}
					text={fc(props.maximized ? 'minimize' : 'maximize')}
					key={PIN_ACTIONS.maximize}
					canPin
					pinned={props.widgetData && props.widgetData.pinnedActions && props.widgetData.pinnedActions[PIN_ACTIONS.maximize]}
					onChangePin={(pinned) => setPinnedActions(PIN_ACTIONS.maximize, pinned)}
				/>,
				<WidgetMenuItem
					disabled={!props.locked && props.uris.length == 0}
					onClick={lockHandler}
					text={fc(props.locked ? 'unlock' : 'lock')}
					key={PIN_ACTIONS.lock}
					canPin
					pinned={props.widgetData && props.widgetData.pinnedActions && props.widgetData.pinnedActions[PIN_ACTIONS.lock]}
					onChangePin={(pinned) => setPinnedActions(PIN_ACTIONS.lock, pinned)}
				/>
			];
		},
		configuration: () => <ModeConfiguration modes={Object.values(modeData)} initialMode={selectedMode} />,
		dynamicContent: () => {
			if (props.uris.length > 0) {
				return <CommuteIntervalList uid={props.uid} uris={props.uris} reduxKey={props.reduxKey} />;
			}
		},
		expandable: props.uris.length > 0
	}));

	let content = null;
	if (props.uris.length > 0) {
		if (
			props.uris.length == 1 &&
			props.deviceMap && props.deviceMap[props.uri] &&
			props.message
		) {
			content = (<>
				<DeviceShortInfoView device={device} />
				<MessageFieldsTimeLocation message={props.message} />
				<CommuterEvaluatingBoard uid={props.uid} onMounted={props.update} uris={props.uris} reduxKey={props.reduxKey} />
			</>);
		} else if (props.uris.length > 1) {
			content = (
				<>
					<DeviceMessageCharts uris={props.uris} reduxKey={props.reduxKey} />
					<CommuterEvaluatingBoard uid={props.uid} onMounted={props.update} uris={props.uris} reduxKey={props.reduxKey} />
				</>
			);
		} else {
			const message = props.uris.length > 1
				? f('no data for selected devices')
				: f('no data for', { name: props.deviceMap && props.deviceMap[props.uri] ? props.deviceMap[props.uri].name : props.uri })
			;
			content = (<NoDataView message={message} />);
		}
	} else {
		content = (<WidgetErrorContent message={f('please select device')} />);
	}

	return (
		<div className="commuters widget">
			{content}
		</div>
	);
}

export default connect((state, props) => {
	const widget = state.widgets[props.reduxKey].map[props.uid];
	const selection = state.pages[props.reduxKey].selection;

	const widgetData = widget && widget.data;
	const locked = !!(widgetData && widgetData.uris);
	let uris = locked  ? widgetData.uris : (selection ? selection : []);
	let disabledUris = null;
	if (locked && props.reduxKey == 'timeMachine') {
		uris = selection.filter(uri => widgetData.uris.includes(uri));
		disabledUris = widgetData.uris.filter(uri => !selection.includes(uri));
	}
	const uri = uris[0];

	let now = null;
	let message = null;
	if (props.reduxKey == 'dashboard') {
		message = state.deviceStates.map && state.deviceStates.map[uri]
			? state.deviceStates.map[uri].message
			: null
		;
	} else if (props.reduxKey == 'timeMachine') {
		if (state.timeMachine.state.parameters) now = state.timeMachine.state.parameters.now;
		if (state.timeMachine.state.map && state.timeMachine.state.map[uri]) {
			message = state.timeMachine.state.map[uri].message
		}
	}
	return {
		selection,
		widget,
		message,
		uris,
		uri,
		now,
		locked,
		widgetData,
		disabledUris,
		deviceMap: state.devices.map
	};
})(CommutersWidget);
