import React, { useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import { useI18n } from '../../../../../i18n';
import { cx } from '../../../../api';
import Form from '../../../general/form/Form';
import { actions } from '../../../../redux/api/reports';

const BATCH_SIZE = 70;

/**
 * @param {Object} props
 * @param {cx.ods.TimeRangeParameters} props.timeRange
 * @param {React.RefObject} props.customRef
 * @param {boolean} [props.disabled]
 */

function Ridership(props) {
	const { f } = useI18n();

	useImperativeHandle(props.customRef, () => ({
		submit: (values) => {
			const parameters = new cx.ods.reports.RidershipReportParameters();
			parameters.timeRange = props.timeRange;
			parameters.timeRange.retrospective = values.retrospective;
			parameters.timeRange.window = new cx.ods.TimeWindow(BATCH_SIZE);
			props.dispatch(actions.ridership.request({ uri: values.uri, parameters, clear: true }));
		},
		clear: () => {
			props.dispatch(actions.ridership.exportClear());
		}
	}));

	return (
		<Form.ControlGroup key={"ridership-parameters"} disabled={props.disabled}>
			<Form.Control
				controlName="retrospective"
				controlType={Form.Control.Type.Checkbox}
				label={f('report later items first')}
				initialValue={props.state.parameters ? props.state.parameters.timeRange.retrospective : false}
			/>
		</Form.ControlGroup>
	)
}

export default connect(state => ({
	state: state.reports.ridership
}))(Ridership);
