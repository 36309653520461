import React from 'react';
import { useI18n } from '../../../../../i18n';
import Button from '../../../general/form/Button';
import './deviceCommandIsPending.scss';

/**
 * @param {Object} props
 * @param {cx.ods.devices.CommandType} props.command
 * @param {function} props.onCancel
 * @param {boolean} [props.processing]
 */

function DeviceCommandIsPending(props) {
	const { f } =useI18n();

	return (
		<div className="device-command-is-pending">
			<div className="title">
				<label>
					<span className="capitalize">
						{f({ prefix: 'command', id: props.command.description })}
					</span>
				</label>
			</div>
			<Button disabled={props.processing} onClick={() => props.onCancel(props.command.commandType)}>{f('cancel')}</Button>
		</div>
	);
}

export default DeviceCommandIsPending;