import Resolver from '../Resolver';
import { actions } from '../actions';

const getter = (entry) => {
	const isTrip = !!entry.trip;
	const startMessage = isTrip ? entry.trip.startMessage : entry.startMessage;
	const lastMessage = isTrip ? entry.trip.lastMessage : entry.lastMessage;
	return [{
		latitude: startMessage.latitude,
		longitude: startMessage.longitude,
	}, {
		latitude: lastMessage.latitude,
		longitude: lastMessage.longitude,
	}]
}

let resolver = null;
const connect = (store) => {
	resolver = new Resolver(store, actions.tripHistory.exportProgress, getter);
}

export { resolver, connect };